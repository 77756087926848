import { useTranslation } from 'react-i18next';

import {
  Document,
  QualityControlInstance,
  SearchDocument,
} from '@pro4all/graphql';

export const useGetCardTitle = () => {
  const { t } = useTranslation();

  const getCardTitle = (
    card: SearchDocument | QualityControlInstance | Document
  ) => {
    if (!card?.name) {
      return t('No permissions to view this document');
    }

    if (card?.__typename === 'QualityControlInstance') {
      const referencePart = ` #${card?.reference ? card?.reference : '-'}`;
      const statusPart = ` status: ${
        card?.indicativeState?.value ? card?.indicativeState.value : '-'
      }`;
      return `${card?.name}${referencePart}${statusPart}`;
    }

    if (card?.path) {
      return `${card?.name} - ${card?.path}`;
    }
    return card?.name;
  };

  return { getCardTitle };
};
