import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  FilterBarProps,
  FilterButtonProps,
} from '@pro4all/shared/search-utils';
import { staticFilterTypes } from '@pro4all/shared/search-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { DataViewContextToggle } from '@pro4all/shared/ui/data-view';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';

import {
  AddFilterButton,
  excludedFilterTypes,
  Filter,
  useFilters,
} from '../filters/';
import { getFacetGroup } from '../filters/utils/getFacetGroup';
import { useIsFeatureEnabled } from '../filters/utils/useIsFeatureEnabled';

import { FilterMenuProvider } from './FilterMenuContext';

export const SearchToolbar: React.FC<FilterBarProps> = ({
  ScopeFilter,
  loading,
  LocalFilter,
  dataViewToggle,
  facetGroups,
  onApply,
  onSave,
  contextQuery,
  showSaveButton = true,
  showResetButton = true,
  addFilterColor = 'primary',
}) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isQCSearchRoute = useIsQCSearchRoute();

  const {
    setFilterValue,
    currentFilters,
    resetFilter,
    resetAllFilters,
    removeFilter,
  } = useFilters();

  const buttonProps: FilterButtonProps = {
    onApply,
    removeFilter,
    resetFilter,
    setFilterValue,
  };

  const persistentFilters = currentFilters
    ?.filter(
      (filter) => !isQCSearchRoute && staticFilterTypes.includes(filter.type)
    )
    .filter(Boolean);

  const optionalFilters = currentFilters?.filter(
    (filter) =>
      !staticFilterTypes.includes(filter.type) &&
      !excludedFilterTypes.includes(filter.type) &&
      isFeatureEnabled(filter.type)
  );

  return (
    <FilterMenuProvider>
      <Box pl={2} pr={1} py={1}>
        <ToolbarWrapper>
          {!isMobile && LocalFilter && LocalFilter}
          {!isMobile && ScopeFilter && ScopeFilter}
          {!isMobile &&
            persistentFilters?.map((filterProps, key) => (
              <Filter
                key={`${filterProps.type}-filter-${key}`}
                {...buttonProps}
                {...filterProps}
                facetGroup={
                  facetGroups && getFacetGroup(facetGroups, filterProps.type)
                }
                facetLoading={loading}
              />
            ))}
          {!isMobile &&
            optionalFilters?.map((filterProps, key) => (
              <Filter
                key={`${filterProps.type}-filter-${key}`}
                {...buttonProps}
                {...filterProps}
                facetGroup={
                  facetGroups &&
                  getFacetGroup(
                    facetGroups,
                    filterProps.type,
                    filterProps.metaDataKey
                  )
                }
                facetLoading={loading}
              />
            ))}
          {!isMobile && <AddFilterButton color={addFilterColor} />}
          <Right>
            {dataViewToggle && <DataViewContextToggle />}
            {showResetButton && (
              <IconButton
                ariaLabel={t('Reset all filters')}
                color="default"
                disableBorder
                iconName="reset"
                onClick={resetAllFilters}
              />
            )}
            {onSave && showSaveButton && (
              <IconButton
                ariaLabel={t('Save search')}
                color="default"
                disableBorder
                disabled={Boolean(contextQuery?.length)}
                iconName="save"
                onClick={onSave}
              />
            )}
          </Right>
        </ToolbarWrapper>
      </Box>
    </FilterMenuProvider>
  );
};

const ToolbarWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;
  min-width: 100%;
  flex-wrap: wrap;

  > * :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;
