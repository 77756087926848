import { v4 as uuid } from 'uuid';

import { Document, FieldDefinition, Instance } from '@pro4all/graphql';

import { Meta, ProcessingStatusMeta } from '../../useUploaderEditorReducer';

import { useGetErrorMetadata, useGetErrorName, useGetWarningMetadata } from '.';

export const useCalculateMetaForFileToUpload = () => {
  const getError = useGetErrorMetadata();
  const getWarning = useGetWarningMetadata();
  const getErrorName = useGetErrorName();

  const calculateMetaForFileToUpload = ({
    currentDocumentsInFolder,
    documentCurrent,
    fields,
    filesToUpload,
  }: {
    currentDocumentsInFolder: Document[];
    documentCurrent: Document | null;
    fields: FieldDefinition[];
    filesToUpload: File[];
  }) => {
    const meta: Meta[] = filesToUpload.map((file) => {
      const fileUploadId = uuid();

      // Find the document in the currentDocumentsInFolder array that has the same name as the file.
      const document = currentDocumentsInFolder.find(
        (doc) => doc.name === file.name
      ) as Document;

      const documentContainer = documentCurrent || document;

      const version = {
        newVersionFor: documentContainer
          ? {
              id: documentContainer.id,
              metadataInstanceId: documentContainer.metadataInstanceId || '',
              name: documentContainer.name,
            }
          : null,
        versionNumber: documentContainer
          ? documentContainer.versionNumber
            ? documentContainer.versionNumber + 1
            : 1
          : 1,
      };

      const metadataInstanceId = documentContainer
        ? (documentContainer.metadataInstanceId as string | undefined)
        : undefined;

      const sharedProps = {
        error: '',
        file,
        id: fileUploadId,
        metaData: fields.map((fieldDefinition) => {
          const { id, name } = fieldDefinition;
          return {
            error: getError({ fieldDefinition, value: '' }),
            fieldDefinitionId: id,
            isUpdatedManually: false,
            name: name as string,
            value: '',
            valueInitial: '',
            warning: getWarning({ fieldDefinition, value: '' }),
          };
        }),
        metadataInstanceId,
        name: file.name,
        nameInitial: file.name,
        processingStatus: null as ProcessingStatusMeta,
        version,
        versionId: '',
      };

      if (documentContainer && documentContainer.metadataInstanceId) {
        // There is already a document container in the folder with the same name as the file OR context is 1. dropping file on sidebar or version table or 2. publish a file.
        // If the document has a metadataInstanceId, it means that it has metadata.
        // We have to fill the meta data property of the document with the metadata of the document.

        const answers =
          (documentContainer.metaData?.answers as Instance[]) || [];

        return {
          ...sharedProps,
          metaData: fields.map((fieldDefinition) => {
            const { fieldDefinitionId, id, name } = fieldDefinition;
            const fieldId = id;
            const compareFieldId = fieldDefinitionId ? fieldDefinitionId : id;
            const answer = answers.find(
              (answer) => answer.fieldDefinitionId === compareFieldId // To search for reusable fields
            ) as Instance;
            const value = answer?.value || '';

            return {
              error: getError({ fieldDefinition, value }),
              fieldDefinitionId: fieldId,
              isUpdatedManually: false,
              name: name as string,
              value,
              valueInitial: value,
              warning: getWarning({ fieldDefinition, value }),
            };
          }),
        };
      } else {
        // There is no document container in the folder with the same name as the file.
        return {
          ...sharedProps,
        };
      }
    });

    // Now we have initialized the meta prop. However it could be that the `error` prop of meta must be updated.
    // For instance if we upload as a new version for a container named `2.jpg` a file called `1.jpg` while there is already a container named `1.jpg`.
    // For that we need to call the `getErrorName` method we pulled out from `useGetErrorName`.
    // This method requires a number of props, among them the prop `meta`.
    const metaResponse = meta.map((metaInstance) => ({
      ...metaInstance,
      error: getErrorName({
        currentDocumentsInFolder,
        documentId: documentCurrent?.id || '',
        meta,
        uploadContext: Boolean(filesToUpload.length),
        value: metaInstance.name,
      }),
    }));

    return metaResponse;
  };

  return calculateMetaForFileToUpload;
};
