import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  QualityControlInstance,
  SearchDocument,
} from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Card } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import {
  determineCardStyle,
  determineIfItsDeleted,
  LinkedInstance,
} from '@pro4all/workflow/ui/utils';

import * as Styled from './LinkedInstances.styles';
import { Drawing, LinkedInstancesProps, TaskKey } from './types';
import { useDetermineMenuItems } from './useDetermineMenuItems';
import { useGetCardConfigs } from './useGetCardConfigs';
import { useGetCardTitle } from './useGetCardTitle';
import { useSetDrawings } from './useSetDrawings';
import { useShowCards } from './useShowCards';

export const LinkedInstances: React.FC<LinkedInstancesProps> = ({
  currentTask,
  taskType,
}) => {
  const { t } = useTranslation();

  //these are the drawings that the linked snags are placed on
  const [drawings, setDrawingsState] = useState<Drawing[]>([]);
  const isSelectIconEnabled = useFeatureFlag('customericons');

  const { cardConfigs } = useGetCardConfigs({
    currentTask,
    drawings,
    taskType,
  });

  useSetDrawings({ currentTask, setDrawingsState });

  const { getCardTitle } = useGetCardTitle();
  const { determineMenuItems } = useDetermineMenuItems();
  const { showCards } = useShowCards({ currentTask, drawings });

  return (
    <Styled.LinksContainer>
      {cardConfigs.map((cardConfig) => {
        const cardLink = cardConfig.key;

        const dataToMap =
          cardLink === 'linkedSnagDrawings'
            ? drawings
            : currentTask[cardLink as TaskKey];

        return (
          <div key={cardLink}>
            {showCards(cardConfig) ? (
              <Box mb={5} mt={5} mx={3}>
                <Styled.LabelRow>
                  <Icon iconName={cardConfig?.iconName ?? 'clipboard'} />
                  <Text variant="h4">{t(cardConfig?.label() ?? '')}</Text>
                </Styled.LabelRow>
                {dataToMap.map((card: LinkedInstance) => (
                  <Card
                    cardStyle={determineCardStyle(card, cardLink, currentTask)}
                    deleted={determineIfItsDeleted(card, cardLink, currentTask)}
                    iconComponent={
                      cardConfig?.iconComponent &&
                      isSelectIconEnabled &&
                      cardConfig?.iconComponent(currentTask, card)
                    }
                    iconName={cardConfig?.iconName ?? 'clipboard'}
                    key={card?.id}
                    menuItems={determineMenuItems(card, cardLink, cardConfig)}
                    meta={
                      card &&
                      cardConfig?.meta &&
                      !determineIfItsDeleted(card, cardLink, currentTask) &&
                      cardConfig?.meta(card)
                    }
                    onClick={() => {
                      card &&
                        cardConfig?.cardClick &&
                        !determineIfItsDeleted(card, cardLink, currentTask) &&
                        cardConfig?.cardClick(
                          card?.id,
                          (card as QualityControlInstance | Document)?.path ??
                            '',
                          (card as QualityControlInstance | Document)?.name ??
                            ''
                        );
                    }}
                    title={getCardTitle(
                      card as SearchDocument | QualityControlInstance | Document
                    )}
                  />
                ))}
              </Box>
            ) : null}
          </div>
        );
      })}
    </Styled.LinksContainer>
  );
};
