import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { Option } from '@pro4all/shared/types';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { isDefined } from '@pro4all/shared/utils';

import { HierarchyStyledLi } from './Styles';
import { TagSelect } from './TagSelect';

const exactMatchChar = '~`';

export const QCHierarchyFilter: React.FC<FilterBaseProps> = ({
  value,
  type,
  facetGroup,
  facetLoading,
  metaDataKey,
}) => {
  const { t } = useTranslation();

  const [showOnlyExactMatch, setShowOnlyExactMatch] = React.useState(false);

  const onChangeExactMatchHandler = () => {
    setShowOnlyExactMatch(!showOnlyExactMatch);
  };

  const options: Option[] =
    facetGroup?.items
      ?.filter(isDefined)
      .map((facetItem) => ({
        iconName: 'personOutline' as const,
        id: facetItem.value,
        inputValue: facetItem.value,
        label: facetItem.value.replace(exactMatchChar, '') || '',
      }))
      .filter((option) =>
        showOnlyExactMatch ? option.inputValue?.includes(exactMatchChar) : true
      ) || [];

  const labels = value
    ?.split(delimiters.multiSelectOptions)
    ?.map?.((label) => label?.replace?.(exactMatchChar, ''))
    ?.filter?.(Boolean);

  const initialOptions = options.filter((option) =>
    labels.includes(option.label)
  );

  return (
    <Box>
      <TagSelect
        initialOptions={initialOptions}
        loading={facetLoading}
        metaDataKey={metaDataKey}
        options={options}
        renderOption={(option) => {
          const nodeLabels = option.uiOption.label.split(' > ');

          return (
            <HierarchyStyledLi onClick={option.uiOption.onClick}>
              {nodeLabels?.map?.((item, index) => {
                const isLast =
                  nodeLabels.indexOf(item) === nodeLabels.length - 1;
                return (
                  <HierarchyLabel $isLast={isLast} key={index} variant="body1">
                    {item}
                    {isLast ? '' : ' > '}
                  </HierarchyLabel>
                );
              })}
            </HierarchyStyledLi>
          );
        }}
        type={type}
        value={value}
      />
      <Container>
        <Box>
          <FormControlLabelHierarchy
            control={
              <Checkbox
                checked={showOnlyExactMatch}
                onChange={onChangeExactMatchHandler}
              />
            }
            label={t('Exact match')}
          />
          <Box>
            <Tooltip
              placement="bottom"
              title={t(
                'While this option is checked, only exact matches will be shown. If you uncheck this option, everything that matches, or matches partially will be shown.'
              )}
            >
              <IconButton color="inherit" disableBorder iconName="info" />
            </Tooltip>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled(Box)`
  bottom: ${({ theme }) => theme.spacing(1)};
  left: ${({ theme }) => theme.spacing(2)};
  position: absolute;
  z-index: 1;
  & > div {
    align-items: center;
    display: flex;
  }
`;

const FormControlLabelHierarchy = styled(FormControlLabel)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const HierarchyLabel = styled(Typography)<{ $isLast?: boolean }>`
  display: inline;
  && {
    font-weight: ${({ $isLast }) => ($isLast ? 'bold' : 'regular')};
  }
`;
