import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const base = `${Routes.settingsOrganization}/`;

export const OrganizationOrganizationTabs: React.FC = () => {
  const { t } = useTranslation();
  const { hasAppPermission } = useAppPermissions();
  const canUpdateOrganizationSettings = hasAppPermission('OrganizationUpdate');
  const { userAuthType } = useOrganizationContext();

  return canUpdateOrganizationSettings ? (
    <SubTabs base={base}>
      <Tab
        data-testid="organization-settings-organization-tab"
        label={t('Organization')}
        value="organization"
      />
      <Tab
        data-testid="organization-settings-license-tab"
        label={t('License')}
        value="license"
      />
      <Tab
        data-testid="organization-settings-integrations-tab"
        label={t('Integrations')}
        value="integrations"
      />
      <Tab
        data-testid="organization-settings-documents-tab"
        label={t('Documents')}
        value="documents"
      />
      {userAuthType === AuthType.Sso && canUpdateOrganizationSettings && (
        <Tab
          data-testid="organization-settings-settings-tab"
          label={t('Security')}
          value="settings"
        />
      )}
    </SubTabs>
  ) : null;
};
