import { useRef, useState } from 'react';

import {
  Document,
  QcPermissionCategory,
  QualityControlInstance,
  Task,
  TaskType,
  VisualContext,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';

import { useCheckDisableStatus } from './useCheckDisableStatus';
import { useDocumentTaskFormConfig } from './useDocumentTaskFormConfig';
import { useGetAvailableStatuses } from './useGetAvailableStatuses';
import { useInitialValues } from './useInitialValues';
import { useSetDrawings } from './useSetDrawings';
import { useSubmit } from './useSubmit';
import { useTaskFormConfig } from './useTaskFormConfig';

export const useTaskFormHelpers = ({
  procedureId,
  task,
  taskType,
}: {
  procedureId?: string;
  task?: Task;
  taskType: TaskType;
}) => {
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { mainProcedureId } = useProjectContext();
  const { searchParams } = useRouting();
  const [linkInstances, setLinkInstances] = useState<
    QualityControlInstance[] | Document[]
  >([]);

  const {
    state: { item: copiedTask },
  } = useOptimisticResponseContext<Task>();

  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
  });

  const { hasQualityControlLicense, hasDmsLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);
  const inputRef = useRef(null);

  const routeCreate = searchParams.is('action', 'createTask');
  const documentTask = searchParams.is('taskType', 'Document');
  const resolveTask = searchParams.is('taskType', 'Resolve');
  const duplicate = searchParams.get('duplicate');
  const isEditMode = Boolean(task);

  const initialValues = useInitialValues({
    hasDmsLicense,
    hasQualityControlFeatureFlag,
    hasQualityControlLicense,
    task: task || (duplicate ? copiedTask : null),
    taskType,
  });

  const onSubmit = useSubmit({
    initialValues,
    linkInstances: linkInstances,
    procedureId: procedureId || mainProcedureId,
    task,
  });

  const { getField: getDocFormField, validationSchema: getDocValidation } =
    useDocumentTaskFormConfig();

  const { getField: getFormField, validationSchema: formValidationSchema } =
    useTaskFormConfig();

  const getField =
    taskType === TaskType.Document ? getDocFormField : getFormField;

  const validationSchema =
    taskType === TaskType.Document ? getDocValidation : formValidationSchema;

  const nameField = getField('name');
  const descriptionField = getField('description');
  const endTimeField = getField('endTime');
  const nextStatusField = getField('nextStatus');
  const formTemplatesField = getField('formTemplates');
  const snagTemplatesField = getField('snagTemplates');
  const visualContextsField = getField('visualContexts');

  const [drawings, setDrawings] = useState<VisualContext[]>([]);

  const { checkDisableStatus, setDeletedLinkedInstance } =
    useCheckDisableStatus({ linkInstances });

  useSetDrawings({ setDrawings, setLinkInstances, task, taskType });

  const { availableStatuses } = useGetAvailableStatuses({ task });

  return {
    availableStatuses,
    checkDisableStatus,
    descriptionField,
    documentTask,
    drawings,
    endTimeField,
    formTemplatesField,
    hasQualityControlFeatureFlag,
    hasQualityControlLicense,
    initialValues,
    inputRef,
    isEditMode,
    linkInstances,
    nameField,
    nextStatusField,
    onSubmit,
    permissions,
    resolveTask,
    routeCreate,
    setDeletedLinkedInstance,
    setDrawings,
    setLinkInstances,
    snagTemplatesField,
    validationSchema,
    visualContextsField,
  };
};
