import {
  FieldDefinition,
  Member,
  MeUser,
  Project,
  QualityControlInstance,
  QualityControlInstanceType,
  ReportConfiguration,
  Task,
  Template,
  ValueTypeName,
} from '@pro4all/graphql';

import { Option } from './types';

// Collection with props used for reporting. Specific types below pick their props from this collection.
// This removes the enormous amount of duplicate prop typing we had before.
export type ReportTypes = {
  activeReportConfiguration: ActiveReportConfigFormFields;
  add: boolean;
  background: string;
  backgroundFrontPage: string;
  bgId: string;
  bgIdFrontPage: string;
  borderRight?: boolean;
  checked: boolean;
  children: React.ReactNode;
  color: string;
  columns?: number;
  compactLists?: boolean;
  companyLogo: string;
  createReportConfiguration?: boolean;
  currentUser: MeUser;
  customMode?: boolean;
  date: Date;
  displayName: string;
  downloadPdfContext: boolean;
  elementIdentifier: string;
  enableCreatedBy: boolean;
  enableCreatedOn: boolean;
  enableEndDate: boolean;
  enableFrontPage: boolean;
  enableGeneratedWith: boolean;
  enableLogo: boolean;
  enableProjectDescription: boolean;
  enableProjectName: boolean;
  enableProjectNumber: boolean;
  enableStartDate: boolean;
  enableTitle: boolean;
  finalInstances: QualityControlInstance[];
  flattenedItem: FlattenedItem;
  footerText?: string;
  formTemplateIdsWithDuplicates: string[];
  formTemplateOptions: Option[];
  formTemplateOptionsAll: Option[];
  group: GroupedInstances;
  hasFirstPageBeenPrinted: boolean;
  hiddenSectionsAndFields: string[];
  html: string;
  id: string;
  ids: string[];
  imageColumn: keyof typeof ImageColumns;
  inlineLists?: boolean;
  instance: Task;
  instanceId: string;
  instances: QualityControlInstance[];
  instancesGroupedByDrawing: GroupedInstances[];
  instancesWithSignatures: InstanceWithSignature[];
  item: FieldDefinition;
  itemId: string;
  items: FieldDefinition[];
  labelWidth?: number;
  lat: number;
  level: number;
  linkedSnagInstances: QualityControlInstance[];
  linkedSnags: QualityControlInstance[];
  lng: number;
  logoId: string;
  mapHeight: number;
  mapInstances: QualityControlInstance[];
  mapWidth: number;
  mediaLoaded: boolean;
  members: Member[];
  miniMapHtml?: string;
  miniMaps?: MapElements;
  name: string;
  onClose?: () => void;
  onLoad: () => void;
  options?: ReportOptions;
  page: number;
  photoBlobs?: PhotoBlobs;
  preventPageBreak?: boolean;
  previewInstances: QualityControlInstance[];
  project: Project;
  reportConfiguration?: ReportConfiguration;
  reportConfigurationDummyTemplate?: Template;
  reportConfigurationDummyTemplates: Template[];
  reportConfigurationReportOptions: ReportOptions;
  reportConfigurationTemplate?: Template;
  reportConfigurationTemplates: Template[];
  reportConfigurations: ReportConfiguration[];
  reportDrawingHtml?: string;
  reportDrawings?: MapElements;
  reportOptions: ReportOptions;
  selectStyleTemplate: (option: Option) => void;
  setActiveReportConfiguration: (payload: ActiveReportConfigFormFields) => void;
  setAsDefault: boolean;
  setBackground: (id: string) => void;
  setBackgroundFrontPage: (id: string) => void;
  setHiddenSectionsAndFields: (payload: PayloadHiddenSectionsAndFields) => void;
  setLogo: (id: string) => void;
  setMiniMaps: React.Dispatch<React.SetStateAction<MapElements | undefined>>;
  setReportConfigurations: (payload: ReportConfiguration[]) => void;
  setReportDrawings: React.Dispatch<
    React.SetStateAction<MapElements | undefined>
  >;
  setReportOptions: (reportOptions: ReportOptions) => void;
  setTemplate: (value: Option) => void;
  setTemplateOptions: (templateId: string, options: TemplateOptions) => void;
  showDescription: boolean;
  showEmptyAnswers: boolean;
  showLinkedSnags: boolean;
  showPageNumbers: boolean;
  signatureId: string;
  signatureUrl: string;
  signatures?: SignatureObject[];
  snagTemplateIdsWithDuplicates: string[];
  snagTemplateOptions: Option[];
  snagTemplateOptionsAll: Option[];
  storeOnOrganizationLevel: boolean;
  taskInstanceIds: string[] | undefined;
  tasks?: Task[];
  template: Option;
  templateId: string;
  templateOptions: TemplateOptions;
  templates?: Record<string, TemplateOptions>;
  toggleCustomMode: (force?: boolean) => void;
  token: string;
  type: ValueTypeName;
  unnestedItems: FieldDefinition[];
  user: MeUser;
  values: string[];
  visualContextId: string;
  visualContextName: string;
  zoomLevels?: number;
};

export enum ImageColumns {
  aOne = 'One',
  bTwo = 'Two',
  cThree = 'Three',
  dFour = 'Four',
}

export type PhotoBlobs = Record<string, string> | null;
export type MapElements = Record<string, string>;
export type ColumnsMap = {
  [key in keyof typeof ImageColumns]: number;
};

export type InstanceWithSignature = Pick<
  ReportTypes,
  'instanceId' | 'itemId' | 'signatureId'
>;

export type SignatureObject = Pick<
  ReportTypes,
  'instanceId' | 'itemId' | 'signatureId' | 'signatureUrl'
>;

export type ReactReportProps = Pick<
  ReportTypes,
  | 'background'
  | 'backgroundFrontPage'
  | 'companyLogo'
  | 'currentUser'
  | 'customMode'
  | 'finalInstances'
  | 'members'
  | 'miniMaps'
  | 'photoBlobs'
  | 'project'
  | 'reportDrawings'
  | 'reportOptions'
  | 'tasks'
> & { signatures: SignatureObject[] };

export type GroupedInstances = Pick<
  ReportTypes,
  'instances' | 'page' | 'visualContextId' | 'visualContextName'
>;

export type ReportProps = Pick<
  ReportTypes,
  | 'companyLogo'
  | 'currentUser'
  | 'date'
  | 'downloadPdfContext'
  | 'instancesGroupedByDrawing'
  | 'members'
  | 'miniMaps'
  | 'photoBlobs'
  | 'project'
  | 'reportDrawings'
  | 'reportConfigurationDummyTemplates'
  | 'reportOptions'
  | 'signatures'
  | 'tasks'
>;

export type LayoutProps = Pick<
  ReportTypes,
  | 'borderRight'
  | 'columns'
  | 'compactLists'
  | 'inlineLists'
  | 'labelWidth'
  | 'preventPageBreak'
>;

export interface ReportOptions
  extends LayoutProps,
    Pick<
      ReportTypes,
      | 'bgId'
      | 'bgIdFrontPage'
      | 'enableCreatedBy'
      | 'enableCreatedOn'
      | 'enableEndDate'
      | 'enableFrontPage'
      | 'enableGeneratedWith'
      | 'enableLogo'
      | 'enableProjectDescription'
      | 'enableProjectName'
      | 'enableProjectNumber'
      | 'enableStartDate'
      | 'enableTitle'
      | 'footerText'
      | 'logoId'
      | 'showEmptyAnswers'
      | 'showPageNumbers'
      | 'templates'
    > {}

export type TemplateOptions = Pick<
  ReportTypes,
  | 'hiddenSectionsAndFields'
  | 'imageColumn'
  | 'showDescription'
  | 'showLinkedSnags'
>;

export type ReportConfigTypes = Pick<
  ReportTypes,
  | 'checked'
  | 'elementIdentifier'
  | 'flattenedItem'
  | 'formTemplateOptions'
  | 'formTemplateOptionsAll'
  | 'hiddenSectionsAndFields'
  | 'id'
  | 'items'
  | 'level'
  | 'onClose'
  | 'previewInstances'
  | 'reportOptions'
  | 'setHiddenSectionsAndFields'
  | 'setTemplate'
  | 'setTemplateOptions'
  | 'snagTemplateOptions'
  | 'snagTemplateOptionsAll'
  | 'template'
> & { templateOptions: Option[] };

export type FlattenedItem = Pick<
  ReportTypes,
  'displayName' | 'id' | 'level' | 'name' | 'type'
>;

export type PayloadHiddenSectionsAndFields = Pick<
  ReportTypes,
  'add' | 'ids' | 'templateId'
>;

export type ReportDrawingProps = Pick<
  ReportTypes,
  'instances' | 'page' | 'visualContextId'
>;

export type ReportDrawingContentsProps = Pick<
  ReportTypes,
  | 'children'
  | 'mapHeight'
  | 'mapWidth'
  | 'page'
  | 'visualContextId'
  | 'zoomLevels'
>;

export interface StrictInstanceType
  extends Omit<QualityControlInstance, 'templateId'>,
    Pick<ReportTypes, 'templateId'> {}

export type ReportMapContentsProps = Pick<
  ReportTypes,
  | 'children'
  | 'lat'
  | 'lng'
  | 'mapHeight'
  | 'mapWidth'
  | 'page'
  | 'visualContextId'
  | 'zoomLevels'
> & { type: QualityControlInstanceType };

export type ReportMiniMapProps = QualityControlInstance;

export type ReportOptionsContextValue = Pick<
  ReportTypes,
  | 'activeReportConfiguration'
  | 'miniMaps'
  | 'reportDrawings'
  | 'reportOptions'
  | 'selectStyleTemplate'
  | 'setActiveReportConfiguration'
  | 'setBackground'
  | 'setBackgroundFrontPage'
  | 'setHiddenSectionsAndFields'
  | 'setLogo'
  | 'setMiniMaps'
  | 'setReportConfigurations'
  | 'setReportDrawings'
  | 'setReportOptions'
  | 'setTemplateOptions'
  | 'toggleCustomMode'
> & { customMode: boolean };

export type ReportPreviewProps = Pick<
  ReportTypes,
  | 'currentUser'
  | 'date'
  | 'instances'
  | 'instancesGroupedByDrawing'
  | 'mapInstances'
  | 'members'
  | 'project'
  | 'reportConfiguration'
  | 'reportConfigurationReportOptions'
  | 'reportConfigurationDummyTemplates'
  | 'tasks'
>;

export type DrawingReportMapsProps = {
  instancesGroupedByDrawing: GroupedInstances[];
  onLoad?: () => void;
};

export type FetchArgs = { id: string; instanceId: string };

export type ReportMiniMapsProps = Pick<ReportTypes, 'instances'>;

export type FetchSignatureReportsProps = Pick<
  ReportTypes,
  'instancesWithSignatures'
>;

export type ReportRendererProps = Pick<
  ReportTypes,
  'html' | 'id' | 'mediaLoaded'
>;

export type ReportImagesProps = Pick<
  ReportTypes,
  | 'photoBlobs'
  | 'reportConfigurationDummyTemplate'
  | 'reportOptions'
  | 'templateId'
  | 'values'
>;

export type SignatureReportsProps = Pick<
  ReportTypes,
  'reportConfigurationDummyTemplate' | 'signatureUrl'
>;

export type QCTaskResourcesProps = Pick<ReportTypes, 'instance'>;

export type ResolveTaskResourcesProps = Pick<ReportTypes, 'instance'>;

export type LinkedSnagsListProps = Pick<ReportTypes, 'linkedSnags'>;

export type ReportDescriptionFieldProps = Pick<ReportTypes, 'item'>;

export type ReportHierarchyFieldProps = Pick<
  ReportTypes,
  'item' | 'linkedSnags' | 'templateOptions'
>;

export type ReportMediaFieldProps = Pick<
  ReportTypes,
  | 'item'
  | 'linkedSnags'
  | 'photoBlobs'
  | 'reportConfigurationDummyTemplate'
  | 'reportOptions'
  | 'templateId'
  | 'templateOptions'
>;

export type ReportSelectionFieldProps = Pick<
  ReportTypes,
  'item' | 'linkedSnags' | 'reportOptions' | 'templateOptions'
>;

export type ReportSignatureFieldProps = Pick<
  ReportTypes,
  | 'item'
  | 'linkedSnags'
  | 'reportConfigurationDummyTemplate'
  | 'signatureUrl'
  | 'signatures'
  | 'templateOptions'
>;

export type ReportStatusFieldProps = Pick<
  ReportTypes,
  | 'background'
  | 'color'
  | 'item'
  | 'linkedSnags'
  | 'options'
  | 'templateOptions'
>;

export type ReportStopwatchFieldProps = Pick<
  ReportTypes,
  'item' | 'linkedSnags' | 'options' | 'templateOptions'
>;

export type InstancePageSectionProps = Pick<
  ReportTypes,
  | 'instanceId'
  | 'items'
  | 'linkedSnagInstances'
  | 'members'
  | 'photoBlobs'
  | 'reportConfigurationDummyTemplate'
  | 'reportOptions'
  | 'signatures'
  | 'templateId'
  | 'unnestedItems'
> & { level?: number };

export type DrawingPageProps = Pick<
  ReportTypes,
  'group' | 'hasFirstPageBeenPrinted' | 'reportDrawingHtml' | 'reportOptions'
>;

export type FrontPageProps = Pick<
  ReportTypes,
  'companyLogo' | 'date' | 'project' | 'reportOptions' | 'user'
>;

export type InstancePageProps = Pick<
  ReportTypes,
  | 'hasFirstPageBeenPrinted'
  | 'members'
  | 'miniMapHtml'
  | 'photoBlobs'
  | 'reportConfigurationDummyTemplate'
  | 'reportDrawingHtml'
  | 'reportOptions'
  | 'signatures'
> & { instance?: QualityControlInstance };

export type InstancePageHeaderProps = Pick<
  ReportTypes,
  'instanceId' | 'items' | 'reportOptions'
>;

export type TaskInstancePageProps = Pick<
  ReportTypes,
  'hasFirstPageBeenPrinted' | 'instance' | 'reportOptions'
>;

export type ReportConfigFormFields = Pick<
  ReportTypes,
  'name' | 'setAsDefault' | 'storeOnOrganizationLevel'
>;

export type ActiveReportConfigFormFields = Pick<
  ReportTypes,
  'id' | 'name' | 'setAsDefault' | 'storeOnOrganizationLevel'
>;

export type ReportDataProps = Pick<
  ReportTypes,
  'instances' | 'taskInstanceIds'
>;

export type TranslateInstancesProps = Pick<ReportTypes, 'instances'>;

export type SetReportConfigurationReportOptionsProps = Pick<
  ReportTypes,
  'reportConfiguration' | 'reportConfigurationReportOptions'
>;

export type GetReportConfigurationReportOptionsProps = Pick<
  ReportTypes,
  'createReportConfiguration' | 'reportConfigurationReportOptions'
>;

export type InjectDummyDataProps = Pick<ReportTypes, 'items'>;

export type SetDummyDataPerFieldTypeProps = Pick<ReportTypes, 'type'>;

export type ReportingMainProps = Pick<
  ReportTypes,
  'createReportConfiguration' | 'reportConfiguration'
> & { reportConfigurations?: ReportConfiguration[] };

export type GetTemplateOptionsProps = Pick<
  ReportTypes,
  | 'formTemplateOptions'
  | 'formTemplateIdsWithDuplicates'
  | 'snagTemplateOptions'
  | 'snagTemplateIdsWithDuplicates'
>;

export type ReportUserSelectionFieldProps = Pick<
  ReportTypes,
  'item' | 'linkedSnags' | 'members' | 'options' | 'templateOptions'
>;

export type DownloadReportButtonProps = Pick<ReportTypes, 'members' | 'tasks'>;

export type UpdateOtherReportConfigurationsProps = Pick<
  ReportTypes,
  'reportConfigurations' | 'setAsDefault' | 'storeOnOrganizationLevel'
>;

export type GetReportImageUrlsProps = Pick<
  ReportTypes,
  'reportOptions' | 'token'
>;

export type ReportConfigurationSidebarProps = Pick<
  ReportTypes,
  'reportConfigurations'
>;

export type ReportOptionsFormProps = Pick<
  ReportTypes,
  | 'formTemplateOptions'
  | 'formTemplateOptionsAll'
  | 'reportConfigurations'
  | 'snagTemplateOptions'
  | 'snagTemplateOptionsAll'
>;

export type ReportConfigurationsProps = Pick<
  ReportTypes,
  'reportConfigurations'
>;
