import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LockType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSessionStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';
import {
  OFFICE_ICON_BY_EXTENSION,
  VALID_OFFICE_EXTENSIONS,
} from '@pro4all/shared/utils';

import { DocumentActionProps } from '../../types';

import { useGetOfficeOnlineInfo } from './useGetOfficeOnlineInfo';
import { useOnClickOnlineOffice } from './useOnClickOnlineOffice';

export const useOpenOfficeAction = ({
  allDocumentsUnfinalized,
  editableDocuments,
  isLocked,
  selection,
  uncheckAllRows,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsUnfinalized'
  | 'editableDocuments'
  | 'isLocked'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const extension = selection[0]?.extension?.replace('.', '') || '';
  const isOfficeExtension = VALID_OFFICE_EXTENSIONS.includes(extension);
  const hasReadOnlyFlag = useFeatureFlag('office-read-only');
  const { lockType, versionId } = selection[0] || {};

  const { hideOficeAction, officeActionUrl } = useGetOfficeOnlineInfo({
    isOfficeExtension,
    selection,
  });

  const { setSessionStorageItem } = useSessionStorage({
    key: `online-office-url-${versionId}`,
  });

  useEffect(() => {
    setSessionStorageItem(officeActionUrl); // Used in the office host page.
  }, [officeActionUrl, setSessionStorageItem]);

  const openOfficeOnline = useOnClickOnlineOffice({
    extension,
    selection,
  });

  const openWarningModal = () => {
    searchParams.set({
      action: 'officeonline',
      documentId: selection[0].id,
    });
  };

  const getLabel = () => {
    const label =
      extension.length > 0 && OFFICE_ICON_BY_EXTENSION[extension]
        ? OFFICE_ICON_BY_EXTENSION[extension]
        : 'Unknown';
    return t('Open in {{name}} for the web', {
      name: label.charAt(0).toUpperCase() + label.slice(1),
    });
  };

  const currentOpenOfficeValidation = !hasReadOnlyFlag
    ? editableDocuments.length === 0 || !allDocumentsUnfinalized
    : hideOficeAction &&
      (editableDocuments.length === 0 || !allDocumentsUnfinalized);

  const openOfficeAction: ActionProps = {
    ariaLabel: getLabel(),
    dataTestId: 'open-office',
    disabled:
      isLocked ||
      selection[0]?.isExpected ||
      !isOfficeExtension ||
      selection.length > 1 ||
      currentOpenOfficeValidation,
    key: 'open-office',
    label: getLabel(),
    onClick: () => {
      if (lockType === LockType.OfficeOnline) {
        openWarningModal();
        uncheckAllRows();
      } else {
        openOfficeOnline();
        uncheckAllRows();
      }
    },
    startIcon: OFFICE_ICON_BY_EXTENSION[extension] as IconName,
  };

  return openOfficeAction;
};
