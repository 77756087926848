import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { ApiConfig } from '@pro4all/shared/config';
import { useCollapseButtons } from '@pro4all/shared/contexts';
import { SnagActionButtons } from '@pro4all/shared/qc-sources/snag-action-buttons';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import {
  Collapsible,
  RenderWhenVisible,
  useToggle,
} from '@pro4all/shared/ui/general';

import * as Styled from './Section.styles';

export interface Props {
  bgColor?: string;
  defaultOpen?: boolean;
  hoverColor?: string;
  id?: string;
  level?: number;
  rounded?: boolean;
  sectionAnsweredQuestionsCount?: string;
  showBorder?: boolean;
  showContentGuides?: boolean;
  storeCollapseState?: boolean;
  title: React.ReactNode;
}

type SectionSave = {
  date: number;
  id: string;
  sectionIds: string[];
};

export const Section: React.FC<Props> = ({
  bgColor = customColors.grey300,
  hoverColor,
  showBorder = false,
  id = uuid(),
  defaultOpen = false,
  rounded = false,
  showContentGuides = false,
  storeCollapseState = false,
  level = 0,
  title,
  children,
  sectionAnsweredQuestionsCount,
  ...rest
}) => {
  //This log is left here on purpose, to ensure the circular dependency caused by the snagactionbuttons is resolved. It will be removed as soon as we built the actual feature.
  console.log(SnagActionButtons, '');
  const storageKey = 'p4a:ps:open-sections';
  const { searchParams } = useRouting();
  const keyId = searchParams.get('id');
  const { collapseLevel } = useCollapseButtons();
  const { toggled, toggle } = useToggle(shouldBeOpen(keyId, id, defaultOpen));

  useEffect(() => {
    if (!collapseLevel.initial) {
      const newState = collapseLevel.level > level;
      toggle(newState);
      saveToggle(newState);
    }
  }, [collapseLevel]);

  function shouldBeOpen(
    instanceId: string | null,
    sectionId: string,
    defaultValue: boolean
  ) {
    if (!keyId || !storeCollapseState) return defaultValue;

    const storageData = JSON.parse(
      localStorage.getItem(storageKey) ?? '[]'
    ) as SectionSave[];
    const savedInstance = storageData.find((d) => d.id === instanceId);
    const sectionFound = savedInstance?.sectionIds.find((x) => x === sectionId);

    return sectionFound ? !defaultValue : defaultValue;
  }

  const saveToggle = function (state: boolean) {
    if (keyId && storeCollapseState) {
      let storageData = JSON.parse(
        localStorage.getItem(storageKey) ?? '[]'
      ) as SectionSave[];
      const savedInstance = storageData.find((d) => d.id === keyId);

      if (!savedInstance) {
        storageData.push({
          date: Date.now(),
          id: keyId,
          sectionIds: [id] as string[],
        });
      } else {
        savedInstance.date = Date.now();

        if (state !== defaultOpen) {
          // In TBQ, defaultOpen is true.
          // In QCS, defaultOpen is false.
          // Only save non default sections.
          savedInstance.sectionIds.push(id);
          savedInstance.sectionIds = [...new Set(savedInstance.sectionIds)];
        } else {
          savedInstance.sectionIds = savedInstance.sectionIds.filter(
            (x) => x !== id
          );
        }
      }

      // Limit saved instances
      storageData = storageData
        .sort((x, y) => y.date - x.date)
        .slice(0, ApiConfig.instanceSectionSaveCount);

      localStorage.setItem(storageKey, JSON.stringify(storageData));
    }
  };

  return (
    <Styled.Section id={`section-${id}`} {...rest}>
      <Styled.Header
        $bgColor={bgColor}
        $hoverColor={hoverColor || ''}
        $rounded={rounded}
        $showBorder={showBorder}
        id={`section-heading-${id}`}
      >
        <Styled.Toggle
          aria-controls={`section-content-${id}`}
          aria-label={`${toggled ? 'Close' : 'Open'} ${title}`}
          checked={toggled}
          id={`section-toggle-${id}`}
          onChange={() => {
            saveToggle(!toggled);
            toggle();
          }}
        >
          {title}
          {sectionAnsweredQuestionsCount && (
            <>{' (' + sectionAnsweredQuestionsCount + ')'}</>
          )}
        </Styled.Toggle>
        {/* <SnagActionButtons fieldId={id} isDirty={false} /> */}
      </Styled.Header>

      <RenderWhenVisible>
        <Collapsible
          component={showContentGuides ? Styled.ContentWrapper : undefined}
          id={`section-content-${id}`}
          open={toggled}
        >
          {/*<Delayed>*/}
          {children}
          {/*</Delayed>*/}
        </Collapsible>
      </RenderWhenVisible>
    </Styled.Section>
  );
};
