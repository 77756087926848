/* This file is generated automatically with GraphQL Code Generator. Do not edit directly. See readme for more info. */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Long: any;
  UInt: any;
  Uri: any;
};

export type AddExternalUsersResponse = Response & {
  __typename?: 'AddExternalUsersResponse';
  projectId: Scalars['ID'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type AddSignatureResponse = {
  __typename?: 'AddSignatureResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type AiSummary = {
  __typename?: 'AiSummary';
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Annotation = {
  __typename?: 'Annotation';
  versionId: Scalars['ID'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  body: Scalars['JSON'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['ID']>;
};

export type Answer = {
  fieldId: Scalars['ID'];
  value: Scalars['String'];
};

export type AnswerType = {
  __typename?: 'AnswerType';
  fieldId: Scalars['ID'];
  templateId?: Maybe<Scalars['ID']>;
  templateVersion?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type AppData = {
  __typename?: 'AppData';
  fieldId?: Maybe<Scalars['ID']>;
};

export type AppDataInput = {
  sectionId?: Maybe<Scalars['ID']>;
};

export type ApplicationInput = {
  name: Scalars['String'];
  integrationType: IntegrationType;
};

export type Attachment = {
  __typename?: 'Attachment';
  fileId: Scalars['ID'];
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['Long']>;
  documentId?: Maybe<Scalars['String']>;
  documentVersionId?: Maybe<Scalars['String']>;
};

export enum AttachmentContext {
  Msg = 'Msg',
  Comm = 'Comm'
}

export type AttachmentInput = {
  fileId: Scalars['ID'];
  fileName: Scalars['String'];
  fileSize?: Maybe<Scalars['Long']>;
  documentId?: Maybe<Scalars['String']>;
  documentVersionId?: Maybe<Scalars['String']>;
};

export enum AuthType {
  Sso = 'SSO',
  UserPassword = 'UserPassword'
}

export type BatchDeleteTaskCategoryResponse = Response & {
  __typename?: 'BatchDeleteTaskCategoryResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BatchDocumentsResponse = Response & {
  __typename?: 'BatchDocumentsResponse';
  succeededIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success: Scalars['Boolean'];
};

export type BatchMutation = {
  __typename?: 'BatchMutation';
  successful?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unsuccessful?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type BatchProjectsResponse = Response & {
  __typename?: 'BatchProjectsResponse';
  succeededIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success: Scalars['Boolean'];
};

export type BatchResult = {
  __typename?: 'BatchResult';
  successful?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unsuccessful?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type BatchTaskCategoryResponse = Response & {
  __typename?: 'BatchTaskCategoryResponse';
  ids: Array<Scalars['String']>;
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BatchUpdateTaskCategoryResponse = Response & {
  __typename?: 'BatchUpdateTaskCategoryResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Build12Connection = {
  __typename?: 'Build12Connection';
  connectionId: Scalars['String'];
  connectionName: Scalars['String'];
  phaseName: Scalars['String'];
};

export enum Build12State {
  NotShared = 'notShared',
  PreviousVersionShared = 'previousVersionShared',
  Shared = 'shared'
}

export type CancelSubscriptionDataResponse = {
  __typename?: 'CancelSubscriptionDataResponse';
  id: Scalars['ID'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type ChangeSubscriptionDataResponse = {
  __typename?: 'ChangeSubscriptionDataResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  mollieRedirect?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ClientDownloadUrls = {
  __typename?: 'ClientDownloadUrls';
  downloadUrlMac: Scalars['String'];
  downloadUrlWindows: Scalars['String'];
};

export type CommandOutput = {
  __typename?: 'CommandOutput';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  commandId: Scalars['String'];
  issuedBy: Scalars['String'];
  issuedAt: Scalars['String'];
  clientUrl: Scalars['String'];
  jobs: Array<JobOutput>;
  status: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CommandStatus = {
  __typename?: 'CommandStatus';
  status: Scalars['Int'];
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CommentStatus {
  LatestContainsUnread = 'latestContainsUnread',
  OldContainsUnread = 'oldContainsUnread',
  AllCommentsRead = 'allCommentsRead'
}

export type CommentThread = {
  __typename?: 'CommentThread';
  id: Scalars['ID'];
  targetId: Scalars['ID'];
  messages?: Maybe<Array<Message>>;
};

export type Condition = {
  __typename?: 'Condition';
  fieldId: Scalars['ID'];
  value: Scalars['String'];
};

export type ConfigDataSegment = {
  __typename?: 'ConfigDataSegment';
  id: Scalars['ID'];
  order: Scalars['Int'];
  type: SegmentType;
};

export type ConfigDataSegmentInput = {
  id: Scalars['ID'];
  order: Scalars['Int'];
  type: SegmentType;
};

export type ConnectProjectResponse = {
  __typename?: 'ConnectProjectResponse';
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  connectionName: Scalars['String'];
};

export type Connection = {
  __typename?: 'Connection';
  connectionName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  integrationType: IntegrationType;
  projectId: Scalars['ID'];
};

export enum ConversionStatus {
  NotStarted = 'notStarted',
  Pending = 'pending',
  Inprogress = 'inprogress',
  Success = 'success',
  Failed = 'failed',
  Uploading = 'uploading'
}

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type CountryDataResponse = {
  __typename?: 'CountryDataResponse';
  countries: Array<Country>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type CreateCommandInput = {
  documentId: Scalars['String'];
  jobs: Array<JobInput>;
};

export type CreateShareLinkResponse = Response & {
  __typename?: 'CreateShareLinkResponse';
  id: Scalars['ID'];
  link: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type CustomConnectionProps = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type CustomIntegrationProps = {
  apiKey?: Maybe<Scalars['String']>;
  environmentUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type DeleteInstanceResponse = {
  __typename?: 'DeleteInstanceResponse';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  instanceId?: Maybe<Scalars['String']>;
};

export enum Direction {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  BiDirectional = 'biDirectional'
}

export enum DirectionType {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  BiDirectional = 'biDirectional'
}

export type Document = {
  __typename?: 'Document';
  amountOfVersions?: Maybe<Scalars['Int']>;
  commentStatus?: Maybe<CommentStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  downloadName?: Maybe<Scalars['String']>;
  expectedExtension?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  folder?: Maybe<Folder>;
  folderId?: Maybe<Scalars['ID']>;
  folderPermissions?: Maybe<Array<Maybe<FolderPermission>>>;
  hasPreviousVersionQr?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  isExpected?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Maybe<Keyword>>>;
  lastUpdated?: Maybe<Scalars['DateTime']>;
  lockType?: Maybe<LockType>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedBy?: Maybe<User>;
  metaData?: Maybe<DocumentMetaData>;
  metadataInstanceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  nameOriginal?: Maybe<Scalars['String']>;
  newDocumentId?: Maybe<Scalars['ID']>;
  newVersionFor?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['String']>;
  pathIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectId?: Maybe<Scalars['ID']>;
  qrCodeState?: Maybe<StampStatus>;
  state?: Maybe<FinalizationState>;
  stateBuild12?: Maybe<StateBuild12>;
  stateSnagstream?: Maybe<StateSnagstream>;
  summary?: Maybe<AiSummary>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  taskLinks?: Maybe<Array<TaskLink>>;
  threeDConversionState?: Maybe<ThreeDConversionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  versionId?: Maybe<Scalars['ID']>;
  versionNumber?: Maybe<Scalars['Int']>;
  versionState?: Maybe<DocumentVersionState>;
  versions?: Maybe<Array<Maybe<DocumentVersion>>>;
  visualContext?: Maybe<VisualContext>;
};

export enum DocumentAction {
  ToUpload = 'ToUpload',
  ForApproval = 'ForApproval',
  ForReview = 'ForReview',
  ForInformation = 'ForInformation',
  OnRequest = 'OnRequest',
  InformationRequest = 'InformationRequest',
  ProcessComments = 'ProcessComments',
  ForSigning = 'ForSigning',
  ForQrStamp = 'ForQrStamp',
  Other = 'Other'
}

export type DocumentCreate = {
  documentId?: Maybe<Scalars['ID']>;
  expectedExtension?: Maybe<Scalars['String']>;
  folderId: Scalars['String'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
};

export type DocumentDigest = {
  __typename?: 'DocumentDigest';
  documentDigestId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DocumentDigestUser = {
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  isInvitationFinalized?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  lastUpdatedById?: Maybe<Scalars['ID']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedOn?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
};

export enum DocumentEventType {
  AnnotationAddedToVersionEvent = 'AnnotationAddedToVersionEvent',
  AnnotationDeletedEvent = 'AnnotationDeletedEvent',
  AnswerAddedEvent = 'AnswerAddedEvent',
  AnswerUpdatedEvent = 'AnswerUpdatedEvent',
  AnswersAddedEvent = 'AnswersAddedEvent',
  DefaultEvent = 'DefaultEvent',
  DocumentAddedEvent = 'DocumentAddedEvent',
  DocumentCopiedEvent = 'DocumentCopiedEvent',
  DocumentDeletedEvent = 'DocumentDeletedEvent',
  DocumentDownloadedByWebEvent = 'DocumentDownloadedByWebEvent',
  DocumentDownloadedBySyncEvent = 'DocumentDownloadedBySyncEvent',
  DocumentDownloadedByShareEvent = 'DocumentDownloadedByShareEvent',
  DocumentFinalizedEvent = 'DocumentFinalizedEvent',
  DocumentLockedEvent = 'DocumentLockedEvent',
  DocumentMovedEvent = 'DocumentMovedEvent',
  DocumentRenamedEvent = 'DocumentRenamedEvent',
  DocumentRestoredEvent = 'DocumentRestoredEvent',
  DocumentUnfinalizedEvent = 'DocumentUnfinalizedEvent',
  DocumentUnlockedEvent = 'DocumentUnlockedEvent',
  HyperlinkCreatedEvent = 'HyperlinkCreatedEvent',
  HyperlinkRevokedEvent = 'HyperlinkRevokedEvent',
  MessageAddedEvent = 'MessageAddedEvent',
  MetadataInstanceAssignedEvent = 'MetadataInstanceAssignedEvent',
  QrStampAddedToVersionEvent = 'QrStampAddedToVersionEvent',
  StampMovedEvent = 'StampMovedEvent',
  StampRemovedEvent = 'StampRemovedEvent',
  TagAddedEvent = 'TagAddedEvent',
  TagRemovedEvent = 'TagRemovedEvent',
  ThumbnailAddedToVersionEvent = 'ThumbnailAddedToVersionEvent',
  Updated3DStatusEvent = 'Updated3DStatusEvent',
  VersionAddedEvent = 'VersionAddedEvent',
  VersionApprovedEvent = 'VersionApprovedEvent',
  VersionDeletedEvent = 'VersionDeletedEvent',
  VersionIntegrationInstanceAddedEvent = 'VersionIntegrationInstanceAddedEvent',
  VersionMigratedEvent = 'VersionMigratedEvent',
  VersionRejectedEvent = 'VersionRejectedEvent',
  VersionUnApprovedEvent = 'VersionUnApprovedEvent',
  VersionUnRejectedEvent = 'VersionUnRejectedEvent'
}

export type DocumentHistory = {
  __typename?: 'DocumentHistory';
  today?: Maybe<Array<DocumentHistoryEvent>>;
  thisWeek?: Maybe<Array<DocumentHistoryEvent>>;
  thisMonth?: Maybe<Array<DocumentHistoryEvent>>;
  older?: Maybe<Array<DocumentHistoryEvent>>;
};

export type DocumentHistoryEvent = {
  __typename?: 'DocumentHistoryEvent';
  changeDate?: Maybe<Scalars['DateTime']>;
  commandId?: Maybe<Scalars['ID']>;
  historyId?: Maybe<Scalars['ID']>;
  name?: Maybe<DocumentEventType>;
  properties?: Maybe<Array<DocumentHistoryProperty>>;
  userId: Scalars['ID'];
  resourceId?: Maybe<Scalars['ID']>;
  versionId?: Maybe<Scalars['ID']>;
  versionNumber?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type DocumentHistoryProperty = {
  __typename?: 'DocumentHistoryProperty';
  addedValues?: Maybe<Array<Scalars['String']>>;
  addedResolvedValues?: Maybe<Array<Scalars['String']>>;
  changeDate?: Maybe<Scalars['DateTime']>;
  deletedValues?: Maybe<Array<Scalars['String']>>;
  deletedResolvedValues?: Maybe<Array<Scalars['String']>>;
  resourceId: Scalars['ID'];
  historyId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DocumentPropertyType>;
};

export type DocumentIdVersionId = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type DocumentKeyword = {
  oldScore?: Maybe<Scalars['Int']>;
  newScore?: Maybe<Scalars['Int']>;
  oldText?: Maybe<Scalars['String']>;
  newText?: Maybe<Scalars['String']>;
};

export type DocumentMetaData = {
  __typename?: 'DocumentMetaData';
  answers?: Maybe<Array<Maybe<Instance>>>;
  templateId?: Maybe<Scalars['ID']>;
  template?: Maybe<Template>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export enum DocumentPropertyType {
  Annotation = 'Annotation',
  Body = 'Body',
  Build12ProjectName = 'Build12ProjectName',
  Build12ProjectPhaseName = 'Build12ProjectPhaseName',
  Bool = 'Bool',
  Connection = 'Connection',
  DateTime = 'DateTime',
  ExpectedExtension = 'ExpectedExtension',
  ExpireAt = 'ExpireAt',
  Extension = 'Extension',
  File = 'File',
  FileSize = 'FileSize',
  Folder = 'Folder',
  IntegrationName = 'IntegrationName',
  LinkId = 'LinkId',
  Metadata = 'Metadata',
  Name = 'Name',
  Number = 'Number',
  PasswordProtected = 'PasswordProtected',
  PrivateShare = 'PrivateShare',
  PublicShare = 'PublicShare',
  ProjectScopeShare = 'ProjectScopeShare',
  Organization = 'Organization',
  OrganizationScopeShare = 'OrganizationScopeShare',
  Project = 'Project',
  Selection = 'Selection',
  Stamp = 'Stamp',
  Status = 'Status',
  Status3D = 'Status3D',
  Tag = 'Tag',
  Text = 'Text',
  Thumbnail = 'Thumbnail',
  Unknown = 'Unknown',
  UploadedByClient = 'UploadedByClient',
  VersionNumber = 'VersionNumber',
  Urn = 'Urn',
  User = 'User',
  Group = 'Group',
  ProjectGroup = 'ProjectGroup'
}

export type DocumentResponse = Response & {
  __typename?: 'DocumentResponse';
  id?: Maybe<Scalars['ID']>;
  document?: Maybe<Document>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type DocumentUpdate = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  commentStatus?: Maybe<CommentStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  documentId: Scalars['ID'];
  downloadName?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Long']>;
  folderId?: Maybe<Scalars['ID']>;
  hasPreviousVersionQr?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isExpected?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Array<Maybe<Keyword>>>;
  md5Hash?: Maybe<Scalars['String']>;
  metaData?: Maybe<DocumentMetaData>;
  metadataInstanceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  pathIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectId?: Maybe<Scalars['ID']>;
  qrCodeState?: Maybe<StampStatus>;
  stamps?: Maybe<Array<Maybe<Stamp>>>;
  state?: Maybe<FinalizationState>;
  stateBuild12?: Maybe<StateBuild12>;
  stateSnagstream?: Maybe<StateSnagstream>;
  summary?: Maybe<AiSummary>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  threeDConversionState?: Maybe<ThreeDConversionStatus>;
  totalPreviewPages?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  versionId?: Maybe<Scalars['ID']>;
  versionNumber?: Maybe<Scalars['Int']>;
  versionState?: Maybe<DocumentVersionState>;
  versions?: Maybe<Array<Maybe<DocumentVersion>>>;
  visualContext?: Maybe<VisualContext>;
};

export type DocumentVersionMetadataMapping = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
  metadataInstanceId: Scalars['ID'];
};

export enum DocumentVersionState {
  None = 'none',
  Rejected = 'rejected',
  Approved = 'approved'
}

export type DocumentsResponse = Response & {
  __typename?: 'DocumentsResponse';
  documentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type DownloadFilenameCharacter = {
  __typename?: 'DownloadFilenameCharacter';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DownloadFilenameConfig = {
  __typename?: 'DownloadFilenameConfig';
  enabled: Scalars['Boolean'];
  segments: Array<Maybe<ConfigDataSegment>>;
};

export type DownloadFilenameConfigInput = {
  enabled: Scalars['Boolean'];
  segments: Array<Maybe<ConfigDataSegmentInput>>;
};

export type Drawing = {
  __typename?: 'Drawing';
  fileId?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<Page>>>;
  pageRange?: Maybe<Scalars['String']>;
};

export enum EmailActions {
  Forward = 'Forward',
  Reply = 'Reply',
  ReplyAll = 'ReplyAll',
  Resend = 'Resend'
}

export type EntityFolderPermission = {
  __typename?: 'EntityFolderPermission';
  id: Scalars['ID'];
  breakInheritance?: Maybe<Scalars['Boolean']>;
  folderPermissions: Array<Maybe<FolderPermission>>;
  inheritedFrom?: Maybe<Array<Maybe<GroupPermission>>>;
  inheritedFromParentFolders?: Maybe<Array<Maybe<ParentFolderPermission>>>;
  memberId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
  group?: Maybe<Group>;
  projectId?: Maybe<Scalars['ID']>;
  subFoldersPermissions?: Maybe<Array<Maybe<SubFolderPermission>>>;
};


export type EntityFolderPermissionGroupArgs = {
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
};

export type EntityWithFolderPermissions = {
  breakInheritance: Scalars['Boolean'];
  entityId: Scalars['ID'];
  permissions: Array<Maybe<FolderPermission>>;
};

export type FacetGroup = {
  __typename?: 'FacetGroup';
  items?: Maybe<Array<Maybe<FacetItem>>>;
  type: FilterType;
};

export type FacetItem = {
  __typename?: 'FacetItem';
  name: Scalars['String'];
  apiValue: Scalars['String'];
  value: Scalars['String'];
  mdField?: Maybe<FacetItemMdField>;
  properties?: Maybe<Scalars['JSON']>;
};

export type FacetItemMdField = {
  __typename?: 'FacetItemMDField';
  fieldDefinitionId?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['String']>;
  valueType: ValueTypeName;
  id: Scalars['String'];
};

export type Field = {
  fieldDefinitionId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  conditions?: Maybe<Array<Condition>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fieldDefinitionId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  indicative?: Maybe<Scalars['Boolean']>;
  linksAllowed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['ID']>;
  parentSectionId?: Maybe<Scalars['ID']>;
  required?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<Scope>;
  type?: Maybe<ValueTypeName>;
  value?: Maybe<Scalars['String']>;
  valueType?: Maybe<ValueType>;
};

export type FieldDefinitionInput = {
  fieldDefinitionId?: Maybe<Scalars['ID']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type FieldDefinitionType = {
  __typename?: 'FieldDefinitionType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
};

export type FieldDefinitionTypeInput = {
  displayType?: Maybe<Scalars['String']>;
  hierarchyListId?: Maybe<Scalars['ID']>;
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  multipleAnswers?: Maybe<Scalars['Boolean']>;
  multiSelect?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<MetaDataOptionInput>>;
  rangeEnabled?: Maybe<Scalars['Boolean']>;
  staticBreadcrumbs?: Maybe<Scalars['Boolean']>;
};

export type File = {
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type FileInformation = {
  __typename?: 'FileInformation';
  amountOfDocuments?: Maybe<Scalars['Float']>;
  amountOfVersions?: Maybe<Scalars['Float']>;
  totalFileSize?: Maybe<Scalars['Float']>;
};

export type FilesExistsBuild12 = {
  __typename?: 'FilesExistsBuild12';
  allowedIds: Array<Maybe<Scalars['ID']>>;
  alreadyExistsIds: Array<Maybe<Scalars['ID']>>;
  rejectedIds: Array<Maybe<Scalars['ID']>>;
};

export type FilesExistsSnagstream = {
  __typename?: 'FilesExistsSnagstream';
  allowedIds: Array<Maybe<Scalars['ID']>>;
  alreadyExistsIds: Array<Maybe<Scalars['ID']>>;
  rejectedIds: Array<Maybe<Scalars['ID']>>;
};

export type Filter = {
  __typename?: 'Filter';
  type: Scalars['String'];
  property: Scalars['String'];
  value: Scalars['String'];
};

export enum FilterType {
  CreatedAt = 'CreatedAt',
  CreatedOn = 'CreatedOn',
  IndivativeStatus = 'IndivativeStatus',
  ReferenceNumber = 'ReferenceNumber',
  UpdatedOn = 'UpdatedOn',
  CreatedBy = 'CreatedBy',
  DateTime = 'DateTime',
  Extension = 'Extension',
  Bool = 'Bool',
  ParentFolderIds = 'ParentFolderIds',
  FolderId = 'FolderId',
  State = 'State',
  Number = 'Number',
  UpdatedBy = 'UpdatedBy',
  UpdatedAt = 'UpdatedAt',
  VersionState = 'VersionState',
  Versions = 'Versions',
  Selection = 'Selection',
  Text = 'Text',
  Status = 'Status',
  Drawing = 'Drawing',
  Template = 'Template',
  TemplateType = 'TemplateType',
  ProjectNumber = 'ProjectNumber',
  ProjectName = 'ProjectName',
  Tasks = 'Tasks',
  Snags = 'Snags',
  Forms = 'Forms',
  UserSelection = 'UserSelection',
  Highlight = 'Highlight',
  TemplateId = 'TemplateId',
  HierarchyList = 'HierarchyList'
}

export enum FinalizationState {
  NotFinalized = 'notFinalized',
  Finalized = 'finalized'
}

export type Folder = {
  __typename?: 'Folder';
  availablePermissions?: Maybe<Array<Maybe<FolderPermission>>>;
  children?: Maybe<Array<Maybe<Folder>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  documents?: Maybe<Array<Maybe<Document>>>;
  entityFolderPermissions?: Maybe<Array<Maybe<EntityFolderPermission>>>;
  fileInformation?: Maybe<FileInformation>;
  hasSubFolders?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inheritParentMetadata?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  metadataSetId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentFolder?: Maybe<Folder>;
  parentFolderId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  subFolders?: Maybe<Array<Maybe<Folder>>>;
  template?: Maybe<Template>;
};


export type FolderDocumentsArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
};


export type FolderEntityFolderPermissionsArgs = {
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  includePermissionViaGroup?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type FolderSubFoldersArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
};

export enum FolderChangeAction {
  ApplyTemplate = 'ApplyTemplate',
  UpdatePermissions = 'UpdatePermissions'
}

export type FolderChangeResponse = {
  __typename?: 'FolderChangeResponse';
  action: FolderChangeAction;
  folderId: Scalars['ID'];
  organizationId: Scalars['ID'];
  parentFolderId?: Maybe<Scalars['ID']>;
  previousParentFolderId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FolderDuplicationResponse = {
  __typename?: 'FolderDuplicationResponse';
  success: Scalars['Boolean'];
  errorCode?: Maybe<Scalars['Int']>;
  folderName?: Maybe<Scalars['String']>;
};

export enum FolderPermission {
  ReadFolder = 'ReadFolder',
  CreateSubFolder = 'CreateSubFolder',
  UpdateFolder = 'UpdateFolder',
  DeleteFolder = 'DeleteFolder',
  ReadContent = 'ReadContent',
  CreateContent = 'CreateContent',
  UpdateContent = 'UpdateContent',
  DeleteContent = 'DeleteContent',
  AllowSync = 'AllowSync',
  FinalizeContent = 'FinalizeContent',
  ApproveContent = 'ApproveContent',
  ReadApproval = 'ReadApproval',
  ReadLastApproval = 'ReadLastApproval',
  ReadOwnAndFinalized = 'ReadOwnAndFinalized',
  DeleteOwnContent = 'DeleteOwnContent',
  ReadOwn = 'ReadOwn',
  UpdateOwn = 'UpdateOwn',
  ReadAllDocuments = 'ReadAllDocuments',
  ReadAllVersions = 'ReadAllVersions',
  ReadLastVersion = 'ReadLastVersion',
  ReadLastVersionAndApproved = 'ReadLastVersionAndApproved',
  ReadOwnVersions = 'ReadOwnVersions'
}

export type FolderPermissionsChangeResponse = {
  __typename?: 'FolderPermissionsChangeResponse';
  folderId: Scalars['ID'];
  userId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};

export type FolderWithPermissions = {
  breakInheritance: Scalars['Boolean'];
  folderId: Scalars['ID'];
  permissions: Array<Maybe<FolderPermission>>;
};

export type FoldersChangeResponse = {
  __typename?: 'FoldersChangeResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FormLinkedSnag = {
  __typename?: 'FormLinkedSnag';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Type>;
  linkId?: Maybe<Scalars['ID']>;
  linkType?: Maybe<LinkType>;
  direction?: Maybe<DirectionType>;
  appData?: Maybe<AppData>;
};

export type GetSignature = {
  __typename?: 'GetSignature';
  data?: Maybe<GetSignatureData>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetSignatureData = {
  __typename?: 'GetSignatureData';
  signatureId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type Group = {
  __typename?: 'Group';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  memberIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  members?: Maybe<Array<Maybe<Member>>>;
  membersCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  projectId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  roleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  scope?: Maybe<Scalars['Int']>;
  subgroupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  subgroups?: Maybe<Array<Maybe<Group>>>;
  explicitProjectMember?: Maybe<Scalars['Boolean']>;
};

export type GroupPermission = {
  __typename?: 'GroupPermission';
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  permission?: Maybe<FolderPermission>;
};

export type HierarchicalList = {
  __typename?: 'HierarchicalList';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  hierarchy: Array<Hierarchy>;
  name: Scalars['String'];
  scope?: Maybe<ScopeType>;
};

export type Hierarchy = {
  __typename?: 'Hierarchy';
  deletedAt?: Maybe<Scalars['DateTime']>;
  expanded?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  parentNodeId?: Maybe<Scalars['ID']>;
  previousNodeId?: Maybe<Scalars['ID']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type HierarchyDummy = {
  __typename?: 'HierarchyDummy';
  deletedAt?: Maybe<Scalars['DateTime']>;
  dummy?: Maybe<Scalars['String']>;
  expanded?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  parentNodeId?: Maybe<Scalars['ID']>;
  previousNodeId?: Maybe<Scalars['ID']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export enum ImageType {
  Background = 'background',
  CompanyLogo = 'companyLogo'
}

export type InheritedPermission = {
  __typename?: 'InheritedPermission';
  permissions?: Maybe<Array<Scalars['String']>>;
  groupName?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
};

export type Instance = {
  __typename?: 'Instance';
  fieldDefinitionId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type InstanceForDocumentInput = {
  documentId: Scalars['ID'];
  fields?: Maybe<Array<Maybe<Field>>>;
  templateId: Scalars['ID'];
  templateVersion?: Maybe<Scalars['Int']>;
  createNewInstanceForVersion?: Maybe<Scalars['Boolean']>;
  metadataInstanceId?: Maybe<Scalars['ID']>;
};

export type InstanceForQcInput = {
  fieldDefinitionId: Scalars['ID'];
  value: Scalars['String'];
};

export enum InstanceResourceType {
  Document = 'Document',
  Task = 'Task',
  VisualContext = 'VisualContext',
  Field = 'Field',
  Tbq = 'TBQ'
}

export type Integration = {
  __typename?: 'Integration';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  integrationName: Scalars['String'];
  integrationType: IntegrationType;
};

export type IntegrationProject = {
  __typename?: 'IntegrationProject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum IntegrationType {
  Integration12Build = 'Integration12Build',
  IntegrationSnagstream = 'IntegrationSnagstream'
}

export type IntegrationWithConnection = {
  __typename?: 'IntegrationWithConnection';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  integrationName: Scalars['String'];
  integrationType: IntegrationType;
  projectId: Scalars['ID'];
  connectionStatus?: Maybe<Scalars['Boolean']>;
  integrationConnection?: Maybe<Connection>;
};


export type JobInput = {
  Type: Scalars['String'];
  documentId: Scalars['String'];
  order: Scalars['Int'];
  metadataInstanceId?: Maybe<Scalars['String']>;
};

export type JobOutput = {
  __typename?: 'JobOutput';
  documentId: Scalars['String'];
  versionId: Scalars['String'];
  jobId: Scalars['String'];
  type: Scalars['String'];
  order: Scalars['Int'];
};

export type Keyword = {
  __typename?: 'Keyword';
  score: Scalars['Int'];
  text: Scalars['String'];
};

export type LinkDeviceInput = {
  objectId: Scalars['String'];
  deviceCode: Scalars['String'];
};

export type LinkInfo = {
  __typename?: 'LinkInfo';
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  folderId: Scalars['ID'];
  linkId: Scalars['ID'];
};

export type LinkResponse = Response & {
  __typename?: 'LinkResponse';
  id?: Maybe<Scalars['ID']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export enum LinkType {
  Attachment = 'attachment',
  Deliverable = 'deliverable'
}

export type LinkUnlinkDeviceResponse = Response & {
  __typename?: 'LinkUnlinkDeviceResponse';
  commandId: Scalars['ID'];
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum LockType {
  None = 'none',
  Prostream = 'prostream',
  OfficeOnline = 'officeOnline'
}

export enum LoginMethodOption {
  OnlySso = 'OnlySSO',
  NotOnlySso = 'NotOnlySSO'
}


export enum MailStyle {
  Prostream = 'Prostream',
  Tbq = 'Tbq'
}

export type MarkMessageInput = {
  id: Scalars['ID'];
  threadId: Scalars['ID'];
};

export type MarkMessageResponse = Response & {
  __typename?: 'MarkMessageResponse';
  ids: Array<Maybe<Scalars['ID']>>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Me = {
  __typename?: 'Me';
  organization?: Maybe<MeOrganization>;
  projects?: Maybe<Array<Maybe<MeProject>>>;
  user?: Maybe<MeUser>;
};

export type MeOrganization = {
  __typename?: 'MeOrganization';
  enforceSSO?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  roleIds: Array<Scalars['ID']>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type MeProject = {
  __typename?: 'MeProject';
  projectId: Scalars['ID'];
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  roleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type MeUser = {
  __typename?: 'MeUser';
  active?: Maybe<Scalars['Boolean']>;
  authType?: Maybe<AuthType>;
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['Uri']>;
  jobTitle?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userflowSignature?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type Media = {
  __typename?: 'Media';
  typeId?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
};

export type Member = Group | User;

export type MemberInput = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  attachments?: Maybe<Array<Attachment>>;
  body?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']>;
  fromId: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  references: Array<Reference>;
  threadId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  read?: Maybe<Scalars['Boolean']>;
  resolved?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  cc?: Maybe<Array<Maybe<Scalars['String']>>>;
  bcc?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<MessageType>;
};

export type MessageBranch = {
  __typename?: 'MessageBranch';
  id: Scalars['ID'];
  main: Message;
  original?: Maybe<Message>;
  previous?: Maybe<Array<Maybe<Message>>>;
  replyAll?: Maybe<Scalars['Boolean']>;
  replyId?: Maybe<Scalars['ID']>;
  action?: Maybe<EmailActions>;
};

export enum MessageType {
  Sent = 'sent',
  Inbox = 'inbox',
  SentAndInbox = 'sentAndInbox'
}

export type MetaData = {
  __typename?: 'MetaData';
  fields?: Maybe<Array<Maybe<FieldDefinition>>>;
  organizationId: Scalars['ID'];
  ownerId: Scalars['ID'];
  projectId: Scalars['ID'];
  templates?: Maybe<Array<Maybe<Template>>>;
};

export type MetaDataInstance = {
  __typename?: 'MetaDataInstance';
  questions?: Maybe<Array<Maybe<SearchDocumentMetaQuestion>>>;
};

export type MetaDataOption = {
  __typename?: 'MetaDataOption';
  color?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  name: Scalars['String'];
};

export type MetaDataOptionInput = {
  color?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type MoveDocumentData = {
  sourceDocumentId: Scalars['ID'];
  destinationDocumentId: Scalars['ID'];
  overwriteDocumentName: Scalars['Boolean'];
};

export type MoveUserModel = {
  __typename?: 'MoveUserModel';
  invitedUserId: Scalars['ID'];
  invitedUserOrganizationId: Scalars['ID'];
  invitedUserOrganizationName: Scalars['String'];
  inviterUserId?: Maybe<Scalars['ID']>;
  inviterEmail: Scalars['String'];
  inviterName: Scalars['String'];
  newOrganizationId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  state: Scalars['Int'];
};

export type MutateAddCommentResponse = Response & {
  __typename?: 'MutateAddCommentResponse';
  id: Scalars['ID'];
  message?: Maybe<Message>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MutateDeleteCommentResponse = Response & {
  __typename?: 'MutateDeleteCommentResponse';
  id: Scalars['ID'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MutateFolderResponse = {
  __typename?: 'MutateFolderResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MutateGroupResponse = {
  __typename?: 'MutateGroupResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MutateMetaDataResponse = {
  __typename?: 'MutateMetaDataResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MutateQualityControlInstanceResponse = {
  __typename?: 'MutateQualityControlInstanceResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  instanceId?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  activateSubscription?: Maybe<ChangeSubscriptionDataResponse>;
  activateUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  addAnnotation?: Maybe<Annotation>;
  addComment?: Maybe<Message>;
  addExternalUsers?: Maybe<AddExternalUsersResponse>;
  addGroupsToProjects?: Maybe<MutateGroupResponse>;
  addIntegration: Scalars['ID'];
  addMessage?: Maybe<Message>;
  addProjectMembers?: Maybe<Scalars['ID']>;
  addQuestionsToResolveTaskBatch?: Maybe<LinkResponse>;
  addSavedAnswers?: Maybe<Scalars['ID']>;
  addSignature?: Maybe<AddSignatureResponse>;
  addSnagToFormLink?: Maybe<MutateQualityControlInstanceResponse>;
  addTagsToDocument?: Maybe<BatchResult>;
  addUserToProject?: Maybe<Scalars['ID']>;
  addUsersToProjects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  applyTemplate?: Maybe<FolderChangeResponse>;
  approveDocumentVersions?: Maybe<BatchResult>;
  cancelSubscription?: Maybe<CancelSubscriptionDataResponse>;
  changePaymentMethod?: Maybe<ChangeSubscriptionDataResponse>;
  changeSubscription?: Maybe<ChangeSubscriptionDataResponse>;
  confirmEmail?: Maybe<Scalars['Boolean']>;
  confirmMoveExistingUser?: Maybe<MoveUserModel>;
  connectProject: ConnectProjectResponse;
  convertTo3D?: Maybe<Scalars['ID']>;
  createApiKey?: Maybe<Scalars['String']>;
  createBatchTaskCategory?: Maybe<BatchTaskCategoryResponse>;
  createCommand?: Maybe<CommandOutput>;
  createDocument?: Maybe<DocumentResponse>;
  createDocumentTask?: Maybe<Task>;
  createDocuments?: Maybe<DocumentsResponse>;
  createFieldDefinition?: Maybe<MutateMetaDataResponse>;
  createFolder?: Maybe<FolderChangeResponse>;
  createGroup?: Maybe<MutateGroupResponse>;
  createHierarchicalList?: Maybe<Scalars['ID']>;
  createPrivateShareLink?: Maybe<CreateShareLinkResponse>;
  createProject?: Maybe<Scalars['ID']>;
  createPublicShareLink?: Maybe<CreateShareLinkResponse>;
  createQualityControlInstance?: Maybe<MutateQualityControlInstanceResponse>;
  createReportConfiguration?: Maybe<Scalars['ID']>;
  createScopedShareLink?: Maybe<CreateShareLinkResponse>;
  createSmartFolder?: Maybe<Scalars['ID']>;
  createSummary?: Maybe<Scalars['ID']>;
  createTags?: Maybe<BatchResult>;
  createTask?: Maybe<Task>;
  createTbqScanTask?: Maybe<Task>;
  createTbqTask: Scalars['ID'];
  createTemplate?: Maybe<MutateMetaDataResponse>;
  createVisualContextFromDocument?: Maybe<VisualContextMutationResponse>;
  deactivateUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deleteAnnotations?: Maybe<Scalars['ID']>;
  deleteBatchTaskCategory?: Maybe<BatchDeleteTaskCategoryResponse>;
  deleteComment?: Maybe<MutateDeleteCommentResponse>;
  deleteDocumentVersion?: Maybe<Scalars['ID']>;
  deleteDocumentVersions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deleteDocuments?: Maybe<BatchDocumentsResponse>;
  deleteFieldDefinitions?: Maybe<MutateMetaDataResponse>;
  deleteFolder?: Maybe<FolderChangeResponse>;
  deleteGroups?: Maybe<MutateGroupResponse>;
  deleteHierarchicalLists?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deleteInstance?: Maybe<Array<Maybe<DeleteInstanceResponse>>>;
  deleteIntegration: Scalars['ID'];
  deleteIntegrationConnection: Scalars['ID'];
  deleteMembersFromProject?: Maybe<Scalars['ID']>;
  deleteProjects?: Maybe<BatchProjectsResponse>;
  deleteQuery?: Maybe<SavedSearchResponse>;
  deleteReportConfigurations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deleteSavedAnswers?: Maybe<Scalars['Boolean']>;
  deleteSmartFolder?: Maybe<Scalars['ID']>;
  deleteTask?: Maybe<Task>;
  deleteTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deleteVisualContexts?: Maybe<VisualContextMutationResponse>;
  downloadFolderZip?: Maybe<Scalars['String']>;
  duplicateFolderStructure?: Maybe<FolderDuplicationResponse>;
  duplicateProject?: Maybe<Scalars['ID']>;
  editApiKey?: Maybe<Scalars['Boolean']>;
  editComment?: Maybe<Message>;
  editGroup?: Maybe<MutateGroupResponse>;
  editHierarchicalList?: Maybe<Scalars['ID']>;
  editQuery?: Maybe<SavedSearchResponse>;
  editReportConfiguration?: Maybe<Scalars['ID']>;
  editTemplate?: Maybe<MutateMetaDataResponse>;
  finalizeDocuments?: Maybe<BatchResult>;
  finalizeOrgInvitation?: Maybe<Scalars['Boolean']>;
  generateDocumentKeywords?: Maybe<BatchDocumentsResponse>;
  generateDocumentKeywordsSingleDoc?: Maybe<Array<Maybe<Keyword>>>;
  inviteExistingUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  inviteUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkOsidDevice: LinkUnlinkDeviceResponse;
  linkProject?: Maybe<ProjectLinkResponse>;
  lockDocuments?: Maybe<BatchResult>;
  mapDocumentVersionMetaDataInstance?: Maybe<BatchResult>;
  markMessage?: Maybe<MarkMessageResponse>;
  moveDocument?: Maybe<Scalars['ID']>;
  moveDocuments?: Maybe<BatchDocumentsResponse>;
  moveFolder?: Maybe<FolderChangeResponse>;
  moveProjectLink?: Maybe<LinkResponse>;
  moveToNewOrganization?: Maybe<MoveUserModel>;
  multiRieResults?: Maybe<Array<Maybe<TbqRieResult>>>;
  mutateDocumentKeywords?: Maybe<Scalars['ID']>;
  pasteDocuments?: Maybe<BatchDocumentsResponse>;
  publishTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reInviteUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  registerOrganization?: Maybe<Scalars['ID']>;
  rejectDocumentVersions?: Maybe<BatchResult>;
  removeFolderProjectLink?: Maybe<LinkResponse>;
  removeStamps?: Maybe<RemoveStampsResponse>;
  removeTagsFromDocument?: Maybe<BatchResult>;
  renameFolder?: Maybe<FolderChangeResponse>;
  requestUnlockDocuments?: Maybe<BatchResult>;
  restoreDocument?: Maybe<Scalars['ID']>;
  restoreDocumentVersion?: Maybe<Scalars['ID']>;
  restoreDocuments?: Maybe<BatchResult>;
  restoreFolder?: Maybe<FolderChangeResponse>;
  restoreInstance?: Maybe<RestoreInstanceResponse>;
  restoreQuery?: Maybe<SavedSearchResponse>;
  restoreSavedAnswers?: Maybe<Scalars['Boolean']>;
  restoreSmartFolder?: Maybe<Scalars['ID']>;
  restoreTask: Scalars['ID'];
  restoreVisualContexts?: Maybe<VisualContextMutationResponse>;
  revokeApiKeys: Array<Maybe<Scalars['String']>>;
  revokeShareLink?: Maybe<RevokeLinkResponse>;
  saveDownloadFileNameConfig?: Maybe<Scalars['ID']>;
  saveInstanceForQc?: Maybe<QualityControlInstance>;
  saveInstancesForDocuments?: Maybe<Array<Maybe<VersionMetaDataCreatedResponse>>>;
  saveQuery?: Maybe<SavedSearchResponse>;
  sendShareLink?: Maybe<SendShareLinkResponse>;
  setDocumentTags?: Maybe<BatchResult>;
  setDocumentTaskLinks?: Maybe<LinkResponse>;
  setEntitiesFolderPermissionsForFolder?: Maybe<BatchResult>;
  setFolderProjectLink?: Maybe<LinkResponse>;
  setFoldersPermissionsForUser?: Maybe<BatchResult>;
  setLink?: Maybe<LinkResponse>;
  setOrganizationEnforceSSO?: Maybe<Scalars['ID']>;
  setOrganizationRoleForUsers?: Maybe<Scalars['ID']>;
  setProjectPermissions?: Maybe<SetProjectPermissionsResponse>;
  setProjectRoleForMembers?: Maybe<Scalars['ID']>;
  setTaskTemplateLinks?: Maybe<LinkResponse>;
  setTaskVisualContextLinks?: Maybe<LinkResponse>;
  setTbqConfig?: Maybe<SetTbqResponse>;
  shareToBuild12: BatchMutation;
  shareToSnagstream: BatchMutation;
  stampDocument?: Maybe<StampDocumentResponse>;
  stayInCurrentOrganization?: Maybe<MoveUserModel>;
  toggleBatchDocumentDigest?: Maybe<DocumentDigest>;
  toggleSingleDocumentDigest?: Maybe<DocumentDigest>;
  unapproveDocumentVersions?: Maybe<BatchResult>;
  unfinalizeDocuments?: Maybe<BatchResult>;
  unlinkLink?: Maybe<MutateQualityControlInstanceResponse>;
  unlinkOsidDevice: LinkUnlinkDeviceResponse;
  unlinkProject?: Maybe<ProjectLinkResponse>;
  unlockDocuments?: Maybe<BatchResult>;
  unrejectDocumentVersions?: Maybe<BatchResult>;
  updateBatchTaskCategory?: Maybe<BatchUpdateTaskCategoryResponse>;
  updateDocuments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  updateFieldDefinition?: Maybe<MutateMetaDataResponse>;
  updateFolder?: Maybe<FolderChangeResponse>;
  updateInstance?: Maybe<UpdateInstance>;
  updateNotificationSettings?: Maybe<NotificationSettings>;
  updateObjects?: Maybe<ObjectsResponse>;
  updateOrganization?: Maybe<Scalars['ID']>;
  updateOrganizationSettings?: Maybe<Scalars['ID']>;
  updateProfile?: Maybe<Scalars['ID']>;
  updateProject?: Maybe<Scalars['ID']>;
  updateProjectSettings?: Maybe<Scalars['ID']>;
  updateSavedAnswers?: Maybe<Scalars['ID']>;
  updateSmartFolder?: Maybe<Scalars['ID']>;
  updateSupportSettings?: Maybe<Scalars['Boolean']>;
  updateTask?: Maybe<Task>;
  validateRegistrationToken?: Maybe<Scalars['Boolean']>;
};


export type MutationActivateSubscriptionArgs = {
  id: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  city: Scalars['String'];
  locale: Scalars['String'];
  postalcode: Scalars['String'];
  redirectUrl: Scalars['String'];
  vatIdentificationNumber: Scalars['String'];
};


export type MutationActivateUsersArgs = {
  userIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationAddAnnotationArgs = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
  json: Scalars['JSON'];
};


export type MutationAddCommentArgs = {
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
  body: Scalars['String'];
  references?: Maybe<Array<Maybe<ReferenceInput>>>;
  target: ReferenceInput;
};


export type MutationAddExternalUsersArgs = {
  emails: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationAddGroupsToProjectsArgs = {
  groupIds: Array<Maybe<Scalars['ID']>>;
  projectIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationAddIntegrationArgs = {
  application: ApplicationInput;
  customIntegrationProps: CustomIntegrationProps;
};


export type MutationAddMessageArgs = {
  id: Scalars['ID'];
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
  body?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  references?: Maybe<Array<Maybe<ReferenceInput>>>;
  subject: Scalars['String'];
  threadId?: Maybe<Scalars['ID']>;
};


export type MutationAddProjectMembersArgs = {
  projectId: Scalars['ID'];
  admins?: Maybe<Array<Maybe<MemberInput>>>;
  users?: Maybe<Array<Maybe<MemberInput>>>;
  message?: Maybe<Scalars['String']>;
};


export type MutationAddQuestionsToResolveTaskBatchArgs = {
  batch: Array<QuestionInput>;
};


export type MutationAddSavedAnswersArgs = {
  answers?: Maybe<Array<Answer>>;
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  templateId: Scalars['ID'];
};


export type MutationAddSignatureArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  signatureBody?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['ID']>;
};


export type MutationAddSnagToFormLinkArgs = {
  fieldId: Scalars['ID'];
  formInstanceId: Scalars['ID'];
  snagInstanceId: Scalars['ID'];
};


export type MutationAddTagsToDocumentArgs = {
  documentId: Scalars['ID'];
  tagIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationAddUserToProjectArgs = {
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationAddUsersToProjectsArgs = {
  projectIds: Array<Maybe<Scalars['ID']>>;
  userIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationApplyTemplateArgs = {
  applyToSubfolders?: Maybe<Scalars['Boolean']>;
  folderId: Scalars['ID'];
  templateId: Scalars['ID'];
};


export type MutationApproveDocumentVersionsArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationCancelSubscriptionArgs = {
  description?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};


export type MutationChangePaymentMethodArgs = {
  locale: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationChangeSubscriptionArgs = {
  id: Scalars['ID'];
  locale: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationConfirmMoveExistingUserArgs = {
  token: Scalars['String'];
};


export type MutationConnectProjectArgs = {
  customConnectionProps: CustomConnectionProps;
  integrationId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationConvertTo3DArgs = {
  versionId: Scalars['ID'];
};


export type MutationCreateApiKeyArgs = {
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};


export type MutationCreateBatchTaskCategoryArgs = {
  names: Array<Maybe<Scalars['String']>>;
  organizationId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  taskType: TaskType;
};


export type MutationCreateCommandArgs = {
  command: CreateCommandInput;
};


export type MutationCreateDocumentArgs = {
  document: DocumentCreate;
};


export type MutationCreateDocumentTaskArgs = {
  name: Scalars['String'];
  procedureId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  duplicatedFrom?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<TaskType>;
  documentAction?: Maybe<DocumentAction>;
  taskCategoryId?: Maybe<Scalars['String']>;
};


export type MutationCreateDocumentsArgs = {
  documents: Array<DocumentCreate>;
};


export type MutationCreateFieldDefinitionArgs = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  indicative?: Maybe<Scalars['Boolean']>;
  fieldDefinitionTypeInput?: Maybe<FieldDefinitionTypeInput>;
  templateService: TemplateService;
  name: Scalars['String'];
  scope: ScopeType;
  type: Scalars['ID'];
};


export type MutationCreateFolderArgs = {
  metadataSetId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentFolderId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  applyToSubfolders?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  subgroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  members?: Maybe<Array<Maybe<MemberInput>>>;
};


export type MutationCreateHierarchicalListArgs = {
  name: Scalars['String'];
  nodes: Array<Maybe<Node>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreatePrivateShareLinkArgs = {
  expirationDate: Scalars['DateTime'];
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  groups?: Maybe<Array<Maybe<SelectedGroup>>>;
  resources: Array<Maybe<Resource>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['ID']>;
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  admins?: Maybe<Array<Maybe<MemberInput>>>;
  members?: Maybe<Array<Maybe<MemberInput>>>;
};


export type MutationCreatePublicShareLinkArgs = {
  expirationDate: Scalars['DateTime'];
  passwordProtected?: Maybe<Scalars['Boolean']>;
  resources: Array<Maybe<Resource>>;
  showFolders: Scalars['Boolean'];
};


export type MutationCreateQualityControlInstanceArgs = {
  resourceType?: Maybe<InstanceResourceType>;
  resourceId?: Maybe<Scalars['ID']>;
  templateId: Scalars['ID'];
  projectId: Scalars['ID'];
  visualContext?: Maybe<VisualContextInput>;
  instanceId?: Maybe<Scalars['ID']>;
  linkToTaskId?: Maybe<Scalars['ID']>;
};


export type MutationCreateReportConfigurationArgs = {
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  reportOptions: Scalars['String'];
  setAsDefault: Scalars['Boolean'];
  storeOnOrganizationLevel: Scalars['Boolean'];
};


export type MutationCreateScopedShareLinkArgs = {
  expirationDate: Scalars['DateTime'];
  resources: Array<Maybe<Resource>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateSmartFolderArgs = {
  name: Scalars['String'];
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fileTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateSummaryArgs = {
  versionId: Scalars['ID'];
};


export type MutationCreateTagsArgs = {
  tags: Array<Maybe<TagInput>>;
};


export type MutationCreateTaskArgs = {
  name: Scalars['String'];
  procedureId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<TaskType>;
  documentAction?: Maybe<DocumentAction>;
  duplicatedFrom?: Maybe<Scalars['String']>;
  taskCategoryId?: Maybe<Scalars['String']>;
};


export type MutationCreateTbqScanTaskArgs = {
  name: Scalars['String'];
  procedureId: Scalars['ID'];
  projectId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<TaskType>;
  taskCategoryId?: Maybe<Scalars['String']>;
  tbqTypeId: Scalars['String'];
  tbqScanTypeId: Scalars['String'];
  tbqModuleType: TbqModuleType;
};


export type MutationCreateTbqTaskArgs = {
  task: TbqTask;
};


export type MutationCreateTemplateArgs = {
  fieldDefinitionsBody: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  scope: ScopeType;
  templateService: TemplateService;
  templateType: TemplateType;
  iconType?: Maybe<Scalars['Int']>;
  selectedTemplateIcon?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
};


export type MutationCreateVisualContextFromDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationDeactivateUsersArgs = {
  userIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteAnnotationsArgs = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
  annotationIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteBatchTaskCategoryArgs = {
  taskCategories: Array<TaskCategoryInput>;
};


export type MutationDeleteCommentArgs = {
  id?: Maybe<Scalars['ID']>;
  threadId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteDocumentVersionArgs = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
};


export type MutationDeleteDocumentVersionsArgs = {
  documentIdsVersionIds?: Maybe<Array<Maybe<DocumentIdVersionId>>>;
};


export type MutationDeleteDocumentsArgs = {
  documentIds: Array<Scalars['ID']>;
};


export type MutationDeleteFieldDefinitionsArgs = {
  autoPublish: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['ID']>>;
  templateService: TemplateService;
};


export type MutationDeleteFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupsArgs = {
  groups: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteHierarchicalListsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteInstanceArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntegrationConnectionArgs = {
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationDeleteMembersFromProjectArgs = {
  members: Array<Maybe<MemberInput>>;
  projectId: Scalars['ID'];
};


export type MutationDeleteProjectsArgs = {
  projectIds: Array<Scalars['ID']>;
};


export type MutationDeleteQueryArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteReportConfigurationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteSavedAnswersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteSmartFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
  procedureId: Scalars['ID'];
};


export type MutationDeleteTemplatesArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  templateService: TemplateService;
};


export type MutationDeleteVisualContextsArgs = {
  visualContextIds?: Maybe<Array<Scalars['ID']>>;
  includeSnagsAndForms?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationDownloadFolderZipArgs = {
  id: Scalars['ID'];
  includeSubfolders: Scalars['Boolean'];
};


export type MutationDuplicateFolderStructureArgs = {
  sourceFolderId?: Maybe<Scalars['String']>;
  sourceProjectId?: Maybe<Scalars['String']>;
  targetFolderId?: Maybe<Scalars['String']>;
  targetProjectId?: Maybe<Scalars['String']>;
};


export type MutationDuplicateProjectArgs = {
  sourceProjectId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['ID']>;
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
};


export type MutationEditApiKeyArgs = {
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};


export type MutationEditCommentArgs = {
  attachments?: Maybe<Array<Maybe<AttachmentInput>>>;
  body?: Maybe<Scalars['String']>;
  threadId: Scalars['ID'];
  messageId?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ReferenceInput>>>;
  target?: Maybe<ReferenceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
};


export type MutationEditGroupArgs = {
  groupId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subgroupsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  subgroupsToDelete?: Maybe<Array<Maybe<Scalars['ID']>>>;
  membersToAdd?: Maybe<Array<Maybe<MemberInput>>>;
  membersToDelete?: Maybe<Array<Maybe<MemberInput>>>;
};


export type MutationEditHierarchicalListArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  nodes: Array<Node>;
};


export type MutationEditQueryArgs = {
  id: Scalars['ID'];
  documentType: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  notificationsEnabled: Scalars['Boolean'];
  notificationDays: Array<Maybe<WeekDay>>;
  notificationTimes: Array<Maybe<ScheduledTimeInput>>;
  notificationTimeZone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  searchUriComponent: Scalars['String'];
};


export type MutationEditReportConfigurationArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  reportOptions: Scalars['String'];
  setAsDefault: Scalars['Boolean'];
  storeOnOrganizationLevel: Scalars['Boolean'];
};


export type MutationEditTemplateArgs = {
  fieldDefinitionsBody: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  templateService: TemplateService;
  iconType?: Maybe<Scalars['Int']>;
  selectedTemplateIcon?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
};


export type MutationFinalizeDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationFinalizeOrgInvitationArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};


export type MutationGenerateDocumentKeywordsArgs = {
  versionIds: Array<Scalars['ID']>;
};


export type MutationGenerateDocumentKeywordsSingleDocArgs = {
  versionId: Scalars['ID'];
};


export type MutationInviteExistingUsersArgs = {
  adminEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  userEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationInviteUsersArgs = {
  adminEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  memberEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  style?: Maybe<MailStyle>;
};


export type MutationLinkOsidDeviceArgs = {
  osidLinkInput: LinkDeviceInput;
};


export type MutationLinkProjectArgs = {
  nodeId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type MutationLockDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationMapDocumentVersionMetaDataInstanceArgs = {
  mappings: Array<Maybe<DocumentVersionMetadataMapping>>;
};


export type MutationMarkMessageArgs = {
  messages: Array<Maybe<MarkMessageInput>>;
  read: Scalars['Boolean'];
};


export type MutationMoveDocumentArgs = {
  moveDocumentData: MoveDocumentData;
};


export type MutationMoveDocumentsArgs = {
  documentIds?: Maybe<Array<Scalars['ID']>>;
  folderId: Scalars['ID'];
};


export type MutationMoveFolderArgs = {
  childFolderId: Scalars['ID'];
  parentFolderId: Scalars['ID'];
};


export type MutationMoveProjectLinkArgs = {
  newFolderId: Scalars['ID'];
  currentLinkId: Scalars['ID'];
  projectInfo: ProjectInfo;
};


export type MutationMoveToNewOrganizationArgs = {
  token: Scalars['String'];
};


export type MutationMultiRieResultsArgs = {
  taskIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationMutateDocumentKeywordsArgs = {
  keywords: Array<DocumentKeyword>;
  versionId: Scalars['ID'];
};


export type MutationPasteDocumentsArgs = {
  documentIds: Array<PasteDocumentsDictionary>;
  folderId: Scalars['ID'];
};


export type MutationPublishTemplatesArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  templateService: TemplateService;
};


export type MutationReInviteUsersArgs = {
  requestIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  style?: Maybe<MailStyle>;
};


export type MutationRegisterOrganizationArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
  organizationName: Scalars['String'];
  phoneNumber: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  subscriptionIntent: Scalars['String'];
};


export type MutationRejectDocumentVersionsArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationRemoveFolderProjectLinkArgs = {
  linkId: Scalars['ID'];
};


export type MutationRemoveStampsArgs = {
  stamps: Array<RemoveStampInput>;
};


export type MutationRemoveTagsFromDocumentArgs = {
  documentId: Scalars['ID'];
  tagIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationRenameFolderArgs = {
  folderId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};


export type MutationRequestUnlockDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationRestoreDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreDocumentVersionArgs = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
};


export type MutationRestoreDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationRestoreFolderArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreInstanceArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationRestoreQueryArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationRestoreSavedAnswersArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRestoreSmartFolderArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreTaskArgs = {
  id: Scalars['ID'];
  procedureId: Scalars['ID'];
};


export type MutationRestoreVisualContextsArgs = {
  visualContextIds?: Maybe<Array<Scalars['ID']>>;
  includeSnagsAndForms?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationRevokeApiKeysArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRevokeShareLinkArgs = {
  id: Scalars['ID'];
};


export type MutationSaveDownloadFileNameConfigArgs = {
  enabled: Scalars['Boolean'];
  segments: Array<Maybe<ConfigDataSegmentInput>>;
  templateId: Scalars['ID'];
};


export type MutationSaveInstanceForQcArgs = {
  answers: Array<InstanceForQcInput>;
  instanceId: Scalars['ID'];
  templateVersion: Scalars['Int'];
};


export type MutationSaveInstancesForDocumentsArgs = {
  answers: Array<InstanceForDocumentInput>;
  projectId?: Maybe<Scalars['ID']>;
  previousInstanceId?: Maybe<Scalars['String']>;
  templateId: Scalars['ID'];
};


export type MutationSaveQueryArgs = {
  documentType: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  notificationsEnabled: Scalars['Boolean'];
  notificationDays: Array<Maybe<WeekDay>>;
  notificationTimes: Array<Maybe<ScheduledTimeInput>>;
  notificationTimeZone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  searchUriComponent: Scalars['String'];
  scope: Scalars['Int'];
};


export type MutationSendShareLinkArgs = {
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  copyToSelf?: Maybe<Scalars['Boolean']>;
  to?: Maybe<Array<Scalars['String']>>;
  userIds?: Maybe<Array<Scalars['ID']>>;
  groupIds?: Maybe<Array<Scalars['ID']>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationSetDocumentTagsArgs = {
  documentId: Scalars['ID'];
  tagIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationSetDocumentTaskLinksArgs = {
  documentId: Scalars['ID'];
  attachmentTaskIds: Array<Maybe<Scalars['ID']>>;
  deliverableTaskIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationSetEntitiesFolderPermissionsForFolderArgs = {
  entities: Array<Maybe<EntityWithFolderPermissions>>;
  folderId: Scalars['ID'];
};


export type MutationSetFolderProjectLinkArgs = {
  folderId: Scalars['ID'];
  projectInfo: ProjectInfo;
};


export type MutationSetFoldersPermissionsForUserArgs = {
  folders: Array<Maybe<FolderWithPermissions>>;
  userId: Scalars['ID'];
};


export type MutationSetLinkArgs = {
  resourceAId: Scalars['ID'];
  resourceAType?: Maybe<ResourceType>;
  resourceBLinkIds: Array<Maybe<Scalars['ID']>>;
  resourceBType?: Maybe<ResourceType>;
  appData?: Maybe<AppDataInput>;
};


export type MutationSetOrganizationEnforceSsoArgs = {
  enforceSSO?: Maybe<Scalars['Boolean']>;
};


export type MutationSetOrganizationRoleForUsersArgs = {
  userIds?: Maybe<Array<Scalars['ID']>>;
  roleId: Scalars['ID'];
};


export type MutationSetProjectPermissionsArgs = {
  member?: Maybe<MemberInput>;
  isMemberGroupFromOrg?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
  memberPermissions: Array<Maybe<Scalars['String']>>;
  category?: Maybe<QcPermissionCategory>;
};


export type MutationSetProjectRoleForMembersArgs = {
  members: Array<Maybe<MemberInput>>;
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
};


export type MutationSetTaskTemplateLinksArgs = {
  taskId: Scalars['ID'];
  templateIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationSetTaskVisualContextLinksArgs = {
  taskId: Scalars['ID'];
  visualContextIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationSetTbqConfigArgs = {
  nodeId: Scalars['ID'];
  tbqConfig?: Maybe<Array<TbqSetting>>;
  tbqFunctionId?: Maybe<Scalars['ID']>;
  tbqTypeId?: Maybe<Scalars['ID']>;
};


export type MutationShareToBuild12Args = {
  connectionId: Scalars['ID'];
  integrationId: Scalars['ID'];
  markAsSentVersionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  projectId: Scalars['ID'];
  projectPhaseId: Scalars['ID'];
  versionIds: Array<Maybe<Scalars['ID']>>;
  projectPhaseName?: Maybe<Scalars['String']>;
};


export type MutationShareToSnagstreamArgs = {
  connectionId: Scalars['ID'];
  integrationId: Scalars['ID'];
  markAsSentVersionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  overwriteVersionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  projectId: Scalars['ID'];
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationStampDocumentArgs = {
  documentId: Scalars['ID'];
  documentVersionId: Scalars['ID'];
  params: StampParams;
};


export type MutationStayInCurrentOrganizationArgs = {
  token: Scalars['String'];
};


export type MutationToggleBatchDocumentDigestArgs = {
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Maybe<NotificationUpdateUser>>>;
  scope: NotificationScope;
};


export type MutationToggleSingleDocumentDigestArgs = {
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  scope: NotificationScope;
};


export type MutationUnapproveDocumentVersionsArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationUnfinalizeDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationUnlinkLinkArgs = {
  linkId: Scalars['ID'];
};


export type MutationUnlinkOsidDeviceArgs = {
  objectId: Scalars['ID'];
};


export type MutationUnlinkProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationUnlockDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationUnrejectDocumentVersionsArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationUpdateBatchTaskCategoryArgs = {
  taskCategories: Array<TaskCategoryInput>;
};


export type MutationUpdateDocumentsArgs = {
  documents: Array<DocumentUpdate>;
};


export type MutationUpdateFieldDefinitionArgs = {
  autoPublish: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  fieldDefinitionTypeInput?: Maybe<FieldDefinitionTypeInput>;
  id: Scalars['ID'];
  indicative?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateService: TemplateService;
  type: Scalars['ID'];
};


export type MutationUpdateFolderArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  parentFolderId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateInstanceArgs = {
  instanceId: Scalars['ID'];
  visualContextId: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['String'];
  page: Scalars['Int'];
};


export type MutationUpdateNotificationSettingsArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  notificationSettingsId?: Maybe<Scalars['ID']>;
  notificationDays?: Maybe<Array<Maybe<Scalars['String']>>>;
  notificationTimes?: Maybe<Array<Maybe<Scalars['String']>>>;
  scope: NotificationScope;
};


export type MutationUpdateObjectsArgs = {
  nodes?: Maybe<Array<ObjectInput>>;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  settings?: Maybe<OrganizationSettingsInput>;
};


export type MutationUpdateOrganizationSettingsArgs = {
  id: Scalars['ID'];
  settings?: Maybe<OrganizationSettingsInput>;
};


export type MutationUpdateProfileArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<ProjectSettingsInput>;
};


export type MutationUpdateProjectSettingsArgs = {
  id: Scalars['ID'];
  settings?: Maybe<ProjectSettingsInput>;
};


export type MutationUpdateSavedAnswersArgs = {
  answers?: Maybe<Array<Answer>>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationUpdateSmartFolderArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fileTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateSupportSettingsArgs = {
  date?: Maybe<Scalars['String']>;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  procedureId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  endTime?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  command?: Maybe<Scalars['String']>;
  documentAction?: Maybe<DocumentAction>;
  taskCategoryId?: Maybe<Scalars['String']>;
};


export type MutationValidateRegistrationTokenArgs = {
  token: Scalars['String'];
};

export type Node = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentNodeId?: Maybe<Scalars['ID']>;
  previousNodeId?: Maybe<Scalars['ID']>;
};

export enum NotificationScope {
  Document = 'Document',
  Qc = 'QC'
}

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  notificationSettingsId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  notificationDays?: Maybe<Array<Maybe<WeekDay>>>;
  notificationTimes?: Maybe<Array<Maybe<ScheduledTime>>>;
};

export type NotificationUpdateUser = {
  userId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type Notifications = {
  __typename?: 'Notifications';
  notificationSettingsId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  users: Array<NotificationsUser>;
  notificationTimes?: Maybe<Array<Maybe<ScheduledTime>>>;
  notificationDays?: Maybe<Array<Maybe<WeekDay>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  hasUpdatePermission?: Maybe<Scalars['Boolean']>;
};

export type NotificationsUser = {
  __typename?: 'NotificationsUser';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  lastUpdatedBy?: Maybe<Scalars['String']>;
  lastUpdatedById?: Maybe<Scalars['ID']>;
  lastUpdatedOn?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  enabled?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  checked: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type NumberProps = {
  __typename?: 'NumberProps';
  maxValue?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['String']>;
  rangeEnabled?: Maybe<Scalars['Boolean']>;
};

export type ObjectInput = {
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  parentNodeId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type ObjectNode = {
  __typename?: 'ObjectNode';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  parentNodeId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']>;
  projectStatus?: Maybe<ObjectProjectStatus>;
  tasks?: Maybe<Array<Task>>;
  tbqConfig?: Maybe<Array<TbqObjectConfig>>;
  tbqFunction?: Maybe<TbqFunction>;
  tbqFunctionAllowed?: Maybe<Array<TbqFunction>>;
  tbqLatestTaskId?: Maybe<Scalars['ID']>;
  tbqRieScanType?: Maybe<Array<TbqRieScanType>>;
  tbqScanTypeAllowed?: Maybe<Array<TbqScanType>>;
  tbqType?: Maybe<TbqType>;
  tbqTypeAbstract?: Maybe<TbqTypeAbstract>;
  tbqTypeAllowed?: Maybe<Array<TbqType>>;
  tbqTypeAllowedAbstract?: Maybe<TbqTypeAbstract>;
};

export enum ObjectProjectStatus {
  Available = 'Available',
  Linked = 'Linked',
  LinkedAncestor = 'LinkedAncestor',
  LinkedDescendant = 'LinkedDescendant'
}

export type ObjectsResponse = Response & {
  __typename?: 'ObjectsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectIds: Array<Maybe<Scalars['ID']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OfficeOnlineInfo = {
  __typename?: 'OfficeOnlineInfo';
  canOpen: Scalars['Boolean'];
  editUrl?: Maybe<Scalars['String']>;
  readOnly: Scalars['Boolean'];
  viewUrl?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  expireDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  metaData?: Maybe<MetaData>;
  name: Scalars['String'];
  subscriptionLevelId?: Maybe<Scalars['ID']>;
  organizationSubscriptionLevel?: Maybe<OrganizationSubscriptionLevel>;
  projects?: Maybe<Array<Maybe<Project>>>;
  enforceSSO?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  settings?: Maybe<OrganizationSettings>;
};

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  approve_action_label?: Maybe<Scalars['String']>;
  reject_action_label?: Maybe<Scalars['String']>;
  finalize_action_label?: Maybe<Scalars['String']>;
  ai_admin_settings?: Maybe<Scalars['Boolean']>;
  ai_organization_settings?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSettingsInput = {
  approve_action_label?: Maybe<Scalars['String']>;
  reject_action_label?: Maybe<Scalars['String']>;
  finalize_action_label?: Maybe<Scalars['String']>;
  ai_admin_settings?: Maybe<Scalars['Boolean']>;
  ai_organization_settings?: Maybe<Scalars['Boolean']>;
};

export enum OrganizationSubscriptionLevel {
  Enterprise = 'Enterprise',
  Inactive = 'Inactive',
  Premium = 'Premium',
  Projectmanagement = 'Projectmanagement',
  Qualitycontrol = 'Qualitycontrol',
  Trial = 'Trial'
}

export type OsidDeviceLinkStateResponse = {
  __typename?: 'OsidDeviceLinkStateResponse';
  data?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OsidDeviceLog = {
  __typename?: 'OsidDeviceLog';
  id: Scalars['String'];
  timestamp: Scalars['String'];
  deviceCode: Scalars['String'];
  eventType: Scalars['String'];
  adres?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type OsidDevicesResponse = {
  __typename?: 'OsidDevicesResponse';
  data?: Maybe<OsidDevicesType>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OsidDevicesType = {
  __typename?: 'OsidDevicesType';
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Page = {
  __typename?: 'Page';
  pageNumber?: Maybe<Scalars['Int']>;
  zoomLevels?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ParentFolder = {
  __typename?: 'ParentFolder';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ParentFolderPermission = {
  __typename?: 'ParentFolderPermission';
  parentFolder?: Maybe<ParentFolder>;
  permission?: Maybe<FolderPermission>;
};

export type PasteDocumentsDictionary = {
  key: Scalars['ID'];
  value: Scalars['ID'];
};

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  last3Digits: Scalars['String'];
  isValid?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Int']>;
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String'];
  resourceTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
};

export type PhaseBuild12 = {
  __typename?: 'PhaseBuild12';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Position = {
  __typename?: 'Position';
  page?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  typeId?: Maybe<Scalars['String']>;
};

export type Procedure = {
  __typename?: 'Procedure';
  id: Scalars['ID'];
  description: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['ID'];
  projectMainProcedure: Scalars['Boolean'];
  tasks?: Maybe<Array<Task>>;
};


export type ProcedureTasksArgs = {
  typeFilter?: Maybe<Array<Maybe<TaskType>>>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  availableRoles?: Maybe<Array<Maybe<Role>>>;
  deadline?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  folders?: Maybe<Array<Maybe<Folder>>>;
  mainProcedure?: Maybe<Procedure>;
  members?: Maybe<Array<Maybe<Member>>>;
  metaData?: Maybe<MetaData>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId: Scalars['ID'];
  procedures?: Maybe<Array<Maybe<Procedure>>>;
  projectNumber: Scalars['String'];
  settings?: Maybe<ProjectSettings>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type ProjectFoldersArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
};

export type ProjectByFolderResponse = {
  __typename?: 'ProjectByFolderResponse';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Array<Maybe<LinkInfo>>>;
};

export type ProjectInfo = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProjectLinkResponse = Response & {
  __typename?: 'ProjectLinkResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectId: Scalars['ID'];
  success?: Maybe<Scalars['Boolean']>;
};

export type ProjectRoles = {
  __typename?: 'ProjectRoles';
  project?: Maybe<Project>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type ProjectSettings = {
  __typename?: 'ProjectSettings';
  metadata_required?: Maybe<RequiredMetaDataFieldOption>;
  version_name_instead_of_document_name_on_upload?: Maybe<UploadFileOption>;
  approve_action_label?: Maybe<Scalars['String']>;
  reject_action_label?: Maybe<Scalars['String']>;
  finalize_action_label?: Maybe<Scalars['String']>;
  ai_admin_settings?: Maybe<Scalars['Boolean']>;
};

export type ProjectSettingsInput = {
  metadata_required?: Maybe<RequiredMetaDataFieldOption>;
  version_name_instead_of_document_name_on_upload?: Maybe<UploadFileOption>;
  approve_action_label?: Maybe<Scalars['String']>;
  reject_action_label?: Maybe<Scalars['String']>;
  finalize_action_label?: Maybe<Scalars['String']>;
  ai_admin_settings?: Maybe<Scalars['Boolean']>;
};

export type ProjectUnread = {
  __typename?: 'ProjectUnread';
  id: Scalars['ID'];
  unreadCount?: Maybe<Scalars['Int']>;
};

export enum QcInstanceEventType {
  AnswerAddedEvent = 'AnswerAddedEvent',
  AnswerUpdatedEvent = 'AnswerUpdatedEvent',
  AnswersAddedEvent = 'AnswersAddedEvent',
  InstanceAddedEvent = 'InstanceAddedEvent',
  InstanceCopiedEvent = 'InstanceCopiedEvent',
  InstanceUpdatedEvent = 'InstanceUpdatedEvent'
}

export type QcInstanceHistory = {
  __typename?: 'QCInstanceHistory';
  today?: Maybe<Array<QcHistoryEvent>>;
  thisWeek?: Maybe<Array<QcHistoryEvent>>;
  thisMonth?: Maybe<Array<QcHistoryEvent>>;
  older?: Maybe<Array<QcHistoryEvent>>;
};

export type QcSearchTask = {
  __typename?: 'QCSearchTask';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TaskType>;
  status?: Maybe<Status>;
  id?: Maybe<Scalars['ID']>;
  assignment?: Maybe<Array<User>>;
  endTime?: Maybe<Scalars['DateTime']>;
};

export type QcTemplate = {
  __typename?: 'QCTemplate';
  templateId?: Maybe<Scalars['ID']>;
  templateVersion?: Maybe<Scalars['Int']>;
  indicativeState?: Maybe<State>;
  isValid?: Maybe<Scalars['Boolean']>;
  firstAnswerAt?: Maybe<Scalars['DateTime']>;
};

export type QcHistoryEvent = {
  __typename?: 'QcHistoryEvent';
  changeDate: Scalars['DateTime'];
  commandId?: Maybe<Scalars['ID']>;
  historyId: Scalars['ID'];
  name: QcInstanceEventType;
  properties: Array<QcHistoryProperty>;
  userId: Scalars['ID'];
  resourceId: Scalars['ID'];
  user: User;
};

export type QcHistoryProperty = {
  __typename?: 'QcHistoryProperty';
  addedValues: Array<Scalars['String']>;
  addedResolvedValues?: Maybe<Array<Scalars['String']>>;
  changeDate: Scalars['DateTime'];
  deletedValues: Array<Scalars['String']>;
  deletedResolvedValues: Array<Scalars['String']>;
  resourceId: Scalars['ID'];
  historyId: Scalars['ID'];
  name: Scalars['String'];
  type: QcPropertyType;
};

export enum QcPropertyType {
  Text = 'Text',
  Project = 'Project',
  Organization = 'Organization',
  Metadata = 'Metadata',
  Tag = 'Tag',
  Number = 'Number',
  Field = 'Field',
  Resource = 'Resource',
  Template = 'Template',
  VisualContext = 'VisualContext',
  Bool = 'Bool',
  DateTime = 'DateTime',
  Description = 'Description',
  Media = 'Media',
  MultiSelect = 'MultiSelect',
  Section = 'Section',
  Selection = 'Selection',
  Signature = 'Signature',
  StandardItem = 'StandardItem',
  Status = 'Status',
  TreeSelection = 'TreeSelection',
  UserSelection = 'UserSelection',
  TimeSpan = 'TimeSpan',
  HierarchyList = 'HierarchyList'
}

export type QualityControlInstance = {
  __typename?: 'QualityControlInstance';
  id: Scalars['ID'];
  answers?: Maybe<Array<AnswerType>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  iconName?: Maybe<Scalars['String']>;
  items?: Maybe<Array<FieldDefinition>>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']>;
  reference: Scalars['String'];
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['ID']>;
  type: QualityControlInstanceType;
  visualContext?: Maybe<VisualContext>;
  visualContextId?: Maybe<Scalars['ID']>;
  indicativeState?: Maybe<State>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Int']>;
  isOptimistic?: Maybe<Scalars['Boolean']>;
  templates?: Maybe<Array<QcTemplate>>;
  linkedSnags?: Maybe<Array<FormLinkedSnag>>;
  linkedSnagInstances?: Maybe<Array<QualityControlInstance>>;
  linkedFieldId?: Maybe<Scalars['String']>;
  commentStatus?: Maybe<CommentStatus>;
  templateIconProps?: Maybe<TemplateIconProps>;
  templateVersion?: Maybe<Scalars['Int']>;
};

export enum QualityControlInstanceType {
  Document = 'document',
  Form = 'form',
  FreeSnag = 'freeSnag',
  General = 'general',
  Snag = 'snag',
  Tbq = 'tbq'
}

export type QualityControlTaskInstance = {
  __typename?: 'QualityControlTaskInstance';
  answers?: Maybe<Array<AnswerType>>;
  instanceId: Scalars['ID'];
  ownerId?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  indicativeState?: Maybe<State>;
  templates?: Maybe<Array<QcTemplate>>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deleteBy?: Maybe<Scalars['String']>;
  deleteAt?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  ObjectInclude?: Maybe<ObjectNode>;
  _empty?: Maybe<Scalars['String']>;
  allTemplateItemsByScope?: Maybe<Array<Maybe<FieldDefinition>>>;
  allowedGroups?: Maybe<Array<Maybe<Group>>>;
  annotations?: Maybe<Array<Maybe<Annotation>>>;
  apiKey?: Maybe<ApiKey>;
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  baseFolderByPath?: Maybe<Folder>;
  batchQualityControlInstances: Array<QualityControlInstance>;
  checkCommandStatus?: Maybe<CommandStatus>;
  commentThread?: Maybe<CommentThread>;
  connection: Connection;
  connections: Array<IntegrationWithConnection>;
  countries: CountryDataResponse;
  document?: Maybe<Document>;
  documentClientDownloadUrls?: Maybe<ClientDownloadUrls>;
  documentHistory?: Maybe<DocumentHistory>;
  documentState?: Maybe<Document>;
  documentVersion?: Maybe<Array<Maybe<DocumentVersion>>>;
  documents?: Maybe<Array<Maybe<Document>>>;
  downloadFileNameConfig?: Maybe<DownloadFilenameConfig>;
  downloadFilenameCharacters?: Maybe<Array<Maybe<DownloadFilenameCharacter>>>;
  fieldDefinition?: Maybe<FieldDefinition>;
  fieldDefinitionTypes?: Maybe<Array<Maybe<FieldDefinitionType>>>;
  fieldDefinitions?: Maybe<Array<Maybe<FieldDefinition>>>;
  filesExistsBuild12: FilesExistsBuild12;
  filesExistsSnagstream: FilesExistsSnagstream;
  folder?: Maybe<Folder>;
  folderByPath?: Maybe<Folder>;
  folderWithPermissionsByPath?: Maybe<Folder>;
  folders?: Maybe<Array<Maybe<Folder>>>;
  foldersById?: Maybe<Array<Maybe<Folder>>>;
  foldersByPath?: Maybe<Array<Maybe<Folder>>>;
  getNotifications?: Maybe<Notifications>;
  getOrganizationTaskCategoriesByType?: Maybe<Array<TaskCategory>>;
  getProjectPermissions?: Maybe<UserQcPermissions>;
  getProjectTaskCategoriesByType?: Maybe<Array<TaskCategory>>;
  getSignature?: Maybe<GetSignature>;
  getTBQBrandcheckMultiResult?: Maybe<Array<TbqBrandcheckMultiResult>>;
  getTBQGlobalScope: Array<TbqGlobalScope>;
  getTBQOsidDeviceLinkState: OsidDeviceLinkStateResponse;
  getTBQOsidDeviceLogs?: Maybe<Array<OsidDeviceLog>>;
  getTBQOsidDevices?: Maybe<OsidDevicesResponse>;
  getTBQResult?: Maybe<TbqResult>;
  getTBQRieResult?: Maybe<Array<TbqRieResult>>;
  getTbqConfig?: Maybe<Array<TbqConfig>>;
  group?: Maybe<Group>;
  groups?: Maybe<Array<Maybe<Group>>>;
  groupsAndUsers?: Maybe<Array<Maybe<Member>>>;
  hierarchicalList: HierarchicalList;
  hierarchicalLists?: Maybe<Array<HierarchicalList>>;
  instanceWithSuggestedAnswers?: Maybe<SuggestedAnswersBasedOnImageResponse>;
  instances?: Maybe<Array<Maybe<QualityControlInstance>>>;
  integration: Integration;
  integrations: Array<Integration>;
  loadQueries?: Maybe<Array<Maybe<SavedSearch>>>;
  me?: Maybe<Me>;
  messageBranch: MessageBranch;
  messageInbox: Array<Maybe<Message>>;
  myOrganization: Organization;
  myOrganizationProjects?: Maybe<Array<Project>>;
  myProjectRoles: Array<Role>;
  myProjects?: Maybe<Array<Project>>;
  myTasks?: Maybe<Array<Task>>;
  object?: Maybe<ObjectNode>;
  objects: Array<ObjectNode>;
  officeOnlineInfo?: Maybe<OfficeOnlineInfo>;
  openCdeUrl?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationExists?: Maybe<Scalars['Boolean']>;
  organizationUsers?: Maybe<Array<Maybe<User>>>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  pdfTronLicenseKey: Scalars['String'];
  phaseBuild12: PhaseBuild12;
  phasesBuild12: Array<PhaseBuild12>;
  privateLinkData?: Maybe<SharedLinkDataResponse>;
  procedure?: Maybe<Procedure>;
  procedures?: Maybe<Array<Maybe<Procedure>>>;
  project: Project;
  projectMembers?: Maybe<Array<Maybe<Member>>>;
  projects: Array<Maybe<Project>>;
  projectsBuild12: Array<IntegrationProject>;
  projectsByFolder?: Maybe<ProjectByFolderResponse>;
  projectsSnagstream: Array<IntegrationProject>;
  projectsUnreadCount: Array<Maybe<ProjectUnread>>;
  publicAttachmentData?: Maybe<SharedAttachmentDataResponse>;
  publicLinkData?: Maybe<SharedLinkDataResponse>;
  qrCodeState?: Maybe<StampStatus>;
  qualityControlGetOrCreateInstance?: Maybe<QualityControlInstance>;
  qualityControlInstance?: Maybe<QualityControlInstance>;
  qualityControlInstances: Array<QualityControlInstance>;
  qualityControlTask?: Maybe<Task>;
  qualityControlTaskInstances?: Maybe<Array<Maybe<QualityControlInstance>>>;
  reportBackgrounds?: Maybe<Array<ReportImage>>;
  reportConfiguration?: Maybe<ReportConfiguration>;
  reportConfigurations?: Maybe<Array<Maybe<ReportConfiguration>>>;
  reportLogos?: Maybe<Array<ReportImage>>;
  resourcePermissions?: Maybe<ResourcePermissions>;
  savedAnswers?: Maybe<Array<SavedAnswers>>;
  savedAnswersInstance?: Maybe<SavedAnswersInstance>;
  savedAnswersOnTemplateId?: Maybe<Array<SavedAnswers>>;
  scopedLinkData?: Maybe<SharedLinkDataResponse>;
  search?: Maybe<SearchResponse>;
  smartFolder?: Maybe<SmartFolder>;
  smartFolders?: Maybe<Array<Maybe<SmartFolder>>>;
  snagFormHistoryLog?: Maybe<QcInstanceHistory>;
  stamps?: Maybe<StampsResponse>;
  standardFieldDefinitions?: Maybe<Array<Maybe<FieldDefinition>>>;
  subscriptionLevels: SubscriptionLevelsDataResponse;
  subscriptionOrganization?: Maybe<SubscriptionOrganizationDataResponse>;
  subscriptionOrganizationExpire?: Maybe<SubscriptionOrganizationExpireDataResponse>;
  subscriptionOrganizationUsers?: Maybe<SubscriptionOrganizationUsersDataResponse>;
  suggestedTemplatesBasedOnImage?: Maybe<SuggestedTemplatesBasedOnImageResponse>;
  supportSettings?: Maybe<Scalars['String']>;
  supportedFormats: Array<Scalars['String']>;
  tag?: Maybe<Tag>;
  tags: Array<Maybe<Tag>>;
  task?: Maybe<Task>;
  taskStatuses?: Maybe<Array<TaskStatus>>;
  tasksBatchInclude?: Maybe<Array<Task>>;
  templateItems?: Maybe<Array<Maybe<FieldDefinition>>>;
  templates?: Maybe<Array<Template>>;
  templatesByIds?: Maybe<Array<Template>>;
  testMobileAppFallBackApi?: Maybe<Scalars['String']>;
  threeDConversionStatus?: Maybe<ThreeDConversionStatus>;
  threeDConversionStatuses?: Maybe<Array<Maybe<ThreeDConversionStatus>>>;
  token?: Maybe<Scalars['String']>;
  unreadCount?: Maybe<Array<Maybe<UnreadMessages>>>;
  userExists?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<User>>;
  version?: Maybe<DocumentVersion>;
  versions?: Maybe<Array<Maybe<DocumentVersion>>>;
  visualContext?: Maybe<VisualContext>;
  visualContexts?: Maybe<Array<VisualContext>>;
  visualContextsForTask?: Maybe<Array<VisualContext>>;
};


export type QueryObjectIncludeArgs = {
  id: Scalars['ID'];
  includeProjectId?: Maybe<Scalars['Boolean']>;
  includeTasks?: Maybe<Scalars['Boolean']>;
  includeProject?: Maybe<Scalars['Boolean']>;
};


export type QueryAllTemplateItemsByScopeArgs = {
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  state?: Maybe<TemplateState>;
  templateService: TemplateService;
  templateType: TemplateType;
};


export type QueryAllowedGroupsArgs = {
  groupId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryAnnotationsArgs = {
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
};


export type QueryApiKeyArgs = {
  id: Scalars['ID'];
};


export type QueryBaseFolderByPathArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryBatchQualityControlInstancesArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type QueryCheckCommandStatusArgs = {
  id: Scalars['ID'];
};


export type QueryCommentThreadArgs = {
  targetId?: Maybe<Scalars['ID']>;
};


export type QueryConnectionArgs = {
  id: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryConnectionsArgs = {
  filterOnActiveConnections?: Maybe<Scalars['Boolean']>;
  filterOnIntegrationType?: Maybe<IntegrationType>;
  projectId: Scalars['ID'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentHistoryArgs = {
  documentId: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
  fetchForVersion: Scalars['Boolean'];
  metadataInstanceId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryDocumentStateArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentVersionArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  documentIds: Array<Maybe<Scalars['ID']>>;
};


export type QueryDownloadFileNameConfigArgs = {
  templateId: Scalars['ID'];
};


export type QueryFieldDefinitionArgs = {
  fieldDefinitionId: Scalars['ID'];
  templateService?: Maybe<TemplateService>;
};


export type QueryFieldDefinitionTypesArgs = {
  templateService?: Maybe<TemplateService>;
};


export type QueryFieldDefinitionsArgs = {
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  templateService?: Maybe<TemplateService>;
};


export type QueryFilesExistsBuild12Args = {
  versionIds: Array<Maybe<Scalars['ID']>>;
  projectId: Scalars['ID'];
  connectionId: Scalars['ID'];
  projectPhaseId: Scalars['ID'];
};


export type QueryFilesExistsSnagstreamArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
  projectId: Scalars['ID'];
  connectionId: Scalars['ID'];
};


export type QueryFolderArgs = {
  id: Scalars['ID'];
};


export type QueryFolderByPathArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryFolderWithPermissionsByPathArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  entityIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryFoldersArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  includePermissionViaGroup?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  parentFolderIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryFoldersByIdArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryFoldersByPathArgs = {
  includeDeleted?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryGetNotificationsArgs = {
  searchId?: Maybe<Scalars['String']>;
  isProject?: Maybe<Scalars['Boolean']>;
  scope: NotificationScope;
};


export type QueryGetOrganizationTaskCategoriesByTypeArgs = {
  taskType: TaskType;
};


export type QueryGetProjectPermissionsArgs = {
  memberId: Scalars['ID'];
  memberType: Scalars['Int'];
  isMemberGroupFromOrg?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
};


export type QueryGetProjectTaskCategoriesByTypeArgs = {
  projectId?: Maybe<Scalars['ID']>;
  taskType?: Maybe<TaskType>;
};


export type QueryGetTbqBrandcheckMultiResultArgs = {
  objectId: Scalars['ID'];
  resultSet?: Maybe<ResultSet>;
};


export type QueryGetTbqOsidDeviceLinkStateArgs = {
  objectId: Scalars['ID'];
};


export type QueryGetTbqOsidDeviceLogsArgs = {
  deviceCode: Scalars['String'];
  objectId: Scalars['String'];
};


export type QueryGetTbqResultArgs = {
  taskId: Scalars['ID'];
  resultSet?: Maybe<ResultSet>;
};


export type QueryGetTbqRieResultArgs = {
  taskId: Scalars['ID'];
};


export type QueryGetTbqConfigArgs = {
  tbqTypeId: Scalars['ID'];
};


export type QueryGroupArgs = {
  groupId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryGroupsArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryGroupsAndUsersArgs = {
  projectId?: Maybe<Scalars['ID']>;
  fetchInvitedUsers?: Maybe<Scalars['Boolean']>;
};


export type QueryHierarchicalListArgs = {
  id: Scalars['ID'];
};


export type QueryHierarchicalListsArgs = {
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryInstanceWithSuggestedAnswersArgs = {
  templateId: Scalars['ID'];
  fileId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  page?: Maybe<Scalars['Int']>;
  visualContextId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryInstancesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};


export type QueryLoadQueriesArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryMessageBranchArgs = {
  messageId: Scalars['ID'];
  authorName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  replyAll?: Maybe<Scalars['Boolean']>;
  replyId?: Maybe<Scalars['ID']>;
  action?: Maybe<EmailActions>;
  resendId?: Maybe<Scalars['ID']>;
};


export type QueryMessageInboxArgs = {
  projectId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  sentOrInbox?: Maybe<SentOrInbox>;
};


export type QueryMyProjectRolesArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryObjectArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryObjectsArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryOfficeOnlineInfoArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationExistsArgs = {
  name: Scalars['String'];
};


export type QueryPdfTronLicenseKeyArgs = {
  documentVersionId: Scalars['ID'];
};


export type QueryPhaseBuild12Args = {
  id: Scalars['ID'];
};


export type QueryPhasesBuild12Args = {
  connectionId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type QueryPrivateLinkDataArgs = {
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
};


export type QueryProcedureArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectMembersArgs = {
  projectId: Scalars['ID'];
};


export type QueryProjectsArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type QueryProjectsBuild12Args = {
  integrationId: Scalars['ID'];
};


export type QueryProjectsByFolderArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
};


export type QueryProjectsSnagstreamArgs = {
  integrationId: Scalars['ID'];
};


export type QueryProjectsUnreadCountArgs = {
  isOrganization?: Maybe<Scalars['Boolean']>;
};


export type QueryPublicAttachmentDataArgs = {
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
};


export type QueryPublicLinkDataArgs = {
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};


export type QueryQrCodeStateArgs = {
  versionId: Scalars['ID'];
};


export type QueryQualityControlGetOrCreateInstanceArgs = {
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  templateId: Scalars['ID'];
};


export type QueryQualityControlInstanceArgs = {
  id: Scalars['ID'];
};


export type QueryQualityControlInstancesArgs = {
  projectId: Scalars['ID'];
  visualContextIds?: Maybe<Array<Scalars['ID']>>;
  resourceIds?: Maybe<Array<Scalars['ID']>>;
  pages?: Maybe<Array<Scalars['Int']>>;
};


export type QueryQualityControlTaskArgs = {
  id: Scalars['ID'];
};


export type QueryQualityControlTaskInstancesArgs = {
  taskId: Scalars['ID'];
  templateId?: Maybe<Scalars['ID']>;
};


export type QueryReportConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryReportConfigurationsArgs = {
  projectId: Scalars['ID'];
};


export type QueryResourcePermissionsArgs = {
  projectId: Scalars['ID'];
  resourceId: Scalars['ID'];
  resourceType: ResourceType;
};


export type QuerySavedAnswersArgs = {
  projectId: Scalars['ID'];
};


export type QuerySavedAnswersInstanceArgs = {
  id: Scalars['ID'];
};


export type QuerySavedAnswersOnTemplateIdArgs = {
  templateId: Scalars['ID'];
};


export type QueryScopedLinkDataArgs = {
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
};


export type QuerySearchArgs = {
  documentType?: Maybe<Scalars['String']>;
  facet?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  contextQuery?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySmartFolderArgs = {
  id: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type QuerySmartFoldersArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QuerySnagFormHistoryLogArgs = {
  id: Scalars['ID'];
};


export type QueryStampsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySubscriptionOrganizationArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


export type QuerySubscriptionOrganizationExpireArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


export type QuerySuggestedTemplatesBasedOnImageArgs = {
  projectId: Scalars['ID'];
  base64ImageString: Scalars['String'];
  templateType: TemplateType;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTaskStatusesArgs = {
  id: Scalars['ID'];
  status: Status;
};


export type QueryTasksBatchIncludeArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTemplateItemsArgs = {
  id: Scalars['ID'];
  templateService: TemplateService;
};


export type QueryTemplatesArgs = {
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  globalId?: Maybe<Scalars['ID']>;
  selectedIds?: Maybe<Array<Scalars['ID']>>;
  state?: Maybe<TemplateState>;
  templateService: TemplateService;
  templateType: TemplateType;
};


export type QueryTemplatesByIdsArgs = {
  ids: Array<Scalars['ID']>;
  templateService: TemplateService;
};


export type QueryThreeDConversionStatusArgs = {
  versionId: Scalars['ID'];
};


export type QueryThreeDConversionStatusesArgs = {
  versionIds: Array<Maybe<Scalars['ID']>>;
};


export type QueryUnreadCountArgs = {
  isOrganization?: Maybe<Scalars['Boolean']>;
};


export type QueryUserExistsArgs = {
  email: Scalars['String'];
};


export type QueryUsersArgs = {
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryVersionArgs = {
  id: Scalars['ID'];
};


export type QueryVersionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryVisualContextArgs = {
  id: Scalars['ID'];
  resourceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryVisualContextsArgs = {
  projectId: Scalars['ID'];
};


export type QueryVisualContextsForTaskArgs = {
  id: Scalars['ID'];
};

export type QuestionInput = {
  fieldId: Scalars['ID'];
  resourceAId: Scalars['ID'];
  resourceAType: ResourceType;
  resourceBId: Scalars['ID'];
  resourceBType: ResourceType;
};

export type Reference = {
  __typename?: 'Reference';
  referenceData?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['ID']>;
  referenceKind: ReferenceKind;
  referenceType?: Maybe<ReferenceType>;
  referenceValue: Scalars['String'];
};

export type ReferenceInput = {
  referenceData?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['ID']>;
  referenceKind: ReferenceKind;
  referenceType?: Maybe<ReferenceType>;
  referenceValue: Scalars['String'];
};

export enum ReferenceKind {
  Body = 'Body',
  Target = 'Target',
  To = 'To',
  LinkInfo = 'LinkInfo',
  Cc = 'Cc',
  Bcc = 'Bcc',
  Sender = 'Sender',
  Mentions = 'Mentions'
}

export enum ReferenceType {
  Document = 'Document',
  DocumentVersion = 'DocumentVersion',
  Form = 'Form',
  Mention = 'Mention',
  Group = 'Group',
  GroupMention = 'GroupMention',
  GroupSnapshot = 'GroupSnapshot',
  Project = 'Project',
  Folder = 'Folder',
  Snag = 'Snag',
  Thread = 'Thread',
  User = 'User',
  Email = 'Email',
  Organization = 'Organization',
  Task = 'Task'
}

export type RemoveStampInput = {
  documentId: Scalars['ID'];
  id: Scalars['ID'];
};

export type RemoveStampsResponse = Response & {
  __typename?: 'RemoveStampsResponse';
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type ReportConfiguration = {
  __typename?: 'ReportConfiguration';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  reportOptions: Scalars['String'];
  scope?: Maybe<ScopeType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type ReportImage = {
  __typename?: 'ReportImage';
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  imageType?: Maybe<ImageType>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum RequiredMetaDataFieldOption {
  Enforce = 'Enforce',
  DoNotEnforce = 'DoNotEnforce'
}

export type ResolveTaskLinks = {
  __typename?: 'ResolveTaskLinks';
  appData?: Maybe<Scalars['String']>;
  direction?: Maybe<Direction>;
  id?: Maybe<Scalars['String']>;
  linkId?: Maybe<Scalars['String']>;
  linkType?: Maybe<LinkType>;
  type?: Maybe<ResourceType>;
};

export type Resource = {
  resourceType: Scalars['Int'];
  resourceId: Scalars['ID'];
};

export type ResourcePermissions = {
  __typename?: 'ResourcePermissions';
  permissions: Array<Scalars['String']>;
};

export enum ResourceType {
  Organization = 'organization',
  Project = 'project',
  Group = 'group',
  Procedure = 'procedure',
  Task = 'task',
  Milestone = 'milestone',
  Document = 'document',
  Folder = 'folder',
  Instance = 'instance',
  VisualContext = 'visualContext',
  Template = 'template',
  TbqInstance = 'tbqInstance',
  Field = 'field',
  Object = 'object'
}

export type Response = {
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RestoreInstanceData = {
  __typename?: 'RestoreInstanceData';
  instanceId?: Maybe<Scalars['ID']>;
};

export type RestoreInstanceResponse = {
  __typename?: 'RestoreInstanceResponse';
  data?: Maybe<RestoreInstanceData>;
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ResultSet {
  Victims = 'Victims',
  VictimsPlus = 'VictimsPlus',
  Damage = 'Damage',
  DamagePlus = 'DamagePlus'
}

export type RevokeLinkResponse = Response & {
  __typename?: 'RevokeLinkResponse';
  id: Scalars['ID'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type RieCondition = {
  __typename?: 'RieCondition';
  fieldId: Scalars['ID'];
  value: Scalars['String'];
};

export type RieOption = {
  __typename?: 'RieOption';
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type RieResult = {
  __typename?: 'RieResult';
  color: Scalars['String'];
  index: Scalars['Int'];
  name: Scalars['String'];
};

export type RieSection = {
  __typename?: 'RieSection';
  type: Scalars['String'];
  parentSectionId: Scalars['ID'];
  valueType: RieValueTypeSection;
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  tagId?: Maybe<Scalars['ID']>;
  conditions?: Maybe<Array<Maybe<RieCondition>>>;
};

export type RieSubField = {
  __typename?: 'RieSubField';
  name: Scalars['String'];
  type: Scalars['String'];
  parentSectionId: Scalars['ID'];
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  valueType: RieValueType;
  linksAllowed: Scalars['Boolean'];
  required: Scalars['Boolean'];
  indicative: Scalars['Boolean'];
  tagId?: Maybe<Scalars['ID']>;
  fieldDefinitionId?: Maybe<Scalars['ID']>;
  fieldDefinitionDeleted: Scalars['Boolean'];
  value?: Maybe<Scalars['String']>;
  conditions?: Maybe<Array<Maybe<RieCondition>>>;
};

export type RieValueType = {
  __typename?: 'RieValueType';
  multiSelect?: Maybe<Scalars['Boolean']>;
  displayType?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<RieOption>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RieValueTypeSection = {
  __typename?: 'RieValueTypeSection';
  subFields: Array<RieSubField>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SavedAnswers = {
  __typename?: 'SavedAnswers';
  answers?: Maybe<Array<AnswerType>>;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  templateId: Scalars['ID'];
  templateName: Scalars['String'];
  type: TemplateType;
};

export type SavedAnswersInstance = {
  __typename?: 'SavedAnswersInstance';
  answers?: Maybe<Array<AnswerType>>;
  id: Scalars['String'];
  items?: Maybe<Array<FieldDefinition>>;
  name: Scalars['String'];
};

export type SavedSearch = {
  __typename?: 'SavedSearch';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationDays: Array<Maybe<WeekDay>>;
  notificationsEnabled: Scalars['Boolean'];
  notificationTimes: Array<Maybe<ScheduledTime>>;
  projectId?: Maybe<Scalars['String']>;
  searchQuery?: Maybe<Scalars['String']>;
  searchFilter?: Maybe<Scalars['String']>;
  scope: Scalars['Int'];
};

export type SavedSearchResponse = Response & {
  __typename?: 'SavedSearchResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export enum ScanType {
  Brandcheck = 'brandcheck',
  Thing = 'thing'
}

export type ScheduledTime = {
  __typename?: 'ScheduledTime';
  hour: Scalars['String'];
  minute: Scalars['String'];
};

export type ScheduledTimeInput = {
  hour: Scalars['String'];
  minute: Scalars['String'];
};

export type Scope = {
  __typename?: 'Scope';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<ScopeType>;
};

export enum ScopeType {
  Global = 'global',
  Organization = 'organization',
  Project = 'project'
}

export type SearchDocument = {
  __typename?: 'SearchDocument';
  id: Scalars['ID'];
  deletedBy?: Maybe<SearchUser>;
  commentStatus?: Maybe<CommentStatus>;
  createdBy?: Maybe<SearchUser>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
  hasPreviousVersionQr?: Maybe<Scalars['Boolean']>;
  isExpected?: Maybe<Scalars['Boolean']>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedBy?: Maybe<User>;
  lockType?: Maybe<LockType>;
  metaDataInstance?: Maybe<MetaDataInstance>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  pathIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<SearchProject>;
  projectId?: Maybe<Scalars['ID']>;
  reference?: Maybe<Scalars['String']>;
  qrCodeState?: Maybe<StampStatus>;
  state?: Maybe<FinalizationState>;
  indicativeState?: Maybe<State>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<SearchUser>;
  versionId?: Maybe<Scalars['ID']>;
  versionNumber?: Maybe<Scalars['Int']>;
  versionState?: Maybe<DocumentVersionState>;
  versions?: Maybe<Array<Maybe<DocumentVersion>>>;
  visualContext?: Maybe<VisualContext>;
  task?: Maybe<Array<Maybe<QcSearchTask>>>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Int']>;
  templateIconProps?: Maybe<TemplateIconProps>;
};

export type SearchDocumentMetaQuestion = {
  __typename?: 'SearchDocumentMetaQuestion';
  fieldDefinitionId?: Maybe<Scalars['ID']>;
  answer?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  valueType?: Maybe<ValueTypeName>;
  templateId?: Maybe<Scalars['ID']>;
  fieldId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['String']>;
};

export type SearchProject = {
  __typename?: 'SearchProject';
  projectNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SearchQcInstance = {
  __typename?: 'SearchQCInstance';
  id: Scalars['ID'];
  answers?: Maybe<Array<Maybe<AnswerType>>>;
  commentStatus?: Maybe<CommentStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  iconName?: Maybe<Scalars['String']>;
  items?: Maybe<Array<FieldDefinition>>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']>;
  reference: Scalars['String'];
  task?: Maybe<Array<Maybe<QcSearchTask>>>;
  taskId?: Maybe<Scalars['ID']>;
  extension?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['ID']>;
  metaDataInstance?: Maybe<MetaDataInstance>;
  type: QualityControlInstanceType;
  visualContext?: Maybe<VisualContext>;
  visualContextId?: Maybe<Scalars['ID']>;
  state?: Maybe<FinalizationState>;
  indicativeState?: Maybe<State>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Int']>;
  isOptimistic?: Maybe<Scalars['Boolean']>;
  templates?: Maybe<Array<Maybe<QcTemplate>>>;
  linkedSnags?: Maybe<Array<Maybe<FormLinkedSnag>>>;
  templateIconProps?: Maybe<TemplateIconProps>;
};

export type SearchResponse = Response & {
  __typename?: 'SearchResponse';
  totalCount?: Maybe<Scalars['Int']>;
  searchResults?: Maybe<Array<SearchResult>>;
  facetGroups?: Maybe<Array<Maybe<FacetGroup>>>;
  requestBody?: Maybe<Scalars['String']>;
  debugInfo?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SearchResult = SearchDocument | SearchQcInstance;

export type SearchUser = {
  __typename?: 'SearchUser';
  displayName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export enum SegmentType {
  Character = 'CHARACTER',
  CustomInline = 'CUSTOM_INLINE',
  CustomReusable = 'CUSTOM_REUSABLE',
  Standard = 'STANDARD'
}

export type SelectedGroup = {
  id: Scalars['ID'];
  members: Array<Maybe<Scalars['ID']>>;
  scope?: Maybe<Scalars['Int']>;
};

export type SendShareLinkResponse = Response & {
  __typename?: 'SendShareLinkResponse';
  id?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export enum SentOrInbox {
  All = 'all',
  Sent = 'sent',
  Inbox = 'inbox'
}

export type SetProjectPermissionsResponse = {
  __typename?: 'SetProjectPermissionsResponse';
  projectId: Scalars['ID'];
};

export type SetTbqResponse = Response & {
  __typename?: 'SetTBQResponse';
  nodeId: Scalars['ID'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ShareType {
  Public = 'public',
  Private = 'private',
  Scoped = 'scoped'
}

export type SharedAttachmentDataResponse = Response & {
  __typename?: 'SharedAttachmentDataResponse';
  id?: Maybe<Scalars['ID']>;
  linkType?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  scopeId?: Maybe<Scalars['ID']>;
  zipDownloadUrl?: Maybe<Scalars['String']>;
  messageAttachments?: Maybe<Array<Maybe<SharedAttachments>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SharedAttachments = {
  __typename?: 'SharedAttachments';
  id: Scalars['ID'];
  name: Scalars['String'];
  downloadUrl: Scalars['String'];
};

export type SharedLinkDataResponse = Response & {
  __typename?: 'SharedLinkDataResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['ID']>;
  documentLinks?: Maybe<Array<Maybe<DocumentVersion>>>;
  documentVersions?: Maybe<Array<Maybe<DocumentVersion>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  latestDocumentVersions?: Maybe<Array<Maybe<DocumentVersion>>>;
  linkType?: Maybe<Scalars['Int']>;
  scopeId?: Maybe<Scalars['ID']>;
  showFolders?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
  zipDownloadUrl?: Maybe<Scalars['String']>;
};

export type SmartFolder = {
  __typename?: 'SmartFolder';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdBy?: Maybe<User>;
  documents?: Maybe<Array<Maybe<Document>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
};

export type SnagstreamConnection = {
  __typename?: 'SnagstreamConnection';
  connectionId: Scalars['String'];
  connectionName: Scalars['String'];
};

export enum SnagstreamState {
  NotShared = 'notShared',
  PreviousVersionShared = 'previousVersionShared',
  Shared = 'shared'
}

export type Stamp = {
  __typename?: 'Stamp';
  id: Scalars['ID'];
  versionId: Scalars['ID'];
  originalFileId: Scalars['ID'];
  originalFileSize: Scalars['Int'];
  fileId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  offsetX: Scalars['Float'];
  offsetY: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  type: StampType;
};

export type StampDocumentResponse = Response & {
  __typename?: 'StampDocumentResponse';
  documentId?: Maybe<Scalars['ID']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type StampParams = {
  createNewVersion?: Maybe<Scalars['Boolean']>;
  offsetX: Scalars['Float'];
  offsetY: Scalars['Float'];
  pageIndex?: Maybe<Scalars['Int']>;
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  stampId?: Maybe<Scalars['ID']>;
};

export enum StampState {
  None = 'None',
  Done = 'Done'
}

export enum StampStatus {
  None = 'none',
  Progress = 'progress',
  Done = 'done',
  Failed = 'failed'
}

export enum StampType {
  QrCode = 'QRCode'
}

export type StampsResponse = Response & {
  __typename?: 'StampsResponse';
  data: Array<Maybe<Stamp>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export enum StandardDateRange {
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastThreeDays = 'LastThreeDays',
  LastSevenDays = 'LastSevenDays',
  LastFourteenDays = 'LastFourteenDays',
  LastThirtyDays = 'LastThirtyDays',
  LastNinetyDays = 'LastNinetyDays',
  LastYear = 'LastYear'
}

export enum StandardItemKey {
  Approved = 'Approved',
  Build12 = 'Build12',
  Comments = 'Comments',
  Extension = 'Extension',
  Finalized = 'Finalized',
  Name = 'Name',
  Qr = 'Qr',
  Snagstream = 'Snagstream',
  Tags = 'Tags',
  ThreeD = 'ThreeD',
  UpdatedBy = 'UpdatedBy',
  UpdatedOn = 'UpdatedOn',
  Version = 'Version'
}

export type State = {
  __typename?: 'State';
  color?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type StateBuild12 = {
  __typename?: 'StateBuild12';
  connections?: Maybe<Array<Maybe<Build12Connection>>>;
  oldConnections?: Maybe<Array<Maybe<Build12Connection>>>;
  state: Build12State;
};

export type StateSnagstream = {
  __typename?: 'StateSnagstream';
  connections?: Maybe<Array<Maybe<SnagstreamConnection>>>;
  oldConnections?: Maybe<Array<Maybe<SnagstreamConnection>>>;
  state: SnagstreamState;
};

export enum Status {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Paused = 'Paused',
  Done = 'Done'
}

export type SubFolderPermission = {
  __typename?: 'SubFolderPermission';
  permission?: Maybe<FolderPermission>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  taskChange?: Maybe<Task>;
};


export type SubscriptionTaskChangeArgs = {
  id: Scalars['ID'];
};

export type SubscriptionLevel = {
  __typename?: 'SubscriptionLevel';
  iconName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPopular?: Maybe<Scalars['Boolean']>;
  isSelectable?: Maybe<Scalars['Boolean']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricing?: Maybe<Scalars['Float']>;
};

export type SubscriptionLevelsDataResponse = {
  __typename?: 'SubscriptionLevelsDataResponse';
  subscriptionLevels: Array<SubscriptionLevel>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SubscriptionOrganization = {
  __typename?: 'SubscriptionOrganization';
  billingEmail?: Maybe<Scalars['String']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  nextBillingCycle?: Maybe<Scalars['DateTime']>;
  nextSubscriptionLevelId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  paymentInfo?: Maybe<PaymentInfo>;
  subscriptionEndDate?: Maybe<Scalars['DateTime']>;
  subscriptionLevelId: Scalars['ID'];
};

export type SubscriptionOrganizationDataResponse = {
  __typename?: 'SubscriptionOrganizationDataResponse';
  subscriptionOrganization: SubscriptionOrganization;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SubscriptionOrganizationExpireDataResponse = {
  __typename?: 'SubscriptionOrganizationExpireDataResponse';
  expireDate: Scalars['DateTime'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SubscriptionOrganizationUsersDataResponse = {
  __typename?: 'SubscriptionOrganizationUsersDataResponse';
  isFixedPrice?: Maybe<Scalars['Boolean']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
  users?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
};

export type SuggestedAnswersBasedOnImageResponse = {
  __typename?: 'SuggestedAnswersBasedOnImageResponse';
  instanceId: Scalars['String'];
  suggestedAnswers: Array<AnswerType>;
};

export type SuggestedTemplatesBasedOnImageResponse = {
  __typename?: 'SuggestedTemplatesBasedOnImageResponse';
  mediaFileId: Scalars['String'];
  suggestedTemplates: Array<Template>;
  otherTemplates: Array<Template>;
};

export type SupportSettings = {
  __typename?: 'SupportSettings';
  date?: Maybe<Scalars['String']>;
};

export type Tbq = {
  __typename?: 'TBQ';
  tbqScanTypeId?: Maybe<Scalars['ID']>;
  tbqTypeId?: Maybe<Scalars['ID']>;
  tbqModuleType?: Maybe<TbqModuleType>;
};

export type TbqAnswer = {
  __typename?: 'TBQAnswer';
  id: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
  answerClass?: Maybe<Scalars['String']>;
  answerClassString?: Maybe<Scalars['String']>;
  answerOption?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  categoryName: Scalars['String'];
  class?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  goalName: Scalars['String'];
  operationGoalIds: Array<Scalars['ID']>;
  question: Scalars['String'];
  questionId: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
  value: Scalars['Boolean'];
  result?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  sectionText: Scalars['String'];
  sectionId: Scalars['String'];
};

export type TbqBrandcheckMultiResult = {
  __typename?: 'TBQBrandcheckMultiResult';
  id: Scalars['ID'];
  objectParentId?: Maybe<Scalars['ID']>;
  objectName: Scalars['String'];
  scanId: Scalars['ID'];
  answers: Array<TbqAnswer>;
  categories: Array<TbqCategory>;
  operationGoals: Array<TbqGoal>;
};

export type TbqCategory = {
  __typename?: 'TBQCategory';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
};

export type TbqConfig = {
  __typename?: 'TBQConfig';
  type: Scalars['String'];
  fields?: Maybe<Array<TbqFieldOption>>;
};

export type TbqFieldOption = {
  __typename?: 'TBQFieldOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  options?: Maybe<Array<TbqOption>>;
};

export type TbqFieldValue = {
  __typename?: 'TBQFieldValue';
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<TbqValue>;
};

export type TbqFunction = {
  __typename?: 'TBQFunction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TbqGlobalScope = {
  __typename?: 'TBQGlobalScope';
  name: Scalars['String'];
  scopeId: Scalars['ID'];
};

export type TbqGoal = {
  __typename?: 'TBQGoal';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  percentage: Scalars['Float'];
};

export enum TbqModuleType {
  Brandcheck = 'Brandcheck',
  Generic = 'Generic',
  Rie = 'RIE'
}

export type TbqObjectConfig = {
  __typename?: 'TBQObjectConfig';
  type: Scalars['String'];
  fields?: Maybe<Array<TbqFieldValue>>;
};

export type TbqOption = {
  __typename?: 'TBQOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
};

export type TbqResult = {
  __typename?: 'TBQResult';
  id: Scalars['ID'];
  answers: Array<TbqAnswer>;
  categories: Array<TbqCategory>;
  operationGoals: Array<TbqGoal>;
  instance: QualityControlInstance;
  shortcomings: Array<TbqAnswer>;
  tbqInstanceLinkedSnags?: Maybe<Array<FormLinkedSnag>>;
};

export type TbqRieResult = {
  __typename?: 'TBQRieResult';
  id: Scalars['ID'];
  section: RieSection;
  risc: Scalars['Int'];
  result: RieResult;
  category: Scalars['String'];
  class?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
  scanId?: Maybe<Scalars['String']>;
  instanceId?: Maybe<Scalars['String']>;
  questionLinkedResolveTask?: Maybe<Task>;
};

export type TbqRieScanType = {
  __typename?: 'TBQRieScanType';
  entityId: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['Int'];
  id: Scalars['String'];
};

export type TbqScanType = {
  __typename?: 'TBQScanType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TbqSetting = {
  settingId: Scalars['ID'];
  valueId: Scalars['ID'];
};

export type TbqTask = {
  nodeId: Scalars['ID'];
  projectId: Scalars['ID'];
  organizationId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  assignedUser?: Maybe<Scalars['ID']>;
  endTime?: Maybe<Scalars['DateTime']>;
  durationMinutes?: Maybe<Scalars['Int']>;
  tbqScanTypeId: Scalars['ID'];
  tbqModuleType?: Maybe<TbqModuleType>;
};

export type TbqType = {
  __typename?: 'TBQType';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export enum TbqTypeAbstract {
  Building = 'Building',
  BuildingPart = 'BuildingPart',
  Department = 'Department',
  Residence = 'Residence'
}

export type TbqValue = {
  __typename?: 'TBQValue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  documentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type TagInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  assignment?: Maybe<Array<User>>;
  attachments?: Maybe<Array<Maybe<Document>>>;
  availableStatuses?: Maybe<Array<TaskStatus>>;
  commentStatus?: Maybe<CommentStatus>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<User>;
  deleted?: Maybe<Scalars['Boolean']>;
  deliverables?: Maybe<Array<Maybe<Document>>>;
  description?: Maybe<Scalars['String']>;
  documentAction?: Maybe<DocumentAction>;
  durationMinutes?: Maybe<Scalars['UInt']>;
  endTime?: Maybe<Scalars['DateTime']>;
  formTemplates?: Maybe<Array<Template>>;
  id: Scalars['ID'];
  isOptimistic?: Maybe<Scalars['Boolean']>;
  linkedFormInstances?: Maybe<Array<QualityControlInstance>>;
  linkedSnagInstances?: Maybe<Array<QualityControlInstance>>;
  name?: Maybe<Scalars['String']>;
  procedureId: Scalars['ID'];
  project?: Maybe<Project>;
  snagTemplates?: Maybe<Array<Template>>;
  status?: Maybe<Status>;
  statusOrder?: Maybe<TaskStatusOrder>;
  taskCategoryId?: Maybe<Scalars['ID']>;
  taskCategoryName?: Maybe<Scalars['String']>;
  tbq?: Maybe<Tbq>;
  tbqFormInstances?: Maybe<Array<QualityControlInstance>>;
  tbqLinkedQuestions?: Maybe<Array<ResolveTaskLinks>>;
  tbqTbqResolveTaskLinkedObjectId?: Maybe<Scalars['String']>;
  type: TaskType;
  visualContexts?: Maybe<Array<VisualContext>>;
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  taskCategoryId: Scalars['ID'];
  organizationId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  taskType: TaskType;
  name: Scalars['String'];
};

export type TaskCategoryInput = {
  taskType?: Maybe<TaskType>;
  taskCategoryId: Scalars['ID'];
  organizationId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type TaskLink = {
  __typename?: 'TaskLink';
  task: Task;
  type?: Maybe<LinkType>;
};

export type TaskStatus = {
  __typename?: 'TaskStatus';
  taskStatus?: Maybe<Status>;
  available: Scalars['Boolean'];
  command?: Maybe<Scalars['String']>;
};

export type TaskStatusOrder = {
  __typename?: 'TaskStatusOrder';
  index: Scalars['Int'];
  status: Status;
};

export enum TaskType {
  Document = 'Document',
  QualityControl = 'QualityControl',
  Resolve = 'Resolve',
  Tbq = 'TBQ',
  TbqResolve = 'TBQResolve',
  TbqScan = 'TBQScan'
}

export type Template = {
  __typename?: 'Template';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  fields?: Maybe<Array<Maybe<FieldDefinition>>>;
  icon?: Maybe<TemplateIcon>;
  id: Scalars['ID'];
  items?: Maybe<Array<FieldDefinition>>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<Scope>;
  state?: Maybe<TemplateState>;
  type?: Maybe<TemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};


export type TemplateFieldsArgs = {
  draft?: Maybe<Scalars['Boolean']>;
  templateService?: Maybe<TemplateService>;
};

export type TemplateIcon = {
  __typename?: 'TemplateIcon';
  type?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TemplateIconProps = {
  __typename?: 'TemplateIconProps';
  iconName?: Maybe<Scalars['String']>;
  iconType?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['String']>;
};

export enum TemplateService {
  Documents = 'Documents',
  QualityControl = 'QualityControl'
}

export enum TemplateState {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published'
}

export enum TemplateType {
  Document = 'document',
  Field = 'field',
  Form = 'form',
  Section = 'section',
  Snag = 'snag',
  Tbq = 'tbq'
}

export type ThreeDConversionStatus = {
  __typename?: 'ThreeDConversionStatus';
  versionId?: Maybe<Scalars['ID']>;
  progress?: Maybe<Scalars['Int']>;
  fileUrn?: Maybe<Scalars['String']>;
  status?: Maybe<ConversionStatus>;
};

export type TimeSpan = {
  __typename?: 'TimeSpan';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type TimeSpanInput = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
};

export enum Type {
  Organization = 'organization',
  Project = 'project',
  Group = 'group',
  Procedure = 'procedure',
  Task = 'task',
  Milestone = 'milestone',
  Document = 'document',
  Folder = 'folder',
  Instance = 'instance',
  VisualContext = 'visualContext',
  Template = 'template',
  TbqInstance = 'tbqInstance',
  Field = 'field'
}


export type UnlinkLink = {
  __typename?: 'UnlinkLink';
  linkId?: Maybe<Scalars['ID']>;
};

export type UnreadMessages = {
  __typename?: 'UnreadMessages';
  id?: Maybe<Scalars['ID']>;
  all?: Maybe<Scalars['Int']>;
  inbox?: Maybe<Scalars['Int']>;
  sent?: Maybe<Scalars['Int']>;
};

export enum UploadFileOption {
  KeepCurrent = 'KeepCurrent',
  UseFromUploaded = 'UseFromUploaded'
}


export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  explicitProjectMember?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  folderPermissions?: Maybe<Array<Maybe<FolderPermission>>>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['Uri']>;
  invited?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastOnlineAt?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  scope?: Maybe<Scalars['Int']>;
  state?: Maybe<UserState>;
};


export type UserFolderPermissionsArgs = {
  projectId?: Maybe<Scalars['ID']>;
  folderId?: Maybe<Scalars['ID']>;
};

export type UserQcPermissions = {
  __typename?: 'UserQCPermissions';
  directPermissions?: Maybe<Array<Scalars['String']>>;
  inheritedPermissions?: Maybe<Array<InheritedPermission>>;
};

export enum UserState {
  Active = 'Active',
  Deactivated = 'Deactivated',
  InvitationExpired = 'InvitationExpired',
  InvitationNotSent = 'InvitationNotSent',
  InvitationSent = 'InvitationSent'
}

export type ValueType = {
  __typename?: 'ValueType';
  hierarchyListId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  maxValue?: Maybe<Scalars['Long']>;
  minValue?: Maybe<Scalars['Long']>;
  multiSelect?: Maybe<Scalars['Boolean']>;
  multipleAnswers?: Maybe<Scalars['Boolean']>;
  displayType?: Maybe<Scalars['String']>;
  name?: Maybe<ValueTypeName>;
  options?: Maybe<Array<MetaDataOption>>;
  rangeEnabled?: Maybe<Scalars['Boolean']>;
  staticBreadcrumbs?: Maybe<Scalars['Boolean']>;
  subFields?: Maybe<Array<FieldDefinition>>;
  subType?: Maybe<Scalars['String']>;
};

export enum ValueTypeName {
  Bool = 'Bool',
  DateTime = 'DateTime',
  Description = 'Description',
  HierarchyList = 'HierarchyList',
  Media = 'Media',
  MultiSelect = 'MultiSelect',
  Number = 'Number',
  Section = 'Section',
  Selection = 'Selection',
  Signature = 'Signature',
  StandardItem = 'StandardItem',
  Status = 'Status',
  Text = 'Text',
  TreeSelection = 'TreeSelection',
  UserSelection = 'UserSelection',
  TimeSpan = 'TimeSpan',
  Snags = 'Snags',
  Forms = 'Forms'
}

export type VersionMetaDataCreatedResponse = {
  __typename?: 'VersionMetaDataCreatedResponse';
  id?: Maybe<Scalars['ID']>;
  instanceId?: Maybe<Scalars['ID']>;
};

export type VisualContext = {
  __typename?: 'VisualContext';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  documentVersionId?: Maybe<Scalars['ID']>;
  drawing?: Maybe<Drawing>;
  formCount?: Maybe<Scalars['Int']>;
  freeSnagCount?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  instances?: Maybe<Array<Maybe<VisualContextInstance>>>;
  media?: Maybe<Media>;
  name: Scalars['String'];
  pageRange?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  qualityControlInstances?: Maybe<Array<QualityControlInstance>>;
  scope?: Maybe<Scope>;
  snagCount?: Maybe<Scalars['Int']>;
  pageCount?: Maybe<Scalars['Int']>;
  status?: Maybe<VisualContextStatus>;
  visualContextId?: Maybe<Scalars['ID']>;
  width?: Maybe<Scalars['Int']>;
  zoomLevels?: Maybe<Scalars['Int']>;
};


export type VisualContextQualityControlInstancesArgs = {
  resourceIds?: Maybe<Array<Scalars['ID']>>;
  projectId: Scalars['ID'];
};

export type VisualContextInput = {
  visualContextId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  page: Scalars['Int'];
};

export type VisualContextInstance = {
  __typename?: 'VisualContextInstance';
  instanceId: Scalars['ID'];
  iconId?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  indicativeState?: Maybe<State>;
  position?: Maybe<Position>;
};

export type VisualContextMutationResponse = Response & {
  __typename?: 'VisualContextMutationResponse';
  visualContextId?: Maybe<Scalars['ID']>;
  visualContext?: Maybe<VisualContext>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  success: Scalars['Boolean'];
};

export enum VisualContextStatus {
  New = 'New',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum WeekDay {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export enum QcPermissionCategory {
  Qcs = 'qcs',
  Procedure = 'procedure'
}

export type UpdateInstance = {
  __typename?: 'updateInstance';
  instanceId: Scalars['ID'];
  visualContextId: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['String'];
  page: Scalars['Int'];
};

export type CommentFragmentFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'body' | 'createdAt' | 'deleted' | 'fromId' | 'id' | 'read' | 'threadId' | 'resolved' | 'updatedAt'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'fileId' | 'fileName' | 'fileSize'>
  )>>, createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName' | 'email'>
  ), references: Array<(
    { __typename?: 'Reference' }
    & Pick<Reference, 'referenceId' | 'referenceKind' | 'referenceType' | 'referenceValue' | 'referenceData'>
  )> }
);

export type MessageFragmentFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'body' | 'bcc' | 'cc' | 'createdAt' | 'deleted' | 'fromId' | 'id' | 'parentId' | 'read' | 'subject' | 'threadId' | 'to' | 'resolved' | 'updatedAt' | 'messageType'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'fileId' | 'fileName' | 'fileSize'>
  )>>, createdBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName' | 'email'>
  ), references: Array<(
    { __typename?: 'Reference' }
    & Pick<Reference, 'referenceId' | 'referenceKind' | 'referenceType' | 'referenceValue' | 'referenceData'>
  )> }
);

export type AddMessageMutationVariables = Exact<{
  attachments?: Maybe<Array<Maybe<AttachmentInput>> | Maybe<AttachmentInput>>;
  body: Scalars['String'];
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  references?: Maybe<Array<Maybe<ReferenceInput>> | Maybe<ReferenceInput>>;
  subject: Scalars['String'];
  threadId?: Maybe<Scalars['ID']>;
}>;


export type AddMessageMutation = (
  { __typename?: 'Mutation' }
  & { addMessage?: Maybe<(
    { __typename?: 'Message' }
    & MessageFragmentFragment
  )> }
);

export type MarkMessageMutationVariables = Exact<{
  messages: Array<Maybe<MarkMessageInput>> | Maybe<MarkMessageInput>;
  read: Scalars['Boolean'];
}>;


export type MarkMessageMutation = (
  { __typename?: 'Mutation' }
  & { markMessage?: Maybe<(
    { __typename?: 'MarkMessageResponse' }
    & Pick<MarkMessageResponse, 'ids' | 'success' | 'errors'>
  )> }
);

export type AddCommentMutationVariables = Exact<{
  attachments?: Maybe<Array<Maybe<AttachmentInput>> | Maybe<AttachmentInput>>;
  body: Scalars['String'];
  references?: Maybe<Array<Maybe<ReferenceInput>> | Maybe<ReferenceInput>>;
  target: ReferenceInput;
}>;


export type AddCommentMutation = (
  { __typename?: 'Mutation' }
  & { addComment?: Maybe<(
    { __typename?: 'Message' }
    & CommentFragmentFragment
  )> }
);

export type EditCommentMutationVariables = Exact<{
  attachments?: Maybe<Array<Maybe<AttachmentInput>> | Maybe<AttachmentInput>>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  messageId?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Maybe<ReferenceInput>> | Maybe<ReferenceInput>>;
  target?: Maybe<ReferenceInput>;
  threadId: Scalars['ID'];
}>;


export type EditCommentMutation = (
  { __typename?: 'Mutation' }
  & { editComment?: Maybe<(
    { __typename?: 'Message' }
    & MessageFragmentFragment
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  threadId?: Maybe<Scalars['ID']>;
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'MutateDeleteCommentResponse' }
    & Pick<MutateDeleteCommentResponse, 'id' | 'success' | 'errors'>
  )> }
);

export type AddSignatureMutationVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  signatureBody: Scalars['String'];
  signatureId?: Maybe<Scalars['ID']>;
}>;


export type AddSignatureMutation = (
  { __typename?: 'Mutation' }
  & { addSignature?: Maybe<(
    { __typename?: 'AddSignatureResponse' }
    & Pick<AddSignatureResponse, 'success'>
  )> }
);

export type MessageInboxQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  sentOrInbox?: Maybe<SentOrInbox>;
}>;


export type MessageInboxQuery = (
  { __typename?: 'Query' }
  & { messageInbox: Array<Maybe<(
    { __typename?: 'Message' }
    & MessageFragmentFragment
  )>> }
);

export type MessageBranchQueryVariables = Exact<{
  messageId: Scalars['ID'];
  authorName?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  replyId?: Maybe<Scalars['ID']>;
  replyAll?: Maybe<Scalars['Boolean']>;
  action?: Maybe<EmailActions>;
  resendId?: Maybe<Scalars['ID']>;
}>;


export type MessageBranchQuery = (
  { __typename?: 'Query' }
  & { messageBranch: (
    { __typename?: 'MessageBranch' }
    & Pick<MessageBranch, 'id' | 'replyAll' | 'replyId'>
    & { main: (
      { __typename?: 'Message' }
      & Pick<Message, 'messageType'>
      & MessageFragmentFragment
    ), original?: Maybe<(
      { __typename?: 'Message' }
      & MessageFragmentFragment
    )>, previous?: Maybe<Array<Maybe<(
      { __typename?: 'Message' }
      & MessageFragmentFragment
    )>>> }
  ) }
);

export type UnreadCountQueryVariables = Exact<{
  isOrganization?: Maybe<Scalars['Boolean']>;
}>;


export type UnreadCountQuery = (
  { __typename?: 'Query' }
  & { unreadCount?: Maybe<Array<Maybe<(
    { __typename?: 'UnreadMessages' }
    & Pick<UnreadMessages, 'id' | 'all' | 'inbox' | 'sent'>
  )>>> }
);

export type ProjectsUnreadCountQueryVariables = Exact<{
  isOrganization?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectsUnreadCountQuery = (
  { __typename?: 'Query' }
  & { projectsUnreadCount: Array<Maybe<(
    { __typename?: 'ProjectUnread' }
    & Pick<ProjectUnread, 'id' | 'unreadCount'>
  )>> }
);

export type CommentThreadQueryVariables = Exact<{
  targetId: Scalars['ID'];
}>;


export type CommentThreadQuery = (
  { __typename?: 'Query' }
  & { commentThread?: Maybe<(
    { __typename?: 'CommentThread' }
    & Pick<CommentThread, 'id' | 'targetId'>
    & { messages?: Maybe<Array<(
      { __typename?: 'Message' }
      & CommentFragmentFragment
    )>> }
  )> }
);

export type GetSignatureQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSignatureQuery = (
  { __typename?: 'Query' }
  & { getSignature?: Maybe<(
    { __typename?: 'GetSignature' }
    & Pick<GetSignature, 'success'>
    & { data?: Maybe<(
      { __typename?: 'GetSignatureData' }
      & Pick<GetSignatureData, 'signatureId' | 'userId' | 'organizationId' | 'body' | 'createdAt' | 'updatedAt' | 'deletedAt'>
    )> }
  )> }
);

export type StampFragmentFragment = (
  { __typename?: 'Stamp' }
  & Pick<Stamp, 'id' | 'versionId' | 'offsetX' | 'offsetY' | 'scaleX' | 'scaleY' | 'type'>
);

export type DocumentFragmentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'amountOfVersions' | 'commentStatus' | 'createdAt' | 'downloadName' | 'expectedExtension' | 'extension' | 'folderId' | 'hasPreviousVersionQr' | 'id' | 'isExpected' | 'lastUpdated' | 'lockedAt' | 'lockType' | 'metadataInstanceId' | 'name' | 'path' | 'pathIds' | 'projectId' | 'qrCodeState' | 'state' | 'updatedAt' | 'versionId' | 'versionNumber' | 'versionState'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )>, lockedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, stateBuild12?: Maybe<(
    { __typename?: 'StateBuild12' }
    & Pick<StateBuild12, 'state'>
    & { oldConnections?: Maybe<Array<Maybe<(
      { __typename?: 'Build12Connection' }
      & Pick<Build12Connection, 'connectionId' | 'connectionName' | 'phaseName'>
    )>>>, connections?: Maybe<Array<Maybe<(
      { __typename?: 'Build12Connection' }
      & Pick<Build12Connection, 'connectionId' | 'connectionName' | 'phaseName'>
    )>>> }
  )>, stateSnagstream?: Maybe<(
    { __typename?: 'StateSnagstream' }
    & Pick<StateSnagstream, 'state'>
    & { oldConnections?: Maybe<Array<Maybe<(
      { __typename?: 'SnagstreamConnection' }
      & Pick<SnagstreamConnection, 'connectionId' | 'connectionName'>
    )>>>, connections?: Maybe<Array<Maybe<(
      { __typename?: 'SnagstreamConnection' }
      & Pick<SnagstreamConnection, 'connectionId' | 'connectionName'>
    )>>> }
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>>>, threeDConversionState?: Maybe<(
    { __typename?: 'ThreeDConversionStatus' }
    & Pick<ThreeDConversionStatus, 'status'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )> }
);

export type DocumentMetaDataFragmentFragment = (
  { __typename?: 'Document' }
  & { metaData?: Maybe<(
    { __typename?: 'DocumentMetaData' }
    & Pick<DocumentMetaData, 'isValid'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'fieldDefinitionId' | 'value'>
    )>>> }
  )> }
);

export type DocumentVersionsFragmentFragment = (
  { __typename?: 'Document' }
  & { versions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentVersion' }
    & VersionFragmentFragment
  )>>> }
);

export type VersionFragmentFragment = (
  { __typename?: 'DocumentVersion' }
  & Pick<DocumentVersion, 'commentStatus' | 'createdAt' | 'documentId' | 'downloadName' | 'extension' | 'fileSize' | 'folderId' | 'hasPreviousVersionQr' | 'id' | 'isExpected' | 'metadataInstanceId' | 'md5Hash' | 'name' | 'qrCodeState' | 'state' | 'totalPreviewPages' | 'updatedAt' | 'versionNumber' | 'versionState'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, stateBuild12?: Maybe<(
    { __typename?: 'StateBuild12' }
    & Pick<StateBuild12, 'state'>
    & { connections?: Maybe<Array<Maybe<(
      { __typename?: 'Build12Connection' }
      & Pick<Build12Connection, 'connectionId' | 'connectionName' | 'phaseName'>
    )>>> }
  )>, stateSnagstream?: Maybe<(
    { __typename?: 'StateSnagstream' }
    & Pick<StateSnagstream, 'state'>
    & { connections?: Maybe<Array<Maybe<(
      { __typename?: 'SnagstreamConnection' }
      & Pick<SnagstreamConnection, 'connectionId' | 'connectionName'>
    )>>> }
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>>>, threeDConversionState?: Maybe<(
    { __typename?: 'ThreeDConversionStatus' }
    & Pick<ThreeDConversionStatus, 'status'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
  & DocumentVersionMetaDataFragmentFragment
);

export type VersionFragmentIncludeFragment = (
  { __typename?: 'DocumentVersion' }
  & MakeOptional<Pick<DocumentVersion, 'fileSize' | 'documentId' | 'name' | 'path' | 'projectId' | 'folderId' | 'qrCodeState' | 'id'>, 'fileSize' | 'documentId' | 'name' | 'path' | 'projectId' | 'folderId' | 'qrCodeState'>
);

export type DocumentVersionMetaDataFragmentFragment = (
  { __typename?: 'DocumentVersion' }
  & { metaData?: Maybe<(
    { __typename?: 'DocumentMetaData' }
    & Pick<DocumentMetaData, 'isValid'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Instance' }
      & Pick<Instance, 'fieldDefinitionId' | 'value'>
    )>>> }
  )> }
);

export type FolderFragmentFragment = (
  { __typename?: 'Folder' }
  & Pick<Folder, 'id' | 'createdAt' | 'hasSubFolders' | 'isPrivate' | 'inheritParentMetadata' | 'metadataSetId' | 'name' | 'parentFolderId' | 'path' | 'projectId'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type FolderMetaDataFragmentFragment = (
  { __typename?: 'Folder' }
  & { template?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name'>
    & { fields?: Maybe<Array<Maybe<(
      { __typename?: 'FieldDefinition' }
      & Pick<FieldDefinition, 'displayName' | 'fieldDefinitionId' | 'id' | 'name' | 'required' | 'type'>
      & { valueType?: Maybe<(
        { __typename?: 'ValueType' }
        & Pick<ValueType, 'id' | 'maxValue' | 'minValue' | 'multiSelect' | 'rangeEnabled' | 'subType'>
        & { options?: Maybe<Array<(
          { __typename?: 'MetaDataOption' }
          & Pick<MetaDataOption, 'name' | 'color'>
        )>> }
      )> }
    )>>> }
  )> }
);

export type SmartFolderFragmentFragment = (
  { __typename?: 'SmartFolder' }
  & Pick<SmartFolder, 'id' | 'name'>
  & { filters?: Maybe<Array<Maybe<(
    { __typename?: 'Filter' }
    & Pick<Filter, 'type' | 'property' | 'value'>
  )>>> }
);

export type AnnotationFragmentFragment = (
  { __typename?: 'Annotation' }
  & Pick<Annotation, 'id' | 'body' | 'createdAt' | 'userId' | 'versionId' | 'deletedAt' | 'deletedBy'>
);

export type EntityPermissionsFragmentFragment = (
  { __typename?: 'EntityFolderPermission' }
  & Pick<EntityFolderPermission, 'id' | 'breakInheritance' | 'memberId' | 'folderPermissions'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'displayName' | 'id'>
  )>, group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'displayName' | 'id'>
  )> }
);

export type InheritedPermissionsFragmentFragment = (
  { __typename?: 'EntityFolderPermission' }
  & { inheritedFrom?: Maybe<Array<Maybe<(
    { __typename?: 'GroupPermission' }
    & Pick<GroupPermission, 'groups' | 'permission'>
  )>>>, inheritedFromParentFolders?: Maybe<Array<Maybe<(
    { __typename?: 'ParentFolderPermission' }
    & Pick<ParentFolderPermission, 'permission'>
    & { parentFolder?: Maybe<(
      { __typename?: 'ParentFolder' }
      & Pick<ParentFolder, 'id' | 'name'>
    )> }
  )>>>, subFoldersPermissions?: Maybe<Array<Maybe<(
    { __typename?: 'SubFolderPermission' }
    & Pick<SubFolderPermission, 'permission'>
  )>>> }
);

export type SharedLinkFragmentFragment = (
  { __typename?: 'SharedLinkDataResponse' }
  & Pick<SharedLinkDataResponse, 'createdAt' | 'createdBy' | 'expirationDate' | 'id' | 'linkType' | 'scopeId' | 'showFolders' | 'zipDownloadUrl'>
  & { documentVersions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentVersion' }
    & Pick<DocumentVersion, 'id' | 'name' | 'documentId' | 'downloadUrl' | 'extension' | 'fileSize' | 'folderId' | 'path' | 'pathIds'>
  )>>>, latestDocumentVersions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentVersion' }
    & Pick<DocumentVersion, 'id' | 'name' | 'documentId' | 'downloadUrl' | 'extension' | 'fileSize' | 'folderId' | 'path' | 'pathIds'>
  )>>> }
);

export type DocumentHistoryEventFragmentFragment = (
  { __typename?: 'DocumentHistoryEvent' }
  & Pick<DocumentHistoryEvent, 'changeDate' | 'resourceId' | 'historyId' | 'name' | 'userId' | 'versionNumber' | 'versionId'>
  & { properties?: Maybe<Array<(
    { __typename?: 'DocumentHistoryProperty' }
    & Pick<DocumentHistoryProperty, 'addedValues' | 'addedResolvedValues' | 'changeDate' | 'deletedValues' | 'deletedResolvedValues' | 'resourceId' | 'historyId' | 'name' | 'type'>
  )>>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type ConvertTo3DMutationVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type ConvertTo3DMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'convertTo3D'>
);

export type CreateDocumentMutationVariables = Exact<{
  document: DocumentCreate;
}>;


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDocument?: Maybe<(
    { __typename?: 'DocumentResponse' }
    & Pick<DocumentResponse, 'id' | 'errors' | 'success'>
  )> }
);

export type MoveDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['ID']> | Scalars['ID'];
  folderId: Scalars['ID'];
}>;


export type MoveDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { moveDocuments?: Maybe<(
    { __typename?: 'BatchDocumentsResponse' }
    & Pick<BatchDocumentsResponse, 'success'>
  )> }
);

export type MoveDocumentMutationVariables = Exact<{
  moveDocumentData: MoveDocumentData;
}>;


export type MoveDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'moveDocument'>
);

export type PasteDocumentsMutationVariables = Exact<{
  documentIds: Array<PasteDocumentsDictionary> | PasteDocumentsDictionary;
  folderId: Scalars['ID'];
}>;


export type PasteDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { pasteDocuments?: Maybe<(
    { __typename?: 'BatchDocumentsResponse' }
    & Pick<BatchDocumentsResponse, 'success'>
  )> }
);

export type CreateDocumentsMutationVariables = Exact<{
  documents: Array<DocumentCreate> | DocumentCreate;
}>;


export type CreateDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { createDocuments?: Maybe<(
    { __typename?: 'DocumentsResponse' }
    & Pick<DocumentsResponse, 'errors' | 'success'>
    & { documents?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )>>> }
  )> }
);

export type UpdateDocumentsMutationVariables = Exact<{
  documents: Array<DocumentUpdate> | DocumentUpdate;
}>;


export type UpdateDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocuments'>
);

export type DeleteDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocuments?: Maybe<(
    { __typename?: 'BatchDocumentsResponse' }
    & Pick<BatchDocumentsResponse, 'succeededIds' | 'errors' | 'failedIds' | 'success'>
  )> }
);

export type DeleteDocumentVersionMutationVariables = Exact<{
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
}>;


export type DeleteDocumentVersionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocumentVersion'>
);

export type DeleteDocumentVersionsMutationVariables = Exact<{
  documentIdsVersionIds: Array<DocumentIdVersionId> | DocumentIdVersionId;
}>;


export type DeleteDocumentVersionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocumentVersions'>
);

export type GenerateDocumentKeywordsMutationVariables = Exact<{
  versionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GenerateDocumentKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { generateDocumentKeywords?: Maybe<(
    { __typename?: 'BatchDocumentsResponse' }
    & Pick<BatchDocumentsResponse, 'succeededIds' | 'errors' | 'failedIds' | 'success'>
  )> }
);

export type GenerateDocumentKeywordsSingleDocMutationVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type GenerateDocumentKeywordsSingleDocMutation = (
  { __typename?: 'Mutation' }
  & { generateDocumentKeywordsSingleDoc?: Maybe<Array<Maybe<(
    { __typename?: 'Keyword' }
    & Pick<Keyword, 'score' | 'text'>
  )>>> }
);

export type MutateDocumentKeywordsMutationVariables = Exact<{
  keywords: Array<DocumentKeyword> | DocumentKeyword;
  versionId: Scalars['ID'];
}>;


export type MutateDocumentKeywordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'mutateDocumentKeywords'>
);

export type RestoreDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreDocument'>
);

export type RestoreDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type RestoreDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { restoreDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type RestoreDocumentVersionMutationVariables = Exact<{
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
}>;


export type RestoreDocumentVersionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreDocumentVersion'>
);

export type CreateTagsMutationVariables = Exact<{
  tags: Array<Maybe<TagInput>> | Maybe<TagInput>;
}>;


export type CreateTagsMutation = (
  { __typename?: 'Mutation' }
  & { createTags?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type SetDocumentTagsMutationVariables = Exact<{
  documentId: Scalars['ID'];
  tagIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type SetDocumentTagsMutation = (
  { __typename?: 'Mutation' }
  & { setDocumentTags?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type AddAnnotationMutationVariables = Exact<{
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
  json: Scalars['JSON'];
}>;


export type AddAnnotationMutation = (
  { __typename?: 'Mutation' }
  & { addAnnotation?: Maybe<(
    { __typename?: 'Annotation' }
    & AnnotationFragmentFragment
  )> }
);

export type DeleteAnnotationsMutationVariables = Exact<{
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
  annotationIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type DeleteAnnotationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAnnotations'>
);

export type LockDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type LockDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { lockDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type UnlockDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type UnlockDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { unlockDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type RequestUnlockDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type RequestUnlockDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { requestUnlockDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type FinalizeDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type FinalizeDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { finalizeDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type UnfinalizeDocumentsMutationVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type UnfinalizeDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { unfinalizeDocuments?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type ApproveDocumentVersionsMutationVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ApproveDocumentVersionsMutation = (
  { __typename?: 'Mutation' }
  & { approveDocumentVersions?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type RejectDocumentVersionsMutationVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type RejectDocumentVersionsMutation = (
  { __typename?: 'Mutation' }
  & { rejectDocumentVersions?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type UnapproveDocumentVersionsMutationVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type UnapproveDocumentVersionsMutation = (
  { __typename?: 'Mutation' }
  & { unapproveDocumentVersions?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type UnrejectDocumentVersionsMutationVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type UnrejectDocumentVersionsMutation = (
  { __typename?: 'Mutation' }
  & { unrejectDocumentVersions?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type MapDocumentVersionMetaDataInstanceMutationVariables = Exact<{
  mappings: Array<Maybe<DocumentVersionMetadataMapping>> | Maybe<DocumentVersionMetadataMapping>;
}>;


export type MapDocumentVersionMetaDataInstanceMutation = (
  { __typename?: 'Mutation' }
  & { mapDocumentVersionMetaDataInstance?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type CreateSummaryMutationVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type CreateSummaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSummary'>
);

export type CreateCommandMutationVariables = Exact<{
  command: CreateCommandInput;
}>;


export type CreateCommandMutation = (
  { __typename?: 'Mutation' }
  & { createCommand?: Maybe<(
    { __typename?: 'CommandOutput' }
    & Pick<CommandOutput, 'commandId' | 'status' | 'clientUrl'>
  )> }
);

export type ApplyTemplateMutationVariables = Exact<{
  applyToSubfolders?: Maybe<Scalars['Boolean']>;
  folderId: Scalars['ID'];
  templateId: Scalars['ID'];
}>;


export type ApplyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { applyTemplate?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type CreateFolderMutationVariables = Exact<{
  metadataSetId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentFolderId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  applyToSubfolders?: Maybe<Scalars['Boolean']>;
}>;


export type CreateFolderMutation = (
  { __typename?: 'Mutation' }
  & { createFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type MoveFolderMutationVariables = Exact<{
  childFolderId: Scalars['ID'];
  parentFolderId: Scalars['ID'];
}>;


export type MoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { moveFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type RenameFolderMutationVariables = Exact<{
  folderId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
}>;


export type RenameFolderMutation = (
  { __typename?: 'Mutation' }
  & { renameFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  parentFolderId?: Maybe<Scalars['ID']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateFolderMutation = (
  { __typename?: 'Mutation' }
  & { updateFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type RestoreFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreFolderMutation = (
  { __typename?: 'Mutation' }
  & { restoreFolder?: Maybe<(
    { __typename?: 'FolderChangeResponse' }
    & Pick<FolderChangeResponse, 'folderId'>
  )> }
);

export type DownloadFolderZipMutationVariables = Exact<{
  id: Scalars['ID'];
  includeSubfolders: Scalars['Boolean'];
}>;


export type DownloadFolderZipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'downloadFolderZip'>
);

export type SetFoldersPermissionsForUserMutationVariables = Exact<{
  folders: Array<Maybe<FolderWithPermissions>> | Maybe<FolderWithPermissions>;
  userId: Scalars['ID'];
}>;


export type SetFoldersPermissionsForUserMutation = (
  { __typename?: 'Mutation' }
  & { setFoldersPermissionsForUser?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type SetFolderProjectLinkMutationVariables = Exact<{
  folderId: Scalars['ID'];
  projectInfo: ProjectInfo;
}>;


export type SetFolderProjectLinkMutation = (
  { __typename?: 'Mutation' }
  & { setFolderProjectLink?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'id' | 'success' | 'errors'>
  )> }
);

export type RemoveFolderProjectLinkMutationVariables = Exact<{
  linkId: Scalars['ID'];
}>;


export type RemoveFolderProjectLinkMutation = (
  { __typename?: 'Mutation' }
  & { removeFolderProjectLink?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'id' | 'success' | 'errors'>
  )> }
);

export type MoveProjectLinkMutationVariables = Exact<{
  newFolderId: Scalars['ID'];
  currentLinkId: Scalars['ID'];
  projectInfo: ProjectInfo;
}>;


export type MoveProjectLinkMutation = (
  { __typename?: 'Mutation' }
  & { moveProjectLink?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'id' | 'success' | 'errors'>
  )> }
);

export type SetEntitiesFolderPermissionsForFolderMutationVariables = Exact<{
  entities: Array<Maybe<EntityWithFolderPermissions>> | Maybe<EntityWithFolderPermissions>;
  folderId: Scalars['ID'];
}>;


export type SetEntitiesFolderPermissionsForFolderMutation = (
  { __typename?: 'Mutation' }
  & { setEntitiesFolderPermissionsForFolder?: Maybe<(
    { __typename?: 'BatchResult' }
    & Pick<BatchResult, 'successful' | 'unsuccessful'>
  )> }
);

export type DuplicateFolderStructureMutationVariables = Exact<{
  sourceFolderId?: Maybe<Scalars['String']>;
  sourceProjectId?: Maybe<Scalars['String']>;
  targetFolderId?: Maybe<Scalars['String']>;
  targetProjectId?: Maybe<Scalars['String']>;
}>;


export type DuplicateFolderStructureMutation = (
  { __typename?: 'Mutation' }
  & { duplicateFolderStructure?: Maybe<(
    { __typename?: 'FolderDuplicationResponse' }
    & Pick<FolderDuplicationResponse, 'success' | 'errorCode' | 'folderName'>
  )> }
);

export type CreatePublicShareLinkMutationVariables = Exact<{
  expirationDate: Scalars['DateTime'];
  passwordProtected?: Maybe<Scalars['Boolean']>;
  resources: Array<Maybe<Resource>> | Maybe<Resource>;
  showFolders: Scalars['Boolean'];
}>;


export type CreatePublicShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { createPublicShareLink?: Maybe<(
    { __typename?: 'CreateShareLinkResponse' }
    & Pick<CreateShareLinkResponse, 'id' | 'link' | 'password' | 'errors' | 'success'>
  )> }
);

export type CreatePrivateShareLinkMutationVariables = Exact<{
  expirationDate: Scalars['DateTime'];
  projectId?: Maybe<Scalars['ID']>;
  resources: Array<Maybe<Resource>> | Maybe<Resource>;
  userIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<SelectedGroup>> | Maybe<SelectedGroup>>;
}>;


export type CreatePrivateShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { createPrivateShareLink?: Maybe<(
    { __typename?: 'CreateShareLinkResponse' }
    & Pick<CreateShareLinkResponse, 'id' | 'link' | 'errors' | 'success'>
  )> }
);

export type CreateScopedShareLinkMutationVariables = Exact<{
  expirationDate: Scalars['DateTime'];
  resources: Array<Maybe<Resource>> | Maybe<Resource>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type CreateScopedShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { createScopedShareLink?: Maybe<(
    { __typename?: 'CreateShareLinkResponse' }
    & Pick<CreateShareLinkResponse, 'id' | 'link' | 'errors' | 'success'>
  )> }
);

export type RevokeShareLinkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevokeShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { revokeShareLink?: Maybe<(
    { __typename?: 'RevokeLinkResponse' }
    & Pick<RevokeLinkResponse, 'id' | 'errors' | 'success'>
  )> }
);

export type SendShareLinkMutationVariables = Exact<{
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  copyToSelf?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  groupIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  to?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SendShareLinkMutation = (
  { __typename?: 'Mutation' }
  & { sendShareLink?: Maybe<(
    { __typename?: 'SendShareLinkResponse' }
    & Pick<SendShareLinkResponse, 'id' | 'errors' | 'success'>
  )> }
);

export type CreateSmartFolderMutationVariables = Exact<{
  name: Scalars['String'];
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  fileTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type CreateSmartFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSmartFolder'>
);

export type UpdateSmartFolderMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  fileTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateSmartFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSmartFolder'>
);

export type DeleteSmartFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSmartFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSmartFolder'>
);

export type RestoreSmartFolderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestoreSmartFolderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreSmartFolder'>
);

export type StampDocumentMutationVariables = Exact<{
  documentId: Scalars['ID'];
  documentVersionId: Scalars['ID'];
  params: StampParams;
}>;


export type StampDocumentMutation = (
  { __typename?: 'Mutation' }
  & { stampDocument?: Maybe<(
    { __typename?: 'StampDocumentResponse' }
    & Pick<StampDocumentResponse, 'documentId' | 'errors' | 'success'>
  )> }
);

export type RemoveStampsMutationVariables = Exact<{
  stamps: Array<RemoveStampInput> | RemoveStampInput;
}>;


export type RemoveStampsMutation = (
  { __typename?: 'Mutation' }
  & { removeStamps?: Maybe<(
    { __typename?: 'RemoveStampsResponse' }
    & Pick<RemoveStampsResponse, 'ids' | 'errors' | 'success'>
  )> }
);

export type SupportedFormatsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportedFormatsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supportedFormats'>
);

export type DocumentHistoryQueryVariables = Exact<{
  documentId: Scalars['ID'];
  versionId?: Maybe<Scalars['ID']>;
  fetchForVersion: Scalars['Boolean'];
  metadataInstanceId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type DocumentHistoryQuery = (
  { __typename?: 'Query' }
  & { documentHistory?: Maybe<(
    { __typename?: 'DocumentHistory' }
    & { today?: Maybe<Array<(
      { __typename?: 'DocumentHistoryEvent' }
      & DocumentHistoryEventFragmentFragment
    )>>, thisWeek?: Maybe<Array<(
      { __typename?: 'DocumentHistoryEvent' }
      & DocumentHistoryEventFragmentFragment
    )>>, thisMonth?: Maybe<Array<(
      { __typename?: 'DocumentHistoryEvent' }
      & DocumentHistoryEventFragmentFragment
    )>>, older?: Maybe<Array<(
      { __typename?: 'DocumentHistoryEvent' }
      & DocumentHistoryEventFragmentFragment
    )>> }
  )> }
);

export type PublicAttachmentDataQueryVariables = Exact<{
  id: Scalars['ID'];
  signature: Scalars['String'];
  template: Scalars['String'];
}>;


export type PublicAttachmentDataQuery = (
  { __typename?: 'Query' }
  & { publicAttachmentData?: Maybe<(
    { __typename?: 'SharedAttachmentDataResponse' }
    & Pick<SharedAttachmentDataResponse, 'id' | 'createdAt' | 'createdBy' | 'expirationDate' | 'linkType' | 'scopeId' | 'zipDownloadUrl' | 'errors' | 'success'>
    & { messageAttachments?: Maybe<Array<Maybe<(
      { __typename?: 'SharedAttachments' }
      & Pick<SharedAttachments, 'id' | 'name' | 'downloadUrl'>
    )>>> }
  )> }
);

export type PublicLinkDataQueryVariables = Exact<{
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  template: Scalars['String'];
}>;


export type PublicLinkDataQuery = (
  { __typename?: 'Query' }
  & { publicLinkData?: Maybe<(
    { __typename?: 'SharedLinkDataResponse' }
    & Pick<SharedLinkDataResponse, 'errors' | 'success'>
    & { documentLinks?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentVersion' }
      & Pick<DocumentVersion, 'createdAt' | 'documentId' | 'downloadUrl' | 'extension' | 'fileSize' | 'folderId' | 'id' | 'name'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )> }
    )>>> }
    & SharedLinkFragmentFragment
  )> }
);

export type PrivateLinkDataQueryVariables = Exact<{
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
}>;


export type PrivateLinkDataQuery = (
  { __typename?: 'Query' }
  & { privateLinkData?: Maybe<(
    { __typename?: 'SharedLinkDataResponse' }
    & Pick<SharedLinkDataResponse, 'errors' | 'success'>
    & { documentLinks?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentVersion' }
      & Pick<DocumentVersion, 'createdAt' | 'documentId' | 'downloadUrl' | 'extension' | 'fileSize' | 'folderId' | 'id' | 'name' | 'path'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )> }
    )>>> }
    & SharedLinkFragmentFragment
  )> }
);

export type ScopedLinkDataQueryVariables = Exact<{
  id: Scalars['ID'];
  template: Scalars['String'];
  signature: Scalars['String'];
}>;


export type ScopedLinkDataQuery = (
  { __typename?: 'Query' }
  & { scopedLinkData?: Maybe<(
    { __typename?: 'SharedLinkDataResponse' }
    & Pick<SharedLinkDataResponse, 'errors' | 'success'>
    & { documentLinks?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentVersion' }
      & Pick<DocumentVersion, 'createdAt' | 'documentId' | 'downloadUrl' | 'extension' | 'fileSize' | 'folderId' | 'id' | 'name' | 'path'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )> }
    )>>> }
    & SharedLinkFragmentFragment
  )> }
);

export type StampsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type StampsQuery = (
  { __typename?: 'Query' }
  & { stamps?: Maybe<(
    { __typename?: 'StampsResponse' }
    & Pick<StampsResponse, 'errors' | 'success'>
    & { data: Array<Maybe<(
      { __typename?: 'Stamp' }
      & StampFragmentFragment
    )>> }
  )> }
);

export type QrCodeStateQueryVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type QrCodeStateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'qrCodeState'>
);

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentQuery = (
  { __typename?: 'Query' }
  & { document?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'extension'>
    & { keywords?: Maybe<Array<Maybe<(
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'text' | 'score'>
    )>>>, taskLinks?: Maybe<Array<(
      { __typename?: 'TaskLink' }
      & Pick<TaskLink, 'type'>
      & { task: (
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'name' | 'description' | 'procedureId' | 'documentAction' | 'status' | 'endTime'>
        & { assignment?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'displayName' | 'id'>
        )>> }
      ) }
    )>>, summary?: Maybe<(
      { __typename?: 'AiSummary' }
      & Pick<AiSummary, 'text' | 'status'>
    )>, visualContext?: Maybe<(
      { __typename?: 'VisualContext' }
      & Pick<VisualContext, 'id' | 'name' | 'status'>
    )> }
    & DocumentFragmentFragment
    & DocumentMetaDataFragmentFragment
    & DocumentVersionsFragmentFragment
  )> }
);

export type DocumentStateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentStateQuery = (
  { __typename?: 'Query' }
  & { documentState?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'state'>
  )> }
);

export type DocumentsQueryVariables = Exact<{
  documentIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { documents?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'extension'>
    & { visualContext?: Maybe<(
      { __typename?: 'VisualContext' }
      & Pick<VisualContext, 'id' | 'name' | 'status'>
    )> }
    & DocumentFragmentFragment
  )>>> }
);

export type VersionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VersionQuery = (
  { __typename?: 'Query' }
  & { version?: Maybe<(
    { __typename?: 'DocumentVersion' }
    & Pick<DocumentVersion, 'createdAt' | 'documentId' | 'extension' | 'fileSize' | 'folderId' | 'id' | 'metadataInstanceId' | 'name' | 'projectId' | 'state' | 'totalPreviewPages' | 'updatedAt' | 'versionNumber' | 'versionState'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'name'>
      )> }
    )>, keywords?: Maybe<Array<Maybe<(
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'text' | 'score'>
    )>>>, updatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )>, summary?: Maybe<(
      { __typename?: 'AiSummary' }
      & Pick<AiSummary, 'text' | 'status'>
    )>, versions?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentVersion' }
      & VersionFragmentFragment
    )>>> }
  )> }
);

export type VersionsIncludeQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  includeFileSize?: Maybe<Scalars['Boolean']>;
  includeDocumentId?: Maybe<Scalars['Boolean']>;
  includeName?: Maybe<Scalars['Boolean']>;
  includePath?: Maybe<Scalars['Boolean']>;
  includeProjectId?: Maybe<Scalars['Boolean']>;
  includeFolderId?: Maybe<Scalars['Boolean']>;
  includeQrCodeState?: Maybe<Scalars['Boolean']>;
}>;


export type VersionsIncludeQuery = (
  { __typename?: 'Query' }
  & { versions?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentVersion' }
    & VersionFragmentIncludeFragment
  )>>> }
);

export type PdfTronLicenseKeyQueryVariables = Exact<{
  documentVersionId: Scalars['ID'];
}>;


export type PdfTronLicenseKeyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pdfTronLicenseKey'>
);

export type AnnotationsQueryVariables = Exact<{
  documentId: Scalars['ID'];
  versionId: Scalars['ID'];
}>;


export type AnnotationsQuery = (
  { __typename?: 'Query' }
  & { annotations?: Maybe<Array<Maybe<(
    { __typename?: 'Annotation' }
    & AnnotationFragmentFragment
  )>>> }
);

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>> }
);

export type FolderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FolderQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & { fileInformation?: Maybe<(
      { __typename?: 'FileInformation' }
      & Pick<FileInformation, 'amountOfDocuments' | 'amountOfVersions' | 'totalFileSize'>
    )>, documents?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'name'>
    )>>> }
    & FolderFragmentFragment
  )> }
);

export type SearchResultFolderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SearchResultFolderQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & { fileInformation?: Maybe<(
      { __typename?: 'FileInformation' }
      & Pick<FileInformation, 'amountOfDocuments' | 'amountOfVersions' | 'totalFileSize'>
    )> }
    & FolderFragmentFragment
    & FolderMetaDataFragmentFragment
  )> }
);

export type FolderByPathQueryVariables = Exact<{
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FolderByPathQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'availablePermissions'>
    & { documents?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & DocumentFragmentFragment
      & DocumentMetaDataFragmentFragment
    )>>> }
    & FolderFragmentFragment
    & FolderMetaDataFragmentFragment
  )> }
);

export type BaseFolderByPathQueryVariables = Exact<{
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type BaseFolderByPathQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & FolderFragmentFragment
  )> }
);

export type FolderWithPermissionsByPathQueryVariables = Exact<{
  entityIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FolderWithPermissionsByPathQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'parentFolderId'>
    & { entityFolderPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'EntityFolderPermission' }
      & EntityPermissionsFragmentFragment
      & InheritedPermissionsFragmentFragment
    )>>> }
  )> }
);

export type FolderWithEntityPermissionsQueryVariables = Exact<{
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  entityIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FolderWithEntityPermissionsQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'availablePermissions'>
    & { entityFolderPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'EntityFolderPermission' }
      & EntityPermissionsFragmentFragment
      & InheritedPermissionsFragmentFragment
    )>>> }
    & FolderFragmentFragment
  )> }
);

export type FoldersQueryVariables = Exact<{
  entityId?: Maybe<Scalars['ID']>;
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  parentFolderIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FoldersQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & { entityFolderPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'EntityFolderPermission' }
      & Pick<EntityFolderPermission, 'id' | 'breakInheritance' | 'memberId' | 'folderPermissions'>
      & InheritedPermissionsFragmentFragment
    )>>> }
    & FolderFragmentFragment
  )>>> }
);

export type FoldersPermissionsQueryVariables = Exact<{
  entityId?: Maybe<Scalars['ID']>;
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  includePermissionViaGroup?: Maybe<Scalars['Boolean']>;
  inherit?: Maybe<Scalars['Boolean']>;
  parentFolderIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FoldersPermissionsQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'parentFolderId'>
    & { entityFolderPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'EntityFolderPermission' }
      & Pick<EntityFolderPermission, 'id' | 'folderPermissions'>
    )>>> }
  )>>> }
);

export type FoldersByIdQueryVariables = Exact<{
  forceOrganizationGroups?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  entityId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FoldersByIdQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & { entityFolderPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'EntityFolderPermission' }
      & EntityPermissionsFragmentFragment
    )>>> }
    & FolderFragmentFragment
  )>>> }
);

export type ProjectByFolderQueryVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ProjectByFolderQuery = (
  { __typename?: 'Query' }
  & { projectsByFolder?: Maybe<(
    { __typename?: 'ProjectByFolderResponse' }
    & Pick<ProjectByFolderResponse, 'success' | 'errors'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'LinkInfo' }
      & Pick<LinkInfo, 'projectId' | 'projectName' | 'folderId' | 'linkId'>
    )>>> }
  )> }
);

export type FoldersByPathQueryVariables = Exact<{
  path: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
}>;


export type FoldersByPathQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & FolderFragmentFragment
  )>>> }
);

export type SmartFolderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SmartFolderQuery = (
  { __typename?: 'Query' }
  & { smartFolder?: Maybe<(
    { __typename?: 'SmartFolder' }
    & { documents?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'pathIds'>
      & DocumentFragmentFragment
    )>>> }
    & SmartFolderFragmentFragment
  )> }
);

export type SmartFoldersQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
}>;


export type SmartFoldersQuery = (
  { __typename?: 'Query' }
  & { smartFolders?: Maybe<Array<Maybe<(
    { __typename?: 'SmartFolder' }
    & SmartFolderFragmentFragment
  )>>> }
);

export type ThreeDConversionStatusQueryVariables = Exact<{
  versionId: Scalars['ID'];
}>;


export type ThreeDConversionStatusQuery = (
  { __typename?: 'Query' }
  & { threeDConversionStatus?: Maybe<(
    { __typename?: 'ThreeDConversionStatus' }
    & Pick<ThreeDConversionStatus, 'fileUrn' | 'progress' | 'status' | 'versionId'>
  )> }
);

export type ThreeDConversionStatusesQueryVariables = Exact<{
  versionIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ThreeDConversionStatusesQuery = (
  { __typename?: 'Query' }
  & { threeDConversionStatuses?: Maybe<Array<Maybe<(
    { __typename?: 'ThreeDConversionStatus' }
    & Pick<ThreeDConversionStatus, 'fileUrn' | 'progress' | 'status' | 'versionId'>
  )>>> }
);

export type DocumentVersionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentVersionQuery = (
  { __typename?: 'Query' }
  & { documentVersion?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentVersion' }
    & VersionFragmentFragment
  )>>> }
);

export type OfficeOnlineInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OfficeOnlineInfoQuery = (
  { __typename?: 'Query' }
  & { officeOnlineInfo?: Maybe<(
    { __typename?: 'OfficeOnlineInfo' }
    & Pick<OfficeOnlineInfo, 'canOpen' | 'editUrl' | 'readOnly' | 'viewUrl'>
  )> }
);

export type CheckCommandStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckCommandStatusQuery = (
  { __typename?: 'Query' }
  & { checkCommandStatus?: Maybe<(
    { __typename?: 'CommandStatus' }
    & Pick<CommandStatus, 'status'>
  )> }
);

export type DocumentClientDownloadUrlsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentClientDownloadUrlsQuery = (
  { __typename?: 'Query' }
  & { documentClientDownloadUrls?: Maybe<(
    { __typename?: 'ClientDownloadUrls' }
    & Pick<ClientDownloadUrls, 'downloadUrlMac' | 'downloadUrlWindows'>
  )> }
);

export type CreateApiKeyMutationVariables = Exact<{
  description: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
}>;


export type CreateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createApiKey'>
);

export type EditApiKeyMutationVariables = Exact<{
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
}>;


export type EditApiKeyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editApiKey'>
);

export type RevokeApiKeysMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RevokeApiKeysMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeApiKeys'>
);

export type ApiKeysIncludeQueryVariables = Exact<{
  includeExpiresAt?: Maybe<Scalars['Boolean']>;
}>;


export type ApiKeysIncludeQuery = (
  { __typename?: 'Query' }
  & { apiKeys?: Maybe<Array<Maybe<(
    { __typename?: 'ApiKey' }
    & MakeOptional<Pick<ApiKey, 'description' | 'expiresAt' | 'id'>, 'expiresAt'>
  )>>> }
);

export type ApiKeyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApiKeyQuery = (
  { __typename?: 'Query' }
  & { apiKey?: Maybe<(
    { __typename?: 'ApiKey' }
    & Pick<ApiKey, 'description' | 'expiresAt' | 'id'>
  )> }
);

export type MeFragmentFragment = (
  { __typename?: 'Me' }
  & { organization?: Maybe<(
    { __typename?: 'MeOrganization' }
    & Pick<MeOrganization, 'enforceSSO' | 'organizationId' | 'organizationName'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'permissions'>
    )>>> }
  )>, projects?: Maybe<Array<Maybe<(
    { __typename?: 'MeProject' }
    & Pick<MeProject, 'projectId' | 'organizationId' | 'organizationName'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'permissions'>
    )>>> }
  )>>>, user?: Maybe<(
    { __typename?: 'MeUser' }
    & Pick<MeUser, 'active' | 'authType' | 'displayName' | 'email' | 'firstName' | 'imageUrl' | 'jobTitle' | 'language' | 'lastName' | 'organizationId' | 'phoneNumber' | 'userflowSignature' | 'userId'>
  )> }
);

export type UserFragmentFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'active' | 'displayName' | 'email' | 'lastOnlineAt' | 'firstName' | 'isAdmin' | 'lastName' | 'invited' | 'organizationId' | 'scope' | 'state'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )> }
);

export type GroupFragmentFragment = (
  { __typename: 'Group' }
  & Pick<Group, 'id' | 'createdAt' | 'displayName' | 'name' | 'scope'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, members?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'displayName'>
  ) | (
    { __typename?: 'User' }
    & UserFragmentFragment
  )>>>, subgroups?: Maybe<Array<Maybe<(
    { __typename: 'Group' }
    & Pick<Group, 'id' | 'displayName'>
  )>>> }
);

export type FragmentGroupIncludeFragment = (
  { __typename?: 'Group' }
  & MakeOptional<Pick<Group, 'createdAt' | 'displayName' | 'id' | 'memberIds' | 'membersCount' | 'subgroupIds' | 'organizationId' | 'explicitProjectMember'>, 'createdAt' | 'memberIds' | 'membersCount' | 'subgroupIds' | 'organizationId' | 'explicitProjectMember'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'displayName' | 'id'>
  )>, members?: Maybe<Array<Maybe<(
    { __typename: 'Group' }
    & Pick<Group, 'displayName' | 'id'>
  ) | (
    { __typename: 'User' }
    & Pick<User, 'displayName' | 'id'>
  )>>>, subgroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'displayName' | 'id'>
  )>>> }
);

export type FragmentProjectMemberGroupIncludeFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'displayName' | 'id'>
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'permissions'>
  )>>> }
);

export type FragmentUserIncludeFragment = (
  { __typename?: 'User' }
  & MakeOptional<Pick<User, 'active' | 'displayName' | 'email' | 'id' | 'invited' | 'isAdmin' | 'jobTitle' | 'lastOnlineAt' | 'phoneNumber' | 'state' | 'explicitProjectMember'>, 'active' | 'email' | 'invited' | 'isAdmin' | 'jobTitle' | 'lastOnlineAt' | 'phoneNumber' | 'state' | 'explicitProjectMember'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'permissions'>
  )>>> }
);

export type AddGroupsToProjectsMutationVariables = Exact<{
  groupIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  projectIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type AddGroupsToProjectsMutation = (
  { __typename?: 'Mutation' }
  & { addGroupsToProjects?: Maybe<(
    { __typename?: 'MutateGroupResponse' }
    & Pick<MutateGroupResponse, 'errors' | 'ids' | 'success'>
  )> }
);

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  subgroups?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  members?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'MutateGroupResponse' }
    & Pick<MutateGroupResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type DeleteGroupsMutationVariables = Exact<{
  groups: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeleteGroupsMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroups?: Maybe<(
    { __typename?: 'MutateGroupResponse' }
    & Pick<MutateGroupResponse, 'errors' | 'ids' | 'success'>
  )> }
);

export type EditGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subgroupsToDelete?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  subgroupsToAdd?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  membersToAdd?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
  membersToDelete?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
}>;


export type EditGroupMutation = (
  { __typename?: 'Mutation' }
  & { editGroup?: Maybe<(
    { __typename?: 'MutateGroupResponse' }
    & Pick<MutateGroupResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type AllowedGroupsIncludeQueryVariables = Exact<{
  groupId?: Maybe<Scalars['ID']>;
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeMemberIds?: Maybe<Scalars['Boolean']>;
  includeMembers?: Maybe<Scalars['Boolean']>;
  includeMembersCount?: Maybe<Scalars['Boolean']>;
  includeSubgroups?: Maybe<Scalars['Boolean']>;
  includeSubgroupIds?: Maybe<Scalars['Boolean']>;
  includeOrganizationId?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type AllowedGroupsIncludeQuery = (
  { __typename?: 'Query' }
  & { allowedGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & FragmentGroupIncludeFragment
  )>>> }
);

export type GroupIncludeQueryVariables = Exact<{
  groupId: Scalars['ID'];
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeMemberIds?: Maybe<Scalars['Boolean']>;
  includeMembers?: Maybe<Scalars['Boolean']>;
  includeMembersCount?: Maybe<Scalars['Boolean']>;
  includeSubgroups?: Maybe<Scalars['Boolean']>;
  includeSubgroupIds?: Maybe<Scalars['Boolean']>;
  includeOrganizationId?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type GroupIncludeQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & FragmentGroupIncludeFragment
  )> }
);

export type GroupsIncludeQueryVariables = Exact<{
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeMemberIds?: Maybe<Scalars['Boolean']>;
  includeMembers?: Maybe<Scalars['Boolean']>;
  includeMembersCount?: Maybe<Scalars['Boolean']>;
  includeSubgroups?: Maybe<Scalars['Boolean']>;
  includeSubgroupIds?: Maybe<Scalars['Boolean']>;
  includeOrganizationId?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type GroupsIncludeQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & FragmentGroupIncludeFragment
  )>>> }
);

export type AddIntegrationMutationVariables = Exact<{
  application: ApplicationInput;
  customIntegrationProps: CustomIntegrationProps;
}>;


export type AddIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addIntegration'>
);

export type ConnectProjectMutationVariables = Exact<{
  customConnectionProps: CustomConnectionProps;
  integrationId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ConnectProjectMutation = (
  { __typename?: 'Mutation' }
  & { connectProject: (
    { __typename?: 'ConnectProjectResponse' }
    & Pick<ConnectProjectResponse, 'connectionName' | 'id' | 'projectId'>
  ) }
);

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteIntegrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIntegration'>
);

export type DeleteIntegrationConnectionMutationVariables = Exact<{
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteIntegrationConnectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIntegrationConnection'>
);

export type ShareToBuild12MutationVariables = Exact<{
  connectionId: Scalars['ID'];
  integrationId: Scalars['ID'];
  markAsSentVersionIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  projectId: Scalars['ID'];
  projectPhaseId: Scalars['ID'];
  projectPhaseName?: Maybe<Scalars['String']>;
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ShareToBuild12Mutation = (
  { __typename?: 'Mutation' }
  & { shareToBuild12: (
    { __typename?: 'BatchMutation' }
    & Pick<BatchMutation, 'successful' | 'unsuccessful'>
  ) }
);

export type ShareToSnagstreamMutationVariables = Exact<{
  connectionId: Scalars['ID'];
  integrationId: Scalars['ID'];
  markAsSentVersionIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  overwriteVersionIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  projectId: Scalars['ID'];
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ShareToSnagstreamMutation = (
  { __typename?: 'Mutation' }
  & { shareToSnagstream: (
    { __typename?: 'BatchMutation' }
    & Pick<BatchMutation, 'successful' | 'unsuccessful'>
  ) }
);

export type ConnectionQueryVariables = Exact<{
  id: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ConnectionQuery = (
  { __typename?: 'Query' }
  & { connection: (
    { __typename?: 'Connection' }
    & Pick<Connection, 'connectionName' | 'createdAt' | 'id' | 'integrationId' | 'projectId'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )> }
  ) }
);

export type IntegrationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IntegrationQuery = (
  { __typename?: 'Query' }
  & { integration: (
    { __typename?: 'Integration' }
    & Pick<Integration, 'integrationName' | 'createdAt' | 'id' | 'integrationType'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )> }
  ) }
);

export type PhaseBuild12QueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PhaseBuild12Query = (
  { __typename?: 'Query' }
  & { phaseBuild12: (
    { __typename?: 'PhaseBuild12' }
    & Pick<PhaseBuild12, 'id' | 'name'>
  ) }
);

export type FilesExistsBuild12QueryVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  connectionId: Scalars['ID'];
  projectPhaseId: Scalars['ID'];
}>;


export type FilesExistsBuild12Query = (
  { __typename?: 'Query' }
  & { filesExistsBuild12: (
    { __typename?: 'FilesExistsBuild12' }
    & Pick<FilesExistsBuild12, 'allowedIds' | 'alreadyExistsIds' | 'rejectedIds'>
  ) }
);

export type FilesExistsSnagstreamQueryVariables = Exact<{
  versionIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  connectionId: Scalars['ID'];
}>;


export type FilesExistsSnagstreamQuery = (
  { __typename?: 'Query' }
  & { filesExistsSnagstream: (
    { __typename?: 'FilesExistsSnagstream' }
    & Pick<FilesExistsSnagstream, 'allowedIds' | 'alreadyExistsIds' | 'rejectedIds'>
  ) }
);

export type ConnectionsQueryVariables = Exact<{
  filterOnActiveConnections?: Maybe<Scalars['Boolean']>;
  filterOnIntegrationType?: Maybe<IntegrationType>;
  projectId: Scalars['ID'];
}>;


export type ConnectionsQuery = (
  { __typename?: 'Query' }
  & { connections: Array<(
    { __typename?: 'IntegrationWithConnection' }
    & Pick<IntegrationWithConnection, 'integrationName' | 'createdAt' | 'connectionStatus' | 'id' | 'integrationType' | 'projectId'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )>, integrationConnection?: Maybe<(
      { __typename?: 'Connection' }
      & Pick<Connection, 'connectionName' | 'createdAt' | 'id' | 'integrationId' | 'projectId'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )> }
    )> }
  )> }
);

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = (
  { __typename?: 'Query' }
  & { integrations: Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'integrationName' | 'createdAt' | 'id' | 'integrationType'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )> }
  )> }
);

export type PhasesBuild12QueryVariables = Exact<{
  connectionId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type PhasesBuild12Query = (
  { __typename?: 'Query' }
  & { phasesBuild12: Array<(
    { __typename?: 'PhaseBuild12' }
    & Pick<PhaseBuild12, 'id' | 'name'>
  )> }
);

export type ProjectsBuild12QueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type ProjectsBuild12Query = (
  { __typename?: 'Query' }
  & { projectsBuild12: Array<(
    { __typename?: 'IntegrationProject' }
    & Pick<IntegrationProject, 'id' | 'name'>
  )> }
);

export type ProjectsSnagstreamQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;


export type ProjectsSnagstreamQuery = (
  { __typename?: 'Query' }
  & { projectsSnagstream: Array<(
    { __typename?: 'IntegrationProject' }
    & Pick<IntegrationProject, 'id' | 'name'>
  )> }
);

export type SetProjectPermissionsMutationVariables = Exact<{
  member?: Maybe<MemberInput>;
  isMemberGroupFromOrg?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
  memberPermissions: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  category?: Maybe<QcPermissionCategory>;
}>;


export type SetProjectPermissionsMutation = (
  { __typename?: 'Mutation' }
  & { setProjectPermissions?: Maybe<(
    { __typename?: 'SetProjectPermissionsResponse' }
    & Pick<SetProjectPermissionsResponse, 'projectId'>
  )> }
);

export type GroupsAndUsersIncludeQueryVariables = Exact<{
  includeActive?: Maybe<Scalars['Boolean']>;
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeEmail?: Maybe<Scalars['Boolean']>;
  fetchInvitedUsers?: Maybe<Scalars['Boolean']>;
  includeInvited?: Maybe<Scalars['Boolean']>;
  includeIsAdmin?: Maybe<Scalars['Boolean']>;
  includeJobTitle?: Maybe<Scalars['Boolean']>;
  includeLastOnlineAt?: Maybe<Scalars['Boolean']>;
  includeMemberIds?: Maybe<Scalars['Boolean']>;
  includeMembers?: Maybe<Scalars['Boolean']>;
  includeMembersCount?: Maybe<Scalars['Boolean']>;
  includeOrganization?: Maybe<Scalars['Boolean']>;
  includePhoneNumber?: Maybe<Scalars['Boolean']>;
  includeRoles?: Maybe<Scalars['Boolean']>;
  includeState?: Maybe<Scalars['Boolean']>;
  includeSubgroupIds?: Maybe<Scalars['Boolean']>;
  includeOrganizationId?: Maybe<Scalars['Boolean']>;
  includeSubgroups?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type GroupsAndUsersIncludeQuery = (
  { __typename?: 'Query' }
  & { groupsAndUsers?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & FragmentGroupIncludeFragment
  ) | (
    { __typename?: 'User' }
    & FragmentUserIncludeFragment
  )>>> }
);

export type ProjectMembersIncludeQueryVariables = Exact<{
  includeActive?: Maybe<Scalars['Boolean']>;
  includeEmail?: Maybe<Scalars['Boolean']>;
  includeInvited?: Maybe<Scalars['Boolean']>;
  includeIsAdmin?: Maybe<Scalars['Boolean']>;
  includeJobTitle?: Maybe<Scalars['Boolean']>;
  includeLastOnlineAt?: Maybe<Scalars['Boolean']>;
  includeOrganization?: Maybe<Scalars['Boolean']>;
  includeRoles?: Maybe<Scalars['Boolean']>;
  includePhoneNumber?: Maybe<Scalars['Boolean']>;
  includeState?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
}>;


export type ProjectMembersIncludeQuery = (
  { __typename?: 'Query' }
  & { projectMembers?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & FragmentProjectMemberGroupIncludeFragment
  ) | (
    { __typename?: 'User' }
    & FragmentUserIncludeFragment
  )>>> }
);

export type GetProjectPermissionsQueryVariables = Exact<{
  memberId: Scalars['ID'];
  memberType: Scalars['Int'];
  isMemberGroupFromOrg?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
}>;


export type GetProjectPermissionsQuery = (
  { __typename?: 'Query' }
  & { getProjectPermissions?: Maybe<(
    { __typename?: 'UserQCPermissions' }
    & Pick<UserQcPermissions, 'directPermissions'>
    & { inheritedPermissions?: Maybe<Array<(
      { __typename?: 'InheritedPermission' }
      & Pick<InheritedPermission, 'permissions' | 'groupName' | 'groupId'>
    )>> }
  )> }
);

export type SetOrganizationRoleForUsersMutationVariables = Exact<{
  userIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  roleId: Scalars['ID'];
}>;


export type SetOrganizationRoleForUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOrganizationRoleForUsers'>
);

export type SetOrganizationEnforceSsoMutationVariables = Exact<{
  enforceSSO?: Maybe<Scalars['Boolean']>;
}>;


export type SetOrganizationEnforceSsoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setOrganizationEnforceSSO'>
);

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  settings?: Maybe<OrganizationSettingsInput>;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganization'>
);

export type UpdateOrganizationSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  settings?: Maybe<OrganizationSettingsInput>;
}>;


export type UpdateOrganizationSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganizationSettings'>
);

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'enforceSSO' | 'id' | 'name' | 'subscriptionLevelId' | 'organizationSubscriptionLevel'>
    & { settings?: Maybe<(
      { __typename?: 'OrganizationSettings' }
      & Pick<OrganizationSettings, 'approve_action_label' | 'reject_action_label' | 'finalize_action_label' | 'ai_admin_settings' | 'ai_organization_settings'>
    )> }
  )> }
);

export type ActivateSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  city: Scalars['String'];
  locale: Scalars['String'];
  postalcode: Scalars['String'];
  redirectUrl: Scalars['String'];
  vatIdentificationNumber: Scalars['String'];
}>;


export type ActivateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { activateSubscription?: Maybe<(
    { __typename?: 'ChangeSubscriptionDataResponse' }
    & Pick<ChangeSubscriptionDataResponse, 'errors' | 'mollieRedirect' | 'success'>
  )> }
);

export type CancelSubscriptionMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelSubscription?: Maybe<(
    { __typename?: 'CancelSubscriptionDataResponse' }
    & Pick<CancelSubscriptionDataResponse, 'id' | 'errors' | 'success'>
  )> }
);

export type ChangePaymentMethodMutationVariables = Exact<{
  locale: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type ChangePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { changePaymentMethod?: Maybe<(
    { __typename?: 'ChangeSubscriptionDataResponse' }
    & Pick<ChangeSubscriptionDataResponse, 'errors' | 'mollieRedirect' | 'success'>
  )> }
);

export type ChangeSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  locale: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type ChangeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { changeSubscription?: Maybe<(
    { __typename?: 'ChangeSubscriptionDataResponse' }
    & Pick<ChangeSubscriptionDataResponse, 'errors' | 'mollieRedirect' | 'success'>
  )> }
);

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: (
    { __typename?: 'CountryDataResponse' }
    & Pick<CountryDataResponse, 'errors' | 'success'>
    & { countries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'label'>
    )> }
  ) }
);

export type SubscriptionLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionLevelsQuery = (
  { __typename?: 'Query' }
  & { subscriptionLevels: (
    { __typename?: 'SubscriptionLevelsDataResponse' }
    & Pick<SubscriptionLevelsDataResponse, 'errors' | 'success'>
    & { subscriptionLevels: Array<(
      { __typename?: 'SubscriptionLevel' }
      & Pick<SubscriptionLevel, 'iconName' | 'id' | 'isPopular' | 'isSelectable' | 'isTrial' | 'name' | 'order' | 'permissions' | 'pricing'>
    )> }
  ) }
);

export type FieldDefinitionQueryVariables = Exact<{
  fieldDefinitionId: Scalars['ID'];
  templateService?: Maybe<TemplateService>;
}>;


export type FieldDefinitionQuery = (
  { __typename?: 'Query' }
  & { fieldDefinition?: Maybe<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )> }
);

export type SubscriptionOrganizationQueryVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
}>;


export type SubscriptionOrganizationQuery = (
  { __typename?: 'Query' }
  & { subscriptionOrganization?: Maybe<(
    { __typename?: 'SubscriptionOrganizationDataResponse' }
    & Pick<SubscriptionOrganizationDataResponse, 'errors' | 'success'>
    & { subscriptionOrganization: (
      { __typename?: 'SubscriptionOrganization' }
      & Pick<SubscriptionOrganization, 'billingEmail' | 'expireDate' | 'nextBillingCycle' | 'nextSubscriptionLevelId' | 'organizationId' | 'subscriptionEndDate' | 'subscriptionLevelId'>
      & { paymentInfo?: Maybe<(
        { __typename?: 'PaymentInfo' }
        & Pick<PaymentInfo, 'isValid' | 'last3Digits' | 'type'>
      )> }
    ) }
  )> }
);

export type SubscriptionOrganizationExpireQueryVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
}>;


export type SubscriptionOrganizationExpireQuery = (
  { __typename?: 'Query' }
  & { subscriptionOrganizationExpire?: Maybe<(
    { __typename?: 'SubscriptionOrganizationExpireDataResponse' }
    & Pick<SubscriptionOrganizationExpireDataResponse, 'errors' | 'expireDate' | 'success'>
  )> }
);

export type SubscriptionOrganizationUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionOrganizationUsersQuery = (
  { __typename?: 'Query' }
  & { subscriptionOrganizationUsers?: Maybe<(
    { __typename?: 'SubscriptionOrganizationUsersDataResponse' }
    & Pick<SubscriptionOrganizationUsersDataResponse, 'isFixedPrice' | 'pricePerUnit' | 'users' | 'errors' | 'success'>
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  language: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfile'>
);

export type UpdateSupportSettingsMutationVariables = Exact<{
  date?: Maybe<Scalars['String']>;
}>;


export type UpdateSupportSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupportSettings'>
);

export type ActivateUsersMutationVariables = Exact<{
  userIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ActivateUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUsers'>
);

export type ConfirmMoveExistingUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmMoveExistingUserMutation = (
  { __typename?: 'Mutation' }
  & { confirmMoveExistingUser?: Maybe<(
    { __typename?: 'MoveUserModel' }
    & Pick<MoveUserModel, 'invitedUserId' | 'invitedUserOrganizationId' | 'invitedUserOrganizationName' | 'inviterEmail' | 'inviterName' | 'newOrganizationId' | 'newOrganizationName' | 'state'>
  )> }
);

export type DeactivateUsersMutationVariables = Exact<{
  userIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeactivateUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateUsers'>
);

export type InviteExistingUsersMutationVariables = Exact<{
  userEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  adminEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type InviteExistingUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteExistingUsers'>
);

export type InviteUsersMutationVariables = Exact<{
  adminEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  memberEmails?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  style?: Maybe<MailStyle>;
}>;


export type InviteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUsers'>
);

export type MoveToNewOrganizationMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type MoveToNewOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { moveToNewOrganization?: Maybe<(
    { __typename?: 'MoveUserModel' }
    & Pick<MoveUserModel, 'invitedUserId' | 'invitedUserOrganizationId' | 'invitedUserOrganizationName' | 'inviterEmail' | 'inviterName' | 'newOrganizationId' | 'newOrganizationName' | 'state'>
  )> }
);

export type ReInviteUsersMutationVariables = Exact<{
  requestIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  style?: Maybe<MailStyle>;
}>;


export type ReInviteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reInviteUsers'>
);

export type StayInCurrentOrganizationMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type StayInCurrentOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { stayInCurrentOrganization?: Maybe<(
    { __typename?: 'MoveUserModel' }
    & Pick<MoveUserModel, 'invitedUserId' | 'invitedUserOrganizationId' | 'invitedUserOrganizationName' | 'inviterEmail' | 'inviterName' | 'newOrganizationId' | 'newOrganizationName' | 'state'>
  )> }
);

export type OrganizationUsersIncludeQueryVariables = Exact<{
  includeActive?: Maybe<Scalars['Boolean']>;
  includeEmail?: Maybe<Scalars['Boolean']>;
  includeInvited?: Maybe<Scalars['Boolean']>;
  includeIsAdmin?: Maybe<Scalars['Boolean']>;
  includeJobTitle?: Maybe<Scalars['Boolean']>;
  includeLastOnlineAt?: Maybe<Scalars['Boolean']>;
  includeOrganization?: Maybe<Scalars['Boolean']>;
  includePhoneNumber?: Maybe<Scalars['Boolean']>;
  includeRoles?: Maybe<Scalars['Boolean']>;
  includeState?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
}>;


export type OrganizationUsersIncludeQuery = (
  { __typename?: 'Query' }
  & { organizationUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & FragmentUserIncludeFragment
  )>>> }
);

export type UsersIncludeQueryVariables = Exact<{
  includeActive?: Maybe<Scalars['Boolean']>;
  includeEmail?: Maybe<Scalars['Boolean']>;
  includeInvited?: Maybe<Scalars['Boolean']>;
  includeIsAdmin?: Maybe<Scalars['Boolean']>;
  includeJobTitle?: Maybe<Scalars['Boolean']>;
  includeLastOnlineAt?: Maybe<Scalars['Boolean']>;
  includeOrganization?: Maybe<Scalars['Boolean']>;
  includePhoneNumber?: Maybe<Scalars['Boolean']>;
  includeRoles?: Maybe<Scalars['Boolean']>;
  includeState?: Maybe<Scalars['Boolean']>;
  includeExplicitProjectMember?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type UsersIncludeQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & FragmentUserIncludeFragment
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & MeFragmentFragment
  )> }
);

export type SupportSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportSettingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supportSettings'>
);

export type MyOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrganizationQuery = (
  { __typename?: 'Query' }
  & { myOrganization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'enforceSSO' | 'name'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>> }
  ) }
);

export type FieldDefinitionPropsFragmentFragment = (
  { __typename?: 'FieldDefinition' }
  & Pick<FieldDefinition, 'id' | 'createdAt' | 'description' | 'displayDescription' | 'displayName' | 'fieldDefinitionId' | 'indicative' | 'linksAllowed' | 'name' | 'parentSectionId' | 'required' | 'type' | 'value'>
  & { conditions?: Maybe<Array<(
    { __typename?: 'Condition' }
    & Pick<Condition, 'fieldId' | 'value'>
  )>>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )>, scope?: Maybe<(
    { __typename?: 'Scope' }
    & Pick<Scope, 'id' | 'type'>
  )> }
  & RecursiveFieldDefinitionFragmentFragment
);

export type SubFieldDefinitionPropsFragmentFragment = (
  { __typename?: 'FieldDefinition' }
  & Pick<FieldDefinition, 'id' | 'createdAt' | 'description' | 'displayDescription' | 'displayName' | 'fieldDefinitionId' | 'linksAllowed' | 'name' | 'parentSectionId' | 'required' | 'indicative' | 'type' | 'value'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )>, conditions?: Maybe<Array<(
    { __typename?: 'Condition' }
    & Pick<Condition, 'fieldId' | 'value'>
  )>>, scope?: Maybe<(
    { __typename?: 'Scope' }
    & Pick<Scope, 'id' | 'type'>
  )>, valueType?: Maybe<(
    { __typename?: 'ValueType' }
    & Pick<ValueType, 'hierarchyListId' | 'id' | 'maxValue' | 'minValue' | 'multipleAnswers' | 'multiSelect' | 'name' | 'rangeEnabled' | 'staticBreadcrumbs'>
    & { options?: Maybe<Array<(
      { __typename?: 'MetaDataOption' }
      & Pick<MetaDataOption, 'name' | 'color' | 'index'>
    )>> }
  )> }
);

export type ValueTypeFragmentFragment = (
  { __typename?: 'ValueType' }
  & Pick<ValueType, 'displayType' | 'hierarchyListId' | 'id' | 'maxValue' | 'minValue' | 'multipleAnswers' | 'multiSelect' | 'name' | 'rangeEnabled' | 'staticBreadcrumbs'>
  & { options?: Maybe<Array<(
    { __typename?: 'MetaDataOption' }
    & Pick<MetaDataOption, 'name' | 'color' | 'index'>
  )>> }
);

export type TemplatePropsFragmentFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'createdAt' | 'name' | 'state' | 'type' | 'version'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )>, scope?: Maybe<(
    { __typename?: 'Scope' }
    & Pick<Scope, 'id' | 'type'>
  )>, items?: Maybe<Array<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>> }
);

export type FieldDefinitionBasicFragmentFragment = (
  { __typename?: 'FieldDefinition' }
  & Pick<FieldDefinition, 'id' | 'indicative'>
  & RecursiveFieldDefinitionFragmentFragment
);

export type RecursiveFieldDefinitionFragmentFragment = (
  { __typename?: 'FieldDefinition' }
  & { valueType?: Maybe<(
    { __typename?: 'ValueType' }
    & { subFields?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & { valueType?: Maybe<(
        { __typename?: 'ValueType' }
        & { subFields?: Maybe<Array<(
          { __typename?: 'FieldDefinition' }
          & { valueType?: Maybe<(
            { __typename?: 'ValueType' }
            & { subFields?: Maybe<Array<(
              { __typename?: 'FieldDefinition' }
              & { valueType?: Maybe<(
                { __typename?: 'ValueType' }
                & { subFields?: Maybe<Array<(
                  { __typename?: 'FieldDefinition' }
                  & { valueType?: Maybe<(
                    { __typename?: 'ValueType' }
                    & { subFields?: Maybe<Array<(
                      { __typename?: 'FieldDefinition' }
                      & { valueType?: Maybe<(
                        { __typename?: 'ValueType' }
                        & { subFields?: Maybe<Array<(
                          { __typename?: 'FieldDefinition' }
                          & { valueType?: Maybe<(
                            { __typename?: 'ValueType' }
                            & { subFields?: Maybe<Array<(
                              { __typename?: 'FieldDefinition' }
                              & { valueType?: Maybe<(
                                { __typename?: 'ValueType' }
                                & { subFields?: Maybe<Array<(
                                  { __typename?: 'FieldDefinition' }
                                  & { valueType?: Maybe<(
                                    { __typename?: 'ValueType' }
                                    & { subFields?: Maybe<Array<(
                                      { __typename?: 'FieldDefinition' }
                                      & { valueType?: Maybe<(
                                        { __typename?: 'ValueType' }
                                        & { subFields?: Maybe<Array<(
                                          { __typename?: 'FieldDefinition' }
                                          & SubFieldDefinitionPropsFragmentFragment
                                        )>> }
                                        & ValueTypeFragmentFragment
                                      )> }
                                      & SubFieldDefinitionPropsFragmentFragment
                                    )>> }
                                    & ValueTypeFragmentFragment
                                  )> }
                                  & SubFieldDefinitionPropsFragmentFragment
                                )>> }
                                & ValueTypeFragmentFragment
                              )> }
                              & SubFieldDefinitionPropsFragmentFragment
                            )>> }
                            & ValueTypeFragmentFragment
                          )> }
                          & SubFieldDefinitionPropsFragmentFragment
                        )>> }
                        & ValueTypeFragmentFragment
                      )> }
                      & SubFieldDefinitionPropsFragmentFragment
                    )>> }
                    & ValueTypeFragmentFragment
                  )> }
                  & SubFieldDefinitionPropsFragmentFragment
                )>> }
                & ValueTypeFragmentFragment
              )> }
              & SubFieldDefinitionPropsFragmentFragment
            )>> }
            & ValueTypeFragmentFragment
          )> }
          & SubFieldDefinitionPropsFragmentFragment
        )>> }
        & ValueTypeFragmentFragment
      )> }
      & SubFieldDefinitionPropsFragmentFragment
    )>> }
    & ValueTypeFragmentFragment
  )> }
);

export type CreateHierarchicalListMutationVariables = Exact<{
  name: Scalars['String'];
  nodes: Array<Maybe<Node>> | Maybe<Node>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type CreateHierarchicalListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createHierarchicalList'>
);

export type DeleteHierarchicalListsMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeleteHierarchicalListsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteHierarchicalLists'>
);

export type EditHierarchicalListMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  nodes: Array<Node> | Node;
}>;


export type EditHierarchicalListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editHierarchicalList'>
);

export type HierarchicalListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HierarchicalListQuery = (
  { __typename?: 'Query' }
  & { hierarchicalList: (
    { __typename?: 'HierarchicalList' }
    & Pick<HierarchicalList, 'id'>
    & { hierarchy: Array<(
      { __typename?: 'Hierarchy' }
      & Pick<Hierarchy, 'id' | 'name' | 'parentNodeId' | 'previousNodeId'>
    )> }
  ) }
);

export type HierarchicalListsIncludeQueryVariables = Exact<{
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeScope?: Maybe<Scalars['Boolean']>;
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type HierarchicalListsIncludeQuery = (
  { __typename?: 'Query' }
  & { hierarchicalLists?: Maybe<Array<(
    { __typename?: 'HierarchicalList' }
    & MakeOptional<Pick<HierarchicalList, 'id' | 'createdAt' | 'name' | 'scope'>, 'createdAt' | 'scope'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'name'>
      )> }
    )> }
  )>> }
);

export type CreateFieldDefinitionMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  fieldDefinitionTypeInput?: Maybe<FieldDefinitionTypeInput>;
  templateService: TemplateService;
  indicative?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  scope: ScopeType;
  type: Scalars['ID'];
}>;


export type CreateFieldDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { createFieldDefinition?: Maybe<(
    { __typename?: 'MutateMetaDataResponse' }
    & Pick<MutateMetaDataResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type UpdateFieldDefinitionMutationVariables = Exact<{
  autoPublish: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  displayDescription?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  fieldDefinitionTypeInput?: Maybe<FieldDefinitionTypeInput>;
  id: Scalars['ID'];
  indicative?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateService: TemplateService;
  type: Scalars['ID'];
}>;


export type UpdateFieldDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateFieldDefinition?: Maybe<(
    { __typename?: 'MutateMetaDataResponse' }
    & Pick<MutateMetaDataResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type DeleteFieldDefinitionsMutationVariables = Exact<{
  autoPublish: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  templateService: TemplateService;
}>;


export type DeleteFieldDefinitionsMutation = (
  { __typename?: 'Mutation' }
  & { deleteFieldDefinitions?: Maybe<(
    { __typename?: 'MutateMetaDataResponse' }
    & Pick<MutateMetaDataResponse, 'errors' | 'ids' | 'success'>
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  fieldDefinitionsBody: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  scope: ScopeType;
  templateService: TemplateService;
  templateType: TemplateType;
  iconType?: Maybe<Scalars['Int']>;
  selectedTemplateIcon?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate?: Maybe<(
    { __typename?: 'MutateMetaDataResponse' }
    & Pick<MutateMetaDataResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type DeleteTemplatesMutationVariables = Exact<{
  templateService: TemplateService;
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type DeleteTemplatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTemplates'>
);

export type EditTemplateMutationVariables = Exact<{
  fieldDefinitionsBody: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  templateService: TemplateService;
  iconType?: Maybe<Scalars['Int']>;
  selectedTemplateIcon?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
}>;


export type EditTemplateMutation = (
  { __typename?: 'Mutation' }
  & { editTemplate?: Maybe<(
    { __typename?: 'MutateMetaDataResponse' }
    & Pick<MutateMetaDataResponse, 'errors' | 'id' | 'success'>
  )> }
);

export type PublishTemplatesMutationVariables = Exact<{
  templateService: TemplateService;
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type PublishTemplatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishTemplates'>
);

export type SaveInstancesMutationVariables = Exact<{
  answers: Array<InstanceForDocumentInput> | InstanceForDocumentInput;
  previousInstanceId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  templateId: Scalars['ID'];
}>;


export type SaveInstancesMutation = (
  { __typename?: 'Mutation' }
  & { saveInstancesForDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'VersionMetaDataCreatedResponse' }
    & Pick<VersionMetaDataCreatedResponse, 'id' | 'instanceId'>
  )>>> }
);

export type SaveInstanceForQcMutationVariables = Exact<{
  answers: Array<InstanceForQcInput> | InstanceForQcInput;
  instanceId: Scalars['ID'];
  templateVersion: Scalars['Int'];
}>;


export type SaveInstanceForQcMutation = (
  { __typename?: 'Mutation' }
  & { saveInstanceForQc?: Maybe<(
    { __typename?: 'QualityControlInstance' }
    & Pick<QualityControlInstance, 'id' | 'isOptimistic'>
    & { answers?: Maybe<Array<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'fieldId' | 'value'>
    )>>, indicativeState?: Maybe<(
      { __typename?: 'State' }
      & Pick<State, 'index' | 'color' | 'value'>
    )> }
  )> }
);

export type UpdateInstanceMutationVariables = Exact<{
  instanceId: Scalars['ID'];
  visualContextId: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['String'];
  page: Scalars['Int'];
}>;


export type UpdateInstanceMutation = (
  { __typename?: 'Mutation' }
  & { updateInstance?: Maybe<(
    { __typename?: 'updateInstance' }
    & Pick<UpdateInstance, 'instanceId'>
  )> }
);

export type SaveDownloadFileNameConfigMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
  segments: Array<Maybe<ConfigDataSegmentInput>> | Maybe<ConfigDataSegmentInput>;
  templateId: Scalars['ID'];
}>;


export type SaveDownloadFileNameConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveDownloadFileNameConfig'>
);

export type FieldDefinitionTypesQueryVariables = Exact<{
  templateService: TemplateService;
}>;


export type FieldDefinitionTypesQuery = (
  { __typename?: 'Query' }
  & { fieldDefinitionTypes?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinitionType' }
    & Pick<FieldDefinitionType, 'id' | 'label'>
  )>>> }
);

export type TemplatesIncludeQueryVariables = Exact<{
  globalId?: Maybe<Scalars['ID']>;
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  selectedIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  state?: Maybe<TemplateState>;
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeItems?: Maybe<Scalars['Boolean']>;
  includeScope?: Maybe<Scalars['Boolean']>;
  includeState?: Maybe<Scalars['Boolean']>;
  includeType?: Maybe<Scalars['Boolean']>;
  includeVersion?: Maybe<Scalars['Boolean']>;
  templateService: TemplateService;
  templateType: TemplateType;
}>;


export type TemplatesIncludeQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<(
    { __typename?: 'Template' }
    & MakeOptional<Pick<Template, 'id' | 'name' | 'createdAt' | 'state' | 'type' | 'version'>, 'createdAt' | 'state' | 'type' | 'version'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>, items?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & FieldDefinitionPropsFragmentFragment
    )>>, scope?: Maybe<(
      { __typename?: 'Scope' }
      & Pick<Scope, 'id' | 'type'>
    )>, icon?: Maybe<(
      { __typename?: 'TemplateIcon' }
      & Pick<TemplateIcon, 'type' | 'name' | 'fileId'>
    )> }
  )>> }
);

export type TemplatesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  templateService: TemplateService;
}>;


export type TemplatesByIdsQuery = (
  { __typename?: 'Query' }
  & { templatesByIds?: Maybe<Array<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'type'>
    & { items?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & FieldDefinitionPropsFragmentFragment
    )>> }
  )>> }
);

export type AllTemplateItemsByScopeQueryVariables = Exact<{
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  state?: Maybe<TemplateState>;
  templateService: TemplateService;
  templateType: TemplateType;
}>;


export type AllTemplateItemsByScopeQuery = (
  { __typename?: 'Query' }
  & { allTemplateItemsByScope?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>>> }
);

export type FieldDefinitionsQueryVariables = Exact<{
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  templateService?: Maybe<TemplateService>;
}>;


export type FieldDefinitionsQuery = (
  { __typename?: 'Query' }
  & { fieldDefinitions?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>>> }
);

export type FieldDefinitionsIncludeQueryVariables = Exact<{
  includeOrgItems?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  templateService?: Maybe<TemplateService>;
  includeConditions?: Maybe<Scalars['Boolean']>;
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeDescription?: Maybe<Scalars['Boolean']>;
  includeDisplayDescription?: Maybe<Scalars['Boolean']>;
  includeDisplayName?: Maybe<Scalars['Boolean']>;
  includeFieldDefinitionId?: Maybe<Scalars['Boolean']>;
  includeIndicative?: Maybe<Scalars['Boolean']>;
  includeLinksAllowed?: Maybe<Scalars['Boolean']>;
  includeParentSectionId?: Maybe<Scalars['Boolean']>;
  includeRequired?: Maybe<Scalars['Boolean']>;
  includeScope?: Maybe<Scalars['Boolean']>;
  includeType?: Maybe<Scalars['Boolean']>;
  includeValue?: Maybe<Scalars['Boolean']>;
}>;


export type FieldDefinitionsIncludeQuery = (
  { __typename?: 'Query' }
  & { fieldDefinitions?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinition' }
    & MakeOptional<Pick<FieldDefinition, 'id' | 'name' | 'createdAt' | 'description' | 'displayDescription' | 'displayName' | 'fieldDefinitionId' | 'indicative' | 'linksAllowed' | 'parentSectionId' | 'required' | 'type' | 'value'>, 'createdAt' | 'description' | 'displayDescription' | 'displayName' | 'fieldDefinitionId' | 'indicative' | 'linksAllowed' | 'parentSectionId' | 'required' | 'type' | 'value'>
    & { conditions?: Maybe<Array<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'fieldId' | 'value'>
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )>, scope?: Maybe<(
      { __typename?: 'Scope' }
      & Pick<Scope, 'id' | 'type'>
    )> }
  )>>> }
);

export type StandardFieldDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type StandardFieldDefinitionsQuery = (
  { __typename?: 'Query' }
  & { standardFieldDefinitions?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>>> }
);

export type TemplateItemsQueryVariables = Exact<{
  id: Scalars['ID'];
  templateService: TemplateService;
}>;


export type TemplateItemsQuery = (
  { __typename?: 'Query' }
  & { templateItems?: Maybe<Array<Maybe<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>>> }
);

export type DownloadFilenameCharactersQueryVariables = Exact<{ [key: string]: never; }>;


export type DownloadFilenameCharactersQuery = (
  { __typename?: 'Query' }
  & { downloadFilenameCharacters?: Maybe<Array<Maybe<(
    { __typename?: 'DownloadFilenameCharacter' }
    & Pick<DownloadFilenameCharacter, 'id' | 'name'>
  )>>> }
);

export type DownloadFileNameConfigQueryVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DownloadFileNameConfigQuery = (
  { __typename?: 'Query' }
  & { downloadFileNameConfig?: Maybe<(
    { __typename?: 'DownloadFilenameConfig' }
    & Pick<DownloadFilenameConfig, 'enabled'>
    & { segments: Array<Maybe<(
      { __typename?: 'ConfigDataSegment' }
      & Pick<ConfigDataSegment, 'id' | 'order' | 'type'>
    )>> }
  )> }
);

export type GetNotificationsQueryVariables = Exact<{
  searchId?: Maybe<Scalars['String']>;
  isProject?: Maybe<Scalars['Boolean']>;
  scope: NotificationScope;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications?: Maybe<(
    { __typename?: 'Notifications' }
    & Pick<Notifications, 'notificationSettingsId' | 'organizationId' | 'projectId' | 'notificationDays' | 'createdAt' | 'createdBy' | 'hasUpdatePermission' | 'updatedAt' | 'updatedBy'>
    & { notificationTimes?: Maybe<Array<Maybe<(
      { __typename?: 'ScheduledTime' }
      & Pick<ScheduledTime, 'hour' | 'minute'>
    )>>>, users: Array<(
      { __typename?: 'NotificationsUser' }
      & Pick<NotificationsUser, 'id' | 'userId' | 'projectId' | 'enabled' | 'displayName' | 'lastUpdatedBy' | 'lastUpdatedOn' | 'checked' | 'isAdmin' | 'email'>
      & { organization?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'name' | 'id'>
      )> }
    )> }
  )> }
);

export type ToggleBatchDocumentDigestMutationVariables = Exact<{
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Maybe<NotificationUpdateUser>> | Maybe<NotificationUpdateUser>>;
  scope: NotificationScope;
}>;


export type ToggleBatchDocumentDigestMutation = (
  { __typename?: 'Mutation' }
  & { toggleBatchDocumentDigest?: Maybe<(
    { __typename?: 'DocumentDigest' }
    & Pick<DocumentDigest, 'documentDigestId' | 'organizationId' | 'projectId' | 'userId'>
  )> }
);

export type ToggleSingleDocumentDigestMutationVariables = Exact<{
  organizationId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  scope: NotificationScope;
}>;


export type ToggleSingleDocumentDigestMutation = (
  { __typename?: 'Mutation' }
  & { toggleSingleDocumentDigest?: Maybe<(
    { __typename?: 'DocumentDigest' }
    & Pick<DocumentDigest, 'documentDigestId' | 'organizationId' | 'projectId' | 'userId'>
  )> }
);

export type UpdateNotificationSettingsMutationVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  notificationSettingsId?: Maybe<Scalars['ID']>;
  notificationDays?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  notificationTimes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  scope: NotificationScope;
}>;


export type UpdateNotificationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationSettings?: Maybe<(
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'notificationSettingsId' | 'organizationId' | 'projectId' | 'notificationDays'>
    & { notificationTimes?: Maybe<Array<Maybe<(
      { __typename?: 'ScheduledTime' }
      & Pick<ScheduledTime, 'hour' | 'minute'>
    )>>> }
  )> }
);

export type ObjectNodeFragmentFragment = (
  { __typename?: 'ObjectNode' }
  & Pick<ObjectNode, 'id' | 'deletedAt' | 'index' | 'name' | 'organizationId' | 'parentNodeId' | 'projectId' | 'projectStatus' | 'tbqTypeAbstract'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'organizationId' | 'projectNumber'>
  )>, tbqType?: Maybe<(
    { __typename?: 'TBQType' }
    & Pick<TbqType, 'id' | 'name'>
  )> }
);

export type ObjectNodeDetailIncludeFragment = (
  { __typename?: 'ObjectNode' }
  & MakeOptional<Pick<ObjectNode, 'id' | 'projectId'>, 'projectId'>
  & { tasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )>>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'organizationId' | 'projectNumber'>
    & { mainProcedure?: Maybe<(
      { __typename?: 'Procedure' }
      & Pick<Procedure, 'id'>
    )> }
  )> }
);

export type ObjectNodeDetailFragment = (
  { __typename?: 'ObjectNode' }
  & Pick<ObjectNode, 'id' | 'createdAt' | 'deletedAt' | 'description' | 'index' | 'name' | 'organizationId' | 'parentNodeId' | 'projectId' | 'projectStatus' | 'tbqTypeAbstract' | 'tbqTypeAllowedAbstract' | 'tbqLatestTaskId'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'organizationId' | 'projectNumber'>
    & { mainProcedure?: Maybe<(
      { __typename?: 'Procedure' }
      & Pick<Procedure, 'id'>
    )> }
  )>, tbqFunction?: Maybe<(
    { __typename?: 'TBQFunction' }
    & Pick<TbqFunction, 'id' | 'name'>
  )>, tbqFunctionAllowed?: Maybe<Array<(
    { __typename?: 'TBQFunction' }
    & Pick<TbqFunction, 'id' | 'name'>
  )>>, tbqConfig?: Maybe<Array<(
    { __typename?: 'TBQObjectConfig' }
    & Pick<TbqObjectConfig, 'type'>
    & { fields?: Maybe<Array<(
      { __typename?: 'TBQFieldValue' }
      & Pick<TbqFieldValue, 'id' | 'name'>
      & { value?: Maybe<(
        { __typename?: 'TBQValue' }
        & Pick<TbqValue, 'id' | 'name'>
      )> }
    )>> }
  )>>, tbqRieScanType?: Maybe<Array<(
    { __typename?: 'TBQRieScanType' }
    & Pick<TbqRieScanType, 'entityId' | 'name' | 'type' | 'id'>
  )>>, tbqScanTypeAllowed?: Maybe<Array<(
    { __typename?: 'TBQScanType' }
    & Pick<TbqScanType, 'id' | 'name'>
  )>>, tbqType?: Maybe<(
    { __typename?: 'TBQType' }
    & Pick<TbqType, 'id' | 'name'>
  )>, tbqTypeAllowed?: Maybe<Array<(
    { __typename?: 'TBQType' }
    & Pick<TbqType, 'id' | 'name'>
  )>>, tasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )>> }
);

export type ObjectQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ObjectQuery = (
  { __typename?: 'Query' }
  & { object?: Maybe<(
    { __typename?: 'ObjectNode' }
    & ObjectNodeDetailFragment
  )> }
);

export type ObjectIncludeQueryVariables = Exact<{
  id: Scalars['ID'];
  includeProjectId?: Maybe<Scalars['Boolean']>;
  includeTasks?: Maybe<Scalars['Boolean']>;
  includeProject?: Maybe<Scalars['Boolean']>;
}>;


export type ObjectIncludeQuery = (
  { __typename?: 'Query' }
  & { object?: Maybe<(
    { __typename?: 'ObjectNode' }
    & ObjectNodeDetailIncludeFragment
  )> }
);

export type ObjectsQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
}>;


export type ObjectsQuery = (
  { __typename?: 'Query' }
  & { objects: Array<(
    { __typename?: 'ObjectNode' }
    & ObjectNodeFragmentFragment
  )> }
);

export type UpdateNodesMutationVariables = Exact<{
  nodes?: Maybe<Array<ObjectInput> | ObjectInput>;
}>;


export type UpdateNodesMutation = (
  { __typename?: 'Mutation' }
  & { updateObjects?: Maybe<(
    { __typename?: 'ObjectsResponse' }
    & Pick<ObjectsResponse, 'objectIds' | 'success' | 'errors'>
  )> }
);

export type LinkProjectMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type LinkProjectMutation = (
  { __typename?: 'Mutation' }
  & { linkProject?: Maybe<(
    { __typename?: 'ProjectLinkResponse' }
    & Pick<ProjectLinkResponse, 'projectId' | 'success' | 'errors'>
  )> }
);

export type UnlinkProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UnlinkProjectMutation = (
  { __typename?: 'Mutation' }
  & { unlinkProject?: Maybe<(
    { __typename?: 'ProjectLinkResponse' }
    & Pick<ProjectLinkResponse, 'projectId' | 'success' | 'errors'>
  )> }
);

export type TbqAnswerPropsFragment = (
  { __typename?: 'TBQAnswer' }
  & Pick<TbqAnswer, 'id' | 'answer' | 'answerClass' | 'answerClassString' | 'answerOption' | 'categoryId' | 'categoryName' | 'goalId' | 'goalName' | 'operationGoalIds' | 'question' | 'questionId' | 'reference' | 'value' | 'sectionText' | 'sectionId'>
);

export type DeviceLogFragmentFragment = (
  { __typename?: 'OsidDeviceLog' }
  & Pick<OsidDeviceLog, 'id' | 'timestamp' | 'deviceCode' | 'eventType' | 'adres' | 'zone' | 'text'>
);

export type GetTbqResultQueryVariables = Exact<{
  taskId: Scalars['ID'];
  resultSet?: Maybe<ResultSet>;
}>;


export type GetTbqResultQuery = (
  { __typename?: 'Query' }
  & { getTBQResult?: Maybe<(
    { __typename?: 'TBQResult' }
    & Pick<TbqResult, 'id'>
    & { answers: Array<(
      { __typename?: 'TBQAnswer' }
      & TbqAnswerPropsFragment
    )>, categories: Array<(
      { __typename?: 'TBQCategory' }
      & Pick<TbqCategory, 'id' | 'name' | 'percentage'>
    )>, instance: (
      { __typename?: 'QualityControlInstance' }
      & QualityControlInstanceFragmentFragment
    ), operationGoals: Array<(
      { __typename?: 'TBQGoal' }
      & Pick<TbqGoal, 'id' | 'name' | 'percentage'>
    )>, shortcomings: Array<(
      { __typename?: 'TBQAnswer' }
      & TbqAnswerPropsFragment
    )>, tbqInstanceLinkedSnags?: Maybe<Array<(
      { __typename?: 'FormLinkedSnag' }
      & FormLinkedSnagsFragmentFragment
    )>> }
  )> }
);

export type GetTbqBrandcheckMultiResultQueryVariables = Exact<{
  objectId: Scalars['ID'];
  resultSet?: Maybe<ResultSet>;
}>;


export type GetTbqBrandcheckMultiResultQuery = (
  { __typename?: 'Query' }
  & { getTBQBrandcheckMultiResult?: Maybe<Array<(
    { __typename?: 'TBQBrandcheckMultiResult' }
    & Pick<TbqBrandcheckMultiResult, 'id' | 'objectParentId' | 'objectName' | 'scanId'>
    & { answers: Array<(
      { __typename?: 'TBQAnswer' }
      & TbqAnswerPropsFragment
    )>, categories: Array<(
      { __typename?: 'TBQCategory' }
      & Pick<TbqCategory, 'id' | 'name' | 'percentage'>
    )>, operationGoals: Array<(
      { __typename?: 'TBQGoal' }
      & Pick<TbqGoal, 'id' | 'name' | 'percentage'>
    )> }
  )>> }
);

export type RieResultFragmentFragment = (
  { __typename?: 'TBQRieResult' }
  & Pick<TbqRieResult, 'id' | 'risc' | 'category' | 'location' | 'priority' | 'class' | 'taskId' | 'scanId' | 'instanceId'>
  & { section: (
    { __typename?: 'RieSection' }
    & Pick<RieSection, 'id' | 'name'>
  ), result: (
    { __typename?: 'RieResult' }
    & Pick<RieResult, 'name'>
  ) }
);

export type GetTbqRieResultQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type GetTbqRieResultQuery = (
  { __typename?: 'Query' }
  & { getTBQRieResult?: Maybe<Array<(
    { __typename?: 'TBQRieResult' }
    & { questionLinkedResolveTask?: Maybe<(
      { __typename?: 'Task' }
      & TaskFragmentFragment
    )> }
    & RieResultFragmentFragment
  )>> }
);

export type GetTbqConfigQueryVariables = Exact<{
  tbqTypeId: Scalars['ID'];
}>;


export type GetTbqConfigQuery = (
  { __typename?: 'Query' }
  & { getTbqConfig?: Maybe<Array<(
    { __typename?: 'TBQConfig' }
    & Pick<TbqConfig, 'type'>
    & { fields?: Maybe<Array<(
      { __typename?: 'TBQFieldOption' }
      & Pick<TbqFieldOption, 'id' | 'name'>
      & { options?: Maybe<Array<(
        { __typename?: 'TBQOption' }
        & Pick<TbqOption, 'id' | 'name' | 'index'>
      )>> }
    )>> }
  )>> }
);

export type GetTbqGlobalScopeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTbqGlobalScopeQuery = (
  { __typename?: 'Query' }
  & { getTBQGlobalScope: Array<(
    { __typename?: 'TBQGlobalScope' }
    & Pick<TbqGlobalScope, 'name' | 'scopeId'>
  )> }
);

export type GetTbqOsidDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTbqOsidDevicesQuery = (
  { __typename?: 'Query' }
  & { getTBQOsidDevices?: Maybe<(
    { __typename?: 'OsidDevicesResponse' }
    & Pick<OsidDevicesResponse, 'success' | 'errors'>
    & { data?: Maybe<(
      { __typename?: 'OsidDevicesType' }
      & Pick<OsidDevicesType, 'data'>
    )> }
  )> }
);

export type GetTbqOsidDeviceLogsQueryVariables = Exact<{
  deviceCode: Scalars['String'];
  objectId: Scalars['String'];
}>;


export type GetTbqOsidDeviceLogsQuery = (
  { __typename?: 'Query' }
  & { getTBQOsidDeviceLogs?: Maybe<Array<(
    { __typename?: 'OsidDeviceLog' }
    & DeviceLogFragmentFragment
  )>> }
);

export type GetTbqOsidDeviceLinkStateQueryVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type GetTbqOsidDeviceLinkStateQuery = (
  { __typename?: 'Query' }
  & { getTBQOsidDeviceLinkState: (
    { __typename?: 'OsidDeviceLinkStateResponse' }
    & Pick<OsidDeviceLinkStateResponse, 'data' | 'success' | 'errors'>
  ) }
);

export type SetTbqConfigMutationVariables = Exact<{
  nodeId: Scalars['ID'];
  tbqConfig?: Maybe<Array<TbqSetting> | TbqSetting>;
  tbqFunctionId?: Maybe<Scalars['ID']>;
  tbqTypeId?: Maybe<Scalars['ID']>;
}>;


export type SetTbqConfigMutation = (
  { __typename?: 'Mutation' }
  & { setTbqConfig?: Maybe<(
    { __typename?: 'SetTBQResponse' }
    & Pick<SetTbqResponse, 'nodeId' | 'success' | 'errors'>
  )> }
);

export type LinkOsidDeviceMutationVariables = Exact<{
  osidLinkInput: LinkDeviceInput;
}>;


export type LinkOsidDeviceMutation = (
  { __typename?: 'Mutation' }
  & { linkOsidDevice: (
    { __typename?: 'LinkUnlinkDeviceResponse' }
    & Pick<LinkUnlinkDeviceResponse, 'commandId' | 'success' | 'errors'>
  ) }
);

export type UnlinkOsidDeviceMutationVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type UnlinkOsidDeviceMutation = (
  { __typename?: 'Mutation' }
  & { unlinkOsidDevice: (
    { __typename?: 'LinkUnlinkDeviceResponse' }
    & Pick<LinkUnlinkDeviceResponse, 'commandId' | 'success' | 'errors'>
  ) }
);

export type CreateTbqTaskMutationVariables = Exact<{
  task: TbqTask;
}>;


export type CreateTbqTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTbqTask'>
);

export type MultiRieResultsMutationVariables = Exact<{
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type MultiRieResultsMutation = (
  { __typename?: 'Mutation' }
  & { multiRieResults?: Maybe<Array<Maybe<(
    { __typename?: 'TBQRieResult' }
    & RieResultFragmentFragment
  )>>> }
);

export type OpenCdeUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenCdeUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'openCdeUrl'>
);

export type CreateProjectMutationVariables = Exact<{
  admins?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
  deadline?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
  name: Scalars['String'];
  objectId?: Maybe<Scalars['ID']>;
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProject'>
);

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
  settings?: Maybe<ProjectSettingsInput>;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProject'>
);

export type UpdateProjectSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  settings?: Maybe<ProjectSettingsInput>;
}>;


export type UpdateProjectSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProjectSettings'>
);

export type DuplicateProjectMutationVariables = Exact<{
  sourceProjectId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectId?: Maybe<Scalars['ID']>;
  projectNumber: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  deadline?: Maybe<Scalars['DateTime']>;
}>;


export type DuplicateProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'duplicateProject'>
);

export type DeleteProjectsMutationVariables = Exact<{
  projectIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteProjectsMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjects?: Maybe<(
    { __typename?: 'BatchProjectsResponse' }
    & Pick<BatchProjectsResponse, 'succeededIds' | 'errors' | 'failedIds' | 'success'>
  )> }
);

export type AddProjectMembersMutationVariables = Exact<{
  projectId: Scalars['ID'];
  admins?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
  users?: Maybe<Array<Maybe<MemberInput>> | Maybe<MemberInput>>;
  message?: Maybe<Scalars['String']>;
}>;


export type AddProjectMembersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addProjectMembers'>
);

export type SetProjectRoleForMembersMutationVariables = Exact<{
  members: Array<Maybe<MemberInput>> | Maybe<MemberInput>;
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
}>;


export type SetProjectRoleForMembersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setProjectRoleForMembers'>
);

export type AddUsersToProjectsMutationVariables = Exact<{
  projectIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  userIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type AddUsersToProjectsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUsersToProjects'>
);

export type DeleteMembersFromProjectMutationVariables = Exact<{
  members: Array<Maybe<MemberInput>> | Maybe<MemberInput>;
  projectId: Scalars['ID'];
}>;


export type DeleteMembersFromProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMembersFromProject'>
);

export type AddExternalUsersMutationVariables = Exact<{
  projectId: Scalars['ID'];
  roleId: Scalars['ID'];
  emails: Array<Scalars['String']> | Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;


export type AddExternalUsersMutation = (
  { __typename?: 'Mutation' }
  & { addExternalUsers?: Maybe<(
    { __typename?: 'AddExternalUsersResponse' }
    & Pick<AddExternalUsersResponse, 'projectId' | 'success' | 'errors'>
  )> }
);

export type MyProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  )>> }
);

export type MyProjectRolesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type MyProjectRolesQuery = (
  { __typename?: 'Query' }
  & { myProjectRoles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'permissions'>
  )> }
);

export type MyOrganizationProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyOrganizationProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  )>> }
);

export type ProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & ProjectDetailFragmentFragment
  ) }
);

export type ProjectsQueryVariables = Exact<{
  projectIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<Maybe<(
    { __typename?: 'Project' }
    & { availableRoles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & GroupFragmentFragment
    ) | (
      { __typename?: 'User' }
      & UserFragmentFragment
    )>>> }
    & ProjectFragmentFragment
  )>> }
);

export type ProjectRolesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectRolesQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { availableRoles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
    )>>> }
  ) }
);

export type ProjectFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'deadline' | 'description' | 'organizationId' | 'projectNumber' | 'startDate'>
  & { organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'expireDate' | 'id' | 'name'>
  )> }
);

export type ProjectDetailFragmentFragment = (
  { __typename?: 'Project' }
  & { availableRoles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>>>, mainProcedure?: Maybe<(
    { __typename?: 'Procedure' }
    & Pick<Procedure, 'id' | 'description' | 'name' | 'projectId' | 'projectMainProcedure'>
  )>, settings?: Maybe<(
    { __typename?: 'ProjectSettings' }
    & Pick<ProjectSettings, 'metadata_required' | 'version_name_instead_of_document_name_on_upload' | 'approve_action_label' | 'finalize_action_label' | 'reject_action_label' | 'ai_admin_settings'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'expireDate' | 'id' | 'name' | 'organizationSubscriptionLevel'>
  )> }
  & ProjectFragmentFragment
);

export type VisualContextFragmentFragment = (
  { __typename?: 'VisualContext' }
  & Pick<VisualContext, 'id' | 'createdAt' | 'height' | 'name' | 'status' | 'width' | 'zoomLevels' | 'pageCount'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type VisualContextMobileAppFragmentFragment = (
  { __typename?: 'VisualContext' }
  & { qualityControlInstances?: Maybe<Array<(
    { __typename?: 'QualityControlInstance' }
    & QualityControlInstanceForVisualContextFragment
  )>>, drawing?: Maybe<(
    { __typename?: 'Drawing' }
    & { pages?: Maybe<Array<Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'pageNumber' | 'zoomLevels' | 'width' | 'height'>
    )>>> }
  )> }
  & VisualContextFragmentFragment
);

export type QualityControlInstanceForVisualContextFragment = (
  { __typename?: 'QualityControlInstance' }
  & Pick<QualityControlInstance, 'x' | 'y' | 'id' | 'type' | 'name' | 'page'>
  & { answers?: Maybe<Array<(
    { __typename?: 'AnswerType' }
    & AnswerTypeFragmentFragment
  )>>, items?: Maybe<Array<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionBasicFragmentFragment
  )>>, indicativeState?: Maybe<(
    { __typename?: 'State' }
    & Pick<State, 'index' | 'value' | 'color'>
  )> }
);

export type QualityControlInstanceFragmentForTaskFragment = (
  { __typename?: 'QualityControlInstance' }
  & Pick<QualityControlInstance, 'createdAt' | 'id' | 'name' | 'page' | 'reference' | 'type' | 'visualContextId'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, templateIconProps?: Maybe<(
    { __typename?: 'TemplateIconProps' }
    & Pick<TemplateIconProps, 'iconName' | 'iconType' | 'templateId'>
  )>, visualContext?: Maybe<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'id' | 'name'>
  )> }
);

export type QualityControlInstanceFragmentFragment = (
  { __typename?: 'QualityControlInstance' }
  & Pick<QualityControlInstance, 'commentStatus' | 'createdAt' | 'deletedAt' | 'iconName' | 'id' | 'isOptimistic' | 'name' | 'page' | 'projectId' | 'reference' | 'templateId' | 'templateVersion' | 'type' | 'visualContextId' | 'x' | 'y'>
  & { answers?: Maybe<Array<(
    { __typename?: 'AnswerType' }
    & AnswerTypeFragmentFragment
  )>>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, indicativeState?: Maybe<(
    { __typename?: 'State' }
    & Pick<State, 'index' | 'value' | 'color'>
  )>, items?: Maybe<Array<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>>, task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'procedureId' | 'name' | 'type'>
  )>, templateIconProps?: Maybe<(
    { __typename?: 'TemplateIconProps' }
    & Pick<TemplateIconProps, 'iconName' | 'iconType' | 'templateId'>
  )>, visualContext?: Maybe<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'id' | 'name' | 'deletedAt' | 'height' | 'width'>
  )> }
);

export type AnswerTypeFragmentFragment = (
  { __typename?: 'AnswerType' }
  & Pick<AnswerType, 'fieldId' | 'templateId' | 'templateVersion' | 'value'>
);

export type ReportImageFragmentFragment = (
  { __typename?: 'ReportImage' }
  & Pick<ReportImage, 'id' | 'imageType' | 'name' | 'projectId' | 'deletedAt'>
);

export type FormLinkedSnagsFragmentFragment = (
  { __typename?: 'FormLinkedSnag' }
  & Pick<FormLinkedSnag, 'id' | 'type' | 'linkId' | 'linkType' | 'direction'>
  & { appData?: Maybe<(
    { __typename?: 'AppData' }
    & Pick<AppData, 'fieldId'>
  )> }
);

export type QcHistoryEventFragmentFragment = (
  { __typename?: 'QcHistoryEvent' }
  & Pick<QcHistoryEvent, 'changeDate' | 'resourceId' | 'historyId' | 'name' | 'userId'>
  & { properties: Array<(
    { __typename?: 'QcHistoryProperty' }
    & Pick<QcHistoryProperty, 'addedValues' | 'addedResolvedValues' | 'changeDate' | 'deletedValues' | 'deletedResolvedValues' | 'resourceId' | 'historyId' | 'name' | 'type'>
  )>, user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ) }
);

export type SuggestedTemplateFragmentFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'name' | 'type'>
);

export type SuggestedTemplatesBasedOnImageResponseFragmentFragment = (
  { __typename?: 'SuggestedTemplatesBasedOnImageResponse' }
  & Pick<SuggestedTemplatesBasedOnImageResponse, 'mediaFileId'>
  & { suggestedTemplates: Array<(
    { __typename?: 'Template' }
    & SuggestedTemplateFragmentFragment
  )>, otherTemplates: Array<(
    { __typename?: 'Template' }
    & SuggestedTemplateFragmentFragment
  )> }
);

export type SuggestedAnswersBasedOnImageResponseFragmentFragment = (
  { __typename?: 'SuggestedAnswersBasedOnImageResponse' }
  & Pick<SuggestedAnswersBasedOnImageResponse, 'instanceId'>
  & { suggestedAnswers: Array<(
    { __typename?: 'AnswerType' }
    & AnswerTypeFragmentFragment
  )> }
);

export type FragmentSavedAnswersIncludeFragment = (
  { __typename?: 'SavedAnswers' }
  & MakeOptional<Pick<SavedAnswers, 'createdOn' | 'id' | 'name' | 'templateName' | 'type'>, 'createdOn' | 'templateName' | 'type'>
  & { answers?: Maybe<Array<(
    { __typename?: 'AnswerType' }
    & Pick<AnswerType, 'fieldId' | 'value'>
  )>> }
);

export type FragmentSavedAnswersInstanceFragment = (
  { __typename?: 'SavedAnswersInstance' }
  & Pick<SavedAnswersInstance, 'id' | 'name'>
  & { answers?: Maybe<Array<(
    { __typename?: 'AnswerType' }
    & Pick<AnswerType, 'fieldId' | 'value'>
  )>>, items?: Maybe<Array<(
    { __typename?: 'FieldDefinition' }
    & FieldDefinitionPropsFragmentFragment
  )>> }
);

export type FragmentSavedAnswersOnTemplateIdFragment = (
  { __typename?: 'SavedAnswers' }
  & Pick<SavedAnswers, 'id' | 'name'>
);

export type FragmentReportConfigurationsIncludeFragment = (
  { __typename?: 'ReportConfiguration' }
  & MakeOptional<Pick<ReportConfiguration, 'createdAt' | 'id' | 'isDefault' | 'name' | 'projectId' | 'reportOptions' | 'scope' | 'updatedAt'>, 'createdAt' | 'isDefault' | 'projectId' | 'reportOptions' | 'scope' | 'updatedAt'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type FragmentReportConfigurationFragment = (
  { __typename?: 'ReportConfiguration' }
  & Pick<ReportConfiguration, 'createdAt' | 'id' | 'isDefault' | 'name' | 'projectId' | 'reportOptions'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type CreateVisualContextFromDocumentMutationVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type CreateVisualContextFromDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createVisualContextFromDocument?: Maybe<(
    { __typename?: 'VisualContextMutationResponse' }
    & Pick<VisualContextMutationResponse, 'visualContextId' | 'success' | 'errors'>
  )> }
);

export type CreateQualityControlInstanceMutationVariables = Exact<{
  projectId: Scalars['ID'];
  resourceType?: Maybe<InstanceResourceType>;
  resourceId?: Maybe<Scalars['ID']>;
  templateId: Scalars['ID'];
  visualContext?: Maybe<VisualContextInput>;
  instanceId?: Maybe<Scalars['ID']>;
  linkToTaskId?: Maybe<Scalars['ID']>;
}>;


export type CreateQualityControlInstanceMutation = (
  { __typename?: 'Mutation' }
  & { createQualityControlInstance?: Maybe<(
    { __typename?: 'MutateQualityControlInstanceResponse' }
    & Pick<MutateQualityControlInstanceResponse, 'instanceId' | 'success' | 'errors'>
  )> }
);

export type DeleteVisualContextsMutationVariables = Exact<{
  visualContextIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  includeSnagsAndForms?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type DeleteVisualContextsMutation = (
  { __typename?: 'Mutation' }
  & { deleteVisualContexts?: Maybe<(
    { __typename?: 'VisualContextMutationResponse' }
    & Pick<VisualContextMutationResponse, 'success' | 'errors'>
  )> }
);

export type RestoreVisualContextsMutationVariables = Exact<{
  visualContextIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  includeSnagsAndForms?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type RestoreVisualContextsMutation = (
  { __typename?: 'Mutation' }
  & { restoreVisualContexts?: Maybe<(
    { __typename?: 'VisualContextMutationResponse' }
    & Pick<VisualContextMutationResponse, 'success' | 'errors'>
  )> }
);

export type AddSnagToFormLinkMutationVariables = Exact<{
  fieldId: Scalars['ID'];
  formInstanceId: Scalars['ID'];
  snagInstanceId: Scalars['ID'];
}>;


export type AddSnagToFormLinkMutation = (
  { __typename?: 'Mutation' }
  & { addSnagToFormLink?: Maybe<(
    { __typename?: 'MutateQualityControlInstanceResponse' }
    & Pick<MutateQualityControlInstanceResponse, 'instanceId' | 'success' | 'errors'>
  )> }
);

export type UnlinkLinkMutationVariables = Exact<{
  linkId: Scalars['ID'];
}>;


export type UnlinkLinkMutation = (
  { __typename?: 'Mutation' }
  & { unlinkLink?: Maybe<(
    { __typename?: 'MutateQualityControlInstanceResponse' }
    & Pick<MutateQualityControlInstanceResponse, 'instanceId' | 'success' | 'errors'>
  )> }
);

export type AddSavedAnswersMutationVariables = Exact<{
  answers?: Maybe<Array<Answer> | Answer>;
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  templateId: Scalars['ID'];
}>;


export type AddSavedAnswersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSavedAnswers'>
);

export type DeleteSavedAnswersMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteSavedAnswersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSavedAnswers'>
);

export type RestoreSavedAnswersMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RestoreSavedAnswersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreSavedAnswers'>
);

export type DeleteInstanceMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteInstanceMutation = (
  { __typename?: 'Mutation' }
  & { deleteInstance?: Maybe<Array<Maybe<(
    { __typename?: 'DeleteInstanceResponse' }
    & Pick<DeleteInstanceResponse, 'success' | 'errors' | 'instanceId'>
  )>>> }
);

export type RestoreInstanceMutationVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type RestoreInstanceMutation = (
  { __typename?: 'Mutation' }
  & { restoreInstance?: Maybe<(
    { __typename?: 'RestoreInstanceResponse' }
    & Pick<RestoreInstanceResponse, 'success'>
    & { data?: Maybe<(
      { __typename?: 'RestoreInstanceData' }
      & Pick<RestoreInstanceData, 'instanceId'>
    )> }
  )> }
);

export type UpdateSavedAnswersMutationVariables = Exact<{
  answers?: Maybe<Array<Answer> | Answer>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type UpdateSavedAnswersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSavedAnswers'>
);

export type QualityControlTaskInstancesQueryVariables = Exact<{
  taskId: Scalars['ID'];
  templateId?: Maybe<Scalars['ID']>;
}>;


export type QualityControlTaskInstancesQuery = (
  { __typename?: 'Query' }
  & { qualityControlTaskInstances?: Maybe<Array<Maybe<(
    { __typename?: 'QualityControlInstance' }
    & Pick<QualityControlInstance, 'id' | 'createdAt'>
    & { answers?: Maybe<Array<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'fieldId' | 'value'>
    )>>, templates?: Maybe<Array<(
      { __typename?: 'QCTemplate' }
      & Pick<QcTemplate, 'templateId' | 'templateVersion'>
    )>> }
  )>>> }
);

export type QualityControlInstancesQueryVariables = Exact<{
  projectId: Scalars['ID'];
  visualContextIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  resourceIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  pages?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type QualityControlInstancesQuery = (
  { __typename?: 'Query' }
  & { qualityControlInstances: Array<(
    { __typename?: 'QualityControlInstance' }
    & QualityControlInstanceFragmentFragment
  )> }
);

export type ResourcePermissionsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  resourceId: Scalars['ID'];
  resourceType: ResourceType;
}>;


export type ResourcePermissionsQuery = (
  { __typename?: 'Query' }
  & { resourcePermissions?: Maybe<(
    { __typename?: 'ResourcePermissions' }
    & Pick<ResourcePermissions, 'permissions'>
  )> }
);

export type QualityControlInstanceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QualityControlInstanceQuery = (
  { __typename?: 'Query' }
  & { qualityControlInstance?: Maybe<(
    { __typename?: 'QualityControlInstance' }
    & Pick<QualityControlInstance, 'taskId' | 'projectId'>
    & { items?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & FieldDefinitionPropsFragmentFragment
    )>>, answers?: Maybe<Array<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'fieldId' | 'value'>
    )>>, linkedSnags?: Maybe<Array<(
      { __typename?: 'FormLinkedSnag' }
      & FormLinkedSnagsFragmentFragment
    )>> }
    & QualityControlInstanceFragmentFragment
  )> }
);

export type BatchQualityControlInstancesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type BatchQualityControlInstancesQuery = (
  { __typename?: 'Query' }
  & { qualityControlInstances: Array<(
    { __typename?: 'QualityControlInstance' }
    & Pick<QualityControlInstance, 'taskId' | 'projectId'>
    & { items?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & FieldDefinitionPropsFragmentFragment
    )>>, answers?: Maybe<Array<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'fieldId' | 'value'>
    )>>, linkedSnags?: Maybe<Array<(
      { __typename?: 'FormLinkedSnag' }
      & FormLinkedSnagsFragmentFragment
    )>>, linkedSnagInstances?: Maybe<Array<(
      { __typename?: 'QualityControlInstance' }
      & Pick<QualityControlInstance, 'name' | 'reference' | 'id' | 'type' | 'x' | 'y' | 'linkedFieldId'>
      & { visualContext?: Maybe<(
        { __typename?: 'VisualContext' }
        & Pick<VisualContext, 'id' | 'name' | 'height' | 'formCount' | 'freeSnagCount' | 'snagCount' | 'pageCount' | 'width' | 'zoomLevels' | 'status'>
      )> }
    )>> }
    & QualityControlInstanceFragmentFragment
  )> }
);

export type QualityControlGetOrCreateInstanceQueryVariables = Exact<{
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  templateId: Scalars['ID'];
}>;


export type QualityControlGetOrCreateInstanceQuery = (
  { __typename?: 'Query' }
  & { qualityControlGetOrCreateInstance?: Maybe<(
    { __typename?: 'QualityControlInstance' }
    & Pick<QualityControlInstance, 'id'>
  )> }
);

export type VisualContextsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type VisualContextsQuery = (
  { __typename?: 'Query' }
  & { visualContexts?: Maybe<Array<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'snagCount' | 'freeSnagCount' | 'formCount'>
    & VisualContextFragmentFragment
  )>> }
);

export type VisualContextQueryVariables = Exact<{
  id: Scalars['ID'];
  resourceIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type VisualContextQuery = (
  { __typename?: 'Query' }
  & { visualContext?: Maybe<(
    { __typename?: 'VisualContext' }
    & VisualContextFragmentFragment
  )> }
);

export type SnagFormHistoryLogQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SnagFormHistoryLogQuery = (
  { __typename?: 'Query' }
  & { snagFormHistoryLog?: Maybe<(
    { __typename?: 'QCInstanceHistory' }
    & { today?: Maybe<Array<(
      { __typename?: 'QcHistoryEvent' }
      & QcHistoryEventFragmentFragment
    )>>, thisWeek?: Maybe<Array<(
      { __typename?: 'QcHistoryEvent' }
      & QcHistoryEventFragmentFragment
    )>>, thisMonth?: Maybe<Array<(
      { __typename?: 'QcHistoryEvent' }
      & QcHistoryEventFragmentFragment
    )>>, older?: Maybe<Array<(
      { __typename?: 'QcHistoryEvent' }
      & QcHistoryEventFragmentFragment
    )>> }
  )> }
);

export type VisualContextMobileAppQueryVariables = Exact<{
  id: Scalars['ID'];
  resourceIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  projectId: Scalars['ID'];
}>;


export type VisualContextMobileAppQuery = (
  { __typename?: 'Query' }
  & { visualContext?: Maybe<(
    { __typename?: 'VisualContext' }
    & VisualContextMobileAppFragmentFragment
  )> }
);

export type SuggestedTemplatesBasedOnImageQueryVariables = Exact<{
  projectId: Scalars['ID'];
  base64ImageString: Scalars['String'];
  templateType: TemplateType;
}>;


export type SuggestedTemplatesBasedOnImageQuery = (
  { __typename?: 'Query' }
  & { suggestedTemplatesBasedOnImage?: Maybe<(
    { __typename?: 'SuggestedTemplatesBasedOnImageResponse' }
    & SuggestedTemplatesBasedOnImageResponseFragmentFragment
  )> }
);

export type InstanceWithSuggestedAnswersQueryVariables = Exact<{
  templateId: Scalars['ID'];
  fileId: Scalars['ID'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  page?: Maybe<Scalars['Int']>;
  visualContextId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type InstanceWithSuggestedAnswersQuery = (
  { __typename?: 'Query' }
  & { instanceWithSuggestedAnswers?: Maybe<(
    { __typename?: 'SuggestedAnswersBasedOnImageResponse' }
    & SuggestedAnswersBasedOnImageResponseFragmentFragment
  )> }
);

export type SavedAnswersIncludeQueryVariables = Exact<{
  includeAnswers?: Maybe<Scalars['Boolean']>;
  includeCreatedOn?: Maybe<Scalars['Boolean']>;
  includeTemplateName?: Maybe<Scalars['Boolean']>;
  includeType?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
}>;


export type SavedAnswersIncludeQuery = (
  { __typename?: 'Query' }
  & { savedAnswers?: Maybe<Array<(
    { __typename?: 'SavedAnswers' }
    & FragmentSavedAnswersIncludeFragment
  )>> }
);

export type SavedAnswersInstanceIncludeQueryVariables = Exact<{
  id: Scalars['ID'];
  includeAnswers?: Maybe<Scalars['Boolean']>;
  includeItems?: Maybe<Scalars['Boolean']>;
}>;


export type SavedAnswersInstanceIncludeQuery = (
  { __typename?: 'Query' }
  & { savedAnswersInstance?: Maybe<(
    { __typename?: 'SavedAnswersInstance' }
    & FragmentSavedAnswersInstanceFragment
  )> }
);

export type SavedAnswersOnTemplateIdQueryVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type SavedAnswersOnTemplateIdQuery = (
  { __typename?: 'Query' }
  & { savedAnswersOnTemplateId?: Maybe<Array<(
    { __typename?: 'SavedAnswers' }
    & FragmentSavedAnswersOnTemplateIdFragment
  )>> }
);

export type TestMobileAppFallBackApiQueryVariables = Exact<{ [key: string]: never; }>;


export type TestMobileAppFallBackApiQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testMobileAppFallBackApi'>
);

export type CreateReportConfigurationMutationVariables = Exact<{
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  reportOptions: Scalars['String'];
  setAsDefault: Scalars['Boolean'];
  storeOnOrganizationLevel: Scalars['Boolean'];
}>;


export type CreateReportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReportConfiguration'>
);

export type DeleteReportConfigurationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteReportConfigurationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReportConfigurations'>
);

export type EditReportConfigurationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId?: Maybe<Scalars['ID']>;
  reportOptions: Scalars['String'];
  setAsDefault: Scalars['Boolean'];
  storeOnOrganizationLevel: Scalars['Boolean'];
}>;


export type EditReportConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editReportConfiguration'>
);

export type ReportLogosQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportLogosQuery = (
  { __typename?: 'Query' }
  & { reportLogos?: Maybe<Array<(
    { __typename?: 'ReportImage' }
    & ReportImageFragmentFragment
  )>> }
);

export type ReportBackgroundsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportBackgroundsQuery = (
  { __typename?: 'Query' }
  & { reportBackgrounds?: Maybe<Array<(
    { __typename?: 'ReportImage' }
    & ReportImageFragmentFragment
  )>> }
);

export type ReportConfigurationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReportConfigurationQuery = (
  { __typename?: 'Query' }
  & { reportConfiguration?: Maybe<(
    { __typename?: 'ReportConfiguration' }
    & FragmentReportConfigurationFragment
  )> }
);

export type ReportConfigurationsIncludeQueryVariables = Exact<{
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeIsDefault?: Maybe<Scalars['Boolean']>;
  includeProjectId?: Maybe<Scalars['Boolean']>;
  includeReportOptions?: Maybe<Scalars['Boolean']>;
  includeScope?: Maybe<Scalars['Boolean']>;
  includeUpdatedAt?: Maybe<Scalars['Boolean']>;
  includeUpdatedBy?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
}>;


export type ReportConfigurationsIncludeQuery = (
  { __typename?: 'Query' }
  & { reportConfigurations?: Maybe<Array<Maybe<(
    { __typename?: 'ReportConfiguration' }
    & FragmentReportConfigurationsIncludeFragment
  )>>> }
);

export type RegisterOrganizationMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  organizationName: Scalars['String'];
  password: Scalars['String'];
  recaptcha: Scalars['String'];
  subscriptionIntent: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type RegisterOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registerOrganization'>
);

export type FinalizeOrgInvitationMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  locale: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type FinalizeOrgInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'finalizeOrgInvitation'>
);

export type ValidateRegistrationTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidateRegistrationTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'validateRegistrationToken'>
);

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmEmail'>
);

export type UserExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userExists'>
);

export type OrganizationExistsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrganizationExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'organizationExists'>
);

export type SearchDocumentFragmentFragment = (
  { __typename?: 'SearchDocument' }
  & Pick<SearchDocument, 'createdAt' | 'commentStatus' | 'extension' | 'folderId' | 'hasPreviousVersionQr' | 'id' | 'isExpected' | 'lockedAt' | 'lockType' | 'name' | 'path' | 'pathIds' | 'qrCodeState' | 'state' | 'reference' | 'type' | 'updatedAt' | 'versionId' | 'versionNumber' | 'versionState' | 'x' | 'y' | 'page'>
  & { createdBy?: Maybe<(
    { __typename?: 'SearchUser' }
    & Pick<SearchUser, 'displayName' | 'id' | 'email'>
  )>, lockedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, project?: Maybe<(
    { __typename?: 'SearchProject' }
    & Pick<SearchProject, 'projectNumber' | 'name'>
  )>, task?: Maybe<Array<Maybe<(
    { __typename?: 'QCSearchTask' }
    & Pick<QcSearchTask, 'name' | 'type' | 'status' | 'id' | 'endTime'>
    & { assignment?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )>> }
  )>>>, indicativeState?: Maybe<(
    { __typename?: 'State' }
    & Pick<State, 'color' | 'index' | 'value'>
  )>, updatedBy?: Maybe<(
    { __typename?: 'SearchUser' }
    & Pick<SearchUser, 'displayName' | 'email'>
  )>, visualContext?: Maybe<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'name' | 'id' | 'status' | 'visualContextId' | 'deletedAt'>
  )>, templateIconProps?: Maybe<(
    { __typename?: 'TemplateIconProps' }
    & Pick<TemplateIconProps, 'iconName' | 'iconType' | 'templateId'>
  )> }
);

export type SearchDocumentMetaDataFragmentFragment = (
  { __typename?: 'SearchDocument' }
  & { metaDataInstance?: Maybe<(
    { __typename?: 'MetaDataInstance' }
    & { questions?: Maybe<Array<Maybe<(
      { __typename?: 'SearchDocumentMetaQuestion' }
      & Pick<SearchDocumentMetaQuestion, 'fieldDefinitionId' | 'answer' | 'displayName' | 'valueType' | 'templateId' | 'fieldId'>
    )>>> }
  )> }
);

export type LoadQueriesFragmentFragment = (
  { __typename?: 'SavedSearch' }
  & Pick<SavedSearch, 'id' | 'name' | 'searchQuery' | 'searchFilter' | 'notificationDays' | 'notificationsEnabled' | 'projectId' | 'createdAt' | 'createdBy' | 'deletedAt' | 'deletedBy' | 'scope'>
  & { notificationTimes: Array<Maybe<(
    { __typename?: 'ScheduledTime' }
    & Pick<ScheduledTime, 'hour' | 'minute'>
  )>> }
);

export type SaveQueryMutationVariables = Exact<{
  documentType: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  name: Scalars['String'];
  notificationsEnabled: Scalars['Boolean'];
  notificationDays: Array<Maybe<WeekDay>> | Maybe<WeekDay>;
  notificationTimes: Array<Maybe<ScheduledTimeInput>> | Maybe<ScheduledTimeInput>;
  notificationTimeZone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  searchUriComponent: Scalars['String'];
  scope: Scalars['Int'];
}>;


export type SaveQueryMutation = (
  { __typename?: 'Mutation' }
  & { saveQuery?: Maybe<(
    { __typename?: 'SavedSearchResponse' }
    & Pick<SavedSearchResponse, 'errors' | 'name' | 'success'>
  )> }
);

export type EditQueryMutationVariables = Exact<{
  id: Scalars['ID'];
  documentType: Scalars['String'];
  filters?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  name: Scalars['String'];
  notificationsEnabled: Scalars['Boolean'];
  notificationDays: Array<Maybe<WeekDay>> | Maybe<WeekDay>;
  notificationTimes: Array<Maybe<ScheduledTimeInput>> | Maybe<ScheduledTimeInput>;
  notificationTimeZone?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  searchUriComponent: Scalars['String'];
}>;


export type EditQueryMutation = (
  { __typename?: 'Mutation' }
  & { editQuery?: Maybe<(
    { __typename?: 'SavedSearchResponse' }
    & Pick<SavedSearchResponse, 'errors' | 'success'>
  )> }
);

export type DeleteQueryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type DeleteQueryMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuery?: Maybe<(
    { __typename?: 'SavedSearchResponse' }
    & Pick<SavedSearchResponse, 'errors' | 'name' | 'success'>
  )> }
);

export type RestoreQueryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type RestoreQueryMutation = (
  { __typename?: 'Mutation' }
  & { restoreQuery?: Maybe<(
    { __typename?: 'SavedSearchResponse' }
    & Pick<SavedSearchResponse, 'errors' | 'name' | 'success'>
  )> }
);

export type SearchQueryVariables = Exact<{
  documentType?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  contextQuery?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  facet?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  order?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResponse' }
    & Pick<SearchResponse, 'totalCount' | 'requestBody' | 'debugInfo'>
    & { searchResults?: Maybe<Array<(
      { __typename?: 'SearchDocument' }
      & SearchDocumentFragmentFragment
      & SearchDocumentMetaDataFragmentFragment
    ) | { __typename?: 'SearchQCInstance' }>>, facetGroups?: Maybe<Array<Maybe<(
      { __typename?: 'FacetGroup' }
      & Pick<FacetGroup, 'type'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'FacetItem' }
        & Pick<FacetItem, 'properties' | 'apiValue' | 'name' | 'value'>
        & { mdField?: Maybe<(
          { __typename?: 'FacetItemMDField' }
          & Pick<FacetItemMdField, 'fieldDefinitionId' | 'fieldId' | 'valueType' | 'id'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type LoadQueriesQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
}>;


export type LoadQueriesQuery = (
  { __typename?: 'Query' }
  & { loadQueries?: Maybe<Array<Maybe<(
    { __typename?: 'SavedSearch' }
    & LoadQueriesFragmentFragment
  )>>> }
);

export type TaskFragmentFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'commentStatus' | 'completedAt' | 'createdAt' | 'description' | 'documentAction' | 'endTime' | 'id' | 'isOptimistic' | 'name' | 'procedureId' | 'status' | 'taskCategoryId' | 'taskCategoryName' | 'tbqTbqResolveTaskLinkedObjectId' | 'type'>
  & { assignment?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>>, availableStatuses?: Maybe<Array<(
    { __typename?: 'TaskStatus' }
    & Pick<TaskStatus, 'taskStatus' | 'available' | 'command'>
  )>>, createdByUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>, tbq?: Maybe<(
    { __typename?: 'TBQ' }
    & Pick<Tbq, 'tbqScanTypeId' | 'tbqTypeId' | 'tbqModuleType'>
  )>, tbqLinkedQuestions?: Maybe<Array<(
    { __typename?: 'ResolveTaskLinks' }
    & ResolveTaskLinksFragmentFragment
  )>> }
);

export type ResolveTaskLinksFragmentFragment = (
  { __typename?: 'ResolveTaskLinks' }
  & Pick<ResolveTaskLinks, 'appData' | 'direction' | 'id' | 'linkId' | 'linkType' | 'type'>
);

export type TbqRieResultFragmentFragment = (
  { __typename?: 'TBQRieResult' }
  & Pick<TbqRieResult, 'id' | 'risc' | 'category' | 'location' | 'priority' | 'class' | 'taskId' | 'scanId' | 'instanceId'>
  & { section: (
    { __typename?: 'RieSection' }
    & Pick<RieSection, 'id' | 'name' | 'type' | 'parentSectionId' | 'displayName' | 'index' | 'tagId'>
    & { valueType: (
      { __typename?: 'RieValueTypeSection' }
      & { subFields: Array<(
        { __typename?: 'RieSubField' }
        & Pick<RieSubField, 'name' | 'type' | 'parentSectionId' | 'id' | 'displayName' | 'description' | 'displayDescription' | 'index' | 'linksAllowed' | 'required' | 'indicative' | 'tagId' | 'fieldDefinitionId' | 'fieldDefinitionDeleted' | 'value'>
        & { valueType: (
          { __typename?: 'RieValueType' }
          & Pick<RieValueType, 'id' | 'name'>
        ) }
      )> }
    ), conditions?: Maybe<Array<Maybe<(
      { __typename?: 'RieCondition' }
      & Pick<RieCondition, 'fieldId' | 'value'>
    )>>> }
  ), result: (
    { __typename?: 'RieResult' }
    & Pick<RieResult, 'name' | 'color' | 'index'>
  ) }
);

export type TaskDetailFragmentFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'description' | 'endTime' | 'id' | 'name' | 'procedureId' | 'status' | 'type'>
  & { assignment?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )>>, attachments?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & LinkedDocumentFragmentFragment
  )>>>, availableStatuses?: Maybe<Array<(
    { __typename?: 'TaskStatus' }
    & Pick<TaskStatus, 'taskStatus' | 'available' | 'command'>
  )>>, deliverables?: Maybe<Array<Maybe<(
    { __typename?: 'Document' }
    & LinkedDocumentFragmentFragment
  )>>>, formTemplates?: Maybe<Array<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'state' | 'type'>
    & { icon?: Maybe<(
      { __typename?: 'TemplateIcon' }
      & Pick<TemplateIcon, 'fileId' | 'name' | 'type'>
    )> }
  )>>, linkedFormInstances?: Maybe<Array<(
    { __typename?: 'QualityControlInstance' }
    & QualityControlInstanceFragmentForTaskFragment
  )>>, linkedSnagInstances?: Maybe<Array<(
    { __typename?: 'QualityControlInstance' }
    & QualityControlInstanceFragmentForTaskFragment
  )>>, project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'organizationId' | 'projectNumber'>
  )>, snagTemplates?: Maybe<Array<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'state' | 'type'>
    & { icon?: Maybe<(
      { __typename?: 'TemplateIcon' }
      & Pick<TemplateIcon, 'fileId' | 'name' | 'type'>
    )> }
  )>>, tbqFormInstances?: Maybe<Array<(
    { __typename?: 'QualityControlInstance' }
    & { items?: Maybe<Array<(
      { __typename?: 'FieldDefinition' }
      & FieldDefinitionPropsFragmentFragment
    )>> }
    & QualityControlInstanceFragmentForTaskFragment
  )>>, tbqLinkedQuestions?: Maybe<Array<(
    { __typename?: 'ResolveTaskLinks' }
    & ResolveTaskLinksFragmentFragment
  )>>, visualContexts?: Maybe<Array<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'id' | 'name' | 'deletedAt' | 'createdAt'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )> }
  )>> }
  & TaskFragmentFragment
);

export type LinkedDocumentFragmentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'name' | 'createdAt' | 'deletedAt' | 'expectedExtension' | 'extension' | 'folderId' | 'isExpected' | 'path' | 'projectId' | 'versionId' | 'versionNumber'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  )> }
);

export type CreateTaskMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  procedureId: Scalars['ID'];
  type?: Maybe<TaskType>;
  documentAction?: Maybe<DocumentAction>;
  duplicatedFrom?: Maybe<Scalars['String']>;
  taskCategoryId?: Maybe<Scalars['String']>;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type CreateTbqScanTaskMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  procedureId: Scalars['ID'];
  projectId: Scalars['ID'];
  type?: Maybe<TaskType>;
  taskCategoryId?: Maybe<Scalars['String']>;
  tbqTypeId: Scalars['String'];
  tbqScanTypeId: Scalars['String'];
  tbqModuleType: TbqModuleType;
}>;


export type CreateTbqScanTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTbqScanTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type CreateBatchTaskCategoryMutationVariables = Exact<{
  names: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
  taskType: TaskType;
}>;


export type CreateBatchTaskCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createBatchTaskCategory?: Maybe<(
    { __typename?: 'BatchTaskCategoryResponse' }
    & Pick<BatchTaskCategoryResponse, 'ids' | 'success' | 'errors'>
  )> }
);

export type UpdateBatchTaskCategoryMutationVariables = Exact<{
  taskCategories: Array<TaskCategoryInput> | TaskCategoryInput;
}>;


export type UpdateBatchTaskCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateBatchTaskCategory?: Maybe<(
    { __typename?: 'BatchUpdateTaskCategoryResponse' }
    & Pick<BatchUpdateTaskCategoryResponse, 'success' | 'errors'>
  )> }
);

export type DeleteBatchTaskCategoryMutationVariables = Exact<{
  taskCategories: Array<TaskCategoryInput> | TaskCategoryInput;
}>;


export type DeleteBatchTaskCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteBatchTaskCategory?: Maybe<(
    { __typename?: 'BatchDeleteTaskCategoryResponse' }
    & Pick<BatchDeleteTaskCategoryResponse, 'success' | 'errors'>
  )> }
);

export type CreateDocumentTaskMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  procedureId: Scalars['ID'];
  type?: Maybe<TaskType>;
  duplicatedFrom?: Maybe<Scalars['String']>;
  documentAction?: Maybe<DocumentAction>;
  taskCategoryId?: Maybe<Scalars['String']>;
}>;


export type CreateDocumentTaskMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  procedureId: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type RestoreTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  procedureId: Scalars['ID'];
}>;


export type RestoreTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restoreTask'>
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  command?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  procedureId: Scalars['ID'];
  type?: Maybe<TaskType>;
  userId?: Maybe<Scalars['ID']>;
  documentAction?: Maybe<DocumentAction>;
  taskCategoryId?: Maybe<Scalars['String']>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & TaskFragmentFragment
  )> }
);

export type AddQuestionsToResolveTaskBatchMutationVariables = Exact<{
  batch: Array<QuestionInput> | QuestionInput;
}>;


export type AddQuestionsToResolveTaskBatchMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionsToResolveTaskBatch?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'success' | 'id'>
  )> }
);

export type SetLinkMutationVariables = Exact<{
  resourceAId: Scalars['ID'];
  resourceAType?: Maybe<ResourceType>;
  resourceBLinkIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  resourceBType?: Maybe<ResourceType>;
  appData?: Maybe<AppDataInput>;
}>;


export type SetLinkMutation = (
  { __typename?: 'Mutation' }
  & { setLink?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'success' | 'id'>
  )> }
);

export type SetDocumentTaskLinksMutationVariables = Exact<{
  attachmentTaskIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  deliverableTaskIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  documentId: Scalars['ID'];
}>;


export type SetDocumentTaskLinksMutation = (
  { __typename?: 'Mutation' }
  & { setDocumentTaskLinks?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'success' | 'id'>
  )> }
);

export type SetTaskTemplateLinksMutationVariables = Exact<{
  taskId: Scalars['ID'];
  templateIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type SetTaskTemplateLinksMutation = (
  { __typename?: 'Mutation' }
  & { setTaskTemplateLinks?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'success' | 'id'>
  )> }
);

export type SetTaskVisualContextLinksMutationVariables = Exact<{
  taskId: Scalars['ID'];
  visualContextIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type SetTaskVisualContextLinksMutation = (
  { __typename?: 'Mutation' }
  & { setTaskVisualContextLinks?: Maybe<(
    { __typename?: 'LinkResponse' }
    & Pick<LinkResponse, 'success' | 'id'>
  )> }
);

export type ProjectTasksQueryVariables = Exact<{
  id: Scalars['ID'];
  typeFilter?: Maybe<Array<Maybe<TaskType>> | Maybe<TaskType>>;
}>;


export type ProjectTasksQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { mainProcedure?: Maybe<(
      { __typename?: 'Procedure' }
      & Pick<Procedure, 'id'>
      & { tasks?: Maybe<Array<(
        { __typename?: 'Task' }
        & TaskFragmentFragment
      )>> }
    )> }
  ) }
);

export type MyTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTasksQuery = (
  { __typename?: 'Query' }
  & { myTasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
    & TaskFragmentFragment
  )>> }
);

export type GetProjectTaskCategoriesByTypeQueryVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
  taskType: TaskType;
}>;


export type GetProjectTaskCategoriesByTypeQuery = (
  { __typename?: 'Query' }
  & { getProjectTaskCategoriesByType?: Maybe<Array<(
    { __typename?: 'TaskCategory' }
    & Pick<TaskCategory, 'name' | 'taskType' | 'taskCategoryId' | 'projectId' | 'organizationId'>
  )>> }
);

export type GetOrganizationTaskCategoriesByTypeQueryVariables = Exact<{
  taskType: TaskType;
}>;


export type GetOrganizationTaskCategoriesByTypeQuery = (
  { __typename?: 'Query' }
  & { getOrganizationTaskCategoriesByType?: Maybe<Array<(
    { __typename?: 'TaskCategory' }
    & Pick<TaskCategory, 'name' | 'taskType' | 'taskCategoryId' | 'projectId' | 'organizationId'>
  )>> }
);

export type VisualContextsForTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VisualContextsForTaskQuery = (
  { __typename?: 'Query' }
  & { visualContextsForTask?: Maybe<Array<(
    { __typename?: 'VisualContext' }
    & Pick<VisualContext, 'id' | 'name'>
  )>> }
);

export type TaskStatusesQueryVariables = Exact<{
  id: Scalars['ID'];
  status: Status;
}>;


export type TaskStatusesQuery = (
  { __typename?: 'Query' }
  & { taskStatuses?: Maybe<Array<(
    { __typename?: 'TaskStatus' }
    & Pick<TaskStatus, 'taskStatus' | 'available' | 'command'>
  )>> }
);

export type TaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & TaskDetailFragmentFragment
  )> }
);

export type TasksBatchIncludeQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  includeName?: Maybe<Scalars['Boolean']>;
  includeCreatedBy?: Maybe<Scalars['Boolean']>;
  includeCreatedAt?: Maybe<Scalars['Boolean']>;
  includeAssignment?: Maybe<Scalars['Boolean']>;
  includeCategory?: Maybe<Scalars['Boolean']>;
  includeLinkedSnagInstances?: Maybe<Scalars['Boolean']>;
  includeLinkedFormInstances?: Maybe<Scalars['Boolean']>;
  includeStatus?: Maybe<Scalars['Boolean']>;
  includeCompletedAt?: Maybe<Scalars['Boolean']>;
  includeSnagTemplates?: Maybe<Scalars['Boolean']>;
  includeFormTemplates?: Maybe<Scalars['Boolean']>;
  includeVisualContexts?: Maybe<Scalars['Boolean']>;
  includeEndtime?: Maybe<Scalars['Boolean']>;
  includeDescription?: Maybe<Scalars['Boolean']>;
}>;


export type TasksBatchIncludeQuery = (
  { __typename?: 'Query' }
  & { tasksBatchInclude?: Maybe<Array<(
    { __typename?: 'Task' }
    & MakeOptional<Pick<Task, 'id' | 'procedureId' | 'name' | 'type' | 'taskCategoryName' | 'createdAt' | 'completedAt' | 'description' | 'endTime' | 'status'>, 'name' | 'taskCategoryName' | 'createdAt' | 'completedAt' | 'description' | 'endTime' | 'status'>
    & { createdByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )>, snagTemplates?: Maybe<Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name'>
    )>>, formTemplates?: Maybe<Array<(
      { __typename?: 'Template' }
      & Pick<Template, 'id' | 'name'>
    )>>, assignment?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    )>>, visualContexts?: Maybe<Array<(
      { __typename?: 'VisualContext' }
      & Pick<VisualContext, 'id' | 'name'>
    )>>, linkedSnagInstances?: Maybe<Array<(
      { __typename?: 'QualityControlInstance' }
      & QualityControlInstanceFragmentFragment
    )>>, linkedFormInstances?: Maybe<Array<(
      { __typename?: 'QualityControlInstance' }
      & Pick<QualityControlInstance, 'id' | 'reference' | 'type' | 'name' | 'visualContextId' | 'x' | 'y' | 'page'>
      & { items?: Maybe<Array<(
        { __typename?: 'FieldDefinition' }
        & FieldDefinitionBasicFragmentFragment
      )>>, visualContext?: Maybe<(
        { __typename?: 'VisualContext' }
        & Pick<VisualContext, 'name' | 'id'>
      )> }
    )>> }
  )>> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddExternalUsersResponse: ResolverTypeWrapper<AddExternalUsersResponse>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AddSignatureResponse: ResolverTypeWrapper<AddSignatureResponse>;
  AiSummary: ResolverTypeWrapper<AiSummary>;
  Annotation: ResolverTypeWrapper<Annotation>;
  Answer: Answer;
  AnswerType: ResolverTypeWrapper<AnswerType>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ApiKey: ResolverTypeWrapper<ApiKey>;
  AppData: ResolverTypeWrapper<AppData>;
  AppDataInput: AppDataInput;
  ApplicationInput: ApplicationInput;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentContext: AttachmentContext;
  AttachmentInput: AttachmentInput;
  AuthType: AuthType;
  BatchDeleteTaskCategoryResponse: ResolverTypeWrapper<BatchDeleteTaskCategoryResponse>;
  BatchDocumentsResponse: ResolverTypeWrapper<BatchDocumentsResponse>;
  BatchMutation: ResolverTypeWrapper<BatchMutation>;
  BatchProjectsResponse: ResolverTypeWrapper<BatchProjectsResponse>;
  BatchResult: ResolverTypeWrapper<BatchResult>;
  BatchTaskCategoryResponse: ResolverTypeWrapper<BatchTaskCategoryResponse>;
  BatchUpdateTaskCategoryResponse: ResolverTypeWrapper<BatchUpdateTaskCategoryResponse>;
  Build12Connection: ResolverTypeWrapper<Build12Connection>;
  Build12State: Build12State;
  CancelSubscriptionDataResponse: ResolverTypeWrapper<CancelSubscriptionDataResponse>;
  ChangeSubscriptionDataResponse: ResolverTypeWrapper<ChangeSubscriptionDataResponse>;
  ClientDownloadUrls: ResolverTypeWrapper<ClientDownloadUrls>;
  CommandOutput: ResolverTypeWrapper<CommandOutput>;
  CommandStatus: ResolverTypeWrapper<CommandStatus>;
  CommentStatus: CommentStatus;
  CommentThread: ResolverTypeWrapper<CommentThread>;
  Condition: ResolverTypeWrapper<Condition>;
  ConfigDataSegment: ResolverTypeWrapper<ConfigDataSegment>;
  ConfigDataSegmentInput: ConfigDataSegmentInput;
  ConnectProjectResponse: ResolverTypeWrapper<ConnectProjectResponse>;
  Connection: ResolverTypeWrapper<Connection>;
  ConversionStatus: ConversionStatus;
  Country: ResolverTypeWrapper<Country>;
  CountryDataResponse: ResolverTypeWrapper<CountryDataResponse>;
  CreateCommandInput: CreateCommandInput;
  CreateShareLinkResponse: ResolverTypeWrapper<CreateShareLinkResponse>;
  CustomConnectionProps: CustomConnectionProps;
  CustomIntegrationProps: CustomIntegrationProps;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteInstanceResponse: ResolverTypeWrapper<DeleteInstanceResponse>;
  Direction: Direction;
  DirectionType: DirectionType;
  Document: ResolverTypeWrapper<Document>;
  DocumentAction: DocumentAction;
  DocumentCreate: DocumentCreate;
  DocumentDigest: ResolverTypeWrapper<DocumentDigest>;
  DocumentDigestUser: DocumentDigestUser;
  DocumentEventType: DocumentEventType;
  DocumentHistory: ResolverTypeWrapper<DocumentHistory>;
  DocumentHistoryEvent: ResolverTypeWrapper<DocumentHistoryEvent>;
  DocumentHistoryProperty: ResolverTypeWrapper<DocumentHistoryProperty>;
  DocumentIdVersionId: DocumentIdVersionId;
  DocumentKeyword: DocumentKeyword;
  DocumentMetaData: ResolverTypeWrapper<DocumentMetaData>;
  DocumentPropertyType: DocumentPropertyType;
  DocumentResponse: ResolverTypeWrapper<DocumentResponse>;
  DocumentUpdate: DocumentUpdate;
  DocumentVersion: ResolverTypeWrapper<DocumentVersion>;
  DocumentVersionMetadataMapping: DocumentVersionMetadataMapping;
  DocumentVersionState: DocumentVersionState;
  DocumentsResponse: ResolverTypeWrapper<DocumentsResponse>;
  DownloadFilenameCharacter: ResolverTypeWrapper<DownloadFilenameCharacter>;
  DownloadFilenameConfig: ResolverTypeWrapper<DownloadFilenameConfig>;
  DownloadFilenameConfigInput: DownloadFilenameConfigInput;
  Drawing: ResolverTypeWrapper<Drawing>;
  EmailActions: EmailActions;
  EntityFolderPermission: ResolverTypeWrapper<EntityFolderPermission>;
  EntityWithFolderPermissions: EntityWithFolderPermissions;
  FacetGroup: ResolverTypeWrapper<FacetGroup>;
  FacetItem: ResolverTypeWrapper<FacetItem>;
  FacetItemMDField: ResolverTypeWrapper<FacetItemMdField>;
  Field: Field;
  FieldDefinition: ResolverTypeWrapper<FieldDefinition>;
  FieldDefinitionInput: FieldDefinitionInput;
  FieldDefinitionType: ResolverTypeWrapper<FieldDefinitionType>;
  FieldDefinitionTypeInput: FieldDefinitionTypeInput;
  File: File;
  FileInformation: ResolverTypeWrapper<FileInformation>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FilesExistsBuild12: ResolverTypeWrapper<FilesExistsBuild12>;
  FilesExistsSnagstream: ResolverTypeWrapper<FilesExistsSnagstream>;
  Filter: ResolverTypeWrapper<Filter>;
  FilterType: FilterType;
  FinalizationState: FinalizationState;
  Folder: ResolverTypeWrapper<Folder>;
  FolderChangeAction: FolderChangeAction;
  FolderChangeResponse: ResolverTypeWrapper<FolderChangeResponse>;
  FolderDuplicationResponse: ResolverTypeWrapper<FolderDuplicationResponse>;
  FolderPermission: FolderPermission;
  FolderPermissionsChangeResponse: ResolverTypeWrapper<FolderPermissionsChangeResponse>;
  FolderWithPermissions: FolderWithPermissions;
  FoldersChangeResponse: ResolverTypeWrapper<FoldersChangeResponse>;
  FormLinkedSnag: ResolverTypeWrapper<FormLinkedSnag>;
  GetSignature: ResolverTypeWrapper<GetSignature>;
  GetSignatureData: ResolverTypeWrapper<GetSignatureData>;
  Group: ResolverTypeWrapper<Omit<Group, 'members'> & { members?: Maybe<Array<Maybe<ResolversTypes['Member']>>> }>;
  GroupPermission: ResolverTypeWrapper<GroupPermission>;
  HierarchicalList: ResolverTypeWrapper<HierarchicalList>;
  Hierarchy: ResolverTypeWrapper<Hierarchy>;
  HierarchyDummy: ResolverTypeWrapper<HierarchyDummy>;
  ImageType: ImageType;
  InheritedPermission: ResolverTypeWrapper<InheritedPermission>;
  Instance: ResolverTypeWrapper<Instance>;
  InstanceForDocumentInput: InstanceForDocumentInput;
  InstanceForQcInput: InstanceForQcInput;
  InstanceResourceType: InstanceResourceType;
  Integration: ResolverTypeWrapper<Integration>;
  IntegrationProject: ResolverTypeWrapper<IntegrationProject>;
  IntegrationType: IntegrationType;
  IntegrationWithConnection: ResolverTypeWrapper<IntegrationWithConnection>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JobInput: JobInput;
  JobOutput: ResolverTypeWrapper<JobOutput>;
  Keyword: ResolverTypeWrapper<Keyword>;
  LinkDeviceInput: LinkDeviceInput;
  LinkInfo: ResolverTypeWrapper<LinkInfo>;
  LinkResponse: ResolverTypeWrapper<LinkResponse>;
  LinkType: LinkType;
  LinkUnlinkDeviceResponse: ResolverTypeWrapper<LinkUnlinkDeviceResponse>;
  LockType: LockType;
  LoginMethodOption: LoginMethodOption;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  MailStyle: MailStyle;
  MarkMessageInput: MarkMessageInput;
  MarkMessageResponse: ResolverTypeWrapper<MarkMessageResponse>;
  Me: ResolverTypeWrapper<Me>;
  MeOrganization: ResolverTypeWrapper<MeOrganization>;
  MeProject: ResolverTypeWrapper<MeProject>;
  MeUser: ResolverTypeWrapper<MeUser>;
  Media: ResolverTypeWrapper<Media>;
  Member: ResolversTypes['Group'] | ResolversTypes['User'];
  MemberInput: MemberInput;
  Message: ResolverTypeWrapper<Message>;
  MessageBranch: ResolverTypeWrapper<MessageBranch>;
  MessageType: MessageType;
  MetaData: ResolverTypeWrapper<MetaData>;
  MetaDataInstance: ResolverTypeWrapper<MetaDataInstance>;
  MetaDataOption: ResolverTypeWrapper<MetaDataOption>;
  MetaDataOptionInput: MetaDataOptionInput;
  MoveDocumentData: MoveDocumentData;
  MoveUserModel: ResolverTypeWrapper<MoveUserModel>;
  MutateAddCommentResponse: ResolverTypeWrapper<MutateAddCommentResponse>;
  MutateDeleteCommentResponse: ResolverTypeWrapper<MutateDeleteCommentResponse>;
  MutateFolderResponse: ResolverTypeWrapper<MutateFolderResponse>;
  MutateGroupResponse: ResolverTypeWrapper<MutateGroupResponse>;
  MutateMetaDataResponse: ResolverTypeWrapper<MutateMetaDataResponse>;
  MutateQualityControlInstanceResponse: ResolverTypeWrapper<MutateQualityControlInstanceResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  Node: Node;
  NotificationScope: NotificationScope;
  NotificationSettings: ResolverTypeWrapper<NotificationSettings>;
  NotificationUpdateUser: NotificationUpdateUser;
  Notifications: ResolverTypeWrapper<Notifications>;
  NotificationsUser: ResolverTypeWrapper<NotificationsUser>;
  NumberProps: ResolverTypeWrapper<NumberProps>;
  ObjectInput: ObjectInput;
  ObjectNode: ResolverTypeWrapper<ObjectNode>;
  ObjectProjectStatus: ObjectProjectStatus;
  ObjectsResponse: ResolverTypeWrapper<ObjectsResponse>;
  OfficeOnlineInfo: ResolverTypeWrapper<OfficeOnlineInfo>;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationSettings: ResolverTypeWrapper<OrganizationSettings>;
  OrganizationSettingsInput: OrganizationSettingsInput;
  OrganizationSubscriptionLevel: OrganizationSubscriptionLevel;
  OsidDeviceLinkStateResponse: ResolverTypeWrapper<OsidDeviceLinkStateResponse>;
  OsidDeviceLog: ResolverTypeWrapper<OsidDeviceLog>;
  OsidDevicesResponse: ResolverTypeWrapper<OsidDevicesResponse>;
  OsidDevicesType: ResolverTypeWrapper<OsidDevicesType>;
  Page: ResolverTypeWrapper<Page>;
  ParentFolder: ResolverTypeWrapper<ParentFolder>;
  ParentFolderPermission: ResolverTypeWrapper<ParentFolderPermission>;
  PasteDocumentsDictionary: PasteDocumentsDictionary;
  PaymentInfo: ResolverTypeWrapper<PaymentInfo>;
  Permission: ResolverTypeWrapper<Permission>;
  PhaseBuild12: ResolverTypeWrapper<PhaseBuild12>;
  Position: ResolverTypeWrapper<Position>;
  Procedure: ResolverTypeWrapper<Procedure>;
  Project: ResolverTypeWrapper<Omit<Project, 'members'> & { members?: Maybe<Array<Maybe<ResolversTypes['Member']>>> }>;
  ProjectByFolderResponse: ResolverTypeWrapper<ProjectByFolderResponse>;
  ProjectInfo: ProjectInfo;
  ProjectLinkResponse: ResolverTypeWrapper<ProjectLinkResponse>;
  ProjectRoles: ResolverTypeWrapper<ProjectRoles>;
  ProjectSettings: ResolverTypeWrapper<ProjectSettings>;
  ProjectSettingsInput: ProjectSettingsInput;
  ProjectUnread: ResolverTypeWrapper<ProjectUnread>;
  QCInstanceEventType: QcInstanceEventType;
  QCInstanceHistory: ResolverTypeWrapper<QcInstanceHistory>;
  QCSearchTask: ResolverTypeWrapper<QcSearchTask>;
  QCTemplate: ResolverTypeWrapper<QcTemplate>;
  QcHistoryEvent: ResolverTypeWrapper<QcHistoryEvent>;
  QcHistoryProperty: ResolverTypeWrapper<QcHistoryProperty>;
  QcPropertyType: QcPropertyType;
  QualityControlInstance: ResolverTypeWrapper<QualityControlInstance>;
  QualityControlInstanceType: QualityControlInstanceType;
  QualityControlTaskInstance: ResolverTypeWrapper<QualityControlTaskInstance>;
  Query: ResolverTypeWrapper<{}>;
  QuestionInput: QuestionInput;
  Reference: ResolverTypeWrapper<Reference>;
  ReferenceInput: ReferenceInput;
  ReferenceKind: ReferenceKind;
  ReferenceType: ReferenceType;
  RemoveStampInput: RemoveStampInput;
  RemoveStampsResponse: ResolverTypeWrapper<RemoveStampsResponse>;
  ReportConfiguration: ResolverTypeWrapper<ReportConfiguration>;
  ReportImage: ResolverTypeWrapper<ReportImage>;
  RequiredMetaDataFieldOption: RequiredMetaDataFieldOption;
  ResolveTaskLinks: ResolverTypeWrapper<ResolveTaskLinks>;
  Resource: Resource;
  ResourcePermissions: ResolverTypeWrapper<ResourcePermissions>;
  ResourceType: ResourceType;
  Response: ResolversTypes['AddExternalUsersResponse'] | ResolversTypes['BatchDeleteTaskCategoryResponse'] | ResolversTypes['BatchDocumentsResponse'] | ResolversTypes['BatchProjectsResponse'] | ResolversTypes['BatchTaskCategoryResponse'] | ResolversTypes['BatchUpdateTaskCategoryResponse'] | ResolversTypes['CreateShareLinkResponse'] | ResolversTypes['DocumentResponse'] | ResolversTypes['DocumentsResponse'] | ResolversTypes['LinkResponse'] | ResolversTypes['LinkUnlinkDeviceResponse'] | ResolversTypes['MarkMessageResponse'] | ResolversTypes['MutateAddCommentResponse'] | ResolversTypes['MutateDeleteCommentResponse'] | ResolversTypes['ObjectsResponse'] | ResolversTypes['ProjectLinkResponse'] | ResolversTypes['RemoveStampsResponse'] | ResolversTypes['RevokeLinkResponse'] | ResolversTypes['SavedSearchResponse'] | ResolversTypes['SearchResponse'] | ResolversTypes['SendShareLinkResponse'] | ResolversTypes['SetTBQResponse'] | ResolversTypes['SharedAttachmentDataResponse'] | ResolversTypes['SharedLinkDataResponse'] | ResolversTypes['StampDocumentResponse'] | ResolversTypes['StampsResponse'] | ResolversTypes['VisualContextMutationResponse'];
  RestoreInstanceData: ResolverTypeWrapper<RestoreInstanceData>;
  RestoreInstanceResponse: ResolverTypeWrapper<RestoreInstanceResponse>;
  ResultSet: ResultSet;
  RevokeLinkResponse: ResolverTypeWrapper<RevokeLinkResponse>;
  RieCondition: ResolverTypeWrapper<RieCondition>;
  RieOption: ResolverTypeWrapper<RieOption>;
  RieResult: ResolverTypeWrapper<RieResult>;
  RieSection: ResolverTypeWrapper<RieSection>;
  RieSubField: ResolverTypeWrapper<RieSubField>;
  RieValueType: ResolverTypeWrapper<RieValueType>;
  RieValueTypeSection: ResolverTypeWrapper<RieValueTypeSection>;
  Role: ResolverTypeWrapper<Role>;
  SavedAnswers: ResolverTypeWrapper<SavedAnswers>;
  SavedAnswersInstance: ResolverTypeWrapper<SavedAnswersInstance>;
  SavedSearch: ResolverTypeWrapper<SavedSearch>;
  SavedSearchResponse: ResolverTypeWrapper<SavedSearchResponse>;
  ScanType: ScanType;
  ScheduledTime: ResolverTypeWrapper<ScheduledTime>;
  ScheduledTimeInput: ScheduledTimeInput;
  Scope: ResolverTypeWrapper<Scope>;
  ScopeType: ScopeType;
  SearchDocument: ResolverTypeWrapper<SearchDocument>;
  SearchDocumentMetaQuestion: ResolverTypeWrapper<SearchDocumentMetaQuestion>;
  SearchProject: ResolverTypeWrapper<SearchProject>;
  SearchQCInstance: ResolverTypeWrapper<SearchQcInstance>;
  SearchResponse: ResolverTypeWrapper<Omit<SearchResponse, 'searchResults'> & { searchResults?: Maybe<Array<ResolversTypes['SearchResult']>> }>;
  SearchResult: ResolversTypes['SearchDocument'] | ResolversTypes['SearchQCInstance'];
  SearchUser: ResolverTypeWrapper<SearchUser>;
  SegmentType: SegmentType;
  SelectedGroup: SelectedGroup;
  SendShareLinkResponse: ResolverTypeWrapper<SendShareLinkResponse>;
  SentOrInbox: SentOrInbox;
  SetProjectPermissionsResponse: ResolverTypeWrapper<SetProjectPermissionsResponse>;
  SetTBQResponse: ResolverTypeWrapper<SetTbqResponse>;
  ShareType: ShareType;
  SharedAttachmentDataResponse: ResolverTypeWrapper<SharedAttachmentDataResponse>;
  SharedAttachments: ResolverTypeWrapper<SharedAttachments>;
  SharedLinkDataResponse: ResolverTypeWrapper<SharedLinkDataResponse>;
  SmartFolder: ResolverTypeWrapper<SmartFolder>;
  SnagstreamConnection: ResolverTypeWrapper<SnagstreamConnection>;
  SnagstreamState: SnagstreamState;
  Stamp: ResolverTypeWrapper<Stamp>;
  StampDocumentResponse: ResolverTypeWrapper<StampDocumentResponse>;
  StampParams: StampParams;
  StampState: StampState;
  StampStatus: StampStatus;
  StampType: StampType;
  StampsResponse: ResolverTypeWrapper<StampsResponse>;
  StandardDateRange: StandardDateRange;
  StandardItemKey: StandardItemKey;
  State: ResolverTypeWrapper<State>;
  StateBuild12: ResolverTypeWrapper<StateBuild12>;
  StateSnagstream: ResolverTypeWrapper<StateSnagstream>;
  Status: Status;
  SubFolderPermission: ResolverTypeWrapper<SubFolderPermission>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionLevel: ResolverTypeWrapper<SubscriptionLevel>;
  SubscriptionLevelsDataResponse: ResolverTypeWrapper<SubscriptionLevelsDataResponse>;
  SubscriptionOrganization: ResolverTypeWrapper<SubscriptionOrganization>;
  SubscriptionOrganizationDataResponse: ResolverTypeWrapper<SubscriptionOrganizationDataResponse>;
  SubscriptionOrganizationExpireDataResponse: ResolverTypeWrapper<SubscriptionOrganizationExpireDataResponse>;
  SubscriptionOrganizationUsersDataResponse: ResolverTypeWrapper<SubscriptionOrganizationUsersDataResponse>;
  SuggestedAnswersBasedOnImageResponse: ResolverTypeWrapper<SuggestedAnswersBasedOnImageResponse>;
  SuggestedTemplatesBasedOnImageResponse: ResolverTypeWrapper<SuggestedTemplatesBasedOnImageResponse>;
  SupportSettings: ResolverTypeWrapper<SupportSettings>;
  TBQ: ResolverTypeWrapper<Tbq>;
  TBQAnswer: ResolverTypeWrapper<TbqAnswer>;
  TBQBrandcheckMultiResult: ResolverTypeWrapper<TbqBrandcheckMultiResult>;
  TBQCategory: ResolverTypeWrapper<TbqCategory>;
  TBQConfig: ResolverTypeWrapper<TbqConfig>;
  TBQFieldOption: ResolverTypeWrapper<TbqFieldOption>;
  TBQFieldValue: ResolverTypeWrapper<TbqFieldValue>;
  TBQFunction: ResolverTypeWrapper<TbqFunction>;
  TBQGlobalScope: ResolverTypeWrapper<TbqGlobalScope>;
  TBQGoal: ResolverTypeWrapper<TbqGoal>;
  TBQModuleType: TbqModuleType;
  TBQObjectConfig: ResolverTypeWrapper<TbqObjectConfig>;
  TBQOption: ResolverTypeWrapper<TbqOption>;
  TBQResult: ResolverTypeWrapper<TbqResult>;
  TBQRieResult: ResolverTypeWrapper<TbqRieResult>;
  TBQRieScanType: ResolverTypeWrapper<TbqRieScanType>;
  TBQScanType: ResolverTypeWrapper<TbqScanType>;
  TBQSetting: TbqSetting;
  TBQTask: TbqTask;
  TBQType: ResolverTypeWrapper<TbqType>;
  TBQTypeAbstract: TbqTypeAbstract;
  TBQValue: ResolverTypeWrapper<TbqValue>;
  Tag: ResolverTypeWrapper<Tag>;
  TagInput: TagInput;
  Task: ResolverTypeWrapper<Task>;
  TaskCategory: ResolverTypeWrapper<TaskCategory>;
  TaskCategoryInput: TaskCategoryInput;
  TaskLink: ResolverTypeWrapper<TaskLink>;
  TaskStatus: ResolverTypeWrapper<TaskStatus>;
  TaskStatusOrder: ResolverTypeWrapper<TaskStatusOrder>;
  TaskType: TaskType;
  Template: ResolverTypeWrapper<Template>;
  TemplateIcon: ResolverTypeWrapper<TemplateIcon>;
  TemplateIconProps: ResolverTypeWrapper<TemplateIconProps>;
  TemplateService: TemplateService;
  TemplateState: TemplateState;
  TemplateType: TemplateType;
  ThreeDConversionStatus: ResolverTypeWrapper<ThreeDConversionStatus>;
  TimeSpan: ResolverTypeWrapper<TimeSpan>;
  TimeSpanInput: TimeSpanInput;
  Token: ResolverTypeWrapper<Token>;
  Type: Type;
  UInt: ResolverTypeWrapper<Scalars['UInt']>;
  UnlinkLink: ResolverTypeWrapper<UnlinkLink>;
  UnreadMessages: ResolverTypeWrapper<UnreadMessages>;
  UploadFileOption: UploadFileOption;
  Uri: ResolverTypeWrapper<Scalars['Uri']>;
  User: ResolverTypeWrapper<User>;
  UserQCPermissions: ResolverTypeWrapper<UserQcPermissions>;
  UserState: UserState;
  ValueType: ResolverTypeWrapper<ValueType>;
  ValueTypeName: ValueTypeName;
  VersionMetaDataCreatedResponse: ResolverTypeWrapper<VersionMetaDataCreatedResponse>;
  VisualContext: ResolverTypeWrapper<VisualContext>;
  VisualContextInput: VisualContextInput;
  VisualContextInstance: ResolverTypeWrapper<VisualContextInstance>;
  VisualContextMutationResponse: ResolverTypeWrapper<VisualContextMutationResponse>;
  VisualContextStatus: VisualContextStatus;
  WeekDay: WeekDay;
  qcPermissionCategory: QcPermissionCategory;
  updateInstance: ResolverTypeWrapper<UpdateInstance>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddExternalUsersResponse: AddExternalUsersResponse;
  ID: Scalars['ID'];
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  AddSignatureResponse: AddSignatureResponse;
  AiSummary: AiSummary;
  Annotation: Annotation;
  Answer: Answer;
  AnswerType: AnswerType;
  Int: Scalars['Int'];
  ApiKey: ApiKey;
  AppData: AppData;
  AppDataInput: AppDataInput;
  ApplicationInput: ApplicationInput;
  Attachment: Attachment;
  AttachmentInput: AttachmentInput;
  BatchDeleteTaskCategoryResponse: BatchDeleteTaskCategoryResponse;
  BatchDocumentsResponse: BatchDocumentsResponse;
  BatchMutation: BatchMutation;
  BatchProjectsResponse: BatchProjectsResponse;
  BatchResult: BatchResult;
  BatchTaskCategoryResponse: BatchTaskCategoryResponse;
  BatchUpdateTaskCategoryResponse: BatchUpdateTaskCategoryResponse;
  Build12Connection: Build12Connection;
  CancelSubscriptionDataResponse: CancelSubscriptionDataResponse;
  ChangeSubscriptionDataResponse: ChangeSubscriptionDataResponse;
  ClientDownloadUrls: ClientDownloadUrls;
  CommandOutput: CommandOutput;
  CommandStatus: CommandStatus;
  CommentThread: CommentThread;
  Condition: Condition;
  ConfigDataSegment: ConfigDataSegment;
  ConfigDataSegmentInput: ConfigDataSegmentInput;
  ConnectProjectResponse: ConnectProjectResponse;
  Connection: Connection;
  Country: Country;
  CountryDataResponse: CountryDataResponse;
  CreateCommandInput: CreateCommandInput;
  CreateShareLinkResponse: CreateShareLinkResponse;
  CustomConnectionProps: CustomConnectionProps;
  CustomIntegrationProps: CustomIntegrationProps;
  DateTime: Scalars['DateTime'];
  DeleteInstanceResponse: DeleteInstanceResponse;
  Document: Document;
  DocumentCreate: DocumentCreate;
  DocumentDigest: DocumentDigest;
  DocumentDigestUser: DocumentDigestUser;
  DocumentHistory: DocumentHistory;
  DocumentHistoryEvent: DocumentHistoryEvent;
  DocumentHistoryProperty: DocumentHistoryProperty;
  DocumentIdVersionId: DocumentIdVersionId;
  DocumentKeyword: DocumentKeyword;
  DocumentMetaData: DocumentMetaData;
  DocumentResponse: DocumentResponse;
  DocumentUpdate: DocumentUpdate;
  DocumentVersion: DocumentVersion;
  DocumentVersionMetadataMapping: DocumentVersionMetadataMapping;
  DocumentsResponse: DocumentsResponse;
  DownloadFilenameCharacter: DownloadFilenameCharacter;
  DownloadFilenameConfig: DownloadFilenameConfig;
  DownloadFilenameConfigInput: DownloadFilenameConfigInput;
  Drawing: Drawing;
  EntityFolderPermission: EntityFolderPermission;
  EntityWithFolderPermissions: EntityWithFolderPermissions;
  FacetGroup: FacetGroup;
  FacetItem: FacetItem;
  FacetItemMDField: FacetItemMdField;
  Field: Field;
  FieldDefinition: FieldDefinition;
  FieldDefinitionInput: FieldDefinitionInput;
  FieldDefinitionType: FieldDefinitionType;
  FieldDefinitionTypeInput: FieldDefinitionTypeInput;
  File: File;
  FileInformation: FileInformation;
  Float: Scalars['Float'];
  FilesExistsBuild12: FilesExistsBuild12;
  FilesExistsSnagstream: FilesExistsSnagstream;
  Filter: Filter;
  Folder: Folder;
  FolderChangeResponse: FolderChangeResponse;
  FolderDuplicationResponse: FolderDuplicationResponse;
  FolderPermissionsChangeResponse: FolderPermissionsChangeResponse;
  FolderWithPermissions: FolderWithPermissions;
  FoldersChangeResponse: FoldersChangeResponse;
  FormLinkedSnag: FormLinkedSnag;
  GetSignature: GetSignature;
  GetSignatureData: GetSignatureData;
  Group: Omit<Group, 'members'> & { members?: Maybe<Array<Maybe<ResolversParentTypes['Member']>>> };
  GroupPermission: GroupPermission;
  HierarchicalList: HierarchicalList;
  Hierarchy: Hierarchy;
  HierarchyDummy: HierarchyDummy;
  InheritedPermission: InheritedPermission;
  Instance: Instance;
  InstanceForDocumentInput: InstanceForDocumentInput;
  InstanceForQcInput: InstanceForQcInput;
  Integration: Integration;
  IntegrationProject: IntegrationProject;
  IntegrationWithConnection: IntegrationWithConnection;
  JSON: Scalars['JSON'];
  JobInput: JobInput;
  JobOutput: JobOutput;
  Keyword: Keyword;
  LinkDeviceInput: LinkDeviceInput;
  LinkInfo: LinkInfo;
  LinkResponse: LinkResponse;
  LinkUnlinkDeviceResponse: LinkUnlinkDeviceResponse;
  Long: Scalars['Long'];
  MarkMessageInput: MarkMessageInput;
  MarkMessageResponse: MarkMessageResponse;
  Me: Me;
  MeOrganization: MeOrganization;
  MeProject: MeProject;
  MeUser: MeUser;
  Media: Media;
  Member: ResolversParentTypes['Group'] | ResolversParentTypes['User'];
  MemberInput: MemberInput;
  Message: Message;
  MessageBranch: MessageBranch;
  MetaData: MetaData;
  MetaDataInstance: MetaDataInstance;
  MetaDataOption: MetaDataOption;
  MetaDataOptionInput: MetaDataOptionInput;
  MoveDocumentData: MoveDocumentData;
  MoveUserModel: MoveUserModel;
  MutateAddCommentResponse: MutateAddCommentResponse;
  MutateDeleteCommentResponse: MutateDeleteCommentResponse;
  MutateFolderResponse: MutateFolderResponse;
  MutateGroupResponse: MutateGroupResponse;
  MutateMetaDataResponse: MutateMetaDataResponse;
  MutateQualityControlInstanceResponse: MutateQualityControlInstanceResponse;
  Mutation: {};
  Node: Node;
  NotificationSettings: NotificationSettings;
  NotificationUpdateUser: NotificationUpdateUser;
  Notifications: Notifications;
  NotificationsUser: NotificationsUser;
  NumberProps: NumberProps;
  ObjectInput: ObjectInput;
  ObjectNode: ObjectNode;
  ObjectsResponse: ObjectsResponse;
  OfficeOnlineInfo: OfficeOnlineInfo;
  Organization: Organization;
  OrganizationSettings: OrganizationSettings;
  OrganizationSettingsInput: OrganizationSettingsInput;
  OsidDeviceLinkStateResponse: OsidDeviceLinkStateResponse;
  OsidDeviceLog: OsidDeviceLog;
  OsidDevicesResponse: OsidDevicesResponse;
  OsidDevicesType: OsidDevicesType;
  Page: Page;
  ParentFolder: ParentFolder;
  ParentFolderPermission: ParentFolderPermission;
  PasteDocumentsDictionary: PasteDocumentsDictionary;
  PaymentInfo: PaymentInfo;
  Permission: Permission;
  PhaseBuild12: PhaseBuild12;
  Position: Position;
  Procedure: Procedure;
  Project: Omit<Project, 'members'> & { members?: Maybe<Array<Maybe<ResolversParentTypes['Member']>>> };
  ProjectByFolderResponse: ProjectByFolderResponse;
  ProjectInfo: ProjectInfo;
  ProjectLinkResponse: ProjectLinkResponse;
  ProjectRoles: ProjectRoles;
  ProjectSettings: ProjectSettings;
  ProjectSettingsInput: ProjectSettingsInput;
  ProjectUnread: ProjectUnread;
  QCInstanceHistory: QcInstanceHistory;
  QCSearchTask: QcSearchTask;
  QCTemplate: QcTemplate;
  QcHistoryEvent: QcHistoryEvent;
  QcHistoryProperty: QcHistoryProperty;
  QualityControlInstance: QualityControlInstance;
  QualityControlTaskInstance: QualityControlTaskInstance;
  Query: {};
  QuestionInput: QuestionInput;
  Reference: Reference;
  ReferenceInput: ReferenceInput;
  RemoveStampInput: RemoveStampInput;
  RemoveStampsResponse: RemoveStampsResponse;
  ReportConfiguration: ReportConfiguration;
  ReportImage: ReportImage;
  ResolveTaskLinks: ResolveTaskLinks;
  Resource: Resource;
  ResourcePermissions: ResourcePermissions;
  Response: ResolversParentTypes['AddExternalUsersResponse'] | ResolversParentTypes['BatchDeleteTaskCategoryResponse'] | ResolversParentTypes['BatchDocumentsResponse'] | ResolversParentTypes['BatchProjectsResponse'] | ResolversParentTypes['BatchTaskCategoryResponse'] | ResolversParentTypes['BatchUpdateTaskCategoryResponse'] | ResolversParentTypes['CreateShareLinkResponse'] | ResolversParentTypes['DocumentResponse'] | ResolversParentTypes['DocumentsResponse'] | ResolversParentTypes['LinkResponse'] | ResolversParentTypes['LinkUnlinkDeviceResponse'] | ResolversParentTypes['MarkMessageResponse'] | ResolversParentTypes['MutateAddCommentResponse'] | ResolversParentTypes['MutateDeleteCommentResponse'] | ResolversParentTypes['ObjectsResponse'] | ResolversParentTypes['ProjectLinkResponse'] | ResolversParentTypes['RemoveStampsResponse'] | ResolversParentTypes['RevokeLinkResponse'] | ResolversParentTypes['SavedSearchResponse'] | ResolversParentTypes['SearchResponse'] | ResolversParentTypes['SendShareLinkResponse'] | ResolversParentTypes['SetTBQResponse'] | ResolversParentTypes['SharedAttachmentDataResponse'] | ResolversParentTypes['SharedLinkDataResponse'] | ResolversParentTypes['StampDocumentResponse'] | ResolversParentTypes['StampsResponse'] | ResolversParentTypes['VisualContextMutationResponse'];
  RestoreInstanceData: RestoreInstanceData;
  RestoreInstanceResponse: RestoreInstanceResponse;
  RevokeLinkResponse: RevokeLinkResponse;
  RieCondition: RieCondition;
  RieOption: RieOption;
  RieResult: RieResult;
  RieSection: RieSection;
  RieSubField: RieSubField;
  RieValueType: RieValueType;
  RieValueTypeSection: RieValueTypeSection;
  Role: Role;
  SavedAnswers: SavedAnswers;
  SavedAnswersInstance: SavedAnswersInstance;
  SavedSearch: SavedSearch;
  SavedSearchResponse: SavedSearchResponse;
  ScheduledTime: ScheduledTime;
  ScheduledTimeInput: ScheduledTimeInput;
  Scope: Scope;
  SearchDocument: SearchDocument;
  SearchDocumentMetaQuestion: SearchDocumentMetaQuestion;
  SearchProject: SearchProject;
  SearchQCInstance: SearchQcInstance;
  SearchResponse: Omit<SearchResponse, 'searchResults'> & { searchResults?: Maybe<Array<ResolversParentTypes['SearchResult']>> };
  SearchResult: ResolversParentTypes['SearchDocument'] | ResolversParentTypes['SearchQCInstance'];
  SearchUser: SearchUser;
  SelectedGroup: SelectedGroup;
  SendShareLinkResponse: SendShareLinkResponse;
  SetProjectPermissionsResponse: SetProjectPermissionsResponse;
  SetTBQResponse: SetTbqResponse;
  SharedAttachmentDataResponse: SharedAttachmentDataResponse;
  SharedAttachments: SharedAttachments;
  SharedLinkDataResponse: SharedLinkDataResponse;
  SmartFolder: SmartFolder;
  SnagstreamConnection: SnagstreamConnection;
  Stamp: Stamp;
  StampDocumentResponse: StampDocumentResponse;
  StampParams: StampParams;
  StampsResponse: StampsResponse;
  State: State;
  StateBuild12: StateBuild12;
  StateSnagstream: StateSnagstream;
  SubFolderPermission: SubFolderPermission;
  Subscription: {};
  SubscriptionLevel: SubscriptionLevel;
  SubscriptionLevelsDataResponse: SubscriptionLevelsDataResponse;
  SubscriptionOrganization: SubscriptionOrganization;
  SubscriptionOrganizationDataResponse: SubscriptionOrganizationDataResponse;
  SubscriptionOrganizationExpireDataResponse: SubscriptionOrganizationExpireDataResponse;
  SubscriptionOrganizationUsersDataResponse: SubscriptionOrganizationUsersDataResponse;
  SuggestedAnswersBasedOnImageResponse: SuggestedAnswersBasedOnImageResponse;
  SuggestedTemplatesBasedOnImageResponse: SuggestedTemplatesBasedOnImageResponse;
  SupportSettings: SupportSettings;
  TBQ: Tbq;
  TBQAnswer: TbqAnswer;
  TBQBrandcheckMultiResult: TbqBrandcheckMultiResult;
  TBQCategory: TbqCategory;
  TBQConfig: TbqConfig;
  TBQFieldOption: TbqFieldOption;
  TBQFieldValue: TbqFieldValue;
  TBQFunction: TbqFunction;
  TBQGlobalScope: TbqGlobalScope;
  TBQGoal: TbqGoal;
  TBQObjectConfig: TbqObjectConfig;
  TBQOption: TbqOption;
  TBQResult: TbqResult;
  TBQRieResult: TbqRieResult;
  TBQRieScanType: TbqRieScanType;
  TBQScanType: TbqScanType;
  TBQSetting: TbqSetting;
  TBQTask: TbqTask;
  TBQType: TbqType;
  TBQValue: TbqValue;
  Tag: Tag;
  TagInput: TagInput;
  Task: Task;
  TaskCategory: TaskCategory;
  TaskCategoryInput: TaskCategoryInput;
  TaskLink: TaskLink;
  TaskStatus: TaskStatus;
  TaskStatusOrder: TaskStatusOrder;
  Template: Template;
  TemplateIcon: TemplateIcon;
  TemplateIconProps: TemplateIconProps;
  ThreeDConversionStatus: ThreeDConversionStatus;
  TimeSpan: TimeSpan;
  TimeSpanInput: TimeSpanInput;
  Token: Token;
  UInt: Scalars['UInt'];
  UnlinkLink: UnlinkLink;
  UnreadMessages: UnreadMessages;
  Uri: Scalars['Uri'];
  User: User;
  UserQCPermissions: UserQcPermissions;
  ValueType: ValueType;
  VersionMetaDataCreatedResponse: VersionMetaDataCreatedResponse;
  VisualContext: VisualContext;
  VisualContextInput: VisualContextInput;
  VisualContextInstance: VisualContextInstance;
  VisualContextMutationResponse: VisualContextMutationResponse;
  updateInstance: UpdateInstance;
};

export type AddExternalUsersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddExternalUsersResponse'] = ResolversParentTypes['AddExternalUsersResponse']> = {
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddSignatureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddSignatureResponse'] = ResolversParentTypes['AddSignatureResponse']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AiSummary'] = ResolversParentTypes['AiSummary']> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Annotation'] = ResolversParentTypes['Annotation']> = {
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnswerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnswerType'] = ResolversParentTypes['AnswerType']> = {
  fieldId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  templateVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiKey'] = ResolversParentTypes['ApiKey']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppData'] = ResolversParentTypes['AppData']> = {
  fieldId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  fileId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileSize?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  documentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentVersionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchDeleteTaskCategoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchDeleteTaskCategoryResponse'] = ResolversParentTypes['BatchDeleteTaskCategoryResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchDocumentsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchDocumentsResponse'] = ResolversParentTypes['BatchDocumentsResponse']> = {
  succeededIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  failedIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchMutation'] = ResolversParentTypes['BatchMutation']> = {
  successful?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  unsuccessful?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchProjectsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchProjectsResponse'] = ResolversParentTypes['BatchProjectsResponse']> = {
  succeededIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  failedIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchResult'] = ResolversParentTypes['BatchResult']> = {
  successful?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  unsuccessful?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchTaskCategoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchTaskCategoryResponse'] = ResolversParentTypes['BatchTaskCategoryResponse']> = {
  ids?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchUpdateTaskCategoryResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BatchUpdateTaskCategoryResponse'] = ResolversParentTypes['BatchUpdateTaskCategoryResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Build12ConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Build12Connection'] = ResolversParentTypes['Build12Connection']> = {
  connectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phaseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelSubscriptionDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelSubscriptionDataResponse'] = ResolversParentTypes['CancelSubscriptionDataResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangeSubscriptionDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSubscriptionDataResponse'] = ResolversParentTypes['ChangeSubscriptionDataResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  mollieRedirect?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientDownloadUrlsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientDownloadUrls'] = ResolversParentTypes['ClientDownloadUrls']> = {
  downloadUrlMac?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downloadUrlWindows?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommandOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommandOutput'] = ResolversParentTypes['CommandOutput']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  commandId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  issuedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  issuedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobs?: Resolver<Array<ResolversTypes['JobOutput']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommandStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommandStatus'] = ResolversParentTypes['CommandStatus']> = {
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentThreadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommentThread'] = ResolversParentTypes['CommentThread']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  messages?: Resolver<Maybe<Array<ResolversTypes['Message']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Condition'] = ResolversParentTypes['Condition']> = {
  fieldId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfigDataSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfigDataSegment'] = ResolversParentTypes['ConfigDataSegment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SegmentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectProjectResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectProjectResponse'] = ResolversParentTypes['ConnectProjectResponse']> = {
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  connectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Connection'] = ResolversParentTypes['Connection']> = {
  connectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['IntegrationType'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryDataResponse'] = ResolversParentTypes['CountryDataResponse']> = {
  countries?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateShareLinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateShareLinkResponse'] = ResolversParentTypes['CreateShareLinkResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteInstanceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteInstanceResponse'] = ResolversParentTypes['DeleteInstanceResponse']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  instanceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  amountOfVersions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  downloadName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expectedExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  folder?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType>;
  folderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  folderPermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FolderPermission']>>>, ParentType, ContextType>;
  hasPreviousVersionQr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isExpected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  keywords?: Resolver<Maybe<Array<Maybe<ResolversTypes['Keyword']>>>, ParentType, ContextType>;
  lastUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lockType?: Resolver<Maybe<ResolversTypes['LockType']>, ParentType, ContextType>;
  lockedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lockedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  metaData?: Resolver<Maybe<ResolversTypes['DocumentMetaData']>, ParentType, ContextType>;
  metadataInstanceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameOriginal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newDocumentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  newVersionFor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pathIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  qrCodeState?: Resolver<Maybe<ResolversTypes['StampStatus']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['FinalizationState']>, ParentType, ContextType>;
  stateBuild12?: Resolver<Maybe<ResolversTypes['StateBuild12']>, ParentType, ContextType>;
  stateSnagstream?: Resolver<Maybe<ResolversTypes['StateSnagstream']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['AiSummary']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tag']>>>, ParentType, ContextType>;
  taskLinks?: Resolver<Maybe<Array<ResolversTypes['TaskLink']>>, ParentType, ContextType>;
  threeDConversionState?: Resolver<Maybe<ResolversTypes['ThreeDConversionStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  versionState?: Resolver<Maybe<ResolversTypes['DocumentVersionState']>, ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentDigestResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentDigest'] = ResolversParentTypes['DocumentDigest']> = {
  documentDigestId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentHistory'] = ResolversParentTypes['DocumentHistory']> = {
  today?: Resolver<Maybe<Array<ResolversTypes['DocumentHistoryEvent']>>, ParentType, ContextType>;
  thisWeek?: Resolver<Maybe<Array<ResolversTypes['DocumentHistoryEvent']>>, ParentType, ContextType>;
  thisMonth?: Resolver<Maybe<Array<ResolversTypes['DocumentHistoryEvent']>>, ParentType, ContextType>;
  older?: Resolver<Maybe<Array<ResolversTypes['DocumentHistoryEvent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentHistoryEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentHistoryEvent'] = ResolversParentTypes['DocumentHistoryEvent']> = {
  changeDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  commandId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  historyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['DocumentEventType']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<ResolversTypes['DocumentHistoryProperty']>>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentHistoryPropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentHistoryProperty'] = ResolversParentTypes['DocumentHistoryProperty']> = {
  addedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  addedResolvedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  changeDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  deletedResolvedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  historyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['DocumentPropertyType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentMetaDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentMetaData'] = ResolversParentTypes['DocumentMetaData']> = {
  answers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Instance']>>>, ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentResponse'] = ResolversParentTypes['DocumentResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentVersion'] = ResolversParentTypes['DocumentVersion']> = {
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  documentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  downloadName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  downloadUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileSize?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  folderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hasPreviousVersionQr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isExpected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  keywords?: Resolver<Maybe<Array<Maybe<ResolversTypes['Keyword']>>>, ParentType, ContextType>;
  md5Hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaData?: Resolver<Maybe<ResolversTypes['DocumentMetaData']>, ParentType, ContextType>;
  metadataInstanceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pathIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  qrCodeState?: Resolver<Maybe<ResolversTypes['StampStatus']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<Array<Maybe<ResolversTypes['Stamp']>>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['FinalizationState']>, ParentType, ContextType>;
  stateBuild12?: Resolver<Maybe<ResolversTypes['StateBuild12']>, ParentType, ContextType>;
  stateSnagstream?: Resolver<Maybe<ResolversTypes['StateSnagstream']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['AiSummary']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tag']>>>, ParentType, ContextType>;
  threeDConversionState?: Resolver<Maybe<ResolversTypes['ThreeDConversionStatus']>, ParentType, ContextType>;
  totalPreviewPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  versionState?: Resolver<Maybe<ResolversTypes['DocumentVersionState']>, ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentsResponse'] = ResolversParentTypes['DocumentsResponse']> = {
  documentIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownloadFilenameCharacterResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadFilenameCharacter'] = ResolversParentTypes['DownloadFilenameCharacter']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownloadFilenameConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadFilenameConfig'] = ResolversParentTypes['DownloadFilenameConfig']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  segments?: Resolver<Array<Maybe<ResolversTypes['ConfigDataSegment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrawingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Drawing'] = ResolversParentTypes['Drawing']> = {
  fileId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pages?: Resolver<Maybe<Array<Maybe<ResolversTypes['Page']>>>, ParentType, ContextType>;
  pageRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityFolderPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityFolderPermission'] = ResolversParentTypes['EntityFolderPermission']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  breakInheritance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  folderPermissions?: Resolver<Array<Maybe<ResolversTypes['FolderPermission']>>, ParentType, ContextType>;
  inheritedFrom?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupPermission']>>>, ParentType, ContextType>;
  inheritedFromParentFolders?: Resolver<Maybe<Array<Maybe<ResolversTypes['ParentFolderPermission']>>>, ParentType, ContextType>;
  memberId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<EntityFolderPermissionGroupArgs, never>>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  subFoldersPermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubFolderPermission']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacetGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacetGroup'] = ResolversParentTypes['FacetGroup']> = {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['FacetItem']>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FilterType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacetItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacetItem'] = ResolversParentTypes['FacetItem']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  apiValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mdField?: Resolver<Maybe<ResolversTypes['FacetItemMDField']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacetItemMdFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FacetItemMDField'] = ResolversParentTypes['FacetItemMDField']> = {
  fieldDefinitionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valueType?: Resolver<ResolversTypes['ValueTypeName'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldDefinition'] = ResolversParentTypes['FieldDefinition']> = {
  conditions?: Resolver<Maybe<Array<ResolversTypes['Condition']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldDefinitionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  indicative?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  linksAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  parentSectionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['Scope']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ValueTypeName']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valueType?: Resolver<Maybe<ResolversTypes['ValueType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldDefinitionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldDefinitionType'] = ResolversParentTypes['FieldDefinitionType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileInformation'] = ResolversParentTypes['FileInformation']> = {
  amountOfDocuments?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOfVersions?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalFileSize?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesExistsBuild12Resolvers<ContextType = any, ParentType extends ResolversParentTypes['FilesExistsBuild12'] = ResolversParentTypes['FilesExistsBuild12']> = {
  allowedIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  alreadyExistsIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  rejectedIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilesExistsSnagstreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilesExistsSnagstream'] = ResolversParentTypes['FilesExistsSnagstream']> = {
  allowedIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  alreadyExistsIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  rejectedIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Filter'] = ResolversParentTypes['Filter']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  property?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Folder'] = ResolversParentTypes['Folder']> = {
  availablePermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FolderPermission']>>>, ParentType, ContextType>;
  children?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType, RequireFields<FolderDocumentsArgs, never>>;
  entityFolderPermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityFolderPermission']>>>, ParentType, ContextType, RequireFields<FolderEntityFolderPermissionsArgs, never>>;
  fileInformation?: Resolver<Maybe<ResolversTypes['FileInformation']>, ParentType, ContextType>;
  hasSubFolders?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inheritParentMetadata?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPrivate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metadataSetId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentFolder?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType>;
  parentFolderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  subFolders?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType, RequireFields<FolderSubFoldersArgs, never>>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderChangeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FolderChangeResponse'] = ResolversParentTypes['FolderChangeResponse']> = {
  action?: Resolver<ResolversTypes['FolderChangeAction'], ParentType, ContextType>;
  folderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  parentFolderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previousParentFolderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderDuplicationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FolderDuplicationResponse'] = ResolversParentTypes['FolderDuplicationResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errorCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  folderName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FolderPermissionsChangeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FolderPermissionsChangeResponse'] = ResolversParentTypes['FolderPermissionsChangeResponse']> = {
  folderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoldersChangeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FoldersChangeResponse'] = ResolversParentTypes['FoldersChangeResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormLinkedSnagResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormLinkedSnag'] = ResolversParentTypes['FormLinkedSnag']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Type']>, ParentType, ContextType>;
  linkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  linkType?: Resolver<Maybe<ResolversTypes['LinkType']>, ParentType, ContextType>;
  direction?: Resolver<Maybe<ResolversTypes['DirectionType']>, ParentType, ContextType>;
  appData?: Resolver<Maybe<ResolversTypes['AppData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetSignatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetSignature'] = ResolversParentTypes['GetSignature']> = {
  data?: Resolver<Maybe<ResolversTypes['GetSignatureData']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetSignatureDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetSignatureData'] = ResolversParentTypes['GetSignatureData']> = {
  signatureId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  memberIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType>;
  membersCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  roleIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subgroupIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  subgroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType>;
  explicitProjectMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupPermission'] = ResolversParentTypes['GroupPermission']> = {
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  permission?: Resolver<Maybe<ResolversTypes['FolderPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HierarchicalListResolvers<ContextType = any, ParentType extends ResolversParentTypes['HierarchicalList'] = ResolversParentTypes['HierarchicalList']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  hierarchy?: Resolver<Array<ResolversTypes['Hierarchy']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['ScopeType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HierarchyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Hierarchy'] = ResolversParentTypes['Hierarchy']> = {
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expanded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasChildren?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentNodeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previousNodeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HierarchyDummyResolvers<ContextType = any, ParentType extends ResolversParentTypes['HierarchyDummy'] = ResolversParentTypes['HierarchyDummy']> = {
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dummy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expanded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasChildren?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentNodeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previousNodeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  selected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InheritedPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InheritedPermission'] = ResolversParentTypes['InheritedPermission']> = {
  permissions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Instance'] = ResolversParentTypes['Instance']> = {
  fieldDefinitionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integration'] = ResolversParentTypes['Integration']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['IntegrationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationProject'] = ResolversParentTypes['IntegrationProject']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationWithConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationWithConnection'] = ResolversParentTypes['IntegrationWithConnection']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  integrationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['IntegrationType'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  connectionStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  integrationConnection?: Resolver<Maybe<ResolversTypes['Connection']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type JobOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobOutput'] = ResolversParentTypes['JobOutput']> = {
  documentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['Keyword'] = ResolversParentTypes['Keyword']> = {
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkInfo'] = ResolversParentTypes['LinkInfo']> = {
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  folderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  linkId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkResponse'] = ResolversParentTypes['LinkResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkUnlinkDeviceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkUnlinkDeviceResponse'] = ResolversParentTypes['LinkUnlinkDeviceResponse']> = {
  commandId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type MarkMessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarkMessageResponse'] = ResolversParentTypes['MarkMessageResponse']> = {
  ids?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Me'] = ResolversParentTypes['Me']> = {
  organization?: Resolver<Maybe<ResolversTypes['MeOrganization']>, ParentType, ContextType>;
  projects?: Resolver<Maybe<Array<Maybe<ResolversTypes['MeProject']>>>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['MeUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeOrganization'] = ResolversParentTypes['MeOrganization']> = {
  enforceSSO?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeProject'] = ResolversParentTypes['MeProject']> = {
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeUser'] = ResolversParentTypes['MeUser']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  authType?: Resolver<Maybe<ResolversTypes['AuthType']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['Uri']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userflowSignature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Media'] = ResolversParentTypes['Media']> = {
  typeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['Member'] = ResolversParentTypes['Member']> = {
  __resolveType: TypeResolveFn<'Group' | 'User', ParentType, ContextType>;
};

export type MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<ResolversTypes['Attachment']>>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  fromId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  references?: Resolver<Array<ResolversTypes['Reference']>, ParentType, ContextType>;
  threadId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  to?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  cc?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bcc?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  messageType?: Resolver<Maybe<ResolversTypes['MessageType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageBranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageBranch'] = ResolversParentTypes['MessageBranch']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  main?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  original?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<Array<Maybe<ResolversTypes['Message']>>>, ParentType, ContextType>;
  replyAll?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  replyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  action?: Resolver<Maybe<ResolversTypes['EmailActions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetaData'] = ResolversParentTypes['MetaData']> = {
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  templates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Template']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaDataInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetaDataInstance'] = ResolversParentTypes['MetaDataInstance']> = {
  questions?: Resolver<Maybe<Array<Maybe<ResolversTypes['SearchDocumentMetaQuestion']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaDataOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetaDataOption'] = ResolversParentTypes['MetaDataOption']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveUserModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['MoveUserModel'] = ResolversParentTypes['MoveUserModel']> = {
  invitedUserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedUserOrganizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedUserOrganizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inviterUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inviterEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inviterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newOrganizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  newOrganizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateAddCommentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateAddCommentResponse'] = ResolversParentTypes['MutateAddCommentResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateDeleteCommentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateDeleteCommentResponse'] = ResolversParentTypes['MutateDeleteCommentResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateFolderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateFolderResponse'] = ResolversParentTypes['MutateFolderResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateGroupResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateGroupResponse'] = ResolversParentTypes['MutateGroupResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateMetaDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateMetaDataResponse'] = ResolversParentTypes['MutateMetaDataResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutateQualityControlInstanceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutateQualityControlInstanceResponse'] = ResolversParentTypes['MutateQualityControlInstanceResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  instanceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activateSubscription?: Resolver<Maybe<ResolversTypes['ChangeSubscriptionDataResponse']>, ParentType, ContextType, RequireFields<MutationActivateSubscriptionArgs, 'id' | 'addressLine1' | 'country' | 'city' | 'locale' | 'postalcode' | 'redirectUrl' | 'vatIdentificationNumber'>>;
  activateUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationActivateUsersArgs, 'userIds'>>;
  addAnnotation?: Resolver<Maybe<ResolversTypes['Annotation']>, ParentType, ContextType, RequireFields<MutationAddAnnotationArgs, 'documentId' | 'versionId' | 'json'>>;
  addComment?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType, RequireFields<MutationAddCommentArgs, 'body' | 'target'>>;
  addExternalUsers?: Resolver<Maybe<ResolversTypes['AddExternalUsersResponse']>, ParentType, ContextType, RequireFields<MutationAddExternalUsersArgs, 'emails' | 'projectId' | 'roleId'>>;
  addGroupsToProjects?: Resolver<Maybe<ResolversTypes['MutateGroupResponse']>, ParentType, ContextType, RequireFields<MutationAddGroupsToProjectsArgs, 'groupIds' | 'projectIds'>>;
  addIntegration?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationAddIntegrationArgs, 'application' | 'customIntegrationProps'>>;
  addMessage?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType, RequireFields<MutationAddMessageArgs, 'id' | 'subject'>>;
  addProjectMembers?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationAddProjectMembersArgs, 'projectId'>>;
  addQuestionsToResolveTaskBatch?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationAddQuestionsToResolveTaskBatchArgs, 'batch'>>;
  addSavedAnswers?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationAddSavedAnswersArgs, 'name' | 'templateId'>>;
  addSignature?: Resolver<Maybe<ResolversTypes['AddSignatureResponse']>, ParentType, ContextType, RequireFields<MutationAddSignatureArgs, never>>;
  addSnagToFormLink?: Resolver<Maybe<ResolversTypes['MutateQualityControlInstanceResponse']>, ParentType, ContextType, RequireFields<MutationAddSnagToFormLinkArgs, 'fieldId' | 'formInstanceId' | 'snagInstanceId'>>;
  addTagsToDocument?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationAddTagsToDocumentArgs, 'documentId' | 'tagIds'>>;
  addUserToProject?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationAddUserToProjectArgs, 'userId' | 'projectId' | 'roleId'>>;
  addUsersToProjects?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationAddUsersToProjectsArgs, 'projectIds' | 'userIds'>>;
  applyTemplate?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationApplyTemplateArgs, 'folderId' | 'templateId'>>;
  approveDocumentVersions?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationApproveDocumentVersionsArgs, 'versionIds'>>;
  cancelSubscription?: Resolver<Maybe<ResolversTypes['CancelSubscriptionDataResponse']>, ParentType, ContextType, RequireFields<MutationCancelSubscriptionArgs, 'reason'>>;
  changePaymentMethod?: Resolver<Maybe<ResolversTypes['ChangeSubscriptionDataResponse']>, ParentType, ContextType, RequireFields<MutationChangePaymentMethodArgs, 'locale' | 'redirectUrl'>>;
  changeSubscription?: Resolver<Maybe<ResolversTypes['ChangeSubscriptionDataResponse']>, ParentType, ContextType, RequireFields<MutationChangeSubscriptionArgs, 'id' | 'locale' | 'redirectUrl'>>;
  confirmEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationConfirmEmailArgs, 'token'>>;
  confirmMoveExistingUser?: Resolver<Maybe<ResolversTypes['MoveUserModel']>, ParentType, ContextType, RequireFields<MutationConfirmMoveExistingUserArgs, 'token'>>;
  connectProject?: Resolver<ResolversTypes['ConnectProjectResponse'], ParentType, ContextType, RequireFields<MutationConnectProjectArgs, 'customConnectionProps' | 'integrationId' | 'projectId'>>;
  convertTo3D?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationConvertTo3DArgs, 'versionId'>>;
  createApiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationCreateApiKeyArgs, 'description' | 'id'>>;
  createBatchTaskCategory?: Resolver<Maybe<ResolversTypes['BatchTaskCategoryResponse']>, ParentType, ContextType, RequireFields<MutationCreateBatchTaskCategoryArgs, 'names' | 'organizationId' | 'taskType'>>;
  createCommand?: Resolver<Maybe<ResolversTypes['CommandOutput']>, ParentType, ContextType, RequireFields<MutationCreateCommandArgs, 'command'>>;
  createDocument?: Resolver<Maybe<ResolversTypes['DocumentResponse']>, ParentType, ContextType, RequireFields<MutationCreateDocumentArgs, 'document'>>;
  createDocumentTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationCreateDocumentTaskArgs, 'name' | 'procedureId'>>;
  createDocuments?: Resolver<Maybe<ResolversTypes['DocumentsResponse']>, ParentType, ContextType, RequireFields<MutationCreateDocumentsArgs, 'documents'>>;
  createFieldDefinition?: Resolver<Maybe<ResolversTypes['MutateMetaDataResponse']>, ParentType, ContextType, RequireFields<MutationCreateFieldDefinitionArgs, 'id' | 'displayName' | 'templateService' | 'name' | 'scope' | 'type'>>;
  createFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationCreateFolderArgs, 'name'>>;
  createGroup?: Resolver<Maybe<ResolversTypes['MutateGroupResponse']>, ParentType, ContextType, RequireFields<MutationCreateGroupArgs, 'name'>>;
  createHierarchicalList?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateHierarchicalListArgs, 'name' | 'nodes'>>;
  createPrivateShareLink?: Resolver<Maybe<ResolversTypes['CreateShareLinkResponse']>, ParentType, ContextType, RequireFields<MutationCreatePrivateShareLinkArgs, 'expirationDate' | 'resources'>>;
  createProject?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateProjectArgs, 'name' | 'projectNumber'>>;
  createPublicShareLink?: Resolver<Maybe<ResolversTypes['CreateShareLinkResponse']>, ParentType, ContextType, RequireFields<MutationCreatePublicShareLinkArgs, 'expirationDate' | 'resources' | 'showFolders'>>;
  createQualityControlInstance?: Resolver<Maybe<ResolversTypes['MutateQualityControlInstanceResponse']>, ParentType, ContextType, RequireFields<MutationCreateQualityControlInstanceArgs, 'templateId' | 'projectId'>>;
  createReportConfiguration?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateReportConfigurationArgs, 'name' | 'reportOptions' | 'setAsDefault' | 'storeOnOrganizationLevel'>>;
  createScopedShareLink?: Resolver<Maybe<ResolversTypes['CreateShareLinkResponse']>, ParentType, ContextType, RequireFields<MutationCreateScopedShareLinkArgs, 'expirationDate' | 'resources'>>;
  createSmartFolder?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateSmartFolderArgs, 'name'>>;
  createSummary?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationCreateSummaryArgs, 'versionId'>>;
  createTags?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationCreateTagsArgs, 'tags'>>;
  createTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationCreateTaskArgs, 'name' | 'procedureId'>>;
  createTbqScanTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationCreateTbqScanTaskArgs, 'name' | 'procedureId' | 'projectId' | 'tbqTypeId' | 'tbqScanTypeId' | 'tbqModuleType'>>;
  createTbqTask?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationCreateTbqTaskArgs, 'task'>>;
  createTemplate?: Resolver<Maybe<ResolversTypes['MutateMetaDataResponse']>, ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'fieldDefinitionsBody' | 'id' | 'name' | 'scope' | 'templateService' | 'templateType'>>;
  createVisualContextFromDocument?: Resolver<Maybe<ResolversTypes['VisualContextMutationResponse']>, ParentType, ContextType, RequireFields<MutationCreateVisualContextFromDocumentArgs, 'documentId'>>;
  deactivateUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationDeactivateUsersArgs, 'userIds'>>;
  deleteAnnotations?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDeleteAnnotationsArgs, 'documentId' | 'versionId'>>;
  deleteBatchTaskCategory?: Resolver<Maybe<ResolversTypes['BatchDeleteTaskCategoryResponse']>, ParentType, ContextType, RequireFields<MutationDeleteBatchTaskCategoryArgs, 'taskCategories'>>;
  deleteComment?: Resolver<Maybe<ResolversTypes['MutateDeleteCommentResponse']>, ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, never>>;
  deleteDocumentVersion?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDeleteDocumentVersionArgs, 'documentId' | 'versionId'>>;
  deleteDocumentVersions?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationDeleteDocumentVersionsArgs, never>>;
  deleteDocuments?: Resolver<Maybe<ResolversTypes['BatchDocumentsResponse']>, ParentType, ContextType, RequireFields<MutationDeleteDocumentsArgs, 'documentIds'>>;
  deleteFieldDefinitions?: Resolver<Maybe<ResolversTypes['MutateMetaDataResponse']>, ParentType, ContextType, RequireFields<MutationDeleteFieldDefinitionsArgs, 'autoPublish' | 'ids' | 'templateService'>>;
  deleteFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationDeleteFolderArgs, 'id'>>;
  deleteGroups?: Resolver<Maybe<ResolversTypes['MutateGroupResponse']>, ParentType, ContextType, RequireFields<MutationDeleteGroupsArgs, 'groups'>>;
  deleteHierarchicalLists?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationDeleteHierarchicalListsArgs, 'ids'>>;
  deleteInstance?: Resolver<Maybe<Array<Maybe<ResolversTypes['DeleteInstanceResponse']>>>, ParentType, ContextType, RequireFields<MutationDeleteInstanceArgs, 'ids'>>;
  deleteIntegration?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationDeleteIntegrationArgs, 'id'>>;
  deleteIntegrationConnection?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationDeleteIntegrationConnectionArgs, 'id' | 'integrationId' | 'projectId'>>;
  deleteMembersFromProject?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDeleteMembersFromProjectArgs, 'members' | 'projectId'>>;
  deleteProjects?: Resolver<Maybe<ResolversTypes['BatchProjectsResponse']>, ParentType, ContextType, RequireFields<MutationDeleteProjectsArgs, 'projectIds'>>;
  deleteQuery?: Resolver<Maybe<ResolversTypes['SavedSearchResponse']>, ParentType, ContextType, RequireFields<MutationDeleteQueryArgs, 'id' | 'name'>>;
  deleteReportConfigurations?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationDeleteReportConfigurationsArgs, 'ids'>>;
  deleteSavedAnswers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteSavedAnswersArgs, 'ids'>>;
  deleteSmartFolder?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDeleteSmartFolderArgs, 'id'>>;
  deleteTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationDeleteTaskArgs, 'id' | 'procedureId'>>;
  deleteTemplates?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationDeleteTemplatesArgs, 'ids' | 'templateService'>>;
  deleteVisualContexts?: Resolver<Maybe<ResolversTypes['VisualContextMutationResponse']>, ParentType, ContextType, RequireFields<MutationDeleteVisualContextsArgs, never>>;
  downloadFolderZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDownloadFolderZipArgs, 'id' | 'includeSubfolders'>>;
  duplicateFolderStructure?: Resolver<Maybe<ResolversTypes['FolderDuplicationResponse']>, ParentType, ContextType, RequireFields<MutationDuplicateFolderStructureArgs, never>>;
  duplicateProject?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDuplicateProjectArgs, 'sourceProjectId' | 'name' | 'projectNumber'>>;
  editApiKey?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationEditApiKeyArgs, 'id'>>;
  editComment?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType, RequireFields<MutationEditCommentArgs, 'threadId'>>;
  editGroup?: Resolver<Maybe<ResolversTypes['MutateGroupResponse']>, ParentType, ContextType, RequireFields<MutationEditGroupArgs, 'groupId'>>;
  editHierarchicalList?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationEditHierarchicalListArgs, 'id' | 'name' | 'nodes'>>;
  editQuery?: Resolver<Maybe<ResolversTypes['SavedSearchResponse']>, ParentType, ContextType, RequireFields<MutationEditQueryArgs, 'id' | 'documentType' | 'name' | 'notificationsEnabled' | 'notificationDays' | 'notificationTimes' | 'searchUriComponent'>>;
  editReportConfiguration?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationEditReportConfigurationArgs, 'id' | 'name' | 'reportOptions' | 'setAsDefault' | 'storeOnOrganizationLevel'>>;
  editTemplate?: Resolver<Maybe<ResolversTypes['MutateMetaDataResponse']>, ParentType, ContextType, RequireFields<MutationEditTemplateArgs, 'fieldDefinitionsBody' | 'id' | 'name' | 'templateService'>>;
  finalizeDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationFinalizeDocumentsArgs, 'documentIds'>>;
  finalizeOrgInvitation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationFinalizeOrgInvitationArgs, 'firstName' | 'lastName' | 'locale' | 'password' | 'phoneNumber' | 'token'>>;
  generateDocumentKeywords?: Resolver<Maybe<ResolversTypes['BatchDocumentsResponse']>, ParentType, ContextType, RequireFields<MutationGenerateDocumentKeywordsArgs, 'versionIds'>>;
  generateDocumentKeywordsSingleDoc?: Resolver<Maybe<Array<Maybe<ResolversTypes['Keyword']>>>, ParentType, ContextType, RequireFields<MutationGenerateDocumentKeywordsSingleDocArgs, 'versionId'>>;
  inviteExistingUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<MutationInviteExistingUsersArgs, never>>;
  inviteUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<MutationInviteUsersArgs, never>>;
  linkOsidDevice?: Resolver<ResolversTypes['LinkUnlinkDeviceResponse'], ParentType, ContextType, RequireFields<MutationLinkOsidDeviceArgs, 'osidLinkInput'>>;
  linkProject?: Resolver<Maybe<ResolversTypes['ProjectLinkResponse']>, ParentType, ContextType, RequireFields<MutationLinkProjectArgs, 'nodeId' | 'projectId'>>;
  lockDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationLockDocumentsArgs, 'documentIds'>>;
  mapDocumentVersionMetaDataInstance?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationMapDocumentVersionMetaDataInstanceArgs, 'mappings'>>;
  markMessage?: Resolver<Maybe<ResolversTypes['MarkMessageResponse']>, ParentType, ContextType, RequireFields<MutationMarkMessageArgs, 'messages' | 'read'>>;
  moveDocument?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationMoveDocumentArgs, 'moveDocumentData'>>;
  moveDocuments?: Resolver<Maybe<ResolversTypes['BatchDocumentsResponse']>, ParentType, ContextType, RequireFields<MutationMoveDocumentsArgs, 'folderId'>>;
  moveFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationMoveFolderArgs, 'childFolderId' | 'parentFolderId'>>;
  moveProjectLink?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationMoveProjectLinkArgs, 'newFolderId' | 'currentLinkId' | 'projectInfo'>>;
  moveToNewOrganization?: Resolver<Maybe<ResolversTypes['MoveUserModel']>, ParentType, ContextType, RequireFields<MutationMoveToNewOrganizationArgs, 'token'>>;
  multiRieResults?: Resolver<Maybe<Array<Maybe<ResolversTypes['TBQRieResult']>>>, ParentType, ContextType, RequireFields<MutationMultiRieResultsArgs, never>>;
  mutateDocumentKeywords?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationMutateDocumentKeywordsArgs, 'keywords' | 'versionId'>>;
  pasteDocuments?: Resolver<Maybe<ResolversTypes['BatchDocumentsResponse']>, ParentType, ContextType, RequireFields<MutationPasteDocumentsArgs, 'documentIds' | 'folderId'>>;
  publishTemplates?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationPublishTemplatesArgs, 'ids' | 'templateService'>>;
  reInviteUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationReInviteUsersArgs, never>>;
  registerOrganization?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRegisterOrganizationArgs, 'email' | 'firstName' | 'locale' | 'organizationName' | 'phoneNumber' | 'password' | 'recaptcha' | 'subscriptionIntent'>>;
  rejectDocumentVersions?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationRejectDocumentVersionsArgs, 'versionIds'>>;
  removeFolderProjectLink?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationRemoveFolderProjectLinkArgs, 'linkId'>>;
  removeStamps?: Resolver<Maybe<ResolversTypes['RemoveStampsResponse']>, ParentType, ContextType, RequireFields<MutationRemoveStampsArgs, 'stamps'>>;
  removeTagsFromDocument?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationRemoveTagsFromDocumentArgs, 'documentId' | 'tagIds'>>;
  renameFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationRenameFolderArgs, 'name'>>;
  requestUnlockDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationRequestUnlockDocumentsArgs, 'documentIds'>>;
  restoreDocument?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRestoreDocumentArgs, 'id'>>;
  restoreDocumentVersion?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRestoreDocumentVersionArgs, 'documentId' | 'versionId'>>;
  restoreDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationRestoreDocumentsArgs, 'documentIds'>>;
  restoreFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationRestoreFolderArgs, 'id'>>;
  restoreInstance?: Resolver<Maybe<ResolversTypes['RestoreInstanceResponse']>, ParentType, ContextType, RequireFields<MutationRestoreInstanceArgs, never>>;
  restoreQuery?: Resolver<Maybe<ResolversTypes['SavedSearchResponse']>, ParentType, ContextType, RequireFields<MutationRestoreQueryArgs, 'id' | 'name'>>;
  restoreSavedAnswers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRestoreSavedAnswersArgs, 'ids'>>;
  restoreSmartFolder?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationRestoreSmartFolderArgs, 'id'>>;
  restoreTask?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationRestoreTaskArgs, 'id' | 'procedureId'>>;
  restoreVisualContexts?: Resolver<Maybe<ResolversTypes['VisualContextMutationResponse']>, ParentType, ContextType, RequireFields<MutationRestoreVisualContextsArgs, never>>;
  revokeApiKeys?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<MutationRevokeApiKeysArgs, 'ids'>>;
  revokeShareLink?: Resolver<Maybe<ResolversTypes['RevokeLinkResponse']>, ParentType, ContextType, RequireFields<MutationRevokeShareLinkArgs, 'id'>>;
  saveDownloadFileNameConfig?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationSaveDownloadFileNameConfigArgs, 'enabled' | 'segments' | 'templateId'>>;
  saveInstanceForQc?: Resolver<Maybe<ResolversTypes['QualityControlInstance']>, ParentType, ContextType, RequireFields<MutationSaveInstanceForQcArgs, 'answers' | 'instanceId' | 'templateVersion'>>;
  saveInstancesForDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['VersionMetaDataCreatedResponse']>>>, ParentType, ContextType, RequireFields<MutationSaveInstancesForDocumentsArgs, 'answers' | 'templateId'>>;
  saveQuery?: Resolver<Maybe<ResolversTypes['SavedSearchResponse']>, ParentType, ContextType, RequireFields<MutationSaveQueryArgs, 'documentType' | 'name' | 'notificationsEnabled' | 'notificationDays' | 'notificationTimes' | 'searchUriComponent' | 'scope'>>;
  sendShareLink?: Resolver<Maybe<ResolversTypes['SendShareLinkResponse']>, ParentType, ContextType, RequireFields<MutationSendShareLinkArgs, 'id'>>;
  setDocumentTags?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationSetDocumentTagsArgs, 'documentId' | 'tagIds'>>;
  setDocumentTaskLinks?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationSetDocumentTaskLinksArgs, 'documentId' | 'attachmentTaskIds' | 'deliverableTaskIds'>>;
  setEntitiesFolderPermissionsForFolder?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationSetEntitiesFolderPermissionsForFolderArgs, 'entities' | 'folderId'>>;
  setFolderProjectLink?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationSetFolderProjectLinkArgs, 'folderId' | 'projectInfo'>>;
  setFoldersPermissionsForUser?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationSetFoldersPermissionsForUserArgs, 'folders' | 'userId'>>;
  setLink?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationSetLinkArgs, 'resourceAId' | 'resourceBLinkIds'>>;
  setOrganizationEnforceSSO?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationSetOrganizationEnforceSsoArgs, never>>;
  setOrganizationRoleForUsers?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationSetOrganizationRoleForUsersArgs, 'roleId'>>;
  setProjectPermissions?: Resolver<Maybe<ResolversTypes['SetProjectPermissionsResponse']>, ParentType, ContextType, RequireFields<MutationSetProjectPermissionsArgs, 'projectId' | 'memberPermissions'>>;
  setProjectRoleForMembers?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationSetProjectRoleForMembersArgs, 'members' | 'projectId' | 'roleId'>>;
  setTaskTemplateLinks?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationSetTaskTemplateLinksArgs, 'taskId' | 'templateIds'>>;
  setTaskVisualContextLinks?: Resolver<Maybe<ResolversTypes['LinkResponse']>, ParentType, ContextType, RequireFields<MutationSetTaskVisualContextLinksArgs, 'taskId' | 'visualContextIds'>>;
  setTbqConfig?: Resolver<Maybe<ResolversTypes['SetTBQResponse']>, ParentType, ContextType, RequireFields<MutationSetTbqConfigArgs, 'nodeId'>>;
  shareToBuild12?: Resolver<ResolversTypes['BatchMutation'], ParentType, ContextType, RequireFields<MutationShareToBuild12Args, 'connectionId' | 'integrationId' | 'projectId' | 'projectPhaseId' | 'versionIds'>>;
  shareToSnagstream?: Resolver<ResolversTypes['BatchMutation'], ParentType, ContextType, RequireFields<MutationShareToSnagstreamArgs, 'connectionId' | 'integrationId' | 'projectId' | 'versionIds'>>;
  stampDocument?: Resolver<Maybe<ResolversTypes['StampDocumentResponse']>, ParentType, ContextType, RequireFields<MutationStampDocumentArgs, 'documentId' | 'documentVersionId' | 'params'>>;
  stayInCurrentOrganization?: Resolver<Maybe<ResolversTypes['MoveUserModel']>, ParentType, ContextType, RequireFields<MutationStayInCurrentOrganizationArgs, 'token'>>;
  toggleBatchDocumentDigest?: Resolver<Maybe<ResolversTypes['DocumentDigest']>, ParentType, ContextType, RequireFields<MutationToggleBatchDocumentDigestArgs, 'scope'>>;
  toggleSingleDocumentDigest?: Resolver<Maybe<ResolversTypes['DocumentDigest']>, ParentType, ContextType, RequireFields<MutationToggleSingleDocumentDigestArgs, 'scope'>>;
  unapproveDocumentVersions?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationUnapproveDocumentVersionsArgs, 'versionIds'>>;
  unfinalizeDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationUnfinalizeDocumentsArgs, 'documentIds'>>;
  unlinkLink?: Resolver<Maybe<ResolversTypes['MutateQualityControlInstanceResponse']>, ParentType, ContextType, RequireFields<MutationUnlinkLinkArgs, 'linkId'>>;
  unlinkOsidDevice?: Resolver<ResolversTypes['LinkUnlinkDeviceResponse'], ParentType, ContextType, RequireFields<MutationUnlinkOsidDeviceArgs, 'objectId'>>;
  unlinkProject?: Resolver<Maybe<ResolversTypes['ProjectLinkResponse']>, ParentType, ContextType, RequireFields<MutationUnlinkProjectArgs, 'projectId'>>;
  unlockDocuments?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationUnlockDocumentsArgs, 'documentIds'>>;
  unrejectDocumentVersions?: Resolver<Maybe<ResolversTypes['BatchResult']>, ParentType, ContextType, RequireFields<MutationUnrejectDocumentVersionsArgs, 'versionIds'>>;
  updateBatchTaskCategory?: Resolver<Maybe<ResolversTypes['BatchUpdateTaskCategoryResponse']>, ParentType, ContextType, RequireFields<MutationUpdateBatchTaskCategoryArgs, 'taskCategories'>>;
  updateDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType, RequireFields<MutationUpdateDocumentsArgs, 'documents'>>;
  updateFieldDefinition?: Resolver<Maybe<ResolversTypes['MutateMetaDataResponse']>, ParentType, ContextType, RequireFields<MutationUpdateFieldDefinitionArgs, 'autoPublish' | 'displayName' | 'id' | 'name' | 'templateService' | 'type'>>;
  updateFolder?: Resolver<Maybe<ResolversTypes['FolderChangeResponse']>, ParentType, ContextType, RequireFields<MutationUpdateFolderArgs, 'id' | 'name'>>;
  updateInstance?: Resolver<Maybe<ResolversTypes['updateInstance']>, ParentType, ContextType, RequireFields<MutationUpdateInstanceArgs, 'instanceId' | 'visualContextId' | 'x' | 'y' | 'page'>>;
  updateNotificationSettings?: Resolver<Maybe<ResolversTypes['NotificationSettings']>, ParentType, ContextType, RequireFields<MutationUpdateNotificationSettingsArgs, 'scope'>>;
  updateObjects?: Resolver<Maybe<ResolversTypes['ObjectsResponse']>, ParentType, ContextType, RequireFields<MutationUpdateObjectsArgs, never>>;
  updateOrganization?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationArgs, 'id' | 'name'>>;
  updateOrganizationSettings?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationSettingsArgs, 'id'>>;
  updateProfile?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'active' | 'id' | 'organizationId' | 'email' | 'firstName' | 'jobTitle' | 'language' | 'lastName' | 'phoneNumber'>>;
  updateProject?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateProjectArgs, 'id' | 'name' | 'projectNumber'>>;
  updateProjectSettings?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateProjectSettingsArgs, 'id'>>;
  updateSavedAnswers?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateSavedAnswersArgs, 'id'>>;
  updateSmartFolder?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationUpdateSmartFolderArgs, 'id' | 'name'>>;
  updateSupportSettings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateSupportSettingsArgs, never>>;
  updateTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationUpdateTaskArgs, 'id' | 'procedureId'>>;
  validateRegistrationToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationValidateRegistrationTokenArgs, 'token'>>;
};

export type NotificationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSettings'] = ResolversParentTypes['NotificationSettings']> = {
  notificationSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  notificationDays?: Resolver<Maybe<Array<Maybe<ResolversTypes['WeekDay']>>>, ParentType, ContextType>;
  notificationTimes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledTime']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notifications'] = ResolversParentTypes['Notifications']> = {
  notificationSettingsId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['NotificationsUser']>, ParentType, ContextType>;
  notificationTimes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledTime']>>>, ParentType, ContextType>;
  notificationDays?: Resolver<Maybe<Array<Maybe<ResolversTypes['WeekDay']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hasUpdatePermission?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsUser'] = ResolversParentTypes['NotificationsUser']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastUpdatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdatedById?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastUpdatedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  checked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberPropsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NumberProps'] = ResolversParentTypes['NumberProps']> = {
  maxValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rangeEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectNode'] = ResolversParentTypes['ObjectNode']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  parentNodeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  projectStatus?: Resolver<Maybe<ResolversTypes['ObjectProjectStatus']>, ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  tbqConfig?: Resolver<Maybe<Array<ResolversTypes['TBQObjectConfig']>>, ParentType, ContextType>;
  tbqFunction?: Resolver<Maybe<ResolversTypes['TBQFunction']>, ParentType, ContextType>;
  tbqFunctionAllowed?: Resolver<Maybe<Array<ResolversTypes['TBQFunction']>>, ParentType, ContextType>;
  tbqLatestTaskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  tbqRieScanType?: Resolver<Maybe<Array<ResolversTypes['TBQRieScanType']>>, ParentType, ContextType>;
  tbqScanTypeAllowed?: Resolver<Maybe<Array<ResolversTypes['TBQScanType']>>, ParentType, ContextType>;
  tbqType?: Resolver<Maybe<ResolversTypes['TBQType']>, ParentType, ContextType>;
  tbqTypeAbstract?: Resolver<Maybe<ResolversTypes['TBQTypeAbstract']>, ParentType, ContextType>;
  tbqTypeAllowed?: Resolver<Maybe<Array<ResolversTypes['TBQType']>>, ParentType, ContextType>;
  tbqTypeAllowedAbstract?: Resolver<Maybe<ResolversTypes['TBQTypeAbstract']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObjectsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObjectsResponse'] = ResolversParentTypes['ObjectsResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  objectIds?: Resolver<Array<Maybe<ResolversTypes['ID']>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfficeOnlineInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfficeOnlineInfo'] = ResolversParentTypes['OfficeOnlineInfo']> = {
  canOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  editUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  expireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metaData?: Resolver<Maybe<ResolversTypes['MetaData']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionLevelId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationSubscriptionLevel?: Resolver<Maybe<ResolversTypes['OrganizationSubscriptionLevel']>, ParentType, ContextType>;
  projects?: Resolver<Maybe<Array<Maybe<ResolversTypes['Project']>>>, ParentType, ContextType>;
  enforceSSO?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['OrganizationSettings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationSettings'] = ResolversParentTypes['OrganizationSettings']> = {
  approve_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reject_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalize_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ai_admin_settings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ai_organization_settings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OsidDeviceLinkStateResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OsidDeviceLinkStateResponse'] = ResolversParentTypes['OsidDeviceLinkStateResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OsidDeviceLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['OsidDeviceLog'] = ResolversParentTypes['OsidDeviceLog']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deviceCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adres?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OsidDevicesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['OsidDevicesResponse'] = ResolversParentTypes['OsidDevicesResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['OsidDevicesType']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OsidDevicesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OsidDevicesType'] = ResolversParentTypes['OsidDevicesType']> = {
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = {
  pageNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoomLevels?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentFolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParentFolder'] = ResolversParentTypes['ParentFolder']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentFolderPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParentFolderPermission'] = ResolversParentTypes['ParentFolderPermission']> = {
  parentFolder?: Resolver<Maybe<ResolversTypes['ParentFolder']>, ParentType, ContextType>;
  permission?: Resolver<Maybe<ResolversTypes['FolderPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentInfo'] = ResolversParentTypes['PaymentInfo']> = {
  last3Digits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhaseBuild12Resolvers<ContextType = any, ParentType extends ResolversParentTypes['PhaseBuild12'] = ResolversParentTypes['PhaseBuild12']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Position'] = ResolversParentTypes['Position']> = {
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcedureResolvers<ContextType = any, ParentType extends ResolversParentTypes['Procedure'] = ResolversParentTypes['Procedure']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectMainProcedure?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType, RequireFields<ProcedureTasksArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  availableRoles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  deadline?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  folders?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType, RequireFields<ProjectFoldersArgs, never>>;
  mainProcedure?: Resolver<Maybe<ResolversTypes['Procedure']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType>;
  metaData?: Resolver<Maybe<ResolversTypes['MetaData']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  procedures?: Resolver<Maybe<Array<Maybe<ResolversTypes['Procedure']>>>, ParentType, ContextType>;
  projectNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['ProjectSettings']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectByFolderResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectByFolderResponse'] = ResolversParentTypes['ProjectByFolderResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['LinkInfo']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectLinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectLinkResponse'] = ResolversParentTypes['ProjectLinkResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectRoles'] = ResolversParentTypes['ProjectRoles']> = {
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectSettings'] = ResolversParentTypes['ProjectSettings']> = {
  metadata_required?: Resolver<Maybe<ResolversTypes['RequiredMetaDataFieldOption']>, ParentType, ContextType>;
  version_name_instead_of_document_name_on_upload?: Resolver<Maybe<ResolversTypes['UploadFileOption']>, ParentType, ContextType>;
  approve_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reject_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  finalize_action_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ai_admin_settings?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectUnreadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectUnread'] = ResolversParentTypes['ProjectUnread']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QcInstanceHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['QCInstanceHistory'] = ResolversParentTypes['QCInstanceHistory']> = {
  today?: Resolver<Maybe<Array<ResolversTypes['QcHistoryEvent']>>, ParentType, ContextType>;
  thisWeek?: Resolver<Maybe<Array<ResolversTypes['QcHistoryEvent']>>, ParentType, ContextType>;
  thisMonth?: Resolver<Maybe<Array<ResolversTypes['QcHistoryEvent']>>, ParentType, ContextType>;
  older?: Resolver<Maybe<Array<ResolversTypes['QcHistoryEvent']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QcSearchTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['QCSearchTask'] = ResolversParentTypes['QCSearchTask']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TaskType']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  assignment?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QcTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QCTemplate'] = ResolversParentTypes['QCTemplate']> = {
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  templateVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstAnswerAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QcHistoryEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['QcHistoryEvent'] = ResolversParentTypes['QcHistoryEvent']> = {
  changeDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  commandId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  historyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['QCInstanceEventType'], ParentType, ContextType>;
  properties?: Resolver<Array<ResolversTypes['QcHistoryProperty']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QcHistoryPropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['QcHistoryProperty'] = ResolversParentTypes['QcHistoryProperty']> = {
  addedValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  addedResolvedValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  changeDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  deletedResolvedValues?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  resourceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  historyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QcPropertyType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualityControlInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualityControlInstance'] = ResolversParentTypes['QualityControlInstance']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<Maybe<Array<ResolversTypes['AnswerType']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  iconName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['FieldDefinition']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QualityControlInstanceType'], ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  visualContextId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isOptimistic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  templates?: Resolver<Maybe<Array<ResolversTypes['QCTemplate']>>, ParentType, ContextType>;
  linkedSnags?: Resolver<Maybe<Array<ResolversTypes['FormLinkedSnag']>>, ParentType, ContextType>;
  linkedSnagInstances?: Resolver<Maybe<Array<ResolversTypes['QualityControlInstance']>>, ParentType, ContextType>;
  linkedFieldId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  templateIconProps?: Resolver<Maybe<ResolversTypes['TemplateIconProps']>, ParentType, ContextType>;
  templateVersion?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualityControlTaskInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualityControlTaskInstance'] = ResolversParentTypes['QualityControlTaskInstance']> = {
  answers?: Resolver<Maybe<Array<ResolversTypes['AnswerType']>>, ParentType, ContextType>;
  instanceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  templates?: Resolver<Maybe<Array<ResolversTypes['QCTemplate']>>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleteBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleteAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  ObjectInclude?: Resolver<Maybe<ResolversTypes['ObjectNode']>, ParentType, ContextType, RequireFields<QueryObjectIncludeArgs, 'id'>>;
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allTemplateItemsByScope?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType, RequireFields<QueryAllTemplateItemsByScopeArgs, 'templateService' | 'templateType'>>;
  allowedGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType, RequireFields<QueryAllowedGroupsArgs, never>>;
  annotations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Annotation']>>>, ParentType, ContextType, RequireFields<QueryAnnotationsArgs, 'documentId' | 'versionId'>>;
  apiKey?: Resolver<Maybe<ResolversTypes['ApiKey']>, ParentType, ContextType, RequireFields<QueryApiKeyArgs, 'id'>>;
  apiKeys?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApiKey']>>>, ParentType, ContextType>;
  baseFolderByPath?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType, RequireFields<QueryBaseFolderByPathArgs, 'path'>>;
  batchQualityControlInstances?: Resolver<Array<ResolversTypes['QualityControlInstance']>, ParentType, ContextType, RequireFields<QueryBatchQualityControlInstancesArgs, 'ids'>>;
  checkCommandStatus?: Resolver<Maybe<ResolversTypes['CommandStatus']>, ParentType, ContextType, RequireFields<QueryCheckCommandStatusArgs, 'id'>>;
  commentThread?: Resolver<Maybe<ResolversTypes['CommentThread']>, ParentType, ContextType, RequireFields<QueryCommentThreadArgs, never>>;
  connection?: Resolver<ResolversTypes['Connection'], ParentType, ContextType, RequireFields<QueryConnectionArgs, 'id' | 'projectId'>>;
  connections?: Resolver<Array<ResolversTypes['IntegrationWithConnection']>, ParentType, ContextType, RequireFields<QueryConnectionsArgs, 'projectId'>>;
  countries?: Resolver<ResolversTypes['CountryDataResponse'], ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryDocumentArgs, 'id'>>;
  documentClientDownloadUrls?: Resolver<Maybe<ResolversTypes['ClientDownloadUrls']>, ParentType, ContextType>;
  documentHistory?: Resolver<Maybe<ResolversTypes['DocumentHistory']>, ParentType, ContextType, RequireFields<QueryDocumentHistoryArgs, 'documentId' | 'fetchForVersion'>>;
  documentState?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryDocumentStateArgs, 'id'>>;
  documentVersion?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType, RequireFields<QueryDocumentVersionArgs, 'id'>>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType, RequireFields<QueryDocumentsArgs, 'documentIds'>>;
  downloadFileNameConfig?: Resolver<Maybe<ResolversTypes['DownloadFilenameConfig']>, ParentType, ContextType, RequireFields<QueryDownloadFileNameConfigArgs, 'templateId'>>;
  downloadFilenameCharacters?: Resolver<Maybe<Array<Maybe<ResolversTypes['DownloadFilenameCharacter']>>>, ParentType, ContextType>;
  fieldDefinition?: Resolver<Maybe<ResolversTypes['FieldDefinition']>, ParentType, ContextType, RequireFields<QueryFieldDefinitionArgs, 'fieldDefinitionId'>>;
  fieldDefinitionTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinitionType']>>>, ParentType, ContextType, RequireFields<QueryFieldDefinitionTypesArgs, never>>;
  fieldDefinitions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType, RequireFields<QueryFieldDefinitionsArgs, never>>;
  filesExistsBuild12?: Resolver<ResolversTypes['FilesExistsBuild12'], ParentType, ContextType, RequireFields<QueryFilesExistsBuild12Args, 'versionIds' | 'projectId' | 'connectionId' | 'projectPhaseId'>>;
  filesExistsSnagstream?: Resolver<ResolversTypes['FilesExistsSnagstream'], ParentType, ContextType, RequireFields<QueryFilesExistsSnagstreamArgs, 'versionIds' | 'projectId' | 'connectionId'>>;
  folder?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType, RequireFields<QueryFolderArgs, 'id'>>;
  folderByPath?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType, RequireFields<QueryFolderByPathArgs, 'path'>>;
  folderWithPermissionsByPath?: Resolver<Maybe<ResolversTypes['Folder']>, ParentType, ContextType, RequireFields<QueryFolderWithPermissionsByPathArgs, 'path'>>;
  folders?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType, RequireFields<QueryFoldersArgs, never>>;
  foldersById?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType, RequireFields<QueryFoldersByIdArgs, never>>;
  foldersByPath?: Resolver<Maybe<Array<Maybe<ResolversTypes['Folder']>>>, ParentType, ContextType, RequireFields<QueryFoldersByPathArgs, 'path'>>;
  getNotifications?: Resolver<Maybe<ResolversTypes['Notifications']>, ParentType, ContextType, RequireFields<QueryGetNotificationsArgs, 'scope'>>;
  getOrganizationTaskCategoriesByType?: Resolver<Maybe<Array<ResolversTypes['TaskCategory']>>, ParentType, ContextType, RequireFields<QueryGetOrganizationTaskCategoriesByTypeArgs, 'taskType'>>;
  getProjectPermissions?: Resolver<Maybe<ResolversTypes['UserQCPermissions']>, ParentType, ContextType, RequireFields<QueryGetProjectPermissionsArgs, 'memberId' | 'memberType' | 'projectId'>>;
  getProjectTaskCategoriesByType?: Resolver<Maybe<Array<ResolversTypes['TaskCategory']>>, ParentType, ContextType, RequireFields<QueryGetProjectTaskCategoriesByTypeArgs, never>>;
  getSignature?: Resolver<Maybe<ResolversTypes['GetSignature']>, ParentType, ContextType>;
  getTBQBrandcheckMultiResult?: Resolver<Maybe<Array<ResolversTypes['TBQBrandcheckMultiResult']>>, ParentType, ContextType, RequireFields<QueryGetTbqBrandcheckMultiResultArgs, 'objectId'>>;
  getTBQGlobalScope?: Resolver<Array<ResolversTypes['TBQGlobalScope']>, ParentType, ContextType>;
  getTBQOsidDeviceLinkState?: Resolver<ResolversTypes['OsidDeviceLinkStateResponse'], ParentType, ContextType, RequireFields<QueryGetTbqOsidDeviceLinkStateArgs, 'objectId'>>;
  getTBQOsidDeviceLogs?: Resolver<Maybe<Array<ResolversTypes['OsidDeviceLog']>>, ParentType, ContextType, RequireFields<QueryGetTbqOsidDeviceLogsArgs, 'deviceCode' | 'objectId'>>;
  getTBQOsidDevices?: Resolver<Maybe<ResolversTypes['OsidDevicesResponse']>, ParentType, ContextType>;
  getTBQResult?: Resolver<Maybe<ResolversTypes['TBQResult']>, ParentType, ContextType, RequireFields<QueryGetTbqResultArgs, 'taskId'>>;
  getTBQRieResult?: Resolver<Maybe<Array<ResolversTypes['TBQRieResult']>>, ParentType, ContextType, RequireFields<QueryGetTbqRieResultArgs, 'taskId'>>;
  getTbqConfig?: Resolver<Maybe<Array<ResolversTypes['TBQConfig']>>, ParentType, ContextType, RequireFields<QueryGetTbqConfigArgs, 'tbqTypeId'>>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<QueryGroupArgs, 'groupId'>>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType, RequireFields<QueryGroupsArgs, never>>;
  groupsAndUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType, RequireFields<QueryGroupsAndUsersArgs, never>>;
  hierarchicalList?: Resolver<ResolversTypes['HierarchicalList'], ParentType, ContextType, RequireFields<QueryHierarchicalListArgs, 'id'>>;
  hierarchicalLists?: Resolver<Maybe<Array<ResolversTypes['HierarchicalList']>>, ParentType, ContextType, RequireFields<QueryHierarchicalListsArgs, never>>;
  instanceWithSuggestedAnswers?: Resolver<Maybe<ResolversTypes['SuggestedAnswersBasedOnImageResponse']>, ParentType, ContextType, RequireFields<QueryInstanceWithSuggestedAnswersArgs, 'templateId' | 'fileId' | 'x' | 'y' | 'visualContextId' | 'projectId'>>;
  instances?: Resolver<Maybe<Array<Maybe<ResolversTypes['QualityControlInstance']>>>, ParentType, ContextType, RequireFields<QueryInstancesArgs, 'ids'>>;
  integration?: Resolver<ResolversTypes['Integration'], ParentType, ContextType, RequireFields<QueryIntegrationArgs, 'id'>>;
  integrations?: Resolver<Array<ResolversTypes['Integration']>, ParentType, ContextType>;
  loadQueries?: Resolver<Maybe<Array<Maybe<ResolversTypes['SavedSearch']>>>, ParentType, ContextType, RequireFields<QueryLoadQueriesArgs, never>>;
  me?: Resolver<Maybe<ResolversTypes['Me']>, ParentType, ContextType>;
  messageBranch?: Resolver<ResolversTypes['MessageBranch'], ParentType, ContextType, RequireFields<QueryMessageBranchArgs, 'messageId'>>;
  messageInbox?: Resolver<Array<Maybe<ResolversTypes['Message']>>, ParentType, ContextType, RequireFields<QueryMessageInboxArgs, never>>;
  myOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  myOrganizationProjects?: Resolver<Maybe<Array<ResolversTypes['Project']>>, ParentType, ContextType>;
  myProjectRoles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryMyProjectRolesArgs, never>>;
  myProjects?: Resolver<Maybe<Array<ResolversTypes['Project']>>, ParentType, ContextType>;
  myTasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  object?: Resolver<Maybe<ResolversTypes['ObjectNode']>, ParentType, ContextType, RequireFields<QueryObjectArgs, never>>;
  objects?: Resolver<Array<ResolversTypes['ObjectNode']>, ParentType, ContextType, RequireFields<QueryObjectsArgs, never>>;
  officeOnlineInfo?: Resolver<Maybe<ResolversTypes['OfficeOnlineInfo']>, ParentType, ContextType, RequireFields<QueryOfficeOnlineInfoArgs, 'id'>>;
  openCdeUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<QueryOrganizationArgs, 'id'>>;
  organizationExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryOrganizationExistsArgs, 'name'>>;
  organizationUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  organizations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Organization']>>>, ParentType, ContextType>;
  pdfTronLicenseKey?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryPdfTronLicenseKeyArgs, 'documentVersionId'>>;
  phaseBuild12?: Resolver<ResolversTypes['PhaseBuild12'], ParentType, ContextType, RequireFields<QueryPhaseBuild12Args, 'id'>>;
  phasesBuild12?: Resolver<Array<ResolversTypes['PhaseBuild12']>, ParentType, ContextType, RequireFields<QueryPhasesBuild12Args, 'connectionId' | 'projectId'>>;
  privateLinkData?: Resolver<Maybe<ResolversTypes['SharedLinkDataResponse']>, ParentType, ContextType, RequireFields<QueryPrivateLinkDataArgs, 'id' | 'template' | 'signature'>>;
  procedure?: Resolver<Maybe<ResolversTypes['Procedure']>, ParentType, ContextType, RequireFields<QueryProcedureArgs, 'id'>>;
  procedures?: Resolver<Maybe<Array<Maybe<ResolversTypes['Procedure']>>>, ParentType, ContextType>;
  project?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<QueryProjectArgs, 'id'>>;
  projectMembers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType, RequireFields<QueryProjectMembersArgs, 'projectId'>>;
  projects?: Resolver<Array<Maybe<ResolversTypes['Project']>>, ParentType, ContextType, RequireFields<QueryProjectsArgs, 'ids'>>;
  projectsBuild12?: Resolver<Array<ResolversTypes['IntegrationProject']>, ParentType, ContextType, RequireFields<QueryProjectsBuild12Args, 'integrationId'>>;
  projectsByFolder?: Resolver<Maybe<ResolversTypes['ProjectByFolderResponse']>, ParentType, ContextType, RequireFields<QueryProjectsByFolderArgs, 'ids'>>;
  projectsSnagstream?: Resolver<Array<ResolversTypes['IntegrationProject']>, ParentType, ContextType, RequireFields<QueryProjectsSnagstreamArgs, 'integrationId'>>;
  projectsUnreadCount?: Resolver<Array<Maybe<ResolversTypes['ProjectUnread']>>, ParentType, ContextType, RequireFields<QueryProjectsUnreadCountArgs, never>>;
  publicAttachmentData?: Resolver<Maybe<ResolversTypes['SharedAttachmentDataResponse']>, ParentType, ContextType, RequireFields<QueryPublicAttachmentDataArgs, 'id' | 'template' | 'signature'>>;
  publicLinkData?: Resolver<Maybe<ResolversTypes['SharedLinkDataResponse']>, ParentType, ContextType, RequireFields<QueryPublicLinkDataArgs, 'id' | 'template' | 'signature'>>;
  qrCodeState?: Resolver<Maybe<ResolversTypes['StampStatus']>, ParentType, ContextType, RequireFields<QueryQrCodeStateArgs, 'versionId'>>;
  qualityControlGetOrCreateInstance?: Resolver<Maybe<ResolversTypes['QualityControlInstance']>, ParentType, ContextType, RequireFields<QueryQualityControlGetOrCreateInstanceArgs, 'projectId' | 'taskId' | 'templateId'>>;
  qualityControlInstance?: Resolver<Maybe<ResolversTypes['QualityControlInstance']>, ParentType, ContextType, RequireFields<QueryQualityControlInstanceArgs, 'id'>>;
  qualityControlInstances?: Resolver<Array<ResolversTypes['QualityControlInstance']>, ParentType, ContextType, RequireFields<QueryQualityControlInstancesArgs, 'projectId'>>;
  qualityControlTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<QueryQualityControlTaskArgs, 'id'>>;
  qualityControlTaskInstances?: Resolver<Maybe<Array<Maybe<ResolversTypes['QualityControlInstance']>>>, ParentType, ContextType, RequireFields<QueryQualityControlTaskInstancesArgs, 'taskId'>>;
  reportBackgrounds?: Resolver<Maybe<Array<ResolversTypes['ReportImage']>>, ParentType, ContextType>;
  reportConfiguration?: Resolver<Maybe<ResolversTypes['ReportConfiguration']>, ParentType, ContextType, RequireFields<QueryReportConfigurationArgs, 'id'>>;
  reportConfigurations?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportConfiguration']>>>, ParentType, ContextType, RequireFields<QueryReportConfigurationsArgs, 'projectId'>>;
  reportLogos?: Resolver<Maybe<Array<ResolversTypes['ReportImage']>>, ParentType, ContextType>;
  resourcePermissions?: Resolver<Maybe<ResolversTypes['ResourcePermissions']>, ParentType, ContextType, RequireFields<QueryResourcePermissionsArgs, 'projectId' | 'resourceId' | 'resourceType'>>;
  savedAnswers?: Resolver<Maybe<Array<ResolversTypes['SavedAnswers']>>, ParentType, ContextType, RequireFields<QuerySavedAnswersArgs, 'projectId'>>;
  savedAnswersInstance?: Resolver<Maybe<ResolversTypes['SavedAnswersInstance']>, ParentType, ContextType, RequireFields<QuerySavedAnswersInstanceArgs, 'id'>>;
  savedAnswersOnTemplateId?: Resolver<Maybe<Array<ResolversTypes['SavedAnswers']>>, ParentType, ContextType, RequireFields<QuerySavedAnswersOnTemplateIdArgs, 'templateId'>>;
  scopedLinkData?: Resolver<Maybe<ResolversTypes['SharedLinkDataResponse']>, ParentType, ContextType, RequireFields<QueryScopedLinkDataArgs, 'id' | 'template' | 'signature'>>;
  search?: Resolver<Maybe<ResolversTypes['SearchResponse']>, ParentType, ContextType, RequireFields<QuerySearchArgs, never>>;
  smartFolder?: Resolver<Maybe<ResolversTypes['SmartFolder']>, ParentType, ContextType, RequireFields<QuerySmartFolderArgs, 'id'>>;
  smartFolders?: Resolver<Maybe<Array<Maybe<ResolversTypes['SmartFolder']>>>, ParentType, ContextType, RequireFields<QuerySmartFoldersArgs, never>>;
  snagFormHistoryLog?: Resolver<Maybe<ResolversTypes['QCInstanceHistory']>, ParentType, ContextType, RequireFields<QuerySnagFormHistoryLogArgs, 'id'>>;
  stamps?: Resolver<Maybe<ResolversTypes['StampsResponse']>, ParentType, ContextType, RequireFields<QueryStampsArgs, 'ids'>>;
  standardFieldDefinitions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType>;
  subscriptionLevels?: Resolver<ResolversTypes['SubscriptionLevelsDataResponse'], ParentType, ContextType>;
  subscriptionOrganization?: Resolver<Maybe<ResolversTypes['SubscriptionOrganizationDataResponse']>, ParentType, ContextType, RequireFields<QuerySubscriptionOrganizationArgs, never>>;
  subscriptionOrganizationExpire?: Resolver<Maybe<ResolversTypes['SubscriptionOrganizationExpireDataResponse']>, ParentType, ContextType, RequireFields<QuerySubscriptionOrganizationExpireArgs, never>>;
  subscriptionOrganizationUsers?: Resolver<Maybe<ResolversTypes['SubscriptionOrganizationUsersDataResponse']>, ParentType, ContextType>;
  suggestedTemplatesBasedOnImage?: Resolver<Maybe<ResolversTypes['SuggestedTemplatesBasedOnImageResponse']>, ParentType, ContextType, RequireFields<QuerySuggestedTemplatesBasedOnImageArgs, 'projectId' | 'base64ImageString' | 'templateType'>>;
  supportSettings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supportedFormats?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType, RequireFields<QueryTagArgs, 'id'>>;
  tags?: Resolver<Array<Maybe<ResolversTypes['Tag']>>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<QueryTaskArgs, 'id'>>;
  taskStatuses?: Resolver<Maybe<Array<ResolversTypes['TaskStatus']>>, ParentType, ContextType, RequireFields<QueryTaskStatusesArgs, 'id' | 'status'>>;
  tasksBatchInclude?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType, RequireFields<QueryTasksBatchIncludeArgs, 'ids'>>;
  templateItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType, RequireFields<QueryTemplateItemsArgs, 'id' | 'templateService'>>;
  templates?: Resolver<Maybe<Array<ResolversTypes['Template']>>, ParentType, ContextType, RequireFields<QueryTemplatesArgs, 'templateService' | 'templateType'>>;
  templatesByIds?: Resolver<Maybe<Array<ResolversTypes['Template']>>, ParentType, ContextType, RequireFields<QueryTemplatesByIdsArgs, 'ids' | 'templateService'>>;
  testMobileAppFallBackApi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threeDConversionStatus?: Resolver<Maybe<ResolversTypes['ThreeDConversionStatus']>, ParentType, ContextType, RequireFields<QueryThreeDConversionStatusArgs, 'versionId'>>;
  threeDConversionStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['ThreeDConversionStatus']>>>, ParentType, ContextType, RequireFields<QueryThreeDConversionStatusesArgs, 'versionIds'>>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<Array<Maybe<ResolversTypes['UnreadMessages']>>>, ParentType, ContextType, RequireFields<QueryUnreadCountArgs, never>>;
  userExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryUserExistsArgs, 'email'>>;
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryUsersArgs, never>>;
  version?: Resolver<Maybe<ResolversTypes['DocumentVersion']>, ParentType, ContextType, RequireFields<QueryVersionArgs, 'id'>>;
  versions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType, RequireFields<QueryVersionsArgs, 'ids'>>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType, RequireFields<QueryVisualContextArgs, 'id'>>;
  visualContexts?: Resolver<Maybe<Array<ResolversTypes['VisualContext']>>, ParentType, ContextType, RequireFields<QueryVisualContextsArgs, 'projectId'>>;
  visualContextsForTask?: Resolver<Maybe<Array<ResolversTypes['VisualContext']>>, ParentType, ContextType, RequireFields<QueryVisualContextsForTaskArgs, 'id'>>;
};

export type ReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reference'] = ResolversParentTypes['Reference']> = {
  referenceData?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  referenceKind?: Resolver<ResolversTypes['ReferenceKind'], ParentType, ContextType>;
  referenceType?: Resolver<Maybe<ResolversTypes['ReferenceType']>, ParentType, ContextType>;
  referenceValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveStampsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveStampsResponse'] = ResolversParentTypes['RemoveStampsResponse']> = {
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportConfiguration'] = ResolversParentTypes['ReportConfiguration']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  reportOptions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['ScopeType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportImage'] = ResolversParentTypes['ReportImage']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  imageType?: Resolver<Maybe<ResolversTypes['ImageType']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResolveTaskLinksResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResolveTaskLinks'] = ResolversParentTypes['ResolveTaskLinks']> = {
  appData?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  direction?: Resolver<Maybe<ResolversTypes['Direction']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkType?: Resolver<Maybe<ResolversTypes['LinkType']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ResourceType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourcePermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourcePermissions'] = ResolversParentTypes['ResourcePermissions']> = {
  permissions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = {
  __resolveType: TypeResolveFn<'AddExternalUsersResponse' | 'BatchDeleteTaskCategoryResponse' | 'BatchDocumentsResponse' | 'BatchProjectsResponse' | 'BatchTaskCategoryResponse' | 'BatchUpdateTaskCategoryResponse' | 'CreateShareLinkResponse' | 'DocumentResponse' | 'DocumentsResponse' | 'LinkResponse' | 'LinkUnlinkDeviceResponse' | 'MarkMessageResponse' | 'MutateAddCommentResponse' | 'MutateDeleteCommentResponse' | 'ObjectsResponse' | 'ProjectLinkResponse' | 'RemoveStampsResponse' | 'RevokeLinkResponse' | 'SavedSearchResponse' | 'SearchResponse' | 'SendShareLinkResponse' | 'SetTBQResponse' | 'SharedAttachmentDataResponse' | 'SharedLinkDataResponse' | 'StampDocumentResponse' | 'StampsResponse' | 'VisualContextMutationResponse', ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
};

export type RestoreInstanceDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['RestoreInstanceData'] = ResolversParentTypes['RestoreInstanceData']> = {
  instanceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RestoreInstanceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RestoreInstanceResponse'] = ResolversParentTypes['RestoreInstanceResponse']> = {
  data?: Resolver<Maybe<ResolversTypes['RestoreInstanceData']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevokeLinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RevokeLinkResponse'] = ResolversParentTypes['RevokeLinkResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieCondition'] = ResolversParentTypes['RieCondition']> = {
  fieldId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieOption'] = ResolversParentTypes['RieOption']> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieResult'] = ResolversParentTypes['RieResult']> = {
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieSection'] = ResolversParentTypes['RieSection']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentSectionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  valueType?: Resolver<ResolversTypes['RieValueTypeSection'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  conditions?: Resolver<Maybe<Array<Maybe<ResolversTypes['RieCondition']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieSubFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieSubField'] = ResolversParentTypes['RieSubField']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentSectionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  valueType?: Resolver<ResolversTypes['RieValueType'], ParentType, ContextType>;
  linksAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  indicative?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tagId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  fieldDefinitionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  fieldDefinitionDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditions?: Resolver<Maybe<Array<Maybe<ResolversTypes['RieCondition']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieValueTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieValueType'] = ResolversParentTypes['RieValueType']> = {
  multiSelect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  displayType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['RieOption']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RieValueTypeSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RieValueTypeSection'] = ResolversParentTypes['RieValueTypeSection']> = {
  subFields?: Resolver<Array<ResolversTypes['RieSubField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedAnswersResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedAnswers'] = ResolversParentTypes['SavedAnswers']> = {
  answers?: Resolver<Maybe<Array<ResolversTypes['AnswerType']>>, ParentType, ContextType>;
  createdOn?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  templateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TemplateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedAnswersInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedAnswersInstance'] = ResolversParentTypes['SavedAnswersInstance']> = {
  answers?: Resolver<Maybe<Array<ResolversTypes['AnswerType']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['FieldDefinition']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedSearchResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedSearch'] = ResolversParentTypes['SavedSearch']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notificationDays?: Resolver<Array<Maybe<ResolversTypes['WeekDay']>>, ParentType, ContextType>;
  notificationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notificationTimes?: Resolver<Array<Maybe<ResolversTypes['ScheduledTime']>>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchQuery?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedSearchResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedSearchResponse'] = ResolversParentTypes['SavedSearchResponse']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledTime'] = ResolversParentTypes['ScheduledTime']> = {
  hour?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Scope'] = ResolversParentTypes['Scope']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ScopeType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchDocument'] = ResolversParentTypes['SearchDocument']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['SearchUser']>, ParentType, ContextType>;
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['SearchUser']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  folderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hasPreviousVersionQr?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isExpected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lockedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lockedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  lockType?: Resolver<Maybe<ResolversTypes['LockType']>, ParentType, ContextType>;
  metaDataInstance?: Resolver<Maybe<ResolversTypes['MetaDataInstance']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pathIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['SearchProject']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qrCodeState?: Resolver<Maybe<ResolversTypes['StampStatus']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['FinalizationState']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['SearchUser']>, ParentType, ContextType>;
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  versionNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  versionState?: Resolver<Maybe<ResolversTypes['DocumentVersionState']>, ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  task?: Resolver<Maybe<Array<Maybe<ResolversTypes['QCSearchTask']>>>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  templateIconProps?: Resolver<Maybe<ResolversTypes['TemplateIconProps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchDocumentMetaQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchDocumentMetaQuestion'] = ResolversParentTypes['SearchDocumentMetaQuestion']> = {
  fieldDefinitionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valueType?: Resolver<Maybe<ResolversTypes['ValueTypeName']>, ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchProject'] = ResolversParentTypes['SearchProject']> = {
  projectNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchQcInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchQCInstance'] = ResolversParentTypes['SearchQCInstance']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnswerType']>>>, ParentType, ContextType>;
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  iconName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['FieldDefinition']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  task?: Resolver<Maybe<Array<Maybe<ResolversTypes['QCSearchTask']>>>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  metaDataInstance?: Resolver<Maybe<ResolversTypes['MetaDataInstance']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['QualityControlInstanceType'], ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  visualContextId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['FinalizationState']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  x?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  y?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isOptimistic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  templates?: Resolver<Maybe<Array<Maybe<ResolversTypes['QCTemplate']>>>, ParentType, ContextType>;
  linkedSnags?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormLinkedSnag']>>>, ParentType, ContextType>;
  templateIconProps?: Resolver<Maybe<ResolversTypes['TemplateIconProps']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResponse'] = ResolversParentTypes['SearchResponse']> = {
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  searchResults?: Resolver<Maybe<Array<ResolversTypes['SearchResult']>>, ParentType, ContextType>;
  facetGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['FacetGroup']>>>, ParentType, ContextType>;
  requestBody?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  debugInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']> = {
  __resolveType: TypeResolveFn<'SearchDocument' | 'SearchQCInstance', ParentType, ContextType>;
};

export type SearchUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchUser'] = ResolversParentTypes['SearchUser']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendShareLinkResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendShareLinkResponse'] = ResolversParentTypes['SendShareLinkResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetProjectPermissionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetProjectPermissionsResponse'] = ResolversParentTypes['SetProjectPermissionsResponse']> = {
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetTbqResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetTBQResponse'] = ResolversParentTypes['SetTBQResponse']> = {
  nodeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharedAttachmentDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SharedAttachmentDataResponse'] = ResolversParentTypes['SharedAttachmentDataResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  linkType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  scopeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  zipDownloadUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageAttachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['SharedAttachments']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharedAttachmentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SharedAttachments'] = ResolversParentTypes['SharedAttachments']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  downloadUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharedLinkDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SharedLinkDataResponse'] = ResolversParentTypes['SharedLinkDataResponse']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  documentLinks?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  documentVersions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  latestDocumentVersions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentVersion']>>>, ParentType, ContextType>;
  linkType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  scopeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  showFolders?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  zipDownloadUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmartFolderResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmartFolder'] = ResolversParentTypes['SmartFolder']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<Maybe<ResolversTypes['Filter']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SnagstreamConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SnagstreamConnection'] = ResolversParentTypes['SnagstreamConnection']> = {
  connectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StampResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stamp'] = ResolversParentTypes['Stamp']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  versionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  originalFileId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  originalFileSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fileId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  offsetX?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  offsetY?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  scaleX?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  scaleY?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['StampType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StampDocumentResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StampDocumentResponse'] = ResolversParentTypes['StampDocumentResponse']> = {
  documentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StampsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StampsResponse'] = ResolversParentTypes['StampsResponse']> = {
  data?: Resolver<Array<Maybe<ResolversTypes['Stamp']>>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateResolvers<ContextType = any, ParentType extends ResolversParentTypes['State'] = ResolversParentTypes['State']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateBuild12Resolvers<ContextType = any, ParentType extends ResolversParentTypes['StateBuild12'] = ResolversParentTypes['StateBuild12']> = {
  connections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Build12Connection']>>>, ParentType, ContextType>;
  oldConnections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Build12Connection']>>>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['Build12State'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateSnagstreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['StateSnagstream'] = ResolversParentTypes['StateSnagstream']> = {
  connections?: Resolver<Maybe<Array<Maybe<ResolversTypes['SnagstreamConnection']>>>, ParentType, ContextType>;
  oldConnections?: Resolver<Maybe<Array<Maybe<ResolversTypes['SnagstreamConnection']>>>, ParentType, ContextType>;
  state?: Resolver<ResolversTypes['SnagstreamState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubFolderPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubFolderPermission'] = ResolversParentTypes['SubFolderPermission']> = {
  permission?: Resolver<Maybe<ResolversTypes['FolderPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  _empty?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "_empty", ParentType, ContextType>;
  taskChange?: SubscriptionResolver<Maybe<ResolversTypes['Task']>, "taskChange", ParentType, ContextType, RequireFields<SubscriptionTaskChangeArgs, 'id'>>;
};

export type SubscriptionLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLevel'] = ResolversParentTypes['SubscriptionLevel']> = {
  iconName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPopular?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSelectable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTrial?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  permissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  pricing?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionLevelsDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionLevelsDataResponse'] = ResolversParentTypes['SubscriptionLevelsDataResponse']> = {
  subscriptionLevels?: Resolver<Array<ResolversTypes['SubscriptionLevel']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionOrganization'] = ResolversParentTypes['SubscriptionOrganization']> = {
  billingEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  nextBillingCycle?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  nextSubscriptionLevelId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  paymentInfo?: Resolver<Maybe<ResolversTypes['PaymentInfo']>, ParentType, ContextType>;
  subscriptionEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  subscriptionLevelId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionOrganizationDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionOrganizationDataResponse'] = ResolversParentTypes['SubscriptionOrganizationDataResponse']> = {
  subscriptionOrganization?: Resolver<ResolversTypes['SubscriptionOrganization'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionOrganizationExpireDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionOrganizationExpireDataResponse'] = ResolversParentTypes['SubscriptionOrganizationExpireDataResponse']> = {
  expireDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionOrganizationUsersDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionOrganizationUsersDataResponse'] = ResolversParentTypes['SubscriptionOrganizationUsersDataResponse']> = {
  isFixedPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pricePerUnit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  users?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedAnswersBasedOnImageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestedAnswersBasedOnImageResponse'] = ResolversParentTypes['SuggestedAnswersBasedOnImageResponse']> = {
  instanceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  suggestedAnswers?: Resolver<Array<ResolversTypes['AnswerType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedTemplatesBasedOnImageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestedTemplatesBasedOnImageResponse'] = ResolversParentTypes['SuggestedTemplatesBasedOnImageResponse']> = {
  mediaFileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  suggestedTemplates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  otherTemplates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SupportSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportSettings'] = ResolversParentTypes['SupportSettings']> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQ'] = ResolversParentTypes['TBQ']> = {
  tbqScanTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  tbqTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  tbqModuleType?: Resolver<Maybe<ResolversTypes['TBQModuleType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQAnswer'] = ResolversParentTypes['TBQAnswer']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerClassString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerOption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  goalId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  goalName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operationGoalIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqBrandcheckMultiResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQBrandcheckMultiResult'] = ResolversParentTypes['TBQBrandcheckMultiResult']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  objectParentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  objectName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scanId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['TBQAnswer']>, ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['TBQCategory']>, ParentType, ContextType>;
  operationGoals?: Resolver<Array<ResolversTypes['TBQGoal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQCategory'] = ResolversParentTypes['TBQCategory']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQConfig'] = ResolversParentTypes['TBQConfig']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<ResolversTypes['TBQFieldOption']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqFieldOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQFieldOption'] = ResolversParentTypes['TBQFieldOption']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Maybe<Array<ResolversTypes['TBQOption']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqFieldValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQFieldValue'] = ResolversParentTypes['TBQFieldValue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['TBQValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqFunctionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQFunction'] = ResolversParentTypes['TBQFunction']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqGlobalScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQGlobalScope'] = ResolversParentTypes['TBQGlobalScope']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scopeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqGoalResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQGoal'] = ResolversParentTypes['TBQGoal']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqObjectConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQObjectConfig'] = ResolversParentTypes['TBQObjectConfig']> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<ResolversTypes['TBQFieldValue']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQOption'] = ResolversParentTypes['TBQOption']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQResult'] = ResolversParentTypes['TBQResult']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['TBQAnswer']>, ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['TBQCategory']>, ParentType, ContextType>;
  operationGoals?: Resolver<Array<ResolversTypes['TBQGoal']>, ParentType, ContextType>;
  instance?: Resolver<ResolversTypes['QualityControlInstance'], ParentType, ContextType>;
  shortcomings?: Resolver<Array<ResolversTypes['TBQAnswer']>, ParentType, ContextType>;
  tbqInstanceLinkedSnags?: Resolver<Maybe<Array<ResolversTypes['FormLinkedSnag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqRieResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQRieResult'] = ResolversParentTypes['TBQRieResult']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  section?: Resolver<ResolversTypes['RieSection'], ParentType, ContextType>;
  risc?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  result?: Resolver<ResolversTypes['RieResult'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  class?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scanId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instanceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionLinkedResolveTask?: Resolver<Maybe<ResolversTypes['Task']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqRieScanTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQRieScanType'] = ResolversParentTypes['TBQRieScanType']> = {
  entityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqScanTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQScanType'] = ResolversParentTypes['TBQScanType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQType'] = ResolversParentTypes['TBQType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TbqValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['TBQValue'] = ResolversParentTypes['TBQValue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  documentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  assignment?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  availableStatuses?: Resolver<Maybe<Array<ResolversTypes['TaskStatus']>>, ParentType, ContextType>;
  commentStatus?: Resolver<Maybe<ResolversTypes['CommentStatus']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deliverables?: Resolver<Maybe<Array<Maybe<ResolversTypes['Document']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentAction?: Resolver<Maybe<ResolversTypes['DocumentAction']>, ParentType, ContextType>;
  durationMinutes?: Resolver<Maybe<ResolversTypes['UInt']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  formTemplates?: Resolver<Maybe<Array<ResolversTypes['Template']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isOptimistic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  linkedFormInstances?: Resolver<Maybe<Array<ResolversTypes['QualityControlInstance']>>, ParentType, ContextType>;
  linkedSnagInstances?: Resolver<Maybe<Array<ResolversTypes['QualityControlInstance']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  procedureId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  snagTemplates?: Resolver<Maybe<Array<ResolversTypes['Template']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  statusOrder?: Resolver<Maybe<ResolversTypes['TaskStatusOrder']>, ParentType, ContextType>;
  taskCategoryId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  taskCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tbq?: Resolver<Maybe<ResolversTypes['TBQ']>, ParentType, ContextType>;
  tbqFormInstances?: Resolver<Maybe<Array<ResolversTypes['QualityControlInstance']>>, ParentType, ContextType>;
  tbqLinkedQuestions?: Resolver<Maybe<Array<ResolversTypes['ResolveTaskLinks']>>, ParentType, ContextType>;
  tbqTbqResolveTaskLinkedObjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType>;
  visualContexts?: Resolver<Maybe<Array<ResolversTypes['VisualContext']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskCategory'] = ResolversParentTypes['TaskCategory']> = {
  taskCategoryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  taskType?: Resolver<ResolversTypes['TaskType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskLink'] = ResolversParentTypes['TaskLink']> = {
  task?: Resolver<ResolversTypes['Task'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LinkType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskStatus'] = ResolversParentTypes['TaskStatus']> = {
  taskStatus?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  available?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  command?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStatusOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskStatusOrder'] = ResolversParentTypes['TaskStatusOrder']> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Status'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldDefinition']>>>, ParentType, ContextType, RequireFields<TemplateFieldsArgs, never>>;
  icon?: Resolver<Maybe<ResolversTypes['TemplateIcon']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['FieldDefinition']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['Scope']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['TemplateState']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TemplateType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateIconResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateIcon'] = ResolversParentTypes['TemplateIcon']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateIconPropsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateIconProps'] = ResolversParentTypes['TemplateIconProps']> = {
  iconName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iconType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  templateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThreeDConversionStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ThreeDConversionStatus'] = ResolversParentTypes['ThreeDConversionStatus']> = {
  versionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  progress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fileUrn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ConversionStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSpanResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSpan'] = ResolversParentTypes['TimeSpan']> = {
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt'], any> {
  name: 'UInt';
}

export type UnlinkLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnlinkLink'] = ResolversParentTypes['UnlinkLink']> = {
  linkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnreadMessages'] = ResolversParentTypes['UnreadMessages']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  all?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inbox?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UriScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Uri'], any> {
  name: 'Uri';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  explicitProjectMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  folderPermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['FolderPermission']>>>, ParentType, ContextType, RequireFields<UserFolderPermissionsArgs, never>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['Uri']>, ParentType, ContextType>;
  invited?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastOnlineAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  scope?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserQcPermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserQCPermissions'] = ResolversParentTypes['UserQCPermissions']> = {
  directPermissions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  inheritedPermissions?: Resolver<Maybe<Array<ResolversTypes['InheritedPermission']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValueTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValueType'] = ResolversParentTypes['ValueType']> = {
  hierarchyListId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  maxValue?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  minValue?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  multiSelect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  multipleAnswers?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  displayType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['ValueTypeName']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<ResolversTypes['MetaDataOption']>>, ParentType, ContextType>;
  rangeEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  staticBreadcrumbs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  subFields?: Resolver<Maybe<Array<ResolversTypes['FieldDefinition']>>, ParentType, ContextType>;
  subType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VersionMetaDataCreatedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VersionMetaDataCreatedResponse'] = ResolversParentTypes['VersionMetaDataCreatedResponse']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  instanceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisualContextResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisualContext'] = ResolversParentTypes['VisualContext']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  documentVersionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  drawing?: Resolver<Maybe<ResolversTypes['Drawing']>, ParentType, ContextType>;
  formCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  freeSnagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  instances?: Resolver<Maybe<Array<Maybe<ResolversTypes['VisualContextInstance']>>>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['Media']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  projectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  qualityControlInstances?: Resolver<Maybe<Array<ResolversTypes['QualityControlInstance']>>, ParentType, ContextType, RequireFields<VisualContextQualityControlInstancesArgs, 'projectId'>>;
  scope?: Resolver<Maybe<ResolversTypes['Scope']>, ParentType, ContextType>;
  snagCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['VisualContextStatus']>, ParentType, ContextType>;
  visualContextId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  zoomLevels?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisualContextInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisualContextInstance'] = ResolversParentTypes['VisualContextInstance']> = {
  instanceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  iconId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isValid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  indicativeState?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['Position']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisualContextMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisualContextMutationResponse'] = ResolversParentTypes['VisualContextMutationResponse']> = {
  visualContextId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  visualContext?: Resolver<Maybe<ResolversTypes['VisualContext']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateInstanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['updateInstance'] = ResolversParentTypes['updateInstance']> = {
  instanceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  visualContextId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  x?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AddExternalUsersResponse?: AddExternalUsersResponseResolvers<ContextType>;
  AddSignatureResponse?: AddSignatureResponseResolvers<ContextType>;
  AiSummary?: AiSummaryResolvers<ContextType>;
  Annotation?: AnnotationResolvers<ContextType>;
  AnswerType?: AnswerTypeResolvers<ContextType>;
  ApiKey?: ApiKeyResolvers<ContextType>;
  AppData?: AppDataResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  BatchDeleteTaskCategoryResponse?: BatchDeleteTaskCategoryResponseResolvers<ContextType>;
  BatchDocumentsResponse?: BatchDocumentsResponseResolvers<ContextType>;
  BatchMutation?: BatchMutationResolvers<ContextType>;
  BatchProjectsResponse?: BatchProjectsResponseResolvers<ContextType>;
  BatchResult?: BatchResultResolvers<ContextType>;
  BatchTaskCategoryResponse?: BatchTaskCategoryResponseResolvers<ContextType>;
  BatchUpdateTaskCategoryResponse?: BatchUpdateTaskCategoryResponseResolvers<ContextType>;
  Build12Connection?: Build12ConnectionResolvers<ContextType>;
  CancelSubscriptionDataResponse?: CancelSubscriptionDataResponseResolvers<ContextType>;
  ChangeSubscriptionDataResponse?: ChangeSubscriptionDataResponseResolvers<ContextType>;
  ClientDownloadUrls?: ClientDownloadUrlsResolvers<ContextType>;
  CommandOutput?: CommandOutputResolvers<ContextType>;
  CommandStatus?: CommandStatusResolvers<ContextType>;
  CommentThread?: CommentThreadResolvers<ContextType>;
  Condition?: ConditionResolvers<ContextType>;
  ConfigDataSegment?: ConfigDataSegmentResolvers<ContextType>;
  ConnectProjectResponse?: ConnectProjectResponseResolvers<ContextType>;
  Connection?: ConnectionResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CountryDataResponse?: CountryDataResponseResolvers<ContextType>;
  CreateShareLinkResponse?: CreateShareLinkResponseResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteInstanceResponse?: DeleteInstanceResponseResolvers<ContextType>;
  Document?: DocumentResolvers<ContextType>;
  DocumentDigest?: DocumentDigestResolvers<ContextType>;
  DocumentHistory?: DocumentHistoryResolvers<ContextType>;
  DocumentHistoryEvent?: DocumentHistoryEventResolvers<ContextType>;
  DocumentHistoryProperty?: DocumentHistoryPropertyResolvers<ContextType>;
  DocumentMetaData?: DocumentMetaDataResolvers<ContextType>;
  DocumentResponse?: DocumentResponseResolvers<ContextType>;
  DocumentVersion?: DocumentVersionResolvers<ContextType>;
  DocumentsResponse?: DocumentsResponseResolvers<ContextType>;
  DownloadFilenameCharacter?: DownloadFilenameCharacterResolvers<ContextType>;
  DownloadFilenameConfig?: DownloadFilenameConfigResolvers<ContextType>;
  Drawing?: DrawingResolvers<ContextType>;
  EntityFolderPermission?: EntityFolderPermissionResolvers<ContextType>;
  FacetGroup?: FacetGroupResolvers<ContextType>;
  FacetItem?: FacetItemResolvers<ContextType>;
  FacetItemMDField?: FacetItemMdFieldResolvers<ContextType>;
  FieldDefinition?: FieldDefinitionResolvers<ContextType>;
  FieldDefinitionType?: FieldDefinitionTypeResolvers<ContextType>;
  FileInformation?: FileInformationResolvers<ContextType>;
  FilesExistsBuild12?: FilesExistsBuild12Resolvers<ContextType>;
  FilesExistsSnagstream?: FilesExistsSnagstreamResolvers<ContextType>;
  Filter?: FilterResolvers<ContextType>;
  Folder?: FolderResolvers<ContextType>;
  FolderChangeResponse?: FolderChangeResponseResolvers<ContextType>;
  FolderDuplicationResponse?: FolderDuplicationResponseResolvers<ContextType>;
  FolderPermissionsChangeResponse?: FolderPermissionsChangeResponseResolvers<ContextType>;
  FoldersChangeResponse?: FoldersChangeResponseResolvers<ContextType>;
  FormLinkedSnag?: FormLinkedSnagResolvers<ContextType>;
  GetSignature?: GetSignatureResolvers<ContextType>;
  GetSignatureData?: GetSignatureDataResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupPermission?: GroupPermissionResolvers<ContextType>;
  HierarchicalList?: HierarchicalListResolvers<ContextType>;
  Hierarchy?: HierarchyResolvers<ContextType>;
  HierarchyDummy?: HierarchyDummyResolvers<ContextType>;
  InheritedPermission?: InheritedPermissionResolvers<ContextType>;
  Instance?: InstanceResolvers<ContextType>;
  Integration?: IntegrationResolvers<ContextType>;
  IntegrationProject?: IntegrationProjectResolvers<ContextType>;
  IntegrationWithConnection?: IntegrationWithConnectionResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JobOutput?: JobOutputResolvers<ContextType>;
  Keyword?: KeywordResolvers<ContextType>;
  LinkInfo?: LinkInfoResolvers<ContextType>;
  LinkResponse?: LinkResponseResolvers<ContextType>;
  LinkUnlinkDeviceResponse?: LinkUnlinkDeviceResponseResolvers<ContextType>;
  Long?: GraphQLScalarType;
  MarkMessageResponse?: MarkMessageResponseResolvers<ContextType>;
  Me?: MeResolvers<ContextType>;
  MeOrganization?: MeOrganizationResolvers<ContextType>;
  MeProject?: MeProjectResolvers<ContextType>;
  MeUser?: MeUserResolvers<ContextType>;
  Media?: MediaResolvers<ContextType>;
  Member?: MemberResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessageBranch?: MessageBranchResolvers<ContextType>;
  MetaData?: MetaDataResolvers<ContextType>;
  MetaDataInstance?: MetaDataInstanceResolvers<ContextType>;
  MetaDataOption?: MetaDataOptionResolvers<ContextType>;
  MoveUserModel?: MoveUserModelResolvers<ContextType>;
  MutateAddCommentResponse?: MutateAddCommentResponseResolvers<ContextType>;
  MutateDeleteCommentResponse?: MutateDeleteCommentResponseResolvers<ContextType>;
  MutateFolderResponse?: MutateFolderResponseResolvers<ContextType>;
  MutateGroupResponse?: MutateGroupResponseResolvers<ContextType>;
  MutateMetaDataResponse?: MutateMetaDataResponseResolvers<ContextType>;
  MutateQualityControlInstanceResponse?: MutateQualityControlInstanceResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NotificationSettings?: NotificationSettingsResolvers<ContextType>;
  Notifications?: NotificationsResolvers<ContextType>;
  NotificationsUser?: NotificationsUserResolvers<ContextType>;
  NumberProps?: NumberPropsResolvers<ContextType>;
  ObjectNode?: ObjectNodeResolvers<ContextType>;
  ObjectsResponse?: ObjectsResponseResolvers<ContextType>;
  OfficeOnlineInfo?: OfficeOnlineInfoResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationSettings?: OrganizationSettingsResolvers<ContextType>;
  OsidDeviceLinkStateResponse?: OsidDeviceLinkStateResponseResolvers<ContextType>;
  OsidDeviceLog?: OsidDeviceLogResolvers<ContextType>;
  OsidDevicesResponse?: OsidDevicesResponseResolvers<ContextType>;
  OsidDevicesType?: OsidDevicesTypeResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  ParentFolder?: ParentFolderResolvers<ContextType>;
  ParentFolderPermission?: ParentFolderPermissionResolvers<ContextType>;
  PaymentInfo?: PaymentInfoResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  PhaseBuild12?: PhaseBuild12Resolvers<ContextType>;
  Position?: PositionResolvers<ContextType>;
  Procedure?: ProcedureResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectByFolderResponse?: ProjectByFolderResponseResolvers<ContextType>;
  ProjectLinkResponse?: ProjectLinkResponseResolvers<ContextType>;
  ProjectRoles?: ProjectRolesResolvers<ContextType>;
  ProjectSettings?: ProjectSettingsResolvers<ContextType>;
  ProjectUnread?: ProjectUnreadResolvers<ContextType>;
  QCInstanceHistory?: QcInstanceHistoryResolvers<ContextType>;
  QCSearchTask?: QcSearchTaskResolvers<ContextType>;
  QCTemplate?: QcTemplateResolvers<ContextType>;
  QcHistoryEvent?: QcHistoryEventResolvers<ContextType>;
  QcHistoryProperty?: QcHistoryPropertyResolvers<ContextType>;
  QualityControlInstance?: QualityControlInstanceResolvers<ContextType>;
  QualityControlTaskInstance?: QualityControlTaskInstanceResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Reference?: ReferenceResolvers<ContextType>;
  RemoveStampsResponse?: RemoveStampsResponseResolvers<ContextType>;
  ReportConfiguration?: ReportConfigurationResolvers<ContextType>;
  ReportImage?: ReportImageResolvers<ContextType>;
  ResolveTaskLinks?: ResolveTaskLinksResolvers<ContextType>;
  ResourcePermissions?: ResourcePermissionsResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  RestoreInstanceData?: RestoreInstanceDataResolvers<ContextType>;
  RestoreInstanceResponse?: RestoreInstanceResponseResolvers<ContextType>;
  RevokeLinkResponse?: RevokeLinkResponseResolvers<ContextType>;
  RieCondition?: RieConditionResolvers<ContextType>;
  RieOption?: RieOptionResolvers<ContextType>;
  RieResult?: RieResultResolvers<ContextType>;
  RieSection?: RieSectionResolvers<ContextType>;
  RieSubField?: RieSubFieldResolvers<ContextType>;
  RieValueType?: RieValueTypeResolvers<ContextType>;
  RieValueTypeSection?: RieValueTypeSectionResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  SavedAnswers?: SavedAnswersResolvers<ContextType>;
  SavedAnswersInstance?: SavedAnswersInstanceResolvers<ContextType>;
  SavedSearch?: SavedSearchResolvers<ContextType>;
  SavedSearchResponse?: SavedSearchResponseResolvers<ContextType>;
  ScheduledTime?: ScheduledTimeResolvers<ContextType>;
  Scope?: ScopeResolvers<ContextType>;
  SearchDocument?: SearchDocumentResolvers<ContextType>;
  SearchDocumentMetaQuestion?: SearchDocumentMetaQuestionResolvers<ContextType>;
  SearchProject?: SearchProjectResolvers<ContextType>;
  SearchQCInstance?: SearchQcInstanceResolvers<ContextType>;
  SearchResponse?: SearchResponseResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  SearchUser?: SearchUserResolvers<ContextType>;
  SendShareLinkResponse?: SendShareLinkResponseResolvers<ContextType>;
  SetProjectPermissionsResponse?: SetProjectPermissionsResponseResolvers<ContextType>;
  SetTBQResponse?: SetTbqResponseResolvers<ContextType>;
  SharedAttachmentDataResponse?: SharedAttachmentDataResponseResolvers<ContextType>;
  SharedAttachments?: SharedAttachmentsResolvers<ContextType>;
  SharedLinkDataResponse?: SharedLinkDataResponseResolvers<ContextType>;
  SmartFolder?: SmartFolderResolvers<ContextType>;
  SnagstreamConnection?: SnagstreamConnectionResolvers<ContextType>;
  Stamp?: StampResolvers<ContextType>;
  StampDocumentResponse?: StampDocumentResponseResolvers<ContextType>;
  StampsResponse?: StampsResponseResolvers<ContextType>;
  State?: StateResolvers<ContextType>;
  StateBuild12?: StateBuild12Resolvers<ContextType>;
  StateSnagstream?: StateSnagstreamResolvers<ContextType>;
  SubFolderPermission?: SubFolderPermissionResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionLevel?: SubscriptionLevelResolvers<ContextType>;
  SubscriptionLevelsDataResponse?: SubscriptionLevelsDataResponseResolvers<ContextType>;
  SubscriptionOrganization?: SubscriptionOrganizationResolvers<ContextType>;
  SubscriptionOrganizationDataResponse?: SubscriptionOrganizationDataResponseResolvers<ContextType>;
  SubscriptionOrganizationExpireDataResponse?: SubscriptionOrganizationExpireDataResponseResolvers<ContextType>;
  SubscriptionOrganizationUsersDataResponse?: SubscriptionOrganizationUsersDataResponseResolvers<ContextType>;
  SuggestedAnswersBasedOnImageResponse?: SuggestedAnswersBasedOnImageResponseResolvers<ContextType>;
  SuggestedTemplatesBasedOnImageResponse?: SuggestedTemplatesBasedOnImageResponseResolvers<ContextType>;
  SupportSettings?: SupportSettingsResolvers<ContextType>;
  TBQ?: TbqResolvers<ContextType>;
  TBQAnswer?: TbqAnswerResolvers<ContextType>;
  TBQBrandcheckMultiResult?: TbqBrandcheckMultiResultResolvers<ContextType>;
  TBQCategory?: TbqCategoryResolvers<ContextType>;
  TBQConfig?: TbqConfigResolvers<ContextType>;
  TBQFieldOption?: TbqFieldOptionResolvers<ContextType>;
  TBQFieldValue?: TbqFieldValueResolvers<ContextType>;
  TBQFunction?: TbqFunctionResolvers<ContextType>;
  TBQGlobalScope?: TbqGlobalScopeResolvers<ContextType>;
  TBQGoal?: TbqGoalResolvers<ContextType>;
  TBQObjectConfig?: TbqObjectConfigResolvers<ContextType>;
  TBQOption?: TbqOptionResolvers<ContextType>;
  TBQResult?: TbqResultResolvers<ContextType>;
  TBQRieResult?: TbqRieResultResolvers<ContextType>;
  TBQRieScanType?: TbqRieScanTypeResolvers<ContextType>;
  TBQScanType?: TbqScanTypeResolvers<ContextType>;
  TBQType?: TbqTypeResolvers<ContextType>;
  TBQValue?: TbqValueResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  TaskCategory?: TaskCategoryResolvers<ContextType>;
  TaskLink?: TaskLinkResolvers<ContextType>;
  TaskStatus?: TaskStatusResolvers<ContextType>;
  TaskStatusOrder?: TaskStatusOrderResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateIcon?: TemplateIconResolvers<ContextType>;
  TemplateIconProps?: TemplateIconPropsResolvers<ContextType>;
  ThreeDConversionStatus?: ThreeDConversionStatusResolvers<ContextType>;
  TimeSpan?: TimeSpanResolvers<ContextType>;
  Token?: TokenResolvers<ContextType>;
  UInt?: GraphQLScalarType;
  UnlinkLink?: UnlinkLinkResolvers<ContextType>;
  UnreadMessages?: UnreadMessagesResolvers<ContextType>;
  Uri?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserQCPermissions?: UserQcPermissionsResolvers<ContextType>;
  ValueType?: ValueTypeResolvers<ContextType>;
  VersionMetaDataCreatedResponse?: VersionMetaDataCreatedResponseResolvers<ContextType>;
  VisualContext?: VisualContextResolvers<ContextType>;
  VisualContextInstance?: VisualContextInstanceResolvers<ContextType>;
  VisualContextMutationResponse?: VisualContextMutationResponseResolvers<ContextType>;
  updateInstance?: UpdateInstanceResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export const CommentFragmentFragmentDoc = gql`
    fragment CommentFragment on Message {
  attachments {
    fileId
    fileName
    fileSize
  }
  body
  createdAt
  createdBy {
    id
    displayName
    firstName
    lastName
    email
  }
  deleted
  fromId
  id
  read
  references {
    referenceId
    referenceKind
    referenceType
    referenceValue
    referenceData
  }
  threadId
  resolved
  updatedAt
}
    `;
export const MessageFragmentFragmentDoc = gql`
    fragment MessageFragment on Message {
  attachments {
    fileId
    fileName
    fileSize
  }
  body
  bcc
  cc
  createdAt
  createdBy {
    id
    displayName
    firstName
    lastName
    email
  }
  deleted
  fromId
  id
  parentId
  read
  references {
    referenceId
    referenceKind
    referenceType
    referenceValue
    referenceData
  }
  subject
  threadId
  to
  resolved
  updatedAt
  messageType
}
    `;
export const StampFragmentFragmentDoc = gql`
    fragment StampFragment on Stamp {
  id
  versionId
  offsetX
  offsetY
  scaleX
  scaleY
  type
}
    `;
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on Document {
  amountOfVersions
  commentStatus
  createdAt
  createdBy {
    id
    displayName
    organization {
      name
    }
  }
  downloadName
  expectedExtension
  extension
  folderId
  hasPreviousVersionQr
  id
  isExpected
  lastUpdated
  lockedAt
  lockedBy {
    id
    displayName
  }
  lockType
  metadataInstanceId
  name
  path
  pathIds
  projectId
  qrCodeState
  state
  stateBuild12 {
    oldConnections {
      connectionId
      connectionName
      phaseName
    }
    connections {
      connectionId
      connectionName
      phaseName
    }
    state
  }
  stateSnagstream {
    oldConnections {
      connectionId
      connectionName
    }
    connections {
      connectionId
      connectionName
    }
    state
  }
  tags {
    id
    name
  }
  threeDConversionState {
    status
  }
  updatedAt
  updatedBy {
    id
    displayName
    organization {
      name
    }
  }
  versionId
  versionNumber
  versionState
}
    `;
export const DocumentMetaDataFragmentFragmentDoc = gql`
    fragment DocumentMetaDataFragment on Document {
  metaData {
    isValid
    answers {
      fieldDefinitionId
      value
    }
  }
}
    `;
export const DocumentVersionMetaDataFragmentFragmentDoc = gql`
    fragment DocumentVersionMetaDataFragment on DocumentVersion {
  metaData {
    isValid
    answers {
      fieldDefinitionId
      value
    }
  }
}
    `;
export const VersionFragmentFragmentDoc = gql`
    fragment VersionFragment on DocumentVersion {
  commentStatus
  createdAt
  createdBy {
    id
    displayName
  }
  documentId
  downloadName
  ...DocumentVersionMetaDataFragment
  extension
  fileSize
  folderId
  hasPreviousVersionQr
  id
  isExpected
  metadataInstanceId
  md5Hash
  name
  qrCodeState
  state
  stateBuild12 {
    connections {
      connectionId
      connectionName
      phaseName
    }
    state
  }
  stateSnagstream {
    connections {
      connectionId
      connectionName
    }
    state
  }
  tags {
    id
    name
  }
  threeDConversionState {
    status
  }
  totalPreviewPages
  updatedAt
  updatedBy {
    id
    displayName
  }
  versionNumber
  versionState
}
    ${DocumentVersionMetaDataFragmentFragmentDoc}`;
export const DocumentVersionsFragmentFragmentDoc = gql`
    fragment DocumentVersionsFragment on Document {
  versions {
    ...VersionFragment
  }
}
    ${VersionFragmentFragmentDoc}`;
export const VersionFragmentIncludeFragmentDoc = gql`
    fragment VersionFragmentInclude on DocumentVersion {
  fileSize @include(if: $includeFileSize)
  documentId @include(if: $includeDocumentId)
  name @include(if: $includeName)
  path @include(if: $includePath)
  projectId @include(if: $includeProjectId)
  folderId @include(if: $includeFolderId)
  qrCodeState @include(if: $includeQrCodeState)
  id
}
    `;
export const FolderFragmentFragmentDoc = gql`
    fragment FolderFragment on Folder {
  id
  createdAt
  createdBy {
    id
    displayName
  }
  hasSubFolders
  isPrivate
  inheritParentMetadata
  metadataSetId
  name
  parentFolderId
  path
  projectId
}
    `;
export const FolderMetaDataFragmentFragmentDoc = gql`
    fragment FolderMetaDataFragment on Folder {
  template {
    id
    name
    fields(draft: false) {
      displayName
      fieldDefinitionId
      id
      name
      required
      type
      valueType {
        id
        maxValue
        minValue
        multiSelect
        options {
          name
          color
        }
        rangeEnabled
        subType
      }
    }
  }
}
    `;
export const SmartFolderFragmentFragmentDoc = gql`
    fragment SmartFolderFragment on SmartFolder {
  id
  filters {
    type
    property
    value
  }
  name
}
    `;
export const AnnotationFragmentFragmentDoc = gql`
    fragment AnnotationFragment on Annotation {
  id
  body
  createdAt
  userId
  versionId
  deletedAt
  deletedBy
}
    `;
export const EntityPermissionsFragmentFragmentDoc = gql`
    fragment EntityPermissionsFragment on EntityFolderPermission {
  id
  breakInheritance
  memberId
  folderPermissions
  user {
    active
    displayName
    id
  }
  group(forceOrganizationGroups: $forceOrganizationGroups) {
    displayName
    id
  }
}
    `;
export const InheritedPermissionsFragmentFragmentDoc = gql`
    fragment InheritedPermissionsFragment on EntityFolderPermission {
  inheritedFrom {
    groups
    permission
  }
  inheritedFromParentFolders {
    parentFolder {
      id
      name
    }
    permission
  }
  subFoldersPermissions {
    permission
  }
}
    `;
export const SharedLinkFragmentFragmentDoc = gql`
    fragment SharedLinkFragment on SharedLinkDataResponse {
  createdAt
  createdBy
  documentVersions {
    id
    name
    documentId
    downloadUrl
    extension
    fileSize
    folderId
    path
    pathIds
  }
  expirationDate
  id
  latestDocumentVersions {
    id
    name
    documentId
    downloadUrl
    extension
    fileSize
    folderId
    path
    pathIds
  }
  linkType
  scopeId
  showFolders
  zipDownloadUrl
}
    `;
export const DocumentHistoryEventFragmentFragmentDoc = gql`
    fragment DocumentHistoryEventFragment on DocumentHistoryEvent {
  changeDate
  resourceId
  historyId
  name
  properties {
    addedValues
    addedResolvedValues
    changeDate
    deletedValues
    deletedResolvedValues
    resourceId
    historyId
    name
    type
  }
  userId
  versionNumber
  versionId
  user {
    id
    displayName
  }
}
    `;
export const MeFragmentFragmentDoc = gql`
    fragment MeFragment on Me {
  organization {
    enforceSSO
    organizationId
    organizationName
    roles {
      id
      name
      permissions
    }
  }
  projects {
    projectId
    organizationId
    organizationName
    roles {
      id
      name
      permissions
    }
  }
  user {
    active
    authType
    displayName
    email
    firstName
    imageUrl
    jobTitle
    language
    lastName
    organizationId
    phoneNumber
    userflowSignature
    userId
  }
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  __typename
  id
  active
  displayName
  email
  lastOnlineAt
  firstName
  isAdmin
  lastName
  invited
  organization {
    id
    name
  }
  organizationId
  scope
  state
}
    `;
export const GroupFragmentFragmentDoc = gql`
    fragment GroupFragment on Group {
  __typename
  id
  createdAt
  createdBy {
    id
    displayName
  }
  displayName
  members {
    ... on Group {
      id
      displayName
    }
    ... on User {
      ...UserFragment
    }
  }
  name
  scope
  subgroups {
    __typename
    id
    displayName
  }
}
    ${UserFragmentFragmentDoc}`;
export const FragmentGroupIncludeFragmentDoc = gql`
    fragment FragmentGroupInclude on Group {
  createdAt @include(if: $includeCreatedAt)
  createdBy @include(if: $includeCreatedBy) {
    displayName
    id
  }
  displayName
  id
  memberIds @include(if: $includeMemberIds)
  members @include(if: $includeMembers) {
    ... on Group {
      __typename
      displayName
      id
    }
    ... on User {
      __typename
      displayName
      id
    }
  }
  membersCount @include(if: $includeMembersCount)
  subgroups @include(if: $includeSubgroups) {
    displayName
    id
  }
  subgroupIds @include(if: $includeSubgroupIds)
  organizationId @include(if: $includeOrganizationId)
  explicitProjectMember @include(if: $includeExplicitProjectMember)
}
    `;
export const FragmentProjectMemberGroupIncludeFragmentDoc = gql`
    fragment FragmentProjectMemberGroupInclude on Group {
  displayName
  id
  roles @include(if: $includeRoles) {
    id
    name
    permissions
  }
}
    `;
export const FragmentUserIncludeFragmentDoc = gql`
    fragment FragmentUserInclude on User {
  active @include(if: $includeActive)
  displayName
  email @include(if: $includeEmail)
  id
  invited @include(if: $includeInvited)
  isAdmin @include(if: $includeIsAdmin)
  jobTitle @include(if: $includeJobTitle)
  lastOnlineAt @include(if: $includeLastOnlineAt)
  organization @include(if: $includeOrganization) {
    id
    name
  }
  phoneNumber @include(if: $includePhoneNumber)
  roles @include(if: $includeRoles) {
    id
    name
    permissions
  }
  state @include(if: $includeState)
  explicitProjectMember @include(if: $includeExplicitProjectMember)
}
    `;
export const ValueTypeFragmentFragmentDoc = gql`
    fragment ValueTypeFragment on ValueType {
  displayType
  hierarchyListId
  id
  maxValue
  minValue
  multipleAnswers
  multiSelect
  name
  options {
    name
    color
    index
  }
  rangeEnabled
  staticBreadcrumbs
}
    `;
export const SubFieldDefinitionPropsFragmentFragmentDoc = gql`
    fragment SubFieldDefinitionPropsFragment on FieldDefinition {
  id
  createdAt
  createdBy {
    id
    displayName
    organization {
      name
    }
  }
  description
  displayDescription
  displayName
  fieldDefinitionId
  linksAllowed
  name
  parentSectionId
  conditions {
    fieldId
    value
  }
  required
  indicative
  scope {
    id
    type
  }
  type
  valueType {
    hierarchyListId
    id
    maxValue
    minValue
    multipleAnswers
    multiSelect
    options {
      name
      color
      index
    }
    name
    rangeEnabled
    staticBreadcrumbs
  }
  value
}
    `;
export const RecursiveFieldDefinitionFragmentFragmentDoc = gql`
    fragment RecursiveFieldDefinitionFragment on FieldDefinition {
  valueType {
    ...ValueTypeFragment
    subFields {
      ...SubFieldDefinitionPropsFragment
      valueType {
        ...ValueTypeFragment
        subFields {
          ...SubFieldDefinitionPropsFragment
          valueType {
            ...ValueTypeFragment
            subFields {
              ...SubFieldDefinitionPropsFragment
              valueType {
                ...ValueTypeFragment
                subFields {
                  ...SubFieldDefinitionPropsFragment
                  valueType {
                    ...ValueTypeFragment
                    subFields {
                      ...SubFieldDefinitionPropsFragment
                      valueType {
                        ...ValueTypeFragment
                        subFields {
                          ...SubFieldDefinitionPropsFragment
                          valueType {
                            ...ValueTypeFragment
                            subFields {
                              ...SubFieldDefinitionPropsFragment
                              valueType {
                                ...ValueTypeFragment
                                subFields {
                                  ...SubFieldDefinitionPropsFragment
                                  valueType {
                                    ...ValueTypeFragment
                                    subFields {
                                      ...SubFieldDefinitionPropsFragment
                                      valueType {
                                        ...ValueTypeFragment
                                        subFields {
                                          ...SubFieldDefinitionPropsFragment
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ValueTypeFragmentFragmentDoc}
${SubFieldDefinitionPropsFragmentFragmentDoc}`;
export const FieldDefinitionPropsFragmentFragmentDoc = gql`
    fragment FieldDefinitionPropsFragment on FieldDefinition {
  id
  conditions {
    fieldId
    value
  }
  createdAt
  createdBy {
    id
    displayName
    organization {
      name
    }
  }
  description
  displayDescription
  displayName
  fieldDefinitionId
  indicative
  linksAllowed
  name
  parentSectionId
  required
  scope {
    id
    type
  }
  type
  value
  ...RecursiveFieldDefinitionFragment
}
    ${RecursiveFieldDefinitionFragmentFragmentDoc}`;
export const TemplatePropsFragmentFragmentDoc = gql`
    fragment TemplatePropsFragment on Template {
  id
  createdAt
  createdBy {
    id
    displayName
    organization {
      name
    }
  }
  name
  scope {
    id
    type
  }
  state
  type
  version
  items {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;
export const ObjectNodeFragmentFragmentDoc = gql`
    fragment ObjectNodeFragment on ObjectNode {
  id
  deletedAt
  index
  name
  organizationId
  parentNodeId
  projectId
  project {
    id
    name
    organizationId
    projectNumber
  }
  projectStatus
  tbqType {
    id
    name
  }
  tbqTypeAbstract
}
    `;
export const ResolveTaskLinksFragmentFragmentDoc = gql`
    fragment ResolveTaskLinksFragment on ResolveTaskLinks {
  appData
  direction
  id
  linkId
  linkType
  type
}
    `;
export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  assignment {
    id
    displayName
  }
  availableStatuses {
    taskStatus
    available
    command
  }
  commentStatus
  completedAt
  createdAt
  createdByUser {
    id
    displayName
  }
  description
  documentAction
  endTime
  id
  isOptimistic
  name
  procedureId
  project {
    id
    name
  }
  status
  taskCategoryId
  taskCategoryName
  tbq {
    tbqScanTypeId
    tbqTypeId
    tbqModuleType
  }
  tbqLinkedQuestions {
    ...ResolveTaskLinksFragment
  }
  tbqTbqResolveTaskLinkedObjectId
  type
}
    ${ResolveTaskLinksFragmentFragmentDoc}`;
export const ObjectNodeDetailIncludeFragmentDoc = gql`
    fragment ObjectNodeDetailInclude on ObjectNode {
  id
  projectId @include(if: $includeProjectId)
  tasks @include(if: $includeTasks) {
    ...TaskFragment
  }
  project @include(if: $includeProject) {
    id
    mainProcedure {
      id
    }
    name
    organizationId
    projectNumber
  }
}
    ${TaskFragmentFragmentDoc}`;
export const ObjectNodeDetailFragmentDoc = gql`
    fragment ObjectNodeDetail on ObjectNode {
  id
  createdAt
  deletedAt
  description
  index
  name
  organizationId
  parentNodeId
  projectId
  project {
    id
    mainProcedure {
      id
    }
    name
    organizationId
    projectNumber
  }
  projectStatus
  tbqFunction {
    id
    name
  }
  tbqFunctionAllowed {
    id
    name
  }
  tbqConfig {
    type
    fields {
      id
      name
      value {
        id
        name
      }
    }
  }
  tbqRieScanType {
    entityId
    name
    type
    id
  }
  tbqScanTypeAllowed {
    id
    name
  }
  tbqType {
    id
    name
  }
  tbqTypeAbstract
  tbqTypeAllowed {
    id
    name
  }
  tbqTypeAllowedAbstract
  tbqLatestTaskId
  tasks {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export const TbqAnswerPropsFragmentDoc = gql`
    fragment TBQAnswerProps on TBQAnswer {
  id
  answer
  answerClass
  answerClassString
  answerOption
  categoryId
  categoryName
  goalId
  goalName
  operationGoalIds
  question
  questionId
  reference
  value
  sectionText
  sectionId
}
    `;
export const DeviceLogFragmentFragmentDoc = gql`
    fragment DeviceLogFragment on OsidDeviceLog {
  id
  timestamp
  deviceCode
  eventType
  adres
  zone
  text
}
    `;
export const RieResultFragmentFragmentDoc = gql`
    fragment RieResultFragment on TBQRieResult {
  id
  section {
    id
    name
  }
  risc
  result {
    name
  }
  category
  location
  priority
  class
  taskId
  scanId
  instanceId
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  deadline
  description
  organizationId
  organization {
    expireDate
    id
    name
  }
  projectNumber
  startDate
}
    `;
export const ProjectDetailFragmentFragmentDoc = gql`
    fragment ProjectDetailFragment on Project {
  availableRoles {
    id
    name
  }
  mainProcedure {
    id
    description
    name
    projectId
    projectMainProcedure
  }
  settings {
    metadata_required
    version_name_instead_of_document_name_on_upload
    approve_action_label
    finalize_action_label
    reject_action_label
    ai_admin_settings
  }
  ...ProjectFragment
  organization {
    expireDate
    id
    name
    organizationSubscriptionLevel
  }
}
    ${ProjectFragmentFragmentDoc}`;
export const VisualContextFragmentFragmentDoc = gql`
    fragment VisualContextFragment on VisualContext {
  id
  createdAt
  createdBy {
    id
    displayName
  }
  height
  name
  status
  width
  zoomLevels
  pageCount
}
    `;
export const AnswerTypeFragmentFragmentDoc = gql`
    fragment AnswerTypeFragment on AnswerType {
  fieldId
  templateId
  templateVersion
  value
}
    `;
export const FieldDefinitionBasicFragmentFragmentDoc = gql`
    fragment FieldDefinitionBasicFragment on FieldDefinition {
  id
  indicative
  ...RecursiveFieldDefinitionFragment
}
    ${RecursiveFieldDefinitionFragmentFragmentDoc}`;
export const QualityControlInstanceForVisualContextFragmentDoc = gql`
    fragment QualityControlInstanceForVisualContext on QualityControlInstance {
  x
  y
  id
  answers {
    ...AnswerTypeFragment
  }
  items {
    ...FieldDefinitionBasicFragment
  }
  type
  name
  page
  indicativeState {
    index
    value
    color
  }
}
    ${AnswerTypeFragmentFragmentDoc}
${FieldDefinitionBasicFragmentFragmentDoc}`;
export const VisualContextMobileAppFragmentFragmentDoc = gql`
    fragment VisualContextMobileAppFragment on VisualContext {
  ...VisualContextFragment
  qualityControlInstances(resourceIds: $resourceIds, projectId: $projectId) {
    ...QualityControlInstanceForVisualContext
  }
  drawing {
    pages {
      pageNumber
      zoomLevels
      width
      height
    }
  }
}
    ${VisualContextFragmentFragmentDoc}
${QualityControlInstanceForVisualContextFragmentDoc}`;
export const QualityControlInstanceFragmentFragmentDoc = gql`
    fragment QualityControlInstanceFragment on QualityControlInstance {
  answers {
    ...AnswerTypeFragment
  }
  commentStatus
  createdAt
  createdBy {
    id
    displayName
  }
  deletedAt
  iconName
  id
  indicativeState {
    index
    value
    color
  }
  isOptimistic
  items {
    ...FieldDefinitionPropsFragment
  }
  name
  page
  projectId
  reference
  task {
    id
    procedureId
    name
    type
  }
  templateId
  templateIconProps {
    iconName
    iconType
    templateId
  }
  templateVersion
  type
  visualContext {
    id
    name
    deletedAt
    height
    width
  }
  visualContextId
  x
  y
}
    ${AnswerTypeFragmentFragmentDoc}
${FieldDefinitionPropsFragmentFragmentDoc}`;
export const ReportImageFragmentFragmentDoc = gql`
    fragment ReportImageFragment on ReportImage {
  id
  imageType
  name
  projectId
  deletedAt
}
    `;
export const FormLinkedSnagsFragmentFragmentDoc = gql`
    fragment FormLinkedSnagsFragment on FormLinkedSnag {
  id
  type
  linkId
  linkType
  direction
  appData {
    fieldId
  }
}
    `;
export const QcHistoryEventFragmentFragmentDoc = gql`
    fragment QCHistoryEventFragment on QcHistoryEvent {
  changeDate
  resourceId
  historyId
  name
  properties {
    addedValues
    addedResolvedValues
    changeDate
    deletedValues
    deletedResolvedValues
    resourceId
    historyId
    name
    type
  }
  userId
  user {
    id
    displayName
  }
}
    `;
export const SuggestedTemplateFragmentFragmentDoc = gql`
    fragment SuggestedTemplateFragment on Template {
  id
  name
  type
}
    `;
export const SuggestedTemplatesBasedOnImageResponseFragmentFragmentDoc = gql`
    fragment SuggestedTemplatesBasedOnImageResponseFragment on SuggestedTemplatesBasedOnImageResponse {
  mediaFileId
  suggestedTemplates {
    ...SuggestedTemplateFragment
  }
  otherTemplates {
    ...SuggestedTemplateFragment
  }
}
    ${SuggestedTemplateFragmentFragmentDoc}`;
export const SuggestedAnswersBasedOnImageResponseFragmentFragmentDoc = gql`
    fragment SuggestedAnswersBasedOnImageResponseFragment on SuggestedAnswersBasedOnImageResponse {
  instanceId
  suggestedAnswers {
    ...AnswerTypeFragment
  }
}
    ${AnswerTypeFragmentFragmentDoc}`;
export const FragmentSavedAnswersIncludeFragmentDoc = gql`
    fragment FragmentSavedAnswersInclude on SavedAnswers {
  answers @include(if: $includeAnswers) {
    fieldId
    value
  }
  createdOn @include(if: $includeCreatedOn)
  id
  name
  templateName @include(if: $includeTemplateName)
  type @include(if: $includeType)
}
    `;
export const FragmentSavedAnswersInstanceFragmentDoc = gql`
    fragment FragmentSavedAnswersInstance on SavedAnswersInstance {
  answers @include(if: $includeAnswers) {
    fieldId
    value
  }
  id
  items @include(if: $includeItems) {
    ...FieldDefinitionPropsFragment
  }
  name
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;
export const FragmentSavedAnswersOnTemplateIdFragmentDoc = gql`
    fragment FragmentSavedAnswersOnTemplateId on SavedAnswers {
  id
  name
}
    `;
export const FragmentReportConfigurationsIncludeFragmentDoc = gql`
    fragment FragmentReportConfigurationsInclude on ReportConfiguration {
  createdAt @include(if: $includeCreatedAt)
  createdBy @include(if: $includeCreatedBy) {
    id
    displayName
  }
  id
  isDefault @include(if: $includeIsDefault)
  name
  projectId @include(if: $includeProjectId)
  reportOptions @include(if: $includeReportOptions)
  scope @include(if: $includeScope)
  updatedAt @include(if: $includeUpdatedAt)
  updatedBy @include(if: $includeUpdatedBy) {
    id
    displayName
  }
}
    `;
export const FragmentReportConfigurationFragmentDoc = gql`
    fragment FragmentReportConfiguration on ReportConfiguration {
  createdAt
  createdBy {
    id
    displayName
  }
  id
  isDefault
  name
  projectId
  reportOptions
}
    `;
export const SearchDocumentFragmentFragmentDoc = gql`
    fragment SearchDocumentFragment on SearchDocument {
  createdAt
  createdBy {
    displayName
    id
    email
  }
  commentStatus
  extension
  folderId
  hasPreviousVersionQr
  id
  isExpected
  lockedAt
  lockedBy {
    id
    displayName
  }
  lockType
  name
  path
  pathIds
  project {
    projectNumber
    name
  }
  qrCodeState
  state
  task {
    name
    type
    status
    assignment {
      id
      displayName
    }
    id
    endTime
  }
  indicativeState {
    color
    index
    value
  }
  reference
  type
  updatedAt
  updatedBy {
    displayName
    email
  }
  versionId
  versionNumber
  versionState
  x
  y
  page
  visualContext {
    name
    id
    status
    visualContextId
    deletedAt
  }
  templateIconProps {
    iconName
    iconType
    templateId
  }
}
    `;
export const SearchDocumentMetaDataFragmentFragmentDoc = gql`
    fragment SearchDocumentMetaDataFragment on SearchDocument {
  metaDataInstance {
    questions {
      fieldDefinitionId
      answer
      displayName
      valueType
      templateId
      fieldId
    }
  }
}
    `;
export const LoadQueriesFragmentFragmentDoc = gql`
    fragment LoadQueriesFragment on SavedSearch {
  id
  name
  searchQuery
  searchFilter
  notificationDays
  notificationsEnabled
  notificationTimes {
    hour
    minute
  }
  projectId
  createdAt
  createdBy
  deletedAt
  deletedBy
  notificationDays
  notificationsEnabled
  notificationTimes {
    hour
    minute
  }
  scope
}
    `;
export const TbqRieResultFragmentFragmentDoc = gql`
    fragment TbqRieResultFragment on TBQRieResult {
  id
  section {
    id
    name
    type
    parentSectionId
    displayName
    index
    tagId
    valueType {
      subFields {
        name
        type
        parentSectionId
        id
        displayName
        description
        displayDescription
        index
        valueType {
          id
          name
        }
        linksAllowed
        required
        indicative
        tagId
        fieldDefinitionId
        fieldDefinitionDeleted
        value
      }
    }
    conditions {
      fieldId
      value
    }
  }
  risc
  result {
    name
    color
    index
  }
  category
  location
  priority
  class
  taskId
  scanId
  instanceId
}
    `;
export const LinkedDocumentFragmentFragmentDoc = gql`
    fragment LinkedDocumentFragment on Document {
  id
  name
  createdAt
  createdBy {
    id
    displayName
  }
  deletedAt
  expectedExtension
  extension
  folderId
  isExpected
  path
  projectId
  versionId
  versionNumber
}
    `;
export const QualityControlInstanceFragmentForTaskFragmentDoc = gql`
    fragment QualityControlInstanceFragmentForTask on QualityControlInstance {
  createdAt
  createdBy {
    id
    displayName
  }
  id
  name
  page
  reference
  templateIconProps {
    iconName
    iconType
    templateId
  }
  type
  visualContext {
    id
    name
  }
  visualContextId
}
    `;
export const TaskDetailFragmentFragmentDoc = gql`
    fragment TaskDetailFragment on Task {
  ...TaskFragment
  assignment {
    id
    displayName
  }
  attachments {
    ...LinkedDocumentFragment
  }
  availableStatuses {
    taskStatus
    available
    command
  }
  deliverables {
    ...LinkedDocumentFragment
  }
  description
  endTime
  formTemplates {
    icon {
      fileId
      name
      type
    }
    id
    name
    state
    type
  }
  id
  linkedFormInstances {
    ...QualityControlInstanceFragmentForTask
  }
  linkedSnagInstances {
    ...QualityControlInstanceFragmentForTask
  }
  name
  procedureId
  project {
    id
    name
    organizationId
    projectNumber
  }
  snagTemplates {
    icon {
      fileId
      name
      type
    }
    id
    name
    state
    type
  }
  status
  tbqFormInstances {
    ...QualityControlInstanceFragmentForTask
    items {
      ...FieldDefinitionPropsFragment
    }
  }
  tbqLinkedQuestions {
    ...ResolveTaskLinksFragment
  }
  type
  visualContexts {
    id
    name
    deletedAt
    createdAt
    createdBy {
      id
      displayName
    }
  }
}
    ${TaskFragmentFragmentDoc}
${LinkedDocumentFragmentFragmentDoc}
${QualityControlInstanceFragmentForTaskFragmentDoc}
${FieldDefinitionPropsFragmentFragmentDoc}
${ResolveTaskLinksFragmentFragmentDoc}`;
export const AddMessageDocument = gql`
    mutation AddMessage($attachments: [AttachmentInput], $body: String!, $id: ID!, $parentId: ID, $projectId: ID, $references: [ReferenceInput], $subject: String!, $threadId: ID) {
  addMessage(
    attachments: $attachments
    body: $body
    id: $id
    parentId: $parentId
    projectId: $projectId
    references: $references
    subject: $subject
    threadId: $threadId
  ) {
    ...MessageFragment
  }
}
    ${MessageFragmentFragmentDoc}`;
export type AddMessageMutationFn = Apollo.MutationFunction<AddMessageMutation, AddMessageMutationVariables>;

/**
 * __useAddMessageMutation__
 *
 * To run a mutation, you first call `useAddMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageMutation, { data, loading, error }] = useAddMessageMutation({
 *   variables: {
 *      attachments: // value for 'attachments'
 *      body: // value for 'body'
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *      projectId: // value for 'projectId'
 *      references: // value for 'references'
 *      subject: // value for 'subject'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useAddMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddMessageMutation, AddMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMessageMutation, AddMessageMutationVariables>(AddMessageDocument, options);
      }
export type AddMessageMutationHookResult = ReturnType<typeof useAddMessageMutation>;
export type AddMessageMutationResult = Apollo.MutationResult<AddMessageMutation>;
export type AddMessageMutationOptions = Apollo.BaseMutationOptions<AddMessageMutation, AddMessageMutationVariables>;
export const MarkMessageDocument = gql`
    mutation MarkMessage($messages: [MarkMessageInput]!, $read: Boolean!) {
  markMessage(messages: $messages, read: $read) {
    ids
    success
    errors
  }
}
    `;
export type MarkMessageMutationFn = Apollo.MutationFunction<MarkMessageMutation, MarkMessageMutationVariables>;

/**
 * __useMarkMessageMutation__
 *
 * To run a mutation, you first call `useMarkMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageMutation, { data, loading, error }] = useMarkMessageMutation({
 *   variables: {
 *      messages: // value for 'messages'
 *      read: // value for 'read'
 *   },
 * });
 */
export function useMarkMessageMutation(baseOptions?: Apollo.MutationHookOptions<MarkMessageMutation, MarkMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkMessageMutation, MarkMessageMutationVariables>(MarkMessageDocument, options);
      }
export type MarkMessageMutationHookResult = ReturnType<typeof useMarkMessageMutation>;
export type MarkMessageMutationResult = Apollo.MutationResult<MarkMessageMutation>;
export type MarkMessageMutationOptions = Apollo.BaseMutationOptions<MarkMessageMutation, MarkMessageMutationVariables>;
export const AddCommentDocument = gql`
    mutation AddComment($attachments: [AttachmentInput], $body: String!, $references: [ReferenceInput], $target: ReferenceInput!) {
  addComment(
    attachments: $attachments
    body: $body
    references: $references
    target: $target
  ) {
    ...CommentFragment
  }
}
    ${CommentFragmentFragmentDoc}`;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      attachments: // value for 'attachments'
 *      body: // value for 'body'
 *      references: // value for 'references'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const EditCommentDocument = gql`
    mutation EditComment($attachments: [AttachmentInput], $body: String, $createdAt: DateTime, $messageId: String, $references: [ReferenceInput], $target: ReferenceInput, $threadId: ID!) {
  editComment(
    attachments: $attachments
    body: $body
    createdAt: $createdAt
    messageId: $messageId
    references: $references
    target: $target
    threadId: $threadId
  ) {
    ...MessageFragment
  }
}
    ${MessageFragmentFragmentDoc}`;
export type EditCommentMutationFn = Apollo.MutationFunction<EditCommentMutation, EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      attachments: // value for 'attachments'
 *      body: // value for 'body'
 *      createdAt: // value for 'createdAt'
 *      messageId: // value for 'messageId'
 *      references: // value for 'references'
 *      target: // value for 'target'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<EditCommentMutation, EditCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID, $threadId: ID) {
  deleteComment(id: $id, threadId: $threadId) {
    id
    success
    errors
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const AddSignatureDocument = gql`
    mutation AddSignature($organizationId: ID, $userId: ID, $signatureBody: String!, $signatureId: ID) {
  addSignature(
    organizationId: $organizationId
    userId: $userId
    signatureBody: $signatureBody
    signatureId: $signatureId
  ) {
    success
  }
}
    `;
export type AddSignatureMutationFn = Apollo.MutationFunction<AddSignatureMutation, AddSignatureMutationVariables>;

/**
 * __useAddSignatureMutation__
 *
 * To run a mutation, you first call `useAddSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSignatureMutation, { data, loading, error }] = useAddSignatureMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      signatureBody: // value for 'signatureBody'
 *      signatureId: // value for 'signatureId'
 *   },
 * });
 */
export function useAddSignatureMutation(baseOptions?: Apollo.MutationHookOptions<AddSignatureMutation, AddSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSignatureMutation, AddSignatureMutationVariables>(AddSignatureDocument, options);
      }
export type AddSignatureMutationHookResult = ReturnType<typeof useAddSignatureMutation>;
export type AddSignatureMutationResult = Apollo.MutationResult<AddSignatureMutation>;
export type AddSignatureMutationOptions = Apollo.BaseMutationOptions<AddSignatureMutation, AddSignatureMutationVariables>;
export const MessageInboxDocument = gql`
    query MessageInbox($projectId: ID, $organizationId: ID, $sentOrInbox: SentOrInbox) {
  messageInbox(
    projectId: $projectId
    sentOrInbox: $sentOrInbox
    organizationId: $organizationId
  ) {
    ...MessageFragment
  }
}
    ${MessageFragmentFragmentDoc}`;

/**
 * __useMessageInboxQuery__
 *
 * To run a query within a React component, call `useMessageInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageInboxQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      organizationId: // value for 'organizationId'
 *      sentOrInbox: // value for 'sentOrInbox'
 *   },
 * });
 */
export function useMessageInboxQuery(baseOptions?: Apollo.QueryHookOptions<MessageInboxQuery, MessageInboxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageInboxQuery, MessageInboxQueryVariables>(MessageInboxDocument, options);
      }
export function useMessageInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageInboxQuery, MessageInboxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageInboxQuery, MessageInboxQueryVariables>(MessageInboxDocument, options);
        }
export type MessageInboxQueryHookResult = ReturnType<typeof useMessageInboxQuery>;
export type MessageInboxLazyQueryHookResult = ReturnType<typeof useMessageInboxLazyQuery>;
export type MessageInboxQueryResult = Apollo.QueryResult<MessageInboxQuery, MessageInboxQueryVariables>;
export const MessageBranchDocument = gql`
    query MessageBranch($messageId: ID!, $authorName: String, $projectId: ID, $replyId: ID, $replyAll: Boolean, $action: EmailActions, $resendId: ID) {
  messageBranch(
    messageId: $messageId
    authorName: $authorName
    projectId: $projectId
    replyAll: $replyAll
    replyId: $replyId
    action: $action
    resendId: $resendId
  ) {
    id
    main {
      ...MessageFragment
      messageType
    }
    original {
      ...MessageFragment
    }
    previous {
      ...MessageFragment
    }
    replyAll
    replyId
  }
}
    ${MessageFragmentFragmentDoc}`;

/**
 * __useMessageBranchQuery__
 *
 * To run a query within a React component, call `useMessageBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageBranchQuery({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      authorName: // value for 'authorName'
 *      projectId: // value for 'projectId'
 *      replyId: // value for 'replyId'
 *      replyAll: // value for 'replyAll'
 *      action: // value for 'action'
 *      resendId: // value for 'resendId'
 *   },
 * });
 */
export function useMessageBranchQuery(baseOptions: Apollo.QueryHookOptions<MessageBranchQuery, MessageBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageBranchQuery, MessageBranchQueryVariables>(MessageBranchDocument, options);
      }
export function useMessageBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageBranchQuery, MessageBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageBranchQuery, MessageBranchQueryVariables>(MessageBranchDocument, options);
        }
export type MessageBranchQueryHookResult = ReturnType<typeof useMessageBranchQuery>;
export type MessageBranchLazyQueryHookResult = ReturnType<typeof useMessageBranchLazyQuery>;
export type MessageBranchQueryResult = Apollo.QueryResult<MessageBranchQuery, MessageBranchQueryVariables>;
export const UnreadCountDocument = gql`
    query UnreadCount($isOrganization: Boolean) {
  unreadCount(isOrganization: $isOrganization) {
    id
    all
    inbox
    sent
  }
}
    `;

/**
 * __useUnreadCountQuery__
 *
 * To run a query within a React component, call `useUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadCountQuery({
 *   variables: {
 *      isOrganization: // value for 'isOrganization'
 *   },
 * });
 */
export function useUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<UnreadCountQuery, UnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadCountQuery, UnreadCountQueryVariables>(UnreadCountDocument, options);
      }
export function useUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadCountQuery, UnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadCountQuery, UnreadCountQueryVariables>(UnreadCountDocument, options);
        }
export type UnreadCountQueryHookResult = ReturnType<typeof useUnreadCountQuery>;
export type UnreadCountLazyQueryHookResult = ReturnType<typeof useUnreadCountLazyQuery>;
export type UnreadCountQueryResult = Apollo.QueryResult<UnreadCountQuery, UnreadCountQueryVariables>;
export const ProjectsUnreadCountDocument = gql`
    query ProjectsUnreadCount($isOrganization: Boolean) {
  projectsUnreadCount(isOrganization: $isOrganization) {
    id
    unreadCount
  }
}
    `;

/**
 * __useProjectsUnreadCountQuery__
 *
 * To run a query within a React component, call `useProjectsUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsUnreadCountQuery({
 *   variables: {
 *      isOrganization: // value for 'isOrganization'
 *   },
 * });
 */
export function useProjectsUnreadCountQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsUnreadCountQuery, ProjectsUnreadCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsUnreadCountQuery, ProjectsUnreadCountQueryVariables>(ProjectsUnreadCountDocument, options);
      }
export function useProjectsUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsUnreadCountQuery, ProjectsUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsUnreadCountQuery, ProjectsUnreadCountQueryVariables>(ProjectsUnreadCountDocument, options);
        }
export type ProjectsUnreadCountQueryHookResult = ReturnType<typeof useProjectsUnreadCountQuery>;
export type ProjectsUnreadCountLazyQueryHookResult = ReturnType<typeof useProjectsUnreadCountLazyQuery>;
export type ProjectsUnreadCountQueryResult = Apollo.QueryResult<ProjectsUnreadCountQuery, ProjectsUnreadCountQueryVariables>;
export const CommentThreadDocument = gql`
    query CommentThread($targetId: ID!) {
  commentThread(targetId: $targetId) {
    id
    targetId
    messages {
      ...CommentFragment
    }
  }
}
    ${CommentFragmentFragmentDoc}`;

/**
 * __useCommentThreadQuery__
 *
 * To run a query within a React component, call `useCommentThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentThreadQuery({
 *   variables: {
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useCommentThreadQuery(baseOptions: Apollo.QueryHookOptions<CommentThreadQuery, CommentThreadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentThreadQuery, CommentThreadQueryVariables>(CommentThreadDocument, options);
      }
export function useCommentThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentThreadQuery, CommentThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentThreadQuery, CommentThreadQueryVariables>(CommentThreadDocument, options);
        }
export type CommentThreadQueryHookResult = ReturnType<typeof useCommentThreadQuery>;
export type CommentThreadLazyQueryHookResult = ReturnType<typeof useCommentThreadLazyQuery>;
export type CommentThreadQueryResult = Apollo.QueryResult<CommentThreadQuery, CommentThreadQueryVariables>;
export const GetSignatureDocument = gql`
    query GetSignature {
  getSignature {
    data {
      signatureId
      userId
      organizationId
      body
      createdAt
      updatedAt
      deletedAt
    }
    success
  }
}
    `;

/**
 * __useGetSignatureQuery__
 *
 * To run a query within a React component, call `useGetSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatureQuery(baseOptions?: Apollo.QueryHookOptions<GetSignatureQuery, GetSignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSignatureQuery, GetSignatureQueryVariables>(GetSignatureDocument, options);
      }
export function useGetSignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSignatureQuery, GetSignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSignatureQuery, GetSignatureQueryVariables>(GetSignatureDocument, options);
        }
export type GetSignatureQueryHookResult = ReturnType<typeof useGetSignatureQuery>;
export type GetSignatureLazyQueryHookResult = ReturnType<typeof useGetSignatureLazyQuery>;
export type GetSignatureQueryResult = Apollo.QueryResult<GetSignatureQuery, GetSignatureQueryVariables>;
export const ConvertTo3DDocument = gql`
    mutation ConvertTo3D($versionId: ID!) {
  convertTo3D(versionId: $versionId)
}
    `;
export type ConvertTo3DMutationFn = Apollo.MutationFunction<ConvertTo3DMutation, ConvertTo3DMutationVariables>;

/**
 * __useConvertTo3DMutation__
 *
 * To run a mutation, you first call `useConvertTo3DMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertTo3DMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertTo3DMutation, { data, loading, error }] = useConvertTo3DMutation({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useConvertTo3DMutation(baseOptions?: Apollo.MutationHookOptions<ConvertTo3DMutation, ConvertTo3DMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertTo3DMutation, ConvertTo3DMutationVariables>(ConvertTo3DDocument, options);
      }
export type ConvertTo3DMutationHookResult = ReturnType<typeof useConvertTo3DMutation>;
export type ConvertTo3DMutationResult = Apollo.MutationResult<ConvertTo3DMutation>;
export type ConvertTo3DMutationOptions = Apollo.BaseMutationOptions<ConvertTo3DMutation, ConvertTo3DMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($document: DocumentCreate!) {
  createDocument(document: $document) {
    id
    errors
    success
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const MoveDocumentsDocument = gql`
    mutation MoveDocuments($documentIds: [ID!]!, $folderId: ID!) {
  moveDocuments(documentIds: $documentIds, folderId: $folderId) {
    success
  }
}
    `;
export type MoveDocumentsMutationFn = Apollo.MutationFunction<MoveDocumentsMutation, MoveDocumentsMutationVariables>;

/**
 * __useMoveDocumentsMutation__
 *
 * To run a mutation, you first call `useMoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentsMutation, { data, loading, error }] = useMoveDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useMoveDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<MoveDocumentsMutation, MoveDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveDocumentsMutation, MoveDocumentsMutationVariables>(MoveDocumentsDocument, options);
      }
export type MoveDocumentsMutationHookResult = ReturnType<typeof useMoveDocumentsMutation>;
export type MoveDocumentsMutationResult = Apollo.MutationResult<MoveDocumentsMutation>;
export type MoveDocumentsMutationOptions = Apollo.BaseMutationOptions<MoveDocumentsMutation, MoveDocumentsMutationVariables>;
export const MoveDocumentDocument = gql`
    mutation MoveDocument($moveDocumentData: MoveDocumentData!) {
  moveDocument(moveDocumentData: $moveDocumentData)
}
    `;
export type MoveDocumentMutationFn = Apollo.MutationFunction<MoveDocumentMutation, MoveDocumentMutationVariables>;

/**
 * __useMoveDocumentMutation__
 *
 * To run a mutation, you first call `useMoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentMutation, { data, loading, error }] = useMoveDocumentMutation({
 *   variables: {
 *      moveDocumentData: // value for 'moveDocumentData'
 *   },
 * });
 */
export function useMoveDocumentMutation(baseOptions?: Apollo.MutationHookOptions<MoveDocumentMutation, MoveDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveDocumentMutation, MoveDocumentMutationVariables>(MoveDocumentDocument, options);
      }
export type MoveDocumentMutationHookResult = ReturnType<typeof useMoveDocumentMutation>;
export type MoveDocumentMutationResult = Apollo.MutationResult<MoveDocumentMutation>;
export type MoveDocumentMutationOptions = Apollo.BaseMutationOptions<MoveDocumentMutation, MoveDocumentMutationVariables>;
export const PasteDocumentsDocument = gql`
    mutation PasteDocuments($documentIds: [PasteDocumentsDictionary!]!, $folderId: ID!) {
  pasteDocuments(documentIds: $documentIds, folderId: $folderId) {
    success
  }
}
    `;
export type PasteDocumentsMutationFn = Apollo.MutationFunction<PasteDocumentsMutation, PasteDocumentsMutationVariables>;

/**
 * __usePasteDocumentsMutation__
 *
 * To run a mutation, you first call `usePasteDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasteDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pasteDocumentsMutation, { data, loading, error }] = usePasteDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function usePasteDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<PasteDocumentsMutation, PasteDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasteDocumentsMutation, PasteDocumentsMutationVariables>(PasteDocumentsDocument, options);
      }
export type PasteDocumentsMutationHookResult = ReturnType<typeof usePasteDocumentsMutation>;
export type PasteDocumentsMutationResult = Apollo.MutationResult<PasteDocumentsMutation>;
export type PasteDocumentsMutationOptions = Apollo.BaseMutationOptions<PasteDocumentsMutation, PasteDocumentsMutationVariables>;
export const CreateDocumentsDocument = gql`
    mutation CreateDocuments($documents: [DocumentCreate!]!) {
  createDocuments(documents: $documents) {
    documents {
      id
    }
    errors
    success
  }
}
    `;
export type CreateDocumentsMutationFn = Apollo.MutationFunction<CreateDocumentsMutation, CreateDocumentsMutationVariables>;

/**
 * __useCreateDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentsMutation, { data, loading, error }] = useCreateDocumentsMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useCreateDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentsMutation, CreateDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentsMutation, CreateDocumentsMutationVariables>(CreateDocumentsDocument, options);
      }
export type CreateDocumentsMutationHookResult = ReturnType<typeof useCreateDocumentsMutation>;
export type CreateDocumentsMutationResult = Apollo.MutationResult<CreateDocumentsMutation>;
export type CreateDocumentsMutationOptions = Apollo.BaseMutationOptions<CreateDocumentsMutation, CreateDocumentsMutationVariables>;
export const UpdateDocumentsDocument = gql`
    mutation UpdateDocuments($documents: [DocumentUpdate!]!) {
  updateDocuments(documents: $documents)
}
    `;
export type UpdateDocumentsMutationFn = Apollo.MutationFunction<UpdateDocumentsMutation, UpdateDocumentsMutationVariables>;

/**
 * __useUpdateDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentsMutation, { data, loading, error }] = useUpdateDocumentsMutation({
 *   variables: {
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUpdateDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentsMutation, UpdateDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentsMutation, UpdateDocumentsMutationVariables>(UpdateDocumentsDocument, options);
      }
export type UpdateDocumentsMutationHookResult = ReturnType<typeof useUpdateDocumentsMutation>;
export type UpdateDocumentsMutationResult = Apollo.MutationResult<UpdateDocumentsMutation>;
export type UpdateDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentsMutation, UpdateDocumentsMutationVariables>;
export const DeleteDocumentsDocument = gql`
    mutation DeleteDocuments($documentIds: [ID!]!) {
  deleteDocuments(documentIds: $documentIds) {
    succeededIds
    errors
    failedIds
    success
  }
}
    `;
export type DeleteDocumentsMutationFn = Apollo.MutationFunction<DeleteDocumentsMutation, DeleteDocumentsMutationVariables>;

/**
 * __useDeleteDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentsMutation, { data, loading, error }] = useDeleteDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDeleteDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentsMutation, DeleteDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentsMutation, DeleteDocumentsMutationVariables>(DeleteDocumentsDocument, options);
      }
export type DeleteDocumentsMutationHookResult = ReturnType<typeof useDeleteDocumentsMutation>;
export type DeleteDocumentsMutationResult = Apollo.MutationResult<DeleteDocumentsMutation>;
export type DeleteDocumentsMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentsMutation, DeleteDocumentsMutationVariables>;
export const DeleteDocumentVersionDocument = gql`
    mutation DeleteDocumentVersion($documentId: ID!, $versionId: ID!) {
  deleteDocumentVersion(documentId: $documentId, versionId: $versionId)
}
    `;
export type DeleteDocumentVersionMutationFn = Apollo.MutationFunction<DeleteDocumentVersionMutation, DeleteDocumentVersionMutationVariables>;

/**
 * __useDeleteDocumentVersionMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentVersionMutation, { data, loading, error }] = useDeleteDocumentVersionMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useDeleteDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentVersionMutation, DeleteDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentVersionMutation, DeleteDocumentVersionMutationVariables>(DeleteDocumentVersionDocument, options);
      }
export type DeleteDocumentVersionMutationHookResult = ReturnType<typeof useDeleteDocumentVersionMutation>;
export type DeleteDocumentVersionMutationResult = Apollo.MutationResult<DeleteDocumentVersionMutation>;
export type DeleteDocumentVersionMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentVersionMutation, DeleteDocumentVersionMutationVariables>;
export const DeleteDocumentVersionsDocument = gql`
    mutation DeleteDocumentVersions($documentIdsVersionIds: [DocumentIdVersionId!]!) {
  deleteDocumentVersions(documentIdsVersionIds: $documentIdsVersionIds)
}
    `;
export type DeleteDocumentVersionsMutationFn = Apollo.MutationFunction<DeleteDocumentVersionsMutation, DeleteDocumentVersionsMutationVariables>;

/**
 * __useDeleteDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentVersionsMutation, { data, loading, error }] = useDeleteDocumentVersionsMutation({
 *   variables: {
 *      documentIdsVersionIds: // value for 'documentIdsVersionIds'
 *   },
 * });
 */
export function useDeleteDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentVersionsMutation, DeleteDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentVersionsMutation, DeleteDocumentVersionsMutationVariables>(DeleteDocumentVersionsDocument, options);
      }
export type DeleteDocumentVersionsMutationHookResult = ReturnType<typeof useDeleteDocumentVersionsMutation>;
export type DeleteDocumentVersionsMutationResult = Apollo.MutationResult<DeleteDocumentVersionsMutation>;
export type DeleteDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentVersionsMutation, DeleteDocumentVersionsMutationVariables>;
export const GenerateDocumentKeywordsDocument = gql`
    mutation GenerateDocumentKeywords($versionIds: [ID!]!) {
  generateDocumentKeywords(versionIds: $versionIds) {
    succeededIds
    errors
    failedIds
    success
  }
}
    `;
export type GenerateDocumentKeywordsMutationFn = Apollo.MutationFunction<GenerateDocumentKeywordsMutation, GenerateDocumentKeywordsMutationVariables>;

/**
 * __useGenerateDocumentKeywordsMutation__
 *
 * To run a mutation, you first call `useGenerateDocumentKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocumentKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocumentKeywordsMutation, { data, loading, error }] = useGenerateDocumentKeywordsMutation({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useGenerateDocumentKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateDocumentKeywordsMutation, GenerateDocumentKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateDocumentKeywordsMutation, GenerateDocumentKeywordsMutationVariables>(GenerateDocumentKeywordsDocument, options);
      }
export type GenerateDocumentKeywordsMutationHookResult = ReturnType<typeof useGenerateDocumentKeywordsMutation>;
export type GenerateDocumentKeywordsMutationResult = Apollo.MutationResult<GenerateDocumentKeywordsMutation>;
export type GenerateDocumentKeywordsMutationOptions = Apollo.BaseMutationOptions<GenerateDocumentKeywordsMutation, GenerateDocumentKeywordsMutationVariables>;
export const GenerateDocumentKeywordsSingleDocDocument = gql`
    mutation GenerateDocumentKeywordsSingleDoc($versionId: ID!) {
  generateDocumentKeywordsSingleDoc(versionId: $versionId) {
    score
    text
  }
}
    `;
export type GenerateDocumentKeywordsSingleDocMutationFn = Apollo.MutationFunction<GenerateDocumentKeywordsSingleDocMutation, GenerateDocumentKeywordsSingleDocMutationVariables>;

/**
 * __useGenerateDocumentKeywordsSingleDocMutation__
 *
 * To run a mutation, you first call `useGenerateDocumentKeywordsSingleDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocumentKeywordsSingleDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocumentKeywordsSingleDocMutation, { data, loading, error }] = useGenerateDocumentKeywordsSingleDocMutation({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useGenerateDocumentKeywordsSingleDocMutation(baseOptions?: Apollo.MutationHookOptions<GenerateDocumentKeywordsSingleDocMutation, GenerateDocumentKeywordsSingleDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateDocumentKeywordsSingleDocMutation, GenerateDocumentKeywordsSingleDocMutationVariables>(GenerateDocumentKeywordsSingleDocDocument, options);
      }
export type GenerateDocumentKeywordsSingleDocMutationHookResult = ReturnType<typeof useGenerateDocumentKeywordsSingleDocMutation>;
export type GenerateDocumentKeywordsSingleDocMutationResult = Apollo.MutationResult<GenerateDocumentKeywordsSingleDocMutation>;
export type GenerateDocumentKeywordsSingleDocMutationOptions = Apollo.BaseMutationOptions<GenerateDocumentKeywordsSingleDocMutation, GenerateDocumentKeywordsSingleDocMutationVariables>;
export const MutateDocumentKeywordsDocument = gql`
    mutation MutateDocumentKeywords($keywords: [DocumentKeyword!]!, $versionId: ID!) {
  mutateDocumentKeywords(keywords: $keywords, versionId: $versionId)
}
    `;
export type MutateDocumentKeywordsMutationFn = Apollo.MutationFunction<MutateDocumentKeywordsMutation, MutateDocumentKeywordsMutationVariables>;

/**
 * __useMutateDocumentKeywordsMutation__
 *
 * To run a mutation, you first call `useMutateDocumentKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateDocumentKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateDocumentKeywordsMutation, { data, loading, error }] = useMutateDocumentKeywordsMutation({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useMutateDocumentKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<MutateDocumentKeywordsMutation, MutateDocumentKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateDocumentKeywordsMutation, MutateDocumentKeywordsMutationVariables>(MutateDocumentKeywordsDocument, options);
      }
export type MutateDocumentKeywordsMutationHookResult = ReturnType<typeof useMutateDocumentKeywordsMutation>;
export type MutateDocumentKeywordsMutationResult = Apollo.MutationResult<MutateDocumentKeywordsMutation>;
export type MutateDocumentKeywordsMutationOptions = Apollo.BaseMutationOptions<MutateDocumentKeywordsMutation, MutateDocumentKeywordsMutationVariables>;
export const RestoreDocumentDocument = gql`
    mutation RestoreDocument($id: ID!) {
  restoreDocument(id: $id)
}
    `;
export type RestoreDocumentMutationFn = Apollo.MutationFunction<RestoreDocumentMutation, RestoreDocumentMutationVariables>;

/**
 * __useRestoreDocumentMutation__
 *
 * To run a mutation, you first call `useRestoreDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDocumentMutation, { data, loading, error }] = useRestoreDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDocumentMutation, RestoreDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreDocumentMutation, RestoreDocumentMutationVariables>(RestoreDocumentDocument, options);
      }
export type RestoreDocumentMutationHookResult = ReturnType<typeof useRestoreDocumentMutation>;
export type RestoreDocumentMutationResult = Apollo.MutationResult<RestoreDocumentMutation>;
export type RestoreDocumentMutationOptions = Apollo.BaseMutationOptions<RestoreDocumentMutation, RestoreDocumentMutationVariables>;
export const RestoreDocumentsDocument = gql`
    mutation RestoreDocuments($documentIds: [ID]!) {
  restoreDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type RestoreDocumentsMutationFn = Apollo.MutationFunction<RestoreDocumentsMutation, RestoreDocumentsMutationVariables>;

/**
 * __useRestoreDocumentsMutation__
 *
 * To run a mutation, you first call `useRestoreDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDocumentsMutation, { data, loading, error }] = useRestoreDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useRestoreDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDocumentsMutation, RestoreDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreDocumentsMutation, RestoreDocumentsMutationVariables>(RestoreDocumentsDocument, options);
      }
export type RestoreDocumentsMutationHookResult = ReturnType<typeof useRestoreDocumentsMutation>;
export type RestoreDocumentsMutationResult = Apollo.MutationResult<RestoreDocumentsMutation>;
export type RestoreDocumentsMutationOptions = Apollo.BaseMutationOptions<RestoreDocumentsMutation, RestoreDocumentsMutationVariables>;
export const RestoreDocumentVersionDocument = gql`
    mutation RestoreDocumentVersion($documentId: ID!, $versionId: ID!) {
  restoreDocumentVersion(documentId: $documentId, versionId: $versionId)
}
    `;
export type RestoreDocumentVersionMutationFn = Apollo.MutationFunction<RestoreDocumentVersionMutation, RestoreDocumentVersionMutationVariables>;

/**
 * __useRestoreDocumentVersionMutation__
 *
 * To run a mutation, you first call `useRestoreDocumentVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDocumentVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDocumentVersionMutation, { data, loading, error }] = useRestoreDocumentVersionMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useRestoreDocumentVersionMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDocumentVersionMutation, RestoreDocumentVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreDocumentVersionMutation, RestoreDocumentVersionMutationVariables>(RestoreDocumentVersionDocument, options);
      }
export type RestoreDocumentVersionMutationHookResult = ReturnType<typeof useRestoreDocumentVersionMutation>;
export type RestoreDocumentVersionMutationResult = Apollo.MutationResult<RestoreDocumentVersionMutation>;
export type RestoreDocumentVersionMutationOptions = Apollo.BaseMutationOptions<RestoreDocumentVersionMutation, RestoreDocumentVersionMutationVariables>;
export const CreateTagsDocument = gql`
    mutation CreateTags($tags: [TagInput]!) {
  createTags(tags: $tags) {
    successful
    unsuccessful
  }
}
    `;
export type CreateTagsMutationFn = Apollo.MutationFunction<CreateTagsMutation, CreateTagsMutationVariables>;

/**
 * __useCreateTagsMutation__
 *
 * To run a mutation, you first call `useCreateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagsMutation, { data, loading, error }] = useCreateTagsMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateTagsMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagsMutation, CreateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagsMutation, CreateTagsMutationVariables>(CreateTagsDocument, options);
      }
export type CreateTagsMutationHookResult = ReturnType<typeof useCreateTagsMutation>;
export type CreateTagsMutationResult = Apollo.MutationResult<CreateTagsMutation>;
export type CreateTagsMutationOptions = Apollo.BaseMutationOptions<CreateTagsMutation, CreateTagsMutationVariables>;
export const SetDocumentTagsDocument = gql`
    mutation SetDocumentTags($documentId: ID!, $tagIds: [ID]!) {
  setDocumentTags(documentId: $documentId, tagIds: $tagIds) {
    successful
    unsuccessful
  }
}
    `;
export type SetDocumentTagsMutationFn = Apollo.MutationFunction<SetDocumentTagsMutation, SetDocumentTagsMutationVariables>;

/**
 * __useSetDocumentTagsMutation__
 *
 * To run a mutation, you first call `useSetDocumentTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentTagsMutation, { data, loading, error }] = useSetDocumentTagsMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useSetDocumentTagsMutation(baseOptions?: Apollo.MutationHookOptions<SetDocumentTagsMutation, SetDocumentTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDocumentTagsMutation, SetDocumentTagsMutationVariables>(SetDocumentTagsDocument, options);
      }
export type SetDocumentTagsMutationHookResult = ReturnType<typeof useSetDocumentTagsMutation>;
export type SetDocumentTagsMutationResult = Apollo.MutationResult<SetDocumentTagsMutation>;
export type SetDocumentTagsMutationOptions = Apollo.BaseMutationOptions<SetDocumentTagsMutation, SetDocumentTagsMutationVariables>;
export const AddAnnotationDocument = gql`
    mutation AddAnnotation($documentId: ID!, $versionId: ID!, $json: JSON!) {
  addAnnotation(documentId: $documentId, versionId: $versionId, json: $json) {
    ...AnnotationFragment
  }
}
    ${AnnotationFragmentFragmentDoc}`;
export type AddAnnotationMutationFn = Apollo.MutationFunction<AddAnnotationMutation, AddAnnotationMutationVariables>;

/**
 * __useAddAnnotationMutation__
 *
 * To run a mutation, you first call `useAddAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnotationMutation, { data, loading, error }] = useAddAnnotationMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *      json: // value for 'json'
 *   },
 * });
 */
export function useAddAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<AddAnnotationMutation, AddAnnotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAnnotationMutation, AddAnnotationMutationVariables>(AddAnnotationDocument, options);
      }
export type AddAnnotationMutationHookResult = ReturnType<typeof useAddAnnotationMutation>;
export type AddAnnotationMutationResult = Apollo.MutationResult<AddAnnotationMutation>;
export type AddAnnotationMutationOptions = Apollo.BaseMutationOptions<AddAnnotationMutation, AddAnnotationMutationVariables>;
export const DeleteAnnotationsDocument = gql`
    mutation DeleteAnnotations($documentId: ID!, $versionId: ID!, $annotationIds: [ID!]) {
  deleteAnnotations(
    documentId: $documentId
    versionId: $versionId
    annotationIds: $annotationIds
  )
}
    `;
export type DeleteAnnotationsMutationFn = Apollo.MutationFunction<DeleteAnnotationsMutation, DeleteAnnotationsMutationVariables>;

/**
 * __useDeleteAnnotationsMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationsMutation, { data, loading, error }] = useDeleteAnnotationsMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *      annotationIds: // value for 'annotationIds'
 *   },
 * });
 */
export function useDeleteAnnotationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnnotationsMutation, DeleteAnnotationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnnotationsMutation, DeleteAnnotationsMutationVariables>(DeleteAnnotationsDocument, options);
      }
export type DeleteAnnotationsMutationHookResult = ReturnType<typeof useDeleteAnnotationsMutation>;
export type DeleteAnnotationsMutationResult = Apollo.MutationResult<DeleteAnnotationsMutation>;
export type DeleteAnnotationsMutationOptions = Apollo.BaseMutationOptions<DeleteAnnotationsMutation, DeleteAnnotationsMutationVariables>;
export const LockDocumentsDocument = gql`
    mutation lockDocuments($documentIds: [ID]!) {
  lockDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type LockDocumentsMutationFn = Apollo.MutationFunction<LockDocumentsMutation, LockDocumentsMutationVariables>;

/**
 * __useLockDocumentsMutation__
 *
 * To run a mutation, you first call `useLockDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockDocumentsMutation, { data, loading, error }] = useLockDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useLockDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<LockDocumentsMutation, LockDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LockDocumentsMutation, LockDocumentsMutationVariables>(LockDocumentsDocument, options);
      }
export type LockDocumentsMutationHookResult = ReturnType<typeof useLockDocumentsMutation>;
export type LockDocumentsMutationResult = Apollo.MutationResult<LockDocumentsMutation>;
export type LockDocumentsMutationOptions = Apollo.BaseMutationOptions<LockDocumentsMutation, LockDocumentsMutationVariables>;
export const UnlockDocumentsDocument = gql`
    mutation unlockDocuments($documentIds: [ID]!) {
  unlockDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type UnlockDocumentsMutationFn = Apollo.MutationFunction<UnlockDocumentsMutation, UnlockDocumentsMutationVariables>;

/**
 * __useUnlockDocumentsMutation__
 *
 * To run a mutation, you first call `useUnlockDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockDocumentsMutation, { data, loading, error }] = useUnlockDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useUnlockDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UnlockDocumentsMutation, UnlockDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockDocumentsMutation, UnlockDocumentsMutationVariables>(UnlockDocumentsDocument, options);
      }
export type UnlockDocumentsMutationHookResult = ReturnType<typeof useUnlockDocumentsMutation>;
export type UnlockDocumentsMutationResult = Apollo.MutationResult<UnlockDocumentsMutation>;
export type UnlockDocumentsMutationOptions = Apollo.BaseMutationOptions<UnlockDocumentsMutation, UnlockDocumentsMutationVariables>;
export const RequestUnlockDocumentsDocument = gql`
    mutation requestUnlockDocuments($documentIds: [ID]!) {
  requestUnlockDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type RequestUnlockDocumentsMutationFn = Apollo.MutationFunction<RequestUnlockDocumentsMutation, RequestUnlockDocumentsMutationVariables>;

/**
 * __useRequestUnlockDocumentsMutation__
 *
 * To run a mutation, you first call `useRequestUnlockDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUnlockDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUnlockDocumentsMutation, { data, loading, error }] = useRequestUnlockDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useRequestUnlockDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<RequestUnlockDocumentsMutation, RequestUnlockDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestUnlockDocumentsMutation, RequestUnlockDocumentsMutationVariables>(RequestUnlockDocumentsDocument, options);
      }
export type RequestUnlockDocumentsMutationHookResult = ReturnType<typeof useRequestUnlockDocumentsMutation>;
export type RequestUnlockDocumentsMutationResult = Apollo.MutationResult<RequestUnlockDocumentsMutation>;
export type RequestUnlockDocumentsMutationOptions = Apollo.BaseMutationOptions<RequestUnlockDocumentsMutation, RequestUnlockDocumentsMutationVariables>;
export const FinalizeDocumentsDocument = gql`
    mutation FinalizeDocuments($documentIds: [ID]!) {
  finalizeDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type FinalizeDocumentsMutationFn = Apollo.MutationFunction<FinalizeDocumentsMutation, FinalizeDocumentsMutationVariables>;

/**
 * __useFinalizeDocumentsMutation__
 *
 * To run a mutation, you first call `useFinalizeDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeDocumentsMutation, { data, loading, error }] = useFinalizeDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useFinalizeDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeDocumentsMutation, FinalizeDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeDocumentsMutation, FinalizeDocumentsMutationVariables>(FinalizeDocumentsDocument, options);
      }
export type FinalizeDocumentsMutationHookResult = ReturnType<typeof useFinalizeDocumentsMutation>;
export type FinalizeDocumentsMutationResult = Apollo.MutationResult<FinalizeDocumentsMutation>;
export type FinalizeDocumentsMutationOptions = Apollo.BaseMutationOptions<FinalizeDocumentsMutation, FinalizeDocumentsMutationVariables>;
export const UnfinalizeDocumentsDocument = gql`
    mutation UnfinalizeDocuments($documentIds: [ID]!) {
  unfinalizeDocuments(documentIds: $documentIds) {
    successful
    unsuccessful
  }
}
    `;
export type UnfinalizeDocumentsMutationFn = Apollo.MutationFunction<UnfinalizeDocumentsMutation, UnfinalizeDocumentsMutationVariables>;

/**
 * __useUnfinalizeDocumentsMutation__
 *
 * To run a mutation, you first call `useUnfinalizeDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfinalizeDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfinalizeDocumentsMutation, { data, loading, error }] = useUnfinalizeDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useUnfinalizeDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UnfinalizeDocumentsMutation, UnfinalizeDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfinalizeDocumentsMutation, UnfinalizeDocumentsMutationVariables>(UnfinalizeDocumentsDocument, options);
      }
export type UnfinalizeDocumentsMutationHookResult = ReturnType<typeof useUnfinalizeDocumentsMutation>;
export type UnfinalizeDocumentsMutationResult = Apollo.MutationResult<UnfinalizeDocumentsMutation>;
export type UnfinalizeDocumentsMutationOptions = Apollo.BaseMutationOptions<UnfinalizeDocumentsMutation, UnfinalizeDocumentsMutationVariables>;
export const ApproveDocumentVersionsDocument = gql`
    mutation ApproveDocumentVersions($versionIds: [ID]!) {
  approveDocumentVersions(versionIds: $versionIds) {
    successful
    unsuccessful
  }
}
    `;
export type ApproveDocumentVersionsMutationFn = Apollo.MutationFunction<ApproveDocumentVersionsMutation, ApproveDocumentVersionsMutationVariables>;

/**
 * __useApproveDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useApproveDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDocumentVersionsMutation, { data, loading, error }] = useApproveDocumentVersionsMutation({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useApproveDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<ApproveDocumentVersionsMutation, ApproveDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveDocumentVersionsMutation, ApproveDocumentVersionsMutationVariables>(ApproveDocumentVersionsDocument, options);
      }
export type ApproveDocumentVersionsMutationHookResult = ReturnType<typeof useApproveDocumentVersionsMutation>;
export type ApproveDocumentVersionsMutationResult = Apollo.MutationResult<ApproveDocumentVersionsMutation>;
export type ApproveDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<ApproveDocumentVersionsMutation, ApproveDocumentVersionsMutationVariables>;
export const RejectDocumentVersionsDocument = gql`
    mutation RejectDocumentVersions($versionIds: [ID]!) {
  rejectDocumentVersions(versionIds: $versionIds) {
    successful
    unsuccessful
  }
}
    `;
export type RejectDocumentVersionsMutationFn = Apollo.MutationFunction<RejectDocumentVersionsMutation, RejectDocumentVersionsMutationVariables>;

/**
 * __useRejectDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useRejectDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDocumentVersionsMutation, { data, loading, error }] = useRejectDocumentVersionsMutation({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useRejectDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<RejectDocumentVersionsMutation, RejectDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectDocumentVersionsMutation, RejectDocumentVersionsMutationVariables>(RejectDocumentVersionsDocument, options);
      }
export type RejectDocumentVersionsMutationHookResult = ReturnType<typeof useRejectDocumentVersionsMutation>;
export type RejectDocumentVersionsMutationResult = Apollo.MutationResult<RejectDocumentVersionsMutation>;
export type RejectDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<RejectDocumentVersionsMutation, RejectDocumentVersionsMutationVariables>;
export const UnapproveDocumentVersionsDocument = gql`
    mutation UnapproveDocumentVersions($versionIds: [ID]!) {
  unapproveDocumentVersions(versionIds: $versionIds) {
    successful
    unsuccessful
  }
}
    `;
export type UnapproveDocumentVersionsMutationFn = Apollo.MutationFunction<UnapproveDocumentVersionsMutation, UnapproveDocumentVersionsMutationVariables>;

/**
 * __useUnapproveDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useUnapproveDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveDocumentVersionsMutation, { data, loading, error }] = useUnapproveDocumentVersionsMutation({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useUnapproveDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<UnapproveDocumentVersionsMutation, UnapproveDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnapproveDocumentVersionsMutation, UnapproveDocumentVersionsMutationVariables>(UnapproveDocumentVersionsDocument, options);
      }
export type UnapproveDocumentVersionsMutationHookResult = ReturnType<typeof useUnapproveDocumentVersionsMutation>;
export type UnapproveDocumentVersionsMutationResult = Apollo.MutationResult<UnapproveDocumentVersionsMutation>;
export type UnapproveDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<UnapproveDocumentVersionsMutation, UnapproveDocumentVersionsMutationVariables>;
export const UnrejectDocumentVersionsDocument = gql`
    mutation UnrejectDocumentVersions($versionIds: [ID]!) {
  unrejectDocumentVersions(versionIds: $versionIds) {
    successful
    unsuccessful
  }
}
    `;
export type UnrejectDocumentVersionsMutationFn = Apollo.MutationFunction<UnrejectDocumentVersionsMutation, UnrejectDocumentVersionsMutationVariables>;

/**
 * __useUnrejectDocumentVersionsMutation__
 *
 * To run a mutation, you first call `useUnrejectDocumentVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnrejectDocumentVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unrejectDocumentVersionsMutation, { data, loading, error }] = useUnrejectDocumentVersionsMutation({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useUnrejectDocumentVersionsMutation(baseOptions?: Apollo.MutationHookOptions<UnrejectDocumentVersionsMutation, UnrejectDocumentVersionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnrejectDocumentVersionsMutation, UnrejectDocumentVersionsMutationVariables>(UnrejectDocumentVersionsDocument, options);
      }
export type UnrejectDocumentVersionsMutationHookResult = ReturnType<typeof useUnrejectDocumentVersionsMutation>;
export type UnrejectDocumentVersionsMutationResult = Apollo.MutationResult<UnrejectDocumentVersionsMutation>;
export type UnrejectDocumentVersionsMutationOptions = Apollo.BaseMutationOptions<UnrejectDocumentVersionsMutation, UnrejectDocumentVersionsMutationVariables>;
export const MapDocumentVersionMetaDataInstanceDocument = gql`
    mutation MapDocumentVersionMetaDataInstance($mappings: [DocumentVersionMetadataMapping]!) {
  mapDocumentVersionMetaDataInstance(mappings: $mappings) {
    successful
    unsuccessful
  }
}
    `;
export type MapDocumentVersionMetaDataInstanceMutationFn = Apollo.MutationFunction<MapDocumentVersionMetaDataInstanceMutation, MapDocumentVersionMetaDataInstanceMutationVariables>;

/**
 * __useMapDocumentVersionMetaDataInstanceMutation__
 *
 * To run a mutation, you first call `useMapDocumentVersionMetaDataInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMapDocumentVersionMetaDataInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mapDocumentVersionMetaDataInstanceMutation, { data, loading, error }] = useMapDocumentVersionMetaDataInstanceMutation({
 *   variables: {
 *      mappings: // value for 'mappings'
 *   },
 * });
 */
export function useMapDocumentVersionMetaDataInstanceMutation(baseOptions?: Apollo.MutationHookOptions<MapDocumentVersionMetaDataInstanceMutation, MapDocumentVersionMetaDataInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MapDocumentVersionMetaDataInstanceMutation, MapDocumentVersionMetaDataInstanceMutationVariables>(MapDocumentVersionMetaDataInstanceDocument, options);
      }
export type MapDocumentVersionMetaDataInstanceMutationHookResult = ReturnType<typeof useMapDocumentVersionMetaDataInstanceMutation>;
export type MapDocumentVersionMetaDataInstanceMutationResult = Apollo.MutationResult<MapDocumentVersionMetaDataInstanceMutation>;
export type MapDocumentVersionMetaDataInstanceMutationOptions = Apollo.BaseMutationOptions<MapDocumentVersionMetaDataInstanceMutation, MapDocumentVersionMetaDataInstanceMutationVariables>;
export const CreateSummaryDocument = gql`
    mutation CreateSummary($versionId: ID!) {
  createSummary(versionId: $versionId)
}
    `;
export type CreateSummaryMutationFn = Apollo.MutationFunction<CreateSummaryMutation, CreateSummaryMutationVariables>;

/**
 * __useCreateSummaryMutation__
 *
 * To run a mutation, you first call `useCreateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSummaryMutation, { data, loading, error }] = useCreateSummaryMutation({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useCreateSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateSummaryMutation, CreateSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSummaryMutation, CreateSummaryMutationVariables>(CreateSummaryDocument, options);
      }
export type CreateSummaryMutationHookResult = ReturnType<typeof useCreateSummaryMutation>;
export type CreateSummaryMutationResult = Apollo.MutationResult<CreateSummaryMutation>;
export type CreateSummaryMutationOptions = Apollo.BaseMutationOptions<CreateSummaryMutation, CreateSummaryMutationVariables>;
export const CreateCommandDocument = gql`
    mutation CreateCommand($command: CreateCommandInput!) {
  createCommand(command: $command) {
    commandId
    status
    clientUrl
  }
}
    `;
export type CreateCommandMutationFn = Apollo.MutationFunction<CreateCommandMutation, CreateCommandMutationVariables>;

/**
 * __useCreateCommandMutation__
 *
 * To run a mutation, you first call `useCreateCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommandMutation, { data, loading, error }] = useCreateCommandMutation({
 *   variables: {
 *      command: // value for 'command'
 *   },
 * });
 */
export function useCreateCommandMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommandMutation, CreateCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommandMutation, CreateCommandMutationVariables>(CreateCommandDocument, options);
      }
export type CreateCommandMutationHookResult = ReturnType<typeof useCreateCommandMutation>;
export type CreateCommandMutationResult = Apollo.MutationResult<CreateCommandMutation>;
export type CreateCommandMutationOptions = Apollo.BaseMutationOptions<CreateCommandMutation, CreateCommandMutationVariables>;
export const ApplyTemplateDocument = gql`
    mutation ApplyTemplate($applyToSubfolders: Boolean, $folderId: ID!, $templateId: ID!) {
  applyTemplate(
    applyToSubfolders: $applyToSubfolders
    folderId: $folderId
    templateId: $templateId
  ) {
    folderId
  }
}
    `;
export type ApplyTemplateMutationFn = Apollo.MutationFunction<ApplyTemplateMutation, ApplyTemplateMutationVariables>;

/**
 * __useApplyTemplateMutation__
 *
 * To run a mutation, you first call `useApplyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyTemplateMutation, { data, loading, error }] = useApplyTemplateMutation({
 *   variables: {
 *      applyToSubfolders: // value for 'applyToSubfolders'
 *      folderId: // value for 'folderId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useApplyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ApplyTemplateMutation, ApplyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyTemplateMutation, ApplyTemplateMutationVariables>(ApplyTemplateDocument, options);
      }
export type ApplyTemplateMutationHookResult = ReturnType<typeof useApplyTemplateMutation>;
export type ApplyTemplateMutationResult = Apollo.MutationResult<ApplyTemplateMutation>;
export type ApplyTemplateMutationOptions = Apollo.BaseMutationOptions<ApplyTemplateMutation, ApplyTemplateMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($metadataSetId: ID, $name: String!, $parentFolderId: ID, $projectId: ID, $isPrivate: Boolean, $applyToSubfolders: Boolean) {
  createFolder(
    applyToSubfolders: $applyToSubfolders
    metadataSetId: $metadataSetId
    name: $name
    parentFolderId: $parentFolderId
    projectId: $projectId
    isPrivate: $isPrivate
  ) {
    folderId
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      metadataSetId: // value for 'metadataSetId'
 *      name: // value for 'name'
 *      parentFolderId: // value for 'parentFolderId'
 *      projectId: // value for 'projectId'
 *      isPrivate: // value for 'isPrivate'
 *      applyToSubfolders: // value for 'applyToSubfolders'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const MoveFolderDocument = gql`
    mutation MoveFolder($childFolderId: ID!, $parentFolderId: ID!) {
  moveFolder(childFolderId: $childFolderId, parentFolderId: $parentFolderId) {
    folderId
  }
}
    `;
export type MoveFolderMutationFn = Apollo.MutationFunction<MoveFolderMutation, MoveFolderMutationVariables>;

/**
 * __useMoveFolderMutation__
 *
 * To run a mutation, you first call `useMoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFolderMutation, { data, loading, error }] = useMoveFolderMutation({
 *   variables: {
 *      childFolderId: // value for 'childFolderId'
 *      parentFolderId: // value for 'parentFolderId'
 *   },
 * });
 */
export function useMoveFolderMutation(baseOptions?: Apollo.MutationHookOptions<MoveFolderMutation, MoveFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument, options);
      }
export type MoveFolderMutationHookResult = ReturnType<typeof useMoveFolderMutation>;
export type MoveFolderMutationResult = Apollo.MutationResult<MoveFolderMutation>;
export type MoveFolderMutationOptions = Apollo.BaseMutationOptions<MoveFolderMutation, MoveFolderMutationVariables>;
export const RenameFolderDocument = gql`
    mutation RenameFolder($folderId: ID, $name: String!) {
  renameFolder(folderId: $folderId, name: $name) {
    folderId
  }
}
    `;
export type RenameFolderMutationFn = Apollo.MutationFunction<RenameFolderMutation, RenameFolderMutationVariables>;

/**
 * __useRenameFolderMutation__
 *
 * To run a mutation, you first call `useRenameFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFolderMutation, { data, loading, error }] = useRenameFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameFolderMutation(baseOptions?: Apollo.MutationHookOptions<RenameFolderMutation, RenameFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameFolderMutation, RenameFolderMutationVariables>(RenameFolderDocument, options);
      }
export type RenameFolderMutationHookResult = ReturnType<typeof useRenameFolderMutation>;
export type RenameFolderMutationResult = Apollo.MutationResult<RenameFolderMutation>;
export type RenameFolderMutationOptions = Apollo.BaseMutationOptions<RenameFolderMutation, RenameFolderMutationVariables>;
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($id: ID!, $name: String!, $parentFolderId: ID, $isPrivate: Boolean) {
  updateFolder(
    id: $id
    name: $name
    parentFolderId: $parentFolderId
    isPrivate: $isPrivate
  ) {
    folderId
  }
}
    `;
export type UpdateFolderMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      parentFolderId: // value for 'parentFolderId'
 *      isPrivate: // value for 'isPrivate'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, options);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: ID!) {
  deleteFolder(id: $id) {
    folderId
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const RestoreFolderDocument = gql`
    mutation RestoreFolder($id: ID!) {
  restoreFolder(id: $id) {
    folderId
  }
}
    `;
export type RestoreFolderMutationFn = Apollo.MutationFunction<RestoreFolderMutation, RestoreFolderMutationVariables>;

/**
 * __useRestoreFolderMutation__
 *
 * To run a mutation, you first call `useRestoreFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreFolderMutation, { data, loading, error }] = useRestoreFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreFolderMutation(baseOptions?: Apollo.MutationHookOptions<RestoreFolderMutation, RestoreFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreFolderMutation, RestoreFolderMutationVariables>(RestoreFolderDocument, options);
      }
export type RestoreFolderMutationHookResult = ReturnType<typeof useRestoreFolderMutation>;
export type RestoreFolderMutationResult = Apollo.MutationResult<RestoreFolderMutation>;
export type RestoreFolderMutationOptions = Apollo.BaseMutationOptions<RestoreFolderMutation, RestoreFolderMutationVariables>;
export const DownloadFolderZipDocument = gql`
    mutation DownloadFolderZip($id: ID!, $includeSubfolders: Boolean!) {
  downloadFolderZip(id: $id, includeSubfolders: $includeSubfolders)
}
    `;
export type DownloadFolderZipMutationFn = Apollo.MutationFunction<DownloadFolderZipMutation, DownloadFolderZipMutationVariables>;

/**
 * __useDownloadFolderZipMutation__
 *
 * To run a mutation, you first call `useDownloadFolderZipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFolderZipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFolderZipMutation, { data, loading, error }] = useDownloadFolderZipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      includeSubfolders: // value for 'includeSubfolders'
 *   },
 * });
 */
export function useDownloadFolderZipMutation(baseOptions?: Apollo.MutationHookOptions<DownloadFolderZipMutation, DownloadFolderZipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadFolderZipMutation, DownloadFolderZipMutationVariables>(DownloadFolderZipDocument, options);
      }
export type DownloadFolderZipMutationHookResult = ReturnType<typeof useDownloadFolderZipMutation>;
export type DownloadFolderZipMutationResult = Apollo.MutationResult<DownloadFolderZipMutation>;
export type DownloadFolderZipMutationOptions = Apollo.BaseMutationOptions<DownloadFolderZipMutation, DownloadFolderZipMutationVariables>;
export const SetFoldersPermissionsForUserDocument = gql`
    mutation SetFoldersPermissionsForUser($folders: [FolderWithPermissions]!, $userId: ID!) {
  setFoldersPermissionsForUser(folders: $folders, userId: $userId) {
    successful
    unsuccessful
  }
}
    `;
export type SetFoldersPermissionsForUserMutationFn = Apollo.MutationFunction<SetFoldersPermissionsForUserMutation, SetFoldersPermissionsForUserMutationVariables>;

/**
 * __useSetFoldersPermissionsForUserMutation__
 *
 * To run a mutation, you first call `useSetFoldersPermissionsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFoldersPermissionsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFoldersPermissionsForUserMutation, { data, loading, error }] = useSetFoldersPermissionsForUserMutation({
 *   variables: {
 *      folders: // value for 'folders'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetFoldersPermissionsForUserMutation(baseOptions?: Apollo.MutationHookOptions<SetFoldersPermissionsForUserMutation, SetFoldersPermissionsForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFoldersPermissionsForUserMutation, SetFoldersPermissionsForUserMutationVariables>(SetFoldersPermissionsForUserDocument, options);
      }
export type SetFoldersPermissionsForUserMutationHookResult = ReturnType<typeof useSetFoldersPermissionsForUserMutation>;
export type SetFoldersPermissionsForUserMutationResult = Apollo.MutationResult<SetFoldersPermissionsForUserMutation>;
export type SetFoldersPermissionsForUserMutationOptions = Apollo.BaseMutationOptions<SetFoldersPermissionsForUserMutation, SetFoldersPermissionsForUserMutationVariables>;
export const SetFolderProjectLinkDocument = gql`
    mutation SetFolderProjectLink($folderId: ID!, $projectInfo: ProjectInfo!) {
  setFolderProjectLink(folderId: $folderId, projectInfo: $projectInfo) {
    id
    success
    errors
  }
}
    `;
export type SetFolderProjectLinkMutationFn = Apollo.MutationFunction<SetFolderProjectLinkMutation, SetFolderProjectLinkMutationVariables>;

/**
 * __useSetFolderProjectLinkMutation__
 *
 * To run a mutation, you first call `useSetFolderProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFolderProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFolderProjectLinkMutation, { data, loading, error }] = useSetFolderProjectLinkMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      projectInfo: // value for 'projectInfo'
 *   },
 * });
 */
export function useSetFolderProjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<SetFolderProjectLinkMutation, SetFolderProjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFolderProjectLinkMutation, SetFolderProjectLinkMutationVariables>(SetFolderProjectLinkDocument, options);
      }
export type SetFolderProjectLinkMutationHookResult = ReturnType<typeof useSetFolderProjectLinkMutation>;
export type SetFolderProjectLinkMutationResult = Apollo.MutationResult<SetFolderProjectLinkMutation>;
export type SetFolderProjectLinkMutationOptions = Apollo.BaseMutationOptions<SetFolderProjectLinkMutation, SetFolderProjectLinkMutationVariables>;
export const RemoveFolderProjectLinkDocument = gql`
    mutation RemoveFolderProjectLink($linkId: ID!) {
  removeFolderProjectLink(linkId: $linkId) {
    id
    success
    errors
  }
}
    `;
export type RemoveFolderProjectLinkMutationFn = Apollo.MutationFunction<RemoveFolderProjectLinkMutation, RemoveFolderProjectLinkMutationVariables>;

/**
 * __useRemoveFolderProjectLinkMutation__
 *
 * To run a mutation, you first call `useRemoveFolderProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFolderProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFolderProjectLinkMutation, { data, loading, error }] = useRemoveFolderProjectLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useRemoveFolderProjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFolderProjectLinkMutation, RemoveFolderProjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFolderProjectLinkMutation, RemoveFolderProjectLinkMutationVariables>(RemoveFolderProjectLinkDocument, options);
      }
export type RemoveFolderProjectLinkMutationHookResult = ReturnType<typeof useRemoveFolderProjectLinkMutation>;
export type RemoveFolderProjectLinkMutationResult = Apollo.MutationResult<RemoveFolderProjectLinkMutation>;
export type RemoveFolderProjectLinkMutationOptions = Apollo.BaseMutationOptions<RemoveFolderProjectLinkMutation, RemoveFolderProjectLinkMutationVariables>;
export const MoveProjectLinkDocument = gql`
    mutation MoveProjectLink($newFolderId: ID!, $currentLinkId: ID!, $projectInfo: ProjectInfo!) {
  moveProjectLink(
    newFolderId: $newFolderId
    currentLinkId: $currentLinkId
    projectInfo: $projectInfo
  ) {
    id
    success
    errors
  }
}
    `;
export type MoveProjectLinkMutationFn = Apollo.MutationFunction<MoveProjectLinkMutation, MoveProjectLinkMutationVariables>;

/**
 * __useMoveProjectLinkMutation__
 *
 * To run a mutation, you first call `useMoveProjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectLinkMutation, { data, loading, error }] = useMoveProjectLinkMutation({
 *   variables: {
 *      newFolderId: // value for 'newFolderId'
 *      currentLinkId: // value for 'currentLinkId'
 *      projectInfo: // value for 'projectInfo'
 *   },
 * });
 */
export function useMoveProjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<MoveProjectLinkMutation, MoveProjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveProjectLinkMutation, MoveProjectLinkMutationVariables>(MoveProjectLinkDocument, options);
      }
export type MoveProjectLinkMutationHookResult = ReturnType<typeof useMoveProjectLinkMutation>;
export type MoveProjectLinkMutationResult = Apollo.MutationResult<MoveProjectLinkMutation>;
export type MoveProjectLinkMutationOptions = Apollo.BaseMutationOptions<MoveProjectLinkMutation, MoveProjectLinkMutationVariables>;
export const SetEntitiesFolderPermissionsForFolderDocument = gql`
    mutation SetEntitiesFolderPermissionsForFolder($entities: [EntityWithFolderPermissions]!, $folderId: ID!) {
  setEntitiesFolderPermissionsForFolder(entities: $entities, folderId: $folderId) {
    successful
    unsuccessful
  }
}
    `;
export type SetEntitiesFolderPermissionsForFolderMutationFn = Apollo.MutationFunction<SetEntitiesFolderPermissionsForFolderMutation, SetEntitiesFolderPermissionsForFolderMutationVariables>;

/**
 * __useSetEntitiesFolderPermissionsForFolderMutation__
 *
 * To run a mutation, you first call `useSetEntitiesFolderPermissionsForFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEntitiesFolderPermissionsForFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEntitiesFolderPermissionsForFolderMutation, { data, loading, error }] = useSetEntitiesFolderPermissionsForFolderMutation({
 *   variables: {
 *      entities: // value for 'entities'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useSetEntitiesFolderPermissionsForFolderMutation(baseOptions?: Apollo.MutationHookOptions<SetEntitiesFolderPermissionsForFolderMutation, SetEntitiesFolderPermissionsForFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEntitiesFolderPermissionsForFolderMutation, SetEntitiesFolderPermissionsForFolderMutationVariables>(SetEntitiesFolderPermissionsForFolderDocument, options);
      }
export type SetEntitiesFolderPermissionsForFolderMutationHookResult = ReturnType<typeof useSetEntitiesFolderPermissionsForFolderMutation>;
export type SetEntitiesFolderPermissionsForFolderMutationResult = Apollo.MutationResult<SetEntitiesFolderPermissionsForFolderMutation>;
export type SetEntitiesFolderPermissionsForFolderMutationOptions = Apollo.BaseMutationOptions<SetEntitiesFolderPermissionsForFolderMutation, SetEntitiesFolderPermissionsForFolderMutationVariables>;
export const DuplicateFolderStructureDocument = gql`
    mutation DuplicateFolderStructure($sourceFolderId: String, $sourceProjectId: String, $targetFolderId: String, $targetProjectId: String) {
  duplicateFolderStructure(
    sourceFolderId: $sourceFolderId
    sourceProjectId: $sourceProjectId
    targetFolderId: $targetFolderId
    targetProjectId: $targetProjectId
  ) {
    success
    errorCode
    folderName
  }
}
    `;
export type DuplicateFolderStructureMutationFn = Apollo.MutationFunction<DuplicateFolderStructureMutation, DuplicateFolderStructureMutationVariables>;

/**
 * __useDuplicateFolderStructureMutation__
 *
 * To run a mutation, you first call `useDuplicateFolderStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFolderStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFolderStructureMutation, { data, loading, error }] = useDuplicateFolderStructureMutation({
 *   variables: {
 *      sourceFolderId: // value for 'sourceFolderId'
 *      sourceProjectId: // value for 'sourceProjectId'
 *      targetFolderId: // value for 'targetFolderId'
 *      targetProjectId: // value for 'targetProjectId'
 *   },
 * });
 */
export function useDuplicateFolderStructureMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateFolderStructureMutation, DuplicateFolderStructureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateFolderStructureMutation, DuplicateFolderStructureMutationVariables>(DuplicateFolderStructureDocument, options);
      }
export type DuplicateFolderStructureMutationHookResult = ReturnType<typeof useDuplicateFolderStructureMutation>;
export type DuplicateFolderStructureMutationResult = Apollo.MutationResult<DuplicateFolderStructureMutation>;
export type DuplicateFolderStructureMutationOptions = Apollo.BaseMutationOptions<DuplicateFolderStructureMutation, DuplicateFolderStructureMutationVariables>;
export const CreatePublicShareLinkDocument = gql`
    mutation CreatePublicShareLink($expirationDate: DateTime!, $passwordProtected: Boolean, $resources: [Resource]!, $showFolders: Boolean!) {
  createPublicShareLink(
    expirationDate: $expirationDate
    passwordProtected: $passwordProtected
    resources: $resources
    showFolders: $showFolders
  ) {
    id
    link
    password
    errors
    success
  }
}
    `;
export type CreatePublicShareLinkMutationFn = Apollo.MutationFunction<CreatePublicShareLinkMutation, CreatePublicShareLinkMutationVariables>;

/**
 * __useCreatePublicShareLinkMutation__
 *
 * To run a mutation, you first call `useCreatePublicShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicShareLinkMutation, { data, loading, error }] = useCreatePublicShareLinkMutation({
 *   variables: {
 *      expirationDate: // value for 'expirationDate'
 *      passwordProtected: // value for 'passwordProtected'
 *      resources: // value for 'resources'
 *      showFolders: // value for 'showFolders'
 *   },
 * });
 */
export function useCreatePublicShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicShareLinkMutation, CreatePublicShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicShareLinkMutation, CreatePublicShareLinkMutationVariables>(CreatePublicShareLinkDocument, options);
      }
export type CreatePublicShareLinkMutationHookResult = ReturnType<typeof useCreatePublicShareLinkMutation>;
export type CreatePublicShareLinkMutationResult = Apollo.MutationResult<CreatePublicShareLinkMutation>;
export type CreatePublicShareLinkMutationOptions = Apollo.BaseMutationOptions<CreatePublicShareLinkMutation, CreatePublicShareLinkMutationVariables>;
export const CreatePrivateShareLinkDocument = gql`
    mutation CreatePrivateShareLink($expirationDate: DateTime!, $projectId: ID, $resources: [Resource]!, $userIds: [ID]!, $groups: [SelectedGroup]) {
  createPrivateShareLink(
    expirationDate: $expirationDate
    projectId: $projectId
    resources: $resources
    userIds: $userIds
    groups: $groups
  ) {
    id
    link
    errors
    success
  }
}
    `;
export type CreatePrivateShareLinkMutationFn = Apollo.MutationFunction<CreatePrivateShareLinkMutation, CreatePrivateShareLinkMutationVariables>;

/**
 * __useCreatePrivateShareLinkMutation__
 *
 * To run a mutation, you first call `useCreatePrivateShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateShareLinkMutation, { data, loading, error }] = useCreatePrivateShareLinkMutation({
 *   variables: {
 *      expirationDate: // value for 'expirationDate'
 *      projectId: // value for 'projectId'
 *      resources: // value for 'resources'
 *      userIds: // value for 'userIds'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreatePrivateShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrivateShareLinkMutation, CreatePrivateShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrivateShareLinkMutation, CreatePrivateShareLinkMutationVariables>(CreatePrivateShareLinkDocument, options);
      }
export type CreatePrivateShareLinkMutationHookResult = ReturnType<typeof useCreatePrivateShareLinkMutation>;
export type CreatePrivateShareLinkMutationResult = Apollo.MutationResult<CreatePrivateShareLinkMutation>;
export type CreatePrivateShareLinkMutationOptions = Apollo.BaseMutationOptions<CreatePrivateShareLinkMutation, CreatePrivateShareLinkMutationVariables>;
export const CreateScopedShareLinkDocument = gql`
    mutation CreateScopedShareLink($expirationDate: DateTime!, $resources: [Resource]!, $projectId: ID) {
  createScopedShareLink(
    expirationDate: $expirationDate
    resources: $resources
    projectId: $projectId
  ) {
    id
    link
    errors
    success
  }
}
    `;
export type CreateScopedShareLinkMutationFn = Apollo.MutationFunction<CreateScopedShareLinkMutation, CreateScopedShareLinkMutationVariables>;

/**
 * __useCreateScopedShareLinkMutation__
 *
 * To run a mutation, you first call `useCreateScopedShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScopedShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScopedShareLinkMutation, { data, loading, error }] = useCreateScopedShareLinkMutation({
 *   variables: {
 *      expirationDate: // value for 'expirationDate'
 *      resources: // value for 'resources'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateScopedShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateScopedShareLinkMutation, CreateScopedShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScopedShareLinkMutation, CreateScopedShareLinkMutationVariables>(CreateScopedShareLinkDocument, options);
      }
export type CreateScopedShareLinkMutationHookResult = ReturnType<typeof useCreateScopedShareLinkMutation>;
export type CreateScopedShareLinkMutationResult = Apollo.MutationResult<CreateScopedShareLinkMutation>;
export type CreateScopedShareLinkMutationOptions = Apollo.BaseMutationOptions<CreateScopedShareLinkMutation, CreateScopedShareLinkMutationVariables>;
export const RevokeShareLinkDocument = gql`
    mutation RevokeShareLink($id: ID!) {
  revokeShareLink(id: $id) {
    id
    errors
    success
  }
}
    `;
export type RevokeShareLinkMutationFn = Apollo.MutationFunction<RevokeShareLinkMutation, RevokeShareLinkMutationVariables>;

/**
 * __useRevokeShareLinkMutation__
 *
 * To run a mutation, you first call `useRevokeShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeShareLinkMutation, { data, loading, error }] = useRevokeShareLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<RevokeShareLinkMutation, RevokeShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeShareLinkMutation, RevokeShareLinkMutationVariables>(RevokeShareLinkDocument, options);
      }
export type RevokeShareLinkMutationHookResult = ReturnType<typeof useRevokeShareLinkMutation>;
export type RevokeShareLinkMutationResult = Apollo.MutationResult<RevokeShareLinkMutation>;
export type RevokeShareLinkMutationOptions = Apollo.BaseMutationOptions<RevokeShareLinkMutation, RevokeShareLinkMutationVariables>;
export const SendShareLinkDocument = gql`
    mutation SendShareLink($id: ID!, $subject: String, $body: String, $copyToSelf: Boolean, $userIds: [ID!], $groupIds: [ID!], $projectId: ID, $to: [String!]) {
  sendShareLink(
    id: $id
    subject: $subject
    body: $body
    copyToSelf: $copyToSelf
    userIds: $userIds
    groupIds: $groupIds
    projectId: $projectId
    to: $to
  ) {
    id
    errors
    success
  }
}
    `;
export type SendShareLinkMutationFn = Apollo.MutationFunction<SendShareLinkMutation, SendShareLinkMutationVariables>;

/**
 * __useSendShareLinkMutation__
 *
 * To run a mutation, you first call `useSendShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendShareLinkMutation, { data, loading, error }] = useSendShareLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *      copyToSelf: // value for 'copyToSelf'
 *      userIds: // value for 'userIds'
 *      groupIds: // value for 'groupIds'
 *      projectId: // value for 'projectId'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSendShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendShareLinkMutation, SendShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendShareLinkMutation, SendShareLinkMutationVariables>(SendShareLinkDocument, options);
      }
export type SendShareLinkMutationHookResult = ReturnType<typeof useSendShareLinkMutation>;
export type SendShareLinkMutationResult = Apollo.MutationResult<SendShareLinkMutation>;
export type SendShareLinkMutationOptions = Apollo.BaseMutationOptions<SendShareLinkMutation, SendShareLinkMutationVariables>;
export const CreateSmartFolderDocument = gql`
    mutation CreateSmartFolder($name: String!, $tagIds: [ID], $fileTypes: [String], $projectId: ID) {
  createSmartFolder(
    name: $name
    tagIds: $tagIds
    fileTypes: $fileTypes
    projectId: $projectId
  )
}
    `;
export type CreateSmartFolderMutationFn = Apollo.MutationFunction<CreateSmartFolderMutation, CreateSmartFolderMutationVariables>;

/**
 * __useCreateSmartFolderMutation__
 *
 * To run a mutation, you first call `useCreateSmartFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartFolderMutation, { data, loading, error }] = useCreateSmartFolderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tagIds: // value for 'tagIds'
 *      fileTypes: // value for 'fileTypes'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateSmartFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateSmartFolderMutation, CreateSmartFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSmartFolderMutation, CreateSmartFolderMutationVariables>(CreateSmartFolderDocument, options);
      }
export type CreateSmartFolderMutationHookResult = ReturnType<typeof useCreateSmartFolderMutation>;
export type CreateSmartFolderMutationResult = Apollo.MutationResult<CreateSmartFolderMutation>;
export type CreateSmartFolderMutationOptions = Apollo.BaseMutationOptions<CreateSmartFolderMutation, CreateSmartFolderMutationVariables>;
export const UpdateSmartFolderDocument = gql`
    mutation UpdateSmartFolder($id: ID!, $name: String!, $tagIds: [ID], $fileTypes: [String]) {
  updateSmartFolder(id: $id, name: $name, tagIds: $tagIds, fileTypes: $fileTypes)
}
    `;
export type UpdateSmartFolderMutationFn = Apollo.MutationFunction<UpdateSmartFolderMutation, UpdateSmartFolderMutationVariables>;

/**
 * __useUpdateSmartFolderMutation__
 *
 * To run a mutation, you first call `useUpdateSmartFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmartFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmartFolderMutation, { data, loading, error }] = useUpdateSmartFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      tagIds: // value for 'tagIds'
 *      fileTypes: // value for 'fileTypes'
 *   },
 * });
 */
export function useUpdateSmartFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmartFolderMutation, UpdateSmartFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSmartFolderMutation, UpdateSmartFolderMutationVariables>(UpdateSmartFolderDocument, options);
      }
export type UpdateSmartFolderMutationHookResult = ReturnType<typeof useUpdateSmartFolderMutation>;
export type UpdateSmartFolderMutationResult = Apollo.MutationResult<UpdateSmartFolderMutation>;
export type UpdateSmartFolderMutationOptions = Apollo.BaseMutationOptions<UpdateSmartFolderMutation, UpdateSmartFolderMutationVariables>;
export const DeleteSmartFolderDocument = gql`
    mutation DeleteSmartFolder($id: ID!) {
  deleteSmartFolder(id: $id)
}
    `;
export type DeleteSmartFolderMutationFn = Apollo.MutationFunction<DeleteSmartFolderMutation, DeleteSmartFolderMutationVariables>;

/**
 * __useDeleteSmartFolderMutation__
 *
 * To run a mutation, you first call `useDeleteSmartFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmartFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmartFolderMutation, { data, loading, error }] = useDeleteSmartFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmartFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmartFolderMutation, DeleteSmartFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSmartFolderMutation, DeleteSmartFolderMutationVariables>(DeleteSmartFolderDocument, options);
      }
export type DeleteSmartFolderMutationHookResult = ReturnType<typeof useDeleteSmartFolderMutation>;
export type DeleteSmartFolderMutationResult = Apollo.MutationResult<DeleteSmartFolderMutation>;
export type DeleteSmartFolderMutationOptions = Apollo.BaseMutationOptions<DeleteSmartFolderMutation, DeleteSmartFolderMutationVariables>;
export const RestoreSmartFolderDocument = gql`
    mutation RestoreSmartFolder($id: ID!) {
  restoreSmartFolder(id: $id)
}
    `;
export type RestoreSmartFolderMutationFn = Apollo.MutationFunction<RestoreSmartFolderMutation, RestoreSmartFolderMutationVariables>;

/**
 * __useRestoreSmartFolderMutation__
 *
 * To run a mutation, you first call `useRestoreSmartFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSmartFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSmartFolderMutation, { data, loading, error }] = useRestoreSmartFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreSmartFolderMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSmartFolderMutation, RestoreSmartFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreSmartFolderMutation, RestoreSmartFolderMutationVariables>(RestoreSmartFolderDocument, options);
      }
export type RestoreSmartFolderMutationHookResult = ReturnType<typeof useRestoreSmartFolderMutation>;
export type RestoreSmartFolderMutationResult = Apollo.MutationResult<RestoreSmartFolderMutation>;
export type RestoreSmartFolderMutationOptions = Apollo.BaseMutationOptions<RestoreSmartFolderMutation, RestoreSmartFolderMutationVariables>;
export const StampDocumentDocument = gql`
    mutation StampDocument($documentId: ID!, $documentVersionId: ID!, $params: StampParams!) {
  stampDocument(
    documentId: $documentId
    documentVersionId: $documentVersionId
    params: $params
  ) {
    documentId
    errors
    success
  }
}
    `;
export type StampDocumentMutationFn = Apollo.MutationFunction<StampDocumentMutation, StampDocumentMutationVariables>;

/**
 * __useStampDocumentMutation__
 *
 * To run a mutation, you first call `useStampDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStampDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stampDocumentMutation, { data, loading, error }] = useStampDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentVersionId: // value for 'documentVersionId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStampDocumentMutation(baseOptions?: Apollo.MutationHookOptions<StampDocumentMutation, StampDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StampDocumentMutation, StampDocumentMutationVariables>(StampDocumentDocument, options);
      }
export type StampDocumentMutationHookResult = ReturnType<typeof useStampDocumentMutation>;
export type StampDocumentMutationResult = Apollo.MutationResult<StampDocumentMutation>;
export type StampDocumentMutationOptions = Apollo.BaseMutationOptions<StampDocumentMutation, StampDocumentMutationVariables>;
export const RemoveStampsDocument = gql`
    mutation RemoveStamps($stamps: [RemoveStampInput!]!) {
  removeStamps(stamps: $stamps) {
    ids
    errors
    success
  }
}
    `;
export type RemoveStampsMutationFn = Apollo.MutationFunction<RemoveStampsMutation, RemoveStampsMutationVariables>;

/**
 * __useRemoveStampsMutation__
 *
 * To run a mutation, you first call `useRemoveStampsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStampsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStampsMutation, { data, loading, error }] = useRemoveStampsMutation({
 *   variables: {
 *      stamps: // value for 'stamps'
 *   },
 * });
 */
export function useRemoveStampsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStampsMutation, RemoveStampsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStampsMutation, RemoveStampsMutationVariables>(RemoveStampsDocument, options);
      }
export type RemoveStampsMutationHookResult = ReturnType<typeof useRemoveStampsMutation>;
export type RemoveStampsMutationResult = Apollo.MutationResult<RemoveStampsMutation>;
export type RemoveStampsMutationOptions = Apollo.BaseMutationOptions<RemoveStampsMutation, RemoveStampsMutationVariables>;
export const SupportedFormatsDocument = gql`
    query SupportedFormats {
  supportedFormats
}
    `;

/**
 * __useSupportedFormatsQuery__
 *
 * To run a query within a React component, call `useSupportedFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportedFormatsQuery(baseOptions?: Apollo.QueryHookOptions<SupportedFormatsQuery, SupportedFormatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedFormatsQuery, SupportedFormatsQueryVariables>(SupportedFormatsDocument, options);
      }
export function useSupportedFormatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedFormatsQuery, SupportedFormatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedFormatsQuery, SupportedFormatsQueryVariables>(SupportedFormatsDocument, options);
        }
export type SupportedFormatsQueryHookResult = ReturnType<typeof useSupportedFormatsQuery>;
export type SupportedFormatsLazyQueryHookResult = ReturnType<typeof useSupportedFormatsLazyQuery>;
export type SupportedFormatsQueryResult = Apollo.QueryResult<SupportedFormatsQuery, SupportedFormatsQueryVariables>;
export const DocumentHistoryDocument = gql`
    query DocumentHistory($documentId: ID!, $versionId: ID, $fetchForVersion: Boolean!, $metadataInstanceId: ID, $projectId: ID) {
  documentHistory(
    documentId: $documentId
    versionId: $versionId
    metadataInstanceId: $metadataInstanceId
    fetchForVersion: $fetchForVersion
    projectId: $projectId
  ) {
    today {
      ...DocumentHistoryEventFragment
    }
    thisWeek {
      ...DocumentHistoryEventFragment
    }
    thisMonth {
      ...DocumentHistoryEventFragment
    }
    older {
      ...DocumentHistoryEventFragment
    }
  }
}
    ${DocumentHistoryEventFragmentFragmentDoc}`;

/**
 * __useDocumentHistoryQuery__
 *
 * To run a query within a React component, call `useDocumentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentHistoryQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *      fetchForVersion: // value for 'fetchForVersion'
 *      metadataInstanceId: // value for 'metadataInstanceId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDocumentHistoryQuery(baseOptions: Apollo.QueryHookOptions<DocumentHistoryQuery, DocumentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentHistoryQuery, DocumentHistoryQueryVariables>(DocumentHistoryDocument, options);
      }
export function useDocumentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentHistoryQuery, DocumentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentHistoryQuery, DocumentHistoryQueryVariables>(DocumentHistoryDocument, options);
        }
export type DocumentHistoryQueryHookResult = ReturnType<typeof useDocumentHistoryQuery>;
export type DocumentHistoryLazyQueryHookResult = ReturnType<typeof useDocumentHistoryLazyQuery>;
export type DocumentHistoryQueryResult = Apollo.QueryResult<DocumentHistoryQuery, DocumentHistoryQueryVariables>;
export const PublicAttachmentDataDocument = gql`
    query PublicAttachmentData($id: ID!, $signature: String!, $template: String!) {
  publicAttachmentData(id: $id, signature: $signature, template: $template) {
    id
    createdAt
    createdBy
    expirationDate
    linkType
    scopeId
    zipDownloadUrl
    messageAttachments {
      id
      name
      downloadUrl
    }
    errors
    success
  }
}
    `;

/**
 * __usePublicAttachmentDataQuery__
 *
 * To run a query within a React component, call `usePublicAttachmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAttachmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAttachmentDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *      template: // value for 'template'
 *   },
 * });
 */
export function usePublicAttachmentDataQuery(baseOptions: Apollo.QueryHookOptions<PublicAttachmentDataQuery, PublicAttachmentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicAttachmentDataQuery, PublicAttachmentDataQueryVariables>(PublicAttachmentDataDocument, options);
      }
export function usePublicAttachmentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicAttachmentDataQuery, PublicAttachmentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicAttachmentDataQuery, PublicAttachmentDataQueryVariables>(PublicAttachmentDataDocument, options);
        }
export type PublicAttachmentDataQueryHookResult = ReturnType<typeof usePublicAttachmentDataQuery>;
export type PublicAttachmentDataLazyQueryHookResult = ReturnType<typeof usePublicAttachmentDataLazyQuery>;
export type PublicAttachmentDataQueryResult = Apollo.QueryResult<PublicAttachmentDataQuery, PublicAttachmentDataQueryVariables>;
export const PublicLinkDataDocument = gql`
    query PublicLinkData($id: ID!, $password: String, $signature: String!, $template: String!) {
  publicLinkData(
    id: $id
    password: $password
    signature: $signature
    template: $template
  ) {
    ...SharedLinkFragment
    documentLinks {
      createdAt
      createdBy {
        id
        displayName
      }
      documentId
      downloadUrl
      extension
      fileSize
      folderId
      id
      name
    }
    errors
    success
  }
}
    ${SharedLinkFragmentFragmentDoc}`;

/**
 * __usePublicLinkDataQuery__
 *
 * To run a query within a React component, call `usePublicLinkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicLinkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicLinkDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *      signature: // value for 'signature'
 *      template: // value for 'template'
 *   },
 * });
 */
export function usePublicLinkDataQuery(baseOptions: Apollo.QueryHookOptions<PublicLinkDataQuery, PublicLinkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicLinkDataQuery, PublicLinkDataQueryVariables>(PublicLinkDataDocument, options);
      }
export function usePublicLinkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicLinkDataQuery, PublicLinkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicLinkDataQuery, PublicLinkDataQueryVariables>(PublicLinkDataDocument, options);
        }
export type PublicLinkDataQueryHookResult = ReturnType<typeof usePublicLinkDataQuery>;
export type PublicLinkDataLazyQueryHookResult = ReturnType<typeof usePublicLinkDataLazyQuery>;
export type PublicLinkDataQueryResult = Apollo.QueryResult<PublicLinkDataQuery, PublicLinkDataQueryVariables>;
export const PrivateLinkDataDocument = gql`
    query PrivateLinkData($id: ID!, $template: String!, $signature: String!) {
  privateLinkData(id: $id, template: $template, signature: $signature) {
    ...SharedLinkFragment
    documentLinks {
      createdAt
      createdBy {
        id
        displayName
      }
      documentId
      downloadUrl
      extension
      fileSize
      folderId
      id
      name
      path
    }
    errors
    success
  }
}
    ${SharedLinkFragmentFragmentDoc}`;

/**
 * __usePrivateLinkDataQuery__
 *
 * To run a query within a React component, call `usePrivateLinkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateLinkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateLinkDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      template: // value for 'template'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function usePrivateLinkDataQuery(baseOptions: Apollo.QueryHookOptions<PrivateLinkDataQuery, PrivateLinkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivateLinkDataQuery, PrivateLinkDataQueryVariables>(PrivateLinkDataDocument, options);
      }
export function usePrivateLinkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivateLinkDataQuery, PrivateLinkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivateLinkDataQuery, PrivateLinkDataQueryVariables>(PrivateLinkDataDocument, options);
        }
export type PrivateLinkDataQueryHookResult = ReturnType<typeof usePrivateLinkDataQuery>;
export type PrivateLinkDataLazyQueryHookResult = ReturnType<typeof usePrivateLinkDataLazyQuery>;
export type PrivateLinkDataQueryResult = Apollo.QueryResult<PrivateLinkDataQuery, PrivateLinkDataQueryVariables>;
export const ScopedLinkDataDocument = gql`
    query ScopedLinkData($id: ID!, $template: String!, $signature: String!) {
  scopedLinkData(id: $id, template: $template, signature: $signature) {
    ...SharedLinkFragment
    documentLinks {
      createdAt
      createdBy {
        id
        displayName
      }
      documentId
      downloadUrl
      extension
      fileSize
      folderId
      id
      name
      path
    }
    errors
    success
  }
}
    ${SharedLinkFragmentFragmentDoc}`;

/**
 * __useScopedLinkDataQuery__
 *
 * To run a query within a React component, call `useScopedLinkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopedLinkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopedLinkDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      template: // value for 'template'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useScopedLinkDataQuery(baseOptions: Apollo.QueryHookOptions<ScopedLinkDataQuery, ScopedLinkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScopedLinkDataQuery, ScopedLinkDataQueryVariables>(ScopedLinkDataDocument, options);
      }
export function useScopedLinkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScopedLinkDataQuery, ScopedLinkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScopedLinkDataQuery, ScopedLinkDataQueryVariables>(ScopedLinkDataDocument, options);
        }
export type ScopedLinkDataQueryHookResult = ReturnType<typeof useScopedLinkDataQuery>;
export type ScopedLinkDataLazyQueryHookResult = ReturnType<typeof useScopedLinkDataLazyQuery>;
export type ScopedLinkDataQueryResult = Apollo.QueryResult<ScopedLinkDataQuery, ScopedLinkDataQueryVariables>;
export const StampsDocument = gql`
    query Stamps($ids: [ID!]!) {
  stamps(ids: $ids) {
    data {
      ...StampFragment
    }
    errors
    success
  }
}
    ${StampFragmentFragmentDoc}`;

/**
 * __useStampsQuery__
 *
 * To run a query within a React component, call `useStampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStampsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useStampsQuery(baseOptions: Apollo.QueryHookOptions<StampsQuery, StampsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StampsQuery, StampsQueryVariables>(StampsDocument, options);
      }
export function useStampsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StampsQuery, StampsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StampsQuery, StampsQueryVariables>(StampsDocument, options);
        }
export type StampsQueryHookResult = ReturnType<typeof useStampsQuery>;
export type StampsLazyQueryHookResult = ReturnType<typeof useStampsLazyQuery>;
export type StampsQueryResult = Apollo.QueryResult<StampsQuery, StampsQueryVariables>;
export const QrCodeStateDocument = gql`
    query QrCodeState($versionId: ID!) {
  qrCodeState(versionId: $versionId)
}
    `;

/**
 * __useQrCodeStateQuery__
 *
 * To run a query within a React component, call `useQrCodeStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeStateQuery({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useQrCodeStateQuery(baseOptions: Apollo.QueryHookOptions<QrCodeStateQuery, QrCodeStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QrCodeStateQuery, QrCodeStateQueryVariables>(QrCodeStateDocument, options);
      }
export function useQrCodeStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QrCodeStateQuery, QrCodeStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QrCodeStateQuery, QrCodeStateQueryVariables>(QrCodeStateDocument, options);
        }
export type QrCodeStateQueryHookResult = ReturnType<typeof useQrCodeStateQuery>;
export type QrCodeStateLazyQueryHookResult = ReturnType<typeof useQrCodeStateLazyQuery>;
export type QrCodeStateQueryResult = Apollo.QueryResult<QrCodeStateQuery, QrCodeStateQueryVariables>;
export const DocumentDocument = gql`
    query Document($id: ID!) {
  document(id: $id) {
    extension
    ...DocumentFragment
    ...DocumentMetaDataFragment
    ...DocumentVersionsFragment
    keywords {
      text
      score
    }
    taskLinks {
      task {
        id
        name
        description
        procedureId
        documentAction
        status
        endTime
        assignment {
          displayName
          id
        }
      }
      type
    }
    summary {
      text
      status
    }
    visualContext {
      id
      name
      status
    }
  }
}
    ${DocumentFragmentFragmentDoc}
${DocumentMetaDataFragmentFragmentDoc}
${DocumentVersionsFragmentFragmentDoc}`;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
      }
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const DocumentStateDocument = gql`
    query DocumentState($id: ID!) {
  documentState(id: $id) {
    id
    name
    state
  }
}
    `;

/**
 * __useDocumentStateQuery__
 *
 * To run a query within a React component, call `useDocumentStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentStateQuery(baseOptions: Apollo.QueryHookOptions<DocumentStateQuery, DocumentStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentStateQuery, DocumentStateQueryVariables>(DocumentStateDocument, options);
      }
export function useDocumentStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentStateQuery, DocumentStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentStateQuery, DocumentStateQueryVariables>(DocumentStateDocument, options);
        }
export type DocumentStateQueryHookResult = ReturnType<typeof useDocumentStateQuery>;
export type DocumentStateLazyQueryHookResult = ReturnType<typeof useDocumentStateLazyQuery>;
export type DocumentStateQueryResult = Apollo.QueryResult<DocumentStateQuery, DocumentStateQueryVariables>;
export const DocumentsDocument = gql`
    query Documents($documentIds: [ID]!) {
  documents(documentIds: $documentIds) {
    extension
    ...DocumentFragment
    visualContext {
      id
      name
      status
    }
  }
}
    ${DocumentFragmentFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const VersionDocument = gql`
    query Version($id: ID!) {
  version(id: $id) {
    createdAt
    createdBy {
      id
      displayName
      organization {
        name
      }
    }
    documentId
    extension
    fileSize
    folderId
    id
    keywords {
      text
      score
    }
    metadataInstanceId
    name
    projectId
    state
    totalPreviewPages
    updatedAt
    updatedBy {
      id
      displayName
    }
    summary {
      text
      status
    }
    versionNumber
    versions {
      ...VersionFragment
    }
    versionState
  }
}
    ${VersionFragmentFragmentDoc}`;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVersionQuery(baseOptions: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
      }
export function useVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const VersionsIncludeDocument = gql`
    query VersionsInclude($ids: [ID!]!, $includeFileSize: Boolean = false, $includeDocumentId: Boolean = false, $includeName: Boolean = false, $includePath: Boolean = false, $includeProjectId: Boolean = false, $includeFolderId: Boolean = false, $includeQrCodeState: Boolean = false) {
  versions(ids: $ids) {
    ...VersionFragmentInclude
  }
}
    ${VersionFragmentIncludeFragmentDoc}`;

/**
 * __useVersionsIncludeQuery__
 *
 * To run a query within a React component, call `useVersionsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionsIncludeQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      includeFileSize: // value for 'includeFileSize'
 *      includeDocumentId: // value for 'includeDocumentId'
 *      includeName: // value for 'includeName'
 *      includePath: // value for 'includePath'
 *      includeProjectId: // value for 'includeProjectId'
 *      includeFolderId: // value for 'includeFolderId'
 *      includeQrCodeState: // value for 'includeQrCodeState'
 *   },
 * });
 */
export function useVersionsIncludeQuery(baseOptions: Apollo.QueryHookOptions<VersionsIncludeQuery, VersionsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionsIncludeQuery, VersionsIncludeQueryVariables>(VersionsIncludeDocument, options);
      }
export function useVersionsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionsIncludeQuery, VersionsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionsIncludeQuery, VersionsIncludeQueryVariables>(VersionsIncludeDocument, options);
        }
export type VersionsIncludeQueryHookResult = ReturnType<typeof useVersionsIncludeQuery>;
export type VersionsIncludeLazyQueryHookResult = ReturnType<typeof useVersionsIncludeLazyQuery>;
export type VersionsIncludeQueryResult = Apollo.QueryResult<VersionsIncludeQuery, VersionsIncludeQueryVariables>;
export const PdfTronLicenseKeyDocument = gql`
    query PdfTronLicenseKey($documentVersionId: ID!) {
  pdfTronLicenseKey(documentVersionId: $documentVersionId)
}
    `;

/**
 * __usePdfTronLicenseKeyQuery__
 *
 * To run a query within a React component, call `usePdfTronLicenseKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfTronLicenseKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfTronLicenseKeyQuery({
 *   variables: {
 *      documentVersionId: // value for 'documentVersionId'
 *   },
 * });
 */
export function usePdfTronLicenseKeyQuery(baseOptions: Apollo.QueryHookOptions<PdfTronLicenseKeyQuery, PdfTronLicenseKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfTronLicenseKeyQuery, PdfTronLicenseKeyQueryVariables>(PdfTronLicenseKeyDocument, options);
      }
export function usePdfTronLicenseKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfTronLicenseKeyQuery, PdfTronLicenseKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfTronLicenseKeyQuery, PdfTronLicenseKeyQueryVariables>(PdfTronLicenseKeyDocument, options);
        }
export type PdfTronLicenseKeyQueryHookResult = ReturnType<typeof usePdfTronLicenseKeyQuery>;
export type PdfTronLicenseKeyLazyQueryHookResult = ReturnType<typeof usePdfTronLicenseKeyLazyQuery>;
export type PdfTronLicenseKeyQueryResult = Apollo.QueryResult<PdfTronLicenseKeyQuery, PdfTronLicenseKeyQueryVariables>;
export const AnnotationsDocument = gql`
    query Annotations($documentId: ID!, $versionId: ID!) {
  annotations(documentId: $documentId, versionId: $versionId) {
    ...AnnotationFragment
  }
}
    ${AnnotationFragmentFragmentDoc}`;

/**
 * __useAnnotationsQuery__
 *
 * To run a query within a React component, call `useAnnotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotationsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useAnnotationsQuery(baseOptions: Apollo.QueryHookOptions<AnnotationsQuery, AnnotationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnnotationsQuery, AnnotationsQueryVariables>(AnnotationsDocument, options);
      }
export function useAnnotationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnnotationsQuery, AnnotationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnnotationsQuery, AnnotationsQueryVariables>(AnnotationsDocument, options);
        }
export type AnnotationsQueryHookResult = ReturnType<typeof useAnnotationsQuery>;
export type AnnotationsLazyQueryHookResult = ReturnType<typeof useAnnotationsLazyQuery>;
export type AnnotationsQueryResult = Apollo.QueryResult<AnnotationsQuery, AnnotationsQueryVariables>;
export const TagsDocument = gql`
    query Tags {
  tags {
    id
    name
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const FolderDocument = gql`
    query Folder($id: ID!) {
  folder(id: $id) {
    fileInformation {
      amountOfDocuments
      amountOfVersions
      totalFileSize
    }
    ...FolderFragment
    documents {
      id
      name
    }
  }
}
    ${FolderFragmentFragmentDoc}`;

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderQuery(baseOptions: Apollo.QueryHookOptions<FolderQuery, FolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
      }
export function useFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options);
        }
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>;
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>;
export type FolderQueryResult = Apollo.QueryResult<FolderQuery, FolderQueryVariables>;
export const SearchResultFolderDocument = gql`
    query SearchResultFolder($id: ID!) {
  folder(id: $id) {
    fileInformation {
      amountOfDocuments
      amountOfVersions
      totalFileSize
    }
    ...FolderFragment
    ...FolderMetaDataFragment
  }
}
    ${FolderFragmentFragmentDoc}
${FolderMetaDataFragmentFragmentDoc}`;

/**
 * __useSearchResultFolderQuery__
 *
 * To run a query within a React component, call `useSearchResultFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchResultFolderQuery(baseOptions: Apollo.QueryHookOptions<SearchResultFolderQuery, SearchResultFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchResultFolderQuery, SearchResultFolderQueryVariables>(SearchResultFolderDocument, options);
      }
export function useSearchResultFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchResultFolderQuery, SearchResultFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchResultFolderQuery, SearchResultFolderQueryVariables>(SearchResultFolderDocument, options);
        }
export type SearchResultFolderQueryHookResult = ReturnType<typeof useSearchResultFolderQuery>;
export type SearchResultFolderLazyQueryHookResult = ReturnType<typeof useSearchResultFolderLazyQuery>;
export type SearchResultFolderQueryResult = Apollo.QueryResult<SearchResultFolderQuery, SearchResultFolderQueryVariables>;
export const FolderByPathDocument = gql`
    query FolderByPath($path: String!, $projectId: ID) {
  folder: folderByPath(path: $path, projectId: $projectId) {
    ...FolderFragment
    ...FolderMetaDataFragment
    documents {
      ...DocumentFragment
      ...DocumentMetaDataFragment
    }
    availablePermissions
  }
}
    ${FolderFragmentFragmentDoc}
${FolderMetaDataFragmentFragmentDoc}
${DocumentFragmentFragmentDoc}
${DocumentMetaDataFragmentFragmentDoc}`;

/**
 * __useFolderByPathQuery__
 *
 * To run a query within a React component, call `useFolderByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByPathQuery({
 *   variables: {
 *      path: // value for 'path'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFolderByPathQuery(baseOptions: Apollo.QueryHookOptions<FolderByPathQuery, FolderByPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderByPathQuery, FolderByPathQueryVariables>(FolderByPathDocument, options);
      }
export function useFolderByPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderByPathQuery, FolderByPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderByPathQuery, FolderByPathQueryVariables>(FolderByPathDocument, options);
        }
export type FolderByPathQueryHookResult = ReturnType<typeof useFolderByPathQuery>;
export type FolderByPathLazyQueryHookResult = ReturnType<typeof useFolderByPathLazyQuery>;
export type FolderByPathQueryResult = Apollo.QueryResult<FolderByPathQuery, FolderByPathQueryVariables>;
export const BaseFolderByPathDocument = gql`
    query BaseFolderByPath($path: String!, $projectId: ID) {
  folder: baseFolderByPath(path: $path, projectId: $projectId) {
    ...FolderFragment
  }
}
    ${FolderFragmentFragmentDoc}`;

/**
 * __useBaseFolderByPathQuery__
 *
 * To run a query within a React component, call `useBaseFolderByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseFolderByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseFolderByPathQuery({
 *   variables: {
 *      path: // value for 'path'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBaseFolderByPathQuery(baseOptions: Apollo.QueryHookOptions<BaseFolderByPathQuery, BaseFolderByPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseFolderByPathQuery, BaseFolderByPathQueryVariables>(BaseFolderByPathDocument, options);
      }
export function useBaseFolderByPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseFolderByPathQuery, BaseFolderByPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseFolderByPathQuery, BaseFolderByPathQueryVariables>(BaseFolderByPathDocument, options);
        }
export type BaseFolderByPathQueryHookResult = ReturnType<typeof useBaseFolderByPathQuery>;
export type BaseFolderByPathLazyQueryHookResult = ReturnType<typeof useBaseFolderByPathLazyQuery>;
export type BaseFolderByPathQueryResult = Apollo.QueryResult<BaseFolderByPathQuery, BaseFolderByPathQueryVariables>;
export const FolderWithPermissionsByPathDocument = gql`
    query FolderWithPermissionsByPath($entityIds: [ID], $forceOrganizationGroups: Boolean, $inherit: Boolean, $path: String!, $projectId: ID) {
  folder: folderWithPermissionsByPath(
    forceOrganizationGroups: $forceOrganizationGroups
    path: $path
    projectId: $projectId
  ) {
    entityFolderPermissions(
      forceOrganizationGroups: $forceOrganizationGroups
      inherit: $inherit
      ids: $entityIds
      projectId: $projectId
    ) {
      ...EntityPermissionsFragment
      ...InheritedPermissionsFragment
    }
    id
    name
    parentFolderId
  }
}
    ${EntityPermissionsFragmentFragmentDoc}
${InheritedPermissionsFragmentFragmentDoc}`;

/**
 * __useFolderWithPermissionsByPathQuery__
 *
 * To run a query within a React component, call `useFolderWithPermissionsByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderWithPermissionsByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderWithPermissionsByPathQuery({
 *   variables: {
 *      entityIds: // value for 'entityIds'
 *      forceOrganizationGroups: // value for 'forceOrganizationGroups'
 *      inherit: // value for 'inherit'
 *      path: // value for 'path'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFolderWithPermissionsByPathQuery(baseOptions: Apollo.QueryHookOptions<FolderWithPermissionsByPathQuery, FolderWithPermissionsByPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderWithPermissionsByPathQuery, FolderWithPermissionsByPathQueryVariables>(FolderWithPermissionsByPathDocument, options);
      }
export function useFolderWithPermissionsByPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderWithPermissionsByPathQuery, FolderWithPermissionsByPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderWithPermissionsByPathQuery, FolderWithPermissionsByPathQueryVariables>(FolderWithPermissionsByPathDocument, options);
        }
export type FolderWithPermissionsByPathQueryHookResult = ReturnType<typeof useFolderWithPermissionsByPathQuery>;
export type FolderWithPermissionsByPathLazyQueryHookResult = ReturnType<typeof useFolderWithPermissionsByPathLazyQuery>;
export type FolderWithPermissionsByPathQueryResult = Apollo.QueryResult<FolderWithPermissionsByPathQuery, FolderWithPermissionsByPathQueryVariables>;
export const FolderWithEntityPermissionsDocument = gql`
    query FolderWithEntityPermissions($forceOrganizationGroups: Boolean, $id: ID!, $entityIds: [ID], $projectId: ID) {
  folder(id: $id) {
    ...FolderFragment
    availablePermissions
    entityFolderPermissions(
      forceOrganizationGroups: $forceOrganizationGroups
      ids: $entityIds
      projectId: $projectId
    ) {
      ...EntityPermissionsFragment
      ...InheritedPermissionsFragment
    }
  }
}
    ${FolderFragmentFragmentDoc}
${EntityPermissionsFragmentFragmentDoc}
${InheritedPermissionsFragmentFragmentDoc}`;

/**
 * __useFolderWithEntityPermissionsQuery__
 *
 * To run a query within a React component, call `useFolderWithEntityPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderWithEntityPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderWithEntityPermissionsQuery({
 *   variables: {
 *      forceOrganizationGroups: // value for 'forceOrganizationGroups'
 *      id: // value for 'id'
 *      entityIds: // value for 'entityIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFolderWithEntityPermissionsQuery(baseOptions: Apollo.QueryHookOptions<FolderWithEntityPermissionsQuery, FolderWithEntityPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FolderWithEntityPermissionsQuery, FolderWithEntityPermissionsQueryVariables>(FolderWithEntityPermissionsDocument, options);
      }
export function useFolderWithEntityPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderWithEntityPermissionsQuery, FolderWithEntityPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FolderWithEntityPermissionsQuery, FolderWithEntityPermissionsQueryVariables>(FolderWithEntityPermissionsDocument, options);
        }
export type FolderWithEntityPermissionsQueryHookResult = ReturnType<typeof useFolderWithEntityPermissionsQuery>;
export type FolderWithEntityPermissionsLazyQueryHookResult = ReturnType<typeof useFolderWithEntityPermissionsLazyQuery>;
export type FolderWithEntityPermissionsQueryResult = Apollo.QueryResult<FolderWithEntityPermissionsQuery, FolderWithEntityPermissionsQueryVariables>;
export const FoldersDocument = gql`
    query Folders($entityId: ID, $forceOrganizationGroups: Boolean, $inherit: Boolean, $parentFolderIds: [ID], $projectId: ID) {
  folders(parentFolderIds: $parentFolderIds, projectId: $projectId) {
    ...FolderFragment
    entityFolderPermissions(
      forceOrganizationGroups: $forceOrganizationGroups
      ids: [$entityId]
      inherit: $inherit
      projectId: $projectId
    ) {
      id
      breakInheritance
      memberId
      folderPermissions
      ...InheritedPermissionsFragment
    }
  }
}
    ${FolderFragmentFragmentDoc}
${InheritedPermissionsFragmentFragmentDoc}`;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      forceOrganizationGroups: // value for 'forceOrganizationGroups'
 *      inherit: // value for 'inherit'
 *      parentFolderIds: // value for 'parentFolderIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFoldersQuery(baseOptions?: Apollo.QueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
      }
export function useFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
        }
export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersQueryResult = Apollo.QueryResult<FoldersQuery, FoldersQueryVariables>;
export const FoldersPermissionsDocument = gql`
    query FoldersPermissions($entityId: ID, $forceOrganizationGroups: Boolean, $includePermissionViaGroup: Boolean, $inherit: Boolean, $parentFolderIds: [ID], $projectId: ID) {
  folders(parentFolderIds: $parentFolderIds, projectId: $projectId) {
    id
    entityFolderPermissions(
      forceOrganizationGroups: $forceOrganizationGroups
      ids: [$entityId]
      includePermissionViaGroup: $includePermissionViaGroup
      inherit: $inherit
      projectId: $projectId
    ) {
      id
      folderPermissions
    }
    name
    parentFolderId
  }
}
    `;

/**
 * __useFoldersPermissionsQuery__
 *
 * To run a query within a React component, call `useFoldersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersPermissionsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      forceOrganizationGroups: // value for 'forceOrganizationGroups'
 *      includePermissionViaGroup: // value for 'includePermissionViaGroup'
 *      inherit: // value for 'inherit'
 *      parentFolderIds: // value for 'parentFolderIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFoldersPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<FoldersPermissionsQuery, FoldersPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoldersPermissionsQuery, FoldersPermissionsQueryVariables>(FoldersPermissionsDocument, options);
      }
export function useFoldersPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersPermissionsQuery, FoldersPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoldersPermissionsQuery, FoldersPermissionsQueryVariables>(FoldersPermissionsDocument, options);
        }
export type FoldersPermissionsQueryHookResult = ReturnType<typeof useFoldersPermissionsQuery>;
export type FoldersPermissionsLazyQueryHookResult = ReturnType<typeof useFoldersPermissionsLazyQuery>;
export type FoldersPermissionsQueryResult = Apollo.QueryResult<FoldersPermissionsQuery, FoldersPermissionsQueryVariables>;
export const FoldersByIdDocument = gql`
    query FoldersById($forceOrganizationGroups: Boolean, $ids: [ID!], $entityId: ID, $projectId: ID) {
  folders: foldersById(ids: $ids) {
    ...FolderFragment
    entityFolderPermissions(
      forceOrganizationGroups: $forceOrganizationGroups
      ids: [$entityId]
      projectId: $projectId
    ) {
      ...EntityPermissionsFragment
    }
  }
}
    ${FolderFragmentFragmentDoc}
${EntityPermissionsFragmentFragmentDoc}`;

/**
 * __useFoldersByIdQuery__
 *
 * To run a query within a React component, call `useFoldersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersByIdQuery({
 *   variables: {
 *      forceOrganizationGroups: // value for 'forceOrganizationGroups'
 *      ids: // value for 'ids'
 *      entityId: // value for 'entityId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFoldersByIdQuery(baseOptions?: Apollo.QueryHookOptions<FoldersByIdQuery, FoldersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoldersByIdQuery, FoldersByIdQueryVariables>(FoldersByIdDocument, options);
      }
export function useFoldersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersByIdQuery, FoldersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoldersByIdQuery, FoldersByIdQueryVariables>(FoldersByIdDocument, options);
        }
export type FoldersByIdQueryHookResult = ReturnType<typeof useFoldersByIdQuery>;
export type FoldersByIdLazyQueryHookResult = ReturnType<typeof useFoldersByIdLazyQuery>;
export type FoldersByIdQueryResult = Apollo.QueryResult<FoldersByIdQuery, FoldersByIdQueryVariables>;
export const ProjectByFolderDocument = gql`
    query ProjectByFolder($ids: [ID]!) {
  projectsByFolder(ids: $ids) {
    success
    errors
    data {
      projectId
      projectName
      folderId
      linkId
    }
  }
}
    `;

/**
 * __useProjectByFolderQuery__
 *
 * To run a query within a React component, call `useProjectByFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByFolderQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProjectByFolderQuery(baseOptions: Apollo.QueryHookOptions<ProjectByFolderQuery, ProjectByFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectByFolderQuery, ProjectByFolderQueryVariables>(ProjectByFolderDocument, options);
      }
export function useProjectByFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectByFolderQuery, ProjectByFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectByFolderQuery, ProjectByFolderQueryVariables>(ProjectByFolderDocument, options);
        }
export type ProjectByFolderQueryHookResult = ReturnType<typeof useProjectByFolderQuery>;
export type ProjectByFolderLazyQueryHookResult = ReturnType<typeof useProjectByFolderLazyQuery>;
export type ProjectByFolderQueryResult = Apollo.QueryResult<ProjectByFolderQuery, ProjectByFolderQueryVariables>;
export const FoldersByPathDocument = gql`
    query FoldersByPath($path: String!, $projectId: ID) {
  folders: foldersByPath(path: $path, projectId: $projectId) {
    ...FolderFragment
  }
}
    ${FolderFragmentFragmentDoc}`;

/**
 * __useFoldersByPathQuery__
 *
 * To run a query within a React component, call `useFoldersByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersByPathQuery({
 *   variables: {
 *      path: // value for 'path'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFoldersByPathQuery(baseOptions: Apollo.QueryHookOptions<FoldersByPathQuery, FoldersByPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoldersByPathQuery, FoldersByPathQueryVariables>(FoldersByPathDocument, options);
      }
export function useFoldersByPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersByPathQuery, FoldersByPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoldersByPathQuery, FoldersByPathQueryVariables>(FoldersByPathDocument, options);
        }
export type FoldersByPathQueryHookResult = ReturnType<typeof useFoldersByPathQuery>;
export type FoldersByPathLazyQueryHookResult = ReturnType<typeof useFoldersByPathLazyQuery>;
export type FoldersByPathQueryResult = Apollo.QueryResult<FoldersByPathQuery, FoldersByPathQueryVariables>;
export const SmartFolderDocument = gql`
    query SmartFolder($id: ID!) {
  smartFolder(id: $id) {
    ...SmartFolderFragment
    documents {
      ...DocumentFragment
      pathIds
    }
  }
}
    ${SmartFolderFragmentFragmentDoc}
${DocumentFragmentFragmentDoc}`;

/**
 * __useSmartFolderQuery__
 *
 * To run a query within a React component, call `useSmartFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSmartFolderQuery(baseOptions: Apollo.QueryHookOptions<SmartFolderQuery, SmartFolderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartFolderQuery, SmartFolderQueryVariables>(SmartFolderDocument, options);
      }
export function useSmartFolderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartFolderQuery, SmartFolderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartFolderQuery, SmartFolderQueryVariables>(SmartFolderDocument, options);
        }
export type SmartFolderQueryHookResult = ReturnType<typeof useSmartFolderQuery>;
export type SmartFolderLazyQueryHookResult = ReturnType<typeof useSmartFolderLazyQuery>;
export type SmartFolderQueryResult = Apollo.QueryResult<SmartFolderQuery, SmartFolderQueryVariables>;
export const SmartFoldersDocument = gql`
    query SmartFolders($projectId: ID) {
  smartFolders(projectId: $projectId) {
    ...SmartFolderFragment
  }
}
    ${SmartFolderFragmentFragmentDoc}`;

/**
 * __useSmartFoldersQuery__
 *
 * To run a query within a React component, call `useSmartFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartFoldersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSmartFoldersQuery(baseOptions?: Apollo.QueryHookOptions<SmartFoldersQuery, SmartFoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartFoldersQuery, SmartFoldersQueryVariables>(SmartFoldersDocument, options);
      }
export function useSmartFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartFoldersQuery, SmartFoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartFoldersQuery, SmartFoldersQueryVariables>(SmartFoldersDocument, options);
        }
export type SmartFoldersQueryHookResult = ReturnType<typeof useSmartFoldersQuery>;
export type SmartFoldersLazyQueryHookResult = ReturnType<typeof useSmartFoldersLazyQuery>;
export type SmartFoldersQueryResult = Apollo.QueryResult<SmartFoldersQuery, SmartFoldersQueryVariables>;
export const ThreeDConversionStatusDocument = gql`
    query ThreeDConversionStatus($versionId: ID!) {
  threeDConversionStatus(versionId: $versionId) {
    fileUrn
    progress
    status
    versionId
  }
}
    `;

/**
 * __useThreeDConversionStatusQuery__
 *
 * To run a query within a React component, call `useThreeDConversionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDConversionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDConversionStatusQuery({
 *   variables: {
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useThreeDConversionStatusQuery(baseOptions: Apollo.QueryHookOptions<ThreeDConversionStatusQuery, ThreeDConversionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDConversionStatusQuery, ThreeDConversionStatusQueryVariables>(ThreeDConversionStatusDocument, options);
      }
export function useThreeDConversionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDConversionStatusQuery, ThreeDConversionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDConversionStatusQuery, ThreeDConversionStatusQueryVariables>(ThreeDConversionStatusDocument, options);
        }
export type ThreeDConversionStatusQueryHookResult = ReturnType<typeof useThreeDConversionStatusQuery>;
export type ThreeDConversionStatusLazyQueryHookResult = ReturnType<typeof useThreeDConversionStatusLazyQuery>;
export type ThreeDConversionStatusQueryResult = Apollo.QueryResult<ThreeDConversionStatusQuery, ThreeDConversionStatusQueryVariables>;
export const ThreeDConversionStatusesDocument = gql`
    query ThreeDConversionStatuses($versionIds: [ID!]!) {
  threeDConversionStatuses(versionIds: $versionIds) {
    fileUrn
    progress
    status
    versionId
  }
}
    `;

/**
 * __useThreeDConversionStatusesQuery__
 *
 * To run a query within a React component, call `useThreeDConversionStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreeDConversionStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreeDConversionStatusesQuery({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useThreeDConversionStatusesQuery(baseOptions: Apollo.QueryHookOptions<ThreeDConversionStatusesQuery, ThreeDConversionStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThreeDConversionStatusesQuery, ThreeDConversionStatusesQueryVariables>(ThreeDConversionStatusesDocument, options);
      }
export function useThreeDConversionStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThreeDConversionStatusesQuery, ThreeDConversionStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThreeDConversionStatusesQuery, ThreeDConversionStatusesQueryVariables>(ThreeDConversionStatusesDocument, options);
        }
export type ThreeDConversionStatusesQueryHookResult = ReturnType<typeof useThreeDConversionStatusesQuery>;
export type ThreeDConversionStatusesLazyQueryHookResult = ReturnType<typeof useThreeDConversionStatusesLazyQuery>;
export type ThreeDConversionStatusesQueryResult = Apollo.QueryResult<ThreeDConversionStatusesQuery, ThreeDConversionStatusesQueryVariables>;
export const DocumentVersionDocument = gql`
    query DocumentVersion($id: ID!) {
  documentVersion(id: $id) {
    ...VersionFragment
  }
}
    ${VersionFragmentFragmentDoc}`;

/**
 * __useDocumentVersionQuery__
 *
 * To run a query within a React component, call `useDocumentVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentVersionQuery(baseOptions: Apollo.QueryHookOptions<DocumentVersionQuery, DocumentVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentVersionQuery, DocumentVersionQueryVariables>(DocumentVersionDocument, options);
      }
export function useDocumentVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentVersionQuery, DocumentVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentVersionQuery, DocumentVersionQueryVariables>(DocumentVersionDocument, options);
        }
export type DocumentVersionQueryHookResult = ReturnType<typeof useDocumentVersionQuery>;
export type DocumentVersionLazyQueryHookResult = ReturnType<typeof useDocumentVersionLazyQuery>;
export type DocumentVersionQueryResult = Apollo.QueryResult<DocumentVersionQuery, DocumentVersionQueryVariables>;
export const OfficeOnlineInfoDocument = gql`
    query OfficeOnlineInfo($id: ID!) {
  officeOnlineInfo(id: $id) {
    canOpen
    editUrl
    readOnly
    viewUrl
  }
}
    `;

/**
 * __useOfficeOnlineInfoQuery__
 *
 * To run a query within a React component, call `useOfficeOnlineInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeOnlineInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeOnlineInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOfficeOnlineInfoQuery(baseOptions: Apollo.QueryHookOptions<OfficeOnlineInfoQuery, OfficeOnlineInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OfficeOnlineInfoQuery, OfficeOnlineInfoQueryVariables>(OfficeOnlineInfoDocument, options);
      }
export function useOfficeOnlineInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfficeOnlineInfoQuery, OfficeOnlineInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OfficeOnlineInfoQuery, OfficeOnlineInfoQueryVariables>(OfficeOnlineInfoDocument, options);
        }
export type OfficeOnlineInfoQueryHookResult = ReturnType<typeof useOfficeOnlineInfoQuery>;
export type OfficeOnlineInfoLazyQueryHookResult = ReturnType<typeof useOfficeOnlineInfoLazyQuery>;
export type OfficeOnlineInfoQueryResult = Apollo.QueryResult<OfficeOnlineInfoQuery, OfficeOnlineInfoQueryVariables>;
export const CheckCommandStatusDocument = gql`
    query CheckCommandStatus($id: ID!) {
  checkCommandStatus(id: $id) {
    status
  }
}
    `;

/**
 * __useCheckCommandStatusQuery__
 *
 * To run a query within a React component, call `useCheckCommandStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCommandStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCommandStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckCommandStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckCommandStatusQuery, CheckCommandStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCommandStatusQuery, CheckCommandStatusQueryVariables>(CheckCommandStatusDocument, options);
      }
export function useCheckCommandStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCommandStatusQuery, CheckCommandStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCommandStatusQuery, CheckCommandStatusQueryVariables>(CheckCommandStatusDocument, options);
        }
export type CheckCommandStatusQueryHookResult = ReturnType<typeof useCheckCommandStatusQuery>;
export type CheckCommandStatusLazyQueryHookResult = ReturnType<typeof useCheckCommandStatusLazyQuery>;
export type CheckCommandStatusQueryResult = Apollo.QueryResult<CheckCommandStatusQuery, CheckCommandStatusQueryVariables>;
export const DocumentClientDownloadUrlsDocument = gql`
    query DocumentClientDownloadUrls {
  documentClientDownloadUrls {
    downloadUrlMac
    downloadUrlWindows
  }
}
    `;

/**
 * __useDocumentClientDownloadUrlsQuery__
 *
 * To run a query within a React component, call `useDocumentClientDownloadUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentClientDownloadUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentClientDownloadUrlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentClientDownloadUrlsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentClientDownloadUrlsQuery, DocumentClientDownloadUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentClientDownloadUrlsQuery, DocumentClientDownloadUrlsQueryVariables>(DocumentClientDownloadUrlsDocument, options);
      }
export function useDocumentClientDownloadUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentClientDownloadUrlsQuery, DocumentClientDownloadUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentClientDownloadUrlsQuery, DocumentClientDownloadUrlsQueryVariables>(DocumentClientDownloadUrlsDocument, options);
        }
export type DocumentClientDownloadUrlsQueryHookResult = ReturnType<typeof useDocumentClientDownloadUrlsQuery>;
export type DocumentClientDownloadUrlsLazyQueryHookResult = ReturnType<typeof useDocumentClientDownloadUrlsLazyQuery>;
export type DocumentClientDownloadUrlsQueryResult = Apollo.QueryResult<DocumentClientDownloadUrlsQuery, DocumentClientDownloadUrlsQueryVariables>;
export const CreateApiKeyDocument = gql`
    mutation CreateApiKey($description: String!, $expiresAt: DateTime, $id: ID!) {
  createApiKey(description: $description, expiresAt: $expiresAt, id: $id)
}
    `;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      description: // value for 'description'
 *      expiresAt: // value for 'expiresAt'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const EditApiKeyDocument = gql`
    mutation EditApiKey($expiresAt: DateTime, $id: ID!) {
  editApiKey(expiresAt: $expiresAt, id: $id)
}
    `;
export type EditApiKeyMutationFn = Apollo.MutationFunction<EditApiKeyMutation, EditApiKeyMutationVariables>;

/**
 * __useEditApiKeyMutation__
 *
 * To run a mutation, you first call `useEditApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApiKeyMutation, { data, loading, error }] = useEditApiKeyMutation({
 *   variables: {
 *      expiresAt: // value for 'expiresAt'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<EditApiKeyMutation, EditApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditApiKeyMutation, EditApiKeyMutationVariables>(EditApiKeyDocument, options);
      }
export type EditApiKeyMutationHookResult = ReturnType<typeof useEditApiKeyMutation>;
export type EditApiKeyMutationResult = Apollo.MutationResult<EditApiKeyMutation>;
export type EditApiKeyMutationOptions = Apollo.BaseMutationOptions<EditApiKeyMutation, EditApiKeyMutationVariables>;
export const RevokeApiKeysDocument = gql`
    mutation RevokeApiKeys($ids: [ID!]!) {
  revokeApiKeys(ids: $ids)
}
    `;
export type RevokeApiKeysMutationFn = Apollo.MutationFunction<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>;

/**
 * __useRevokeApiKeysMutation__
 *
 * To run a mutation, you first call `useRevokeApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApiKeysMutation, { data, loading, error }] = useRevokeApiKeysMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRevokeApiKeysMutation(baseOptions?: Apollo.MutationHookOptions<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>(RevokeApiKeysDocument, options);
      }
export type RevokeApiKeysMutationHookResult = ReturnType<typeof useRevokeApiKeysMutation>;
export type RevokeApiKeysMutationResult = Apollo.MutationResult<RevokeApiKeysMutation>;
export type RevokeApiKeysMutationOptions = Apollo.BaseMutationOptions<RevokeApiKeysMutation, RevokeApiKeysMutationVariables>;
export const ApiKeysIncludeDocument = gql`
    query ApiKeysInclude($includeExpiresAt: Boolean = false) {
  apiKeys {
    description
    expiresAt @include(if: $includeExpiresAt)
    id
  }
}
    `;

/**
 * __useApiKeysIncludeQuery__
 *
 * To run a query within a React component, call `useApiKeysIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysIncludeQuery({
 *   variables: {
 *      includeExpiresAt: // value for 'includeExpiresAt'
 *   },
 * });
 */
export function useApiKeysIncludeQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeysIncludeQuery, ApiKeysIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysIncludeQuery, ApiKeysIncludeQueryVariables>(ApiKeysIncludeDocument, options);
      }
export function useApiKeysIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysIncludeQuery, ApiKeysIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysIncludeQuery, ApiKeysIncludeQueryVariables>(ApiKeysIncludeDocument, options);
        }
export type ApiKeysIncludeQueryHookResult = ReturnType<typeof useApiKeysIncludeQuery>;
export type ApiKeysIncludeLazyQueryHookResult = ReturnType<typeof useApiKeysIncludeLazyQuery>;
export type ApiKeysIncludeQueryResult = Apollo.QueryResult<ApiKeysIncludeQuery, ApiKeysIncludeQueryVariables>;
export const ApiKeyDocument = gql`
    query ApiKey($id: ID!) {
  apiKey(id: $id) {
    description
    expiresAt
    id
  }
}
    `;

/**
 * __useApiKeyQuery__
 *
 * To run a query within a React component, call `useApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiKeyQuery(baseOptions: Apollo.QueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
      }
export function useApiKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeyQuery, ApiKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument, options);
        }
export type ApiKeyQueryHookResult = ReturnType<typeof useApiKeyQuery>;
export type ApiKeyLazyQueryHookResult = ReturnType<typeof useApiKeyLazyQuery>;
export type ApiKeyQueryResult = Apollo.QueryResult<ApiKeyQuery, ApiKeyQueryVariables>;
export const AddGroupsToProjectsDocument = gql`
    mutation AddGroupsToProjects($groupIds: [ID]!, $projectIds: [ID]!) {
  addGroupsToProjects(groupIds: $groupIds, projectIds: $projectIds) {
    errors
    ids
    success
  }
}
    `;
export type AddGroupsToProjectsMutationFn = Apollo.MutationFunction<AddGroupsToProjectsMutation, AddGroupsToProjectsMutationVariables>;

/**
 * __useAddGroupsToProjectsMutation__
 *
 * To run a mutation, you first call `useAddGroupsToProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupsToProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupsToProjectsMutation, { data, loading, error }] = useAddGroupsToProjectsMutation({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useAddGroupsToProjectsMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupsToProjectsMutation, AddGroupsToProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGroupsToProjectsMutation, AddGroupsToProjectsMutationVariables>(AddGroupsToProjectsDocument, options);
      }
export type AddGroupsToProjectsMutationHookResult = ReturnType<typeof useAddGroupsToProjectsMutation>;
export type AddGroupsToProjectsMutationResult = Apollo.MutationResult<AddGroupsToProjectsMutation>;
export type AddGroupsToProjectsMutationOptions = Apollo.BaseMutationOptions<AddGroupsToProjectsMutation, AddGroupsToProjectsMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($name: String!, $projectId: ID, $subgroups: [ID], $members: [MemberInput]) {
  createGroup(
    name: $name
    projectId: $projectId
    subgroups: $subgroups
    members: $members
  ) {
    errors
    id
    success
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      subgroups: // value for 'subgroups'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const DeleteGroupsDocument = gql`
    mutation DeleteGroups($groups: [ID]!) {
  deleteGroups(groups: $groups) {
    errors
    ids
    success
  }
}
    `;
export type DeleteGroupsMutationFn = Apollo.MutationFunction<DeleteGroupsMutation, DeleteGroupsMutationVariables>;

/**
 * __useDeleteGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupsMutation, { data, loading, error }] = useDeleteGroupsMutation({
 *   variables: {
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useDeleteGroupsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupsMutation, DeleteGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupsMutation, DeleteGroupsMutationVariables>(DeleteGroupsDocument, options);
      }
export type DeleteGroupsMutationHookResult = ReturnType<typeof useDeleteGroupsMutation>;
export type DeleteGroupsMutationResult = Apollo.MutationResult<DeleteGroupsMutation>;
export type DeleteGroupsMutationOptions = Apollo.BaseMutationOptions<DeleteGroupsMutation, DeleteGroupsMutationVariables>;
export const EditGroupDocument = gql`
    mutation EditGroup($groupId: ID!, $name: String, $subgroupsToDelete: [ID], $subgroupsToAdd: [ID], $membersToAdd: [MemberInput], $membersToDelete: [MemberInput]) {
  editGroup(
    groupId: $groupId
    name: $name
    subgroupsToDelete: $subgroupsToDelete
    subgroupsToAdd: $subgroupsToAdd
    membersToAdd: $membersToAdd
    membersToDelete: $membersToDelete
  ) {
    errors
    id
    success
  }
}
    `;
export type EditGroupMutationFn = Apollo.MutationFunction<EditGroupMutation, EditGroupMutationVariables>;

/**
 * __useEditGroupMutation__
 *
 * To run a mutation, you first call `useEditGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupMutation, { data, loading, error }] = useEditGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      subgroupsToDelete: // value for 'subgroupsToDelete'
 *      subgroupsToAdd: // value for 'subgroupsToAdd'
 *      membersToAdd: // value for 'membersToAdd'
 *      membersToDelete: // value for 'membersToDelete'
 *   },
 * });
 */
export function useEditGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupMutation, EditGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGroupMutation, EditGroupMutationVariables>(EditGroupDocument, options);
      }
export type EditGroupMutationHookResult = ReturnType<typeof useEditGroupMutation>;
export type EditGroupMutationResult = Apollo.MutationResult<EditGroupMutation>;
export type EditGroupMutationOptions = Apollo.BaseMutationOptions<EditGroupMutation, EditGroupMutationVariables>;
export const AllowedGroupsIncludeDocument = gql`
    query AllowedGroupsInclude($groupId: ID = null, $includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeMemberIds: Boolean = false, $includeMembers: Boolean = false, $includeMembersCount: Boolean = false, $includeSubgroups: Boolean = false, $includeSubgroupIds: Boolean = false, $includeOrganizationId: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID = null) {
  allowedGroups(groupId: $groupId, projectId: $projectId) {
    ... on Group {
      ...FragmentGroupInclude
    }
  }
}
    ${FragmentGroupIncludeFragmentDoc}`;

/**
 * __useAllowedGroupsIncludeQuery__
 *
 * To run a query within a React component, call `useAllowedGroupsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedGroupsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedGroupsIncludeQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeMemberIds: // value for 'includeMemberIds'
 *      includeMembers: // value for 'includeMembers'
 *      includeMembersCount: // value for 'includeMembersCount'
 *      includeSubgroups: // value for 'includeSubgroups'
 *      includeSubgroupIds: // value for 'includeSubgroupIds'
 *      includeOrganizationId: // value for 'includeOrganizationId'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAllowedGroupsIncludeQuery(baseOptions?: Apollo.QueryHookOptions<AllowedGroupsIncludeQuery, AllowedGroupsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllowedGroupsIncludeQuery, AllowedGroupsIncludeQueryVariables>(AllowedGroupsIncludeDocument, options);
      }
export function useAllowedGroupsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllowedGroupsIncludeQuery, AllowedGroupsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllowedGroupsIncludeQuery, AllowedGroupsIncludeQueryVariables>(AllowedGroupsIncludeDocument, options);
        }
export type AllowedGroupsIncludeQueryHookResult = ReturnType<typeof useAllowedGroupsIncludeQuery>;
export type AllowedGroupsIncludeLazyQueryHookResult = ReturnType<typeof useAllowedGroupsIncludeLazyQuery>;
export type AllowedGroupsIncludeQueryResult = Apollo.QueryResult<AllowedGroupsIncludeQuery, AllowedGroupsIncludeQueryVariables>;
export const GroupIncludeDocument = gql`
    query GroupInclude($groupId: ID!, $includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeMemberIds: Boolean = false, $includeMembers: Boolean = false, $includeMembersCount: Boolean = false, $includeSubgroups: Boolean = false, $includeSubgroupIds: Boolean = false, $includeOrganizationId: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID = null) {
  group(groupId: $groupId, projectId: $projectId) {
    ... on Group {
      ...FragmentGroupInclude
    }
  }
}
    ${FragmentGroupIncludeFragmentDoc}`;

/**
 * __useGroupIncludeQuery__
 *
 * To run a query within a React component, call `useGroupIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupIncludeQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeMemberIds: // value for 'includeMemberIds'
 *      includeMembers: // value for 'includeMembers'
 *      includeMembersCount: // value for 'includeMembersCount'
 *      includeSubgroups: // value for 'includeSubgroups'
 *      includeSubgroupIds: // value for 'includeSubgroupIds'
 *      includeOrganizationId: // value for 'includeOrganizationId'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGroupIncludeQuery(baseOptions: Apollo.QueryHookOptions<GroupIncludeQuery, GroupIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupIncludeQuery, GroupIncludeQueryVariables>(GroupIncludeDocument, options);
      }
export function useGroupIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupIncludeQuery, GroupIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupIncludeQuery, GroupIncludeQueryVariables>(GroupIncludeDocument, options);
        }
export type GroupIncludeQueryHookResult = ReturnType<typeof useGroupIncludeQuery>;
export type GroupIncludeLazyQueryHookResult = ReturnType<typeof useGroupIncludeLazyQuery>;
export type GroupIncludeQueryResult = Apollo.QueryResult<GroupIncludeQuery, GroupIncludeQueryVariables>;
export const GroupsIncludeDocument = gql`
    query GroupsInclude($includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeMemberIds: Boolean = false, $includeMembers: Boolean = false, $includeMembersCount: Boolean = false, $includeSubgroups: Boolean = false, $includeSubgroupIds: Boolean = false, $includeOrganizationId: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID = null) {
  groups(projectId: $projectId) {
    ... on Group {
      ...FragmentGroupInclude
    }
  }
}
    ${FragmentGroupIncludeFragmentDoc}`;

/**
 * __useGroupsIncludeQuery__
 *
 * To run a query within a React component, call `useGroupsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsIncludeQuery({
 *   variables: {
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeMemberIds: // value for 'includeMemberIds'
 *      includeMembers: // value for 'includeMembers'
 *      includeMembersCount: // value for 'includeMembersCount'
 *      includeSubgroups: // value for 'includeSubgroups'
 *      includeSubgroupIds: // value for 'includeSubgroupIds'
 *      includeOrganizationId: // value for 'includeOrganizationId'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGroupsIncludeQuery(baseOptions?: Apollo.QueryHookOptions<GroupsIncludeQuery, GroupsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsIncludeQuery, GroupsIncludeQueryVariables>(GroupsIncludeDocument, options);
      }
export function useGroupsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsIncludeQuery, GroupsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsIncludeQuery, GroupsIncludeQueryVariables>(GroupsIncludeDocument, options);
        }
export type GroupsIncludeQueryHookResult = ReturnType<typeof useGroupsIncludeQuery>;
export type GroupsIncludeLazyQueryHookResult = ReturnType<typeof useGroupsIncludeLazyQuery>;
export type GroupsIncludeQueryResult = Apollo.QueryResult<GroupsIncludeQuery, GroupsIncludeQueryVariables>;
export const AddIntegrationDocument = gql`
    mutation AddIntegration($application: ApplicationInput!, $customIntegrationProps: CustomIntegrationProps!) {
  addIntegration(
    application: $application
    customIntegrationProps: $customIntegrationProps
  )
}
    `;
export type AddIntegrationMutationFn = Apollo.MutationFunction<AddIntegrationMutation, AddIntegrationMutationVariables>;

/**
 * __useAddIntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationMutation, { data, loading, error }] = useAddIntegrationMutation({
 *   variables: {
 *      application: // value for 'application'
 *      customIntegrationProps: // value for 'customIntegrationProps'
 *   },
 * });
 */
export function useAddIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationMutation, AddIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationMutation, AddIntegrationMutationVariables>(AddIntegrationDocument, options);
      }
export type AddIntegrationMutationHookResult = ReturnType<typeof useAddIntegrationMutation>;
export type AddIntegrationMutationResult = Apollo.MutationResult<AddIntegrationMutation>;
export type AddIntegrationMutationOptions = Apollo.BaseMutationOptions<AddIntegrationMutation, AddIntegrationMutationVariables>;
export const ConnectProjectDocument = gql`
    mutation ConnectProject($customConnectionProps: CustomConnectionProps!, $integrationId: ID!, $projectId: ID!) {
  connectProject(
    customConnectionProps: $customConnectionProps
    integrationId: $integrationId
    projectId: $projectId
  ) {
    connectionName
    id
    projectId
  }
}
    `;
export type ConnectProjectMutationFn = Apollo.MutationFunction<ConnectProjectMutation, ConnectProjectMutationVariables>;

/**
 * __useConnectProjectMutation__
 *
 * To run a mutation, you first call `useConnectProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectProjectMutation, { data, loading, error }] = useConnectProjectMutation({
 *   variables: {
 *      customConnectionProps: // value for 'customConnectionProps'
 *      integrationId: // value for 'integrationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConnectProjectMutation(baseOptions?: Apollo.MutationHookOptions<ConnectProjectMutation, ConnectProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectProjectMutation, ConnectProjectMutationVariables>(ConnectProjectDocument, options);
      }
export type ConnectProjectMutationHookResult = ReturnType<typeof useConnectProjectMutation>;
export type ConnectProjectMutationResult = Apollo.MutationResult<ConnectProjectMutation>;
export type ConnectProjectMutationOptions = Apollo.BaseMutationOptions<ConnectProjectMutation, ConnectProjectMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($id: ID!) {
  deleteIntegration(id: $id)
}
    `;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, options);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const DeleteIntegrationConnectionDocument = gql`
    mutation DeleteIntegrationConnection($id: ID!, $integrationId: ID!, $projectId: ID!) {
  deleteIntegrationConnection(
    id: $id
    integrationId: $integrationId
    projectId: $projectId
  )
}
    `;
export type DeleteIntegrationConnectionMutationFn = Apollo.MutationFunction<DeleteIntegrationConnectionMutation, DeleteIntegrationConnectionMutationVariables>;

/**
 * __useDeleteIntegrationConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationConnectionMutation, { data, loading, error }] = useDeleteIntegrationConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      integrationId: // value for 'integrationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteIntegrationConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationConnectionMutation, DeleteIntegrationConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIntegrationConnectionMutation, DeleteIntegrationConnectionMutationVariables>(DeleteIntegrationConnectionDocument, options);
      }
export type DeleteIntegrationConnectionMutationHookResult = ReturnType<typeof useDeleteIntegrationConnectionMutation>;
export type DeleteIntegrationConnectionMutationResult = Apollo.MutationResult<DeleteIntegrationConnectionMutation>;
export type DeleteIntegrationConnectionMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationConnectionMutation, DeleteIntegrationConnectionMutationVariables>;
export const ShareToBuild12Document = gql`
    mutation ShareToBuild12($connectionId: ID!, $integrationId: ID!, $markAsSentVersionIds: [ID], $projectId: ID!, $projectPhaseId: ID!, $projectPhaseName: String, $versionIds: [ID]!) {
  shareToBuild12(
    connectionId: $connectionId
    integrationId: $integrationId
    markAsSentVersionIds: $markAsSentVersionIds
    projectId: $projectId
    projectPhaseId: $projectPhaseId
    projectPhaseName: $projectPhaseName
    versionIds: $versionIds
  ) {
    successful
    unsuccessful
  }
}
    `;
export type ShareToBuild12MutationFn = Apollo.MutationFunction<ShareToBuild12Mutation, ShareToBuild12MutationVariables>;

/**
 * __useShareToBuild12Mutation__
 *
 * To run a mutation, you first call `useShareToBuild12Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareToBuild12Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareToBuild12Mutation, { data, loading, error }] = useShareToBuild12Mutation({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      integrationId: // value for 'integrationId'
 *      markAsSentVersionIds: // value for 'markAsSentVersionIds'
 *      projectId: // value for 'projectId'
 *      projectPhaseId: // value for 'projectPhaseId'
 *      projectPhaseName: // value for 'projectPhaseName'
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useShareToBuild12Mutation(baseOptions?: Apollo.MutationHookOptions<ShareToBuild12Mutation, ShareToBuild12MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareToBuild12Mutation, ShareToBuild12MutationVariables>(ShareToBuild12Document, options);
      }
export type ShareToBuild12MutationHookResult = ReturnType<typeof useShareToBuild12Mutation>;
export type ShareToBuild12MutationResult = Apollo.MutationResult<ShareToBuild12Mutation>;
export type ShareToBuild12MutationOptions = Apollo.BaseMutationOptions<ShareToBuild12Mutation, ShareToBuild12MutationVariables>;
export const ShareToSnagstreamDocument = gql`
    mutation ShareToSnagstream($connectionId: ID!, $integrationId: ID!, $markAsSentVersionIds: [ID], $overwriteVersionIds: [ID], $projectId: ID!, $versionIds: [ID]!) {
  shareToSnagstream(
    connectionId: $connectionId
    integrationId: $integrationId
    markAsSentVersionIds: $markAsSentVersionIds
    overwriteVersionIds: $overwriteVersionIds
    projectId: $projectId
    versionIds: $versionIds
  ) {
    successful
    unsuccessful
  }
}
    `;
export type ShareToSnagstreamMutationFn = Apollo.MutationFunction<ShareToSnagstreamMutation, ShareToSnagstreamMutationVariables>;

/**
 * __useShareToSnagstreamMutation__
 *
 * To run a mutation, you first call `useShareToSnagstreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareToSnagstreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareToSnagstreamMutation, { data, loading, error }] = useShareToSnagstreamMutation({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      integrationId: // value for 'integrationId'
 *      markAsSentVersionIds: // value for 'markAsSentVersionIds'
 *      overwriteVersionIds: // value for 'overwriteVersionIds'
 *      projectId: // value for 'projectId'
 *      versionIds: // value for 'versionIds'
 *   },
 * });
 */
export function useShareToSnagstreamMutation(baseOptions?: Apollo.MutationHookOptions<ShareToSnagstreamMutation, ShareToSnagstreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareToSnagstreamMutation, ShareToSnagstreamMutationVariables>(ShareToSnagstreamDocument, options);
      }
export type ShareToSnagstreamMutationHookResult = ReturnType<typeof useShareToSnagstreamMutation>;
export type ShareToSnagstreamMutationResult = Apollo.MutationResult<ShareToSnagstreamMutation>;
export type ShareToSnagstreamMutationOptions = Apollo.BaseMutationOptions<ShareToSnagstreamMutation, ShareToSnagstreamMutationVariables>;
export const ConnectionDocument = gql`
    query Connection($id: ID!, $projectId: ID!) {
  connection(id: $id, projectId: $projectId) {
    connectionName
    createdAt
    createdBy {
      id
      displayName
    }
    id
    integrationId
    projectId
  }
}
    `;

/**
 * __useConnectionQuery__
 *
 * To run a query within a React component, call `useConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConnectionQuery(baseOptions: Apollo.QueryHookOptions<ConnectionQuery, ConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, options);
      }
export function useConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionQuery, ConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, options);
        }
export type ConnectionQueryHookResult = ReturnType<typeof useConnectionQuery>;
export type ConnectionLazyQueryHookResult = ReturnType<typeof useConnectionLazyQuery>;
export type ConnectionQueryResult = Apollo.QueryResult<ConnectionQuery, ConnectionQueryVariables>;
export const IntegrationDocument = gql`
    query Integration($id: ID!) {
  integration(id: $id) {
    integrationName
    createdAt
    createdBy {
      id
      displayName
    }
    id
    integrationType
  }
}
    `;

/**
 * __useIntegrationQuery__
 *
 * To run a query within a React component, call `useIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIntegrationQuery(baseOptions: Apollo.QueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
      }
export function useIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationQuery, IntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationQuery, IntegrationQueryVariables>(IntegrationDocument, options);
        }
export type IntegrationQueryHookResult = ReturnType<typeof useIntegrationQuery>;
export type IntegrationLazyQueryHookResult = ReturnType<typeof useIntegrationLazyQuery>;
export type IntegrationQueryResult = Apollo.QueryResult<IntegrationQuery, IntegrationQueryVariables>;
export const PhaseBuild12Document = gql`
    query PhaseBuild12($id: ID!) {
  phaseBuild12(id: $id) {
    id
    name
  }
}
    `;

/**
 * __usePhaseBuild12Query__
 *
 * To run a query within a React component, call `usePhaseBuild12Query` and pass it any options that fit your needs.
 * When your component renders, `usePhaseBuild12Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhaseBuild12Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhaseBuild12Query(baseOptions: Apollo.QueryHookOptions<PhaseBuild12Query, PhaseBuild12QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhaseBuild12Query, PhaseBuild12QueryVariables>(PhaseBuild12Document, options);
      }
export function usePhaseBuild12LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhaseBuild12Query, PhaseBuild12QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhaseBuild12Query, PhaseBuild12QueryVariables>(PhaseBuild12Document, options);
        }
export type PhaseBuild12QueryHookResult = ReturnType<typeof usePhaseBuild12Query>;
export type PhaseBuild12LazyQueryHookResult = ReturnType<typeof usePhaseBuild12LazyQuery>;
export type PhaseBuild12QueryResult = Apollo.QueryResult<PhaseBuild12Query, PhaseBuild12QueryVariables>;
export const FilesExistsBuild12Document = gql`
    query FilesExistsBuild12($versionIds: [ID]!, $projectId: ID!, $connectionId: ID!, $projectPhaseId: ID!) {
  filesExistsBuild12(
    versionIds: $versionIds
    projectId: $projectId
    connectionId: $connectionId
    projectPhaseId: $projectPhaseId
  ) {
    allowedIds
    alreadyExistsIds
    rejectedIds
  }
}
    `;

/**
 * __useFilesExistsBuild12Query__
 *
 * To run a query within a React component, call `useFilesExistsBuild12Query` and pass it any options that fit your needs.
 * When your component renders, `useFilesExistsBuild12Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesExistsBuild12Query({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *      projectId: // value for 'projectId'
 *      connectionId: // value for 'connectionId'
 *      projectPhaseId: // value for 'projectPhaseId'
 *   },
 * });
 */
export function useFilesExistsBuild12Query(baseOptions: Apollo.QueryHookOptions<FilesExistsBuild12Query, FilesExistsBuild12QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesExistsBuild12Query, FilesExistsBuild12QueryVariables>(FilesExistsBuild12Document, options);
      }
export function useFilesExistsBuild12LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesExistsBuild12Query, FilesExistsBuild12QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesExistsBuild12Query, FilesExistsBuild12QueryVariables>(FilesExistsBuild12Document, options);
        }
export type FilesExistsBuild12QueryHookResult = ReturnType<typeof useFilesExistsBuild12Query>;
export type FilesExistsBuild12LazyQueryHookResult = ReturnType<typeof useFilesExistsBuild12LazyQuery>;
export type FilesExistsBuild12QueryResult = Apollo.QueryResult<FilesExistsBuild12Query, FilesExistsBuild12QueryVariables>;
export const FilesExistsSnagstreamDocument = gql`
    query FilesExistsSnagstream($versionIds: [ID]!, $projectId: ID!, $connectionId: ID!) {
  filesExistsSnagstream(
    versionIds: $versionIds
    projectId: $projectId
    connectionId: $connectionId
  ) {
    allowedIds
    alreadyExistsIds
    rejectedIds
  }
}
    `;

/**
 * __useFilesExistsSnagstreamQuery__
 *
 * To run a query within a React component, call `useFilesExistsSnagstreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesExistsSnagstreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesExistsSnagstreamQuery({
 *   variables: {
 *      versionIds: // value for 'versionIds'
 *      projectId: // value for 'projectId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useFilesExistsSnagstreamQuery(baseOptions: Apollo.QueryHookOptions<FilesExistsSnagstreamQuery, FilesExistsSnagstreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesExistsSnagstreamQuery, FilesExistsSnagstreamQueryVariables>(FilesExistsSnagstreamDocument, options);
      }
export function useFilesExistsSnagstreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesExistsSnagstreamQuery, FilesExistsSnagstreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesExistsSnagstreamQuery, FilesExistsSnagstreamQueryVariables>(FilesExistsSnagstreamDocument, options);
        }
export type FilesExistsSnagstreamQueryHookResult = ReturnType<typeof useFilesExistsSnagstreamQuery>;
export type FilesExistsSnagstreamLazyQueryHookResult = ReturnType<typeof useFilesExistsSnagstreamLazyQuery>;
export type FilesExistsSnagstreamQueryResult = Apollo.QueryResult<FilesExistsSnagstreamQuery, FilesExistsSnagstreamQueryVariables>;
export const ConnectionsDocument = gql`
    query Connections($filterOnActiveConnections: Boolean, $filterOnIntegrationType: IntegrationType, $projectId: ID!) {
  connections(
    filterOnActiveConnections: $filterOnActiveConnections
    filterOnIntegrationType: $filterOnIntegrationType
    projectId: $projectId
  ) {
    integrationName
    createdAt
    createdBy {
      id
      displayName
    }
    integrationConnection {
      connectionName
      createdAt
      createdBy {
        id
        displayName
      }
      id
      integrationId
      projectId
    }
    connectionStatus
    id
    integrationType
    projectId
  }
}
    `;

/**
 * __useConnectionsQuery__
 *
 * To run a query within a React component, call `useConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsQuery({
 *   variables: {
 *      filterOnActiveConnections: // value for 'filterOnActiveConnections'
 *      filterOnIntegrationType: // value for 'filterOnIntegrationType'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
      }
export function useConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options);
        }
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>;
export type ConnectionsQueryResult = Apollo.QueryResult<ConnectionsQuery, ConnectionsQueryVariables>;
export const IntegrationsDocument = gql`
    query Integrations {
  integrations {
    integrationName
    createdAt
    createdBy {
      id
      displayName
    }
    id
    integrationType
  }
}
    `;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
      }
export function useIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationsQuery, IntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationsQuery, IntegrationsQueryVariables>(IntegrationsDocument, options);
        }
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<IntegrationsQuery, IntegrationsQueryVariables>;
export const PhasesBuild12Document = gql`
    query PhasesBuild12($connectionId: ID!, $projectId: ID!) {
  phasesBuild12(connectionId: $connectionId, projectId: $projectId) {
    id
    name
  }
}
    `;

/**
 * __usePhasesBuild12Query__
 *
 * To run a query within a React component, call `usePhasesBuild12Query` and pass it any options that fit your needs.
 * When your component renders, `usePhasesBuild12Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhasesBuild12Query({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePhasesBuild12Query(baseOptions: Apollo.QueryHookOptions<PhasesBuild12Query, PhasesBuild12QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PhasesBuild12Query, PhasesBuild12QueryVariables>(PhasesBuild12Document, options);
      }
export function usePhasesBuild12LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhasesBuild12Query, PhasesBuild12QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PhasesBuild12Query, PhasesBuild12QueryVariables>(PhasesBuild12Document, options);
        }
export type PhasesBuild12QueryHookResult = ReturnType<typeof usePhasesBuild12Query>;
export type PhasesBuild12LazyQueryHookResult = ReturnType<typeof usePhasesBuild12LazyQuery>;
export type PhasesBuild12QueryResult = Apollo.QueryResult<PhasesBuild12Query, PhasesBuild12QueryVariables>;
export const ProjectsBuild12Document = gql`
    query ProjectsBuild12($integrationId: ID!) {
  projectsBuild12(integrationId: $integrationId) {
    id
    name
  }
}
    `;

/**
 * __useProjectsBuild12Query__
 *
 * To run a query within a React component, call `useProjectsBuild12Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectsBuild12Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsBuild12Query({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useProjectsBuild12Query(baseOptions: Apollo.QueryHookOptions<ProjectsBuild12Query, ProjectsBuild12QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsBuild12Query, ProjectsBuild12QueryVariables>(ProjectsBuild12Document, options);
      }
export function useProjectsBuild12LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsBuild12Query, ProjectsBuild12QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsBuild12Query, ProjectsBuild12QueryVariables>(ProjectsBuild12Document, options);
        }
export type ProjectsBuild12QueryHookResult = ReturnType<typeof useProjectsBuild12Query>;
export type ProjectsBuild12LazyQueryHookResult = ReturnType<typeof useProjectsBuild12LazyQuery>;
export type ProjectsBuild12QueryResult = Apollo.QueryResult<ProjectsBuild12Query, ProjectsBuild12QueryVariables>;
export const ProjectsSnagstreamDocument = gql`
    query ProjectsSnagstream($integrationId: ID!) {
  projectsSnagstream(integrationId: $integrationId) {
    id
    name
  }
}
    `;

/**
 * __useProjectsSnagstreamQuery__
 *
 * To run a query within a React component, call `useProjectsSnagstreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsSnagstreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsSnagstreamQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useProjectsSnagstreamQuery(baseOptions: Apollo.QueryHookOptions<ProjectsSnagstreamQuery, ProjectsSnagstreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsSnagstreamQuery, ProjectsSnagstreamQueryVariables>(ProjectsSnagstreamDocument, options);
      }
export function useProjectsSnagstreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsSnagstreamQuery, ProjectsSnagstreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsSnagstreamQuery, ProjectsSnagstreamQueryVariables>(ProjectsSnagstreamDocument, options);
        }
export type ProjectsSnagstreamQueryHookResult = ReturnType<typeof useProjectsSnagstreamQuery>;
export type ProjectsSnagstreamLazyQueryHookResult = ReturnType<typeof useProjectsSnagstreamLazyQuery>;
export type ProjectsSnagstreamQueryResult = Apollo.QueryResult<ProjectsSnagstreamQuery, ProjectsSnagstreamQueryVariables>;
export const SetProjectPermissionsDocument = gql`
    mutation SetProjectPermissions($member: MemberInput, $isMemberGroupFromOrg: Boolean, $projectId: ID!, $memberPermissions: [String]!, $category: qcPermissionCategory) {
  setProjectPermissions(
    member: $member
    isMemberGroupFromOrg: $isMemberGroupFromOrg
    projectId: $projectId
    memberPermissions: $memberPermissions
    category: $category
  ) {
    projectId
  }
}
    `;
export type SetProjectPermissionsMutationFn = Apollo.MutationFunction<SetProjectPermissionsMutation, SetProjectPermissionsMutationVariables>;

/**
 * __useSetProjectPermissionsMutation__
 *
 * To run a mutation, you first call `useSetProjectPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectPermissionsMutation, { data, loading, error }] = useSetProjectPermissionsMutation({
 *   variables: {
 *      member: // value for 'member'
 *      isMemberGroupFromOrg: // value for 'isMemberGroupFromOrg'
 *      projectId: // value for 'projectId'
 *      memberPermissions: // value for 'memberPermissions'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useSetProjectPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectPermissionsMutation, SetProjectPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectPermissionsMutation, SetProjectPermissionsMutationVariables>(SetProjectPermissionsDocument, options);
      }
export type SetProjectPermissionsMutationHookResult = ReturnType<typeof useSetProjectPermissionsMutation>;
export type SetProjectPermissionsMutationResult = Apollo.MutationResult<SetProjectPermissionsMutation>;
export type SetProjectPermissionsMutationOptions = Apollo.BaseMutationOptions<SetProjectPermissionsMutation, SetProjectPermissionsMutationVariables>;
export const GroupsAndUsersIncludeDocument = gql`
    query GroupsAndUsersInclude($includeActive: Boolean = false, $includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeEmail: Boolean = false, $fetchInvitedUsers: Boolean = false, $includeInvited: Boolean = false, $includeIsAdmin: Boolean = false, $includeJobTitle: Boolean = false, $includeLastOnlineAt: Boolean = false, $includeMemberIds: Boolean = false, $includeMembers: Boolean = false, $includeMembersCount: Boolean = false, $includeOrganization: Boolean = false, $includePhoneNumber: Boolean = false, $includeRoles: Boolean = false, $includeState: Boolean = false, $includeSubgroupIds: Boolean = false, $includeOrganizationId: Boolean = false, $includeSubgroups: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID = null) {
  groupsAndUsers(projectId: $projectId, fetchInvitedUsers: $fetchInvitedUsers) {
    ... on Group {
      ...FragmentGroupInclude
    }
    ... on User {
      ...FragmentUserInclude
    }
  }
}
    ${FragmentGroupIncludeFragmentDoc}
${FragmentUserIncludeFragmentDoc}`;

/**
 * __useGroupsAndUsersIncludeQuery__
 *
 * To run a query within a React component, call `useGroupsAndUsersIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsAndUsersIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsAndUsersIncludeQuery({
 *   variables: {
 *      includeActive: // value for 'includeActive'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeEmail: // value for 'includeEmail'
 *      fetchInvitedUsers: // value for 'fetchInvitedUsers'
 *      includeInvited: // value for 'includeInvited'
 *      includeIsAdmin: // value for 'includeIsAdmin'
 *      includeJobTitle: // value for 'includeJobTitle'
 *      includeLastOnlineAt: // value for 'includeLastOnlineAt'
 *      includeMemberIds: // value for 'includeMemberIds'
 *      includeMembers: // value for 'includeMembers'
 *      includeMembersCount: // value for 'includeMembersCount'
 *      includeOrganization: // value for 'includeOrganization'
 *      includePhoneNumber: // value for 'includePhoneNumber'
 *      includeRoles: // value for 'includeRoles'
 *      includeState: // value for 'includeState'
 *      includeSubgroupIds: // value for 'includeSubgroupIds'
 *      includeOrganizationId: // value for 'includeOrganizationId'
 *      includeSubgroups: // value for 'includeSubgroups'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGroupsAndUsersIncludeQuery(baseOptions?: Apollo.QueryHookOptions<GroupsAndUsersIncludeQuery, GroupsAndUsersIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsAndUsersIncludeQuery, GroupsAndUsersIncludeQueryVariables>(GroupsAndUsersIncludeDocument, options);
      }
export function useGroupsAndUsersIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsAndUsersIncludeQuery, GroupsAndUsersIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsAndUsersIncludeQuery, GroupsAndUsersIncludeQueryVariables>(GroupsAndUsersIncludeDocument, options);
        }
export type GroupsAndUsersIncludeQueryHookResult = ReturnType<typeof useGroupsAndUsersIncludeQuery>;
export type GroupsAndUsersIncludeLazyQueryHookResult = ReturnType<typeof useGroupsAndUsersIncludeLazyQuery>;
export type GroupsAndUsersIncludeQueryResult = Apollo.QueryResult<GroupsAndUsersIncludeQuery, GroupsAndUsersIncludeQueryVariables>;
export const ProjectMembersIncludeDocument = gql`
    query ProjectMembersInclude($includeActive: Boolean = false, $includeEmail: Boolean = false, $includeInvited: Boolean = false, $includeIsAdmin: Boolean = false, $includeJobTitle: Boolean = false, $includeLastOnlineAt: Boolean = false, $includeOrganization: Boolean = false, $includeRoles: Boolean = false, $includePhoneNumber: Boolean = false, $includeState: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID!) {
  projectMembers(projectId: $projectId) {
    ... on Group {
      ...FragmentProjectMemberGroupInclude
    }
    ... on User {
      ...FragmentUserInclude
    }
  }
}
    ${FragmentProjectMemberGroupIncludeFragmentDoc}
${FragmentUserIncludeFragmentDoc}`;

/**
 * __useProjectMembersIncludeQuery__
 *
 * To run a query within a React component, call `useProjectMembersIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembersIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembersIncludeQuery({
 *   variables: {
 *      includeActive: // value for 'includeActive'
 *      includeEmail: // value for 'includeEmail'
 *      includeInvited: // value for 'includeInvited'
 *      includeIsAdmin: // value for 'includeIsAdmin'
 *      includeJobTitle: // value for 'includeJobTitle'
 *      includeLastOnlineAt: // value for 'includeLastOnlineAt'
 *      includeOrganization: // value for 'includeOrganization'
 *      includeRoles: // value for 'includeRoles'
 *      includePhoneNumber: // value for 'includePhoneNumber'
 *      includeState: // value for 'includeState'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectMembersIncludeQuery(baseOptions: Apollo.QueryHookOptions<ProjectMembersIncludeQuery, ProjectMembersIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectMembersIncludeQuery, ProjectMembersIncludeQueryVariables>(ProjectMembersIncludeDocument, options);
      }
export function useProjectMembersIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectMembersIncludeQuery, ProjectMembersIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectMembersIncludeQuery, ProjectMembersIncludeQueryVariables>(ProjectMembersIncludeDocument, options);
        }
export type ProjectMembersIncludeQueryHookResult = ReturnType<typeof useProjectMembersIncludeQuery>;
export type ProjectMembersIncludeLazyQueryHookResult = ReturnType<typeof useProjectMembersIncludeLazyQuery>;
export type ProjectMembersIncludeQueryResult = Apollo.QueryResult<ProjectMembersIncludeQuery, ProjectMembersIncludeQueryVariables>;
export const GetProjectPermissionsDocument = gql`
    query GetProjectPermissions($memberId: ID!, $memberType: Int!, $isMemberGroupFromOrg: Boolean, $projectId: ID!, $category: String) {
  getProjectPermissions(
    memberId: $memberId
    memberType: $memberType
    isMemberGroupFromOrg: $isMemberGroupFromOrg
    projectId: $projectId
    category: $category
  ) {
    directPermissions
    inheritedPermissions {
      permissions
      groupName
      groupId
    }
  }
}
    `;

/**
 * __useGetProjectPermissionsQuery__
 *
 * To run a query within a React component, call `useGetProjectPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPermissionsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      memberType: // value for 'memberType'
 *      isMemberGroupFromOrg: // value for 'isMemberGroupFromOrg'
 *      projectId: // value for 'projectId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetProjectPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables>(GetProjectPermissionsDocument, options);
      }
export function useGetProjectPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables>(GetProjectPermissionsDocument, options);
        }
export type GetProjectPermissionsQueryHookResult = ReturnType<typeof useGetProjectPermissionsQuery>;
export type GetProjectPermissionsLazyQueryHookResult = ReturnType<typeof useGetProjectPermissionsLazyQuery>;
export type GetProjectPermissionsQueryResult = Apollo.QueryResult<GetProjectPermissionsQuery, GetProjectPermissionsQueryVariables>;
export const SetOrganizationRoleForUsersDocument = gql`
    mutation SetOrganizationRoleForUsers($userIds: [ID!], $roleId: ID!) {
  setOrganizationRoleForUsers(userIds: $userIds, roleId: $roleId)
}
    `;
export type SetOrganizationRoleForUsersMutationFn = Apollo.MutationFunction<SetOrganizationRoleForUsersMutation, SetOrganizationRoleForUsersMutationVariables>;

/**
 * __useSetOrganizationRoleForUsersMutation__
 *
 * To run a mutation, you first call `useSetOrganizationRoleForUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationRoleForUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationRoleForUsersMutation, { data, loading, error }] = useSetOrganizationRoleForUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSetOrganizationRoleForUsersMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationRoleForUsersMutation, SetOrganizationRoleForUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationRoleForUsersMutation, SetOrganizationRoleForUsersMutationVariables>(SetOrganizationRoleForUsersDocument, options);
      }
export type SetOrganizationRoleForUsersMutationHookResult = ReturnType<typeof useSetOrganizationRoleForUsersMutation>;
export type SetOrganizationRoleForUsersMutationResult = Apollo.MutationResult<SetOrganizationRoleForUsersMutation>;
export type SetOrganizationRoleForUsersMutationOptions = Apollo.BaseMutationOptions<SetOrganizationRoleForUsersMutation, SetOrganizationRoleForUsersMutationVariables>;
export const SetOrganizationEnforceSsoDocument = gql`
    mutation SetOrganizationEnforceSSO($enforceSSO: Boolean) {
  setOrganizationEnforceSSO(enforceSSO: $enforceSSO)
}
    `;
export type SetOrganizationEnforceSsoMutationFn = Apollo.MutationFunction<SetOrganizationEnforceSsoMutation, SetOrganizationEnforceSsoMutationVariables>;

/**
 * __useSetOrganizationEnforceSsoMutation__
 *
 * To run a mutation, you first call `useSetOrganizationEnforceSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationEnforceSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationEnforceSsoMutation, { data, loading, error }] = useSetOrganizationEnforceSsoMutation({
 *   variables: {
 *      enforceSSO: // value for 'enforceSSO'
 *   },
 * });
 */
export function useSetOrganizationEnforceSsoMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationEnforceSsoMutation, SetOrganizationEnforceSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationEnforceSsoMutation, SetOrganizationEnforceSsoMutationVariables>(SetOrganizationEnforceSsoDocument, options);
      }
export type SetOrganizationEnforceSsoMutationHookResult = ReturnType<typeof useSetOrganizationEnforceSsoMutation>;
export type SetOrganizationEnforceSsoMutationResult = Apollo.MutationResult<SetOrganizationEnforceSsoMutation>;
export type SetOrganizationEnforceSsoMutationOptions = Apollo.BaseMutationOptions<SetOrganizationEnforceSsoMutation, SetOrganizationEnforceSsoMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: ID!, $name: String!, $settings: OrganizationSettingsInput) {
  updateOrganization(id: $id, name: $name, settings: $settings)
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationSettingsDocument = gql`
    mutation UpdateOrganizationSettings($id: ID!, $settings: OrganizationSettingsInput) {
  updateOrganizationSettings(id: $id, settings: $settings)
}
    `;
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>;

/**
 * __useUpdateOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingsMutation, { data, loading, error }] = useUpdateOrganizationSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateOrganizationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>(UpdateOrganizationSettingsDocument, options);
      }
export type UpdateOrganizationSettingsMutationHookResult = ReturnType<typeof useUpdateOrganizationSettingsMutation>;
export type UpdateOrganizationSettingsMutationResult = Apollo.MutationResult<UpdateOrganizationSettingsMutation>;
export type UpdateOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>;
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    enforceSSO
    id
    name
    subscriptionLevelId
    organizationSubscriptionLevel
    settings {
      approve_action_label
      reject_action_label
      finalize_action_label
      ai_admin_settings
      ai_organization_settings
    }
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const ActivateSubscriptionDocument = gql`
    mutation ActivateSubscription($id: ID!, $addressLine1: String!, $addressLine2: String, $country: String!, $city: String!, $locale: String!, $postalcode: String!, $redirectUrl: String!, $vatIdentificationNumber: String!) {
  activateSubscription(
    id: $id
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    country: $country
    city: $city
    locale: $locale
    postalcode: $postalcode
    redirectUrl: $redirectUrl
    vatIdentificationNumber: $vatIdentificationNumber
  ) {
    errors
    mollieRedirect
    success
  }
}
    `;
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      country: // value for 'country'
 *      city: // value for 'city'
 *      locale: // value for 'locale'
 *      postalcode: // value for 'postalcode'
 *      redirectUrl: // value for 'redirectUrl'
 *      vatIdentificationNumber: // value for 'vatIdentificationNumber'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>(ActivateSubscriptionDocument, options);
      }
export type ActivateSubscriptionMutationHookResult = ReturnType<typeof useActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationResult = Apollo.MutationResult<ActivateSubscriptionMutation>;
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ActivateSubscriptionMutation, ActivateSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($description: String, $reason: String!) {
  cancelSubscription(description: $description, reason: $reason) {
    id
    errors
    success
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ChangePaymentMethodDocument = gql`
    mutation ChangePaymentMethod($locale: String!, $redirectUrl: String!) {
  changePaymentMethod(locale: $locale, redirectUrl: $redirectUrl) {
    errors
    mollieRedirect
    success
  }
}
    `;
export type ChangePaymentMethodMutationFn = Apollo.MutationFunction<ChangePaymentMethodMutation, ChangePaymentMethodMutationVariables>;

/**
 * __useChangePaymentMethodMutation__
 *
 * To run a mutation, you first call `useChangePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePaymentMethodMutation, { data, loading, error }] = useChangePaymentMethodMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useChangePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<ChangePaymentMethodMutation, ChangePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePaymentMethodMutation, ChangePaymentMethodMutationVariables>(ChangePaymentMethodDocument, options);
      }
export type ChangePaymentMethodMutationHookResult = ReturnType<typeof useChangePaymentMethodMutation>;
export type ChangePaymentMethodMutationResult = Apollo.MutationResult<ChangePaymentMethodMutation>;
export type ChangePaymentMethodMutationOptions = Apollo.BaseMutationOptions<ChangePaymentMethodMutation, ChangePaymentMethodMutationVariables>;
export const ChangeSubscriptionDocument = gql`
    mutation ChangeSubscription($id: ID!, $locale: String!, $redirectUrl: String!) {
  changeSubscription(id: $id, locale: $locale, redirectUrl: $redirectUrl) {
    errors
    mollieRedirect
    success
  }
}
    `;
export type ChangeSubscriptionMutationFn = Apollo.MutationFunction<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;

/**
 * __useChangeSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionMutation, { data, loading, error }] = useChangeSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useChangeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>(ChangeSubscriptionDocument, options);
      }
export type ChangeSubscriptionMutationHookResult = ReturnType<typeof useChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationResult = Apollo.MutationResult<ChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    countries {
      id
      label
    }
    errors
    success
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const SubscriptionLevelsDocument = gql`
    query SubscriptionLevels {
  subscriptionLevels {
    subscriptionLevels {
      iconName
      id
      isPopular
      isSelectable
      isTrial
      name
      order
      permissions
      pricing
    }
    errors
    success
  }
}
    `;

/**
 * __useSubscriptionLevelsQuery__
 *
 * To run a query within a React component, call `useSubscriptionLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionLevelsQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionLevelsQuery, SubscriptionLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionLevelsQuery, SubscriptionLevelsQueryVariables>(SubscriptionLevelsDocument, options);
      }
export function useSubscriptionLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionLevelsQuery, SubscriptionLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionLevelsQuery, SubscriptionLevelsQueryVariables>(SubscriptionLevelsDocument, options);
        }
export type SubscriptionLevelsQueryHookResult = ReturnType<typeof useSubscriptionLevelsQuery>;
export type SubscriptionLevelsLazyQueryHookResult = ReturnType<typeof useSubscriptionLevelsLazyQuery>;
export type SubscriptionLevelsQueryResult = Apollo.QueryResult<SubscriptionLevelsQuery, SubscriptionLevelsQueryVariables>;
export const FieldDefinitionDocument = gql`
    query FieldDefinition($fieldDefinitionId: ID!, $templateService: TemplateService) {
  fieldDefinition(
    fieldDefinitionId: $fieldDefinitionId
    templateService: $templateService
  ) {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useFieldDefinitionQuery__
 *
 * To run a query within a React component, call `useFieldDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDefinitionQuery({
 *   variables: {
 *      fieldDefinitionId: // value for 'fieldDefinitionId'
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useFieldDefinitionQuery(baseOptions: Apollo.QueryHookOptions<FieldDefinitionQuery, FieldDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDefinitionQuery, FieldDefinitionQueryVariables>(FieldDefinitionDocument, options);
      }
export function useFieldDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDefinitionQuery, FieldDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDefinitionQuery, FieldDefinitionQueryVariables>(FieldDefinitionDocument, options);
        }
export type FieldDefinitionQueryHookResult = ReturnType<typeof useFieldDefinitionQuery>;
export type FieldDefinitionLazyQueryHookResult = ReturnType<typeof useFieldDefinitionLazyQuery>;
export type FieldDefinitionQueryResult = Apollo.QueryResult<FieldDefinitionQuery, FieldDefinitionQueryVariables>;
export const SubscriptionOrganizationDocument = gql`
    query SubscriptionOrganization($organizationId: ID) {
  subscriptionOrganization(organizationId: $organizationId) {
    subscriptionOrganization {
      billingEmail
      expireDate
      nextBillingCycle
      nextSubscriptionLevelId
      organizationId
      paymentInfo {
        isValid
        last3Digits
        type
      }
      subscriptionEndDate
      subscriptionLevelId
    }
    errors
    success
  }
}
    `;

/**
 * __useSubscriptionOrganizationQuery__
 *
 * To run a query within a React component, call `useSubscriptionOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSubscriptionOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionOrganizationQuery, SubscriptionOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionOrganizationQuery, SubscriptionOrganizationQueryVariables>(SubscriptionOrganizationDocument, options);
      }
export function useSubscriptionOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionOrganizationQuery, SubscriptionOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionOrganizationQuery, SubscriptionOrganizationQueryVariables>(SubscriptionOrganizationDocument, options);
        }
export type SubscriptionOrganizationQueryHookResult = ReturnType<typeof useSubscriptionOrganizationQuery>;
export type SubscriptionOrganizationLazyQueryHookResult = ReturnType<typeof useSubscriptionOrganizationLazyQuery>;
export type SubscriptionOrganizationQueryResult = Apollo.QueryResult<SubscriptionOrganizationQuery, SubscriptionOrganizationQueryVariables>;
export const SubscriptionOrganizationExpireDocument = gql`
    query SubscriptionOrganizationExpire($organizationId: ID) {
  subscriptionOrganizationExpire(organizationId: $organizationId) {
    errors
    expireDate
    success
  }
}
    `;

/**
 * __useSubscriptionOrganizationExpireQuery__
 *
 * To run a query within a React component, call `useSubscriptionOrganizationExpireQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionOrganizationExpireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionOrganizationExpireQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSubscriptionOrganizationExpireQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionOrganizationExpireQuery, SubscriptionOrganizationExpireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionOrganizationExpireQuery, SubscriptionOrganizationExpireQueryVariables>(SubscriptionOrganizationExpireDocument, options);
      }
export function useSubscriptionOrganizationExpireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionOrganizationExpireQuery, SubscriptionOrganizationExpireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionOrganizationExpireQuery, SubscriptionOrganizationExpireQueryVariables>(SubscriptionOrganizationExpireDocument, options);
        }
export type SubscriptionOrganizationExpireQueryHookResult = ReturnType<typeof useSubscriptionOrganizationExpireQuery>;
export type SubscriptionOrganizationExpireLazyQueryHookResult = ReturnType<typeof useSubscriptionOrganizationExpireLazyQuery>;
export type SubscriptionOrganizationExpireQueryResult = Apollo.QueryResult<SubscriptionOrganizationExpireQuery, SubscriptionOrganizationExpireQueryVariables>;
export const SubscriptionOrganizationUsersDocument = gql`
    query SubscriptionOrganizationUsers {
  subscriptionOrganizationUsers {
    isFixedPrice
    pricePerUnit
    users
    errors
    success
  }
}
    `;

/**
 * __useSubscriptionOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useSubscriptionOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionOrganizationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionOrganizationUsersQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionOrganizationUsersQuery, SubscriptionOrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionOrganizationUsersQuery, SubscriptionOrganizationUsersQueryVariables>(SubscriptionOrganizationUsersDocument, options);
      }
export function useSubscriptionOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionOrganizationUsersQuery, SubscriptionOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionOrganizationUsersQuery, SubscriptionOrganizationUsersQueryVariables>(SubscriptionOrganizationUsersDocument, options);
        }
export type SubscriptionOrganizationUsersQueryHookResult = ReturnType<typeof useSubscriptionOrganizationUsersQuery>;
export type SubscriptionOrganizationUsersLazyQueryHookResult = ReturnType<typeof useSubscriptionOrganizationUsersLazyQuery>;
export type SubscriptionOrganizationUsersQueryResult = Apollo.QueryResult<SubscriptionOrganizationUsersQuery, SubscriptionOrganizationUsersQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($active: Boolean!, $id: ID!, $organizationId: String!, $email: String!, $firstName: String!, $jobTitle: String!, $language: String!, $lastName: String!, $phoneNumber: String!) {
  updateProfile(
    active: $active
    id: $id
    organizationId: $organizationId
    email: $email
    firstName: $firstName
    jobTitle: $jobTitle
    language: $language
    lastName: $lastName
    phoneNumber: $phoneNumber
  )
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      active: // value for 'active'
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      jobTitle: // value for 'jobTitle'
 *      language: // value for 'language'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateSupportSettingsDocument = gql`
    mutation UpdateSupportSettings($date: String) {
  updateSupportSettings(date: $date)
}
    `;
export type UpdateSupportSettingsMutationFn = Apollo.MutationFunction<UpdateSupportSettingsMutation, UpdateSupportSettingsMutationVariables>;

/**
 * __useUpdateSupportSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSupportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupportSettingsMutation, { data, loading, error }] = useUpdateSupportSettingsMutation({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateSupportSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupportSettingsMutation, UpdateSupportSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupportSettingsMutation, UpdateSupportSettingsMutationVariables>(UpdateSupportSettingsDocument, options);
      }
export type UpdateSupportSettingsMutationHookResult = ReturnType<typeof useUpdateSupportSettingsMutation>;
export type UpdateSupportSettingsMutationResult = Apollo.MutationResult<UpdateSupportSettingsMutation>;
export type UpdateSupportSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSupportSettingsMutation, UpdateSupportSettingsMutationVariables>;
export const ActivateUsersDocument = gql`
    mutation ActivateUsers($userIds: [ID]!) {
  activateUsers(userIds: $userIds)
}
    `;
export type ActivateUsersMutationFn = Apollo.MutationFunction<ActivateUsersMutation, ActivateUsersMutationVariables>;

/**
 * __useActivateUsersMutation__
 *
 * To run a mutation, you first call `useActivateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUsersMutation, { data, loading, error }] = useActivateUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useActivateUsersMutation(baseOptions?: Apollo.MutationHookOptions<ActivateUsersMutation, ActivateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateUsersMutation, ActivateUsersMutationVariables>(ActivateUsersDocument, options);
      }
export type ActivateUsersMutationHookResult = ReturnType<typeof useActivateUsersMutation>;
export type ActivateUsersMutationResult = Apollo.MutationResult<ActivateUsersMutation>;
export type ActivateUsersMutationOptions = Apollo.BaseMutationOptions<ActivateUsersMutation, ActivateUsersMutationVariables>;
export const ConfirmMoveExistingUserDocument = gql`
    mutation ConfirmMoveExistingUser($token: String!) {
  confirmMoveExistingUser(token: $token) {
    invitedUserId
    invitedUserOrganizationId
    invitedUserOrganizationName
    inviterEmail
    inviterName
    newOrganizationId
    newOrganizationName
    state
  }
}
    `;
export type ConfirmMoveExistingUserMutationFn = Apollo.MutationFunction<ConfirmMoveExistingUserMutation, ConfirmMoveExistingUserMutationVariables>;

/**
 * __useConfirmMoveExistingUserMutation__
 *
 * To run a mutation, you first call `useConfirmMoveExistingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMoveExistingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMoveExistingUserMutation, { data, loading, error }] = useConfirmMoveExistingUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmMoveExistingUserMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMoveExistingUserMutation, ConfirmMoveExistingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMoveExistingUserMutation, ConfirmMoveExistingUserMutationVariables>(ConfirmMoveExistingUserDocument, options);
      }
export type ConfirmMoveExistingUserMutationHookResult = ReturnType<typeof useConfirmMoveExistingUserMutation>;
export type ConfirmMoveExistingUserMutationResult = Apollo.MutationResult<ConfirmMoveExistingUserMutation>;
export type ConfirmMoveExistingUserMutationOptions = Apollo.BaseMutationOptions<ConfirmMoveExistingUserMutation, ConfirmMoveExistingUserMutationVariables>;
export const DeactivateUsersDocument = gql`
    mutation DeactivateUsers($userIds: [ID]!) {
  deactivateUsers(userIds: $userIds)
}
    `;
export type DeactivateUsersMutationFn = Apollo.MutationFunction<DeactivateUsersMutation, DeactivateUsersMutationVariables>;

/**
 * __useDeactivateUsersMutation__
 *
 * To run a mutation, you first call `useDeactivateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUsersMutation, { data, loading, error }] = useDeactivateUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useDeactivateUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateUsersMutation, DeactivateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateUsersMutation, DeactivateUsersMutationVariables>(DeactivateUsersDocument, options);
      }
export type DeactivateUsersMutationHookResult = ReturnType<typeof useDeactivateUsersMutation>;
export type DeactivateUsersMutationResult = Apollo.MutationResult<DeactivateUsersMutation>;
export type DeactivateUsersMutationOptions = Apollo.BaseMutationOptions<DeactivateUsersMutation, DeactivateUsersMutationVariables>;
export const InviteExistingUsersDocument = gql`
    mutation InviteExistingUsers($userEmails: [String], $message: String, $adminEmails: [String]) {
  inviteExistingUsers(
    adminEmails: $adminEmails
    message: $message
    userEmails: $userEmails
  )
}
    `;
export type InviteExistingUsersMutationFn = Apollo.MutationFunction<InviteExistingUsersMutation, InviteExistingUsersMutationVariables>;

/**
 * __useInviteExistingUsersMutation__
 *
 * To run a mutation, you first call `useInviteExistingUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteExistingUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteExistingUsersMutation, { data, loading, error }] = useInviteExistingUsersMutation({
 *   variables: {
 *      userEmails: // value for 'userEmails'
 *      message: // value for 'message'
 *      adminEmails: // value for 'adminEmails'
 *   },
 * });
 */
export function useInviteExistingUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteExistingUsersMutation, InviteExistingUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteExistingUsersMutation, InviteExistingUsersMutationVariables>(InviteExistingUsersDocument, options);
      }
export type InviteExistingUsersMutationHookResult = ReturnType<typeof useInviteExistingUsersMutation>;
export type InviteExistingUsersMutationResult = Apollo.MutationResult<InviteExistingUsersMutation>;
export type InviteExistingUsersMutationOptions = Apollo.BaseMutationOptions<InviteExistingUsersMutation, InviteExistingUsersMutationVariables>;
export const InviteUsersDocument = gql`
    mutation InviteUsers($adminEmails: [String], $memberEmails: [String], $message: String, $sendEmail: Boolean, $style: MailStyle) {
  inviteUsers(
    adminEmails: $adminEmails
    memberEmails: $memberEmails
    message: $message
    sendEmail: $sendEmail
    style: $style
  )
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      adminEmails: // value for 'adminEmails'
 *      memberEmails: // value for 'memberEmails'
 *      message: // value for 'message'
 *      sendEmail: // value for 'sendEmail'
 *      style: // value for 'style'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const MoveToNewOrganizationDocument = gql`
    mutation MoveToNewOrganization($token: String!) {
  moveToNewOrganization(token: $token) {
    invitedUserId
    invitedUserOrganizationId
    invitedUserOrganizationName
    inviterEmail
    inviterName
    newOrganizationId
    newOrganizationName
    state
  }
}
    `;
export type MoveToNewOrganizationMutationFn = Apollo.MutationFunction<MoveToNewOrganizationMutation, MoveToNewOrganizationMutationVariables>;

/**
 * __useMoveToNewOrganizationMutation__
 *
 * To run a mutation, you first call `useMoveToNewOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToNewOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToNewOrganizationMutation, { data, loading, error }] = useMoveToNewOrganizationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMoveToNewOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<MoveToNewOrganizationMutation, MoveToNewOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveToNewOrganizationMutation, MoveToNewOrganizationMutationVariables>(MoveToNewOrganizationDocument, options);
      }
export type MoveToNewOrganizationMutationHookResult = ReturnType<typeof useMoveToNewOrganizationMutation>;
export type MoveToNewOrganizationMutationResult = Apollo.MutationResult<MoveToNewOrganizationMutation>;
export type MoveToNewOrganizationMutationOptions = Apollo.BaseMutationOptions<MoveToNewOrganizationMutation, MoveToNewOrganizationMutationVariables>;
export const ReInviteUsersDocument = gql`
    mutation ReInviteUsers($requestIds: [ID]!, $style: MailStyle) {
  reInviteUsers(requestIds: $requestIds, style: $style)
}
    `;
export type ReInviteUsersMutationFn = Apollo.MutationFunction<ReInviteUsersMutation, ReInviteUsersMutationVariables>;

/**
 * __useReInviteUsersMutation__
 *
 * To run a mutation, you first call `useReInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reInviteUsersMutation, { data, loading, error }] = useReInviteUsersMutation({
 *   variables: {
 *      requestIds: // value for 'requestIds'
 *      style: // value for 'style'
 *   },
 * });
 */
export function useReInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<ReInviteUsersMutation, ReInviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReInviteUsersMutation, ReInviteUsersMutationVariables>(ReInviteUsersDocument, options);
      }
export type ReInviteUsersMutationHookResult = ReturnType<typeof useReInviteUsersMutation>;
export type ReInviteUsersMutationResult = Apollo.MutationResult<ReInviteUsersMutation>;
export type ReInviteUsersMutationOptions = Apollo.BaseMutationOptions<ReInviteUsersMutation, ReInviteUsersMutationVariables>;
export const StayInCurrentOrganizationDocument = gql`
    mutation StayInCurrentOrganization($token: String!) {
  stayInCurrentOrganization(token: $token) {
    invitedUserId
    invitedUserOrganizationId
    invitedUserOrganizationName
    inviterEmail
    inviterName
    newOrganizationId
    newOrganizationName
    state
  }
}
    `;
export type StayInCurrentOrganizationMutationFn = Apollo.MutationFunction<StayInCurrentOrganizationMutation, StayInCurrentOrganizationMutationVariables>;

/**
 * __useStayInCurrentOrganizationMutation__
 *
 * To run a mutation, you first call `useStayInCurrentOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStayInCurrentOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stayInCurrentOrganizationMutation, { data, loading, error }] = useStayInCurrentOrganizationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useStayInCurrentOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<StayInCurrentOrganizationMutation, StayInCurrentOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StayInCurrentOrganizationMutation, StayInCurrentOrganizationMutationVariables>(StayInCurrentOrganizationDocument, options);
      }
export type StayInCurrentOrganizationMutationHookResult = ReturnType<typeof useStayInCurrentOrganizationMutation>;
export type StayInCurrentOrganizationMutationResult = Apollo.MutationResult<StayInCurrentOrganizationMutation>;
export type StayInCurrentOrganizationMutationOptions = Apollo.BaseMutationOptions<StayInCurrentOrganizationMutation, StayInCurrentOrganizationMutationVariables>;
export const OrganizationUsersIncludeDocument = gql`
    query OrganizationUsersInclude($includeActive: Boolean = false, $includeEmail: Boolean = false, $includeInvited: Boolean = false, $includeIsAdmin: Boolean = false, $includeJobTitle: Boolean = false, $includeLastOnlineAt: Boolean = false, $includeOrganization: Boolean = false, $includePhoneNumber: Boolean = false, $includeRoles: Boolean = false, $includeState: Boolean = false, $includeExplicitProjectMember: Boolean = false) {
  organizationUsers {
    ... on User {
      ...FragmentUserInclude
    }
  }
}
    ${FragmentUserIncludeFragmentDoc}`;

/**
 * __useOrganizationUsersIncludeQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersIncludeQuery({
 *   variables: {
 *      includeActive: // value for 'includeActive'
 *      includeEmail: // value for 'includeEmail'
 *      includeInvited: // value for 'includeInvited'
 *      includeIsAdmin: // value for 'includeIsAdmin'
 *      includeJobTitle: // value for 'includeJobTitle'
 *      includeLastOnlineAt: // value for 'includeLastOnlineAt'
 *      includeOrganization: // value for 'includeOrganization'
 *      includePhoneNumber: // value for 'includePhoneNumber'
 *      includeRoles: // value for 'includeRoles'
 *      includeState: // value for 'includeState'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *   },
 * });
 */
export function useOrganizationUsersIncludeQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationUsersIncludeQuery, OrganizationUsersIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationUsersIncludeQuery, OrganizationUsersIncludeQueryVariables>(OrganizationUsersIncludeDocument, options);
      }
export function useOrganizationUsersIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUsersIncludeQuery, OrganizationUsersIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationUsersIncludeQuery, OrganizationUsersIncludeQueryVariables>(OrganizationUsersIncludeDocument, options);
        }
export type OrganizationUsersIncludeQueryHookResult = ReturnType<typeof useOrganizationUsersIncludeQuery>;
export type OrganizationUsersIncludeLazyQueryHookResult = ReturnType<typeof useOrganizationUsersIncludeLazyQuery>;
export type OrganizationUsersIncludeQueryResult = Apollo.QueryResult<OrganizationUsersIncludeQuery, OrganizationUsersIncludeQueryVariables>;
export const UsersIncludeDocument = gql`
    query UsersInclude($includeActive: Boolean = false, $includeEmail: Boolean = false, $includeInvited: Boolean = false, $includeIsAdmin: Boolean = false, $includeJobTitle: Boolean = false, $includeLastOnlineAt: Boolean = false, $includeOrganization: Boolean = false, $includePhoneNumber: Boolean = false, $includeRoles: Boolean = false, $includeState: Boolean = false, $includeExplicitProjectMember: Boolean = false, $projectId: ID = null) {
  users(projectId: $projectId) {
    ...FragmentUserInclude
  }
}
    ${FragmentUserIncludeFragmentDoc}`;

/**
 * __useUsersIncludeQuery__
 *
 * To run a query within a React component, call `useUsersIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersIncludeQuery({
 *   variables: {
 *      includeActive: // value for 'includeActive'
 *      includeEmail: // value for 'includeEmail'
 *      includeInvited: // value for 'includeInvited'
 *      includeIsAdmin: // value for 'includeIsAdmin'
 *      includeJobTitle: // value for 'includeJobTitle'
 *      includeLastOnlineAt: // value for 'includeLastOnlineAt'
 *      includeOrganization: // value for 'includeOrganization'
 *      includePhoneNumber: // value for 'includePhoneNumber'
 *      includeRoles: // value for 'includeRoles'
 *      includeState: // value for 'includeState'
 *      includeExplicitProjectMember: // value for 'includeExplicitProjectMember'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUsersIncludeQuery(baseOptions?: Apollo.QueryHookOptions<UsersIncludeQuery, UsersIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersIncludeQuery, UsersIncludeQueryVariables>(UsersIncludeDocument, options);
      }
export function useUsersIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersIncludeQuery, UsersIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersIncludeQuery, UsersIncludeQueryVariables>(UsersIncludeDocument, options);
        }
export type UsersIncludeQueryHookResult = ReturnType<typeof useUsersIncludeQuery>;
export type UsersIncludeLazyQueryHookResult = ReturnType<typeof useUsersIncludeLazyQuery>;
export type UsersIncludeQueryResult = Apollo.QueryResult<UsersIncludeQuery, UsersIncludeQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SupportSettingsDocument = gql`
    query supportSettings {
  supportSettings
}
    `;

/**
 * __useSupportSettingsQuery__
 *
 * To run a query within a React component, call `useSupportSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SupportSettingsQuery, SupportSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportSettingsQuery, SupportSettingsQueryVariables>(SupportSettingsDocument, options);
      }
export function useSupportSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportSettingsQuery, SupportSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportSettingsQuery, SupportSettingsQueryVariables>(SupportSettingsDocument, options);
        }
export type SupportSettingsQueryHookResult = ReturnType<typeof useSupportSettingsQuery>;
export type SupportSettingsLazyQueryHookResult = ReturnType<typeof useSupportSettingsLazyQuery>;
export type SupportSettingsQueryResult = Apollo.QueryResult<SupportSettingsQuery, SupportSettingsQueryVariables>;
export const MyOrganizationDocument = gql`
    query MyOrganization {
  myOrganization {
    id
    enforceSSO
    name
    roles {
      id
      name
    }
  }
}
    `;

/**
 * __useMyOrganizationQuery__
 *
 * To run a query within a React component, call `useMyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
      }
export function useMyOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
        }
export type MyOrganizationQueryHookResult = ReturnType<typeof useMyOrganizationQuery>;
export type MyOrganizationLazyQueryHookResult = ReturnType<typeof useMyOrganizationLazyQuery>;
export type MyOrganizationQueryResult = Apollo.QueryResult<MyOrganizationQuery, MyOrganizationQueryVariables>;
export const CreateHierarchicalListDocument = gql`
    mutation CreateHierarchicalList($name: String!, $nodes: [Node]!, $projectId: ID) {
  createHierarchicalList(name: $name, nodes: $nodes, projectId: $projectId)
}
    `;
export type CreateHierarchicalListMutationFn = Apollo.MutationFunction<CreateHierarchicalListMutation, CreateHierarchicalListMutationVariables>;

/**
 * __useCreateHierarchicalListMutation__
 *
 * To run a mutation, you first call `useCreateHierarchicalListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHierarchicalListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHierarchicalListMutation, { data, loading, error }] = useCreateHierarchicalListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      nodes: // value for 'nodes'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateHierarchicalListMutation(baseOptions?: Apollo.MutationHookOptions<CreateHierarchicalListMutation, CreateHierarchicalListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHierarchicalListMutation, CreateHierarchicalListMutationVariables>(CreateHierarchicalListDocument, options);
      }
export type CreateHierarchicalListMutationHookResult = ReturnType<typeof useCreateHierarchicalListMutation>;
export type CreateHierarchicalListMutationResult = Apollo.MutationResult<CreateHierarchicalListMutation>;
export type CreateHierarchicalListMutationOptions = Apollo.BaseMutationOptions<CreateHierarchicalListMutation, CreateHierarchicalListMutationVariables>;
export const DeleteHierarchicalListsDocument = gql`
    mutation DeleteHierarchicalLists($ids: [ID]!) {
  deleteHierarchicalLists(ids: $ids)
}
    `;
export type DeleteHierarchicalListsMutationFn = Apollo.MutationFunction<DeleteHierarchicalListsMutation, DeleteHierarchicalListsMutationVariables>;

/**
 * __useDeleteHierarchicalListsMutation__
 *
 * To run a mutation, you first call `useDeleteHierarchicalListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHierarchicalListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHierarchicalListsMutation, { data, loading, error }] = useDeleteHierarchicalListsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteHierarchicalListsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHierarchicalListsMutation, DeleteHierarchicalListsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHierarchicalListsMutation, DeleteHierarchicalListsMutationVariables>(DeleteHierarchicalListsDocument, options);
      }
export type DeleteHierarchicalListsMutationHookResult = ReturnType<typeof useDeleteHierarchicalListsMutation>;
export type DeleteHierarchicalListsMutationResult = Apollo.MutationResult<DeleteHierarchicalListsMutation>;
export type DeleteHierarchicalListsMutationOptions = Apollo.BaseMutationOptions<DeleteHierarchicalListsMutation, DeleteHierarchicalListsMutationVariables>;
export const EditHierarchicalListDocument = gql`
    mutation EditHierarchicalList($id: ID!, $name: String!, $nodes: [Node!]!) {
  editHierarchicalList(id: $id, name: $name, nodes: $nodes)
}
    `;
export type EditHierarchicalListMutationFn = Apollo.MutationFunction<EditHierarchicalListMutation, EditHierarchicalListMutationVariables>;

/**
 * __useEditHierarchicalListMutation__
 *
 * To run a mutation, you first call `useEditHierarchicalListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHierarchicalListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHierarchicalListMutation, { data, loading, error }] = useEditHierarchicalListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      nodes: // value for 'nodes'
 *   },
 * });
 */
export function useEditHierarchicalListMutation(baseOptions?: Apollo.MutationHookOptions<EditHierarchicalListMutation, EditHierarchicalListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditHierarchicalListMutation, EditHierarchicalListMutationVariables>(EditHierarchicalListDocument, options);
      }
export type EditHierarchicalListMutationHookResult = ReturnType<typeof useEditHierarchicalListMutation>;
export type EditHierarchicalListMutationResult = Apollo.MutationResult<EditHierarchicalListMutation>;
export type EditHierarchicalListMutationOptions = Apollo.BaseMutationOptions<EditHierarchicalListMutation, EditHierarchicalListMutationVariables>;
export const HierarchicalListDocument = gql`
    query HierarchicalList($id: ID!) {
  hierarchicalList(id: $id) {
    id
    hierarchy {
      id
      name
      parentNodeId
      previousNodeId
    }
  }
}
    `;

/**
 * __useHierarchicalListQuery__
 *
 * To run a query within a React component, call `useHierarchicalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHierarchicalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHierarchicalListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHierarchicalListQuery(baseOptions: Apollo.QueryHookOptions<HierarchicalListQuery, HierarchicalListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HierarchicalListQuery, HierarchicalListQueryVariables>(HierarchicalListDocument, options);
      }
export function useHierarchicalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HierarchicalListQuery, HierarchicalListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HierarchicalListQuery, HierarchicalListQueryVariables>(HierarchicalListDocument, options);
        }
export type HierarchicalListQueryHookResult = ReturnType<typeof useHierarchicalListQuery>;
export type HierarchicalListLazyQueryHookResult = ReturnType<typeof useHierarchicalListLazyQuery>;
export type HierarchicalListQueryResult = Apollo.QueryResult<HierarchicalListQuery, HierarchicalListQueryVariables>;
export const HierarchicalListsIncludeDocument = gql`
    query HierarchicalListsInclude($includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeScope: Boolean = false, $includeOrgItems: Boolean, $projectId: ID) {
  hierarchicalLists(includeOrgItems: $includeOrgItems, projectId: $projectId) {
    id
    createdAt @include(if: $includeCreatedAt)
    createdBy @include(if: $includeCreatedBy) {
      id
      displayName
      organization {
        name
      }
    }
    name
    scope @include(if: $includeScope)
  }
}
    `;

/**
 * __useHierarchicalListsIncludeQuery__
 *
 * To run a query within a React component, call `useHierarchicalListsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHierarchicalListsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHierarchicalListsIncludeQuery({
 *   variables: {
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeScope: // value for 'includeScope'
 *      includeOrgItems: // value for 'includeOrgItems'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useHierarchicalListsIncludeQuery(baseOptions?: Apollo.QueryHookOptions<HierarchicalListsIncludeQuery, HierarchicalListsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HierarchicalListsIncludeQuery, HierarchicalListsIncludeQueryVariables>(HierarchicalListsIncludeDocument, options);
      }
export function useHierarchicalListsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HierarchicalListsIncludeQuery, HierarchicalListsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HierarchicalListsIncludeQuery, HierarchicalListsIncludeQueryVariables>(HierarchicalListsIncludeDocument, options);
        }
export type HierarchicalListsIncludeQueryHookResult = ReturnType<typeof useHierarchicalListsIncludeQuery>;
export type HierarchicalListsIncludeLazyQueryHookResult = ReturnType<typeof useHierarchicalListsIncludeLazyQuery>;
export type HierarchicalListsIncludeQueryResult = Apollo.QueryResult<HierarchicalListsIncludeQuery, HierarchicalListsIncludeQueryVariables>;
export const CreateFieldDefinitionDocument = gql`
    mutation CreateFieldDefinition($id: ID!, $description: String, $displayDescription: String, $displayName: String!, $fieldDefinitionTypeInput: FieldDefinitionTypeInput, $templateService: TemplateService!, $indicative: Boolean, $name: String!, $scope: ScopeType!, $type: ID!) {
  createFieldDefinition(
    id: $id
    description: $description
    displayDescription: $displayDescription
    displayName: $displayName
    indicative: $indicative
    fieldDefinitionTypeInput: $fieldDefinitionTypeInput
    templateService: $templateService
    name: $name
    scope: $scope
    type: $type
  ) {
    errors
    id
    success
  }
}
    `;
export type CreateFieldDefinitionMutationFn = Apollo.MutationFunction<CreateFieldDefinitionMutation, CreateFieldDefinitionMutationVariables>;

/**
 * __useCreateFieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateFieldDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldDefinitionMutation, { data, loading, error }] = useCreateFieldDefinitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      displayDescription: // value for 'displayDescription'
 *      displayName: // value for 'displayName'
 *      fieldDefinitionTypeInput: // value for 'fieldDefinitionTypeInput'
 *      templateService: // value for 'templateService'
 *      indicative: // value for 'indicative'
 *      name: // value for 'name'
 *      scope: // value for 'scope'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateFieldDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<CreateFieldDefinitionMutation, CreateFieldDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFieldDefinitionMutation, CreateFieldDefinitionMutationVariables>(CreateFieldDefinitionDocument, options);
      }
export type CreateFieldDefinitionMutationHookResult = ReturnType<typeof useCreateFieldDefinitionMutation>;
export type CreateFieldDefinitionMutationResult = Apollo.MutationResult<CreateFieldDefinitionMutation>;
export type CreateFieldDefinitionMutationOptions = Apollo.BaseMutationOptions<CreateFieldDefinitionMutation, CreateFieldDefinitionMutationVariables>;
export const UpdateFieldDefinitionDocument = gql`
    mutation UpdateFieldDefinition($autoPublish: Boolean!, $description: String, $displayDescription: String, $displayName: String!, $fieldDefinitionTypeInput: FieldDefinitionTypeInput, $id: ID!, $indicative: Boolean, $name: String!, $templateService: TemplateService!, $type: ID!) {
  updateFieldDefinition(
    autoPublish: $autoPublish
    description: $description
    displayDescription: $displayDescription
    displayName: $displayName
    fieldDefinitionTypeInput: $fieldDefinitionTypeInput
    id: $id
    indicative: $indicative
    name: $name
    templateService: $templateService
    type: $type
  ) {
    errors
    id
    success
  }
}
    `;
export type UpdateFieldDefinitionMutationFn = Apollo.MutationFunction<UpdateFieldDefinitionMutation, UpdateFieldDefinitionMutationVariables>;

/**
 * __useUpdateFieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateFieldDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldDefinitionMutation, { data, loading, error }] = useUpdateFieldDefinitionMutation({
 *   variables: {
 *      autoPublish: // value for 'autoPublish'
 *      description: // value for 'description'
 *      displayDescription: // value for 'displayDescription'
 *      displayName: // value for 'displayName'
 *      fieldDefinitionTypeInput: // value for 'fieldDefinitionTypeInput'
 *      id: // value for 'id'
 *      indicative: // value for 'indicative'
 *      name: // value for 'name'
 *      templateService: // value for 'templateService'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateFieldDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldDefinitionMutation, UpdateFieldDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldDefinitionMutation, UpdateFieldDefinitionMutationVariables>(UpdateFieldDefinitionDocument, options);
      }
export type UpdateFieldDefinitionMutationHookResult = ReturnType<typeof useUpdateFieldDefinitionMutation>;
export type UpdateFieldDefinitionMutationResult = Apollo.MutationResult<UpdateFieldDefinitionMutation>;
export type UpdateFieldDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateFieldDefinitionMutation, UpdateFieldDefinitionMutationVariables>;
export const DeleteFieldDefinitionsDocument = gql`
    mutation DeleteFieldDefinitions($autoPublish: Boolean!, $ids: [ID]!, $templateService: TemplateService!) {
  deleteFieldDefinitions(
    autoPublish: $autoPublish
    ids: $ids
    templateService: $templateService
  ) {
    errors
    ids
    success
  }
}
    `;
export type DeleteFieldDefinitionsMutationFn = Apollo.MutationFunction<DeleteFieldDefinitionsMutation, DeleteFieldDefinitionsMutationVariables>;

/**
 * __useDeleteFieldDefinitionsMutation__
 *
 * To run a mutation, you first call `useDeleteFieldDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldDefinitionsMutation, { data, loading, error }] = useDeleteFieldDefinitionsMutation({
 *   variables: {
 *      autoPublish: // value for 'autoPublish'
 *      ids: // value for 'ids'
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useDeleteFieldDefinitionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldDefinitionsMutation, DeleteFieldDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldDefinitionsMutation, DeleteFieldDefinitionsMutationVariables>(DeleteFieldDefinitionsDocument, options);
      }
export type DeleteFieldDefinitionsMutationHookResult = ReturnType<typeof useDeleteFieldDefinitionsMutation>;
export type DeleteFieldDefinitionsMutationResult = Apollo.MutationResult<DeleteFieldDefinitionsMutation>;
export type DeleteFieldDefinitionsMutationOptions = Apollo.BaseMutationOptions<DeleteFieldDefinitionsMutation, DeleteFieldDefinitionsMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($fieldDefinitionsBody: String!, $id: ID!, $name: String!, $scope: ScopeType!, $templateService: TemplateService!, $templateType: TemplateType!, $iconType: Int, $selectedTemplateIcon: String, $fileId: String) {
  createTemplate(
    fieldDefinitionsBody: $fieldDefinitionsBody
    id: $id
    name: $name
    scope: $scope
    templateService: $templateService
    templateType: $templateType
    iconType: $iconType
    selectedTemplateIcon: $selectedTemplateIcon
    fileId: $fileId
  ) {
    errors
    id
    success
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      fieldDefinitionsBody: // value for 'fieldDefinitionsBody'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      scope: // value for 'scope'
 *      templateService: // value for 'templateService'
 *      templateType: // value for 'templateType'
 *      iconType: // value for 'iconType'
 *      selectedTemplateIcon: // value for 'selectedTemplateIcon'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const DeleteTemplatesDocument = gql`
    mutation DeleteTemplates($templateService: TemplateService!, $ids: [ID]!) {
  deleteTemplates(templateService: $templateService, ids: $ids)
}
    `;
export type DeleteTemplatesMutationFn = Apollo.MutationFunction<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>;

/**
 * __useDeleteTemplatesMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatesMutation, { data, loading, error }] = useDeleteTemplatesMutation({
 *   variables: {
 *      templateService: // value for 'templateService'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>(DeleteTemplatesDocument, options);
      }
export type DeleteTemplatesMutationHookResult = ReturnType<typeof useDeleteTemplatesMutation>;
export type DeleteTemplatesMutationResult = Apollo.MutationResult<DeleteTemplatesMutation>;
export type DeleteTemplatesMutationOptions = Apollo.BaseMutationOptions<DeleteTemplatesMutation, DeleteTemplatesMutationVariables>;
export const EditTemplateDocument = gql`
    mutation EditTemplate($fieldDefinitionsBody: String!, $id: ID!, $name: String!, $templateService: TemplateService!, $iconType: Int, $selectedTemplateIcon: String, $fileId: String) {
  editTemplate(
    fieldDefinitionsBody: $fieldDefinitionsBody
    id: $id
    name: $name
    templateService: $templateService
    iconType: $iconType
    selectedTemplateIcon: $selectedTemplateIcon
    fileId: $fileId
  ) {
    errors
    id
    success
  }
}
    `;
export type EditTemplateMutationFn = Apollo.MutationFunction<EditTemplateMutation, EditTemplateMutationVariables>;

/**
 * __useEditTemplateMutation__
 *
 * To run a mutation, you first call `useEditTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTemplateMutation, { data, loading, error }] = useEditTemplateMutation({
 *   variables: {
 *      fieldDefinitionsBody: // value for 'fieldDefinitionsBody'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      templateService: // value for 'templateService'
 *      iconType: // value for 'iconType'
 *      selectedTemplateIcon: // value for 'selectedTemplateIcon'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useEditTemplateMutation(baseOptions?: Apollo.MutationHookOptions<EditTemplateMutation, EditTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTemplateMutation, EditTemplateMutationVariables>(EditTemplateDocument, options);
      }
export type EditTemplateMutationHookResult = ReturnType<typeof useEditTemplateMutation>;
export type EditTemplateMutationResult = Apollo.MutationResult<EditTemplateMutation>;
export type EditTemplateMutationOptions = Apollo.BaseMutationOptions<EditTemplateMutation, EditTemplateMutationVariables>;
export const PublishTemplatesDocument = gql`
    mutation PublishTemplates($templateService: TemplateService!, $ids: [ID]!) {
  publishTemplates(templateService: $templateService, ids: $ids)
}
    `;
export type PublishTemplatesMutationFn = Apollo.MutationFunction<PublishTemplatesMutation, PublishTemplatesMutationVariables>;

/**
 * __usePublishTemplatesMutation__
 *
 * To run a mutation, you first call `usePublishTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTemplatesMutation, { data, loading, error }] = usePublishTemplatesMutation({
 *   variables: {
 *      templateService: // value for 'templateService'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePublishTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<PublishTemplatesMutation, PublishTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishTemplatesMutation, PublishTemplatesMutationVariables>(PublishTemplatesDocument, options);
      }
export type PublishTemplatesMutationHookResult = ReturnType<typeof usePublishTemplatesMutation>;
export type PublishTemplatesMutationResult = Apollo.MutationResult<PublishTemplatesMutation>;
export type PublishTemplatesMutationOptions = Apollo.BaseMutationOptions<PublishTemplatesMutation, PublishTemplatesMutationVariables>;
export const SaveInstancesDocument = gql`
    mutation SaveInstances($answers: [InstanceForDocumentInput!]!, $previousInstanceId: String, $projectId: ID, $templateId: ID!) {
  saveInstancesForDocuments(
    projectId: $projectId
    answers: $answers
    previousInstanceId: $previousInstanceId
    templateId: $templateId
  ) {
    id
    instanceId
  }
}
    `;
export type SaveInstancesMutationFn = Apollo.MutationFunction<SaveInstancesMutation, SaveInstancesMutationVariables>;

/**
 * __useSaveInstancesMutation__
 *
 * To run a mutation, you first call `useSaveInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInstancesMutation, { data, loading, error }] = useSaveInstancesMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      previousInstanceId: // value for 'previousInstanceId'
 *      projectId: // value for 'projectId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useSaveInstancesMutation(baseOptions?: Apollo.MutationHookOptions<SaveInstancesMutation, SaveInstancesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInstancesMutation, SaveInstancesMutationVariables>(SaveInstancesDocument, options);
      }
export type SaveInstancesMutationHookResult = ReturnType<typeof useSaveInstancesMutation>;
export type SaveInstancesMutationResult = Apollo.MutationResult<SaveInstancesMutation>;
export type SaveInstancesMutationOptions = Apollo.BaseMutationOptions<SaveInstancesMutation, SaveInstancesMutationVariables>;
export const SaveInstanceForQcDocument = gql`
    mutation SaveInstanceForQc($answers: [InstanceForQcInput!]!, $instanceId: ID!, $templateVersion: Int!) {
  saveInstanceForQc(
    answers: $answers
    instanceId: $instanceId
    templateVersion: $templateVersion
  ) {
    id
    answers {
      fieldId
      value
    }
    isOptimistic
    indicativeState {
      index
      color
      value
    }
  }
}
    `;
export type SaveInstanceForQcMutationFn = Apollo.MutationFunction<SaveInstanceForQcMutation, SaveInstanceForQcMutationVariables>;

/**
 * __useSaveInstanceForQcMutation__
 *
 * To run a mutation, you first call `useSaveInstanceForQcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInstanceForQcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInstanceForQcMutation, { data, loading, error }] = useSaveInstanceForQcMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      instanceId: // value for 'instanceId'
 *      templateVersion: // value for 'templateVersion'
 *   },
 * });
 */
export function useSaveInstanceForQcMutation(baseOptions?: Apollo.MutationHookOptions<SaveInstanceForQcMutation, SaveInstanceForQcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInstanceForQcMutation, SaveInstanceForQcMutationVariables>(SaveInstanceForQcDocument, options);
      }
export type SaveInstanceForQcMutationHookResult = ReturnType<typeof useSaveInstanceForQcMutation>;
export type SaveInstanceForQcMutationResult = Apollo.MutationResult<SaveInstanceForQcMutation>;
export type SaveInstanceForQcMutationOptions = Apollo.BaseMutationOptions<SaveInstanceForQcMutation, SaveInstanceForQcMutationVariables>;
export const UpdateInstanceDocument = gql`
    mutation updateInstance($instanceId: ID!, $visualContextId: String!, $x: String!, $y: String!, $page: Int!) {
  updateInstance(
    instanceId: $instanceId
    visualContextId: $visualContextId
    x: $x
    y: $y
    page: $page
  ) {
    instanceId
  }
}
    `;
export type UpdateInstanceMutationFn = Apollo.MutationFunction<UpdateInstanceMutation, UpdateInstanceMutationVariables>;

/**
 * __useUpdateInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstanceMutation, { data, loading, error }] = useUpdateInstanceMutation({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      visualContextId: // value for 'visualContextId'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUpdateInstanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstanceMutation, UpdateInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstanceMutation, UpdateInstanceMutationVariables>(UpdateInstanceDocument, options);
      }
export type UpdateInstanceMutationHookResult = ReturnType<typeof useUpdateInstanceMutation>;
export type UpdateInstanceMutationResult = Apollo.MutationResult<UpdateInstanceMutation>;
export type UpdateInstanceMutationOptions = Apollo.BaseMutationOptions<UpdateInstanceMutation, UpdateInstanceMutationVariables>;
export const SaveDownloadFileNameConfigDocument = gql`
    mutation saveDownloadFileNameConfig($enabled: Boolean!, $segments: [ConfigDataSegmentInput]!, $templateId: ID!) {
  saveDownloadFileNameConfig(
    enabled: $enabled
    segments: $segments
    templateId: $templateId
  )
}
    `;
export type SaveDownloadFileNameConfigMutationFn = Apollo.MutationFunction<SaveDownloadFileNameConfigMutation, SaveDownloadFileNameConfigMutationVariables>;

/**
 * __useSaveDownloadFileNameConfigMutation__
 *
 * To run a mutation, you first call `useSaveDownloadFileNameConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDownloadFileNameConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDownloadFileNameConfigMutation, { data, loading, error }] = useSaveDownloadFileNameConfigMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *      segments: // value for 'segments'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useSaveDownloadFileNameConfigMutation(baseOptions?: Apollo.MutationHookOptions<SaveDownloadFileNameConfigMutation, SaveDownloadFileNameConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDownloadFileNameConfigMutation, SaveDownloadFileNameConfigMutationVariables>(SaveDownloadFileNameConfigDocument, options);
      }
export type SaveDownloadFileNameConfigMutationHookResult = ReturnType<typeof useSaveDownloadFileNameConfigMutation>;
export type SaveDownloadFileNameConfigMutationResult = Apollo.MutationResult<SaveDownloadFileNameConfigMutation>;
export type SaveDownloadFileNameConfigMutationOptions = Apollo.BaseMutationOptions<SaveDownloadFileNameConfigMutation, SaveDownloadFileNameConfigMutationVariables>;
export const FieldDefinitionTypesDocument = gql`
    query FieldDefinitionTypes($templateService: TemplateService!) {
  fieldDefinitionTypes(templateService: $templateService) {
    id
    label
  }
}
    `;

/**
 * __useFieldDefinitionTypesQuery__
 *
 * To run a query within a React component, call `useFieldDefinitionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDefinitionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDefinitionTypesQuery({
 *   variables: {
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useFieldDefinitionTypesQuery(baseOptions: Apollo.QueryHookOptions<FieldDefinitionTypesQuery, FieldDefinitionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDefinitionTypesQuery, FieldDefinitionTypesQueryVariables>(FieldDefinitionTypesDocument, options);
      }
export function useFieldDefinitionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDefinitionTypesQuery, FieldDefinitionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDefinitionTypesQuery, FieldDefinitionTypesQueryVariables>(FieldDefinitionTypesDocument, options);
        }
export type FieldDefinitionTypesQueryHookResult = ReturnType<typeof useFieldDefinitionTypesQuery>;
export type FieldDefinitionTypesLazyQueryHookResult = ReturnType<typeof useFieldDefinitionTypesLazyQuery>;
export type FieldDefinitionTypesQueryResult = Apollo.QueryResult<FieldDefinitionTypesQuery, FieldDefinitionTypesQueryVariables>;
export const TemplatesIncludeDocument = gql`
    query TemplatesInclude($globalId: ID, $includeOrgItems: Boolean, $projectId: ID, $selectedIds: [ID!], $state: TemplateState, $includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeItems: Boolean = false, $includeScope: Boolean = false, $includeState: Boolean = false, $includeType: Boolean = false, $includeVersion: Boolean = false, $templateService: TemplateService!, $templateType: TemplateType!) {
  templates(
    globalId: $globalId
    includeOrgItems: $includeOrgItems
    projectId: $projectId
    selectedIds: $selectedIds
    state: $state
    templateService: $templateService
    templateType: $templateType
  ) {
    id
    name
    createdAt @include(if: $includeCreatedAt)
    createdBy @include(if: $includeCreatedBy) {
      ...UserFragment
    }
    items @include(if: $includeItems) {
      ...FieldDefinitionPropsFragment
    }
    scope @include(if: $includeScope) {
      id
      type
    }
    state @include(if: $includeState)
    type @include(if: $includeType)
    version @include(if: $includeVersion)
    icon {
      type
      name
      fileId
    }
  }
}
    ${UserFragmentFragmentDoc}
${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useTemplatesIncludeQuery__
 *
 * To run a query within a React component, call `useTemplatesIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesIncludeQuery({
 *   variables: {
 *      globalId: // value for 'globalId'
 *      includeOrgItems: // value for 'includeOrgItems'
 *      projectId: // value for 'projectId'
 *      selectedIds: // value for 'selectedIds'
 *      state: // value for 'state'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeItems: // value for 'includeItems'
 *      includeScope: // value for 'includeScope'
 *      includeState: // value for 'includeState'
 *      includeType: // value for 'includeType'
 *      includeVersion: // value for 'includeVersion'
 *      templateService: // value for 'templateService'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplatesIncludeQuery(baseOptions: Apollo.QueryHookOptions<TemplatesIncludeQuery, TemplatesIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesIncludeQuery, TemplatesIncludeQueryVariables>(TemplatesIncludeDocument, options);
      }
export function useTemplatesIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesIncludeQuery, TemplatesIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesIncludeQuery, TemplatesIncludeQueryVariables>(TemplatesIncludeDocument, options);
        }
export type TemplatesIncludeQueryHookResult = ReturnType<typeof useTemplatesIncludeQuery>;
export type TemplatesIncludeLazyQueryHookResult = ReturnType<typeof useTemplatesIncludeLazyQuery>;
export type TemplatesIncludeQueryResult = Apollo.QueryResult<TemplatesIncludeQuery, TemplatesIncludeQueryVariables>;
export const TemplatesByIdsDocument = gql`
    query TemplatesByIds($ids: [ID!]!, $templateService: TemplateService!) {
  templatesByIds(ids: $ids, templateService: $templateService) {
    id
    items {
      ...FieldDefinitionPropsFragment
    }
    name
    type
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useTemplatesByIdsQuery__
 *
 * To run a query within a React component, call `useTemplatesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useTemplatesByIdsQuery(baseOptions: Apollo.QueryHookOptions<TemplatesByIdsQuery, TemplatesByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesByIdsQuery, TemplatesByIdsQueryVariables>(TemplatesByIdsDocument, options);
      }
export function useTemplatesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesByIdsQuery, TemplatesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesByIdsQuery, TemplatesByIdsQueryVariables>(TemplatesByIdsDocument, options);
        }
export type TemplatesByIdsQueryHookResult = ReturnType<typeof useTemplatesByIdsQuery>;
export type TemplatesByIdsLazyQueryHookResult = ReturnType<typeof useTemplatesByIdsLazyQuery>;
export type TemplatesByIdsQueryResult = Apollo.QueryResult<TemplatesByIdsQuery, TemplatesByIdsQueryVariables>;
export const AllTemplateItemsByScopeDocument = gql`
    query AllTemplateItemsByScope($includeOrgItems: Boolean, $projectId: ID, $state: TemplateState, $templateService: TemplateService!, $templateType: TemplateType!) {
  allTemplateItemsByScope(
    includeOrgItems: $includeOrgItems
    projectId: $projectId
    state: $state
    templateService: $templateService
    templateType: $templateType
  ) {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useAllTemplateItemsByScopeQuery__
 *
 * To run a query within a React component, call `useAllTemplateItemsByScopeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplateItemsByScopeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplateItemsByScopeQuery({
 *   variables: {
 *      includeOrgItems: // value for 'includeOrgItems'
 *      projectId: // value for 'projectId'
 *      state: // value for 'state'
 *      templateService: // value for 'templateService'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useAllTemplateItemsByScopeQuery(baseOptions: Apollo.QueryHookOptions<AllTemplateItemsByScopeQuery, AllTemplateItemsByScopeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTemplateItemsByScopeQuery, AllTemplateItemsByScopeQueryVariables>(AllTemplateItemsByScopeDocument, options);
      }
export function useAllTemplateItemsByScopeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTemplateItemsByScopeQuery, AllTemplateItemsByScopeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTemplateItemsByScopeQuery, AllTemplateItemsByScopeQueryVariables>(AllTemplateItemsByScopeDocument, options);
        }
export type AllTemplateItemsByScopeQueryHookResult = ReturnType<typeof useAllTemplateItemsByScopeQuery>;
export type AllTemplateItemsByScopeLazyQueryHookResult = ReturnType<typeof useAllTemplateItemsByScopeLazyQuery>;
export type AllTemplateItemsByScopeQueryResult = Apollo.QueryResult<AllTemplateItemsByScopeQuery, AllTemplateItemsByScopeQueryVariables>;
export const FieldDefinitionsDocument = gql`
    query FieldDefinitions($includeOrgItems: Boolean, $projectId: ID, $templateService: TemplateService) {
  fieldDefinitions(
    includeOrgItems: $includeOrgItems
    projectId: $projectId
    templateService: $templateService
  ) {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useFieldDefinitionsQuery__
 *
 * To run a query within a React component, call `useFieldDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDefinitionsQuery({
 *   variables: {
 *      includeOrgItems: // value for 'includeOrgItems'
 *      projectId: // value for 'projectId'
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useFieldDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>(FieldDefinitionsDocument, options);
      }
export function useFieldDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>(FieldDefinitionsDocument, options);
        }
export type FieldDefinitionsQueryHookResult = ReturnType<typeof useFieldDefinitionsQuery>;
export type FieldDefinitionsLazyQueryHookResult = ReturnType<typeof useFieldDefinitionsLazyQuery>;
export type FieldDefinitionsQueryResult = Apollo.QueryResult<FieldDefinitionsQuery, FieldDefinitionsQueryVariables>;
export const FieldDefinitionsIncludeDocument = gql`
    query FieldDefinitionsInclude($includeOrgItems: Boolean, $projectId: ID, $templateService: TemplateService, $includeConditions: Boolean = false, $includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeDescription: Boolean = false, $includeDisplayDescription: Boolean = false, $includeDisplayName: Boolean = false, $includeFieldDefinitionId: Boolean = false, $includeIndicative: Boolean = false, $includeLinksAllowed: Boolean = false, $includeParentSectionId: Boolean = false, $includeRequired: Boolean = false, $includeScope: Boolean = false, $includeType: Boolean = false, $includeValue: Boolean = false) {
  fieldDefinitions(
    includeOrgItems: $includeOrgItems
    projectId: $projectId
    templateService: $templateService
  ) {
    id
    name
    conditions @include(if: $includeConditions) {
      fieldId
      value
    }
    createdAt @include(if: $includeCreatedAt)
    createdBy @include(if: $includeCreatedBy) {
      ...UserFragment
    }
    description @include(if: $includeDescription)
    displayDescription @include(if: $includeDisplayDescription)
    displayName @include(if: $includeDisplayName)
    fieldDefinitionId @include(if: $includeFieldDefinitionId)
    indicative @include(if: $includeIndicative)
    linksAllowed @include(if: $includeLinksAllowed)
    parentSectionId @include(if: $includeParentSectionId)
    required @include(if: $includeRequired)
    scope @include(if: $includeScope) {
      id
      type
    }
    type @include(if: $includeType)
    value @include(if: $includeValue)
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useFieldDefinitionsIncludeQuery__
 *
 * To run a query within a React component, call `useFieldDefinitionsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDefinitionsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDefinitionsIncludeQuery({
 *   variables: {
 *      includeOrgItems: // value for 'includeOrgItems'
 *      projectId: // value for 'projectId'
 *      templateService: // value for 'templateService'
 *      includeConditions: // value for 'includeConditions'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeDescription: // value for 'includeDescription'
 *      includeDisplayDescription: // value for 'includeDisplayDescription'
 *      includeDisplayName: // value for 'includeDisplayName'
 *      includeFieldDefinitionId: // value for 'includeFieldDefinitionId'
 *      includeIndicative: // value for 'includeIndicative'
 *      includeLinksAllowed: // value for 'includeLinksAllowed'
 *      includeParentSectionId: // value for 'includeParentSectionId'
 *      includeRequired: // value for 'includeRequired'
 *      includeScope: // value for 'includeScope'
 *      includeType: // value for 'includeType'
 *      includeValue: // value for 'includeValue'
 *   },
 * });
 */
export function useFieldDefinitionsIncludeQuery(baseOptions?: Apollo.QueryHookOptions<FieldDefinitionsIncludeQuery, FieldDefinitionsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDefinitionsIncludeQuery, FieldDefinitionsIncludeQueryVariables>(FieldDefinitionsIncludeDocument, options);
      }
export function useFieldDefinitionsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDefinitionsIncludeQuery, FieldDefinitionsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDefinitionsIncludeQuery, FieldDefinitionsIncludeQueryVariables>(FieldDefinitionsIncludeDocument, options);
        }
export type FieldDefinitionsIncludeQueryHookResult = ReturnType<typeof useFieldDefinitionsIncludeQuery>;
export type FieldDefinitionsIncludeLazyQueryHookResult = ReturnType<typeof useFieldDefinitionsIncludeLazyQuery>;
export type FieldDefinitionsIncludeQueryResult = Apollo.QueryResult<FieldDefinitionsIncludeQuery, FieldDefinitionsIncludeQueryVariables>;
export const StandardFieldDefinitionsDocument = gql`
    query StandardFieldDefinitions {
  standardFieldDefinitions {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useStandardFieldDefinitionsQuery__
 *
 * To run a query within a React component, call `useStandardFieldDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardFieldDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardFieldDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardFieldDefinitionsQuery(baseOptions?: Apollo.QueryHookOptions<StandardFieldDefinitionsQuery, StandardFieldDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardFieldDefinitionsQuery, StandardFieldDefinitionsQueryVariables>(StandardFieldDefinitionsDocument, options);
      }
export function useStandardFieldDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardFieldDefinitionsQuery, StandardFieldDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardFieldDefinitionsQuery, StandardFieldDefinitionsQueryVariables>(StandardFieldDefinitionsDocument, options);
        }
export type StandardFieldDefinitionsQueryHookResult = ReturnType<typeof useStandardFieldDefinitionsQuery>;
export type StandardFieldDefinitionsLazyQueryHookResult = ReturnType<typeof useStandardFieldDefinitionsLazyQuery>;
export type StandardFieldDefinitionsQueryResult = Apollo.QueryResult<StandardFieldDefinitionsQuery, StandardFieldDefinitionsQueryVariables>;
export const TemplateItemsDocument = gql`
    query TemplateItems($id: ID!, $templateService: TemplateService!) {
  templateItems(id: $id, templateService: $templateService) {
    ...FieldDefinitionPropsFragment
  }
}
    ${FieldDefinitionPropsFragmentFragmentDoc}`;

/**
 * __useTemplateItemsQuery__
 *
 * To run a query within a React component, call `useTemplateItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      templateService: // value for 'templateService'
 *   },
 * });
 */
export function useTemplateItemsQuery(baseOptions: Apollo.QueryHookOptions<TemplateItemsQuery, TemplateItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateItemsQuery, TemplateItemsQueryVariables>(TemplateItemsDocument, options);
      }
export function useTemplateItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateItemsQuery, TemplateItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateItemsQuery, TemplateItemsQueryVariables>(TemplateItemsDocument, options);
        }
export type TemplateItemsQueryHookResult = ReturnType<typeof useTemplateItemsQuery>;
export type TemplateItemsLazyQueryHookResult = ReturnType<typeof useTemplateItemsLazyQuery>;
export type TemplateItemsQueryResult = Apollo.QueryResult<TemplateItemsQuery, TemplateItemsQueryVariables>;
export const DownloadFilenameCharactersDocument = gql`
    query DownloadFilenameCharacters {
  downloadFilenameCharacters {
    id
    name
  }
}
    `;

/**
 * __useDownloadFilenameCharactersQuery__
 *
 * To run a query within a React component, call `useDownloadFilenameCharactersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFilenameCharactersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFilenameCharactersQuery({
 *   variables: {
 *   },
 * });
 */
export function useDownloadFilenameCharactersQuery(baseOptions?: Apollo.QueryHookOptions<DownloadFilenameCharactersQuery, DownloadFilenameCharactersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadFilenameCharactersQuery, DownloadFilenameCharactersQueryVariables>(DownloadFilenameCharactersDocument, options);
      }
export function useDownloadFilenameCharactersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadFilenameCharactersQuery, DownloadFilenameCharactersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadFilenameCharactersQuery, DownloadFilenameCharactersQueryVariables>(DownloadFilenameCharactersDocument, options);
        }
export type DownloadFilenameCharactersQueryHookResult = ReturnType<typeof useDownloadFilenameCharactersQuery>;
export type DownloadFilenameCharactersLazyQueryHookResult = ReturnType<typeof useDownloadFilenameCharactersLazyQuery>;
export type DownloadFilenameCharactersQueryResult = Apollo.QueryResult<DownloadFilenameCharactersQuery, DownloadFilenameCharactersQueryVariables>;
export const DownloadFileNameConfigDocument = gql`
    query DownloadFileNameConfig($templateId: ID!) {
  downloadFileNameConfig(templateId: $templateId) {
    segments {
      id
      order
      type
    }
    enabled
  }
}
    `;

/**
 * __useDownloadFileNameConfigQuery__
 *
 * To run a query within a React component, call `useDownloadFileNameConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFileNameConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFileNameConfigQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDownloadFileNameConfigQuery(baseOptions: Apollo.QueryHookOptions<DownloadFileNameConfigQuery, DownloadFileNameConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadFileNameConfigQuery, DownloadFileNameConfigQueryVariables>(DownloadFileNameConfigDocument, options);
      }
export function useDownloadFileNameConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadFileNameConfigQuery, DownloadFileNameConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadFileNameConfigQuery, DownloadFileNameConfigQueryVariables>(DownloadFileNameConfigDocument, options);
        }
export type DownloadFileNameConfigQueryHookResult = ReturnType<typeof useDownloadFileNameConfigQuery>;
export type DownloadFileNameConfigLazyQueryHookResult = ReturnType<typeof useDownloadFileNameConfigLazyQuery>;
export type DownloadFileNameConfigQueryResult = Apollo.QueryResult<DownloadFileNameConfigQuery, DownloadFileNameConfigQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($searchId: String, $isProject: Boolean, $scope: NotificationScope!) {
  getNotifications(searchId: $searchId, isProject: $isProject, scope: $scope) {
    notificationSettingsId
    organizationId
    projectId
    notificationTimes {
      hour
      minute
    }
    notificationDays
    createdAt
    createdBy
    hasUpdatePermission
    updatedAt
    updatedBy
    users {
      id
      userId
      projectId
      enabled
      displayName
      lastUpdatedBy
      lastUpdatedOn
      organization {
        name
        id
      }
      checked
      isAdmin
      email
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      searchId: // value for 'searchId'
 *      isProject: // value for 'isProject'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const ToggleBatchDocumentDigestDocument = gql`
    mutation ToggleBatchDocumentDigest($organizationId: String, $projectId: String, $checked: Boolean, $users: [NotificationUpdateUser], $scope: NotificationScope!) {
  toggleBatchDocumentDigest(
    organizationId: $organizationId
    projectId: $projectId
    checked: $checked
    users: $users
    scope: $scope
  ) {
    documentDigestId
    organizationId
    projectId
    userId
  }
}
    `;
export type ToggleBatchDocumentDigestMutationFn = Apollo.MutationFunction<ToggleBatchDocumentDigestMutation, ToggleBatchDocumentDigestMutationVariables>;

/**
 * __useToggleBatchDocumentDigestMutation__
 *
 * To run a mutation, you first call `useToggleBatchDocumentDigestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBatchDocumentDigestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBatchDocumentDigestMutation, { data, loading, error }] = useToggleBatchDocumentDigestMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      checked: // value for 'checked'
 *      users: // value for 'users'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useToggleBatchDocumentDigestMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBatchDocumentDigestMutation, ToggleBatchDocumentDigestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBatchDocumentDigestMutation, ToggleBatchDocumentDigestMutationVariables>(ToggleBatchDocumentDigestDocument, options);
      }
export type ToggleBatchDocumentDigestMutationHookResult = ReturnType<typeof useToggleBatchDocumentDigestMutation>;
export type ToggleBatchDocumentDigestMutationResult = Apollo.MutationResult<ToggleBatchDocumentDigestMutation>;
export type ToggleBatchDocumentDigestMutationOptions = Apollo.BaseMutationOptions<ToggleBatchDocumentDigestMutation, ToggleBatchDocumentDigestMutationVariables>;
export const ToggleSingleDocumentDigestDocument = gql`
    mutation ToggleSingleDocumentDigest($organizationId: String, $projectId: String, $userId: String, $checked: Boolean, $scope: NotificationScope!) {
  toggleSingleDocumentDigest(
    organizationId: $organizationId
    projectId: $projectId
    userId: $userId
    checked: $checked
    scope: $scope
  ) {
    documentDigestId
    organizationId
    projectId
    userId
  }
}
    `;
export type ToggleSingleDocumentDigestMutationFn = Apollo.MutationFunction<ToggleSingleDocumentDigestMutation, ToggleSingleDocumentDigestMutationVariables>;

/**
 * __useToggleSingleDocumentDigestMutation__
 *
 * To run a mutation, you first call `useToggleSingleDocumentDigestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSingleDocumentDigestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSingleDocumentDigestMutation, { data, loading, error }] = useToggleSingleDocumentDigestMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      checked: // value for 'checked'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useToggleSingleDocumentDigestMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSingleDocumentDigestMutation, ToggleSingleDocumentDigestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSingleDocumentDigestMutation, ToggleSingleDocumentDigestMutationVariables>(ToggleSingleDocumentDigestDocument, options);
      }
export type ToggleSingleDocumentDigestMutationHookResult = ReturnType<typeof useToggleSingleDocumentDigestMutation>;
export type ToggleSingleDocumentDigestMutationResult = Apollo.MutationResult<ToggleSingleDocumentDigestMutation>;
export type ToggleSingleDocumentDigestMutationOptions = Apollo.BaseMutationOptions<ToggleSingleDocumentDigestMutation, ToggleSingleDocumentDigestMutationVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation UpdateNotificationSettings($organizationId: ID, $projectId: ID, $notificationSettingsId: ID, $notificationDays: [String], $notificationTimes: [String], $scope: NotificationScope!) {
  updateNotificationSettings(
    organizationId: $organizationId
    projectId: $projectId
    notificationSettingsId: $notificationSettingsId
    notificationDays: $notificationDays
    notificationTimes: $notificationTimes
    scope: $scope
  ) {
    notificationSettingsId
    organizationId
    projectId
    notificationDays
    notificationTimes {
      hour
      minute
    }
  }
}
    `;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      notificationSettingsId: // value for 'notificationSettingsId'
 *      notificationDays: // value for 'notificationDays'
 *      notificationTimes: // value for 'notificationTimes'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, options);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;
export const ObjectDocument = gql`
    query Object($id: ID) {
  object(id: $id) {
    ...ObjectNodeDetail
  }
}
    ${ObjectNodeDetailFragmentDoc}`;

/**
 * __useObjectQuery__
 *
 * To run a query within a React component, call `useObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useObjectQuery(baseOptions?: Apollo.QueryHookOptions<ObjectQuery, ObjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectQuery, ObjectQueryVariables>(ObjectDocument, options);
      }
export function useObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectQuery, ObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectQuery, ObjectQueryVariables>(ObjectDocument, options);
        }
export type ObjectQueryHookResult = ReturnType<typeof useObjectQuery>;
export type ObjectLazyQueryHookResult = ReturnType<typeof useObjectLazyQuery>;
export type ObjectQueryResult = Apollo.QueryResult<ObjectQuery, ObjectQueryVariables>;
export const ObjectIncludeDocument = gql`
    query ObjectInclude($id: ID!, $includeProjectId: Boolean = false, $includeTasks: Boolean = false, $includeProject: Boolean = false) {
  object(id: $id) {
    ...ObjectNodeDetailInclude
  }
}
    ${ObjectNodeDetailIncludeFragmentDoc}`;

/**
 * __useObjectIncludeQuery__
 *
 * To run a query within a React component, call `useObjectIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectIncludeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeProjectId: // value for 'includeProjectId'
 *      includeTasks: // value for 'includeTasks'
 *      includeProject: // value for 'includeProject'
 *   },
 * });
 */
export function useObjectIncludeQuery(baseOptions: Apollo.QueryHookOptions<ObjectIncludeQuery, ObjectIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectIncludeQuery, ObjectIncludeQueryVariables>(ObjectIncludeDocument, options);
      }
export function useObjectIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectIncludeQuery, ObjectIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectIncludeQuery, ObjectIncludeQueryVariables>(ObjectIncludeDocument, options);
        }
export type ObjectIncludeQueryHookResult = ReturnType<typeof useObjectIncludeQuery>;
export type ObjectIncludeLazyQueryHookResult = ReturnType<typeof useObjectIncludeLazyQuery>;
export type ObjectIncludeQueryResult = Apollo.QueryResult<ObjectIncludeQuery, ObjectIncludeQueryVariables>;
export const ObjectsDocument = gql`
    query Objects($projectId: ID) {
  objects(projectId: $projectId) {
    ...ObjectNodeFragment
  }
}
    ${ObjectNodeFragmentFragmentDoc}`;

/**
 * __useObjectsQuery__
 *
 * To run a query within a React component, call `useObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useObjectsQuery(baseOptions?: Apollo.QueryHookOptions<ObjectsQuery, ObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectsQuery, ObjectsQueryVariables>(ObjectsDocument, options);
      }
export function useObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectsQuery, ObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectsQuery, ObjectsQueryVariables>(ObjectsDocument, options);
        }
export type ObjectsQueryHookResult = ReturnType<typeof useObjectsQuery>;
export type ObjectsLazyQueryHookResult = ReturnType<typeof useObjectsLazyQuery>;
export type ObjectsQueryResult = Apollo.QueryResult<ObjectsQuery, ObjectsQueryVariables>;
export const UpdateNodesDocument = gql`
    mutation UpdateNodes($nodes: [ObjectInput!]) {
  updateObjects(nodes: $nodes) {
    objectIds
    success
    errors
  }
}
    `;
export type UpdateNodesMutationFn = Apollo.MutationFunction<UpdateNodesMutation, UpdateNodesMutationVariables>;

/**
 * __useUpdateNodesMutation__
 *
 * To run a mutation, you first call `useUpdateNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodesMutation, { data, loading, error }] = useUpdateNodesMutation({
 *   variables: {
 *      nodes: // value for 'nodes'
 *   },
 * });
 */
export function useUpdateNodesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNodesMutation, UpdateNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNodesMutation, UpdateNodesMutationVariables>(UpdateNodesDocument, options);
      }
export type UpdateNodesMutationHookResult = ReturnType<typeof useUpdateNodesMutation>;
export type UpdateNodesMutationResult = Apollo.MutationResult<UpdateNodesMutation>;
export type UpdateNodesMutationOptions = Apollo.BaseMutationOptions<UpdateNodesMutation, UpdateNodesMutationVariables>;
export const LinkProjectDocument = gql`
    mutation LinkProject($nodeId: ID!, $projectId: ID!) {
  linkProject(nodeId: $nodeId, projectId: $projectId) {
    projectId
    success
    errors
  }
}
    `;
export type LinkProjectMutationFn = Apollo.MutationFunction<LinkProjectMutation, LinkProjectMutationVariables>;

/**
 * __useLinkProjectMutation__
 *
 * To run a mutation, you first call `useLinkProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkProjectMutation, { data, loading, error }] = useLinkProjectMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLinkProjectMutation(baseOptions?: Apollo.MutationHookOptions<LinkProjectMutation, LinkProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkProjectMutation, LinkProjectMutationVariables>(LinkProjectDocument, options);
      }
export type LinkProjectMutationHookResult = ReturnType<typeof useLinkProjectMutation>;
export type LinkProjectMutationResult = Apollo.MutationResult<LinkProjectMutation>;
export type LinkProjectMutationOptions = Apollo.BaseMutationOptions<LinkProjectMutation, LinkProjectMutationVariables>;
export const UnlinkProjectDocument = gql`
    mutation UnlinkProject($projectId: ID!) {
  unlinkProject(projectId: $projectId) {
    projectId
    success
    errors
  }
}
    `;
export type UnlinkProjectMutationFn = Apollo.MutationFunction<UnlinkProjectMutation, UnlinkProjectMutationVariables>;

/**
 * __useUnlinkProjectMutation__
 *
 * To run a mutation, you first call `useUnlinkProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkProjectMutation, { data, loading, error }] = useUnlinkProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnlinkProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkProjectMutation, UnlinkProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkProjectMutation, UnlinkProjectMutationVariables>(UnlinkProjectDocument, options);
      }
export type UnlinkProjectMutationHookResult = ReturnType<typeof useUnlinkProjectMutation>;
export type UnlinkProjectMutationResult = Apollo.MutationResult<UnlinkProjectMutation>;
export type UnlinkProjectMutationOptions = Apollo.BaseMutationOptions<UnlinkProjectMutation, UnlinkProjectMutationVariables>;
export const GetTbqResultDocument = gql`
    query GetTBQResult($taskId: ID!, $resultSet: ResultSet) {
  getTBQResult(taskId: $taskId, resultSet: $resultSet) {
    id
    answers {
      ...TBQAnswerProps
    }
    categories {
      id
      name
      percentage
    }
    instance {
      ...QualityControlInstanceFragment
    }
    operationGoals {
      id
      name
      percentage
    }
    shortcomings {
      ...TBQAnswerProps
    }
    tbqInstanceLinkedSnags {
      ...FormLinkedSnagsFragment
    }
  }
}
    ${TbqAnswerPropsFragmentDoc}
${QualityControlInstanceFragmentFragmentDoc}
${FormLinkedSnagsFragmentFragmentDoc}`;

/**
 * __useGetTbqResultQuery__
 *
 * To run a query within a React component, call `useGetTbqResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqResultQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      resultSet: // value for 'resultSet'
 *   },
 * });
 */
export function useGetTbqResultQuery(baseOptions: Apollo.QueryHookOptions<GetTbqResultQuery, GetTbqResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqResultQuery, GetTbqResultQueryVariables>(GetTbqResultDocument, options);
      }
export function useGetTbqResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqResultQuery, GetTbqResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqResultQuery, GetTbqResultQueryVariables>(GetTbqResultDocument, options);
        }
export type GetTbqResultQueryHookResult = ReturnType<typeof useGetTbqResultQuery>;
export type GetTbqResultLazyQueryHookResult = ReturnType<typeof useGetTbqResultLazyQuery>;
export type GetTbqResultQueryResult = Apollo.QueryResult<GetTbqResultQuery, GetTbqResultQueryVariables>;
export const GetTbqBrandcheckMultiResultDocument = gql`
    query GetTBQBrandcheckMultiResult($objectId: ID!, $resultSet: ResultSet) {
  getTBQBrandcheckMultiResult(objectId: $objectId, resultSet: $resultSet) {
    id
    objectParentId
    objectName
    scanId
    answers {
      ...TBQAnswerProps
    }
    categories {
      id
      name
      percentage
    }
    operationGoals {
      id
      name
      percentage
    }
  }
}
    ${TbqAnswerPropsFragmentDoc}`;

/**
 * __useGetTbqBrandcheckMultiResultQuery__
 *
 * To run a query within a React component, call `useGetTbqBrandcheckMultiResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqBrandcheckMultiResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqBrandcheckMultiResultQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      resultSet: // value for 'resultSet'
 *   },
 * });
 */
export function useGetTbqBrandcheckMultiResultQuery(baseOptions: Apollo.QueryHookOptions<GetTbqBrandcheckMultiResultQuery, GetTbqBrandcheckMultiResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqBrandcheckMultiResultQuery, GetTbqBrandcheckMultiResultQueryVariables>(GetTbqBrandcheckMultiResultDocument, options);
      }
export function useGetTbqBrandcheckMultiResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqBrandcheckMultiResultQuery, GetTbqBrandcheckMultiResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqBrandcheckMultiResultQuery, GetTbqBrandcheckMultiResultQueryVariables>(GetTbqBrandcheckMultiResultDocument, options);
        }
export type GetTbqBrandcheckMultiResultQueryHookResult = ReturnType<typeof useGetTbqBrandcheckMultiResultQuery>;
export type GetTbqBrandcheckMultiResultLazyQueryHookResult = ReturnType<typeof useGetTbqBrandcheckMultiResultLazyQuery>;
export type GetTbqBrandcheckMultiResultQueryResult = Apollo.QueryResult<GetTbqBrandcheckMultiResultQuery, GetTbqBrandcheckMultiResultQueryVariables>;
export const GetTbqRieResultDocument = gql`
    query GetTBQRieResult($taskId: ID!) {
  getTBQRieResult(taskId: $taskId) {
    ...RieResultFragment
    questionLinkedResolveTask {
      ...TaskFragment
    }
  }
}
    ${RieResultFragmentFragmentDoc}
${TaskFragmentFragmentDoc}`;

/**
 * __useGetTbqRieResultQuery__
 *
 * To run a query within a React component, call `useGetTbqRieResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqRieResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqRieResultQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTbqRieResultQuery(baseOptions: Apollo.QueryHookOptions<GetTbqRieResultQuery, GetTbqRieResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqRieResultQuery, GetTbqRieResultQueryVariables>(GetTbqRieResultDocument, options);
      }
export function useGetTbqRieResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqRieResultQuery, GetTbqRieResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqRieResultQuery, GetTbqRieResultQueryVariables>(GetTbqRieResultDocument, options);
        }
export type GetTbqRieResultQueryHookResult = ReturnType<typeof useGetTbqRieResultQuery>;
export type GetTbqRieResultLazyQueryHookResult = ReturnType<typeof useGetTbqRieResultLazyQuery>;
export type GetTbqRieResultQueryResult = Apollo.QueryResult<GetTbqRieResultQuery, GetTbqRieResultQueryVariables>;
export const GetTbqConfigDocument = gql`
    query GetTbqConfig($tbqTypeId: ID!) {
  getTbqConfig(tbqTypeId: $tbqTypeId) {
    type
    fields {
      id
      name
      options {
        id
        name
        index
      }
    }
  }
}
    `;

/**
 * __useGetTbqConfigQuery__
 *
 * To run a query within a React component, call `useGetTbqConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqConfigQuery({
 *   variables: {
 *      tbqTypeId: // value for 'tbqTypeId'
 *   },
 * });
 */
export function useGetTbqConfigQuery(baseOptions: Apollo.QueryHookOptions<GetTbqConfigQuery, GetTbqConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqConfigQuery, GetTbqConfigQueryVariables>(GetTbqConfigDocument, options);
      }
export function useGetTbqConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqConfigQuery, GetTbqConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqConfigQuery, GetTbqConfigQueryVariables>(GetTbqConfigDocument, options);
        }
export type GetTbqConfigQueryHookResult = ReturnType<typeof useGetTbqConfigQuery>;
export type GetTbqConfigLazyQueryHookResult = ReturnType<typeof useGetTbqConfigLazyQuery>;
export type GetTbqConfigQueryResult = Apollo.QueryResult<GetTbqConfigQuery, GetTbqConfigQueryVariables>;
export const GetTbqGlobalScopeDocument = gql`
    query GetTBQGlobalScope {
  getTBQGlobalScope {
    name
    scopeId
  }
}
    `;

/**
 * __useGetTbqGlobalScopeQuery__
 *
 * To run a query within a React component, call `useGetTbqGlobalScopeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqGlobalScopeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqGlobalScopeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTbqGlobalScopeQuery(baseOptions?: Apollo.QueryHookOptions<GetTbqGlobalScopeQuery, GetTbqGlobalScopeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqGlobalScopeQuery, GetTbqGlobalScopeQueryVariables>(GetTbqGlobalScopeDocument, options);
      }
export function useGetTbqGlobalScopeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqGlobalScopeQuery, GetTbqGlobalScopeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqGlobalScopeQuery, GetTbqGlobalScopeQueryVariables>(GetTbqGlobalScopeDocument, options);
        }
export type GetTbqGlobalScopeQueryHookResult = ReturnType<typeof useGetTbqGlobalScopeQuery>;
export type GetTbqGlobalScopeLazyQueryHookResult = ReturnType<typeof useGetTbqGlobalScopeLazyQuery>;
export type GetTbqGlobalScopeQueryResult = Apollo.QueryResult<GetTbqGlobalScopeQuery, GetTbqGlobalScopeQueryVariables>;
export const GetTbqOsidDevicesDocument = gql`
    query GetTBQOsidDevices {
  getTBQOsidDevices {
    data {
      data
    }
    success
    errors
  }
}
    `;

/**
 * __useGetTbqOsidDevicesQuery__
 *
 * To run a query within a React component, call `useGetTbqOsidDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqOsidDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqOsidDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTbqOsidDevicesQuery(baseOptions?: Apollo.QueryHookOptions<GetTbqOsidDevicesQuery, GetTbqOsidDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqOsidDevicesQuery, GetTbqOsidDevicesQueryVariables>(GetTbqOsidDevicesDocument, options);
      }
export function useGetTbqOsidDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqOsidDevicesQuery, GetTbqOsidDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqOsidDevicesQuery, GetTbqOsidDevicesQueryVariables>(GetTbqOsidDevicesDocument, options);
        }
export type GetTbqOsidDevicesQueryHookResult = ReturnType<typeof useGetTbqOsidDevicesQuery>;
export type GetTbqOsidDevicesLazyQueryHookResult = ReturnType<typeof useGetTbqOsidDevicesLazyQuery>;
export type GetTbqOsidDevicesQueryResult = Apollo.QueryResult<GetTbqOsidDevicesQuery, GetTbqOsidDevicesQueryVariables>;
export const GetTbqOsidDeviceLogsDocument = gql`
    query GetTBQOsidDeviceLogs($deviceCode: String!, $objectId: String!) {
  getTBQOsidDeviceLogs(deviceCode: $deviceCode, objectId: $objectId) {
    ...DeviceLogFragment
  }
}
    ${DeviceLogFragmentFragmentDoc}`;

/**
 * __useGetTbqOsidDeviceLogsQuery__
 *
 * To run a query within a React component, call `useGetTbqOsidDeviceLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqOsidDeviceLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqOsidDeviceLogsQuery({
 *   variables: {
 *      deviceCode: // value for 'deviceCode'
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useGetTbqOsidDeviceLogsQuery(baseOptions: Apollo.QueryHookOptions<GetTbqOsidDeviceLogsQuery, GetTbqOsidDeviceLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqOsidDeviceLogsQuery, GetTbqOsidDeviceLogsQueryVariables>(GetTbqOsidDeviceLogsDocument, options);
      }
export function useGetTbqOsidDeviceLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqOsidDeviceLogsQuery, GetTbqOsidDeviceLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqOsidDeviceLogsQuery, GetTbqOsidDeviceLogsQueryVariables>(GetTbqOsidDeviceLogsDocument, options);
        }
export type GetTbqOsidDeviceLogsQueryHookResult = ReturnType<typeof useGetTbqOsidDeviceLogsQuery>;
export type GetTbqOsidDeviceLogsLazyQueryHookResult = ReturnType<typeof useGetTbqOsidDeviceLogsLazyQuery>;
export type GetTbqOsidDeviceLogsQueryResult = Apollo.QueryResult<GetTbqOsidDeviceLogsQuery, GetTbqOsidDeviceLogsQueryVariables>;
export const GetTbqOsidDeviceLinkStateDocument = gql`
    query GetTBQOsidDeviceLinkState($objectId: ID!) {
  getTBQOsidDeviceLinkState(objectId: $objectId) {
    data
    success
    errors
  }
}
    `;

/**
 * __useGetTbqOsidDeviceLinkStateQuery__
 *
 * To run a query within a React component, call `useGetTbqOsidDeviceLinkStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTbqOsidDeviceLinkStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTbqOsidDeviceLinkStateQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useGetTbqOsidDeviceLinkStateQuery(baseOptions: Apollo.QueryHookOptions<GetTbqOsidDeviceLinkStateQuery, GetTbqOsidDeviceLinkStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTbqOsidDeviceLinkStateQuery, GetTbqOsidDeviceLinkStateQueryVariables>(GetTbqOsidDeviceLinkStateDocument, options);
      }
export function useGetTbqOsidDeviceLinkStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTbqOsidDeviceLinkStateQuery, GetTbqOsidDeviceLinkStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTbqOsidDeviceLinkStateQuery, GetTbqOsidDeviceLinkStateQueryVariables>(GetTbqOsidDeviceLinkStateDocument, options);
        }
export type GetTbqOsidDeviceLinkStateQueryHookResult = ReturnType<typeof useGetTbqOsidDeviceLinkStateQuery>;
export type GetTbqOsidDeviceLinkStateLazyQueryHookResult = ReturnType<typeof useGetTbqOsidDeviceLinkStateLazyQuery>;
export type GetTbqOsidDeviceLinkStateQueryResult = Apollo.QueryResult<GetTbqOsidDeviceLinkStateQuery, GetTbqOsidDeviceLinkStateQueryVariables>;
export const SetTbqConfigDocument = gql`
    mutation SetTbqConfig($nodeId: ID!, $tbqConfig: [TBQSetting!], $tbqFunctionId: ID, $tbqTypeId: ID) {
  setTbqConfig(
    nodeId: $nodeId
    tbqConfig: $tbqConfig
    tbqFunctionId: $tbqFunctionId
    tbqTypeId: $tbqTypeId
  ) {
    nodeId
    success
    errors
  }
}
    `;
export type SetTbqConfigMutationFn = Apollo.MutationFunction<SetTbqConfigMutation, SetTbqConfigMutationVariables>;

/**
 * __useSetTbqConfigMutation__
 *
 * To run a mutation, you first call `useSetTbqConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTbqConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTbqConfigMutation, { data, loading, error }] = useSetTbqConfigMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      tbqConfig: // value for 'tbqConfig'
 *      tbqFunctionId: // value for 'tbqFunctionId'
 *      tbqTypeId: // value for 'tbqTypeId'
 *   },
 * });
 */
export function useSetTbqConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetTbqConfigMutation, SetTbqConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTbqConfigMutation, SetTbqConfigMutationVariables>(SetTbqConfigDocument, options);
      }
export type SetTbqConfigMutationHookResult = ReturnType<typeof useSetTbqConfigMutation>;
export type SetTbqConfigMutationResult = Apollo.MutationResult<SetTbqConfigMutation>;
export type SetTbqConfigMutationOptions = Apollo.BaseMutationOptions<SetTbqConfigMutation, SetTbqConfigMutationVariables>;
export const LinkOsidDeviceDocument = gql`
    mutation LinkOsidDevice($osidLinkInput: LinkDeviceInput!) {
  linkOsidDevice(osidLinkInput: $osidLinkInput) {
    commandId
    success
    errors
  }
}
    `;
export type LinkOsidDeviceMutationFn = Apollo.MutationFunction<LinkOsidDeviceMutation, LinkOsidDeviceMutationVariables>;

/**
 * __useLinkOsidDeviceMutation__
 *
 * To run a mutation, you first call `useLinkOsidDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkOsidDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkOsidDeviceMutation, { data, loading, error }] = useLinkOsidDeviceMutation({
 *   variables: {
 *      osidLinkInput: // value for 'osidLinkInput'
 *   },
 * });
 */
export function useLinkOsidDeviceMutation(baseOptions?: Apollo.MutationHookOptions<LinkOsidDeviceMutation, LinkOsidDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkOsidDeviceMutation, LinkOsidDeviceMutationVariables>(LinkOsidDeviceDocument, options);
      }
export type LinkOsidDeviceMutationHookResult = ReturnType<typeof useLinkOsidDeviceMutation>;
export type LinkOsidDeviceMutationResult = Apollo.MutationResult<LinkOsidDeviceMutation>;
export type LinkOsidDeviceMutationOptions = Apollo.BaseMutationOptions<LinkOsidDeviceMutation, LinkOsidDeviceMutationVariables>;
export const UnlinkOsidDeviceDocument = gql`
    mutation UnlinkOsidDevice($objectId: ID!) {
  unlinkOsidDevice(objectId: $objectId) {
    commandId
    success
    errors
  }
}
    `;
export type UnlinkOsidDeviceMutationFn = Apollo.MutationFunction<UnlinkOsidDeviceMutation, UnlinkOsidDeviceMutationVariables>;

/**
 * __useUnlinkOsidDeviceMutation__
 *
 * To run a mutation, you first call `useUnlinkOsidDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkOsidDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkOsidDeviceMutation, { data, loading, error }] = useUnlinkOsidDeviceMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useUnlinkOsidDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkOsidDeviceMutation, UnlinkOsidDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkOsidDeviceMutation, UnlinkOsidDeviceMutationVariables>(UnlinkOsidDeviceDocument, options);
      }
export type UnlinkOsidDeviceMutationHookResult = ReturnType<typeof useUnlinkOsidDeviceMutation>;
export type UnlinkOsidDeviceMutationResult = Apollo.MutationResult<UnlinkOsidDeviceMutation>;
export type UnlinkOsidDeviceMutationOptions = Apollo.BaseMutationOptions<UnlinkOsidDeviceMutation, UnlinkOsidDeviceMutationVariables>;
export const CreateTbqTaskDocument = gql`
    mutation CreateTbqTask($task: TBQTask!) {
  createTbqTask(task: $task)
}
    `;
export type CreateTbqTaskMutationFn = Apollo.MutationFunction<CreateTbqTaskMutation, CreateTbqTaskMutationVariables>;

/**
 * __useCreateTbqTaskMutation__
 *
 * To run a mutation, you first call `useCreateTbqTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTbqTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTbqTaskMutation, { data, loading, error }] = useCreateTbqTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useCreateTbqTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTbqTaskMutation, CreateTbqTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTbqTaskMutation, CreateTbqTaskMutationVariables>(CreateTbqTaskDocument, options);
      }
export type CreateTbqTaskMutationHookResult = ReturnType<typeof useCreateTbqTaskMutation>;
export type CreateTbqTaskMutationResult = Apollo.MutationResult<CreateTbqTaskMutation>;
export type CreateTbqTaskMutationOptions = Apollo.BaseMutationOptions<CreateTbqTaskMutation, CreateTbqTaskMutationVariables>;
export const MultiRieResultsDocument = gql`
    mutation MultiRieResults($taskIds: [ID!]!) {
  multiRieResults(taskIds: $taskIds) {
    ...RieResultFragment
  }
}
    ${RieResultFragmentFragmentDoc}`;
export type MultiRieResultsMutationFn = Apollo.MutationFunction<MultiRieResultsMutation, MultiRieResultsMutationVariables>;

/**
 * __useMultiRieResultsMutation__
 *
 * To run a mutation, you first call `useMultiRieResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiRieResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiRieResultsMutation, { data, loading, error }] = useMultiRieResultsMutation({
 *   variables: {
 *      taskIds: // value for 'taskIds'
 *   },
 * });
 */
export function useMultiRieResultsMutation(baseOptions?: Apollo.MutationHookOptions<MultiRieResultsMutation, MultiRieResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultiRieResultsMutation, MultiRieResultsMutationVariables>(MultiRieResultsDocument, options);
      }
export type MultiRieResultsMutationHookResult = ReturnType<typeof useMultiRieResultsMutation>;
export type MultiRieResultsMutationResult = Apollo.MutationResult<MultiRieResultsMutation>;
export type MultiRieResultsMutationOptions = Apollo.BaseMutationOptions<MultiRieResultsMutation, MultiRieResultsMutationVariables>;
export const OpenCdeUrlDocument = gql`
    query OpenCdeUrl {
  openCdeUrl
}
    `;

/**
 * __useOpenCdeUrlQuery__
 *
 * To run a query within a React component, call `useOpenCdeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenCdeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenCdeUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenCdeUrlQuery(baseOptions?: Apollo.QueryHookOptions<OpenCdeUrlQuery, OpenCdeUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenCdeUrlQuery, OpenCdeUrlQueryVariables>(OpenCdeUrlDocument, options);
      }
export function useOpenCdeUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenCdeUrlQuery, OpenCdeUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenCdeUrlQuery, OpenCdeUrlQueryVariables>(OpenCdeUrlDocument, options);
        }
export type OpenCdeUrlQueryHookResult = ReturnType<typeof useOpenCdeUrlQuery>;
export type OpenCdeUrlLazyQueryHookResult = ReturnType<typeof useOpenCdeUrlLazyQuery>;
export type OpenCdeUrlQueryResult = Apollo.QueryResult<OpenCdeUrlQuery, OpenCdeUrlQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($admins: [MemberInput], $deadline: DateTime, $description: String, $members: [MemberInput], $name: String!, $objectId: ID, $projectNumber: String!, $startDate: DateTime) {
  createProject(
    admins: $admins
    deadline: $deadline
    description: $description
    members: $members
    name: $name
    objectId: $objectId
    projectNumber: $projectNumber
    startDate: $startDate
  )
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      admins: // value for 'admins'
 *      deadline: // value for 'deadline'
 *      description: // value for 'description'
 *      members: // value for 'members'
 *      name: // value for 'name'
 *      objectId: // value for 'objectId'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: ID!, $description: String, $name: String!, $projectNumber: String!, $startDate: DateTime, $deadline: DateTime, $settings: ProjectSettingsInput) {
  updateProject(
    id: $id
    description: $description
    name: $name
    projectNumber: $projectNumber
    startDate: $startDate
    deadline: $deadline
    settings: $settings
  )
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *      deadline: // value for 'deadline'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const UpdateProjectSettingsDocument = gql`
    mutation UpdateProjectSettings($id: ID!, $settings: ProjectSettingsInput) {
  updateProjectSettings(id: $id, settings: $settings)
}
    `;
export type UpdateProjectSettingsMutationFn = Apollo.MutationFunction<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>;

/**
 * __useUpdateProjectSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSettingsMutation, { data, loading, error }] = useUpdateProjectSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateProjectSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>(UpdateProjectSettingsDocument, options);
      }
export type UpdateProjectSettingsMutationHookResult = ReturnType<typeof useUpdateProjectSettingsMutation>;
export type UpdateProjectSettingsMutationResult = Apollo.MutationResult<UpdateProjectSettingsMutation>;
export type UpdateProjectSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>;
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($sourceProjectId: ID!, $description: String, $name: String!, $objectId: ID, $projectNumber: String!, $startDate: DateTime, $deadline: DateTime) {
  duplicateProject(
    sourceProjectId: $sourceProjectId
    description: $description
    name: $name
    objectId: $objectId
    projectNumber: $projectNumber
    startDate: $startDate
    deadline: $deadline
  )
}
    `;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<DuplicateProjectMutation, DuplicateProjectMutationVariables>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      sourceProjectId: // value for 'sourceProjectId'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      objectId: // value for 'objectId'
 *      projectNumber: // value for 'projectNumber'
 *      startDate: // value for 'startDate'
 *      deadline: // value for 'deadline'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument, options);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export const DeleteProjectsDocument = gql`
    mutation DeleteProjects($projectIds: [ID!]!) {
  deleteProjects(projectIds: $projectIds) {
    succeededIds
    errors
    failedIds
    success
  }
}
    `;
export type DeleteProjectsMutationFn = Apollo.MutationFunction<DeleteProjectsMutation, DeleteProjectsMutationVariables>;

/**
 * __useDeleteProjectsMutation__
 *
 * To run a mutation, you first call `useDeleteProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectsMutation, { data, loading, error }] = useDeleteProjectsMutation({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useDeleteProjectsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectsMutation, DeleteProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectsMutation, DeleteProjectsMutationVariables>(DeleteProjectsDocument, options);
      }
export type DeleteProjectsMutationHookResult = ReturnType<typeof useDeleteProjectsMutation>;
export type DeleteProjectsMutationResult = Apollo.MutationResult<DeleteProjectsMutation>;
export type DeleteProjectsMutationOptions = Apollo.BaseMutationOptions<DeleteProjectsMutation, DeleteProjectsMutationVariables>;
export const AddProjectMembersDocument = gql`
    mutation AddProjectMembers($projectId: ID!, $admins: [MemberInput], $users: [MemberInput], $message: String) {
  addProjectMembers(
    projectId: $projectId
    admins: $admins
    users: $users
    message: $message
  )
}
    `;
export type AddProjectMembersMutationFn = Apollo.MutationFunction<AddProjectMembersMutation, AddProjectMembersMutationVariables>;

/**
 * __useAddProjectMembersMutation__
 *
 * To run a mutation, you first call `useAddProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMembersMutation, { data, loading, error }] = useAddProjectMembersMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      admins: // value for 'admins'
 *      users: // value for 'users'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddProjectMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectMembersMutation, AddProjectMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectMembersMutation, AddProjectMembersMutationVariables>(AddProjectMembersDocument, options);
      }
export type AddProjectMembersMutationHookResult = ReturnType<typeof useAddProjectMembersMutation>;
export type AddProjectMembersMutationResult = Apollo.MutationResult<AddProjectMembersMutation>;
export type AddProjectMembersMutationOptions = Apollo.BaseMutationOptions<AddProjectMembersMutation, AddProjectMembersMutationVariables>;
export const SetProjectRoleForMembersDocument = gql`
    mutation SetProjectRoleForMembers($members: [MemberInput]!, $projectId: ID!, $roleId: ID!) {
  setProjectRoleForMembers(
    members: $members
    projectId: $projectId
    roleId: $roleId
  )
}
    `;
export type SetProjectRoleForMembersMutationFn = Apollo.MutationFunction<SetProjectRoleForMembersMutation, SetProjectRoleForMembersMutationVariables>;

/**
 * __useSetProjectRoleForMembersMutation__
 *
 * To run a mutation, you first call `useSetProjectRoleForMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectRoleForMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectRoleForMembersMutation, { data, loading, error }] = useSetProjectRoleForMembersMutation({
 *   variables: {
 *      members: // value for 'members'
 *      projectId: // value for 'projectId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useSetProjectRoleForMembersMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectRoleForMembersMutation, SetProjectRoleForMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectRoleForMembersMutation, SetProjectRoleForMembersMutationVariables>(SetProjectRoleForMembersDocument, options);
      }
export type SetProjectRoleForMembersMutationHookResult = ReturnType<typeof useSetProjectRoleForMembersMutation>;
export type SetProjectRoleForMembersMutationResult = Apollo.MutationResult<SetProjectRoleForMembersMutation>;
export type SetProjectRoleForMembersMutationOptions = Apollo.BaseMutationOptions<SetProjectRoleForMembersMutation, SetProjectRoleForMembersMutationVariables>;
export const AddUsersToProjectsDocument = gql`
    mutation AddUsersToProjects($projectIds: [ID]!, $userIds: [ID]!) {
  addUsersToProjects(projectIds: $projectIds, userIds: $userIds)
}
    `;
export type AddUsersToProjectsMutationFn = Apollo.MutationFunction<AddUsersToProjectsMutation, AddUsersToProjectsMutationVariables>;

/**
 * __useAddUsersToProjectsMutation__
 *
 * To run a mutation, you first call `useAddUsersToProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToProjectsMutation, { data, loading, error }] = useAddUsersToProjectsMutation({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAddUsersToProjectsMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToProjectsMutation, AddUsersToProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToProjectsMutation, AddUsersToProjectsMutationVariables>(AddUsersToProjectsDocument, options);
      }
export type AddUsersToProjectsMutationHookResult = ReturnType<typeof useAddUsersToProjectsMutation>;
export type AddUsersToProjectsMutationResult = Apollo.MutationResult<AddUsersToProjectsMutation>;
export type AddUsersToProjectsMutationOptions = Apollo.BaseMutationOptions<AddUsersToProjectsMutation, AddUsersToProjectsMutationVariables>;
export const DeleteMembersFromProjectDocument = gql`
    mutation DeleteMembersFromProject($members: [MemberInput]!, $projectId: ID!) {
  deleteMembersFromProject(members: $members, projectId: $projectId)
}
    `;
export type DeleteMembersFromProjectMutationFn = Apollo.MutationFunction<DeleteMembersFromProjectMutation, DeleteMembersFromProjectMutationVariables>;

/**
 * __useDeleteMembersFromProjectMutation__
 *
 * To run a mutation, you first call `useDeleteMembersFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMembersFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMembersFromProjectMutation, { data, loading, error }] = useDeleteMembersFromProjectMutation({
 *   variables: {
 *      members: // value for 'members'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteMembersFromProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembersFromProjectMutation, DeleteMembersFromProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembersFromProjectMutation, DeleteMembersFromProjectMutationVariables>(DeleteMembersFromProjectDocument, options);
      }
export type DeleteMembersFromProjectMutationHookResult = ReturnType<typeof useDeleteMembersFromProjectMutation>;
export type DeleteMembersFromProjectMutationResult = Apollo.MutationResult<DeleteMembersFromProjectMutation>;
export type DeleteMembersFromProjectMutationOptions = Apollo.BaseMutationOptions<DeleteMembersFromProjectMutation, DeleteMembersFromProjectMutationVariables>;
export const AddExternalUsersDocument = gql`
    mutation AddExternalUsers($projectId: ID!, $roleId: ID!, $emails: [String!]!, $message: String) {
  addExternalUsers(
    projectId: $projectId
    roleId: $roleId
    emails: $emails
    message: $message
  ) {
    projectId
    success
    errors
  }
}
    `;
export type AddExternalUsersMutationFn = Apollo.MutationFunction<AddExternalUsersMutation, AddExternalUsersMutationVariables>;

/**
 * __useAddExternalUsersMutation__
 *
 * To run a mutation, you first call `useAddExternalUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalUsersMutation, { data, loading, error }] = useAddExternalUsersMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      roleId: // value for 'roleId'
 *      emails: // value for 'emails'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddExternalUsersMutation(baseOptions?: Apollo.MutationHookOptions<AddExternalUsersMutation, AddExternalUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExternalUsersMutation, AddExternalUsersMutationVariables>(AddExternalUsersDocument, options);
      }
export type AddExternalUsersMutationHookResult = ReturnType<typeof useAddExternalUsersMutation>;
export type AddExternalUsersMutationResult = Apollo.MutationResult<AddExternalUsersMutation>;
export type AddExternalUsersMutationOptions = Apollo.BaseMutationOptions<AddExternalUsersMutation, AddExternalUsersMutationVariables>;
export const MyProjectsDocument = gql`
    query MyProjects {
  projects: myProjects {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;

/**
 * __useMyProjectsQuery__
 *
 * To run a query within a React component, call `useMyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProjectsQuery(baseOptions?: Apollo.QueryHookOptions<MyProjectsQuery, MyProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProjectsQuery, MyProjectsQueryVariables>(MyProjectsDocument, options);
      }
export function useMyProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProjectsQuery, MyProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProjectsQuery, MyProjectsQueryVariables>(MyProjectsDocument, options);
        }
export type MyProjectsQueryHookResult = ReturnType<typeof useMyProjectsQuery>;
export type MyProjectsLazyQueryHookResult = ReturnType<typeof useMyProjectsLazyQuery>;
export type MyProjectsQueryResult = Apollo.QueryResult<MyProjectsQuery, MyProjectsQueryVariables>;
export const MyProjectRolesDocument = gql`
    query MyProjectRoles($projectId: ID!) {
  myProjectRoles(projectId: $projectId) {
    id
    name
    permissions
  }
}
    `;

/**
 * __useMyProjectRolesQuery__
 *
 * To run a query within a React component, call `useMyProjectRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProjectRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProjectRolesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMyProjectRolesQuery(baseOptions: Apollo.QueryHookOptions<MyProjectRolesQuery, MyProjectRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProjectRolesQuery, MyProjectRolesQueryVariables>(MyProjectRolesDocument, options);
      }
export function useMyProjectRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProjectRolesQuery, MyProjectRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProjectRolesQuery, MyProjectRolesQueryVariables>(MyProjectRolesDocument, options);
        }
export type MyProjectRolesQueryHookResult = ReturnType<typeof useMyProjectRolesQuery>;
export type MyProjectRolesLazyQueryHookResult = ReturnType<typeof useMyProjectRolesLazyQuery>;
export type MyProjectRolesQueryResult = Apollo.QueryResult<MyProjectRolesQuery, MyProjectRolesQueryVariables>;
export const MyOrganizationProjectsDocument = gql`
    query MyOrganizationProjects {
  projects: myOrganizationProjects {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;

/**
 * __useMyOrganizationProjectsQuery__
 *
 * To run a query within a React component, call `useMyOrganizationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganizationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganizationProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrganizationProjectsQuery(baseOptions?: Apollo.QueryHookOptions<MyOrganizationProjectsQuery, MyOrganizationProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyOrganizationProjectsQuery, MyOrganizationProjectsQueryVariables>(MyOrganizationProjectsDocument, options);
      }
export function useMyOrganizationProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyOrganizationProjectsQuery, MyOrganizationProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyOrganizationProjectsQuery, MyOrganizationProjectsQueryVariables>(MyOrganizationProjectsDocument, options);
        }
export type MyOrganizationProjectsQueryHookResult = ReturnType<typeof useMyOrganizationProjectsQuery>;
export type MyOrganizationProjectsLazyQueryHookResult = ReturnType<typeof useMyOrganizationProjectsLazyQuery>;
export type MyOrganizationProjectsQueryResult = Apollo.QueryResult<MyOrganizationProjectsQuery, MyOrganizationProjectsQueryVariables>;
export const ProjectDocument = gql`
    query Project($projectId: ID!) {
  project(id: $projectId) {
    ...ProjectDetailFragment
  }
}
    ${ProjectDetailFragmentFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($projectIds: [ID]!) {
  projects(ids: $projectIds) {
    availableRoles {
      id
      name
    }
    members {
      ... on Group {
        ...GroupFragment
      }
      ... on User {
        ...UserFragment
      }
    }
    ...ProjectFragment
  }
}
    ${GroupFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${ProjectFragmentFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectRolesDocument = gql`
    query ProjectRoles($id: ID!) {
  project(id: $id) {
    id
    availableRoles {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectRolesQuery__
 *
 * To run a query within a React component, call `useProjectRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectRolesQuery(baseOptions: Apollo.QueryHookOptions<ProjectRolesQuery, ProjectRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRolesQuery, ProjectRolesQueryVariables>(ProjectRolesDocument, options);
      }
export function useProjectRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRolesQuery, ProjectRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRolesQuery, ProjectRolesQueryVariables>(ProjectRolesDocument, options);
        }
export type ProjectRolesQueryHookResult = ReturnType<typeof useProjectRolesQuery>;
export type ProjectRolesLazyQueryHookResult = ReturnType<typeof useProjectRolesLazyQuery>;
export type ProjectRolesQueryResult = Apollo.QueryResult<ProjectRolesQuery, ProjectRolesQueryVariables>;
export const CreateVisualContextFromDocumentDocument = gql`
    mutation CreateVisualContextFromDocument($documentId: ID!) {
  createVisualContextFromDocument(documentId: $documentId) {
    visualContextId
    success
    errors
  }
}
    `;
export type CreateVisualContextFromDocumentMutationFn = Apollo.MutationFunction<CreateVisualContextFromDocumentMutation, CreateVisualContextFromDocumentMutationVariables>;

/**
 * __useCreateVisualContextFromDocumentMutation__
 *
 * To run a mutation, you first call `useCreateVisualContextFromDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisualContextFromDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisualContextFromDocumentMutation, { data, loading, error }] = useCreateVisualContextFromDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useCreateVisualContextFromDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateVisualContextFromDocumentMutation, CreateVisualContextFromDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVisualContextFromDocumentMutation, CreateVisualContextFromDocumentMutationVariables>(CreateVisualContextFromDocumentDocument, options);
      }
export type CreateVisualContextFromDocumentMutationHookResult = ReturnType<typeof useCreateVisualContextFromDocumentMutation>;
export type CreateVisualContextFromDocumentMutationResult = Apollo.MutationResult<CreateVisualContextFromDocumentMutation>;
export type CreateVisualContextFromDocumentMutationOptions = Apollo.BaseMutationOptions<CreateVisualContextFromDocumentMutation, CreateVisualContextFromDocumentMutationVariables>;
export const CreateQualityControlInstanceDocument = gql`
    mutation CreateQualityControlInstance($projectId: ID!, $resourceType: InstanceResourceType, $resourceId: ID, $templateId: ID!, $visualContext: VisualContextInput, $instanceId: ID, $linkToTaskId: ID) {
  createQualityControlInstance(
    projectId: $projectId
    resourceType: $resourceType
    resourceId: $resourceId
    templateId: $templateId
    visualContext: $visualContext
    instanceId: $instanceId
    linkToTaskId: $linkToTaskId
  ) {
    instanceId
    success
    errors
  }
}
    `;
export type CreateQualityControlInstanceMutationFn = Apollo.MutationFunction<CreateQualityControlInstanceMutation, CreateQualityControlInstanceMutationVariables>;

/**
 * __useCreateQualityControlInstanceMutation__
 *
 * To run a mutation, you first call `useCreateQualityControlInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQualityControlInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQualityControlInstanceMutation, { data, loading, error }] = useCreateQualityControlInstanceMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      resourceType: // value for 'resourceType'
 *      resourceId: // value for 'resourceId'
 *      templateId: // value for 'templateId'
 *      visualContext: // value for 'visualContext'
 *      instanceId: // value for 'instanceId'
 *      linkToTaskId: // value for 'linkToTaskId'
 *   },
 * });
 */
export function useCreateQualityControlInstanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateQualityControlInstanceMutation, CreateQualityControlInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQualityControlInstanceMutation, CreateQualityControlInstanceMutationVariables>(CreateQualityControlInstanceDocument, options);
      }
export type CreateQualityControlInstanceMutationHookResult = ReturnType<typeof useCreateQualityControlInstanceMutation>;
export type CreateQualityControlInstanceMutationResult = Apollo.MutationResult<CreateQualityControlInstanceMutation>;
export type CreateQualityControlInstanceMutationOptions = Apollo.BaseMutationOptions<CreateQualityControlInstanceMutation, CreateQualityControlInstanceMutationVariables>;
export const DeleteVisualContextsDocument = gql`
    mutation DeleteVisualContexts($visualContextIds: [ID!], $includeSnagsAndForms: Boolean, $projectId: ID) {
  deleteVisualContexts(
    visualContextIds: $visualContextIds
    includeSnagsAndForms: $includeSnagsAndForms
    projectId: $projectId
  ) {
    success
    errors
  }
}
    `;
export type DeleteVisualContextsMutationFn = Apollo.MutationFunction<DeleteVisualContextsMutation, DeleteVisualContextsMutationVariables>;

/**
 * __useDeleteVisualContextsMutation__
 *
 * To run a mutation, you first call `useDeleteVisualContextsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisualContextsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisualContextsMutation, { data, loading, error }] = useDeleteVisualContextsMutation({
 *   variables: {
 *      visualContextIds: // value for 'visualContextIds'
 *      includeSnagsAndForms: // value for 'includeSnagsAndForms'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteVisualContextsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVisualContextsMutation, DeleteVisualContextsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVisualContextsMutation, DeleteVisualContextsMutationVariables>(DeleteVisualContextsDocument, options);
      }
export type DeleteVisualContextsMutationHookResult = ReturnType<typeof useDeleteVisualContextsMutation>;
export type DeleteVisualContextsMutationResult = Apollo.MutationResult<DeleteVisualContextsMutation>;
export type DeleteVisualContextsMutationOptions = Apollo.BaseMutationOptions<DeleteVisualContextsMutation, DeleteVisualContextsMutationVariables>;
export const RestoreVisualContextsDocument = gql`
    mutation RestoreVisualContexts($visualContextIds: [ID!], $includeSnagsAndForms: Boolean, $projectId: ID) {
  restoreVisualContexts(
    visualContextIds: $visualContextIds
    includeSnagsAndForms: $includeSnagsAndForms
    projectId: $projectId
  ) {
    success
    errors
  }
}
    `;
export type RestoreVisualContextsMutationFn = Apollo.MutationFunction<RestoreVisualContextsMutation, RestoreVisualContextsMutationVariables>;

/**
 * __useRestoreVisualContextsMutation__
 *
 * To run a mutation, you first call `useRestoreVisualContextsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreVisualContextsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreVisualContextsMutation, { data, loading, error }] = useRestoreVisualContextsMutation({
 *   variables: {
 *      visualContextIds: // value for 'visualContextIds'
 *      includeSnagsAndForms: // value for 'includeSnagsAndForms'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRestoreVisualContextsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreVisualContextsMutation, RestoreVisualContextsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreVisualContextsMutation, RestoreVisualContextsMutationVariables>(RestoreVisualContextsDocument, options);
      }
export type RestoreVisualContextsMutationHookResult = ReturnType<typeof useRestoreVisualContextsMutation>;
export type RestoreVisualContextsMutationResult = Apollo.MutationResult<RestoreVisualContextsMutation>;
export type RestoreVisualContextsMutationOptions = Apollo.BaseMutationOptions<RestoreVisualContextsMutation, RestoreVisualContextsMutationVariables>;
export const AddSnagToFormLinkDocument = gql`
    mutation AddSnagToFormLink($fieldId: ID!, $formInstanceId: ID!, $snagInstanceId: ID!) {
  addSnagToFormLink(
    fieldId: $fieldId
    formInstanceId: $formInstanceId
    snagInstanceId: $snagInstanceId
  ) {
    instanceId
    success
    errors
  }
}
    `;
export type AddSnagToFormLinkMutationFn = Apollo.MutationFunction<AddSnagToFormLinkMutation, AddSnagToFormLinkMutationVariables>;

/**
 * __useAddSnagToFormLinkMutation__
 *
 * To run a mutation, you first call `useAddSnagToFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSnagToFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSnagToFormLinkMutation, { data, loading, error }] = useAddSnagToFormLinkMutation({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      formInstanceId: // value for 'formInstanceId'
 *      snagInstanceId: // value for 'snagInstanceId'
 *   },
 * });
 */
export function useAddSnagToFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddSnagToFormLinkMutation, AddSnagToFormLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSnagToFormLinkMutation, AddSnagToFormLinkMutationVariables>(AddSnagToFormLinkDocument, options);
      }
export type AddSnagToFormLinkMutationHookResult = ReturnType<typeof useAddSnagToFormLinkMutation>;
export type AddSnagToFormLinkMutationResult = Apollo.MutationResult<AddSnagToFormLinkMutation>;
export type AddSnagToFormLinkMutationOptions = Apollo.BaseMutationOptions<AddSnagToFormLinkMutation, AddSnagToFormLinkMutationVariables>;
export const UnlinkLinkDocument = gql`
    mutation UnlinkLink($linkId: ID!) {
  unlinkLink(linkId: $linkId) {
    instanceId
    success
    errors
  }
}
    `;
export type UnlinkLinkMutationFn = Apollo.MutationFunction<UnlinkLinkMutation, UnlinkLinkMutationVariables>;

/**
 * __useUnlinkLinkMutation__
 *
 * To run a mutation, you first call `useUnlinkLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkLinkMutation, { data, loading, error }] = useUnlinkLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useUnlinkLinkMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkLinkMutation, UnlinkLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkLinkMutation, UnlinkLinkMutationVariables>(UnlinkLinkDocument, options);
      }
export type UnlinkLinkMutationHookResult = ReturnType<typeof useUnlinkLinkMutation>;
export type UnlinkLinkMutationResult = Apollo.MutationResult<UnlinkLinkMutation>;
export type UnlinkLinkMutationOptions = Apollo.BaseMutationOptions<UnlinkLinkMutation, UnlinkLinkMutationVariables>;
export const AddSavedAnswersDocument = gql`
    mutation AddSavedAnswers($answers: [Answer!], $name: String!, $projectId: ID, $templateId: ID!) {
  addSavedAnswers(
    answers: $answers
    name: $name
    projectId: $projectId
    templateId: $templateId
  )
}
    `;
export type AddSavedAnswersMutationFn = Apollo.MutationFunction<AddSavedAnswersMutation, AddSavedAnswersMutationVariables>;

/**
 * __useAddSavedAnswersMutation__
 *
 * To run a mutation, you first call `useAddSavedAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSavedAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSavedAnswersMutation, { data, loading, error }] = useAddSavedAnswersMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useAddSavedAnswersMutation(baseOptions?: Apollo.MutationHookOptions<AddSavedAnswersMutation, AddSavedAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSavedAnswersMutation, AddSavedAnswersMutationVariables>(AddSavedAnswersDocument, options);
      }
export type AddSavedAnswersMutationHookResult = ReturnType<typeof useAddSavedAnswersMutation>;
export type AddSavedAnswersMutationResult = Apollo.MutationResult<AddSavedAnswersMutation>;
export type AddSavedAnswersMutationOptions = Apollo.BaseMutationOptions<AddSavedAnswersMutation, AddSavedAnswersMutationVariables>;
export const DeleteSavedAnswersDocument = gql`
    mutation DeleteSavedAnswers($ids: [ID!]!) {
  deleteSavedAnswers(ids: $ids)
}
    `;
export type DeleteSavedAnswersMutationFn = Apollo.MutationFunction<DeleteSavedAnswersMutation, DeleteSavedAnswersMutationVariables>;

/**
 * __useDeleteSavedAnswersMutation__
 *
 * To run a mutation, you first call `useDeleteSavedAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedAnswersMutation, { data, loading, error }] = useDeleteSavedAnswersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSavedAnswersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedAnswersMutation, DeleteSavedAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSavedAnswersMutation, DeleteSavedAnswersMutationVariables>(DeleteSavedAnswersDocument, options);
      }
export type DeleteSavedAnswersMutationHookResult = ReturnType<typeof useDeleteSavedAnswersMutation>;
export type DeleteSavedAnswersMutationResult = Apollo.MutationResult<DeleteSavedAnswersMutation>;
export type DeleteSavedAnswersMutationOptions = Apollo.BaseMutationOptions<DeleteSavedAnswersMutation, DeleteSavedAnswersMutationVariables>;
export const RestoreSavedAnswersDocument = gql`
    mutation RestoreSavedAnswers($ids: [ID!]!) {
  restoreSavedAnswers(ids: $ids)
}
    `;
export type RestoreSavedAnswersMutationFn = Apollo.MutationFunction<RestoreSavedAnswersMutation, RestoreSavedAnswersMutationVariables>;

/**
 * __useRestoreSavedAnswersMutation__
 *
 * To run a mutation, you first call `useRestoreSavedAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSavedAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSavedAnswersMutation, { data, loading, error }] = useRestoreSavedAnswersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreSavedAnswersMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSavedAnswersMutation, RestoreSavedAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreSavedAnswersMutation, RestoreSavedAnswersMutationVariables>(RestoreSavedAnswersDocument, options);
      }
export type RestoreSavedAnswersMutationHookResult = ReturnType<typeof useRestoreSavedAnswersMutation>;
export type RestoreSavedAnswersMutationResult = Apollo.MutationResult<RestoreSavedAnswersMutation>;
export type RestoreSavedAnswersMutationOptions = Apollo.BaseMutationOptions<RestoreSavedAnswersMutation, RestoreSavedAnswersMutationVariables>;
export const DeleteInstanceDocument = gql`
    mutation DeleteInstance($ids: [ID!]!) {
  deleteInstance(ids: $ids) {
    success
    errors
    instanceId
  }
}
    `;
export type DeleteInstanceMutationFn = Apollo.MutationFunction<DeleteInstanceMutation, DeleteInstanceMutationVariables>;

/**
 * __useDeleteInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstanceMutation, { data, loading, error }] = useDeleteInstanceMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteInstanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstanceMutation, DeleteInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstanceMutation, DeleteInstanceMutationVariables>(DeleteInstanceDocument, options);
      }
export type DeleteInstanceMutationHookResult = ReturnType<typeof useDeleteInstanceMutation>;
export type DeleteInstanceMutationResult = Apollo.MutationResult<DeleteInstanceMutation>;
export type DeleteInstanceMutationOptions = Apollo.BaseMutationOptions<DeleteInstanceMutation, DeleteInstanceMutationVariables>;
export const RestoreInstanceDocument = gql`
    mutation RestoreInstance($ids: [ID]) {
  restoreInstance(ids: $ids) {
    data {
      instanceId
    }
    success
  }
}
    `;
export type RestoreInstanceMutationFn = Apollo.MutationFunction<RestoreInstanceMutation, RestoreInstanceMutationVariables>;

/**
 * __useRestoreInstanceMutation__
 *
 * To run a mutation, you first call `useRestoreInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreInstanceMutation, { data, loading, error }] = useRestoreInstanceMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreInstanceMutation(baseOptions?: Apollo.MutationHookOptions<RestoreInstanceMutation, RestoreInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreInstanceMutation, RestoreInstanceMutationVariables>(RestoreInstanceDocument, options);
      }
export type RestoreInstanceMutationHookResult = ReturnType<typeof useRestoreInstanceMutation>;
export type RestoreInstanceMutationResult = Apollo.MutationResult<RestoreInstanceMutation>;
export type RestoreInstanceMutationOptions = Apollo.BaseMutationOptions<RestoreInstanceMutation, RestoreInstanceMutationVariables>;
export const UpdateSavedAnswersDocument = gql`
    mutation UpdateSavedAnswers($answers: [Answer!], $name: String, $id: ID!) {
  updateSavedAnswers(answers: $answers, id: $id, name: $name)
}
    `;
export type UpdateSavedAnswersMutationFn = Apollo.MutationFunction<UpdateSavedAnswersMutation, UpdateSavedAnswersMutationVariables>;

/**
 * __useUpdateSavedAnswersMutation__
 *
 * To run a mutation, you first call `useUpdateSavedAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedAnswersMutation, { data, loading, error }] = useUpdateSavedAnswersMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSavedAnswersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSavedAnswersMutation, UpdateSavedAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSavedAnswersMutation, UpdateSavedAnswersMutationVariables>(UpdateSavedAnswersDocument, options);
      }
export type UpdateSavedAnswersMutationHookResult = ReturnType<typeof useUpdateSavedAnswersMutation>;
export type UpdateSavedAnswersMutationResult = Apollo.MutationResult<UpdateSavedAnswersMutation>;
export type UpdateSavedAnswersMutationOptions = Apollo.BaseMutationOptions<UpdateSavedAnswersMutation, UpdateSavedAnswersMutationVariables>;
export const QualityControlTaskInstancesDocument = gql`
    query QualityControlTaskInstances($taskId: ID!, $templateId: ID) {
  qualityControlTaskInstances(taskId: $taskId, templateId: $templateId) {
    id
    createdAt
    answers {
      fieldId
      value
    }
    templates {
      templateId
      templateVersion
    }
  }
}
    `;

/**
 * __useQualityControlTaskInstancesQuery__
 *
 * To run a query within a React component, call `useQualityControlTaskInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQualityControlTaskInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQualityControlTaskInstancesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useQualityControlTaskInstancesQuery(baseOptions: Apollo.QueryHookOptions<QualityControlTaskInstancesQuery, QualityControlTaskInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QualityControlTaskInstancesQuery, QualityControlTaskInstancesQueryVariables>(QualityControlTaskInstancesDocument, options);
      }
export function useQualityControlTaskInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QualityControlTaskInstancesQuery, QualityControlTaskInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QualityControlTaskInstancesQuery, QualityControlTaskInstancesQueryVariables>(QualityControlTaskInstancesDocument, options);
        }
export type QualityControlTaskInstancesQueryHookResult = ReturnType<typeof useQualityControlTaskInstancesQuery>;
export type QualityControlTaskInstancesLazyQueryHookResult = ReturnType<typeof useQualityControlTaskInstancesLazyQuery>;
export type QualityControlTaskInstancesQueryResult = Apollo.QueryResult<QualityControlTaskInstancesQuery, QualityControlTaskInstancesQueryVariables>;
export const QualityControlInstancesDocument = gql`
    query QualityControlInstances($projectId: ID!, $visualContextIds: [ID!], $resourceIds: [ID!], $pages: [Int!]) {
  qualityControlInstances(
    projectId: $projectId
    visualContextIds: $visualContextIds
    resourceIds: $resourceIds
    pages: $pages
  ) {
    ...QualityControlInstanceFragment
  }
}
    ${QualityControlInstanceFragmentFragmentDoc}`;

/**
 * __useQualityControlInstancesQuery__
 *
 * To run a query within a React component, call `useQualityControlInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQualityControlInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQualityControlInstancesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      visualContextIds: // value for 'visualContextIds'
 *      resourceIds: // value for 'resourceIds'
 *      pages: // value for 'pages'
 *   },
 * });
 */
export function useQualityControlInstancesQuery(baseOptions: Apollo.QueryHookOptions<QualityControlInstancesQuery, QualityControlInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QualityControlInstancesQuery, QualityControlInstancesQueryVariables>(QualityControlInstancesDocument, options);
      }
export function useQualityControlInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QualityControlInstancesQuery, QualityControlInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QualityControlInstancesQuery, QualityControlInstancesQueryVariables>(QualityControlInstancesDocument, options);
        }
export type QualityControlInstancesQueryHookResult = ReturnType<typeof useQualityControlInstancesQuery>;
export type QualityControlInstancesLazyQueryHookResult = ReturnType<typeof useQualityControlInstancesLazyQuery>;
export type QualityControlInstancesQueryResult = Apollo.QueryResult<QualityControlInstancesQuery, QualityControlInstancesQueryVariables>;
export const ResourcePermissionsDocument = gql`
    query resourcePermissions($projectId: ID!, $resourceId: ID!, $resourceType: ResourceType!) {
  resourcePermissions(
    projectId: $projectId
    resourceId: $resourceId
    resourceType: $resourceType
  ) {
    permissions
  }
}
    `;

/**
 * __useResourcePermissionsQuery__
 *
 * To run a query within a React component, call `useResourcePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePermissionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      resourceId: // value for 'resourceId'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useResourcePermissionsQuery(baseOptions: Apollo.QueryHookOptions<ResourcePermissionsQuery, ResourcePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcePermissionsQuery, ResourcePermissionsQueryVariables>(ResourcePermissionsDocument, options);
      }
export function useResourcePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcePermissionsQuery, ResourcePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcePermissionsQuery, ResourcePermissionsQueryVariables>(ResourcePermissionsDocument, options);
        }
export type ResourcePermissionsQueryHookResult = ReturnType<typeof useResourcePermissionsQuery>;
export type ResourcePermissionsLazyQueryHookResult = ReturnType<typeof useResourcePermissionsLazyQuery>;
export type ResourcePermissionsQueryResult = Apollo.QueryResult<ResourcePermissionsQuery, ResourcePermissionsQueryVariables>;
export const QualityControlInstanceDocument = gql`
    query QualityControlInstance($id: ID!) {
  qualityControlInstance(id: $id) {
    ...QualityControlInstanceFragment
    items {
      ...FieldDefinitionPropsFragment
    }
    taskId
    projectId
    answers {
      fieldId
      value
    }
    linkedSnags {
      ...FormLinkedSnagsFragment
    }
  }
}
    ${QualityControlInstanceFragmentFragmentDoc}
${FieldDefinitionPropsFragmentFragmentDoc}
${FormLinkedSnagsFragmentFragmentDoc}`;

/**
 * __useQualityControlInstanceQuery__
 *
 * To run a query within a React component, call `useQualityControlInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useQualityControlInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQualityControlInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQualityControlInstanceQuery(baseOptions: Apollo.QueryHookOptions<QualityControlInstanceQuery, QualityControlInstanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QualityControlInstanceQuery, QualityControlInstanceQueryVariables>(QualityControlInstanceDocument, options);
      }
export function useQualityControlInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QualityControlInstanceQuery, QualityControlInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QualityControlInstanceQuery, QualityControlInstanceQueryVariables>(QualityControlInstanceDocument, options);
        }
export type QualityControlInstanceQueryHookResult = ReturnType<typeof useQualityControlInstanceQuery>;
export type QualityControlInstanceLazyQueryHookResult = ReturnType<typeof useQualityControlInstanceLazyQuery>;
export type QualityControlInstanceQueryResult = Apollo.QueryResult<QualityControlInstanceQuery, QualityControlInstanceQueryVariables>;
export const BatchQualityControlInstancesDocument = gql`
    query BatchQualityControlInstances($ids: [ID!]!) {
  qualityControlInstances: batchQualityControlInstances(ids: $ids) {
    ...QualityControlInstanceFragment
    items {
      ...FieldDefinitionPropsFragment
    }
    taskId
    projectId
    answers {
      fieldId
      value
    }
    linkedSnags {
      ...FormLinkedSnagsFragment
    }
    linkedSnagInstances {
      name
      reference
      id
      type
      x
      y
      visualContext {
        id
        name
        height
        formCount
        freeSnagCount
        snagCount
        pageCount
        width
        zoomLevels
        status
      }
      linkedFieldId
    }
  }
}
    ${QualityControlInstanceFragmentFragmentDoc}
${FieldDefinitionPropsFragmentFragmentDoc}
${FormLinkedSnagsFragmentFragmentDoc}`;

/**
 * __useBatchQualityControlInstancesQuery__
 *
 * To run a query within a React component, call `useBatchQualityControlInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchQualityControlInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchQualityControlInstancesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBatchQualityControlInstancesQuery(baseOptions: Apollo.QueryHookOptions<BatchQualityControlInstancesQuery, BatchQualityControlInstancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BatchQualityControlInstancesQuery, BatchQualityControlInstancesQueryVariables>(BatchQualityControlInstancesDocument, options);
      }
export function useBatchQualityControlInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BatchQualityControlInstancesQuery, BatchQualityControlInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BatchQualityControlInstancesQuery, BatchQualityControlInstancesQueryVariables>(BatchQualityControlInstancesDocument, options);
        }
export type BatchQualityControlInstancesQueryHookResult = ReturnType<typeof useBatchQualityControlInstancesQuery>;
export type BatchQualityControlInstancesLazyQueryHookResult = ReturnType<typeof useBatchQualityControlInstancesLazyQuery>;
export type BatchQualityControlInstancesQueryResult = Apollo.QueryResult<BatchQualityControlInstancesQuery, BatchQualityControlInstancesQueryVariables>;
export const QualityControlGetOrCreateInstanceDocument = gql`
    query QualityControlGetOrCreateInstance($projectId: ID!, $taskId: ID!, $templateId: ID!) {
  qualityControlGetOrCreateInstance(
    projectId: $projectId
    taskId: $taskId
    templateId: $templateId
  ) {
    id
  }
}
    `;

/**
 * __useQualityControlGetOrCreateInstanceQuery__
 *
 * To run a query within a React component, call `useQualityControlGetOrCreateInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useQualityControlGetOrCreateInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQualityControlGetOrCreateInstanceQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useQualityControlGetOrCreateInstanceQuery(baseOptions: Apollo.QueryHookOptions<QualityControlGetOrCreateInstanceQuery, QualityControlGetOrCreateInstanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QualityControlGetOrCreateInstanceQuery, QualityControlGetOrCreateInstanceQueryVariables>(QualityControlGetOrCreateInstanceDocument, options);
      }
export function useQualityControlGetOrCreateInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QualityControlGetOrCreateInstanceQuery, QualityControlGetOrCreateInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QualityControlGetOrCreateInstanceQuery, QualityControlGetOrCreateInstanceQueryVariables>(QualityControlGetOrCreateInstanceDocument, options);
        }
export type QualityControlGetOrCreateInstanceQueryHookResult = ReturnType<typeof useQualityControlGetOrCreateInstanceQuery>;
export type QualityControlGetOrCreateInstanceLazyQueryHookResult = ReturnType<typeof useQualityControlGetOrCreateInstanceLazyQuery>;
export type QualityControlGetOrCreateInstanceQueryResult = Apollo.QueryResult<QualityControlGetOrCreateInstanceQuery, QualityControlGetOrCreateInstanceQueryVariables>;
export const VisualContextsDocument = gql`
    query VisualContexts($projectId: ID!) {
  visualContexts(projectId: $projectId) {
    ...VisualContextFragment
    snagCount
    freeSnagCount
    formCount
  }
}
    ${VisualContextFragmentFragmentDoc}`;

/**
 * __useVisualContextsQuery__
 *
 * To run a query within a React component, call `useVisualContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualContextsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useVisualContextsQuery(baseOptions: Apollo.QueryHookOptions<VisualContextsQuery, VisualContextsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualContextsQuery, VisualContextsQueryVariables>(VisualContextsDocument, options);
      }
export function useVisualContextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualContextsQuery, VisualContextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualContextsQuery, VisualContextsQueryVariables>(VisualContextsDocument, options);
        }
export type VisualContextsQueryHookResult = ReturnType<typeof useVisualContextsQuery>;
export type VisualContextsLazyQueryHookResult = ReturnType<typeof useVisualContextsLazyQuery>;
export type VisualContextsQueryResult = Apollo.QueryResult<VisualContextsQuery, VisualContextsQueryVariables>;
export const VisualContextDocument = gql`
    query VisualContext($id: ID!, $resourceIds: [ID!]) {
  visualContext(id: $id, resourceIds: $resourceIds) {
    ...VisualContextFragment
  }
}
    ${VisualContextFragmentFragmentDoc}`;

/**
 * __useVisualContextQuery__
 *
 * To run a query within a React component, call `useVisualContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceIds: // value for 'resourceIds'
 *   },
 * });
 */
export function useVisualContextQuery(baseOptions: Apollo.QueryHookOptions<VisualContextQuery, VisualContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualContextQuery, VisualContextQueryVariables>(VisualContextDocument, options);
      }
export function useVisualContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualContextQuery, VisualContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualContextQuery, VisualContextQueryVariables>(VisualContextDocument, options);
        }
export type VisualContextQueryHookResult = ReturnType<typeof useVisualContextQuery>;
export type VisualContextLazyQueryHookResult = ReturnType<typeof useVisualContextLazyQuery>;
export type VisualContextQueryResult = Apollo.QueryResult<VisualContextQuery, VisualContextQueryVariables>;
export const SnagFormHistoryLogDocument = gql`
    query SnagFormHistoryLog($id: ID!) {
  snagFormHistoryLog(id: $id) {
    today {
      ...QCHistoryEventFragment
    }
    thisWeek {
      ...QCHistoryEventFragment
    }
    thisMonth {
      ...QCHistoryEventFragment
    }
    older {
      ...QCHistoryEventFragment
    }
  }
}
    ${QcHistoryEventFragmentFragmentDoc}`;

/**
 * __useSnagFormHistoryLogQuery__
 *
 * To run a query within a React component, call `useSnagFormHistoryLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnagFormHistoryLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnagFormHistoryLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnagFormHistoryLogQuery(baseOptions: Apollo.QueryHookOptions<SnagFormHistoryLogQuery, SnagFormHistoryLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SnagFormHistoryLogQuery, SnagFormHistoryLogQueryVariables>(SnagFormHistoryLogDocument, options);
      }
export function useSnagFormHistoryLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SnagFormHistoryLogQuery, SnagFormHistoryLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SnagFormHistoryLogQuery, SnagFormHistoryLogQueryVariables>(SnagFormHistoryLogDocument, options);
        }
export type SnagFormHistoryLogQueryHookResult = ReturnType<typeof useSnagFormHistoryLogQuery>;
export type SnagFormHistoryLogLazyQueryHookResult = ReturnType<typeof useSnagFormHistoryLogLazyQuery>;
export type SnagFormHistoryLogQueryResult = Apollo.QueryResult<SnagFormHistoryLogQuery, SnagFormHistoryLogQueryVariables>;
export const VisualContextMobileAppDocument = gql`
    query VisualContextMobileApp($id: ID!, $resourceIds: [ID!], $projectId: ID!) {
  visualContext(id: $id, resourceIds: $resourceIds) {
    ...VisualContextMobileAppFragment
  }
}
    ${VisualContextMobileAppFragmentFragmentDoc}`;

/**
 * __useVisualContextMobileAppQuery__
 *
 * To run a query within a React component, call `useVisualContextMobileAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualContextMobileAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualContextMobileAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceIds: // value for 'resourceIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useVisualContextMobileAppQuery(baseOptions: Apollo.QueryHookOptions<VisualContextMobileAppQuery, VisualContextMobileAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualContextMobileAppQuery, VisualContextMobileAppQueryVariables>(VisualContextMobileAppDocument, options);
      }
export function useVisualContextMobileAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualContextMobileAppQuery, VisualContextMobileAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualContextMobileAppQuery, VisualContextMobileAppQueryVariables>(VisualContextMobileAppDocument, options);
        }
export type VisualContextMobileAppQueryHookResult = ReturnType<typeof useVisualContextMobileAppQuery>;
export type VisualContextMobileAppLazyQueryHookResult = ReturnType<typeof useVisualContextMobileAppLazyQuery>;
export type VisualContextMobileAppQueryResult = Apollo.QueryResult<VisualContextMobileAppQuery, VisualContextMobileAppQueryVariables>;
export const SuggestedTemplatesBasedOnImageDocument = gql`
    query SuggestedTemplatesBasedOnImage($projectId: ID!, $base64ImageString: String!, $templateType: TemplateType!) {
  suggestedTemplatesBasedOnImage(
    projectId: $projectId
    base64ImageString: $base64ImageString
    templateType: $templateType
  ) {
    ...SuggestedTemplatesBasedOnImageResponseFragment
  }
}
    ${SuggestedTemplatesBasedOnImageResponseFragmentFragmentDoc}`;

/**
 * __useSuggestedTemplatesBasedOnImageQuery__
 *
 * To run a query within a React component, call `useSuggestedTemplatesBasedOnImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedTemplatesBasedOnImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedTemplatesBasedOnImageQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      base64ImageString: // value for 'base64ImageString'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useSuggestedTemplatesBasedOnImageQuery(baseOptions: Apollo.QueryHookOptions<SuggestedTemplatesBasedOnImageQuery, SuggestedTemplatesBasedOnImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedTemplatesBasedOnImageQuery, SuggestedTemplatesBasedOnImageQueryVariables>(SuggestedTemplatesBasedOnImageDocument, options);
      }
export function useSuggestedTemplatesBasedOnImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedTemplatesBasedOnImageQuery, SuggestedTemplatesBasedOnImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedTemplatesBasedOnImageQuery, SuggestedTemplatesBasedOnImageQueryVariables>(SuggestedTemplatesBasedOnImageDocument, options);
        }
export type SuggestedTemplatesBasedOnImageQueryHookResult = ReturnType<typeof useSuggestedTemplatesBasedOnImageQuery>;
export type SuggestedTemplatesBasedOnImageLazyQueryHookResult = ReturnType<typeof useSuggestedTemplatesBasedOnImageLazyQuery>;
export type SuggestedTemplatesBasedOnImageQueryResult = Apollo.QueryResult<SuggestedTemplatesBasedOnImageQuery, SuggestedTemplatesBasedOnImageQueryVariables>;
export const InstanceWithSuggestedAnswersDocument = gql`
    query InstanceWithSuggestedAnswers($templateId: ID!, $fileId: ID!, $x: Float!, $y: Float!, $page: Int, $visualContextId: ID!, $projectId: ID!) {
  instanceWithSuggestedAnswers(
    templateId: $templateId
    fileId: $fileId
    x: $x
    y: $y
    page: $page
    visualContextId: $visualContextId
    projectId: $projectId
  ) {
    ...SuggestedAnswersBasedOnImageResponseFragment
  }
}
    ${SuggestedAnswersBasedOnImageResponseFragmentFragmentDoc}`;

/**
 * __useInstanceWithSuggestedAnswersQuery__
 *
 * To run a query within a React component, call `useInstanceWithSuggestedAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstanceWithSuggestedAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstanceWithSuggestedAnswersQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      fileId: // value for 'fileId'
 *      x: // value for 'x'
 *      y: // value for 'y'
 *      page: // value for 'page'
 *      visualContextId: // value for 'visualContextId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useInstanceWithSuggestedAnswersQuery(baseOptions: Apollo.QueryHookOptions<InstanceWithSuggestedAnswersQuery, InstanceWithSuggestedAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstanceWithSuggestedAnswersQuery, InstanceWithSuggestedAnswersQueryVariables>(InstanceWithSuggestedAnswersDocument, options);
      }
export function useInstanceWithSuggestedAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstanceWithSuggestedAnswersQuery, InstanceWithSuggestedAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstanceWithSuggestedAnswersQuery, InstanceWithSuggestedAnswersQueryVariables>(InstanceWithSuggestedAnswersDocument, options);
        }
export type InstanceWithSuggestedAnswersQueryHookResult = ReturnType<typeof useInstanceWithSuggestedAnswersQuery>;
export type InstanceWithSuggestedAnswersLazyQueryHookResult = ReturnType<typeof useInstanceWithSuggestedAnswersLazyQuery>;
export type InstanceWithSuggestedAnswersQueryResult = Apollo.QueryResult<InstanceWithSuggestedAnswersQuery, InstanceWithSuggestedAnswersQueryVariables>;
export const SavedAnswersIncludeDocument = gql`
    query SavedAnswersInclude($includeAnswers: Boolean = false, $includeCreatedOn: Boolean = false, $includeTemplateName: Boolean = false, $includeType: Boolean = false, $projectId: ID!) {
  savedAnswers(projectId: $projectId) {
    ...FragmentSavedAnswersInclude
  }
}
    ${FragmentSavedAnswersIncludeFragmentDoc}`;

/**
 * __useSavedAnswersIncludeQuery__
 *
 * To run a query within a React component, call `useSavedAnswersIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedAnswersIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedAnswersIncludeQuery({
 *   variables: {
 *      includeAnswers: // value for 'includeAnswers'
 *      includeCreatedOn: // value for 'includeCreatedOn'
 *      includeTemplateName: // value for 'includeTemplateName'
 *      includeType: // value for 'includeType'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSavedAnswersIncludeQuery(baseOptions: Apollo.QueryHookOptions<SavedAnswersIncludeQuery, SavedAnswersIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedAnswersIncludeQuery, SavedAnswersIncludeQueryVariables>(SavedAnswersIncludeDocument, options);
      }
export function useSavedAnswersIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedAnswersIncludeQuery, SavedAnswersIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedAnswersIncludeQuery, SavedAnswersIncludeQueryVariables>(SavedAnswersIncludeDocument, options);
        }
export type SavedAnswersIncludeQueryHookResult = ReturnType<typeof useSavedAnswersIncludeQuery>;
export type SavedAnswersIncludeLazyQueryHookResult = ReturnType<typeof useSavedAnswersIncludeLazyQuery>;
export type SavedAnswersIncludeQueryResult = Apollo.QueryResult<SavedAnswersIncludeQuery, SavedAnswersIncludeQueryVariables>;
export const SavedAnswersInstanceIncludeDocument = gql`
    query SavedAnswersInstanceInclude($id: ID!, $includeAnswers: Boolean = false, $includeItems: Boolean = false) {
  savedAnswersInstance(id: $id) {
    ...FragmentSavedAnswersInstance
  }
}
    ${FragmentSavedAnswersInstanceFragmentDoc}`;

/**
 * __useSavedAnswersInstanceIncludeQuery__
 *
 * To run a query within a React component, call `useSavedAnswersInstanceIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedAnswersInstanceIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedAnswersInstanceIncludeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeAnswers: // value for 'includeAnswers'
 *      includeItems: // value for 'includeItems'
 *   },
 * });
 */
export function useSavedAnswersInstanceIncludeQuery(baseOptions: Apollo.QueryHookOptions<SavedAnswersInstanceIncludeQuery, SavedAnswersInstanceIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedAnswersInstanceIncludeQuery, SavedAnswersInstanceIncludeQueryVariables>(SavedAnswersInstanceIncludeDocument, options);
      }
export function useSavedAnswersInstanceIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedAnswersInstanceIncludeQuery, SavedAnswersInstanceIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedAnswersInstanceIncludeQuery, SavedAnswersInstanceIncludeQueryVariables>(SavedAnswersInstanceIncludeDocument, options);
        }
export type SavedAnswersInstanceIncludeQueryHookResult = ReturnType<typeof useSavedAnswersInstanceIncludeQuery>;
export type SavedAnswersInstanceIncludeLazyQueryHookResult = ReturnType<typeof useSavedAnswersInstanceIncludeLazyQuery>;
export type SavedAnswersInstanceIncludeQueryResult = Apollo.QueryResult<SavedAnswersInstanceIncludeQuery, SavedAnswersInstanceIncludeQueryVariables>;
export const SavedAnswersOnTemplateIdDocument = gql`
    query SavedAnswersOnTemplateId($templateId: ID!) {
  savedAnswersOnTemplateId(templateId: $templateId) {
    ...FragmentSavedAnswersOnTemplateId
  }
}
    ${FragmentSavedAnswersOnTemplateIdFragmentDoc}`;

/**
 * __useSavedAnswersOnTemplateIdQuery__
 *
 * To run a query within a React component, call `useSavedAnswersOnTemplateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedAnswersOnTemplateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedAnswersOnTemplateIdQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useSavedAnswersOnTemplateIdQuery(baseOptions: Apollo.QueryHookOptions<SavedAnswersOnTemplateIdQuery, SavedAnswersOnTemplateIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedAnswersOnTemplateIdQuery, SavedAnswersOnTemplateIdQueryVariables>(SavedAnswersOnTemplateIdDocument, options);
      }
export function useSavedAnswersOnTemplateIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedAnswersOnTemplateIdQuery, SavedAnswersOnTemplateIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedAnswersOnTemplateIdQuery, SavedAnswersOnTemplateIdQueryVariables>(SavedAnswersOnTemplateIdDocument, options);
        }
export type SavedAnswersOnTemplateIdQueryHookResult = ReturnType<typeof useSavedAnswersOnTemplateIdQuery>;
export type SavedAnswersOnTemplateIdLazyQueryHookResult = ReturnType<typeof useSavedAnswersOnTemplateIdLazyQuery>;
export type SavedAnswersOnTemplateIdQueryResult = Apollo.QueryResult<SavedAnswersOnTemplateIdQuery, SavedAnswersOnTemplateIdQueryVariables>;
export const TestMobileAppFallBackApiDocument = gql`
    query TestMobileAppFallBackApi {
  testMobileAppFallBackApi
}
    `;

/**
 * __useTestMobileAppFallBackApiQuery__
 *
 * To run a query within a React component, call `useTestMobileAppFallBackApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestMobileAppFallBackApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestMobileAppFallBackApiQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestMobileAppFallBackApiQuery(baseOptions?: Apollo.QueryHookOptions<TestMobileAppFallBackApiQuery, TestMobileAppFallBackApiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestMobileAppFallBackApiQuery, TestMobileAppFallBackApiQueryVariables>(TestMobileAppFallBackApiDocument, options);
      }
export function useTestMobileAppFallBackApiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestMobileAppFallBackApiQuery, TestMobileAppFallBackApiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestMobileAppFallBackApiQuery, TestMobileAppFallBackApiQueryVariables>(TestMobileAppFallBackApiDocument, options);
        }
export type TestMobileAppFallBackApiQueryHookResult = ReturnType<typeof useTestMobileAppFallBackApiQuery>;
export type TestMobileAppFallBackApiLazyQueryHookResult = ReturnType<typeof useTestMobileAppFallBackApiLazyQuery>;
export type TestMobileAppFallBackApiQueryResult = Apollo.QueryResult<TestMobileAppFallBackApiQuery, TestMobileAppFallBackApiQueryVariables>;
export const CreateReportConfigurationDocument = gql`
    mutation CreateReportConfiguration($name: String!, $projectId: ID, $reportOptions: String!, $setAsDefault: Boolean!, $storeOnOrganizationLevel: Boolean!) {
  createReportConfiguration(
    name: $name
    projectId: $projectId
    reportOptions: $reportOptions
    setAsDefault: $setAsDefault
    storeOnOrganizationLevel: $storeOnOrganizationLevel
  )
}
    `;
export type CreateReportConfigurationMutationFn = Apollo.MutationFunction<CreateReportConfigurationMutation, CreateReportConfigurationMutationVariables>;

/**
 * __useCreateReportConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateReportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportConfigurationMutation, { data, loading, error }] = useCreateReportConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      reportOptions: // value for 'reportOptions'
 *      setAsDefault: // value for 'setAsDefault'
 *      storeOnOrganizationLevel: // value for 'storeOnOrganizationLevel'
 *   },
 * });
 */
export function useCreateReportConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportConfigurationMutation, CreateReportConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportConfigurationMutation, CreateReportConfigurationMutationVariables>(CreateReportConfigurationDocument, options);
      }
export type CreateReportConfigurationMutationHookResult = ReturnType<typeof useCreateReportConfigurationMutation>;
export type CreateReportConfigurationMutationResult = Apollo.MutationResult<CreateReportConfigurationMutation>;
export type CreateReportConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateReportConfigurationMutation, CreateReportConfigurationMutationVariables>;
export const DeleteReportConfigurationsDocument = gql`
    mutation DeleteReportConfigurations($ids: [ID!]!) {
  deleteReportConfigurations(ids: $ids)
}
    `;
export type DeleteReportConfigurationsMutationFn = Apollo.MutationFunction<DeleteReportConfigurationsMutation, DeleteReportConfigurationsMutationVariables>;

/**
 * __useDeleteReportConfigurationsMutation__
 *
 * To run a mutation, you first call `useDeleteReportConfigurationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportConfigurationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportConfigurationsMutation, { data, loading, error }] = useDeleteReportConfigurationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReportConfigurationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportConfigurationsMutation, DeleteReportConfigurationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportConfigurationsMutation, DeleteReportConfigurationsMutationVariables>(DeleteReportConfigurationsDocument, options);
      }
export type DeleteReportConfigurationsMutationHookResult = ReturnType<typeof useDeleteReportConfigurationsMutation>;
export type DeleteReportConfigurationsMutationResult = Apollo.MutationResult<DeleteReportConfigurationsMutation>;
export type DeleteReportConfigurationsMutationOptions = Apollo.BaseMutationOptions<DeleteReportConfigurationsMutation, DeleteReportConfigurationsMutationVariables>;
export const EditReportConfigurationDocument = gql`
    mutation EditReportConfiguration($id: ID!, $name: String!, $projectId: ID, $reportOptions: String!, $setAsDefault: Boolean!, $storeOnOrganizationLevel: Boolean!) {
  editReportConfiguration(
    id: $id
    name: $name
    projectId: $projectId
    reportOptions: $reportOptions
    setAsDefault: $setAsDefault
    storeOnOrganizationLevel: $storeOnOrganizationLevel
  )
}
    `;
export type EditReportConfigurationMutationFn = Apollo.MutationFunction<EditReportConfigurationMutation, EditReportConfigurationMutationVariables>;

/**
 * __useEditReportConfigurationMutation__
 *
 * To run a mutation, you first call `useEditReportConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportConfigurationMutation, { data, loading, error }] = useEditReportConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      reportOptions: // value for 'reportOptions'
 *      setAsDefault: // value for 'setAsDefault'
 *      storeOnOrganizationLevel: // value for 'storeOnOrganizationLevel'
 *   },
 * });
 */
export function useEditReportConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<EditReportConfigurationMutation, EditReportConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportConfigurationMutation, EditReportConfigurationMutationVariables>(EditReportConfigurationDocument, options);
      }
export type EditReportConfigurationMutationHookResult = ReturnType<typeof useEditReportConfigurationMutation>;
export type EditReportConfigurationMutationResult = Apollo.MutationResult<EditReportConfigurationMutation>;
export type EditReportConfigurationMutationOptions = Apollo.BaseMutationOptions<EditReportConfigurationMutation, EditReportConfigurationMutationVariables>;
export const ReportLogosDocument = gql`
    query ReportLogos {
  reportLogos {
    ...ReportImageFragment
  }
}
    ${ReportImageFragmentFragmentDoc}`;

/**
 * __useReportLogosQuery__
 *
 * To run a query within a React component, call `useReportLogosQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportLogosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportLogosQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportLogosQuery(baseOptions?: Apollo.QueryHookOptions<ReportLogosQuery, ReportLogosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportLogosQuery, ReportLogosQueryVariables>(ReportLogosDocument, options);
      }
export function useReportLogosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportLogosQuery, ReportLogosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportLogosQuery, ReportLogosQueryVariables>(ReportLogosDocument, options);
        }
export type ReportLogosQueryHookResult = ReturnType<typeof useReportLogosQuery>;
export type ReportLogosLazyQueryHookResult = ReturnType<typeof useReportLogosLazyQuery>;
export type ReportLogosQueryResult = Apollo.QueryResult<ReportLogosQuery, ReportLogosQueryVariables>;
export const ReportBackgroundsDocument = gql`
    query ReportBackgrounds {
  reportBackgrounds {
    ...ReportImageFragment
  }
}
    ${ReportImageFragmentFragmentDoc}`;

/**
 * __useReportBackgroundsQuery__
 *
 * To run a query within a React component, call `useReportBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportBackgroundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportBackgroundsQuery(baseOptions?: Apollo.QueryHookOptions<ReportBackgroundsQuery, ReportBackgroundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportBackgroundsQuery, ReportBackgroundsQueryVariables>(ReportBackgroundsDocument, options);
      }
export function useReportBackgroundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportBackgroundsQuery, ReportBackgroundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportBackgroundsQuery, ReportBackgroundsQueryVariables>(ReportBackgroundsDocument, options);
        }
export type ReportBackgroundsQueryHookResult = ReturnType<typeof useReportBackgroundsQuery>;
export type ReportBackgroundsLazyQueryHookResult = ReturnType<typeof useReportBackgroundsLazyQuery>;
export type ReportBackgroundsQueryResult = Apollo.QueryResult<ReportBackgroundsQuery, ReportBackgroundsQueryVariables>;
export const ReportConfigurationDocument = gql`
    query ReportConfiguration($id: ID!) {
  reportConfiguration(id: $id) {
    ...FragmentReportConfiguration
  }
}
    ${FragmentReportConfigurationFragmentDoc}`;

/**
 * __useReportConfigurationQuery__
 *
 * To run a query within a React component, call `useReportConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportConfigurationQuery(baseOptions: Apollo.QueryHookOptions<ReportConfigurationQuery, ReportConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportConfigurationQuery, ReportConfigurationQueryVariables>(ReportConfigurationDocument, options);
      }
export function useReportConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportConfigurationQuery, ReportConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportConfigurationQuery, ReportConfigurationQueryVariables>(ReportConfigurationDocument, options);
        }
export type ReportConfigurationQueryHookResult = ReturnType<typeof useReportConfigurationQuery>;
export type ReportConfigurationLazyQueryHookResult = ReturnType<typeof useReportConfigurationLazyQuery>;
export type ReportConfigurationQueryResult = Apollo.QueryResult<ReportConfigurationQuery, ReportConfigurationQueryVariables>;
export const ReportConfigurationsIncludeDocument = gql`
    query ReportConfigurationsInclude($includeCreatedAt: Boolean = false, $includeCreatedBy: Boolean = false, $includeIsDefault: Boolean = false, $includeProjectId: Boolean = false, $includeReportOptions: Boolean = false, $includeScope: Boolean = false, $includeUpdatedAt: Boolean = false, $includeUpdatedBy: Boolean = false, $projectId: ID!) {
  reportConfigurations(projectId: $projectId) {
    ...FragmentReportConfigurationsInclude
  }
}
    ${FragmentReportConfigurationsIncludeFragmentDoc}`;

/**
 * __useReportConfigurationsIncludeQuery__
 *
 * To run a query within a React component, call `useReportConfigurationsIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportConfigurationsIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportConfigurationsIncludeQuery({
 *   variables: {
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeIsDefault: // value for 'includeIsDefault'
 *      includeProjectId: // value for 'includeProjectId'
 *      includeReportOptions: // value for 'includeReportOptions'
 *      includeScope: // value for 'includeScope'
 *      includeUpdatedAt: // value for 'includeUpdatedAt'
 *      includeUpdatedBy: // value for 'includeUpdatedBy'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useReportConfigurationsIncludeQuery(baseOptions: Apollo.QueryHookOptions<ReportConfigurationsIncludeQuery, ReportConfigurationsIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportConfigurationsIncludeQuery, ReportConfigurationsIncludeQueryVariables>(ReportConfigurationsIncludeDocument, options);
      }
export function useReportConfigurationsIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportConfigurationsIncludeQuery, ReportConfigurationsIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportConfigurationsIncludeQuery, ReportConfigurationsIncludeQueryVariables>(ReportConfigurationsIncludeDocument, options);
        }
export type ReportConfigurationsIncludeQueryHookResult = ReturnType<typeof useReportConfigurationsIncludeQuery>;
export type ReportConfigurationsIncludeLazyQueryHookResult = ReturnType<typeof useReportConfigurationsIncludeLazyQuery>;
export type ReportConfigurationsIncludeQueryResult = Apollo.QueryResult<ReportConfigurationsIncludeQuery, ReportConfigurationsIncludeQueryVariables>;
export const RegisterOrganizationDocument = gql`
    mutation RegisterOrganization($email: String!, $firstName: String!, $lastName: String!, $locale: String!, $organizationName: String!, $password: String!, $recaptcha: String!, $subscriptionIntent: String!, $phoneNumber: String!) {
  registerOrganization(
    email: $email
    firstName: $firstName
    lastName: $lastName
    locale: $locale
    organizationName: $organizationName
    password: $password
    recaptcha: $recaptcha
    subscriptionIntent: $subscriptionIntent
    phoneNumber: $phoneNumber
  )
}
    `;
export type RegisterOrganizationMutationFn = Apollo.MutationFunction<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>;

/**
 * __useRegisterOrganizationMutation__
 *
 * To run a mutation, you first call `useRegisterOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOrganizationMutation, { data, loading, error }] = useRegisterOrganizationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      locale: // value for 'locale'
 *      organizationName: // value for 'organizationName'
 *      password: // value for 'password'
 *      recaptcha: // value for 'recaptcha'
 *      subscriptionIntent: // value for 'subscriptionIntent'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRegisterOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>(RegisterOrganizationDocument, options);
      }
export type RegisterOrganizationMutationHookResult = ReturnType<typeof useRegisterOrganizationMutation>;
export type RegisterOrganizationMutationResult = Apollo.MutationResult<RegisterOrganizationMutation>;
export type RegisterOrganizationMutationOptions = Apollo.BaseMutationOptions<RegisterOrganizationMutation, RegisterOrganizationMutationVariables>;
export const FinalizeOrgInvitationDocument = gql`
    mutation FinalizeOrgInvitation($firstName: String!, $lastName: String!, $phoneNumber: String!, $locale: String!, $password: String!, $token: String!) {
  finalizeOrgInvitation(
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    locale: $locale
    password: $password
    token: $token
  )
}
    `;
export type FinalizeOrgInvitationMutationFn = Apollo.MutationFunction<FinalizeOrgInvitationMutation, FinalizeOrgInvitationMutationVariables>;

/**
 * __useFinalizeOrgInvitationMutation__
 *
 * To run a mutation, you first call `useFinalizeOrgInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeOrgInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeOrgInvitationMutation, { data, loading, error }] = useFinalizeOrgInvitationMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      locale: // value for 'locale'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFinalizeOrgInvitationMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeOrgInvitationMutation, FinalizeOrgInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeOrgInvitationMutation, FinalizeOrgInvitationMutationVariables>(FinalizeOrgInvitationDocument, options);
      }
export type FinalizeOrgInvitationMutationHookResult = ReturnType<typeof useFinalizeOrgInvitationMutation>;
export type FinalizeOrgInvitationMutationResult = Apollo.MutationResult<FinalizeOrgInvitationMutation>;
export type FinalizeOrgInvitationMutationOptions = Apollo.BaseMutationOptions<FinalizeOrgInvitationMutation, FinalizeOrgInvitationMutationVariables>;
export const ValidateRegistrationTokenDocument = gql`
    mutation ValidateRegistrationToken($token: String!) {
  validateRegistrationToken(token: $token)
}
    `;
export type ValidateRegistrationTokenMutationFn = Apollo.MutationFunction<ValidateRegistrationTokenMutation, ValidateRegistrationTokenMutationVariables>;

/**
 * __useValidateRegistrationTokenMutation__
 *
 * To run a mutation, you first call `useValidateRegistrationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateRegistrationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateRegistrationTokenMutation, { data, loading, error }] = useValidateRegistrationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateRegistrationTokenMutation(baseOptions?: Apollo.MutationHookOptions<ValidateRegistrationTokenMutation, ValidateRegistrationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateRegistrationTokenMutation, ValidateRegistrationTokenMutationVariables>(ValidateRegistrationTokenDocument, options);
      }
export type ValidateRegistrationTokenMutationHookResult = ReturnType<typeof useValidateRegistrationTokenMutation>;
export type ValidateRegistrationTokenMutationResult = Apollo.MutationResult<ValidateRegistrationTokenMutation>;
export type ValidateRegistrationTokenMutationOptions = Apollo.BaseMutationOptions<ValidateRegistrationTokenMutation, ValidateRegistrationTokenMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token)
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const UserExistsDocument = gql`
    query UserExists($email: String!) {
  userExists(email: $email)
}
    `;

/**
 * __useUserExistsQuery__
 *
 * To run a query within a React component, call `useUserExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserExistsQuery(baseOptions: Apollo.QueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, options);
      }
export function useUserExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, options);
        }
export type UserExistsQueryHookResult = ReturnType<typeof useUserExistsQuery>;
export type UserExistsLazyQueryHookResult = ReturnType<typeof useUserExistsLazyQuery>;
export type UserExistsQueryResult = Apollo.QueryResult<UserExistsQuery, UserExistsQueryVariables>;
export const OrganizationExistsDocument = gql`
    query OrganizationExists($name: String!) {
  organizationExists(name: $name)
}
    `;

/**
 * __useOrganizationExistsQuery__
 *
 * To run a query within a React component, call `useOrganizationExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationExistsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationExistsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationExistsQuery, OrganizationExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationExistsQuery, OrganizationExistsQueryVariables>(OrganizationExistsDocument, options);
      }
export function useOrganizationExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationExistsQuery, OrganizationExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationExistsQuery, OrganizationExistsQueryVariables>(OrganizationExistsDocument, options);
        }
export type OrganizationExistsQueryHookResult = ReturnType<typeof useOrganizationExistsQuery>;
export type OrganizationExistsLazyQueryHookResult = ReturnType<typeof useOrganizationExistsLazyQuery>;
export type OrganizationExistsQueryResult = Apollo.QueryResult<OrganizationExistsQuery, OrganizationExistsQueryVariables>;
export const SaveQueryDocument = gql`
    mutation SaveQuery($documentType: String!, $filters: [String], $name: String!, $notificationsEnabled: Boolean!, $notificationDays: [WeekDay]!, $notificationTimes: [ScheduledTimeInput]!, $notificationTimeZone: String, $projectId: String, $query: String, $searchUriComponent: String!, $scope: Int!) {
  saveQuery(
    documentType: $documentType
    filters: $filters
    name: $name
    notificationsEnabled: $notificationsEnabled
    notificationDays: $notificationDays
    notificationTimes: $notificationTimes
    notificationTimeZone: $notificationTimeZone
    projectId: $projectId
    query: $query
    searchUriComponent: $searchUriComponent
    scope: $scope
  ) {
    errors
    name
    success
  }
}
    `;
export type SaveQueryMutationFn = Apollo.MutationFunction<SaveQueryMutation, SaveQueryMutationVariables>;

/**
 * __useSaveQueryMutation__
 *
 * To run a mutation, you first call `useSaveQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQueryMutation, { data, loading, error }] = useSaveQueryMutation({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *      notificationsEnabled: // value for 'notificationsEnabled'
 *      notificationDays: // value for 'notificationDays'
 *      notificationTimes: // value for 'notificationTimes'
 *      notificationTimeZone: // value for 'notificationTimeZone'
 *      projectId: // value for 'projectId'
 *      query: // value for 'query'
 *      searchUriComponent: // value for 'searchUriComponent'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useSaveQueryMutation(baseOptions?: Apollo.MutationHookOptions<SaveQueryMutation, SaveQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveQueryMutation, SaveQueryMutationVariables>(SaveQueryDocument, options);
      }
export type SaveQueryMutationHookResult = ReturnType<typeof useSaveQueryMutation>;
export type SaveQueryMutationResult = Apollo.MutationResult<SaveQueryMutation>;
export type SaveQueryMutationOptions = Apollo.BaseMutationOptions<SaveQueryMutation, SaveQueryMutationVariables>;
export const EditQueryDocument = gql`
    mutation EditQuery($id: ID!, $documentType: String!, $filters: [String], $name: String!, $notificationsEnabled: Boolean!, $notificationDays: [WeekDay]!, $notificationTimes: [ScheduledTimeInput]!, $notificationTimeZone: String, $projectId: String, $query: String, $searchUriComponent: String!) {
  editQuery(
    id: $id
    documentType: $documentType
    filters: $filters
    name: $name
    notificationsEnabled: $notificationsEnabled
    notificationDays: $notificationDays
    notificationTimes: $notificationTimes
    notificationTimeZone: $notificationTimeZone
    projectId: $projectId
    query: $query
    searchUriComponent: $searchUriComponent
  ) {
    errors
    success
  }
}
    `;
export type EditQueryMutationFn = Apollo.MutationFunction<EditQueryMutation, EditQueryMutationVariables>;

/**
 * __useEditQueryMutation__
 *
 * To run a mutation, you first call `useEditQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQueryMutation, { data, loading, error }] = useEditQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      documentType: // value for 'documentType'
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *      notificationsEnabled: // value for 'notificationsEnabled'
 *      notificationDays: // value for 'notificationDays'
 *      notificationTimes: // value for 'notificationTimes'
 *      notificationTimeZone: // value for 'notificationTimeZone'
 *      projectId: // value for 'projectId'
 *      query: // value for 'query'
 *      searchUriComponent: // value for 'searchUriComponent'
 *   },
 * });
 */
export function useEditQueryMutation(baseOptions?: Apollo.MutationHookOptions<EditQueryMutation, EditQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditQueryMutation, EditQueryMutationVariables>(EditQueryDocument, options);
      }
export type EditQueryMutationHookResult = ReturnType<typeof useEditQueryMutation>;
export type EditQueryMutationResult = Apollo.MutationResult<EditQueryMutation>;
export type EditQueryMutationOptions = Apollo.BaseMutationOptions<EditQueryMutation, EditQueryMutationVariables>;
export const DeleteQueryDocument = gql`
    mutation DeleteQuery($id: ID!, $name: String!) {
  deleteQuery(id: $id, name: $name) {
    errors
    name
    success
  }
}
    `;
export type DeleteQueryMutationFn = Apollo.MutationFunction<DeleteQueryMutation, DeleteQueryMutationVariables>;

/**
 * __useDeleteQueryMutation__
 *
 * To run a mutation, you first call `useDeleteQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQueryMutation, { data, loading, error }] = useDeleteQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteQueryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQueryMutation, DeleteQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQueryMutation, DeleteQueryMutationVariables>(DeleteQueryDocument, options);
      }
export type DeleteQueryMutationHookResult = ReturnType<typeof useDeleteQueryMutation>;
export type DeleteQueryMutationResult = Apollo.MutationResult<DeleteQueryMutation>;
export type DeleteQueryMutationOptions = Apollo.BaseMutationOptions<DeleteQueryMutation, DeleteQueryMutationVariables>;
export const RestoreQueryDocument = gql`
    mutation RestoreQuery($id: ID!, $name: String!) {
  restoreQuery(id: $id, name: $name) {
    errors
    name
    success
  }
}
    `;
export type RestoreQueryMutationFn = Apollo.MutationFunction<RestoreQueryMutation, RestoreQueryMutationVariables>;

/**
 * __useRestoreQueryMutation__
 *
 * To run a mutation, you first call `useRestoreQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreQueryMutation, { data, loading, error }] = useRestoreQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRestoreQueryMutation(baseOptions?: Apollo.MutationHookOptions<RestoreQueryMutation, RestoreQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreQueryMutation, RestoreQueryMutationVariables>(RestoreQueryDocument, options);
      }
export type RestoreQueryMutationHookResult = ReturnType<typeof useRestoreQueryMutation>;
export type RestoreQueryMutationResult = Apollo.MutationResult<RestoreQueryMutation>;
export type RestoreQueryMutationOptions = Apollo.BaseMutationOptions<RestoreQueryMutation, RestoreQueryMutationVariables>;
export const SearchDocument = gql`
    query Search($documentType: String, $limit: Int, $page: Int, $projectId: String, $query: String, $contextQuery: [String], $filters: [String], $facet: [String], $order: [String]) {
  search(
    documentType: $documentType
    query: $query
    contextQuery: $contextQuery
    page: $page
    projectId: $projectId
    limit: $limit
    filters: $filters
    facet: $facet
    order: $order
  ) {
    totalCount
    searchResults {
      ... on SearchDocument {
        ...SearchDocumentFragment
        ...SearchDocumentMetaDataFragment
      }
    }
    facetGroups {
      items {
        properties
        apiValue
        mdField {
          fieldDefinitionId
          fieldId
          valueType
          id
        }
        name
        value
      }
      type
    }
    requestBody
    debugInfo
  }
}
    ${SearchDocumentFragmentFragmentDoc}
${SearchDocumentMetaDataFragmentFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      projectId: // value for 'projectId'
 *      query: // value for 'query'
 *      contextQuery: // value for 'contextQuery'
 *      filters: // value for 'filters'
 *      facet: // value for 'facet'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const LoadQueriesDocument = gql`
    query LoadQueries($projectId: ID) {
  loadQueries(projectId: $projectId) {
    ...LoadQueriesFragment
  }
}
    ${LoadQueriesFragmentFragmentDoc}`;

/**
 * __useLoadQueriesQuery__
 *
 * To run a query within a React component, call `useLoadQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadQueriesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLoadQueriesQuery(baseOptions?: Apollo.QueryHookOptions<LoadQueriesQuery, LoadQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadQueriesQuery, LoadQueriesQueryVariables>(LoadQueriesDocument, options);
      }
export function useLoadQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadQueriesQuery, LoadQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadQueriesQuery, LoadQueriesQueryVariables>(LoadQueriesDocument, options);
        }
export type LoadQueriesQueryHookResult = ReturnType<typeof useLoadQueriesQuery>;
export type LoadQueriesLazyQueryHookResult = ReturnType<typeof useLoadQueriesLazyQuery>;
export type LoadQueriesQueryResult = Apollo.QueryResult<LoadQueriesQuery, LoadQueriesQueryVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($name: String!, $description: String, $endTime: String, $userId: ID, $procedureId: ID!, $type: TaskType, $documentAction: DocumentAction, $duplicatedFrom: String, $taskCategoryId: String) {
  createTask(
    name: $name
    description: $description
    endTime: $endTime
    userId: $userId
    procedureId: $procedureId
    type: $type
    documentAction: $documentAction
    duplicatedFrom: $duplicatedFrom
    taskCategoryId: $taskCategoryId
  ) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      endTime: // value for 'endTime'
 *      userId: // value for 'userId'
 *      procedureId: // value for 'procedureId'
 *      type: // value for 'type'
 *      documentAction: // value for 'documentAction'
 *      duplicatedFrom: // value for 'duplicatedFrom'
 *      taskCategoryId: // value for 'taskCategoryId'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateTbqScanTaskDocument = gql`
    mutation CreateTbqScanTask($name: String!, $description: String, $endTime: String, $userId: ID, $procedureId: ID!, $projectId: ID!, $type: TaskType, $taskCategoryId: String, $tbqTypeId: String!, $tbqScanTypeId: String!, $tbqModuleType: TBQModuleType!) {
  createTbqScanTask(
    name: $name
    description: $description
    endTime: $endTime
    userId: $userId
    procedureId: $procedureId
    projectId: $projectId
    type: $type
    taskCategoryId: $taskCategoryId
    tbqTypeId: $tbqTypeId
    tbqScanTypeId: $tbqScanTypeId
    tbqModuleType: $tbqModuleType
  ) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type CreateTbqScanTaskMutationFn = Apollo.MutationFunction<CreateTbqScanTaskMutation, CreateTbqScanTaskMutationVariables>;

/**
 * __useCreateTbqScanTaskMutation__
 *
 * To run a mutation, you first call `useCreateTbqScanTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTbqScanTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTbqScanTaskMutation, { data, loading, error }] = useCreateTbqScanTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      endTime: // value for 'endTime'
 *      userId: // value for 'userId'
 *      procedureId: // value for 'procedureId'
 *      projectId: // value for 'projectId'
 *      type: // value for 'type'
 *      taskCategoryId: // value for 'taskCategoryId'
 *      tbqTypeId: // value for 'tbqTypeId'
 *      tbqScanTypeId: // value for 'tbqScanTypeId'
 *      tbqModuleType: // value for 'tbqModuleType'
 *   },
 * });
 */
export function useCreateTbqScanTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTbqScanTaskMutation, CreateTbqScanTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTbqScanTaskMutation, CreateTbqScanTaskMutationVariables>(CreateTbqScanTaskDocument, options);
      }
export type CreateTbqScanTaskMutationHookResult = ReturnType<typeof useCreateTbqScanTaskMutation>;
export type CreateTbqScanTaskMutationResult = Apollo.MutationResult<CreateTbqScanTaskMutation>;
export type CreateTbqScanTaskMutationOptions = Apollo.BaseMutationOptions<CreateTbqScanTaskMutation, CreateTbqScanTaskMutationVariables>;
export const CreateBatchTaskCategoryDocument = gql`
    mutation CreateBatchTaskCategory($names: [String]!, $organizationId: ID!, $projectId: ID, $taskType: TaskType!) {
  createBatchTaskCategory(
    names: $names
    organizationId: $organizationId
    projectId: $projectId
    taskType: $taskType
  ) {
    ids
    success
    errors
  }
}
    `;
export type CreateBatchTaskCategoryMutationFn = Apollo.MutationFunction<CreateBatchTaskCategoryMutation, CreateBatchTaskCategoryMutationVariables>;

/**
 * __useCreateBatchTaskCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBatchTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchTaskCategoryMutation, { data, loading, error }] = useCreateBatchTaskCategoryMutation({
 *   variables: {
 *      names: // value for 'names'
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useCreateBatchTaskCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBatchTaskCategoryMutation, CreateBatchTaskCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBatchTaskCategoryMutation, CreateBatchTaskCategoryMutationVariables>(CreateBatchTaskCategoryDocument, options);
      }
export type CreateBatchTaskCategoryMutationHookResult = ReturnType<typeof useCreateBatchTaskCategoryMutation>;
export type CreateBatchTaskCategoryMutationResult = Apollo.MutationResult<CreateBatchTaskCategoryMutation>;
export type CreateBatchTaskCategoryMutationOptions = Apollo.BaseMutationOptions<CreateBatchTaskCategoryMutation, CreateBatchTaskCategoryMutationVariables>;
export const UpdateBatchTaskCategoryDocument = gql`
    mutation updateBatchTaskCategory($taskCategories: [TaskCategoryInput!]!) {
  updateBatchTaskCategory(taskCategories: $taskCategories) {
    success
    errors
  }
}
    `;
export type UpdateBatchTaskCategoryMutationFn = Apollo.MutationFunction<UpdateBatchTaskCategoryMutation, UpdateBatchTaskCategoryMutationVariables>;

/**
 * __useUpdateBatchTaskCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateBatchTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBatchTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBatchTaskCategoryMutation, { data, loading, error }] = useUpdateBatchTaskCategoryMutation({
 *   variables: {
 *      taskCategories: // value for 'taskCategories'
 *   },
 * });
 */
export function useUpdateBatchTaskCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBatchTaskCategoryMutation, UpdateBatchTaskCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBatchTaskCategoryMutation, UpdateBatchTaskCategoryMutationVariables>(UpdateBatchTaskCategoryDocument, options);
      }
export type UpdateBatchTaskCategoryMutationHookResult = ReturnType<typeof useUpdateBatchTaskCategoryMutation>;
export type UpdateBatchTaskCategoryMutationResult = Apollo.MutationResult<UpdateBatchTaskCategoryMutation>;
export type UpdateBatchTaskCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateBatchTaskCategoryMutation, UpdateBatchTaskCategoryMutationVariables>;
export const DeleteBatchTaskCategoryDocument = gql`
    mutation DeleteBatchTaskCategory($taskCategories: [TaskCategoryInput!]!) {
  deleteBatchTaskCategory(taskCategories: $taskCategories) {
    success
    errors
  }
}
    `;
export type DeleteBatchTaskCategoryMutationFn = Apollo.MutationFunction<DeleteBatchTaskCategoryMutation, DeleteBatchTaskCategoryMutationVariables>;

/**
 * __useDeleteBatchTaskCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteBatchTaskCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBatchTaskCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBatchTaskCategoryMutation, { data, loading, error }] = useDeleteBatchTaskCategoryMutation({
 *   variables: {
 *      taskCategories: // value for 'taskCategories'
 *   },
 * });
 */
export function useDeleteBatchTaskCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBatchTaskCategoryMutation, DeleteBatchTaskCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBatchTaskCategoryMutation, DeleteBatchTaskCategoryMutationVariables>(DeleteBatchTaskCategoryDocument, options);
      }
export type DeleteBatchTaskCategoryMutationHookResult = ReturnType<typeof useDeleteBatchTaskCategoryMutation>;
export type DeleteBatchTaskCategoryMutationResult = Apollo.MutationResult<DeleteBatchTaskCategoryMutation>;
export type DeleteBatchTaskCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteBatchTaskCategoryMutation, DeleteBatchTaskCategoryMutationVariables>;
export const CreateDocumentTaskDocument = gql`
    mutation CreateDocumentTask($name: String!, $description: String, $endTime: String, $userId: ID, $procedureId: ID!, $type: TaskType, $duplicatedFrom: String, $documentAction: DocumentAction, $taskCategoryId: String) {
  createDocumentTask(
    name: $name
    description: $description
    endTime: $endTime
    userId: $userId
    duplicatedFrom: $duplicatedFrom
    procedureId: $procedureId
    type: $type
    documentAction: $documentAction
    taskCategoryId: $taskCategoryId
  ) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type CreateDocumentTaskMutationFn = Apollo.MutationFunction<CreateDocumentTaskMutation, CreateDocumentTaskMutationVariables>;

/**
 * __useCreateDocumentTaskMutation__
 *
 * To run a mutation, you first call `useCreateDocumentTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentTaskMutation, { data, loading, error }] = useCreateDocumentTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      endTime: // value for 'endTime'
 *      userId: // value for 'userId'
 *      procedureId: // value for 'procedureId'
 *      type: // value for 'type'
 *      duplicatedFrom: // value for 'duplicatedFrom'
 *      documentAction: // value for 'documentAction'
 *      taskCategoryId: // value for 'taskCategoryId'
 *   },
 * });
 */
export function useCreateDocumentTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentTaskMutation, CreateDocumentTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentTaskMutation, CreateDocumentTaskMutationVariables>(CreateDocumentTaskDocument, options);
      }
export type CreateDocumentTaskMutationHookResult = ReturnType<typeof useCreateDocumentTaskMutation>;
export type CreateDocumentTaskMutationResult = Apollo.MutationResult<CreateDocumentTaskMutation>;
export type CreateDocumentTaskMutationOptions = Apollo.BaseMutationOptions<CreateDocumentTaskMutation, CreateDocumentTaskMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation DeleteTask($id: ID!, $procedureId: ID!) {
  deleteTask(id: $id, procedureId: $procedureId) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      procedureId: // value for 'procedureId'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const RestoreTaskDocument = gql`
    mutation RestoreTask($id: ID!, $procedureId: ID!) {
  restoreTask(id: $id, procedureId: $procedureId)
}
    `;
export type RestoreTaskMutationFn = Apollo.MutationFunction<RestoreTaskMutation, RestoreTaskMutationVariables>;

/**
 * __useRestoreTaskMutation__
 *
 * To run a mutation, you first call `useRestoreTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreTaskMutation, { data, loading, error }] = useRestoreTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      procedureId: // value for 'procedureId'
 *   },
 * });
 */
export function useRestoreTaskMutation(baseOptions?: Apollo.MutationHookOptions<RestoreTaskMutation, RestoreTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreTaskMutation, RestoreTaskMutationVariables>(RestoreTaskDocument, options);
      }
export type RestoreTaskMutationHookResult = ReturnType<typeof useRestoreTaskMutation>;
export type RestoreTaskMutationResult = Apollo.MutationResult<RestoreTaskMutation>;
export type RestoreTaskMutationOptions = Apollo.BaseMutationOptions<RestoreTaskMutation, RestoreTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: ID!, $command: String, $description: String, $endTime: String, $name: String, $procedureId: ID!, $type: TaskType, $userId: ID, $documentAction: DocumentAction, $taskCategoryId: String) {
  updateTask(
    id: $id
    command: $command
    description: $description
    endTime: $endTime
    name: $name
    procedureId: $procedureId
    type: $type
    userId: $userId
    documentAction: $documentAction
    taskCategoryId: $taskCategoryId
  ) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      command: // value for 'command'
 *      description: // value for 'description'
 *      endTime: // value for 'endTime'
 *      name: // value for 'name'
 *      procedureId: // value for 'procedureId'
 *      type: // value for 'type'
 *      userId: // value for 'userId'
 *      documentAction: // value for 'documentAction'
 *      taskCategoryId: // value for 'taskCategoryId'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const AddQuestionsToResolveTaskBatchDocument = gql`
    mutation AddQuestionsToResolveTaskBatch($batch: [QuestionInput!]!) {
  addQuestionsToResolveTaskBatch(batch: $batch) {
    success
    id
  }
}
    `;
export type AddQuestionsToResolveTaskBatchMutationFn = Apollo.MutationFunction<AddQuestionsToResolveTaskBatchMutation, AddQuestionsToResolveTaskBatchMutationVariables>;

/**
 * __useAddQuestionsToResolveTaskBatchMutation__
 *
 * To run a mutation, you first call `useAddQuestionsToResolveTaskBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsToResolveTaskBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsToResolveTaskBatchMutation, { data, loading, error }] = useAddQuestionsToResolveTaskBatchMutation({
 *   variables: {
 *      batch: // value for 'batch'
 *   },
 * });
 */
export function useAddQuestionsToResolveTaskBatchMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionsToResolveTaskBatchMutation, AddQuestionsToResolveTaskBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionsToResolveTaskBatchMutation, AddQuestionsToResolveTaskBatchMutationVariables>(AddQuestionsToResolveTaskBatchDocument, options);
      }
export type AddQuestionsToResolveTaskBatchMutationHookResult = ReturnType<typeof useAddQuestionsToResolveTaskBatchMutation>;
export type AddQuestionsToResolveTaskBatchMutationResult = Apollo.MutationResult<AddQuestionsToResolveTaskBatchMutation>;
export type AddQuestionsToResolveTaskBatchMutationOptions = Apollo.BaseMutationOptions<AddQuestionsToResolveTaskBatchMutation, AddQuestionsToResolveTaskBatchMutationVariables>;
export const SetLinkDocument = gql`
    mutation SetLink($resourceAId: ID!, $resourceAType: ResourceType, $resourceBLinkIds: [ID]!, $resourceBType: ResourceType, $appData: AppDataInput) {
  setLink(
    resourceAId: $resourceAId
    resourceAType: $resourceAType
    resourceBLinkIds: $resourceBLinkIds
    resourceBType: $resourceBType
    appData: $appData
  ) {
    success
    id
  }
}
    `;
export type SetLinkMutationFn = Apollo.MutationFunction<SetLinkMutation, SetLinkMutationVariables>;

/**
 * __useSetLinkMutation__
 *
 * To run a mutation, you first call `useSetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLinkMutation, { data, loading, error }] = useSetLinkMutation({
 *   variables: {
 *      resourceAId: // value for 'resourceAId'
 *      resourceAType: // value for 'resourceAType'
 *      resourceBLinkIds: // value for 'resourceBLinkIds'
 *      resourceBType: // value for 'resourceBType'
 *      appData: // value for 'appData'
 *   },
 * });
 */
export function useSetLinkMutation(baseOptions?: Apollo.MutationHookOptions<SetLinkMutation, SetLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLinkMutation, SetLinkMutationVariables>(SetLinkDocument, options);
      }
export type SetLinkMutationHookResult = ReturnType<typeof useSetLinkMutation>;
export type SetLinkMutationResult = Apollo.MutationResult<SetLinkMutation>;
export type SetLinkMutationOptions = Apollo.BaseMutationOptions<SetLinkMutation, SetLinkMutationVariables>;
export const SetDocumentTaskLinksDocument = gql`
    mutation SetDocumentTaskLinks($attachmentTaskIds: [ID]!, $deliverableTaskIds: [ID]!, $documentId: ID!) {
  setDocumentTaskLinks(
    attachmentTaskIds: $attachmentTaskIds
    deliverableTaskIds: $deliverableTaskIds
    documentId: $documentId
  ) {
    success
    id
  }
}
    `;
export type SetDocumentTaskLinksMutationFn = Apollo.MutationFunction<SetDocumentTaskLinksMutation, SetDocumentTaskLinksMutationVariables>;

/**
 * __useSetDocumentTaskLinksMutation__
 *
 * To run a mutation, you first call `useSetDocumentTaskLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentTaskLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentTaskLinksMutation, { data, loading, error }] = useSetDocumentTaskLinksMutation({
 *   variables: {
 *      attachmentTaskIds: // value for 'attachmentTaskIds'
 *      deliverableTaskIds: // value for 'deliverableTaskIds'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useSetDocumentTaskLinksMutation(baseOptions?: Apollo.MutationHookOptions<SetDocumentTaskLinksMutation, SetDocumentTaskLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDocumentTaskLinksMutation, SetDocumentTaskLinksMutationVariables>(SetDocumentTaskLinksDocument, options);
      }
export type SetDocumentTaskLinksMutationHookResult = ReturnType<typeof useSetDocumentTaskLinksMutation>;
export type SetDocumentTaskLinksMutationResult = Apollo.MutationResult<SetDocumentTaskLinksMutation>;
export type SetDocumentTaskLinksMutationOptions = Apollo.BaseMutationOptions<SetDocumentTaskLinksMutation, SetDocumentTaskLinksMutationVariables>;
export const SetTaskTemplateLinksDocument = gql`
    mutation SetTaskTemplateLinks($taskId: ID!, $templateIds: [ID]!) {
  setTaskTemplateLinks(taskId: $taskId, templateIds: $templateIds) {
    success
    id
  }
}
    `;
export type SetTaskTemplateLinksMutationFn = Apollo.MutationFunction<SetTaskTemplateLinksMutation, SetTaskTemplateLinksMutationVariables>;

/**
 * __useSetTaskTemplateLinksMutation__
 *
 * To run a mutation, you first call `useSetTaskTemplateLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskTemplateLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskTemplateLinksMutation, { data, loading, error }] = useSetTaskTemplateLinksMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      templateIds: // value for 'templateIds'
 *   },
 * });
 */
export function useSetTaskTemplateLinksMutation(baseOptions?: Apollo.MutationHookOptions<SetTaskTemplateLinksMutation, SetTaskTemplateLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaskTemplateLinksMutation, SetTaskTemplateLinksMutationVariables>(SetTaskTemplateLinksDocument, options);
      }
export type SetTaskTemplateLinksMutationHookResult = ReturnType<typeof useSetTaskTemplateLinksMutation>;
export type SetTaskTemplateLinksMutationResult = Apollo.MutationResult<SetTaskTemplateLinksMutation>;
export type SetTaskTemplateLinksMutationOptions = Apollo.BaseMutationOptions<SetTaskTemplateLinksMutation, SetTaskTemplateLinksMutationVariables>;
export const SetTaskVisualContextLinksDocument = gql`
    mutation SetTaskVisualContextLinks($taskId: ID!, $visualContextIds: [ID]!) {
  setTaskVisualContextLinks(taskId: $taskId, visualContextIds: $visualContextIds) {
    success
    id
  }
}
    `;
export type SetTaskVisualContextLinksMutationFn = Apollo.MutationFunction<SetTaskVisualContextLinksMutation, SetTaskVisualContextLinksMutationVariables>;

/**
 * __useSetTaskVisualContextLinksMutation__
 *
 * To run a mutation, you first call `useSetTaskVisualContextLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskVisualContextLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskVisualContextLinksMutation, { data, loading, error }] = useSetTaskVisualContextLinksMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      visualContextIds: // value for 'visualContextIds'
 *   },
 * });
 */
export function useSetTaskVisualContextLinksMutation(baseOptions?: Apollo.MutationHookOptions<SetTaskVisualContextLinksMutation, SetTaskVisualContextLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTaskVisualContextLinksMutation, SetTaskVisualContextLinksMutationVariables>(SetTaskVisualContextLinksDocument, options);
      }
export type SetTaskVisualContextLinksMutationHookResult = ReturnType<typeof useSetTaskVisualContextLinksMutation>;
export type SetTaskVisualContextLinksMutationResult = Apollo.MutationResult<SetTaskVisualContextLinksMutation>;
export type SetTaskVisualContextLinksMutationOptions = Apollo.BaseMutationOptions<SetTaskVisualContextLinksMutation, SetTaskVisualContextLinksMutationVariables>;
export const ProjectTasksDocument = gql`
    query ProjectTasks($id: ID!, $typeFilter: [TaskType]) {
  project(id: $id) {
    mainProcedure {
      id
      tasks(typeFilter: $typeFilter) {
        ...TaskFragment
      }
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useProjectTasksQuery__
 *
 * To run a query within a React component, call `useProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *      typeFilter: // value for 'typeFilter'
 *   },
 * });
 */
export function useProjectTasksQuery(baseOptions: Apollo.QueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(ProjectTasksDocument, options);
      }
export function useProjectTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(ProjectTasksDocument, options);
        }
export type ProjectTasksQueryHookResult = ReturnType<typeof useProjectTasksQuery>;
export type ProjectTasksLazyQueryHookResult = ReturnType<typeof useProjectTasksLazyQuery>;
export type ProjectTasksQueryResult = Apollo.QueryResult<ProjectTasksQuery, ProjectTasksQueryVariables>;
export const MyTasksDocument = gql`
    query MyTasks {
  myTasks {
    ...TaskFragment
    project {
      id
      name
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useMyTasksQuery__
 *
 * To run a query within a React component, call `useMyTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTasksQuery(baseOptions?: Apollo.QueryHookOptions<MyTasksQuery, MyTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
      }
export function useMyTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTasksQuery, MyTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
        }
export type MyTasksQueryHookResult = ReturnType<typeof useMyTasksQuery>;
export type MyTasksLazyQueryHookResult = ReturnType<typeof useMyTasksLazyQuery>;
export type MyTasksQueryResult = Apollo.QueryResult<MyTasksQuery, MyTasksQueryVariables>;
export const GetProjectTaskCategoriesByTypeDocument = gql`
    query getProjectTaskCategoriesByType($projectId: ID, $taskType: TaskType!) {
  getProjectTaskCategoriesByType(projectId: $projectId, taskType: $taskType) {
    name
    taskType
    taskCategoryId
    projectId
    organizationId
  }
}
    `;

/**
 * __useGetProjectTaskCategoriesByTypeQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskCategoriesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskCategoriesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskCategoriesByTypeQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useGetProjectTaskCategoriesByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetProjectTaskCategoriesByTypeQuery, GetProjectTaskCategoriesByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectTaskCategoriesByTypeQuery, GetProjectTaskCategoriesByTypeQueryVariables>(GetProjectTaskCategoriesByTypeDocument, options);
      }
export function useGetProjectTaskCategoriesByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectTaskCategoriesByTypeQuery, GetProjectTaskCategoriesByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectTaskCategoriesByTypeQuery, GetProjectTaskCategoriesByTypeQueryVariables>(GetProjectTaskCategoriesByTypeDocument, options);
        }
export type GetProjectTaskCategoriesByTypeQueryHookResult = ReturnType<typeof useGetProjectTaskCategoriesByTypeQuery>;
export type GetProjectTaskCategoriesByTypeLazyQueryHookResult = ReturnType<typeof useGetProjectTaskCategoriesByTypeLazyQuery>;
export type GetProjectTaskCategoriesByTypeQueryResult = Apollo.QueryResult<GetProjectTaskCategoriesByTypeQuery, GetProjectTaskCategoriesByTypeQueryVariables>;
export const GetOrganizationTaskCategoriesByTypeDocument = gql`
    query GetOrganizationTaskCategoriesByType($taskType: TaskType!) {
  getOrganizationTaskCategoriesByType(taskType: $taskType) {
    name
    taskType
    taskCategoryId
    projectId
    organizationId
  }
}
    `;

/**
 * __useGetOrganizationTaskCategoriesByTypeQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTaskCategoriesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTaskCategoriesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTaskCategoriesByTypeQuery({
 *   variables: {
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useGetOrganizationTaskCategoriesByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationTaskCategoriesByTypeQuery, GetOrganizationTaskCategoriesByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationTaskCategoriesByTypeQuery, GetOrganizationTaskCategoriesByTypeQueryVariables>(GetOrganizationTaskCategoriesByTypeDocument, options);
      }
export function useGetOrganizationTaskCategoriesByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationTaskCategoriesByTypeQuery, GetOrganizationTaskCategoriesByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationTaskCategoriesByTypeQuery, GetOrganizationTaskCategoriesByTypeQueryVariables>(GetOrganizationTaskCategoriesByTypeDocument, options);
        }
export type GetOrganizationTaskCategoriesByTypeQueryHookResult = ReturnType<typeof useGetOrganizationTaskCategoriesByTypeQuery>;
export type GetOrganizationTaskCategoriesByTypeLazyQueryHookResult = ReturnType<typeof useGetOrganizationTaskCategoriesByTypeLazyQuery>;
export type GetOrganizationTaskCategoriesByTypeQueryResult = Apollo.QueryResult<GetOrganizationTaskCategoriesByTypeQuery, GetOrganizationTaskCategoriesByTypeQueryVariables>;
export const VisualContextsForTaskDocument = gql`
    query VisualContextsForTask($id: ID!) {
  visualContextsForTask(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useVisualContextsForTaskQuery__
 *
 * To run a query within a React component, call `useVisualContextsForTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualContextsForTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualContextsForTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisualContextsForTaskQuery(baseOptions: Apollo.QueryHookOptions<VisualContextsForTaskQuery, VisualContextsForTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisualContextsForTaskQuery, VisualContextsForTaskQueryVariables>(VisualContextsForTaskDocument, options);
      }
export function useVisualContextsForTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisualContextsForTaskQuery, VisualContextsForTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisualContextsForTaskQuery, VisualContextsForTaskQueryVariables>(VisualContextsForTaskDocument, options);
        }
export type VisualContextsForTaskQueryHookResult = ReturnType<typeof useVisualContextsForTaskQuery>;
export type VisualContextsForTaskLazyQueryHookResult = ReturnType<typeof useVisualContextsForTaskLazyQuery>;
export type VisualContextsForTaskQueryResult = Apollo.QueryResult<VisualContextsForTaskQuery, VisualContextsForTaskQueryVariables>;
export const TaskStatusesDocument = gql`
    query TaskStatuses($id: ID!, $status: Status!) {
  taskStatuses(id: $id, status: $status) {
    taskStatus
    available
    command
  }
}
    `;

/**
 * __useTaskStatusesQuery__
 *
 * To run a query within a React component, call `useTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTaskStatusesQuery(baseOptions: Apollo.QueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, options);
      }
export function useTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, options);
        }
export type TaskStatusesQueryHookResult = ReturnType<typeof useTaskStatusesQuery>;
export type TaskStatusesLazyQueryHookResult = ReturnType<typeof useTaskStatusesLazyQuery>;
export type TaskStatusesQueryResult = Apollo.QueryResult<TaskStatusesQuery, TaskStatusesQueryVariables>;
export const TaskDocument = gql`
    query Task($id: ID!) {
  task(id: $id) {
    ...TaskDetailFragment
  }
}
    ${TaskDetailFragmentFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksBatchIncludeDocument = gql`
    query TasksBatchInclude($ids: [ID!]!, $includeName: Boolean = false, $includeCreatedBy: Boolean = false, $includeCreatedAt: Boolean = false, $includeAssignment: Boolean = false, $includeCategory: Boolean = false, $includeLinkedSnagInstances: Boolean = false, $includeLinkedFormInstances: Boolean = false, $includeStatus: Boolean = false, $includeCompletedAt: Boolean = false, $includeSnagTemplates: Boolean = false, $includeFormTemplates: Boolean = false, $includeVisualContexts: Boolean = false, $includeEndtime: Boolean = false, $includeDescription: Boolean = false) {
  tasksBatchInclude(ids: $ids) {
    id
    procedureId
    name @include(if: $includeName)
    createdByUser @include(if: $includeCreatedBy) {
      id
      displayName
    }
    type
    taskCategoryName @include(if: $includeCategory)
    createdAt @include(if: $includeCreatedAt)
    completedAt @include(if: $includeCompletedAt)
    description @include(if: $includeDescription)
    endTime @include(if: $includeEndtime)
    status @include(if: $includeStatus)
    snagTemplates @include(if: $includeSnagTemplates) {
      id
      name
    }
    formTemplates @include(if: $includeFormTemplates) {
      id
      name
    }
    assignment @include(if: $includeAssignment) {
      id
      displayName
    }
    visualContexts @include(if: $includeVisualContexts) {
      id
      name
    }
    linkedSnagInstances @include(if: $includeLinkedSnagInstances) {
      ...QualityControlInstanceFragment
    }
    linkedFormInstances @include(if: $includeLinkedFormInstances) {
      id
      reference
      type
      name
      items {
        ...FieldDefinitionBasicFragment
      }
      visualContextId
      visualContext {
        name
        id
      }
      x
      y
      page
    }
  }
}
    ${QualityControlInstanceFragmentFragmentDoc}
${FieldDefinitionBasicFragmentFragmentDoc}`;

/**
 * __useTasksBatchIncludeQuery__
 *
 * To run a query within a React component, call `useTasksBatchIncludeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksBatchIncludeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksBatchIncludeQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      includeName: // value for 'includeName'
 *      includeCreatedBy: // value for 'includeCreatedBy'
 *      includeCreatedAt: // value for 'includeCreatedAt'
 *      includeAssignment: // value for 'includeAssignment'
 *      includeCategory: // value for 'includeCategory'
 *      includeLinkedSnagInstances: // value for 'includeLinkedSnagInstances'
 *      includeLinkedFormInstances: // value for 'includeLinkedFormInstances'
 *      includeStatus: // value for 'includeStatus'
 *      includeCompletedAt: // value for 'includeCompletedAt'
 *      includeSnagTemplates: // value for 'includeSnagTemplates'
 *      includeFormTemplates: // value for 'includeFormTemplates'
 *      includeVisualContexts: // value for 'includeVisualContexts'
 *      includeEndtime: // value for 'includeEndtime'
 *      includeDescription: // value for 'includeDescription'
 *   },
 * });
 */
export function useTasksBatchIncludeQuery(baseOptions: Apollo.QueryHookOptions<TasksBatchIncludeQuery, TasksBatchIncludeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksBatchIncludeQuery, TasksBatchIncludeQueryVariables>(TasksBatchIncludeDocument, options);
      }
export function useTasksBatchIncludeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksBatchIncludeQuery, TasksBatchIncludeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksBatchIncludeQuery, TasksBatchIncludeQueryVariables>(TasksBatchIncludeDocument, options);
        }
export type TasksBatchIncludeQueryHookResult = ReturnType<typeof useTasksBatchIncludeQuery>;
export type TasksBatchIncludeLazyQueryHookResult = ReturnType<typeof useTasksBatchIncludeLazyQuery>;
export type TasksBatchIncludeQueryResult = Apollo.QueryResult<TasksBatchIncludeQuery, TasksBatchIncludeQueryVariables>;